import React, { useEffect, useMemo, useState } from 'react';
import CloseIcon from '../CommonIcons/CloseIcon';
import ModelBuilderIcon from '../CommonIcons/ModelBuilderIcon';
import ActivityTimeLine from './ActivityTimeLine';
import { useSelector } from 'react-redux';
import { Popover } from 'antd';

const ActivityHistoryList = ({ activityData, onClose, borderColor, headerBg, title }) => {
    const [activeNotify, setActiveNotify] = useState(activityData ? activityData?.[0]?.category_id : null);
    const [currentTab, setCurrentTab] = useState(2)

    const handleToggleCategory = (category_id) => {
        setActiveNotify(category_id === activeNotify ? null : category_id);
    };

    const nodeHistory = useMemo(() => {
        return activityData?.nodeHistory
    }, [activityData?.nodeHistory])

    const categoryHistory = useMemo(() => {
        return activityData?.categoryHistory ?? []
    }, [activityData?.categoryHistory])


    useEffect(()=>{
        if (categoryHistory) {
            setActiveNotify(categoryHistory?.[0]?.category_id)
        }

    }, [categoryHistory])


    const renderActivityLog = () => {
        if (currentTab == 1) {
            return <div className='pt-[1rem] ml-4 h-full'>
                <ActivityTimeLine data={categoryHistory?.find(category => category.category_id === activeNotify)?.category_history ?? []} />
            </div>
        } else if (currentTab == 2) {
            return <div className='pt-[1rem] ml-4 h-full'>
                <ActivityTimeLine data={nodeHistory} />
            </div>
        }
    }
    
      const { CategoryListData} = useSelector(state => {
        return {
            CategoryListData: state?.ModelBuilder?.categoryListData,
        }
      })

    return (
        <div className={`border-l-2 ${borderColor} h-full`}>
            <div className={`w-full ${headerBg} flex justify-between py-5 pl-[1rem] pr-[1.2rem] h-[6%]`}>
                <div className='flex items-center gap-2 '>
                    <ModelBuilderIcon className={'2xl:w-[25px] 2xl:h-[20px] w-[22px] h-[16px]'} bgColor={'#ffffff'} />
                    <p className='text-white font-semibold 2xl:text-[15px] text-[13px]'>{title}</p>
                </div>
                <div className='flex items-center gap-2 cursor-pointer' onClick={() => onClose('activity')}>
                    <p className='text-white font-semibold 2xl:text-[15px] text-[13px]'>Close</p>
                    <CloseIcon className={'2xl:w-[15px] 2xl:h-[15px] w-[12px] h-[12px]'} bgColor={'#ffffff'} />
                </div>
            </div>
            <div className={`lg:px-[0.5rem] xl:px-[1rem] overflow-hidden thin-scrollbar h-[90%]`}>
                <div className="flex flex-col justify-between w-full h-full">
                    <div className='sticky top-0 bg-white z-[10] h-fit'>
                    <div className="flex flex-row items-center lg:gap-1 xl:gap-2 2xl:gap-3 border-b border-[#E7E6E6]">
                        <div
                            className={`cursor-pointer leading-[16px] xl:text-[14px] text-[13px] whitespace-nowrap tracking-[-0.28px] group flex items-center py-3 space-x-2 ${currentTab === 2
                                ? 'border-b-[2px] border-[#70AD47] font-sans_medium_body text-[#70AD47]'
                                : 'text-[#AFABAB] font-sans_book_body'
                                }`}
                            onClick={() => {setCurrentTab(2);}}
                        >
                            <span className='px-1'>{"Workflow"}  </span>
                        </div>
                        <div className={`cursor-pointer relative leading-[16px] xl:text-[14px] text-[13px] whitespace-nowrap tracking-[-0.28px] group flex items-center py-3 space-x-2 ${currentTab === 1
                                ? 'border-b-[2px] border-[#70AD47] font-sans_medium_body text-[#70AD47]'
                                : 'text-[#AFABAB] font-sans_book_body'
                                }`}
                            onClick={() => setCurrentTab(1)}                            
                            >
                                <Popover
                                    trigger="hover"
                                    placement='bottomLeft'
                                    openClassName='p-0'
                                    overlayInnerStyle={{
                                        padding: '6px'
                                    }}
                                    content={
                                        <div style={{ minWidth: '130px',maxHeight:'15rem' }} className='overflow-y-auto thin-scrollbar'>
                                            {CategoryListData?.map((category, i) => (
                                                <p key={i} className={`cursor-pointer 2xl:text-[14px] text-[12px] px-3 py-1 hover:bg-gray-100 ${category?.id === activeNotify ? 'text-[#00A7B5]':'text-[#000000]' }`} onClick={(e) => handleToggleCategory(category?.id)}
                                                >{category?.category_name}</p>
                                            ))}
                                        </div>
                                    }
                                >
                                <span className='px-1'>{"Category"}  </span>
                                </Popover>
                            </div>

                    </div>
                    {/* {currentTab == 1 ?
                    <div className='my-2 flex justify-evenly gap-2 w-full items-start'>
                        {CategoryListData?.map((category, i) => (
                            <label className='flex items-center 2xl:gap-2 gap-1 cursor-pointer'>
                                <input
                                    type="radio"
                                    value={category?.id}
                                    checked={activeNotify == category?.id}
                                    onChange={(e) => handleToggleCategory(category?.id)}
                                    className='cursor-pointer custom-radio'
                                />
                                <span htmlFor="null" className='text-[13px] whitespace-nowrap'>{category?.category_name}</span>
                            </label>
                        ))}
                    </div>
                    :null} */}
                    </div>
                    <div className='overflow-y-auto'>
                    {renderActivityLog()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ActivityHistoryList;
