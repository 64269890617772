import React from 'react'
import '../../CSS/Skeleton.css'

//skeleton loader
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const MethodSkeleton = ({count =1 }) => {
  return (
    <>
    {Array(count).fill(0).map((item, i) => (
    <div className='flex items-center bg-[#e0e0e0] shadow-[1px_1px_5px_1px_rgb(232,232,232)] p-2 gap-8' key={i}>
        <div className='grid grid-cols-4 w-[95%] gap-8 items-center'>
            <div className=''>
                <Skeleton className='card-skeleton-text skeletonColor'/>
                <Skeleton className='h-8 skeletonColor col-span-1' />   
            </div>
            <div className=''>
                <Skeleton className='card-skeleton-text skeletonColor'/>
                <Skeleton className='h-8 skeletonColor col-span-1' />   
            </div>
            <div className=''>
                <Skeleton className='card-skeleton-text skeletonColor'/>
                <Skeleton className='h-8 skeletonColor col-span-1' />   
            </div>
            <div className=''>
                <Skeleton className='card-skeleton-text skeletonColor'/>
                <Skeleton className='h-8 skeletonColor col-span-1' />   
            </div>  
        </div> 
        <div className='w-[5%] mt-5'>
            <Skeleton className='h-8 skeletonColor ' />    
        </div> 
    </div>
  ))
}
</>
    
  )
}

export default MethodSkeleton
