import React from 'react'
import PlusIcon from '../Components/CommonIcons/PlusIcon'
import MinusIcon from './CommonIcons/MinusIcon'
import CsvIcon from './CommonIcons/CsvIcon'
import HistroyIcon from './CommonIcons/HistroyIcon'
import ModelBuilderIcon from './CommonIcons/ModelBuilderIcon'
import NewPlusIcon from './CommonIcons/NewPlusIcon'
import downloading from '../Images/gif/download.gif'
import processing from '../Images/gif/processing.gif';
import ActivityLog from '../Images/activityLog.svg';
import AddIcon from '../Images/Group 123.svg'

const Button = ({bgColor,borderColor,borderType,label,color,size,onClick,icon,type, weight, fsize, disabled,forLevel,paddingY,borerRadius,className ,menu}) => {
    return (
        <button className={`${className} flex items-center gap-2 justify-center border-${borderType} whitespace-nowrap ${disabled ? 'cursor-not-allowed' :'cursor-pointer'} ${size=='xs'? 'px-2':size=='sm'? 'px-4' :size=='md' ? 'px-6':size=='lg' ? 'lxl:px-8 px-6': size=='xl'? 'lxl:px-12 px-8':'xl:px-1.5 px-1'} ${menu ==="savemethod"?"px-6":""} ${forLevel ? 'py-0.5 rounded-[3px]':icon =='csv' ? 'py-1 rounded-[5px]': paddingY ? paddingY :'py-1.5 rounded-[5px]'} ${borerRadius} xl:text-[12px] text-[10px] border`}
        type={type??'button'}
        style={{color:color,backgroundColor: disabled ? "#D0CECE":bgColor,borderStyle:borderType,fontWeight:weight, fontSize:fsize,borderColor: borderColor}}
        disabled={disabled}
        onClick={(e)=> onClick ? onClick(e):'/#' }
             >
            {icon =='plus' 
                ?                
            <PlusIcon color={color} className={'xl:w-[14px] xl:h-[14px] w-[12px] h-[12px]'}/>
            :
            icon == 'add' ?
            <img src={AddIcon} className='h-[14px] w-[14px]' />        
            :
            icon =='minus'
            ?
            <MinusIcon color={color} className={'xl:w-[14px] xl:h-[14px] w-[12px] h-[12px]'}/>
            :
            icon =='csv'
            ?
            <CsvIcon height={'16px'} width={'15px'} />
            :
            icon=='downloading'
            ?
            <img src={downloading} className='h-[15px] w-[15px]' />
            :
            icon == 'processing'?
            <img src={processing} className="h-[20px] brightness-0 invert-[1] " />
            :
            icon =='histroy'?
            <HistroyIcon className='xl:h-[20px] xl:w-[20px] w-[15px] h-[15px]' bgColor={'#fff'} />
            :
            icon =='modelBuilder' ?
             <ModelBuilderIcon className='xl:h-[20px] xl:w-[17px] w-[13px] h-[13px]' bgColor={'#fff'} />
             :
             icon =='new'?
             <NewPlusIcon bgColor={'#fff'} className='xl:h-[16px] xl:w-[16px] w-[13px] h-[13px]'/>
             :
             icon =='activeLog' ?
             <img src={ActivityLog} className='xl:h-[16px] xl:w-[16px] w-[13px] h-[13px]'/>
             :
            <></>
            }
            <span className='max-w-[10rem] text-ellipsis overflow-hidden text-[11px] xl:text-[13px]'>{label}</span>
        </button>
    )
}

export default Button
