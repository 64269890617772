import React from 'react'

const CloseIcon = ({width,height,bgColor,className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} viewBox="0 0 25 25">
            <g id="Group_170" data-name="Group 170" transform="translate(551.981 -132.909)">
                <path id="Path_116" data-name="Path 116" d="M-551.981,145.386a12.5,12.5,0,0,1,12.521-12.477,12.519,12.519,0,0,1,12.479,12.549,12.507,12.507,0,0,1-12.548,12.451A12.507,12.507,0,0,1-551.981,145.386Zm21.876.034a9.382,9.382,0,0,0-9.353-9.387,9.383,9.383,0,0,0-9.4,9.363,9.385,9.385,0,0,0,9.35,9.389A9.4,9.4,0,0,0-530.1,145.42Z" fill={bgColor} />
                <path id="Path_117" data-name="Path 117" d="M-385.171,297.532c.678.676,1.342,1.324,1.99,1.987a1.554,1.554,0,0,1-.168,2.389,1.551,1.551,0,0,1-2.045-.184c-.541-.529-1.073-1.068-1.606-1.605-.12-.121-.229-.252-.361-.4-.091.087-.155.145-.216.205-.6.6-1.191,1.2-1.8,1.793a1.558,1.558,0,0,1-2.622-.592,1.529,1.529,0,0,1,.424-1.629q.876-.884,1.761-1.761c.063-.063.139-.113.229-.185-.088-.094-.145-.158-.206-.219-.6-.6-1.216-1.2-1.81-1.815a1.555,1.555,0,0,1,.615-2.6,1.514,1.514,0,0,1,1.61.41c.6.587,1.188,1.183,1.778,1.778a1.753,1.753,0,0,1,.164.232c.114-.105.179-.163.241-.224.6-.6,1.2-1.213,1.815-1.811a1.539,1.539,0,0,1,1.776-.319,1.556,1.556,0,0,1,.9,1.5,1.6,1.6,0,0,1-.507,1.076q-.891.887-1.778,1.778C-385.043,297.392-385.1,297.452-385.171,297.532Z" transform="translate(-152.095 -152.113)" fill={bgColor} />
            </g>
        </svg>

    )
}

export default CloseIcon
