
  import {
    API_ERROR,
    CLIENTS_LIST, 
    CLIENTS_LIST_FETCH_FAILED,
    CLIENTS_LIST_FETCH_SUCCEEDED,
    CLIENTS_LIST_FETCH_MORE_REQUESTED,
    CLIENTS_LIST_FETCH_MORE_SUCCEEDED,
    CLIENTS_DATA_CLEARED,
    CREATE_CLIENT,
    CLIENT_CREATE_SUCCESS,
    CLIENTS_LIST_SORTING,
    CLIENTS_LIST_SORTING_SUCCESS,
    CLIENTS_LIST_SORTING_FAILURE,
    CLIENT_COUNTRY_LIST,
    CLIENT_COUNTRY_LIST_FETCH_SUCCEEDED,
    CLIENT_COUNTRY_LEVEL_ONE_LIST,
    CLIENT_COUNTRY_LEVEL_ONE_LIST_FETCH_SUCCEEDED,
    LEVEL_NAME_UPDATE_SUCCESS,
    LEVEL_NAME_UPDATE,
    CATEGORY_NAME_UPDATE,
    CATEGORY_NAME_UPDATE_SUCCESS,
    MANAGE_COUNTRY,
    MANAGE_COUNTRY_SUCCESS,
    UPDATE_CLIENT,
    UPDATE_CLIENT_SUCCESS,
    CLIENT_COUNTRY_LEVEL_TWO_LIST,
    CLIENT_COUNTRY_LEVEL_TWO_LIST_FETCH_SUCCEEDED,
    MANAGE_LEVEL,
    MANAGE_LEVEL_SUCCESS,
    CREATE_COUNTRY_LEVEL,
    CREATE_COUNTRY_LEVEL_SUCCESS,
    CLIENTS_COUNTRY_DATA_CLEARED,
    LEVELONE_DATA_CLEARED,
    LEVELTWO_DATA_CLEARED,
} from './ActionType'



export const clientsList = (payload) => ({
    type: CLIENTS_LIST,
    payload: payload,
});

export const clientsApiError = error => {
    return {
      type: API_ERROR,
      payload: error,
    };
  };

export const clientsListFetchSuccess = (payload) => {
    return{
    type: CLIENTS_LIST_FETCH_SUCCEEDED,
    payload: payload
}}

export const clientsListFetchMoreSuccess = (payload) => ({
    type: CLIENTS_LIST_FETCH_MORE_SUCCEEDED,
    payload: payload
})

export const clientsListFetchFails = (payload) => ({
    type: CLIENTS_LIST_FETCH_FAILED,
    payload: payload
})

export const clientsListDataClear = (payload) => {
    return{
    type: CLIENTS_LIST_FETCH_SUCCEEDED,
    payload: payload
}}

export const getMoreClientsList = (count,searchTerm) => {
    return{
    type: CLIENTS_LIST_FETCH_MORE_REQUESTED,
    payload: {count,searchTerm}
}}

export const getClearClientData = (payload) => ({
    type: CLIENTS_DATA_CLEARED,
    payload: payload
})
export const getClearCountryData = (payload) => ({
    type: CLIENTS_COUNTRY_DATA_CLEARED,
    payload: payload
})
export const getClearLevelOneData = (payload) => ({
    type: LEVELONE_DATA_CLEARED,
    payload: payload
})
export const getClearLevelTwoData = (payload) => ({
    type: LEVELTWO_DATA_CLEARED,
    payload: payload
})

export const getCreateClient = (client_name,primary_country_id,client_branch) => {
    return{
    type: CREATE_CLIENT,
    payload: {client_name,primary_country_id,client_branch}
}
};

export const clientCreateSuccess = (payload) => {
    // console.log(payload,'clientCreateSuccess');
    return{
    type: CLIENT_CREATE_SUCCESS,
    payload: payload}
}

//update success
export const getUpdateClient = (client_name,primary_country_id,client_branch,client_branch_array) => {
    return{
    type: UPDATE_CLIENT,
    payload: {client_name,primary_country_id,client_branch,client_branch_array}
}
};

export const clientUpdateSuccess = (payload) => {
    // console.log(payload,'clientUpdate Success');
    return{
    type: UPDATE_CLIENT_SUCCESS,
    payload: payload}
}


// client list sorting

export const clientsListSorting = (sort,record,key,country,record_count) => {
    // console.log(sort,record,key,country,'sort,record,key,country');
    return{
    type: CLIENTS_LIST_SORTING,
    payload: {sort,record,key,country,record_count}
}
};

export const clientsListSortingSuccess = (payload) => ({
    type: CLIENTS_LIST_SORTING_SUCCESS,
    payload: payload,
});

export const clientsListSortingFailure = (payload) => ({
    type: CLIENTS_LIST_SORTING_FAILURE,
    payload: payload,
});

//client country list
export const clientsCountryList = (sort,record,key,id,level) => ({
    type: CLIENT_COUNTRY_LIST,
    payload: {sort,record,key,id,level},
});

export const clientsCountryListFetchSuccess = (payload) => {
    return{
    type: CLIENT_COUNTRY_LIST_FETCH_SUCCEEDED,
    payload: payload
}}

//client country level one

export const clientsCountryLevelOneList = (sort,record,key,id,level) => ({
    type: CLIENT_COUNTRY_LEVEL_ONE_LIST,
    payload: {sort,record,key,id,level},
});

export const clientsCountryLevelOneListFetchSuccess = (payload) => {
    return{
    type: CLIENT_COUNTRY_LEVEL_ONE_LIST_FETCH_SUCCEEDED,
    payload: payload
}}

//client country level two

export const clientsCountryLevelTwoList = (sort,record,key,data,level) => ({
    type: CLIENT_COUNTRY_LEVEL_TWO_LIST,
    payload: {sort,record,key,data,level},
});

export const clientsCountryLevelTwoListFetchSuccess = (payload) => {
    return{
    type: CLIENT_COUNTRY_LEVEL_TWO_LIST_FETCH_SUCCEEDED,
    payload: payload
}}


//level name update
export const levelNameUpdate = (countryId,level,parrent,name) => ({
    type: LEVEL_NAME_UPDATE,
    payload: {countryId,level,parrent,name},
});

export const levelNameUpdateSuccess = (payload) => ({
    type: LEVEL_NAME_UPDATE_SUCCESS,
    payload: payload,
});

//category name update

export const categoryNameUpdate = (countryId,level,parrent,client_branch,name) => ({
    type: CATEGORY_NAME_UPDATE,
    payload: {countryId,level,parrent,client_branch,name},
});

export const categoryNameUpdateSuccess = (payload) => ({
    type: CATEGORY_NAME_UPDATE_SUCCESS,
    payload: payload,
});

//manage country

export const manageCountry = (payload) => ({
    type: MANAGE_COUNTRY,
    payload: payload,
});

export const manageCountrySuccess = (payload) => ({
    type: MANAGE_COUNTRY_SUCCESS,
    payload: payload,
});

//manage level

export const manageLevel = (client_id,client_country_id,level,parent_level_id) => {
    return {
    type: MANAGE_LEVEL,
    payload: {client_id,client_country_id,level,parent_level_id},
    }};

export const manageLevelSuccess = (payload) => ({
    type: MANAGE_LEVEL_SUCCESS,
    payload: payload,
});

//Create Country level

export const createCountryLevel = (payload) => ({
    type: CREATE_COUNTRY_LEVEL,
    payload: payload,
});

export const createCountryLevelSuccess = (payload) => ({
    type: CREATE_COUNTRY_LEVEL_SUCCESS,
    payload: payload,
});




 