//Dependencies
import React, { useEffect, useState } from "react";
import { DatePicker, Select } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";

//Libraries
import { toast } from "react-toastify";
import moment from 'moment';
import dayjs from 'dayjs';

//CSS
// import "../../index.css";

//images
import Country from "../../Images/world.png";
import DollarIcon from "../../Images/dollar.png";
import Calender from "../../Images/Group 194.svg";
import Star from "../../Images/Group 188.svg"
import AlertIcon from '../../Images/alert.svg'


// Components
import Button from "../../Components/Button";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import RouteSegments from "../../Routes/RouteSegments";
import Warning from "../../Components/Indications/Warning";
import CalenderIcon from "../../Components/CommonIcons/CalenderIcon";
import DownArrow from "../../Components/CommonIcons/DownArrow";
import BankIon from "../../Components/CommonIcons/BankIcon";
import WorldIcon from "../../Components/CommonIcons/WorldIcon";

//Redux Components
import { useDispatch, useSelector } from "react-redux";
import { countryList, currencyList } from "../../Store/Common/Action";
import { Baseurl, monthNames } from "../../Utils/Constants";

//entpoints
import { CLIENT_AVAILABILITY } from "../../Helpers/EndPoints";
import { getCreateClient, getUpdateClient, manageCountry } from "../../Store/action";
import ConfirmPopup from "../../Components/PopUp/ConfirmPopUp";

const schema = yup.object().shape({
  clientname: yup.string().required("Please enter clientname")
    .test('NameCheck', 'Client Name Already Exist', async function (value) {

      const isExist = value == "Axis" ? false : true;

      return isExist;
    }),
  country: yup.string().required("Please select country"),
  financialyear: yup.string().required("Please select date"),
});
let addCountry;
let addFinDate;
let addFinMonth;
let addCurrency;
let primaryCountries;
let primaryCurrency;
let primaryFinancialYear;


const NewClient = ({dynamicHeight}) => {
  const location = useLocation()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [clientname, setClientname] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState();
  const [financialyear, setFinancialyear] = useState("");
  const [submitIsFrom, setSubmitIsFrom] = useState("");
  const [step, setStep] = useState(1);
  const [clientError, setClientError] = useState("");
  const [redirectClient, setRedirectClient] = useState("");
  const [selectedClientDetails, setSelectedClientDetails] = useState([]);
  const [selectedClientDetailsUpdate, setSelectedClientDetailsUpdate] = useState([]);
  const [clientBranch, setClientBranch] = useState([]);
  const { Option } = Select;
  const [countryDropDown, setCountryDropDown] = useState();
  const [currencyDropDown, setCurrencyDropDown] = useState();
  const [existClientId, setExistClientId] = useState(null);
  const [isFromNew, setIsFromNew] = useState()
  const [primaryCountryId, setPrimaryCountryId] = useState();
  const [primaryCurrencyId, setPrimaryCurrencyId] = useState();
  const [primaryDate, setPrimaryDate] = useState();
  const [clientBranchIdArray, setClientBranchIdArray] = useState([]);
  const [undoChanges, setUndoChanges] = useState(false)
  const [disabledSave, setDisabledSave] = useState(true);
  const [isClicked, setIsClicked] = useState(false);


  const movePreviousPage = (redirectTo,isFrom) => {
    if(isFrom == 'exit'){
      if(step ==1 && clientname){
        setUndoChanges(true)
      }else if(step == 2){
        setUndoChanges(true)
      }else if(step > 1 && (financialyear || country || !disabledSave)){
        setUndoChanges(true)
      }else{
        navigate(redirectTo, { state: { setisNewClient: false, clientId: location?.state?.clientID, breadCrumbClientName: location?.state?.breadCrumbClientName } });
        setClientname('')
      }
    }else{
    navigate(redirectTo, { state: { setisNewClient: false, clientId: location?.state?.clientID, breadCrumbClientName: location?.state?.breadCrumbClientName } });
    setClientname('')
  }
  };


  useEffect(() => {
    primaryCountries = "";
    primaryCurrency = "";
    primaryFinancialYear = "";
    dispatch(countryList());
    dispatch(currencyList());
    setIsFromNew(location?.state?.isNewClient)
  }, [])
  useEffect(() => {
    if (location?.state?.isFrom) {
      dispatch(manageCountry(location?.state?.clientID))
      setStep(location?.state?.step)
    }
  }, [location?.state?.isFrom])


  const {
    register,
    handleSubmit,
    control,
    reset,
    setFocus,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    if (submitIsFrom == "proceed") {
      navigate(RouteSegments.CLIENTPAGE, { state: { isClient: true } });
    }
    else if (submitIsFrom == "another") {
      reset();
      setClientname("");
      setCountry("");
      setFinancialyear("");
    }
  };

  const { countries, currencies, isLoading, ManageCountryDetails } = useSelector(state => {
    return {
      countries: state.Common?.countries,
      currencies: state.Common?.currencies,
      isLoading: state?.Common?.isLoading,
      ManageCountryDetails: state?.Clients?.manageCountryDetails
    }
  })
  useEffect(() => {
    if (ManageCountryDetails && step == 3) {
      setClientname(ManageCountryDetails[0]?.client_name)
      setPrimaryCountryId(ManageCountryDetails[0]?.primary_country_id)
      const sampleArray = []
      const sampleArray1 = []
      const branchIdSampleArray = []

      ManageCountryDetails[0]?.clientBranch?.map((data, i) => {
        const country_id = data?.country_id;
        const currency_id = currencies?.find(d => d.currency == data?.currency)?.id;
        const currency = currencies?.find(d => d.currency == data?.currency)?.currency;
        const financial_year_end = data?.financial_year_end;
        const [date, month] = data?.financial_year_end.split('-');

        sampleArray?.push({
          country_id: country_id,
          currency_id: currency_id,
          financial_year_end: financial_year_end,
          month: month,
          date: date,
          is_primary: data?.is_primary,
          client_branch_id: data?.id ?? null
        })
        sampleArray1?.push({
          country_id: country_id,
          currency: currency,
          financial_year_end: financial_year_end,
          client_branch_id: data?.id ?? null
        })
        branchIdSampleArray.push(data?.id)
      })
      const uniqueClientBranch = Array.from(new Set(sampleArray.map(JSON.stringify))).map(JSON.parse);
      const uniqueClientBranch1 = Array.from(new Set(sampleArray1.map(JSON.stringify))).map(JSON.parse);
      const uniqueClientBranchID = [...new Set(branchIdSampleArray)];

      setClientBranch(uniqueClientBranch)
      // setSelectedClientDetails(uniqueClientBranch1)
      setSelectedClientDetailsUpdate(uniqueClientBranch1)
      setClientBranchIdArray(uniqueClientBranchID)
    }

  }, [ManageCountryDetails])


  const clietBranchCreate = () => {
    setClientBranch(prev => (
      [...prev,
      {
        country_id: addCountry ?? country,
        currency_id: addCurrency ?? currency,
        financial_year_end: addFinDate + "-" + addFinMonth ?? financialyear,
        month: addFinMonth,
        date: addFinDate,
        client_branch_id: ""
      }]))

    setSelectedClientDetails(prev => (
      [...prev,
      {
        country_id: addCountry ?? country,
        currency_id: addCurrency ?? currency,
        financial_year_end: addFinDate + "-" + addFinMonth ?? financialyear,
      }])
    )
    setSelectedClientDetailsUpdate(prev => (
      [...prev,
      {
        country_id: addCountry ?? country,
        currency: currency,
        financial_year_end: addFinDate + "-" + addFinMonth ?? financialyear,
        client_branch_id: ""
      }])
    )
   
    if(step < 3){
      setPrimaryCountryId(addCountry)
      setPrimaryCurrencyId(addCurrency)
      const val = (addFinDate + '/' + (parseInt(addFinMonth)) + '/' + '2024')
      isFinancialYearEndDate(val)
    }

    primaryCountries = primaryCountryId;
    primaryCurrency = primaryCurrencyId;
    primaryFinancialYear = primaryDate;
    
    reset({ financialyear: undefined, country: undefined, currency: undefined });
    setCountry('');
    setFinancialyear('');
    setCurrency(null)
    setStep(3)
    setDisabledSave(false)
  }


  const updateClientDetails = (isFrom, value, month) => {
    if (isFrom == 'date') {
      addFinDate = value;
      addFinMonth = month;
    } else if (isFrom == 'country') {
      addCountry = value;
      addCurrency = value;
    } else if (isFrom == 'currency') {
      addCurrency = value;
    }
  }

  const primaryCountry = clientBranch.find(data => data?.country_id === primaryCountryId) ?? {};

  // Find the index of the primary country
  const primaryCountryIndex = clientBranch.findIndex(data => data?.country_id === primaryCountryId);

  // Separate the primary country and the rest
  const restOfClientBranch = clientBranch.filter((_, index) => index !== primaryCountryIndex);

  // Sort the rest alphabetically by country name
  const sortedRest = restOfClientBranch.slice().sort((a, b) => {
    const countryNameA = countries.find(d => d.id === a.country_id)?.country_name || '';
    const countryNameB = countries.find(d => d.id === b.country_id)?.country_name || '';
    return countryNameA.localeCompare(countryNameB);
  });

  // Concatenate the primary country and the sorted rest
  const sortedClientBranch = Object.keys(primaryCountry).length > 0
    ? [primaryCountry, ...sortedRest]
    : sortedRest;

  const removeSelectedClientDetail = (countryId, client_branch_id) => {
    setClientBranch((prevClientBranch) => {
      return prevClientBranch.filter(data => data.country_id !== countryId);
    });

    setSelectedClientDetails((prevSelectedClientDetails) => {
      return prevSelectedClientDetails.filter(data => data.country_id !== countryId);
    });

    setClientBranchIdArray((prevClientBranchIdArray) => {
      return prevClientBranchIdArray.filter(id => id !== client_branch_id);
    });

    setSelectedClientDetailsUpdate((prevSelectedClientDetailsUpdate) => {
      return prevSelectedClientDetailsUpdate.filter(data => data.country_id !== countryId);
    });
    setDisabledSave(false)
  };

  const checkClientAvailability = () => {
    axios(`${Baseurl + CLIENT_AVAILABILITY}`, {
      method: "POST",
      data: {
        client_name: clientname
      },
      headers: {
        "Content-Type": "application/json",
        // "Authorization": getToken()
      },
    })
      .then((response) => {
        if (response?.data?.status == 200) {
          if (response?.data?.success) {
            setClientError('')
            setRedirectClient(response?.data?.redirectTo)
            setStep(2)
          }
        } else if (response?.data?.status == 205) {
          setClientError(response?.data?.message)
          setRedirectClient(response?.data?.redirectTo)
          setExistClientId(response?.data?.clienId)
          setStep(1)
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  }

  const createClients = (action) => {
    if (action == 'update') {
      dispatch(getUpdateClient(location?.state?.clientID, primaryCountryId, selectedClientDetailsUpdate, clientBranchIdArray))
    } else {
      dispatch(getCreateClient(clientname, primaryCountryId, selectedClientDetails))
    }
  }
  const { CreatedClientResponse } = useSelector(state => {
    return {
      CreatedClientResponse: state?.Clients?.CreatedClients,
    }
  })

  useEffect(() => {
    if (isFromNew) {
      if (CreatedClientResponse?.success) {
        setStep(3)
        toast.success(CreatedClientResponse?.message)
        if (location?.state?.isFrom == 'manageCountry') {
          movePreviousPage(RouteSegments.CLIENT_COUNTRIES)
        }
        else {
          movePreviousPage(RouteSegments.CLIENTPAGE)
        }

      }
    }

  }, [CreatedClientResponse])

  useEffect(() => {
    if(step ==1){
      reset({ financialyear: undefined, country: undefined, currency: undefined });
    }
  }, [step])

  const makePrimary = (data) => {
    setPrimaryCountryId(data.country_id)
    setPrimaryCurrencyId(data.currency_id)
    primaryCountries=data.country_id
    primaryCurrency=data.currency_id
    const val = (data.date + '/' + (parseInt(data.month)) + '/' + '2024')
    isFinancialYearEndDate(val)
    setDisabledSave(false)
  }

  const isFinancialYearEndDate = (val) => {

    // Check if the input value is a valid date in the format 'DD/M/YYYY'
    const isValidDate = moment(val, 'DD/M/YYYY', true).isValid() || moment(val).isValid();

    if (isValidDate) {
      const formattedDate = moment(val, 'DD/M/YYYY').format('DD/MM/YY');
      setPrimaryDate(formattedDate);
      primaryFinancialYear = formattedDate;
    } else {
      // Handle the case where the input value is not a valid date
      setPrimaryDate(val);
      console.error('Invalid date format:', val);
      // Optionally, you can set a default date or handle the error in another way.
      // setPrimaryDate(/* default date or null */);
    }
  };

  const handlePopup = (data)=>{
    if(data ==0){
      setUndoChanges(false)

    }else{
      const redirectTo = location?.state?.isFrom == 'manageCountry' ? RouteSegments.CLIENT_COUNTRIES : RouteSegments.CLIENTPAGE;
      navigate(redirectTo, { state: { setisNewClient: false, clientId: location?.state?.clientID, breadCrumbClientName: location?.state?.breadCrumbClientName } });
  }
}
console.log(undoChanges,'undoChanges');

  return (
    <>
    {undoChanges && 
        <ConfirmPopup 
        show={true} 
        message={'Are you sure you want cancel & exit the page?'} 
        activeBtnName={'Yes'} 
        closeBtnName='No' 
        onChange={(data)=>handlePopup(data)}
        activeBtnColor="#42B2AC"
        forWarning={true}
        icon={<img className='' src={AlertIcon} />}
        /> }
      <div className="w-full sticky bg-white">
        <div className="flex justify-between py-3 mx-[4rem]">
          <Button
            bgColor="#FBE5D6"
            borderType="dashed"
            label={"Cancel and exit"}
            color={"#ED7D31"}
            onClick={() => movePreviousPage(location?.state?.isFrom == 'manageCountry' ? RouteSegments.CLIENT_COUNTRIES : RouteSegments.CLIENTPAGE,'exit')}
            icon={"minus"}
          />
        </div>
      </div>
      <div style={{height:dynamicHeight-40+"px"}}>
      <div className={`pl-4 pr-4 flex justify-center items-center h-full w-full  ${step < 3 ? 'h-fit w-fit overflow-y-auto thin-scrollbar' : 'h-fit w-fit'} `}>
        <div className="bg-white shadow-[1px_1px_8px_1px_rgb(232,232,232)] rounded-lg w-[55%] border border-[#E1E1E1]">
          <div className="xl:px-[2rem] pt-[1.5rem] px-[1.5rem]">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="relative mb-4">
                <div className="absolute top-1/2 left-3 transform -translate-y-1/2 xl:w-4 w-3.5">
                  <BankIon bgColor={step == 3 ? '#ffffff' : '#707070'} />
                </div>
                <input
                  id="clientname"
                  name="clientname"
                  {...register("clientname")}
                  type="text"
                  value={clientname}
                  placeholder="Client name"
                  onChange={(e) => {
                    setClientname(e.target.value);
                  }}
                  className={`custom-select-height w-full text-[12px] xl:text-[14px] placeholder:text-[#BEBEBE] rounded-md px-0 xl:py-1.5 py-1 border ${!clientError ? 'border-greeny-blue focus:border focus:border-greeny-blue focus:outline-greeny-blue' : 'focus:outline-error-red'} ${step == 2 ? 'bg-[#F6F6F6] pointer-events-none' : step == 3 ? 'bg-greeny-blue pointer-events-none ' : 'bg-white'}  pl-[2.2rem] placeholder:tracking-[3px]
                    ${clientError ? "border !border-error-red" :
                      errors.clientname
                        ? errors.clientname.type == "required"
                          ? "border border-yellow"
                          : "border !border-error-red"
                        : ""
                    }`}
                  style={{ color: step == 3 ? 'white' : '#000000' }}
                  autoComplete="off"
                />
                {clientError && (
                  <div className="absolute top-1/2 right-3 transform -translate-y-1/2 w-4">
                    <Warning
                      circleBg={"#FF6174"}
                    />
                  </div>
                )}
              </div>
              {clientBranch?.length > 0 && step >= 2 &&
                <div className="border-b-2 border-flat-blue max-h-[4.5rem] xxl:max-h-[11rem] lxl:max-h-[12rem] 2xl:max-h-[15rem] 3xl:max-h-[22rem] overflow-y-auto space-y-2 mb-2 pb-2 thin-scrollbar">
                  <div className="">
                    {sortedClientBranch?.map((data, i) => {
                      const selectedCountryName = countries.find(d => d.id == data?.country_id)?.country_name;
                      const selectedCurrencyName = currencies?.find(d => d.id == data?.currency_id)?.currency;
                      const monthObject = monthNames.find(month => month.id == data?.month)?.value;
                      const formatedDate = data?.date + '-' + monthObject;
                      const isPrimary = data?.country_id == primaryCountryId;
                      return (
                        <div className={`grid grid-cols-6 items-center py-1 ${sortedClientBranch?.length - 1 == i ? '' : 'border-b-2 border-[#E1E1E1]'}`} key={i}>
                          <div className={`flex items-center gap-2 col-span-2 `}>
                            <WorldIcon color={'#4472C4'} className={'lg:w-[15px] lg:h-[15px] w-[20px]'} />
                            <div className="flex gap-1">
                              <p className="text-flat-blue text-[13px] xl:text-[14px]">{selectedCountryName}</p>
                              {isPrimary || clientBranch?.length == 1 ?
                                <img src={Star} alt="selected" className="w-3" /> : <></>
                              }
                            </div>
                          </div>
                          <p className="text-flat-blue col-span-1 text-center text-[13px] xl:text-[14px">{selectedCurrencyName}</p>
                          <p className="text-flat-blue col-span-1 text-center text-[13px] xl:text-[14px">{formatedDate}</p>
                          {!isPrimary ?
                            <div className="flex gap-2 items-center col-span-2 justify-center">
                              <Button bgColor="#FFC048"
                                borderType="normal"
                                label={"Make primary"}
                                color={"#FFFFFF"}
                                onClick={() => makePrimary(data)}
                              />
                              <p className="underline text-error-red text-[12px] xl:text-[14px] text-right cursor-pointer" onClick={() => removeSelectedClientDetail(data.country_id, data?.client_branch_id)}>Remove</p>
                            </div>
                            : <></>
                          }
                        </div>
                      )
                    })}

                  </div>
                </div>
              }
              {step == 3 &&
                <p className="xl:text-[14px] text-[13px] text-greeny-blue xl:leading-[30px] leading-[25px]">
                  Add more countries
                </p>

              }
              {step > 1 &&
                <>
                  <div className="">
                    <div className="relative mb-4 country">
                      {!countryDropDown &&
                        <img
                          src={Country}
                          className="absolute top-1/2 left-3 transform -translate-y-1/2 w-4 z-10"
                          alt="CountryIcon"
                        />}
                      <Controller
                        control={control}
                        name="country"
                        id="Country"
                        render={({ field }) => {
                          return (
                            <Select
                              showSearch
                              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              value={(step == 2 && primaryCountries)? primaryCountries : field.value}
                              ref={field.ref}
                              name={field.name}
                              onBlur={field.onBlur}
                              {...register("country")}
                              onChange={(e) => {
                                field.onChange(e);
                                updateClientDetails('country', e, null);
                                setCountry(e);
                                setCurrency(currencies?.find(d => d.id == e)?.currency)
                                if (clientBranch?.length == 0) {
                                  setPrimaryCountryId(e)
                                  setPrimaryCurrencyId(e)
                                  primaryCountries = e;
                                  primaryCurrency = e;
                                }
                              }}
                              onDropdownVisibleChange={(visible) => setCountryDropDown(visible)}
                              suffixIcon={<DownArrow color="#cad3d0" width={'12px'} height={'12px'} className={countryDropDown ? 'transition-transform transform rotate-180' : ''} />}
                              placeholder="Select country"
                              className={"custom-select-height border-[2px]  text-[11px] xl:text-[12px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"}
                              status={errors.clientname && errors.clientname.type == "NameCheck" ? "error" : ""}
                              style={{
                                width: "100%",
                                border: " 2px solid #00A7B5",
                                // height: '2.2rem',
                                borderRadius: '4px'
                              }}
                            >
                              {countries?.map((country) => (
                                <Option key={country.id} value={country.id} disabled={sortedClientBranch?.find(d => d?.country_id == country?.id)}>
                                  {country.country_name}
                                </Option>
                              ))}
                            </Select>
                          );
                        }}
                      />

                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-3">
                    <div className="relative mb-4 country">
                      {!currencyDropDown &&
                        <img
                          src={DollarIcon}
                          className="absolute top-1/2 left-3 transform -translate-y-1/2 w-4 z-10"
                          alt="DollarIcon"
                        />}
                      <Controller
                        control={control}
                        name="currency"
                        id="currency"
                        render={({ field }) => {
                          return (
                            <Select
                              showSearch
                              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                              value={(step == 2 && primaryCurrency)? primaryCurrency : currency}
                              ref={field.ref}
                              name={field.name}
                              onBlur={field.onBlur}
                              {...register("currency")}
                              onChange={(e) => {
                                field.onChange(e);
                                updateClientDetails('currency', e, null);
                                setCurrency(currencies?.find(d => d.id == e)?.currency)
                                if (clientBranch?.length == 0) {
                                  setPrimaryCurrencyId(e)
                                  primaryCurrency = e;
                                }
                              }}
                              onDropdownVisibleChange={(visible) => setCurrencyDropDown(visible)}
                              suffixIcon={<DownArrow color="#cad3d0" width={'12px'} height={'12px'} className={currencyDropDown ? 'transition-transform transform rotate-180' : ''} />}
                              placeholder="Currency"
                              className={"custom-select-height border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"}
                              status={errors.clientname && errors.clientname.type == "NameCheck" ? "error" : ""}
                              style={{
                                width: "100%",
                                border: " 2px solid #00A7B5",
                                // height: '2.2rem',
                                borderRadius: '4px'
                              }}
                            >
                              {currencies?.map((currency) => (
                                <Option key={currency.id} value={currency.id}>
                                  {currency.currency}
                                </Option>
                              ))}
                            </Select>
                          );
                        }}
                      />

                    </div>

                    <div className="relative mb-4">
                      <img
                        src={Calender}
                        className="absolute top-1/2 left-3 transform -translate-y-1/2 w-3.5 z-10"
                        alt="Calender"
                      />
                      <Controller
                        control={control}
                        name="financialyear"
                        render={({ field }) => {
                          return (
                            <DatePicker
                              key={clientBranch?.length}
                              id={"Financialyear"}
                              value={(step == 2 && primaryFinancialYear)? dayjs(primaryFinancialYear, 'DD/MM/YY') : field.value}
                              ref={field.ref}
                              name={field.name}
                              onBlur={field.onBlur}
                              showSearch={false}
                              allowClear={false}
                              placement="bottomLeft"
                              size="small"
                              className="custom-select-height pl-[2.2rem]"
                              style={{
                                width: "100%",
                                border: " 2px solid #00A7B5",
                                // height: '2.2rem',
                                borderRadius: '4px',
                              }}
                              showToday={false}
                              placeholder="Select year-end"
                              {...register("financialyear", { required: "Please select date!" })}
                              onChange={(date) => {
                                field.onChange(date)
                                setFinancialyear(date.$D + '-' + date.$M + 1);
                                if (clientBranch?.length == 0) {
                                  const val = (date.$D + '/' + (parseInt(date.$M) + 1) + '/' + date.$y)
                                  isFinancialYearEndDate(val)
                                }
                                updateClientDetails('date', date.$D, date.$M + 1)
                              }
                              }
                              dropdownClassName="custom-date-picker"
                              format={"DD-MMM"}
                              suffixIcon={<CalenderIcon color="#707070" height={'15px'} width={'15px'} />}
                            />
                          );
                        }}
                      />
                    </div>
                  </div>
                </>
              }
              {step == 3 &&
                <div className="float-right">
                  <Button bgColor="#00A7B5"
                    borderType="normal"
                    label={'Add'}
                    color={"#ffffff"}
                    size={'md'}
                    // dispatch(createClient())
                    onClick={() => clietBranchCreate(2, null, null)}
                    disabled={financialyear == '' || country == ''}
                  />
                </div>
              }
              {clientError && redirectClient &&
                <div className="flex space-x-3  justify-between items-center mt-[1rem] mb-[2rem] ">
                  {clientError && redirectClient ?
                    <div className="flex space-x-2 items-center">
                      <p className="text-error-red text-[14px]">{clientError}</p>
                      <NavLink to={RouteSegments.CLIENT_COUNTRIES} state={{ clientId: existClientId, name: clientname, breadCrumbClientName: clientname }}>
                        <p className="text-greeny-blue text-[15px] underline">{redirectClient}</p>
                      </NavLink>
                    </div>
                    :
                    errors
                      ?
                      <p className="text-error-red text-sm">{errors?.clientname?.message}</p>
                      :
                      <></>
                  }
                </div>
              }
              <div className={`flex ${location?.state?.isFrom == 'manageCountry' ? 'justify-end' : 'justify-between'} mb-5 items-center ${step == 3 ? 'mt-12' : ""}`}>
                {location?.state?.isFrom != 'manageCountry' &&
                  <div className="flex gap-1">
                    {step > 1 &&
                      <div className="flex gap-1">

                        <p className="text-greeny-blue text-[12px] xl:text-[14px] cursor-pointer tracking-[0px]" onClick={() => { setStep(1); setClientBranch([]); setSelectedClientDetails([]) }}> <span className="underline">Step1</span>&gt;&gt;</p>
                        {step == 3 &&
                          <p className="text-greeny-blue text-[12px] xl:text-[14px]  cursor-pointer tracking-[0px]" onClick={() => { setStep(2); setClientBranch([]); setSelectedClientDetails([]) }}> <span className="underline">Step2</span>&gt;&gt;</p>
                        }
                      </div>
                    }
                    <p className="text-dark-blue text-[12px] xl:text-[14px] ">
                      Step {step} of 3
                    </p>
                  </div>}
                  <Button
                    bgColor="#00A7B5"
                    borderType="normal"
                    label={step === 3 ? "Save & exit" : "Continue"}
                    color="#ffffff"
                    type="submit"
                    size={step === 3 ? 'xl' : 'lg'}
                    onClick={
                        step === 1
                            ? checkClientAvailability
                            : step === 3
                                ? () => {setIsClicked(true);createClients(location?.state?.isFrom === 'manageCountry' ? 'update' : 'create')}
                                : () => clietBranchCreate(2, null, null)
                    }
                    disabled={
                        step === 1
                            ? !clientname
                            : step === 2
                                ? !primaryFinancialYear || !primaryCountries || !clientname || !primaryCurrency
                                : step === 3 &&
                                   financialyear || country || isClicked
                                   ? true 
                                    :
                                    disabledSave   

                    }
                  />

              </div>
            </form>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};
export default NewClient;