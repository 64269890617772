import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { downloadExport, exceptionAnalysisProceed, fetchColumnListDatatype, fetchUnexpecredValuesDetail, getCalculationFormData, getDataImputationList, getNodeWiseCategoryList, selectCurrentNodeData, selectCurrentNodeIds, unexpectedValuesNodeSave } from '../../Store/Workflow/WorkflowSlice'
import DropDown from '../DropDown/DropDown'
import { parseCategotyValueLabel } from '../../Helpers/utils'
import { useFormik } from 'formik'
import DataTableSkeleton from '../Skeleton/DataTableSkeleton'
import Button from '../Button'
import { TextInput } from '../SelectInput'
import { createColumnHelper } from '@tanstack/react-table'

import grayicon from '../../Images/grayicon.svg';
import rediocn from '../../Images/redicon.svg';
import blueicon from '../../Images/blueicon.svg';
import { CircularProgress } from '@mui/material'
import downloadingIcon from '../../Images/gif/downloading.gif'
import excelicon from '../../Images/excel.svg';
import useDataTable from '../../hooks/useDataTable'
import ProceedButton from '../CommonComponents/ProceedButton'
import DataTable from '../Table/DataTable'
import XlsIcon from '../../Images/excel.svg'
import EditIcon from '../../Images/Group 809.svg'
import SummaryTable from '../Table/SummaryTable'
import blockeditiocn from '../../Images/blockediticon.svg';
import { actions } from 'react-table'
import { UNEXPECTED_VALUES_EXPORT } from '../../Helpers/EndPoints'

const columnHelper = createColumnHelper()

const RowWiseDownload = ({ workflow_id, ecl_node_id, category_id, column_name, method, condition, disabled }) => {

    const [isDownloading, setisDownloading] = useState(false)

    const handleExportRow = async () => {
        setisDownloading(true);          
        const request = {
            workflow_id,
            ecl_node_id,
            category_id,
            column_name,
            method,
            condition,
            is_export: true,
        };
        await downloadExport(request, UNEXPECTED_VALUES_EXPORT);
        setisDownloading(false);
    };
    return isDownloading
    ? <img src={downloadingIcon} className='w-6' />
    : <img alt='' onClick={handleExportRow} src={excelicon} className={`${disabled ? 'pointer-events-none color-change-image':'cursor-pointer'} w-4 h-4 xl:w-5 xl:h-5`}/>
}


const TreatAsExceptionActionButton = ({ actions, onClick }) => {

    let btnClassName = ""

    if (actions === "ignored") {
        btnClassName = "pointer-events-none text-[#AFABAB]"
    } else if (actions === "selected") {
        btnClassName = "pointer-events-none text-[#2F5597]"
    } else {
        btnClassName = "cursor-pointer text-[#FF6174]"
    }


    return <button className={`flex gap-2 underline items-center ${btnClassName}`} onClick={onClick}>
        <img src={actions === 'ignored' ? grayicon : actions === 'selected' ? blueicon : rediocn} className='w-3 h-3' alt="selected" />
        Treat as exception
    </button>
}



const IgnoreActionButton = ({ actions, onClick }) => {

    let btnClassName = ""

    if (actions === "ignored") {
        btnClassName = "pointer-events-none text-[#2F5597]"
    } else if (actions === "selected") {
        btnClassName = "cursor-pointer text-[#FF6174]"
    } else {
        btnClassName = "pointer-events-none text-[#FF6174]"
    }


    return <button className={`flex gap-2 underline items-center ${btnClassName}`} onClick={onClick}>
        <img src={actions === 'ignored' ? blueicon : actions === 'selected' ? rediocn : rediocn} className='w-3 h-3' alt="selected" />
        Ignore
    </button>
}

const initialInputState = {
    "column_name": undefined,
    "condition": undefined,
    "method": undefined,
    "input": undefined,
    "actions": undefined,
    "data_imputation_method": null,
}

function UnexpectedValues() {

    const location = useLocation()
    const dispatch = useDispatch()
    const nodeWiseCategory = useSelector(state => state?.Workflow?.nodeWiseCategory)
    const currentNodeData = useSelector(selectCurrentNodeData)
    const isDataLoading = useSelector(state => state?.Workflow?.ui?.isDataLoading)
    const isCalculating = useSelector(state => state?.Workflow?.ui?.isCalculating)
    const filesMerging = useSelector(state => state?.Workflow?.ui?.filesMerging)
    const isProceedLoading = useSelector(state => state?.Workflow?.ui?.isProceedLoading)
    const reduxState = useSelector(state => state)
    const { currentEclNodeId, currentWorkflowId, currentWorkflowNodeId } = useSelector(selectCurrentNodeIds)
    const dependentDropwdownList = useSelector(state => state?.Workflow?.columnList ?? [])
    const isAlreadySavedOnce = !currentNodeData?.unexpectedValuesTableData?.unexpectedValueDatas?.filter(item => item?.actions === "selected" || !item?.actions)?.filter(item => !item?.data_imputation_method)?.length > 0
    const [editVisible, setEditVisible] = useState(isAlreadySavedOnce);
    const [showColumnEdit, setShowColumnEdit] = useState(!editVisible)
    const [selectedRowData, setSelectedRowData] = useState(null)
    const [unexpectedValueDatas, setUnexpectedValueDatas] = useState([])

    const [inputValues, setInputValues] = useState(initialInputState)

    const formik = useFormik({
        initialValues: {
            category: currentNodeData?.nodeCategoryId,
            dataInputationList: currentNodeData?.unexpectedValuesTableData?.excepNullValueDatas?.map(data => ({ type: data?.data_imputation_method }))
        },
        enableReinitialize: true,
    })

    const { dataTableColumns,
        getFilterOptions,
        summaryData,
        tableState,
        refreshDataTable,
        onTableStateChange,
        isExportLoading,
        donwloadXl,
        isDataLoading: isMergeSummaryLoading,
    } = useDataTable({ category_id: formik.values.category, ecl_node_id: currentEclNodeId, workflow_id: currentWorkflowId, from: "unexpectedValues" })


    const dataImputationdList = useSelector(state => {
        return state?.Workflow?.dataImputationdList?.map(data => ({
            label: data?.name,
            value: data?.id,
            data,
        }));
    });

    useEffect(() => {
        if (currentNodeData?.unexpectedValuesTableData?.unexpectedValueDatas?.length > 0) {
            const isAlreadySavedOnce = !currentNodeData?.unexpectedValuesTableData?.unexpectedValueDatas?.filter(item => item?.actions === "selected" || !item?.actions)?.filter(item => !item?.data_imputation_method)?.length > 0
            setEditVisible(isAlreadySavedOnce);
            setShowColumnEdit(!isAlreadySavedOnce)
            setUnexpectedValueDatas(currentNodeData?.unexpectedValuesTableData?.unexpectedValueDatas?.map(data => ({ ...data })))
        } else {
            setEditVisible(false);
            setShowColumnEdit(false)
        }
    }, [currentNodeData?.unexpectedValuesTableData?.unexpectedValueDatas])




    useEffect(() => {
        dispatch(getNodeWiseCategoryList({
            workflow_id: currentWorkflowId,
            ecl_node_id: currentEclNodeId,
        }))
    }, [currentEclNodeId, currentWorkflowId, dispatch])



    useEffect(() => {
        if (currentNodeData?.unexpectedValuesTableData?.unexpectedValueDatas || currentNodeData?.id) {
            refreshDataTable()
        }
        if (currentNodeData?.id) {            
            dispatch(getDataImputationList({
                "workflow_id": currentWorkflowId,
                "ecl_node_id": currentEclNodeId,
                "category_id": formik.values.category
            }
            ))
            dispatch(fetchUnexpecredValuesDetail({
                "workflow_id": currentWorkflowId,
                "ecl_node_id": currentEclNodeId,
                "category_id": formik.values.category
            }
            ))
            .then(response => {
                if (response.type == `${fetchUnexpecredValuesDetail.fulfilled}`) {
                    dispatch(fetchColumnListDatatype({
                        "workflow_id": currentWorkflowId,
                        "ecl_node_id": currentEclNodeId,
                        "category_id": currentNodeData?.nodeCategoryId,
                        "data_type": ["Integer"],
                    }
                    ))
                }
            })
        }
    }, [])

    const onCategoryChange = (value) => {
        formik.setFieldValue("category", value)
    }

    const onCategorySave = () => {
        dispatch(fetchUnexpecredValuesDetail({
            "workflow_id": currentWorkflowId,
            "ecl_node_id": currentEclNodeId,
            "category_id": formik.values.category
        }
        )).then(response => {
            if (response.type == `${fetchUnexpecredValuesDetail.fulfilled}`) {
            dispatch(fetchColumnListDatatype({
                "workflow_id": currentWorkflowId,
                "ecl_node_id": currentEclNodeId,
                "category_id": formik.values.category,
                "data_type": ["Integer"],
            }
            ))
        }})
        dispatch(getDataImputationList({
            "workflow_id": currentWorkflowId,
            "ecl_node_id": currentEclNodeId,
            "category_id": formik.values.category
        }
        ))
        refreshDataTable()
    }

    const onInputValueChange = (name, value) => {
        setInputValues(prev => ({
            ...prev,
            [name]: value
        }))
    }


    const onValueChange = (name, value) => {
        onInputValueChange(name, value)

        if (name == "method") {
            onInputValueChange("input", "")
        }

        if (name == "column_name") {
            onInputValueChange("input", "")
        }
    }

    const onSave = (from) => {
        const requestFormData = getCalculationFormData({
            client_branch_level_id: location?.state?.levelTwoCardId,
            isFrom: "unexpectedValues",
            category_id: currentNodeData?.nodeCategoryId,
            node_id: currentEclNodeId,
            state: reduxState,
            nodeStatus: "Pending",

        })

        dispatch(unexpectedValuesNodeSave({
            requestFormData,
            request: {
                "ecl_workflow_id": currentWorkflowId,
                "ecl_node_id": currentEclNodeId,
                "ecl_category_id": currentNodeData?.nodeCategoryId,
                "unexpectedDatas": from === "imputation"
                    ? unexpectedValueDatas?.map(data => ({
                        "actions": data?.actions,
                        "column_name": data?.column_name,
                        "condition": data.condition,
                        "method": data.method,
                        "input": data.input,
                        "data_imputation_method": data?.data_imputation_method ? data?.data_imputation_method : "null"
                    }))
                    : [
                        {
                            "column_name": inputValues?.column_name,
                            "condition": inputValues.condition,
                            "method": inputValues.method,
                            "input": inputValues.input,
                            "data_imputation_method": null
                        }
                    ]

            }
        }))
            .then((response) => {
                if (response.type === `${unexpectedValuesNodeSave.fulfilled}`) {
                    dispatch(fetchUnexpecredValuesDetail({
                        "workflow_id": currentWorkflowId,
                        "ecl_node_id": currentEclNodeId,
                        "category_id": currentNodeData?.nodeCategoryId
                    }))
                    refreshDataTable()
                    setInputValues(initialInputState)
                    setSelectedRowData(null)
                }
            })

    }

    const conditionOptions = useMemo(() => {
        const conditionDatas = currentNodeData?.unexpectedValuesTableData?.conditionDatas

        if (!conditionDatas) return []

        return Object.keys(conditionDatas)?.map((key) => ({
            label: conditionDatas[key],
            value: key,
            disabled: inputValues.column_name
                ? currentNodeData?.unexpectedValuesTableData?.unexpectedValueDatas?.some(data => data?.column_name == inputValues.column_name && data?.condition == key)
                : false
        }))
    }, [currentNodeData?.unexpectedValuesTableData, inputValues.column_name])


    const methodOptions = useMemo(() => {
        const methodDatas = currentNodeData?.unexpectedValuesTableData?.methodDatas
        if (!methodDatas) return []
        return Object.keys(methodDatas)?.map((key) => ({
            label: methodDatas[key],
            value: key,
        }))
    }, [currentNodeData?.unexpectedValuesTableData?.methodDatas])


    const renderInputs = () => {
        if (isDataLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }
        if (!currentNodeData?.nodeCategoryId || editVisible) return


        const disableSave = !inputValues.column_name || !inputValues.condition || !inputValues.input || !inputValues.method

        return <div className='mt-4'>
            <div className='grid grid-cols-4 gap-4'>
                <DropDown
                    dropDownOptions={dependentDropwdownList?.map(data => ({
                        label: data?.column_name,
                        value: data?.column_name,
                    })) ?? []}
                    placeholder={'Select Column Name'}
                    name={'usedCategory'}
                    ordinary={true}
                    disabled={location?.state?.isfromHistory || isCalculating || selectedRowData?.column_name}
                    customTags={false}
                    noDataFountMessage={'File must have integer column'}
                    value={inputValues?.column_name ? inputValues?.column_name : undefined}
                    onOptionChange={(value) => onValueChange("column_name", value)}
                />
                <DropDown
                    dropDownOptions={conditionOptions}
                    placeholder={'Select condition'}
                    name={'usedCategory'}
                    ordinary={true}
                    disabled={location?.state?.isfromHistory || isCalculating}
                    customTags={false}
                    value={inputValues?.condition ? inputValues?.condition : undefined}
                    onOptionChange={(value) => onValueChange("condition", value)}
                />
                <DropDown
                    dropDownOptions={methodOptions}
                    placeholder={'Select Comparison method'}
                    name={'usedCategory'}
                    ordinary={true}
                    disabled={location?.state?.isfromHistory || isCalculating}
                    customTags={false}
                    value={inputValues?.method ? inputValues?.method : undefined}
                    onOptionChange={(value) => onValueChange("method", value)}
                />

                {!inputValues?.method
                    ? null
                    : inputValues?.method == "another_column"
                    ? <DropDown
                        dropDownOptions={dependentDropwdownList?.map(data => ({
                            label: data?.column_name,
                            value: data?.column_name,
                            disabled: inputValues?.column_name == data?.column_name
                        })) ?? []}
                        placeholder={'Select Comparison column'}
                        name={'usedCategory'}
                        ordinary={true}
                        disabled={location?.state?.isfromHistory || isCalculating}
                        customTags={false}
                        value={inputValues?.input ? inputValues?.input : undefined}
                        onOptionChange={(value) => onValueChange("input", value)}
                    />
                    : <TextInput
                        placeholder="Enter the value"
                        className={'place text-[12px] 2xl:text-[13px] custom-select-height'}
                        value={inputValues?.input}
                        onChange={(e) => onValueChange("input", e.target.value)}
                    />}
            </div>
            <div className="mt-4 flex justify-center">
                <Button
                    bgColor='#42B2AC'
                    borderType=""
                    disabled={disableSave}
                    label={'Save'}
                    color={'#ffffff'}
                    onClick={()=>onSave('category')}
                    size={'md'} />
            </div>
        </div>
    }


    const handleIgnoreClick = (data) => {
        setUnexpectedValueDatas(prev => prev.map(prevData => {
            if (prevData.id === data.id) {
                return { ...prevData, actions: 'ignored', data_imputation_method: null };
            }
            return prevData;
        }))
    }

    const handleTreatAsExceptionClick = (data) => {
        setUnexpectedValueDatas(prev => prev.map(prevData => {
            if (prevData.id === data.id) {
                return { ...prevData, actions: 'selected' };
            } else if (prevData.column_name === data.column_name) {
                return { ...prevData, actions: 'ignored', data_imputation_method: null };
            }
            return prevData;
        }))
    }

    const handleUndoClick = (data) => {
        setUnexpectedValueDatas(prev => prev.map(prevData => {
            if (prevData.column_name === data.column_name) {
                return { ...prevData, actions: '', data_imputation_method: null };
            }
            return prevData;
        }))
    }

    const onInputationChange = (data, value) => {
        setUnexpectedValueDatas(prev => prev.map(prevData => {
            if (prevData.id === data.id) {
                return { ...prevData, data_imputation_method: value };
            }
            return prevData;
        }))
    }

    const onEditClick = () => {
        setEditVisible(false)
        setShowColumnEdit(true)
    }

    const columnEditClick = (data) => {
        setShowColumnEdit(false)
        setSelectedRowData({ ...data })
        setInputValues(prev => ({
            ...data,
            condition: undefined,
            input: undefined,
            method: undefined,
        }))
    }


    const unexpectedTableColumns = useMemo(() => {
        return [
            columnHelper.accessor("column_name", {
                header: "Column name",
                mergeCells: true,
                tdClassName: "align-top",
                cell: ({ getValue, row }) => {
                    return <div className='flex'>
                        {getValue()}
                        {showColumnEdit && (
                            <img src={blockeditiocn} className='wxl:w-4 2xl:h-4 w-3 h-3 cursor-pointer ml-2' alt="Edit" onClick={() => columnEditClick(row.original)} />
                        )}
                    </div>
                }
            }),
            columnHelper.accessor("condition_label", {
                header: "Condition"
            }),
            columnHelper.accessor("method_label", {
                header: "Method"
            }),
            columnHelper.accessor("input", {
                header: "Input",
            }),
            columnHelper.accessor("no_of_unexpected_values", {
                header: "No. of unexpected values",
            }),
            columnHelper.accessor("percentage_of_unexpected_values", {
                header: "Percentage of unexpected values",
                cell: ({ row }) => (<span>{row.original?.percentage_of_unexpected_values ? row.original?.percentage_of_unexpected_values + " %"  : '-'}</span>)

            }),
            columnHelper.display({
                id: "actionsBtn",
                header: "Actions",
                thClassName: "min-w-[14rem] 2xl:min-w-[16rem] 2xl:max-w-[17rem] w-[15rem]",
                cell: ({ row }) => (
                    <div className={`flex text-[12px] md:text-[10px] xl:text-[12px] gap-5 whitespace-nowrap items-center`}>
                        {
                            !editVisible && <>
                                <TreatAsExceptionActionButton
                                    actions={row.original.actions}
                                    onClick={() => handleTreatAsExceptionClick(row.original)}
                                />
                                <IgnoreActionButton
                                    actions={row.original.actions}
                                    onClick={() => handleIgnoreClick(row.original)}
                                />
                            </>
                        }
                        <RowWiseDownload workflow_id={currentWorkflowId} ecl_node_id={currentEclNodeId} category_id={currentNodeData?.nodeCategoryId} column_name={row.original.column_label} method={row.original.method} condition={row.original.condition} disabled={row.original.no_of_unexpected_values ==0}/>
                    </div>
                ),
            }),
            columnHelper.display({
                id: "dataInputationModel",
                header: "Data inputation model",
                thClassName: "w-[14rem]",
                cell: ({ row }) => {
                    return (
                        row.original.actions === 'selected' ? (
                            <div className='w-[13rem]'>
                                {!editVisible
                                    ? <DropDown
                                        dropDownOptions={dataImputationdList?.map(data => {
                                        const dataTypes = data?.data?.data_type.split(',');
                                        return {
                                        label: data?.label,
                                        value: data?.value,
                                        disabled: data?.value == 1 
                                            ? !dataTypes.includes(row.original.column_data_type) 
                                            : (data?.value !== 4 && data?.value !== 5 && data?.value !== 7)
                                        };
                                    })}
                                        placeholder={'Select any operation'}
                                        name={'dataInputationList'}
                                        ordinary={true}
                                        disabled={false}
                                        customTags={false}
                                        value={row.original?.data_imputation_method ? row.original?.data_imputation_method : undefined}
                                        onOptionChange={(value) => onInputationChange(row.original, value)}
                                    />
                                    : <p>{dataImputationdList?.find(element => element.value == row.original?.data_imputation_method)?.label || null}</p>}
                            </div>
                        ) :
                            row.original.actions === 'ignored'
                                ? <div className={`text-[12px] md:text-[10px] xl:text-[12px]`}>
                                    <span className="text-[#FF6174]">ignored / </span>
                                    <span className={`text-[#00B0F0] ${editVisible ? "pointer-events-none" : "cursor-pointer"}`} onClick={() => handleUndoClick(row.original)}>undo</span>
                                </div>
                                : <>-</>


                    );
                },
            }),
        ]
    }, [showColumnEdit, dataImputationdList, editVisible])


    const unexpectedTableData = useMemo(() => {
        return unexpectedValueDatas ?? []
    }, [unexpectedValueDatas])

    console.log(unexpectedValueDatas,'unexpectedValueDatas');


    const onProceedClick = () => {

        const requestFormData = getCalculationFormData({
            client_branch_level_id: location?.state?.levelTwoCardId,
            isFrom: "unexpectedValues",
            category_id: formik?.values?.category,
            node_id: currentEclNodeId,
            state: reduxState,
            nodeStatus: "Completed",
            data_imputation_ids: [...new Set(unexpectedValueDatas?.filter(data =>data?.data_imputation_method !== null )?.map(list =>
                dataImputationdList?.find(imputationData => (imputationData.value == list?.data_imputation_method) && (list?.data_imputation_method !=null))?.data?.ecl_node_id))].filter(id => id !== null)
        })
        // data_imputation_ids: [...new Set(formik.values.dataInputationList?.map(list =>
        //     dataImputationdList?.find(imputationData => imputationData.value == list?.type)?.data?.ecl_node_id))].filter(id => id !== null)
        // })

        dispatch(exceptionAnalysisProceed({
            requestFormData,
            workflow_id: currentWorkflowId,
            notOpenBuilder:true,      
        })).then(res => {
            if (res.type == `${exceptionAnalysisProceed.fulfilled}`) {
                dispatch(fetchUnexpecredValuesDetail({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": formik.values.category
                }
                ))
                refreshDataTable()
            }
        })
    }

    const renderUnexpectedTable = () => {
        if (!currentNodeData?.nodeCategoryId) return

        if (isDataLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }
        if (currentNodeData?.unexpectedValuesTableData?.unexpectedValueDatas?.length > 0) {
            const disableSave = unexpectedValueDatas?.some(unexpectedData => {
                const colData = unexpectedValueDatas?.filter(data => data?.column_name == unexpectedData?.column_name);
                return colData?.filter(data => !data?.data_imputation_method)?.length == colData?.length
            })
            return <>
                <>
                    <div className=' bg-[#2B144C] px-2 grid grid-cols-3 items-center mt-4'>
                        <div className='flex gap-3 items-center py-2 col-span-1'>
                            <button className='text-[#ffffff]  py-0.5 px-2 text-[12px] bg-[#FFC048] rounded-[4px] whitespace-nowrap'>Data category</button>
                        <p className='font-semibold text-[#FFFFFF] text-[10px] whitespace-nowrap'>{currentNodeData?.unexpectedValuesTableData?.categoryName}</p>
                        </div>
                        <h2 className='text-white text-[12px] font-semibold text-center w-full col-span-1'>Calculated output</h2>
                        {currentNodeData?.node_status == "Completed"
                            ? null
                            : editVisible && <div className={`col-span-1 flex gap-1 items-center justify-end mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={onEditClick}>
                                <img alt='' src={EditIcon} className='w-4 h-4' />
                                <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
                            </div>}
                    </div>
                </>
                <div className={`max-h-[200px] overflow-y-auto overflow-x-auto  thin-scrollbar`}>
                    <SummaryTable
                        columns={unexpectedTableColumns}
                        data={unexpectedTableData}
                        className='!table-auto'
                        thClassName='!text-[10px] 2xl:text-[14px]'
                        tdClassName='!text-[10px] 2xl:text-[14px]'

                    />
                </div>
                {!editVisible && <div className='flex justify-center my-4'>
                    <Button
                        bgColor='#42B2AC'
                        borderType=""
                        disabled={disableSave}
                        label={'Save'}
                        color={'#ffffff'}
                        onClick={() => onSave("imputation")}
                        size={'md'} />
                </div>}
            </>
        }
    }

    const renderResultTable = () => {

        if (!currentNodeData?.nodeCategoryId) return

        if (isDataLoading || isMergeSummaryLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        const mergeSummaryTableData = summaryData

        console.log({ summaryData });

        return <div className='mt-5'>

            <div className='flex justify-end items-center'>
                {isExportLoading
                    ? <img src={downloadingIcon} className='w-6 mr-2' />
                    : <img alt='' src={XlsIcon} className=' mb-2 mr-2 object-cover cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
            </div>

            <DataTable
                columns={dataTableColumns}
                data={mergeSummaryTableData?.columnDatas ?? []}
                onTableStateChange={onTableStateChange}
                totalCount={mergeSummaryTableData?.totalRecords}
                isOptionsDisabled={location?.state?.isfromHistory}
                getFilterOptions={getFilterOptions}
                tableState={{
                    pagination: {
                        pageIndex: tableState.pageIndex,
                        pageSize: tableState.pageSize,
                    },
                    sorting: tableState?.sortBy ? [
                        {
                            id: tableState?.sortBy,
                            desc: tableState?.sortOrder === "desc"
                        }
                    ] : [],
                    columnFilters: tableState.columnFilters
                        ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                        : [],
                    columnPinning: tableState.columnPinning,
                    columnVisibility: tableState?.columnVisibility,
                }}
            />
            {currentNodeData?.unexpectedValuesTableData?.isEdit == false || currentNodeData?.node_status == 'Completed'
                ? null
                : <div className={`float-right 2xl:my-6 my-3 ${location?.state?.isfromHistory ? 'hidden' : 'block'}`}>
                    <ProceedButton
                        isLoading={isProceedLoading}
                        onClick={onProceedClick}
                    />

                </div>}
        </div>
    }


    const disableCategory = location?.state?.isfromHistory || isDataLoading || currentNodeData?.nodeCategoryId
    return (
        filesMerging || isCalculating
            ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            : <div className='my-2'>
                {editVisible && currentNodeData?.unexpectedValuesTableData?.isEdit
                    ? null
                    : <div className='flex gap-5'>
                        <DropDown
                            dropDownOptions={parseCategotyValueLabel(nodeWiseCategory)}
                            placeholder={'Select data category'}
                            name={'usedCategory'}
                            ordinary={true}
                            disabled={disableCategory}
                            customTags={false}
                            value={formik.values.category}
                            onOptionChange={onCategoryChange}
                        />
                        <Button
                            bgColor='#42B2AC'
                            borderType=""
                            disabled={disableCategory || !formik.values.category}
                            label={'Save'}
                            color={'#ffffff'}
                            onClick={onCategorySave}
                            size={'md'} />
                    </div>}
                {renderInputs()}
                {renderUnexpectedTable()}
                {renderResultTable()}
            </div>
    )
}

export default UnexpectedValues