import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';

const DateChart = ({ isDayBased, data }) => {
  const { 'x-axis': xAxisCategories, rangeCounts } = data;
  let monthOrDayData;
  const keys = isDayBased ? ['Sun','Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'] : ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const yAxisLabels = ['75-100', '50-75', '25-50', '0-25'];
  const colors = ['#0080ff', '#0000ff', '#8000ff', '#ff00ff', '#ff0080', '#80ff00', '#ff8000', '#00ffff', '#254058', '#54869e', '#385668', '#FF33FF'];
  const valueFormatter = (value) => value ? `${value}%`:undefined
  
    const series = keys
    .filter(key => xAxisCategories.some(year => (rangeCounts[year] || {})[key] !== undefined))
    .map((key, index) => ({
      data: xAxisCategories.map(year => {
         monthOrDayData = (rangeCounts[year] || {})[key];
        if (monthOrDayData) {
          return `${monthOrDayData?.percentage}`
        } else {
          return undefined;
        }
      }),
      label: key,
      id: key,
      color: colors[index],
      valueFormatter
    }));
    
  return (
    <BarChart
      // width={700}
      maxBarSize={2}
      // height={250}
      series={series}
      xAxis={[{ data: xAxisCategories.map(String), scaleType: 'band', categoryGapRatio: 0.1, barGapRatio: 0.1 }]}
      // yAxis={[{ scaleType: 'band', data: yAxisLabels,}]}   
      slotProps={{
        legend: {
          direction: 'column',
          position: { vertical: 'top', horizontal: 'right' },
          padding: 0,
          itemMarkWidth: 10,
          itemMarkHeight: 10,
          markGap: 4,
          itemGap: 7,
          labelStyle: {
            fontSize: 12,
            fill: '#000000',
          },
        },
      }}
    />
  );
};

export default DateChart;
