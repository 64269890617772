import React from 'react'

const FileSelectIon = ({bgColor,width,height,className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 30.074 26">
  <g id="Group_205" data-name="Group 205" transform="translate(549.482 -185.467)">
    <path id="Path_169" data-name="Path 169" d="M-521.661,191.761c.165.031.314.048.458.087a1.7,1.7,0,0,1,1.244,1.974q-1.046,5.664-2.1,11.326c-.246,1.326-.511,2.65-.727,3.981a2.145,2.145,0,0,1-.651,1.427,1.638,1.638,0,0,1-1.061.408l-.114,0q-11.284,0-22.569,0a1.715,1.715,0,0,1-1.776-1.432,1.644,1.644,0,0,1-.028-.337q0-10.7,0-21.4a1.736,1.736,0,0,1,1.82-1.817c2.044,0,4.088.008,6.132-.007a1.465,1.465,0,0,1,1.252.594c.3.4.584.813.846,1.239a.969.969,0,0,0,.937.514c4.854-.019,9.709-.013,14.563-.011a1.71,1.71,0,0,1,1.728,1.351,2.278,2.278,0,0,1,.041.506C-521.658,190.689-521.661,191.218-521.661,191.761Zm-11.33,1.194h-11.1c-.62,0-.722.084-.836.7q-.582,3.145-1.162,6.291-.7,3.757-1.4,7.514c-.1.529-.2,1.057-.3,1.586a.585.585,0,0,0,.637.774c.066,0,.133,0,.2,0h22.089c.663,0,.751-.06.874-.716q.738-3.908,1.462-7.818.7-3.743,1.389-7.487c.114-.609-.072-.844-.7-.844Zm10.182-1.177c0-.507,0-.978,0-1.449,0-.7-.169-.875-.863-.875-4.873,0-9.746-.006-14.618.008a1.57,1.57,0,0,1-1.336-.657c-.144-.187-.272-.386-.405-.581-.2-.3-.389-.605-.612-.885a.555.555,0,0,0-.374-.2q-3.08-.018-6.16-.006a.584.584,0,0,0-.644.63c0,.057,0,.114,0,.17V202.6a.164.164,0,0,0,.084-.113q.8-4.355,1.6-8.711a7.371,7.371,0,0,1,.142-.75,1.777,1.777,0,0,1,1.878-1.245h21.306Z" fill={bgColor} stroke={bgColor} strokeWidth="1"/>
  </g>
</svg>

  )
}

export default FileSelectIon
