import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useFormik } from 'formik';
//components
import DataTableSkeleton from '../Skeleton/DataTableSkeleton';
import DropDown from '../DropDown/DropDown';
import Button from '../../Components/CommonComponents/Button';
import DataTable from '../Table/DataTable';

import { exceptionAnalysisProceed, getCalculationFormData, getDataImputationList, getNodeWiseCategoryList, getOutlierdata, getSelectedMethodBasedData, selectCurrentNodeData, selectCurrentNodeIds } from '../../Store/Workflow/WorkflowSlice';
import { getOutLierMethods } from '../../Helpers/ApiHelpers';
import useDataTable from '../../hooks/useDataTable';

//imgaes
import XlsIcon from '../../Images/excel.svg'
import downloadingIcon from '../../Images/gif/downloading.gif'
import SelectMethod from '../Methods/SelectMethod';
import MethodTable from '../Methods/MethodTable';
import { toast } from 'react-toastify';
import ProceedButton from '../CommonComponents/ProceedButton';

const Outlier = ({}) => {
    
    const currentNodeData = useSelector(selectCurrentNodeData)
    const{nodeWiseCategory,filesMerging,outlierColumns,selectedMethodBasedData,isTableDataLoading}= useSelector(state => ({      
        nodeWiseCategory : state?.Workflow?.nodeWiseCategory,
        filesMerging : state?.Workflow?.ui?.filesMerging,
        outlierColumns: state?.Workflow?.outlierColumns,
        selectedMethodBasedData: state?.Workflow?.selectedMethodBasedData,
        isTableDataLoading: state?.Workflow?.ui?.isDataLoading

    }))
    const dataImputationdList = useSelector(state => {
      return state?.Workflow?.dataImputationdList?.map(data => ({
        label: data?.name,
        value: data?.id,
        data,
        disabled: data?.id != 4
      }))
    })
  
    const isCalculating = useSelector(state => state?.Workflow?.ui?.isCalculating)
    const { currentEclNodeId, currentWorkflowId, currentWorkflowNodeId } = useSelector(selectCurrentNodeIds)
    const isProceedLoading = useSelector(state => state?.Workflow?.ui?.isProceedLoading)


    const formik = useFormik({
        initialValues: {
          category: currentNodeData?.nodeCategoryId ?? undefined,
          outLierMethod: undefined,
          columnName: undefined,
          dataInputationList: currentNodeData?.outLierTableData?.outlierAnalysisDatas?.map(data => ({ type: data?.data_imputation_method }))

        },
        enableReinitialize: true,
    })   
    const location = useLocation();
    const dispatch = useDispatch();
    const reduxState = useSelector(state => state)
    const [showEdit, setShowEdit] =useState(false);
    const [outLierMethods,setOutLierMethods] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(currentNodeData?.nodeCategoryId ?? undefined);
    const [showFields,setShowFields] = useState(false)
    const [selectedColumnName, setSelectedColumnName] = useState('');
    const [selectedMethod, setSelectedMethod] = useState('');
    const [selectedMethodId, setSelectedMethodId] = useState();
  const [allData, setAllData] = useState({})
  const [isColumnDisable, setIsColumnDisable] = useState(false)

    const { dataTableColumns,
        getFilterOptions,
        summaryData,
        tableState,
        refreshDataTable,
        onTableStateChange,
        isDataLoading,
        isExportLoading,
        donwloadXl,
      } = useDataTable({ category_id: selectedCategory, ecl_node_id:currentEclNodeId , workflow_id:currentWorkflowId, from:"outlierAnalysis" })

    const onCategoryChange = (value) => {
        formik.setFieldValue("category", value);
        formik.setFieldValue("columnName", undefined);
        formik.setFieldValue("outLierMethod", undefined);
        setSelectedCategory(value);
    }
  const onMethodChange = async (value) => {
    const option = outLierMethods?.find(method => method?.id == value)
    const methodName = option?.name
    const id = option?.id
    formik.setFieldValue("outLierMethod", id)
        setSelectedMethod(methodName);
        setSelectedMethodId(id)
        const requestData = {
            workflow_id: currentWorkflowId,
            ecl_node_id: currentEclNodeId,
            category_id: selectedCategory,
            column_name:selectedColumnName,
            selected_method:parseInt(id)
        }
        dispatch(getSelectedMethodBasedData(requestData))

    }   

  const onColumNameChange = async (value) => {
    const option = outlierColumns?.find(col => col?.column_name == value)
    const columnName = option?.column_name
    const dataType = option?.data_type

      setSelectedColumnName(columnName);
    
      // Clear the method field in formik
      formik.setFieldValue("outLierMethod", undefined);
      formik.setFieldValue("columnName", columnName);
      
      setSelectedMethod(undefined);
    
      try {
        const response = await getOutLierMethods({ data_type: dataType });
        if (response?.data?.status === 200) {
          setOutLierMethods(response?.data?.outlierMethods);
        }
      } catch (error) {
        console.error("Error fetching outlier methods", error);
      }
    };
  console.log({ formik });

    useEffect(() => {   
        dispatch(getNodeWiseCategoryList({workflow_id:currentWorkflowId ,ecl_node_id:currentEclNodeId,workflow_node_id:currentWorkflowNodeId})) 
        dispatch(getDataImputationList({
          "workflow_id": currentWorkflowId,
          "ecl_node_id": currentEclNodeId,
          "category_id": formik.values.category
        }
        ))   
      },[]);

      const getTableData = async(isFrom) => {           
        const requestData = {
            workflow_id: currentWorkflowId,
            ecl_node_id: currentEclNodeId,
            category_id: selectedCategory
        }
      const responsedata = await dispatch(getOutlierdata({requestData,isFrom:isFrom}))
      refreshDataTable()
      formik.setFieldValue("outLierMethod", undefined);
      if(responsedata?.payload?.outlierData?.isEdit == false){
        setShowFields(true)
      }      
      };
      

        useEffect(() => {
          if (currentNodeData?.id && currentNodeData?.nodeCategoryId) {
            getTableData('edit')
                                  
          }
        }, [currentNodeData?.nodeCategoryId])
        
        const summaryTableData = useMemo(() => {
          if(currentNodeData?.outLierTableData?.isEdit){
            setShowFields(currentNodeData?.outLierTableData?.dataImputationMethodIdresults?.length <= 0)
            setShowEdit(currentNodeData?.outLierTableData?.isEdit)
          }
          return currentNodeData?.outLierTableData ?? []
        }, [currentNodeData?.outLierTableData])

        const onProceedClick = () => {
          if(formik.values.dataInputationList?.length > 0){
          const requestFormData = getCalculationFormData({
            client_branch_level_id: location?.state?.levelTwoCardId,
            isFrom: "EA",
            category_id: formik?.values?.category,
            node_id: currentEclNodeId,
            nodeStatus:"Completed",
            state: reduxState,
            data_imputation_ids: [...new Set(
              formik.values.dataInputationList?.map(list =>
                dataImputationdList?.find(imputationData => imputationData.value == list?.type)?.data?.ecl_node_id
              )
            )].filter(id => id)
            
          })
      
          dispatch(exceptionAnalysisProceed({
            requestFormData,
            workflow_id: currentWorkflowId,
            notOpenBuilder: true,
          }))
        }else{
          toast.error('Current step must be completed first')
        }
        }   

  const onCoulmnEditClick = (dataList, columnLabel) => {
    setAllData({})
    onColumNameChange(columnLabel)
    setIsColumnDisable(true)
  }

  return (
    <div className='h-full overflow-y-auto thin-scrollbar relative '>   
    {
      filesMerging ?
      <div className='mt-2'>
      <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
      </div>
    :  
    (
        <div className={`${currentNodeData?.outLierTableData?.dataImputationMethodIdresults?.length > 0 ? 'hidden':'block'} flex space-x-4 mt-4`}>          
          <DropDown          
              dropDownOptions={nodeWiseCategory?.map((obj) => ({
                label: obj?.category_name,
                value: obj?.category_id,
                disabled: obj?.is_disabled
            }))}
              placeholder={'Select data category'}
              name={'usedCategory'}
              ordinary={true}
              disabled={location?.state?.isfromHistory || currentNodeData?.nodeCategoryId}
              customTags={false}
              value={formik.values?.category}
              onOptionChange={onCategoryChange}
           />
          <Button            
            className={'bg-[#47B4AE] text-white px-[1.5rem] 2xl:px-[1.5rem] py-1.5 rounded-[5px]'}                  
            label={'Save'}            
            onClick={()=>getTableData('cateSave')}
            disabled={!selectedCategory || currentNodeData?.nodeCategoryId}
          />
        </div>
    )      
    }

    <div className={`${showFields ? 'block': 'hidden'} flex justify-between w-full gap-6 my-6`}>
        <DropDown   
            key={1}       
            dropDownOptions={outlierColumns?.length > 0 ? outlierColumns?.map(option => ({
            label: `${option?.column_name}`,
              value: `${option?.column_name}`,
            })) : []}
            placeholder={'Select column name'}
            name={'columnName'}
            value={formik.values?.columnName}
            ordinary={true}
          disabled={location?.state?.isfromHistory || isColumnDisable}
            customTags={false}
          onOptionChange={onColumNameChange}
        />
        <DropDown    
            key={1}                          
            dropDownOptions={outLierMethods ? outLierMethods?.map(option => ({
            label: `${option.name}`,
              value: `${option.id}`,
              disabled: summaryTableData?.outlierAnalysisDatas?.filter(data => data?.column_name == formik.values?.columnName)?.some(method => `${method?.method_label}-${method?.method}` == `${option.name}-${option.id}`)

            })) : []}
            placeholder={'Select method'}
            name={'outLierMethod'}
          value={formik.values.outLierMethod ? `${formik.values.outLierMethod}` : undefined}            
            ordinary={true}
            disabled={location?.state?.isfromHistory}
            customTags={false}
            onOptionChange={onMethodChange}
        />
    </div>

      {!summaryTableData?.outlierAnalysisDatas?.length > 0 && <div className={`${selectedMethod ? 'block' : 'hidden'}`} >
        <SelectMethod
          columnName={selectedColumnName}
          setAllData={setAllData}
          methodId={selectedMethodId}
          selectedMethodData={selectedMethodBasedData ?? {}}
          // saveMethod={saveMethod}
          method={selectedMethod}
        afterMethodSave={(e)=>setSelectedMethod(e)}
          workflow_id={currentWorkflowId}
          ecl_node_id={currentEclNodeId}
        category_id={selectedCategory}
        />
      </div>}
    {summaryTableData?.outlierAnalysisDatas?.length > 0 ?
    isTableDataLoading ? 
      null
      :
    <div className='my-8 w-full'>
      <MethodTable
        onCoulmnEditClick={onCoulmnEditClick}
        afterMethodSave={(e) =>{
          setSelectedMethod(e)
          formik.setFieldValue("columnName",undefined)
          formik.setFieldValue("outLierMethod",undefined)
        }}
        setAllData={setAllData}
        allData={allData}
        setIsColumnDisable={setIsColumnDisable}
        tableData={summaryTableData}
        setSelectedMethod={setSelectedMethod}
        selectedMethod={selectedMethod}
        selectedMethodId={selectedMethodId}
        selectedColumnName={selectedColumnName}
        key={summaryTableData?.outlierAnalysisDatas?.length}
        workflow_id={currentWorkflowId} 
        ecl_node_id={currentEclNodeId} 
        category_id={selectedCategory}        
        setShowFields={setShowFields}
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        selectedMethodData={selectedMethodBasedData ?? {}} 

        />
      </div>
    :""}

    {(summaryData?.columnDatas?.length > 0 || isDataLoading || filesMerging || isCalculating || isTableDataLoading)  &&
            isDataLoading || isTableDataLoading
            ? <div className='mt-2'>
              <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            </div>
            : 
            summaryData?.columnDatas?.length > 0 ?
            <>
            <div className={`flex justify-end items-center`}>
                  {isExportLoading
                    ? <img src={downloadingIcon} className='w-6 mr-2' />
                    : <img src={XlsIcon} className=' mb-2 mr-2  object-cover cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
            </div>
              <DataTable
                columns={dataTableColumns}
                data={summaryData?.columnDatas ?? []}
                onTableStateChange={onTableStateChange}
                totalCount={summaryData?.totalRecords}
                isOptionsDisabled={location?.state?.isfromHistory}
                getFilterOptions={getFilterOptions}
                tableState={{
                  pagination: {
                    pageIndex: tableState.pageIndex,
                    pageSize: tableState.pageSize,
                  },
                  sorting: tableState?.sortBy ? [
                    {
                      id: tableState?.sortBy,
                      desc: tableState?.sortOrder === "desc"
                    }
                  ] : [],
                  columnFilters: tableState.columnFilters
                    ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                    : [],
                  columnPinning: tableState.columnPinning,
                  columnVisibility:tableState?.columnVisibility,
                }}
              />             
          {currentNodeData?.outLierTableData?.isEdit == false || currentNodeData?.outLierTableData?.dataImputationMethodIdresults?.length ==0 
          ? null
          : <div className={`float-right 2xl:my-6 my-3 ${location?.state?.isfromHistory || currentNodeData?.node_status == 'Completed' ? 'hidden' : 'block'}`}>
                <ProceedButton
                  isLoading={isProceedLoading}
                  onClick={onProceedClick}
                />               
          </div>}
            </>
            :
            <></>
          }

      
    </div>
  )
}

export default Outlier
