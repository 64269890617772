import React, { useEffect, useState } from 'react'
import Button from '../../Components/Button'
import RouteSegments from '../../Routes/RouteSegments';
import { useLocation, useNavigate } from 'react-router-dom';
import CategoryIcon from '../../Components/CommonIcons/CategoryIcon';
import { useDispatch, useSelector } from 'react-redux';
import { createCountryLevel, getCategoryList, manageLevel } from '../../Store/action';
import { Baseurl } from '../../Utils/Constants';
import { CHECK_REMOVABLE_CATEGORY, MANAGE_CATEGORY, MANAGE_CATEGORY_AVAILABILITY } from '../../Helpers/EndPoints';
import axios from 'axios';
//image
import EditIcon from '../../Images/editIcon.svg'
import Delete from '../../Images/delete.svg'
import AddNull from '../../Images/addNull.svg'
import AddNullDisabled from '../../Images/addNullDisabled.svg'
import BreadcrumbArrow from '../../Images/Group 499.svg'
import AlertIcon from '../../Images/alert.svg'
import ConfirmPopup from '../../Components/PopUp/ConfirmPopUp';
import { toast } from 'react-toastify';

const ManageCategory = () => {
  const [categoryError, setCategoryError] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryNameList, setCategoryNameList] = useState([]);
  const [dispalyCategoryList,setDispalyCategoryList] = useState([]);
  const [disabledSave, setDisabledSave] = useState(true);
  const [editableCategoryId, setEditableCategoryId] = useState(null)
  const [isedit, setIsEdit] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [undoChanges, setUndoChanges] = useState(false);
  const [disableAdd, setDisableAdd] = useState(true)


  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const movePreviousPage = (step) => {
    const hasEmptyId = categoryNameList?.some(item => item?.id == "");
    const hasDelete  = categoryNameList?.some(item => item?.is_deleted== true);

    if(hasEmptyId || isedit || hasDelete || categoryName){
      setUndoChanges(true)
    } else{
    navigate(RouteSegments.LEVEL_TWO, {
      state: {
        clientId: location?.state?.clientID,
        labelName: location?.state?.labelName,
        data: location?.state?.data,
        breadCrumbClientName: location?.state?.breadCrumbClientName,
        breadCrumbCountryName: location?.state?.breadCrumbCountryName,
        client_country_id: location?.state?.client_country_id,
      }
    })}
  }

  const removeCategoryName = async (id, name, index) => {
    if(dispalyCategoryList?.length <=1){
      toast.warning('Atleast one category is mandatory');
    }else{
 
    if (id) {
      axios(`${Baseurl+ CHECK_REMOVABLE_CATEGORY}`, {
        method: "POST",        
        data:{
          "category_id":id,
          "client_branch_level_two_id":location.state?.client_branch_level_two_id,       
       }
      })
        .then((response) => {
          if (response?.data?.status == 200) {            
            const data = categoryNameList?.map(item => {
              if (item.id == id) {
      
                return { ...item, is_deleted: true };
              } else {
                return item;
              }
            })
            setDisabledSave(false)
            // const filteredData = data.filter(item => !item.is_deleted);
            setCategoryNameList(data)
            // setDispalyCategoryList(filteredData)
          } else if (response?.data?.status == 205) {
            toast.error(response?.data?.message)
          }
        })
        .catch((error) => {
          console.log("API not working", error);
        })
      
      // getPrepareCategory(data)
    } else if (index >= 0 && index < categoryNameList.length) {
      const updatedList = [...categoryNameList];
      updatedList.splice(index, 1); // Remove the item at the specified index
      setCategoryNameList(updatedList);
    } else {
      console.error("Invalid id or index provided.");
    }
  }

  }
  const createCategory = (e) => {
    e.preventDefault();
    getPrepareCategory(categoryNameList)
  };
  const editCategory = (name, id, index) => {
    setEditableCategoryId(id)
    setIsEdit(true)
    setCategoryName(name);
    setEditIndex(index)
  };
  const addNullValue = (name, id) => {
    navigate(RouteSegments.MANAGE_NULL_VALUES,
      {
        state: {
          clientID: location?.state?.clientID,
          data: location?.state?.data,
          labelName: location?.state?.labelName,
          breadCrumbClientName: location?.state?.breadCrumbClientName,
          breadCrumbCountryName: location?.state?.breadCrumbCountryName,
          level_two_name: location?.state?.level_two_name,
          categoryName: name,
          category_id: id,
          client_branch_level_two_id: location.state?.client_branch_level_two_id

        }
      })
  }
  const getPrepareCategory = (categoryNameList) => {
    axios(`${Baseurl+ MANAGE_CATEGORY}`, {
      method: "POST",
      data: {
        "client_id": location?.state?.clientID,
        "client_branch_level_one_id": location?.state?.data?.id,
        "client_branch_level_two_id": location.state?.client_branch_level_two_id,       
        "category_datas": categoryNameList

      }
    })
      .then((response) => {
        if (response?.data?.status == 200) {
          dispatch(getCategoryList(location?.state?.clientID, location.state?.client_branch_level_two_id))
          setIsEdit(false)
          setEditableCategoryId(null)
          setCategoryName('')
          setDisabledSave(true)
        } else if (response?.data?.status == 205) {
          setCategoryError(response?.data?.message)
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      })
  }
  useEffect(() => {
    dispatch(getCategoryList(location?.state?.clientID, location.state?.client_branch_level_two_id))
  }, [])

  const { CategoryListData } = useSelector(state => {
    return {
      CategoryListData: state?.ModelBuilder?.categoryListData,
    }
  })
  useEffect(() => {
    if (CategoryListData) {
      setCategoryNameList(CategoryListData)
    }
  }, [CategoryListData])

  const checkCategoryName = (id, isEdit, indexOfItem) => {
    var hasMatch = false;
    for (var index = 0; index < dispalyCategoryList?.length; ++index) {
      var data = dispalyCategoryList[index];
     
      if(isEdit && id !=''){ 
          if((indexOfItem == index) && data?.category_name.toLowerCase() == categoryName.toLowerCase()) 
            hasMatch = false;
          // break;      
      }else if(isEdit && id==''){
          if(indexOfItem == index) {
            if(data?.category_name.toLowerCase() == categoryName.toLowerCase()){
              hasMatch = false;
            }
          }else{
            if (data?.category_name.toLowerCase() == categoryName.toLowerCase()) {
              hasMatch = true;
              break;
            }
          }

      }else{
      if (data?.category_name.toLowerCase() == categoryName.toLowerCase()) {
        hasMatch = true;
        break;
      }
    }
    }
    axios(`${Baseurl + MANAGE_CATEGORY_AVAILABILITY}`, {
      method: "POST",
      data: {
        "category_id": id ?? "",
        "client_branch_level_one_id": location?.state?.data?.id,
        "category_name": categoryName
      },
      headers: {
        "Content-Type": "application/json",
        // "Authorization": getToken()
      },
    })
      .then((response) => {
        if (response?.data?.status == 200) {
          if (response?.data?.success) {
            if (hasMatch == false) {
              setCategoryError('')
              if (isedit) {
                if (id) {
                  const data = categoryNameList?.map(item => {
                    if (item.id == id) {
                      return { ...item, category_name: categoryName };
                    } else {
                      return item;
                    }
                  })
                  setCategoryNameList(data)
                  setDisabledSave(false)
                  setIsEdit(false)
                } else if (indexOfItem >= 0 && indexOfItem < categoryNameList.length) {
                  const updatedList = [...categoryNameList];
                  updatedList[indexOfItem].category_name = categoryName; // Rename the item at the specified index
                  setCategoryNameList(updatedList);
                  setDisabledSave(false)
                  setIsEdit(false)
                } else {
                  console.error("Invalid id or index provided.");
                }
                // setIsEdit(false)
              } else {
                setCategoryNameList(prev => [...prev, { id: '', category_name: categoryName, is_deleted: false }])
                // setIsEdit(false)
                setDisabledSave(false)
              }
              setCategoryName('')
            }
            else {
              setCategoryError('The document category name already exists')
            }

          }
        } else if (response?.data?.status == 205) {
          setCategoryError(response?.data?.message)
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });

  }

  const handlePopup = (data)=>{
    if(data ==0){
      setUndoChanges(false)

    }else{
      navigate(RouteSegments.LEVEL_TWO, {
        state: {
          clientId: location?.state?.clientID,
          labelName: location?.state?.labelName,
          data: location?.state?.data,
          breadCrumbClientName: location?.state?.breadCrumbClientName,
          breadCrumbCountryName: location?.state?.breadCrumbCountryName,
          client_country_id: location?.state?.client_country_id,
        }
      })}    
  }

  useEffect(()=>{
    if(categoryName){
      setDisableAdd(false)
    }else{
      setDisableAdd(true)
    }
  },[categoryName])

  useEffect(()=>{
    if(categoryNameList){
      const filteredData = categoryNameList.filter(item => !item.is_deleted);
      setDispalyCategoryList(filteredData)
    }
  },[categoryNameList])

  console.log(dispalyCategoryList,'dispalyCategoryList');
  
  return (
    <>
        {undoChanges && 
        <ConfirmPopup 
        show={true} 
        message={'Are you sure you want cancel & exit the page?'} 
        activeBtnName={'Yes'} 
        closeBtnName='No' 
        onChange={(data)=>handlePopup(data)}
        activeBtnColor="#42B2AC"
        forWarning={true}
        icon={<img className='' src={AlertIcon} />}
        /> }

      <div className="w-full  bg-white">
        <div className="py-3 mx-[4rem]">
          <Button
            bgColor="#FBE5D6"
            borderType="dashed"
            label={"Exit"}
            color={"#ED7D31"}
            onClick={movePreviousPage}
            icon={"minus"}
          />
          <div className='flex gap-2 items-center mt-3'>
            <p className='text-[#5B9BD5] text-[13px] cursor-pointer underline' onClick={() => movePreviousPage()}>{location?.state?.level_two_name}</p>
            <img src={BreadcrumbArrow} alt='breadcrumb' className='w-3' />
            <p className='text-[#000000] text-[13px]'>{"Document Category"}</p>
          </div>
        </div>
      </div>
      <div className={`pl-4 pr-4 flex justify-center items-center'}`}>
        <div className="xl:px-[3rem] px-[2rem] 2xl:py-[2.5rem] py-[1.5rem] bg-white shadow-[1px_1px_5px_1px_rgb(232,232,232)] rounded-lg min-h-fit  max-h-80 xlg:max-h-fit w-[55%] border border-[#E1E1E1]">
          <p className='text-[#4F49DF] font-semibold text-[14px] xl:text-[15px] mb-3'>Deﬁne data category</p>
          <div className="">
            <div className='space-y-2 max-h-[5rem] lg:max-h-[6rem] xlg:max-h-[7rem] 2xl:max-h-[20rem] 3xl:max-h-[25rem] overflow-y-auto thin-scrollbar'>
              {dispalyCategoryList?.map((data, i) => {
                return (
                  <div className='border-b-2 border-[#E1E1E1] flex items-center justify-between' key={i}>
                    <p className='text-[#37384C] text-[12px] xl:text-[14px] pb-2'>{data?.category_name}</p>
                    <div className='flex gap-1'>
                      <div className='flex gap-1'>
                        <img src={data?.id ? AddNull : AddNullDisabled} alt='edit' className='xl:w-[0.8rem] w-[0.6rem]' />
                        <p className={`underline ${data?.id ? 'text-[#5B9BD5] cursor-pointer' : 'text-[#D0CECE] cursor-not-allowed'} text-[12px] xl:text-[14px] text-right mr-2`} onClick={() => data?.id ? addNullValue(data?.category_name, data?.id) : '/#'}>Define null values</p>
                      </div>
                      <div className='flex gap-1'>
                        <img src={EditIcon} alt='edit' className='xl:w-[0.8rem] w-[0.6rem]' />
                        <p className="underline  text-[#5B9BD5] xl:text-[14px] text-[12px] text-right cursor-pointer mr-2" onClick={() => editCategory(data?.category_name, data?.id, i)}>Edit</p>
                      </div>
                      <div className='flex gap-1'>
                        <img src={Delete} alt='delete' className='xl:w-[0.7rem] w-[0.6rem]' />
                        <p className="underline text-[#B70000] xl:text-[14px] text-[12px] text-right cursor-pointer mr-2" onClick={() => removeCategoryName(data?.id, data?.category_name, i)}>Remove</p>
                      </div>
                    </div>
                  </div>
                  
                )
              })}
            </div>
            <form onSubmit={(e) =>{setDisabledSave(true);createCategory(e, categoryNameList)}}>
              <div className="relative mt-4">
                <div className="absolute top-1/2 left-3 transform -translate-y-1/2 w-4">
                  <CategoryIcon bgColor={'#707070'} width={'15px'} height={'15px'} />
                </div>
                <input
                  id="categoryName"
                  name="categoryName"
                  type="text"
                  placeholder="Please enter category name"
                  onChange={(e) => {
                    setCategoryName(e.target.value);
                    setCategoryError(false)
                  }}
                  value={categoryName}
                  className={`w-full xl:text-[14px] text-[12px] placeholder:text-[#BEBEBE] placeholder:tracking-[1px] rounded-md px-0 xl:py-1.5 py-[0.3rem] border-2 ${!categoryError ? 'border-greeny-blue focus:border focus:border-greeny-blue focus:outline-greeny-blue' : 'border-error-red focus:outline-error-red'} pl-[2.2rem] placeholder:tracking-[3px]`}
                  autoComplete="off"
                />
              </div>
              {categoryError &&
                <p className="text-error-red text-[14px] pt-1">{categoryError}</p>
              }
              <div className='mt-4 flex justify-end items-center'>                
                <div className='space-y-2'>
                  <div className='flex justify-end'>
                    <Button bgColor="#00A7B5"
                      borderType="normal"
                      label={"Add"}
                      color={"#ffffff"}
                      type={'button'}
                      onClick={() =>{  setDisableAdd(true); checkCategoryName(isedit ? editableCategoryId : null, isedit, editIndex)}}
                      size={'md'}
                      disabled={!categoryName || disableAdd}
                      />
                  </div>
                  <Button bgColor="#00A7B5"
                    borderType="normal"
                    label={"Save"}
                    color={"#ffffff"}
                    type={'submit'}
                    size={'lg'}
                    disabled={categoryName || disabledSave}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ManageCategory
