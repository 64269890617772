import React from 'react'

const Button = ({onClick,type,disabled,className,label,icon}) => {
    return (
        <button className={`${className} flex items-center gap-2 justify-center whitespace-nowrap ${disabled ? 'cursor-not-allowed bg-[#D0CECE]' :'cursor-pointer'} `}
        type={type??'button'}
        disabled={disabled}
        onClick={(e)=> onClick ? onClick(e):'/#' }
             >    
             {icon ? icon:''}        
            <span className='min-w-fit max-w-[10rem] text-ellipsis overflow-hidden text-[11px] xl:text-[13px]'>{label}</span>
        </button>
    )
}

export default Button
