import React, { useEffect,useState } from 'react';
import { Select } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DownArrow from '../CommonIcons/DownArrow';
import Button from '../Button';
import HistoryDataTable from '../Table/HistoryDataTable';
import DataTableSkeleton from '../Skeleton/DataTableSkeleton';
import { useDispatch} from 'react-redux';
import axios from 'axios';
import { Baseurl, localStorageKeys, loginLocalStorageKeys } from '../../Utils/Constants';
import { ASSIGNED_DATA_TYPE_LIST, CREATE_MERGE_SUMMARY,DUCK_DB_DATATYPES, DUCK_DB_DATATYPE_FORMATS, SAVE_DATA_TYPE_FORMAT } from '../../Helpers/EndPoints';
import CommonPopupLayout from '../PopUp/CommonPopupLayout';
import DropDown from '../DropDown/DropDown';
import EditIcon from '../../Images/Group 809.svg'
import { convertDateFormat } from '../../Utils/CommonFunctions';
import { getNodeDetails } from '../../Store/action';
import { toast } from 'react-toastify';
import { setCurrentNodeData } from '../../Store/Workflow/WorkflowSlice';
import { useLocation } from 'react-router-dom';
import ProceedButton from '../CommonComponents/ProceedButton';
import useDataTable from '../../hooks/useDataTable';
import { CircularProgress } from '@mui/material';
import XlsIcon from '../../Images/excel.svg'
import DataTable from '../Table/DataTable';
import downloadingIcon from '../../Images/gif/downloading.gif'

const schema = yup.object().shape({
  category: yup.string().required("Please select category"),
});

function BooleanInputs({ assignDataTypeFormatData, selectedValues, setSelectedValues, currentIndex }) {
  useEffect(() => {
    const initialValues = assignDataTypeFormatData?.datatypeDatas?.reduce((acc, data, i) => {
      if (data?.maybe_boolean && currentIndex == i) {
        data.boolean_inputs.forEach((input) => {
          Object.keys(input).forEach((key,ind) => {
            if(input[key] == ""){
              console.log(input[key],'input[key]');
              acc[`input-${key}`] = ind == 0 ? 'true' : 'false';
            }else{
            acc[`input-${key}`] = input[key] == 'true' ? 'true' : 'false';
          }
          });
        });
      }
      return acc;
    }, {});
    setSelectedValues(initialValues);
  }, [assignDataTypeFormatData, currentIndex, setSelectedValues]);

  console.log(selectedValues,'selectedValues');

  const handleChange = (event, key) => {
    const value = event.target.value;
    setSelectedValues((prevValues) => {
      const updatedValues = { ...prevValues };
      updatedValues[`input-${key}`] = value;
      // Update the opposite value
      Object.keys(prevValues).forEach((k) => {
        if (k !== `input-${key}`) {
          updatedValues[k] = value == 'true' ? 'false' : 'true';
        }
      });
      return updatedValues;
    });
  };

  return (
    <div className=''>
      <p className='text-[14px] font-semibold text-[#000000]'>Boolean inputs</p>
      <div className='grid grid-cols-2 my-2'>
        {assignDataTypeFormatData?.datatypeDatas?.map((data, i) => {
          if (data?.maybe_boolean && currentIndex == i) {
            return data?.boolean_inputs?.map((input, index) => {
              return Object.keys(input).map((key) => (
                <div key={key} className='my-1'>
                  <label className='text-[14px]'>{key}</label>
                  <div className='flex gap-5'>
                    <label className='flex items-center gap-1 cursor-pointer'>
                      <input
                        type="radio"
                        value="true"
                        checked={selectedValues[`input-${key}`] === 'true'}
                        onChange={(e) => handleChange(e, key)}
                      />
                      <span className='text-[14px] text-[#2B144D]'>True</span>
                    </label>
                    <label className='flex items-center gap-1 cursor-pointer'>
                      <input
                        type="radio"
                        value="false"
                        checked={selectedValues[`input-${key}`] === 'false'}
                        onChange={(e) => handleChange(e, key)}
                      />
                      <span className='text-[14px] text-[#2B144D]'>False</span>
                    </label>
                  </div>
                </div>
              ));
            });
          }
          return null;
        })}
      </div>
    </div>
  );
}

function AssignDataTypeFormat({
  workflow_id,
  ecl_node_id,
  workflow_node_id,
  calculationDate
  , templateId,
  templateName,
  client_branch_level_id,
  nodeCategoryId,
  onProceedClick,
}) {
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(nodeCategoryId);
  const [dataTypeList,setDataTypeList] = useState([])
  const [disabled, setDisabled] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedDataType,setSelectedDataType] = useState('');
  const [dataTypeFormat,setDataTypeFormat] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [initialLoading, setInitialLoading] = useState(true);
  const [selectedItemDataType, setSelectedItemDataType] = useState(undefined);
  const [updatedDataType, setUpdatedDataType] = useState(null)
  const [showEdit, setShowEdit] = useState(false);
  const [isEdit, setIsEdit] = useState()
  const [fileMerging, setFileMerging] = useState()
  const [selectedValues, setSelectedValues] = useState([])

  const { register, control, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const [assignDataTypeFormatData, setAssignDataTypeFormatData] = useState()
  const location = useLocation()

  const { dataTableColumns,
    getFilterOptions,
    summaryData,
    tableState,
    refreshDataTable,
    onTableStateChange,
    isDataLoading,
    isExportLoading,
    donwloadXl,
  } = useDataTable({ category_id: selectedCategory, ecl_node_id, workflow_id, from: ""})

  const columns = [
    {
      Header: 'Title',
      accessor: 'column_name',
    },
    {
      Header: 'Current data type',
      accessor: 'current_data_type',
    },
    {
      Header: 'Assign data type',
      accessor: 'assigned_data_type_id',
    },
  ];

  const dispatch = useDispatch()

  const calculationApiCall = (isFrom,category_id) => {

    if (calculationDate == 'Invalid date') {
    }
    else {
      if(templateId || templateName){     
      const postFormData = new FormData();
      postFormData.append('ecl_workflow_id', workflow_id != null || workflow_id != undefined ? workflow_id : "");
      postFormData.append('user_id', localStorage.getItem(loginLocalStorageKeys.loginId));
      postFormData.append('client_branch_level_id', client_branch_level_id);
      postFormData.append('date', convertDateFormat(calculationDate));
      postFormData.append('template_type', 'New');
      postFormData.append('ecl_workflow_node_id', isEdit ? workflow_node_id :"");
      postFormData.append('node_id', ecl_node_id); 
      postFormData.append('is_proceed', "Completed"); 
      if(isFrom == 'postionAnalysis' || isFrom == 'dataCleansing'){
        postFormData.append('category_id', category_id); 
      }
      postFormData.append('template_id', templateId ?? "");
      postFormData.append('template_name', templateId ? "" : templateName)

      axios.post("https://ecl.port0.org/laravelapi/api/ecl/calculation", postFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          // handle the response
          if (response?.data?.status == 200) {
            localStorage.setItem(localStorageKeys.currentWorkFlowId, response?.data?.workflowId)
            setShowEdit(true)
            setShowLoader(false)
            setAssignDataTypeFormatData([])
            dispatch(getNodeDetails(response?.data?.workflowId))
            dispatch(setCurrentNodeData({
              workflowNodeId: response?.data?.workflowNodeId,
              nodeCategoryId: category_id
            }))
            getTableData();                    
            // setReplaceClicked(false)
            // setNodeSaved(true)
            // setError(false)
            // setIsFileSentToS3(true)
            if (isFrom != 'another') {
              localStorage.setItem(localStorageKeys.lastNodeStatus, 'completed')
            }
          }else if(response?.data?.status == 500){
            toast.error('Duck DB connection error please refresh the page')
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }}
  }

  const getCategoryList = () => {
    axios(`${Baseurl}/ecl/calculation/${workflow_id}/${ecl_node_id}/nodeWise/categoryList`, {
      method: "GET",
    })
      .then((response) => {
        if (response?.data?.status === 200) {
          setCategoryList(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  };

  const filesMergeApiCall = () => {
    axios(`${Baseurl + CREATE_MERGE_SUMMARY}`, {
      method: "POST",
      data: {
        workflow_id: workflow_id,
        ecl_node_id: ecl_node_id
      },
    })
      .then((response) => {
       if (response?.data?.status == 200) {
        refreshDataTable()
          setInitialLoading(false)          
          setDisabled(false)
          showLoader(false)
        }
        else if(response?.data?.status == 500){
          toast.error('Duck DB connection error please refresh the page')
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  };

  useEffect(() => {
    if (nodeCategoryId) {
      getTableData()
    }
  }, [])


  const getTableData = () => {
    setShowLoader(true);

    axios(`${Baseurl + ASSIGNED_DATA_TYPE_LIST}`, {
      method: "POST",
      data: {
        workflow_id: workflow_id,
        ecl_node_id: ecl_node_id,
        category_id: selectedCategory
      },
    })
      .then((response) => {
        if (response?.data?.status === 200) {
          console.log(response?.data?.message, 'message');
          setAssignDataTypeFormatData(response?.data);         
          setIsEdit(response?.data?.isEdit)
          setShowLoader(false);
          setDisabled(true)
          refreshDataTable()

        }
       
      })
      
      .catch((error) => {
        console.log("API not working", error);
      });
  };

  const getDataTypeList = ()=>{

    axios(`${Baseurl+ DUCK_DB_DATATYPES}`, {
        method: "GET",       
      })
        .then((response) => {
          if (response?.data?.status === 200) {
            setDataTypeList(response?.data?.duckDbDataTypeList)
          }
        })
        .catch((error) => {
          console.log("API not working", error);
        });

        axios(`${Baseurl+ DUCK_DB_DATATYPE_FORMATS}`, {
          method: "GET",       
        })
          .then((response) => {
            if (response?.data?.status === 200) {
              setDataTypeFormat(response?.data?.dataTypeFormatList)
            }
          })
          .catch((error) => {
            console.log("API not working", error);
          });
        
  }

  const getSaveDataTypeFormat = ()=>{
    setShowLoader(true)
    axios(`${Baseurl+SAVE_DATA_TYPE_FORMAT}`, {
        method: "POST", 
        data: {
            ecl_workflow_id: workflow_id,
            ecl_node_id: ecl_node_id,
            ecl_category_id: nodeCategoryId  ? nodeCategoryId : selectedCategory,
          assign_data_type_datas: getDatatypeRequest()
          },      
      })
        .then((response) => {
          if (response?.data?.success == true) {            
            // onClickSave(selectedCategory)
            calculationApiCall('dataCleansing',selectedCategory)
            console.log(response?.data?.message);
        }else{
          console.log(response?.data?.error,'error');
        }
        })
        .catch((error) => {
          console.log("API not working", error);
        });

  }

  useEffect(() => {
    getCategoryList();
    filesMergeApiCall();
    getDataTypeList();
  }, [ecl_node_id]);

  const getDatatypeById = id => dataTypeList?.find(data => data?.id == id)?.data_type
  const getDatatypeLableById = id => dataTypeList?.find(data => data?.id == id)?.label

  const getUpdateddatatypeByIndex = index => updatedDataType ? updatedDataType[index] : null  

  const getDatatypeRequest = () => {
    return assignDataTypeFormatData?.datatypeDatas?.map((item, i) => {
      const data = updatedDataType ? updatedDataType[i] : null;
      if (data) {
        return {
          ...item,
          assigned_data_type_id: data?.currentDataType,
          assigned_data_type: getDatatypeById(data?.currentDataType),
          is_ignored: "false",
          multi_data_type: "false",
          format: data?.currentDataTypeFormat ? data?.currentDataTypeFormat : "",
          boolean_inputs: item?.boolean_inputs?.length > 0
          ? item?.boolean_inputs?.map((input) => {
              // Iterate over each key-value pair in the `input` object
              return Object.keys(input).reduce((acc, key) => {
                acc[key] = selectedValues[`input-${key}`] === "true" ? "true":"false";
                return acc;
              }, {});
            })
          : []   
        };
      } else {
        return {
          ...item,
          is_ignored: "false",
          multi_data_type: "false",
        };
      }
    });
  };
  
  
  useEffect(()=>{
    if(assignDataTypeFormatData?.datatypeDatas?.length > 0){
      setInitialLoading(false);
      setShowLoader(false)
      setIsEdit(assignDataTypeFormatData?.isEdit)
    }

  },[assignDataTypeFormatData])

  const handleIntergerFieldChange = (e) => {
    const newValue = e.target.value;
    if (newValue === '' || /^[0-9]*$/.test(newValue)) {
      setSelectedItemDataType(newValue)
    }
  };

  const handleIntergerFieldKeyPress = (e) => {
    if (!/[0-9]/.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight') {
      e.preventDefault();
    }
  };
  

  const onPopupClose = () => {
    setShowPopup(false)
    setSelectedDataType(undefined)
    setSelectedItemDataType(undefined)
    setCurrentIndex(null)
  }

  const onDatatypeChange = (rowid, val, oldVal) => {
    setSelectedDataType({ value: val, oldValue: oldVal })
    setCurrentIndex(rowid)
    setSelectedItemDataType(undefined)    
    if ((getDatatypeById(val) ?? undefined) == 'CHARACTER') {
      setUpdatedDataType(prev => ({
        ...prev, [rowid]: {
          currentDataType: val,
          currentDataTypeFormat: "",
          rowIndex: rowid,
        }
      }))
    } else {
      setShowPopup(true)
    }
  }

  const onDatatypeSubmit = () => {
    setUpdatedDataType(prev => ({
      ...prev, [currentIndex]: {
        currentDataType: selectedDataType?.value,
        currentDataTypeFormat: selectedItemDataType,
        rowIndex: currentIndex,
      }
    }))
    onPopupClose()
  }

  const onDatatypeFormatChange = (val, dataType, label) => {
    console.log(val, dataType, label,'muthu');
    setSelectedItemDataType(val)
  }


  const isPopupSubmitDisabled = () => {
    // console.log("isPopupSubmitDisabled", getUpdateddatatypeByIndex(currentIndex)?.currentDataTypeFormat, { selectedItemDataType });
    console.log( getUpdateddatatypeByIndex(),' getUpdateddatatypeByIndex');
    console.log( currentIndex,' currentIndex');
    console.log(selectedItemDataType ,'selectedItemDataType ');
    if (getUpdateddatatypeByIndex(currentIndex)?.currentDataTypeFormat && selectedItemDataType) {
      return false;
    } else if (selectedItemDataType || getDatatypeById(selectedDataType?.value) == 'BOOLEAN') {
      return false
    } else if (getUpdateddatatypeByIndex(currentIndex)?.currentDataTypeFormat) {
      return true
    } else {
      return true
    }
  }

  useEffect(()=>{
    if(assignDataTypeFormatData){
      setShowEdit(assignDataTypeFormatData?.isEdit)
      setIsEdit(assignDataTypeFormatData?.isEdit)
    }

  },[assignDataTypeFormatData])

  return (
    <div className='max-h-full'>
        {showPopup && 
        <CommonPopupLayout
        show={true}
        title={`Define ${getDatatypeLableById(selectedDataType?.value)} values`}
        buttons={true}
        minWidth={'min-w-[30%]'}
        cancelButtonName="Cancel"
        sumbitButtonName={"Save"}
        disabled={isPopupSubmitDisabled()}
        onCancel={onPopupClose}
        onSubmit={onDatatypeSubmit}
        content={<div className=''>
          {getDatatypeById(selectedDataType?.value) == 'INTEGER'
          
        ?
        <div className='p-5'>
            <input ref={ref => ref?.focus()} value={selectedItemDataType} className='border-[2px] rounded-[5px] py-1 pl-3 border-greeny-blue w-full focus:outline-none place' placeholder='Number of decimals (for calculation)' onKeyDown={handleIntergerFieldKeyPress} onChange={(e) => handleIntergerFieldChange(e)} />
        </div>
        :
        getDatatypeById(selectedDataType?.value) == 'BOOLEAN' ?    
          <div className='px-5' key={currentIndex}>
           <BooleanInputs assignDataTypeFormatData={assignDataTypeFormatData} selectedValues={selectedValues} setSelectedValues={setSelectedValues} currentIndex={currentIndex}/>
          <p className='text-[13px] text-[#000000] mb-3'><span className='font-semibold'>Boolean value presentation format </span> <span>(Optional)</span></p>
            <DropDown
              initialFocus={true}
              dropDownOptions={dataTypeFormat[getDatatypeById(selectedDataType.value)]}
              placeholder={'Select boolean value presentation format'}
              name={'dataTypeFormat'}
              value={selectedItemDataType || getUpdateddatatypeByIndex(currentIndex)?.currentDataTypeFormat}
              ordinary={true}
              disabled={false}
              customTags={false}
              onOptionChange={onDatatypeFormatChange}
            />
          </div>
          
            :
            <div className='p-5'>
            <DropDown
              initialFocus={true}
              dropDownOptions={dataTypeFormat[getDatatypeById(selectedDataType?.value)]}
              placeholder={'Select date presentation format'}
              name={'dataTypeFormat'}
              value={selectedItemDataType || getUpdateddatatypeByIndex(currentIndex)?.currentDataTypeFormat}
              ordinary={true}
              disabled={false}
              customTags={false}
              onOptionChange={onDatatypeFormatChange}
            />
            </div>
      }

        </div>} 
        />
    }
    {
      initialLoading ?

      <div className='mt-2'>
        <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={false} showActionableButtons={false} from='mergeSummary' isDownload={false} />
      </div>

      :
        <>
        {!showEdit ? 
        <div className='flex space-x-4 mt-4'>
          <Controller
            control={control}
            name="category"
            render={({ field }) => (
              <Select
                ref={field.ref}
                name={field.name}
                onBlur={field.onBlur}
                {...register("category")}
                onChange={(e) => {
                  field.onChange(e);
                  setSelectedCategory(e);
                }}
                value={nodeCategoryId ? nodeCategoryId : field.value ? field.value : undefined}
                placeholder="Select data category"
                options={categoryList?.map(cList => ({
                  label: cList?.category_name,
                  value: cList?.category_id,
                  disabled: cList?.is_disabled
                }))}
                disabled={disabled || showEdit || nodeCategoryId}
                onDropdownVisibleChange={(visible) => setIsCategoryOpen(visible)}
                suffixIcon={<DownArrow color="#000000" width={'10px'} height={'10px'} className={isCategoryOpen ? 'transition-transform transform rotate-180' : 'transition-transform transform rotate-[-180]'} />}
                className="custom-select-height border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"
                style={{
                  width: '100%',
                  border: '2px solid #00A7B5',
                  borderRadius: '4px',
                }}
              />
            )}
          />
          <Button
            bgColor="#47B4AE"
            size="lg"
            label={'Save'}
            color={'#FFFFFF'}
            onClick={getTableData}
            disabled={disabled || nodeCategoryId || !selectedCategory}
          />
      </div>
    :''}
      </>
      
    }
      {
        !showLoader ? 
        (assignDataTypeFormatData?.datatypeDatas?.length > 0  ?
          <>          
            <div className={`${showEdit ? 'mt-2':'mt-0'}`}>                         
                <div className={`${showEdit ? 'block' :'hidden'} 2xl:py-3 py-1.5 bg-[#4339A8] flex justify-between items-center`}>
                  <div className='mx-4 flex gap-3 items-center'>
                    <button className='text-[#FFFFFF] bg-[#F7BE7E] py-0.5 px-2 text-[12px] rounded'>Data category</button>
                    <p className='text-[#ffffff] font-semibold text-[12px]'>{assignDataTypeFormatData?.categoryName}</p>
                    </div>                  
                  <div className={`flex gap-1 items-center justify-end mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none':'cursor-pointer'}`} onClick={() => { setShowEdit(false) }}>
                        <img src={EditIcon}  className='w-4 h-4'/>
                    <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
                  </div>
                </div>
                <div className={`${showEdit ? '':'mt-5'}`}>
                <HistoryDataTable
                  columns={columns}
                  onDatatypeChange={onDatatypeChange}
                  updatedDataType={updatedDataType}
                  data={assignDataTypeFormatData?.datatypeDatas ?? []}
                  tableCellClassName={'text-[#000000] 2xl:text-[14px] text-[12px] bg-white px-5 2xl:px-8 py-1 2xl:py-2'}
                  headerclassName={'2xl:py-4 py-2 px-5 2xl:px-8 bg-[#A06DFF] text-white text-[12px] xl:text-[13px] border-none max-w-[10rem] min-w-[6rem]'}
                  dropDownOptions={dataTypeList?.map((obj) => ({
                      label: obj?.label,
                      value: obj?.id,
                      data_type:obj?.data_type,
                    }))}
                  placeholder={'Select data type'}
                  setSelectedDataType={setSelectedDataType}
                  name={'dataType'}
                  fixe
                  disabled={showEdit}
                  setCurrentIndex={setCurrentIndex}
                />
              </div>
            </div>
            {!showEdit &&
            <div className='flex items-center justify-center mt-4'>
              <Button
                bgColor="#47B4AE"
                size="lg"
                label={'Save'}
                    // disabled={updatedDataType == null}
                disabled={getDatatypeRequest()?.some(item => !item.assigned_data_type)}
                color={'#FFFFFF'}
                onClick={() => {getSaveDataTypeFormat()}}
              />
            </div>
            }            
          </>
          :
          <></>
        )
         
        :
        (
            <div className='mt-2'>
                <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            </div>
        )
      }
       {((summaryData?.columnDatas?.length > 0) || isDataLoading || fileMerging) &&
                (isDataLoading || fileMerging
                  ? <div className='mt-2'>
                    <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
                  </div>
                  : <div className='mt-5'> 
            
              <div className='flex justify-end items-center'>
                    {isExportLoading
                    ? <img src={downloadingIcon} className='w-6 mr-2' />
                    : <img src={XlsIcon} className=' mb-2 mr-2 cursor-pointer w-5 h-5 xl:w-6 xl:h-6 object-cover' onClick={donwloadXl} />}
              </div>
          
              <DataTable 
                columns={dataTableColumns}
                data={summaryData?.columnDatas ?? []}
                onTableStateChange={onTableStateChange}
                totalCount={summaryData?.totalRecords}
                isOptionsDisabled={location?.state?.isfromHistory}
                thClassName={"min-w-fit 2xl:w-[11rem]"}
                getFilterOptions={getFilterOptions}
                tableState={{
                  pagination: {
                    pageIndex: tableState.pageIndex,
                    pageSize: tableState.pageSize,
                  },
                  sorting: tableState?.sortBy ? [
                    {
                      id: tableState?.sortBy,
                      desc: tableState?.sortOrder === "desc"
                    }
                  ] : [],
                  columnFilters: tableState.columnFilters
                    ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                    : [],
                  columnPinning: tableState.columnPinning,
                  columnVisibility: tableState?.columnVisibility,
                 }}
                />                                        
            <div className={`${showEdit && !location?.state?.isfromHistory ? 'block' : 'hidden'} float-right my-3 2xl:my-6`}>
             <ProceedButton
              onClick={onProceedClick}
            />
          </div> 
      </div>)
    }      
    </div>
  );
}

export default AssignDataTypeFormat;
