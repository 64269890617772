import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States

import {EclCategoryList, ModelBuilderList, SortedEclHistoryList, SortedEclHistoryListForName, deleteNodeApi, downloadMergeSummaryApi, fileUploadSummaryApi, getFileDetailsApi, getNodeDetailsAPI, getPerticularNodeDetails, mergeSummaryApi, tableUpdationApi } from "../../Helpers/ApiHelpers";
import { CategoryListError, CategoryListFetchSuccess, EclHistoryError, EclHistoryFetchSuccess, ModelBuilderError, ModelBuilderListFetchSuccess, NodeDetailsError, NodeDetailsFetchSuccess,deleteNodeApiError, deleteNodeSuccess, downloadMergeSummaryApiError, downloadMergeSummarySuccess, fileDetailsFetchApiError, fileDetailsFetchSuccess, fileUploadSummaryApiError, fileUploadSummarySuccess, mergeSummaryFlowApiError, mergeSummaryFlowSuccess, singleFilePerticularApiError, singleFilePerticularFetchSuccess, singleFileUploadApiError, singleFileUploadSuccess, tableUpdationApiError, tableUpdationSuccess } from "./Action";
import { MODEL_BUILDER_LIST, ECL_HISTORY, NODE_DETAILS, GATEGORY_LIST, SINGLE_FILE_UPLOAD, SINGLE_FILE_UPLOAD_PERTICULAR_LIST, DELETE_NODE, FILE_UPLOAD_SUMMARY, NODE_DETAILS_DATA_CLEAR, MERGE_DATA_WORKFLOW, PERTICULAR_NODE_DATA_CLEAR, DOWNLOAD_MERGE_SUMMARY, FILE_UPLOAD_SUMMARY_CLEAR, MERGE_DATA_CLEAR, TABLE_UPDATION, TABLE_UPDATION_DATA_CLEAR, GATEGORY_LIST_DATA_CLEAR, GET_FILE_DETAILS, FILE_DETAILS_DATA_CLEAR, SET_IS_DATA_LOADING } from "./ActionType";
import { localStorageKeys } from "../../Utils/Constants";

function* getModelBuilderList() {

  try {
    const response = yield call(ModelBuilderList)
    if (response.status === 200) {
      if (response.data.status === 200) {
        localStorage.setItem(localStorageKeys.modelBuilderList,JSON.stringify(response?.data?.modelBuilderDatas))
        yield put(ModelBuilderListFetchSuccess(response?.data))
      }
    } else {
      yield put(ModelBuilderError(response));
    }
  } catch (error) {
    console.log('API ERROR:', error);
    yield put(ModelBuilderError(error));
  }
}

function* getEclHistory(data) {
  try {
    const response = yield call(data?.payload?.key ? SortedEclHistoryListForName : SortedEclHistoryList, data?.payload)
    if (response.status === 200) {
      if (response.data.status === 200) {
        yield put(EclHistoryFetchSuccess(response?.data))
      }
    } else {
      yield put(EclHistoryError(response));
    }
  } catch (error) {
    console.log('API ERROR:', error);
    yield put(EclHistoryError(error));
  }
}

function* getNodeDetails(data) {
  yield put({ type: SET_IS_DATA_LOADING, payload: true });

  try {
    const response = yield call(getNodeDetailsAPI, data?.payload)
    if (response.status === 200) {
      if (response.data.status === 200) {
        localStorage.setItem(localStorageKeys.currentCalculationDate,response?.data?.date)
        localStorage.setItem(localStorageKeys.currentTemplateId,response?.data?.ecl_workflow_template_id)
        localStorage.setItem(localStorageKeys.currentTemplateName,response?.data?.ecl_workflow_template_name)
        yield put(NodeDetailsFetchSuccess(response?.data?.workflowNodes))
      }
    } else {
      yield put(NodeDetailsError(response));
    }
  } catch (error) {
    console.log('API ERROR:', error);
    yield put(NodeDetailsError(error));
  }
  finally {
    // Set isDataLoading to false after API call completes
    yield put({ type: SET_IS_DATA_LOADING, payload: false });
  }
}

function* getCategoryList(data) {
  try {
    const response = yield call(EclCategoryList, data?.payload)
    if (response.status === 200) {
      if (response.data.status === 200) {
        localStorage.setItem(localStorageKeys.categoryList,JSON.stringify(response?.data?.categoryList))
        yield put(CategoryListFetchSuccess(response?.data))
      }
    } else {
      yield put(CategoryListError(response));
    }
  } catch (error) {
    console.log('API ERROR:', error);
    yield put(CategoryListError(error));
  }
}

function* handleSingleFileUpload(action) {
  try {
    const response = yield call(fetch, 'https://ecl.port0.org/laravelapi/api/ecl/calculation', {
      method: 'POST',
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      body: action.payload
    })
    // const response = yield call(SingleFileUpload ,data,headers);
    if (response.status === 200) {
      yield put(singleFileUploadSuccess(response))
    } else {
      yield put(singleFileUploadApiError('Error during file upload'));
    }
  } catch (error) {
    yield put(singleFileUploadApiError('Error during file upload'));
  }
}
function* getSingleFilePerticularList(action) { 

  yield put({ type: SET_IS_DATA_LOADING, payload: true });

  try {
    const response = yield call(getPerticularNodeDetails, action?.payload)
    if (response.status === 200) {
      if (response.data.status === 200) {
        yield put(singleFilePerticularFetchSuccess(response?.data?.workflowNodes))
        // yield put(NodeDetailsFetchSuccess(response?.data?.workflowNodes))
      }
    } else {
      yield put(singleFilePerticularApiError(response));
    }
  } catch (error) {
    console.log('API ERROR:', error);
    yield put(singleFilePerticularApiError(error));
  }
  finally {
    // Set isDataLoading to false after API call completes
    yield put({ type: SET_IS_DATA_LOADING, payload: false });
  }
}

function* getDeleteNode(data) {
  try {
    const response = yield call(deleteNodeApi, data?.payload)
    if (response.status === 200) {
      if (response.data.status === 200) {
        yield put(deleteNodeSuccess(response?.data))
      }
    } else {
      yield put(deleteNodeApiError(response));
    }
  } catch (error) {
    console.log('API ERROR:', error);
    yield put(deleteNodeApiError(error));
  }
}

function* getFileUploadSummary(data) {
  try {
    yield put({ type: SET_IS_DATA_LOADING, payload: true });

    const response = yield call(fileUploadSummaryApi, data?.payload)
    if (response.status === 200) {
      if (response.data.status === 200) {
        localStorage.setItem('fileUpload',response?.data?.node_value_summary)
        yield put(fileUploadSummarySuccess(response?.data?.workflowNodes))
      }else if(response.data.status == 500){
        yield put(fileUploadSummaryApiError(response?.data))
      }
    } else {
      yield put(fileUploadSummaryApiError(response));
    }
  } catch (error) {
    console.log('API ERROR:', error);
    yield put(fileUploadSummaryApiError(error));
  }
  finally {
    // Set isDataLoading to false after API call completes
    yield put({ type: SET_IS_DATA_LOADING, payload: false });
  }
}

function* getNodeDetailDataClear(data) {
  try {    
    yield put(NodeDetailsFetchSuccess([]));
  } catch (error) {
    // Handle any potential errors
    console.error('Error clearing API response:', error);
  }
}
function* getPerticularNodeDataClear(data) {
  try {    
    yield put(singleFilePerticularFetchSuccess([]));
  } catch (error) {
    // Handle any potential errors
    console.error('Error clearing API response:', error);
  }
}

function* getmergeDataClear(data) {
try {    
    yield put(mergeSummaryFlowSuccess([]));
  } catch (error) {
    // Handle any potential errors
    console.error('Error clearing API response:', error);
  }
}

function* mergeSummaryDetails(data) {

  try {
    const response = yield call(mergeSummaryApi, data?.payload)
    if (response.status === 200) {
      if (response.data.status === 200) {
        yield put(mergeSummaryFlowSuccess(response?.data))
      }
    } else {
      yield put(mergeSummaryFlowApiError(response));
    }
  } catch (error) {
    console.log('API ERROR:', error);
    yield put(mergeSummaryFlowApiError(error));
  }
}

function* getDownloadSummary(data) {
  try {
    // const response = yield call(downloadMergeSummaryApi, data?.payload?.request)
    const response = yield call(downloadMergeSummaryApi,{
      "workflow_id":283,
      "ecl_node_id":10,
      "category_id":19,
      "column_name": "Company"
    }
    );
    if (response.data) {
        yield put(downloadMergeSummarySuccess(response?.data))
        // console.log(response?.data,'download response');
        const url = window.URL.createObjectURL(new Blob([response?.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download",`${data?.payload?.fileName ?? "file"}.csv`);
        document.body.appendChild(link);
        link.click();
        localStorage.setItem(localStorageKeys.downloading,false)
    } else {
      yield put(downloadMergeSummaryApiError(response));
    }
  } catch (error) {
    console.log('API ERROR:', error);
    yield put(downloadMergeSummaryApiError(error));
  }
}

function* getFileUploadSummaryClear(data) {
  try {    
    yield put(fileUploadSummarySuccess([]));
  } catch (error) {
    // Handle any potential errors
    console.error('Error clearing API response:', error);
  }
}

function* getTableUpdation(data) {
  try {
    const response = yield call( tableUpdationApi , data?.payload?.payload)
    if (response.status === 200) {
      if (response.data.status === 200) {
        // console.log(response?.data,'tableUpdation');
        yield put(tableUpdationSuccess(response?.data))
      }
      else if(response.data.status === 205){
        yield put(tableUpdationSuccess(response?.data))
      }
    } else {
      yield put(tableUpdationApiError(response));
    }
  } catch (error) {
    console.log('API ERROR:', error);
    yield put(tableUpdationApiError(error));
  }
}

function* getTableUpdateDataClear(data) {
  try {    
    yield put(tableUpdationSuccess([]));
  } catch (error) {
    // Handle any potential errors
    console.error('Error clearing API response:', error);
  }
}

function* getCategoriesDataClear(data) {
  try {    
      yield put(CategoryListFetchSuccess([]));
    } catch (error) {
      // Handle any potential errors
      console.error('Error clearing API response:', error);
    }
  }

  function* getFileDetails(data) {
    try {
      const response = yield call(getFileDetailsApi , data?.payload)
      if (response.status === 200) {
        if (response.data.status === 200) {
          console.log(response?.data?.data,'file details response');
          yield put(fileDetailsFetchSuccess(response?.data))
        }
      } else {
        yield put(fileDetailsFetchApiError(response));
      }
    } catch (error) {
      console.log('API ERROR:', error);
      yield put(fileDetailsFetchApiError(error));
    }
  }  

  function* getFileDetailsDataClear(data) {
    try {    
      yield put(fileDetailsFetchSuccess([]))
    } catch (error) {
        // Handle any potential errors
        console.error('Error clearing API response:', error);
      }
    }
  
function* WizardSaga() {
  yield takeEvery(MODEL_BUILDER_LIST, getModelBuilderList);
  yield takeEvery(ECL_HISTORY, getEclHistory);
  yield takeEvery(NODE_DETAILS, getNodeDetails);
  yield takeEvery(GATEGORY_LIST, getCategoryList);
  yield takeEvery(SINGLE_FILE_UPLOAD, handleSingleFileUpload);
  yield takeEvery(SINGLE_FILE_UPLOAD_PERTICULAR_LIST, getSingleFilePerticularList);
  yield takeEvery(DELETE_NODE, getDeleteNode);
  yield takeEvery(FILE_UPLOAD_SUMMARY, getFileUploadSummary);
  yield takeEvery(FILE_UPLOAD_SUMMARY_CLEAR, getFileUploadSummaryClear);
  yield takeEvery(NODE_DETAILS_DATA_CLEAR,getNodeDetailDataClear)
  yield takeEvery(MERGE_DATA_CLEAR,getmergeDataClear)
  yield takeEvery(PERTICULAR_NODE_DATA_CLEAR,getPerticularNodeDataClear)
  yield takeEvery(MERGE_DATA_WORKFLOW, mergeSummaryDetails);
  yield takeEvery(DOWNLOAD_MERGE_SUMMARY, getDownloadSummary);
  yield takeEvery(TABLE_UPDATION, getTableUpdation); 
  yield takeEvery(TABLE_UPDATION_DATA_CLEAR , getTableUpdateDataClear); 
  yield takeEvery(GATEGORY_LIST_DATA_CLEAR,getCategoriesDataClear);
  yield takeEvery(GET_FILE_DETAILS, getFileDetails) 
  yield takeEvery(FILE_DETAILS_DATA_CLEAR , getFileDetailsDataClear) 
  

}

export default WizardSaga;
