import React from 'react'
import { useLocation } from 'react-router-dom'

import addIcon from "../../Images/Group 201.svg";
import removeFile from "../../Images/removeFIle.svg";
import * as icons from '../../Components/CommonIcons/ModelBuilderIconsSrc';
import { useDispatch, useSelector } from 'react-redux';
import { deleteNode, newNodeAdded, nodeClicked, openModalBuilder, removeNode, selectCurrentNodeData, selectCurrentNodeIds, selectIsDisableAction, selectIsNodeDataAvailable, setCurrentNode} from '../../Store/Workflow/WorkflowSlice';
import FileUpload from './WorkflowModules/FileUpload';
import PositionAnalysis from '../../Pages/Workflow/WorkflowModules/PositionAnalysis';
import RenameColumnName from '../../Components/DataCleansing/RenameColumn';
import AssignDataTypeFormat from '../../Components/DataCleansing/AssignDataTypeFormat';
import RemoveColumnName from '../../Components/DataCleansing/RemoveColumn';
import RemoveDuplicateRow from '../../Components/DataCleansing/RemoveDuplicateRow';
import SplitData from '../../Components/DataTransformation/SplitData';
import ZscoreStandardization from '../../Components/DataTransformation/ZscoreStandardization';
import NaturalLogTransformation from '../../Components/DataTransformation/NaturalLogTransformation';
import Binningandbucketing from '../../Components/DataTransformation/Binningandbucketing';
import Binningapercentiles from '../../Components/DataTransformation/Binningpercentiles';
import BinningUsermapping from '../../Components/DataTransformation/BinningUsermapping';
import { CircularProgress } from '@mui/material';
import Outlier from "../../Components/ExceptionAnalysis/Outlier"
import MismatchedDataType from '../../Components/ExceptionAnalysis/MismatchedDataType';
import NullBlankValue from '../../Components/ExceptionAnalysis/NullBlankValue';
import ValueSpecifiedByUser from '../../Components/DataImputations/ValueSpecifiedByUser';
import FloorAndCaps from '../../Components/DataImputations/FloorAndCaps';
import CreateDuplicatecolumn from '../../Components/DataCleansing/CreateDuplicatecolumn';
import UnexpectedValues from '../../Components/ExceptionAnalysis/UnexpectedValues';
import ReplaceAverageByColumn from '../../Components/DataImputations/ReplaceAverageByColumn';
import MostFrequentlyOccuring from '../../Components/DataImputations/MostFrequentlyOccuring';
import MappingBasedOnDiffColumn from '../../Components/DataImputations/MappingBasedOnDiffColumn';
import MinMaxScaling from '../../Components/DataTransformation/MinMaxScaling';
import CumulativeDistributionFunction from '../../Components/DataTransformation/CumulativeDistributionFunction';
import PropbabilityDensityFUnction from '../../Components/DataTransformation/PropbabilityDensityFunction';
import NormalizationUsingPercentiles from '../../Components/DataTransformation/NormalizationUsingPercentiles';
import NormalizationUsingDecimalScaling from '../../Components/DataTransformation/NormalizationUsingDecimalScaling';
import NormalizationUsingRobustScaling from '../../Components/DataTransformation/NormalizationUsingRobustScaling';
import ReplaceWithEarliest from '../../Components/DataImputations/ReplaceWithEarliest';
import ReplaceWithLatest from '../../Components/DataImputations/ReplaceWithLatest';
import { modelBuilderSections } from '../../Utils/Constants';

const NodeIcon = ({ eclNodeId, id, isLast, onRemove, onClick, location }) => {
    const currentNodeData = useSelector(selectCurrentNodeData)
    const isDeletingNode = useSelector(state => state?.Workflow?.ui?.isDeletingNode)
    const isDisableAction = useSelector(selectIsDisableAction)


    return <>
        <div className='relative'>
            <button onClick={onClick} >
                <img
                    alt=''
                    draggable="false"
                    src={icons[`Id${eclNodeId}`]}
                    className={`self-center cursor-pointer relative max-w-[1.7rem] max-h-[1.8rem] ${id == null ? "color-change-image" : ""}`}
                    title={modelBuilderSections?.find(data => data?.id == eclNodeId)?.name || ""} />
            </button>

            {(isLast && !currentNodeData?.parent_node_id) ?
                isDeletingNode
                    ? <CircularProgress sx={{
                        color: "#e32e2e"
                    }} thickness={5} size={12} className='absolute top-[-1px] right-[-1px]' />
                    :
                    (eclNodeId >= 14 && eclNodeId <= 21 || eclNodeId == 5) ? "" : <img
                        alt=''
                        src={removeFile}
                        onClick={onRemove}
                        id="closeIcon"
                        className={`${location?.state?.isfromHistory ? 'hidden' : 'block'} close-icon absolute top-[-1px] ${isDisableAction ? 'pointer-events-none':'cursor-pointer'} right-[-1px] h-3 w-3`}
                    /> : null}
        </div>
        <hr class="self-center w-[2.5rem] max-w-[4.5rem] h-[3px] bg-[#B8B5B5] text-[#B8B5B5]"></hr>
    </>
}


function WorkflowNodes() {
    const location = useLocation()
    const dispatch = useDispatch()
    const nodeList = useSelector(state => state?.Workflow?.nodeList)
    const error = useSelector(state => state?.Workflow?.ui?.nodeError)
    const currentNodeData = useSelector(selectCurrentNodeData)
    const { currentWorkflowId, currentWorkflowNodeId, currentEclNodeId, nodeCategoryId } = useSelector(selectCurrentNodeIds)
    const { calculationDate, currentTemplateId, currentTemplateName } = useSelector(state => ({
        calculationDate: state?.Workflow?.calculationDate,
        currentTemplateName: state?.Workflow?.currentTemplateName,
        currentTemplateId: state?.Workflow?.currentTemplateId,
    }))
    const pipeLineList = useSelector(state => state?.ModelBuilder?.workflowNodes)

    const onNodeAddClick = () => {
        dispatch(openModalBuilder())
    }

    const onDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const doppedNodeId = e.dataTransfer.getData("text")
        dispatch(newNodeAdded({
            ecl_node_id: doppedNodeId
        }))
    }

    const onDragOver = (e) => {
        e.preventDefault();
        console.log("onDragOver", e);
    }

    const onNodeRemove = (node) => {
        const request = {
            "ecl_workflow_node_id": node?.id,
            "workflow_id": currentWorkflowId,
            "ecl_node_id": node?.ecl_node_id,
            nodeCategoryId: node?.nodeCategoryId,
        }
        console.log(node, 'node?.id');
        if (node?.id == null) {
            dispatch(removeNode())
        } else {
            dispatch(deleteNode(request))
        }
    }

    const onNodeClick = (node) => {
        if (node?.id == currentWorkflowId) return
        if (selectIsNodeDataAvailable(nodeList, node?.id)) {
            dispatch(nodeClicked({ nodeId: node?.id }))
        } else {
            dispatch(setCurrentNode({
                workflowNodeId: node?.id,
                eclNodeId: node?.ecl_node_id,
                nodeCategoryId: node?.nodeCategoryId
            }))
        }
    }

    const renderModules = () => {
        if (currentNodeData?.ecl_node_id == 1) {
            return <FileUpload key={currentWorkflowNodeId} isSingleUpload={true} />
        } else if (currentNodeData?.ecl_node_id == 2) {
            return <FileUpload key={currentWorkflowNodeId} isSingleUpload={false} />
        } else if (currentNodeData?.ecl_node_id == 4) {
            return <PositionAnalysis />
        } else if (currentNodeData?.ecl_node_id == 5) {
            return <AssignDataTypeFormat
                key={currentWorkflowNodeId}
                workflow_id={currentWorkflowId}
                ecl_node_id={currentEclNodeId}
                workflow_node_id={currentWorkflowNodeId}
                calculationDate={calculationDate}
                templateId={currentTemplateId}
                templateName={currentTemplateName}
                client_branch_level_id={location?.state?.levelTwoCardId}
                nodeCategoryId={nodeCategoryId}
                onProceedClick={() => dispatch(openModalBuilder())}

            />
        } else if (currentNodeData?.ecl_node_id == 6) {
            return <RenameColumnName
                workflow_id={currentWorkflowId}
                key={currentWorkflowNodeId}
                ecl_node_id={currentEclNodeId}
                workflow_node_id={currentWorkflowNodeId}
                calculationDate={calculationDate}
                templateId={currentTemplateId}
                templateName={currentTemplateName}
                client_branch_level_id={location?.state?.levelTwoCardId}
                nodeCategoryId={nodeCategoryId}
                onProceedClick={() => dispatch(openModalBuilder())}

            />
        } else if (currentNodeData?.ecl_node_id == 7) {
            return <RemoveColumnName
                key={currentWorkflowNodeId}
                workflow_id={currentWorkflowId}
                ecl_node_id={currentEclNodeId}
                workflow_node_id={currentWorkflowNodeId}
                calculationDate={calculationDate}
                templateId={currentTemplateId}
                templateName={currentTemplateName}
                client_branch_level_id={location?.state?.levelTwoCardId}
                nodeCategoryId={nodeCategoryId}
                onProceedClick={() => dispatch(openModalBuilder())}
            />
        } else if (currentNodeData?.ecl_node_id == 8) {
            return <CreateDuplicatecolumn
                key={currentWorkflowNodeId}                
                client_branch_level_id={location?.state?.levelTwoCardId}                
                onProceedClick={() => dispatch(openModalBuilder())}

            />
        } else if (currentNodeData?.ecl_node_id == 9) {
            return <RemoveDuplicateRow
                key={currentWorkflowNodeId}
                workflow_id={currentWorkflowId}
                ecl_node_id={currentEclNodeId}
                workflow_node_id={currentWorkflowNodeId}
                calculationDate={calculationDate}
                templateId={currentTemplateId}
                templateName={currentTemplateName}
                client_branch_level_id={location?.state?.levelTwoCardId}
                nodeCategoryId={nodeCategoryId}
                viewMode={false}
                onProceedClick={() => dispatch(openModalBuilder())}

            />
        } else if (currentNodeData?.ecl_node_id == 10) {
            return <MismatchedDataType
                key={currentWorkflowNodeId}
            />
        } else if (currentNodeData?.ecl_node_id == 11) {
            return <NullBlankValue
                key={currentWorkflowNodeId} />
        } else if (currentNodeData?.ecl_node_id == 12) {
            return <UnexpectedValues
                key={currentWorkflowNodeId} />
        } else if (currentNodeData?.ecl_node_id == 13) {
            return <Outlier key={currentWorkflowNodeId}
                client_branch_level_id={location?.state?.levelTwoCardId}
                onProceedClick={() => dispatch(openModalBuilder())}
            />
        } else if (currentNodeData?.ecl_node_id == 14) {
            return <ReplaceAverageByColumn key={currentWorkflowNodeId} />
        } else if (currentNodeData?.ecl_node_id == 15) {
            return <ReplaceWithEarliest key={currentWorkflowNodeId} />
        } else if (currentNodeData?.ecl_node_id == 16) {
            return <ReplaceWithLatest key={currentWorkflowNodeId} />
        } else if (currentNodeData?.ecl_node_id == 17) {
            return <ValueSpecifiedByUser key={currentWorkflowNodeId} />
        } else if (currentNodeData?.ecl_node_id == 18) {
            return <MostFrequentlyOccuring key={currentWorkflowNodeId} />
        } else if (currentNodeData?.ecl_node_id == 19) {
            return <FloorAndCaps key={currentWorkflowNodeId} />
        } else if (currentNodeData?.ecl_node_id == 20) {
            return <MappingBasedOnDiffColumn key={currentWorkflowNodeId} />
        } else if (currentNodeData?.ecl_node_id == 22) {
            return <SplitData key={currentWorkflowNodeId} />
        } else if (currentNodeData?.ecl_node_id == 23) {
            return <ZscoreStandardization key={currentWorkflowNodeId}/>
        } else if (currentNodeData?.ecl_node_id == 24) {
            return <NaturalLogTransformation key={currentWorkflowNodeId}/>
        } else if (currentNodeData?.ecl_node_id == 25) {
            return <MinMaxScaling key={currentWorkflowNodeId}/>
        } else if (currentNodeData?.ecl_node_id == 26) {
            return <CumulativeDistributionFunction key={currentWorkflowNodeId}/>
        } else if (currentNodeData?.ecl_node_id == 27) {
            return <PropbabilityDensityFUnction key={currentWorkflowNodeId}/>
        } else if (currentNodeData?.ecl_node_id == 29) {
            return <Binningandbucketing key={currentWorkflowNodeId} />
        } else if (currentNodeData?.ecl_node_id == 30) {
            return <Binningapercentiles key={currentWorkflowNodeId} />
        } else if (currentNodeData?.ecl_node_id == 31) {
            return <BinningUsermapping key={currentWorkflowNodeId}/>
        } else if (currentNodeData?.ecl_node_id == 32) {
            return <NormalizationUsingPercentiles key={currentWorkflowNodeId}/>
        } else if (currentNodeData?.ecl_node_id == 33) {
            return <NormalizationUsingDecimalScaling key={currentWorkflowNodeId}/>
        } else if (currentNodeData?.ecl_node_id == 34) {
            return <NormalizationUsingRobustScaling key={currentWorkflowNodeId}/>
        }                 
        
        return <></>
    }

    const windowHeight = window.innerHeight;
    const headerHeight = document.getElementById('header')?.clientHeight
    const footerHeight = document.getElementById('footer')?.clientHeight
    const pipeLineHeight = document.getElementById('pipeline')?.clientHeight
    const workflowHeaderHeight = document.getElementById('workflowHeader')?.clientHeight
    
    const calculatedHeight = windowHeight - (headerHeight + footerHeight + pipeLineHeight + workflowHeaderHeight);

    return (
        <div className='relative'>
            <div id="pipeline" className={`px-2 py-2  border-b border-[#f0f0f0] overflow-x-auto overflow-y-hidden thin-scrollbar w-full flex items-center`} >
                <div className='items-center flex' id="parent" >
                    {nodeList?.map((node, index) => {
                        return <NodeIcon onClick={() => onNodeClick(node)} key={index} eclNodeId={node?.ecl_node_id} id={node?.id} isLast={(nodeList?.length - 1) == index} onRemove={() => onNodeRemove(node)} location={location} />
                    })}
                    <div className={`${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} id="child" onDrop={onDrop} onDragOver={onDragOver} >
                        <button onClick={onNodeAddClick}><img src={addIcon} alt="" id="addIcon" className='xl:w-[3.2rem] w-[2.5rem]' /></button>
                    </div>
                </div>
                {error &&
                    <p className='text-error-red text-[12px] ml-2 whitespace-nowrap'>{error}</p>}
            </div>
            {/* <div className={`overflow-y-auto thin-scrollbar overflow-x-hidden`} id="container" style={{ height: location?.state?.isfromHistory ? (calculatedHeight - 80 + "px") : currentNodeData?.ecl_node_id < 3 ? calculatedHeight - 150 + "px" : currentNodeData?.ecl_node_id == 5 ? calculatedHeight - 120 + "px" :  calculatedHeight - 83 + "px" }}> */}
            <div className={`overflow-y-auto thin-scrollbar overflow-x-hidden`} id="container" style={{ height: location?.state?.isfromHistory ? (calculatedHeight - 80 + "px") : currentNodeData?.ecl_node_id < 3 ? calculatedHeight - 100 + "px" : currentNodeData?.ecl_node_id == 5 ? calculatedHeight - 50 + "px" :  calculatedHeight - 45 + "px" }}>
                {renderModules()}
            </div>
        </div>
    )
}

export default WorkflowNodes