import React, { useMemo, useState } from 'react'
import { Baseurl } from '../Utils/Constants';
import { CALCULATION_UNIQUE_VALUES, GET_MERGE_SUMMARY_RESULT } from '../Helpers/EndPoints';
import axios from 'axios';
import { createColumnHelper } from '@tanstack/react-table';
import { removeQuotes } from '../Helpers/utils';

const initialtableState = {
    pageIndex: 0,
    pageSize: 10,
    sortBy: "",
    sortOrder: "",
    columnPinning: {
        left: [],
        right: []
    },
    columnVisibility: {}
}

function useDataTable({ workflow_id, ecl_node_id, category_id, from ,parent_node_id}) {

    const [isExport, setIsExport] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(false)
    const [summaryData, setSummaryData] = useState([]);

    const [tableState, setTableState] = useState(initialtableState)


    const getFilterOptions = async (columnId) => {
        const response = await axios.post(`${Baseurl}${CALCULATION_UNIQUE_VALUES}`, {
            workflow_id,
            category_id,
            "column_name": columnId
        })
        if (response.status == 200) {
            return response.data.data ? response.data.data?.filter(dat => dat != null) : []
        } else {
            return []
        }
    }

    const updateFilterState = (columnId, values, isReset) => {
        let currentFilter = tableState?.columnFilters ? [...tableState?.columnFilters] : []

        if (isReset === true) {
            currentFilter = currentFilter?.filter(fil => fil.key != columnId) ?? []
        } else {
            if (currentFilter.length == 0) {
                currentFilter = [{
                    "key": columnId,
                    "values": values,
                    "select_all": false
                }]
            } else {
                const index = currentFilter.findIndex(ob => ob.key == columnId)
                if (index != -1) {
                    currentFilter[index].values = values
                } else {
                    currentFilter.push({
                        "key": columnId,
                        "values": values,
                        "select_all": false
                    })
                }
            }

        }

        setTableState(prev => ({ ...prev, pageIndex: 0, columnFilters: currentFilter }))
        apicallSummary({ page: 1, sort_by: tableState.sortBy, sort_order: tableState.sortOrder, filter_by_values: currentFilter })
        return currentFilter
    }

    const updateRangeFilterState = (columnId, values, isReset) => {
        let currentFilter = tableState?.columnFilters ? [...tableState?.columnFilters] : []

        if (isReset === true) {
            currentFilter = currentFilter?.filter(fil => fil.key != columnId) ?? []
        } else {
            const filterData = {
                "key": columnId,
                "operation": values.operation,
                "filterData": values,
                "values": values.operation === "between"
                    ? {
                        "min": values.min,
                        "max": values.max
                    }
                    : values.min
            }
            if (currentFilter.length == 0) {
                currentFilter = [filterData]
            } else {
                const index = currentFilter.findIndex(ob => ob.key == columnId)
                if (index != -1) {
                    currentFilter[index] = filterData
                } else {
                    currentFilter.push(filterData)
                }
            }

        }
        setTableState(prev => ({ ...prev, pageIndex: 0, columnFilters: currentFilter }))
        apicallSummary({ page: 1, sort_by: tableState.sortBy, sort_order: tableState.sortOrder, filter_by_conditions: currentFilter })
        return currentFilter
    }

    const pinApiCall = ({ pin }) => {
        const request = {
            workflow_id,
            ecl_node_id,
            category_id,
            page: 1,
            record_per_page: 1,
            is_export: false,
            pin,
        };
        axios(`${Baseurl + GET_MERGE_SUMMARY_RESULT}`, {
            method: "POST",
            data: request,
        })
    }

    const updateColumnPinning = (action, column) => {

        let columnPinning = tableState?.columnPinning ?? {
            right: [],
            left: []
        }

        if (action === "pinLeft") {
            columnPinning = {
                left: [...columnPinning?.left, column],
                right: columnPinning.right.filter(col => col != column)
            }
        } else if (action === "pinRight") {
            columnPinning = {
                right: [...columnPinning.right, column],
                left: columnPinning.left.filter(col => col != column)
            }
        } else {
            columnPinning = {
                right: columnPinning.right.filter(col => col != column),
                left: columnPinning.left.filter(col => col != column)
            }
        }

        setTableState(prev => ({ ...prev, columnPinning }))
        pinApiCall({ pin: { operation: action === "pinRight" ? "pinright" : action === "pinLeft" ? "pinleft" : "unpin", "key": column } })
    }

    const onTableStateChange = (action, column, data) => {
        if (action === "pagination") {
            setTableState(prev => ({ ...prev, pageIndex: column - 1 }))
            apicallSummary({ page: column, sort_by: tableState.sortBy, sort_order: tableState.sortOrder })
        } else if (action === "sorting") {

            if (column.sortOrder === false) {
                setTableState(prev => ({ ...prev, sortBy: "", sortOrder: "" }))
                apicallSummary({ page: tableState.pageIndex, sort_by: "", sort_order: "" })
            } else {
                setTableState(prev => ({ ...prev, pageIndex: 0, sortBy: column.sortBy, sortOrder: column.sortOrder }))
                apicallSummary({ page: 1, sort_by: column.sortBy, sort_order: column.sortOrder })
            }

        } else if (action === "filter") {
            const columnMeta = column?.columnDef?.meta;
            if (columnMeta?.filterType === "range") {
                updateRangeFilterState(column.id, data)
            } else {
                updateFilterState(column.id, data)
            }
        } else if (action === "filterReset") {
            const columnMeta = column?.columnDef?.meta;
            if (columnMeta?.filterType === "range") {
                updateRangeFilterState(column.id, null, true)
            } else {
                updateFilterState(column.id, null, true)
            }
        } else if (action === "pinRight" || action === "pinLeft" || action === "pinClear") {
            updateColumnPinning(action, column.id)
        }

        console.log({ action, column, data });
    }




    const dataTableColumns = useMemo(() => {
        const columnHelper = createColumnHelper();

        if (summaryData?.headers) {
            return summaryData?.headers?.map(headerData => {
                let isColumnColorChange = false

                if (from === "renameColumn") {
                    isColumnColorChange = summaryData?.renamedColumns?.includes(headerData?.accessorKey)
                }
                else if (from === "mismatchedDataTypes") {
                    isColumnColorChange = summaryData?.mismatchedDataTypes?.includes(headerData?.accessorKey)
                }
                 else if (from === "removeDuplicateRow") {
                    isColumnColorChange = summaryData?.removeDuplicateRowColumns?.includes(headerData?.accessorKey)
                } else if (from === "nullblank") {
                    isColumnColorChange = summaryData?.excepNullValues?.includes(headerData?.accessorKey)
                }else if(from === 'outlierAnalysis'){
                    isColumnColorChange = summaryData?.outlierAnalysis?.includes(headerData?.accessorKey)
                } else if (from === "zscore") {
                    isColumnColorChange = summaryData?.dtZscoreDatas?.includes(headerData?.accessorKey)
                }
                else if(from == 'imputationUserDefinedDatas'){
                    isColumnColorChange = summaryData?.imputationUserDefinedDatas?.includes(headerData?.accessorKey)
                }
                else if(from == 'floorCapDatas'){
                    isColumnColorChange = summaryData?.floorCapDatas?.includes(headerData?.accessorKey) 
                }else if(from == 'dtBucketPercentileDatas'){
                    isColumnColorChange = summaryData?.dtBucketPercentileDatas?.includes(headerData?.accessorKey) 

                } else if (from == "naturalLog") {
                    isColumnColorChange = summaryData?.dtNaturalLogDatas?.includes(headerData?.accessorKey)
                }
                else if (from == "dtBucketUserMappingDatas") {
                    isColumnColorChange = summaryData?.dtBucketUserMappingDatas?.includes(headerData?.accessorKey)
                }else if(from === 'binningBucketingEqualRange'){
                    isColumnColorChange = summaryData?.dtBucketRangeDatas?.includes(headerData?.accessorKey)
                }else if(from === 'createDuplicateColumnDatas'){
                    isColumnColorChange = summaryData?.createDuplicateColumnDatas?.includes(headerData?.accessorKey)
                } else if (from === 'unexpectedValues') {
                    isColumnColorChange = summaryData?.unexpectedValue?.includes(headerData?.accessorKey)
                } else if (from == 'imputationReplaceAverage') {
                    isColumnColorChange = summaryData?.imputationReplaceBasedOnAverageDatas?.includes(headerData?.accessorKey)
                }else if (from === "dtMinMaxScaling") {
                    isColumnColorChange = summaryData?.dtStandardizeUsingMinMaxScalingDatas?.includes(headerData?.accessorKey)
                }
                else{
                    if(from !== ''){
                    isColumnColorChange = summaryData?.[from]?.includes(headerData?.accessorKey)  
                }  
                                
                }
                

                return columnHelper.accessor(row => row[`${headerData?.accessorKey}`], {
                    id: headerData?.accessorKey,
                    header: () => {
                        if (isColumnColorChange) {
                            return <span className='text-[#00A7B5]'>{headerData?.header}</span>
                        }
                        return headerData?.header
                    },
                    cellClassName: isColumnColorChange ? "bg-[#D9FFFF]" : "bg-white",
                    size: 100,
                    maxSize: 120,
                    minSize: 80,
                    meta: {
                        filterType: headerData?.datatype === "VARCHAR" || headerData?.datatype === "DATE"
                            ? "select"
                            : "range",
                    },
                    cell: ({ getValue }) => (getValue() || getValue() == 0) ? getValue() : <></>
                })
            })
        }

        return []
    }, [summaryData?.headers])

    const getHeaders = () => {
        let headers = [...tableState.columnPinning.left].map(header => dataTableColumns.find(posHead => posHead.id == header)?.id);

        dataTableColumns.forEach(element => {
            if (!headers.includes(element.id) && !tableState.columnPinning.right?.includes(element.id)) {
                headers.push(element.id)
            }
        });
        tableState.columnPinning.right.forEach(element => {
            const el = dataTableColumns.find(posHead => posHead.id == element)?.id
            if (!headers.includes(el)) {
                headers.push(el)
            }
        });


        return headers?.filter(header => !summaryData?.removedDatas?.includes(header));
    }

    const apicallSummary = async ({ page = tableState?.pageIndex, sort_by = tableState?.sortBy, sort_order = tableState?.sortOrder, isFrom, filter_by_values, filter_by_conditions }) => {
        setIsDataLoading(isFrom != 'export');
        setIsExport(isFrom == 'export')
        const appliedFilterByvalues = (filter_by_values ? filter_by_values : tableState?.columnFilters ?? [])?.filter(fil => !fil.operation)
        const appliedFilterByCondition = (filter_by_conditions ? filter_by_conditions : tableState?.columnFilters ?? [])?.filter(fil => fil.operation)

        const request = {
            workflow_id,
            ecl_node_id,
            category_id,
            page: page,
            sort_by,
            sort_order,
            "record_per_page": 10,
            "is_export": isFrom === 'export',
            filter_by_values: appliedFilterByvalues ?? [],
            filter_by_conditions: appliedFilterByCondition ?? [],
            current_headers: isFrom == 'export' ? getHeaders() : null,
            parent_node_id:parent_node_id ?? ''
        }

        axios(`${Baseurl + GET_MERGE_SUMMARY_RESULT}`, {
            method: "POST",
            data: request,
            responseType: isFrom === 'export' ? 'blob' : 'json'
        })
            .then((response) => {
                setIsDataLoading(false);
                setIsExport(false);
                // console.log({ response, dd: isFrom === 'export' && response?.data });
                if (isFrom === 'export' && response?.data) {
                    const fileName = response.headers['content-disposition'].split('filename=')[1]
                    const blob = new Blob([response.data], { type: 'text/xls' });
                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    // link.setAttribute("download", `file.csv`);
                    link.setAttribute("download", removeQuotes(fileName)); // Extract filename from response headers
                    document.body.appendChild(link);
                    link.click();
                    // setIsExport(false);
                } else if (response?.data?.status == 200) {
                    const data = response.data?.mergedResults[0]
                    setSummaryData(data);
                    let columnVisibility = {}

                    data?.removedDatas?.forEach(header => {
                        columnVisibility[header] = false
                    });

                    setTableState(prev => ({
                        ...prev,
                        columnPinning: {
                            left: data?.pinLeftColumns,
                            right: data?.pinRightColumns,
                        },
                        columnVisibility,
                    }))
                    console.log("getMergedFilesTable", response.data);
                }
            })
            .catch((error) => {
                console.log("API not working", error);
            });


    }


    const refreshDataTable = () => {
        setTableState(initialtableState)
        apicallSummary({ page: 1, filter_by_conditions: [], filter_by_values: [], sort_by: "", sort_order: "" })
    }

    const donwloadXl = () => {
        apicallSummary({ isFrom: "export" })
    }



    const isExportLoading = isExport

    return {
        summaryData,
        setSummaryData,
        apicallSummary,
        onTableStateChange,
        tableState,
        getHeaders,
        dataTableColumns,
        getFilterOptions,
        isExportLoading,
        isDataLoading,
        refreshDataTable,
        donwloadXl,
    }

}

export default useDataTable