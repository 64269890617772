import { useFormik } from "formik";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  binningBuckettingNodeSave,
  detailbinningdataList,
  fetchNumericColumnList,
  getCalculationFormData,
  getNodeWiseCategoryList,
  openModalBuilder,
  saveBinningUserdefined,
  selectCurrentNodeData,
  selectCurrentNodeIds,
} from "../../Store/Workflow/WorkflowSlice";
import DropDown from "../DropDown/DropDown";
import { useLocation } from "react-router-dom";
import Button from "../Button";
import * as Yup from "yup";
import { createColumnHelper } from "@tanstack/react-table";
import SummaryTable from "../Table/SummaryTable";
import DataTableSkeleton from "../Skeleton/DataTableSkeleton";
//Components
import DataTable from "../Table/DataTable";

//imgaes
import XlsIcon from "../../Images/excel.svg";
import useDataTable from "../../hooks/useDataTable";
import downloadingIcon from '../../Images/gif/downloading.gif'

import {
  
  onChangebucketdata,
} from "../../Helpers/ApiHelpers";
import ProceedButton from "../CommonComponents/ProceedButton";

const columnHelper = createColumnHelper();

function BinningUsermapping() {
  const dispatch = useDispatch();
  const currentNodeData = useSelector(selectCurrentNodeData);
  const isBinningCalculatingData = useSelector(
    (state) => state?.Workflow?.ui?.isCalculating
  );
  const stateData = useSelector((state) => state);
  const location = useLocation();
  const isCalculating = useSelector(
    (state) => state?.Workflow?.ui?.isCalculating
  );
  const { currentEclNodeId, currentWorkflowId, currentWorkflowNodeId } =
    useSelector(selectCurrentNodeIds);
  const isProceedLoading = useSelector(
    (state) => state?.Workflow?.ui?.isProceedLoading
  );
  const [showFields, setShowFields] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [userdefinedData, setuserdefinedData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [showEditData, setShowEditData] = useState(false);
  const [categoryDisable, setCategoryDisable] = useState(false);
  const [showTable, setShowtable] = useState(false);
  const [onchangeData, setonchangeData] = useState([]);
  const [showtableData, setShowTableData] = useState(false);
  const [showdetail, setShowdetail] = useState(false);
  const [selectedColumnDataPointTable, setSelectedColumnDataPointTable] =
    useState([]);
  const [selectedColumnData, setselectedColumnData] = useState([]);
  const [showtable, setShowTable] = useState(false);
  const [disabledColumnNames, setDisabledColumnNames] = useState([]);

  console.log(disabledColumnNames, "disabledColumnNames");
  const formik = useFormik({
    initialValues: {
      category: currentNodeData?.nodeCategoryId,
      columnName: undefined,
    },
    validationSchema: Yup.object({
      category: Yup.string().required("Required"),
    }),
    enableReinitialize: true,
  });

  const {
    dataTableColumns,
    getFilterOptions,
    summaryData,
    tableState,
    refreshDataTable,
    onTableStateChange,
    isDataLoading,
    isExportLoading,
    donwloadXl,
  } = useDataTable({
    category_id: formik.values.category,
    ecl_node_id: currentEclNodeId,
    workflow_id: currentWorkflowId,
    from: "dtBucketUserMappingDatas",
  });

  const onCategoryChange = (value) => {
    formik.setFieldValue("category", value);
  };
  const onColumnNameChange = async (value) => {
    formik.setFieldValue("columnName", value);

    await BucketsUserOnClick(value);
  };

  const BucketsUserOnClick = async (columnName) => {
    console.log(columnName, "columnName");

    setIsDisabled(true);
    const request = {
      workflow_id: currentWorkflowId,
      ecl_node_id: currentEclNodeId,
      category_id: formik.values.category,
      column_name: columnName,
    };
    const response = await onChangebucketdata(request);
    if (response.data?.status == 200) {
      setIsDisabled(false);
      setonchangeData(response?.data?.dtBinningUserMappingDatas || []);
      setShowTableData(response?.data?.isEdit);
      setShowtable(true);
    }
    console.log(response, "onChanung response");
  };

  // const BinningUserData = useMemo(() => {
  //     return onchangeData?.buckets ??[]
  // }, [onchangeData]);

  // const [binningUserTableData, setBinningUsertableData] = useState(BinningUserData);
  // useEffect(() => {
  //     setBinningUsertableData(BinningUserData);
  // }, [BinningUserData]);

  const BinningUserData = useMemo(() => {
    return onchangeData?.buckets ?? [];
  }, [onchangeData]);

  const [binningUserTableData, setBinningUsertableData] = useState([]);

  useEffect(() => {
    // Filter out rows with null or empty values
    const filteredData = BinningUserData.filter(
      (row) =>
        row.unique_value !== null &&
        row.unique_value !== "" &&
        row.mapped_value !== null &&
        row.mapped_value !== ""
    );
    setBinningUsertableData(filteredData);
    setShowTable(filteredData.length > 0); // Display the table if there is valid data
  }, [BinningUserData]);

  console.log(binningUserTableData, "binningUserTableData");
  console.log(BinningUserData, "BinningUserData");

  const getValueByIndex = (index) => formik.values.userEnterValue?.at(index);

  const handleInputChange = (index, event) => {
    const newValue = event.target.value;
    setBinningUsertableData((prevData) =>
      prevData.map((item, idx) =>
        idx === index ? { ...item, mapped_value: newValue } : item
      )
    );
  };
  const MappingDataColumns = useMemo(() => {
    return [
      columnHelper.accessor("unique_value", {
        header: "Unique values",
      }),
      columnHelper.accessor("mapped_value", {
        header: "Mapped values",
        cell: ({ row }) => {
          const value = getValueByIndex(row.index) || undefined;
          const dataType = row.original?.mapped_value;
          return (
            <div>
              <input
                className="outline-none border border-[#E0DCD7] px-2 py-0.5 rounded-[4px]"
                defaultValue={row.original?.mapped_value}
                value={value}
                type="number"
                min="0"
                onChange={(event) => handleInputChange(row.index, event)}
              />
            </div>
          );
        },
      }),
    ];
  }, [BinningUserData]);

  const onClickAdd = () => {
    setLoading(true);
    setDisabledColumnNames((prev) => [...prev, formik.values.columnName]);

    setselectedColumnData((prev) => [
      ...prev,
      {
        column_name: formik.values.columnName,
        bucket_data: binningUserTableData,
      },
    ]);

    setSelectedColumnDataPointTable((prev) => [
      ...prev,
      ...binningUserTableData?.map((data, i) => ({
        column_name: formik.values.columnName,
        unique_values: data?.unique_value,
        mapped_values: data?.mapped_value,
      })),
    ]);
    setLoading(false);
    setonchangeData([]);
    setShowFields(true);
    setCategoryDisable(true);
    formik.setFieldValue("columnName", undefined);
  };

  console.log(onchangeData, "onchangeData");
  console.log(BinningUserData, "BinningData");

  const {
    nodeWiseCategory,
    filesMerging,
    isUserTableDataLoading,
    numericColumnList,
  } = useSelector((state) => ({
    nodeWiseCategory: state?.Workflow?.nodeWiseCategory,
    filesMerging: state?.Workflow?.ui?.filesMerging,
    isUserTableDataLoading: state?.Workflow?.ui?.isDataLoading,
    numericColumnList: state?.Workflow?.numericColumnList,
  }));

  useEffect(() => {
    dispatch(
      getNodeWiseCategoryList({
        workflow_id: currentWorkflowId,
        ecl_node_id: currentEclNodeId,
        workflow_node_id: currentWorkflowNodeId,
      })
    );
  }, []);

  const BucketData = useMemo(() => {
    if (currentNodeData?.bucketMappingTableData) {
      setShowFields(true);
      setSelectedColumnDataPointTable(
        currentNodeData?.bucketMappingTableData?.dtBinningUserMappingDatas
      );

      setShowTableData(currentNodeData?.bucketMappingTableData?.isEdit);
      setShowEditData(currentNodeData?.node_status == "Completed");
    }
    return currentNodeData?.bucketMappingTableData ?? [];
  }, [currentNodeData?.bucketMappingTableData]);

  console.log(currentNodeData, "bcurrentNodeData");

  console.log(BucketData, "BucketData ");

  useEffect(() => {
    if (currentNodeData?.id && currentNodeData?.nodeCategoryId) {
      onCategoryDataSave();
    }
  }, [currentNodeData?.nodeCategoryId, currentNodeData?.id]);

  const onSave = () => {
    setLoading(true);
    const request = {
      ecl_workflow_id: currentWorkflowId,
      ecl_node_id: currentEclNodeId,
      ecl_category_id: formik.values.category,
      dt_binning_user_mapping_undo_datas: [],

      dt_binning_user_mapping_datas: selectedColumnData,
    };
    dispatch(saveBinningUserdefined(request)).then((res) => {
      if (res.type == `${saveBinningUserdefined.fulfilled}`) {
        // onCategorySave()  ;
        const requestFormData = getCalculationFormData({
          client_branch_level_id: location?.state?.levelTwoCardId,
          isFrom: "splitdata",
          category_id: formik?.values?.category,
          node_id: currentEclNodeId,
          state: stateData,
          nodeStatus: "Completed",
        });

        dispatch(binningBuckettingNodeSave({ requestFormData }));
        setLoading(false);
      }
    });
  };

  const onProceedClick = () => {
    dispatch(openModalBuilder());
  };

  const onCategoryDataSave = () => {
    const request = {
      workflow_id: currentWorkflowId,
      ecl_node_id: currentEclNodeId,
      category_id: formik.values.category,
    };
    dispatch(detailbinningdataList(request)).then((res) => {
      if (res.type == `${detailbinningdataList.fulfilled}`) {
        refreshDataTable();
        setLoading(false);
        setShowFields(true);
        setonchangeData([]);
        formik.setFieldValue("columnName", undefined);
      }
    });
  };
  useEffect(() => {
    if (currentNodeData?.nodeCategoryId) {
      dispatch(
        fetchNumericColumnList({
          workflow_id: currentWorkflowId,
          ecl_node_id: currentEclNodeId,
          category_id: currentNodeData?.nodeCategoryId,
        })
      );
    }
  }, [currentNodeData?.nodeCategoryId]);

  const BucketDataColumns = useMemo(() => {
    return [
      columnHelper.accessor("column_name", {
        header: "Column name",
        mergeCells: true,
      }),
      columnHelper.accessor("unique_values", {
        header: "Unique Value",
      }),
      columnHelper.accessor("mapped_values", {
        header: "Mapped Value",
      }),
    ];
  }, [BinningUserData]);

  const isAddButtonDisabled = () => {
    return binningUserTableData.some((data) => !data?.mapped_value);
  };

  console.log(currentNodeData?.dtBinningUserMappingDatas?.isEdit,"currentNodeData?.dtBinningUserMappingDatas?.isEdit");
  return (
    <div className="my-2">
      {filesMerging ? (
        <div className="mt-2">
          <DataTableSkeleton
            showHeader={true}
            columnWidths={""}
            rowCount={5}
            columnCount={7}
            showPagination={true}
            showActionableButtons={false}
            from="mergeSummary"
            isDownload={false}
          />
        </div>
      ) : (
        <div className={`flex gap-5 ${showEditData ? "hidden" : "block"} `}>
          <DropDown
            dropDownOptions={nodeWiseCategory?.map((val) => ({
              label: val?.category_name,
              value: val?.category_id,
              disabled: val.is_disabled,
            }))}
            placeholder={"Select data category"}
            name={"usedCategory"}
            ordinary={true}
            disabled={location?.state?.isfromHistory || showEditData}
            customTags={false}
            value={
              formik.values?.category ? formik.values?.category : undefined
            }
            onOptionChange={onCategoryChange}
          />
          <Button
            bgColor="#42B2AC"
            borderType=""
            disabled={
              !formik.values?.category ||
              location?.state?.isfromHistory ||
              showEditData
            }
            label={"Save"}
            color={"#ffffff"}
            onClick={onCategoryDataSave}
            size={"md"}
          />
        </div>
      )}

      <div
        className={`w-[100%] ${
          showEditData ? "hidden" : "block"
        } flex gap-5 mt-[1.5rem]`}
      >
        <div
          className={`w-[50%] flex gap-5  ${showFields ? "block" : "hidden"} `}
        >
          <DropDown
            dropDownOptions={numericColumnList?.map((obj) => ({
              label: obj?.column_label,
              value: obj?.column_name,
              disabled:
                disabledColumnNames.includes(obj?.column_name) ||
                BucketData?.bucketMappingTableData?.dtBinningUserMappingDatas?.some(
                  (method) => method?.column_name === obj?.column_name
                ),
            }))}
            placeholder={"Select Column Name"}
            name={"column name"}
            ordinary={true}
            disabled={
              location?.state?.isfromHistory || isBinningCalculatingData
            }
            customTags={false}
            noDataFountMessage={'File must have integer column'}
            value={
              formik.values?.columnName ? formik.values?.columnName : undefined
            }
            onOptionChange={(value) => onColumnNameChange(value)}
          />
        </div>

        {BinningUserData.length > 0 && (
          <div className={`${showTable ? "block w-[50%]" : "hidden"}`}>
            <SummaryTable
              columns={MappingDataColumns}
              data={binningUserTableData}
              thClassName="!bg-[#4339A8] pl-[5rem]"
              tdClassName="pl-[5rem]"
            />
          </div>
        )}
        {/* 1 */}
      </div>
      {BinningUserData.length > 0 && (
        <div
          className={`flex gap-3 ${
            showTable ? "block" : "hidden"
          } justify-center`}
        >
          <div className={`flex justify-center mt-4 `}>
            <Button
              bgColor="#42B2AC"
              borderType=""
              disabled={location?.state?.isfromHistory || isAddButtonDisabled()}
              label={"Add"}
              color={"#ffffff"}
              onClick={onClickAdd}
              size={"md"}
            />
          </div>
        </div>
      )}

      <div className={``}>
        {isDataLoading ||
        isCalculating ||
        selectedColumnDataPointTable?.length > 0 ? (
          isDataLoading || isCalculating ? (
            <div className="mt-2">
              <DataTableSkeleton
                showHeader={true}
                columnWidths={""}
                rowCount={5}
                columnCount={7}
                showPagination={false}
                showActionableButtons={false}
                from="mergeSummary"
                isDownload={false}
              />
            </div>
          ) : (
            selectedColumnDataPointTable?.length > 0 && (
              <div>
                <div className="mt-[2rem]">
                  <div
                    className={`bg-[#2B144C] px-2 flex justify-between items-center mt-[2rem] ${
                      showEditData ? "block" : "hidden"
                    }`}
                  >
                    <div className="flex gap-3 items-center py-2">
                      <h3 className="text-[#FFFFFF] text-[12px] whitespace-nowrap bg-[#F7BE7E] px-2 py-1 rounded-[4px] ">
                        Data category
                      </h3>
                      <p className="font-semibold text-[#FFFFFF] text-[10px] whitespace-nowrap">
                        {BucketData?.categoryName}
                      </p>
                    </div>
                    <h2 className="text-white text-[12px] font-semibold text-center w-full">
                      Calculated output
                    </h2>
                    {/* <h2 className='text-white text-[12px] font-semibold text-center w-full'></h2>                     */}
                  </div>
                  <div className={`${showEditData ? "mb-[2rem]" : ""}`}>
                    <SummaryTable
                      columns={BucketDataColumns}
                      data={selectedColumnDataPointTable}
                      tdClassName="border-b border-[#E0DCD7] text-[#707070] whitespace-nowrap max-w-[14rem] w-[12rem]"
                      thClassName="bg-[#A06DFF] max-w-[14rem] w-[12rem]"
                      className=""
                    />
                  </div>
                </div>

                <div
                  className={`flex justify-center mt-4 ${
                    showEditData ? "hidden" : "block"
                  }`}
                >
                  <Button
                    bgColor="#42B2AC"
                    borderType=""
                    disabled={false}
                    label={"Save"}
                    color={"#ffffff"}
                    onClick={onSave}
                    size={"md"}
                  />
                </div>
              </div>
            )
          )
        ) : (
          <></>
        )}
      </div>
      {((summaryData?.columnDatas?.length > 0 ||
        isDataLoading ||
        filesMerging ||
        isCalculating ||
        isUserTableDataLoading) &&
        isDataLoading) ||
      isUserTableDataLoading ? (
        <div className="mt-2">
          <DataTableSkeleton
            showHeader={true}
            columnWidths={""}
            rowCount={5}
            columnCount={7}
            showPagination={true}
            showActionableButtons={false}
            from="mergeSummary"
            isDownload={false}
          />
        </div>
      ) : summaryData?.columnDatas?.length > 0 ? (
        <>
          <div className={`flex justify-end items-center`}>
            {isExportLoading ? (
              <img src={downloadingIcon} className='w-6 mr-2' />
            ) : (
              <img
                src={XlsIcon}
                className=" mb-2 mr-2 object-cover cursor-pointer w-4 h-4 xl:w-5 xl:h-5"
                onClick={donwloadXl}
              />
            )}
          </div>
          <DataTable
            columns={dataTableColumns}
            data={summaryData?.columnDatas ?? []}
            onTableStateChange={onTableStateChange}
            totalCount={summaryData?.totalRecords}
            isOptionsDisabled={location?.state?.isfromHistory}
            getFilterOptions={getFilterOptions}
            tableState={{
              pagination: {
                pageIndex: tableState.pageIndex,
                pageSize: tableState.pageSize,
              },
              sorting: tableState?.sortBy
                ? [
                    {
                      id: tableState?.sortBy,
                      desc: tableState?.sortOrder === "desc",
                    },
                  ]
                : [],
              columnFilters: tableState.columnFilters
                ? tableState.columnFilters?.map((fil) => ({
                    id: fil.key,
                    value: fil.values,
                    filterData: fil?.filterData,
                  }))
                : [],
              columnPinning: tableState.columnPinning,
              columnVisibility: tableState?.columnVisibility,
            }}
          />
          {currentNodeData?.dtBinningUserMappingDatas?.isEdit ==
          false ? null : (
            <div
              className={`float-right 2xl:my-6 my-3 ${
                !location?.state?.isfromHistory && showEditData? "block" : "hidden"
              }`}
            >
                  <ProceedButton
                    isLoading={isProceedLoading}
                    onClick={onProceedClick}
                  />                 
            </div>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default BinningUsermapping;
