
import {
  MODEL_BUILDER_LIST_API_ERROR,
  MODEL_BUILDER_LIST,
  MODEL_BUILDER_LIST_FETCH_SUCCESS,
  ECL_HISTORY_API_ERROR,
  ECL_HISTORY_FETCH_SUCCESS,
  ECL_HISTORY,
  NODE_DETAILS,
  NODE_DETAILS_FETCH_SUCCESS,
  NODE_DETAILS_API_ERROR,
  GATEGORY_LIST,
  GATEGORY_LIST_API_ERROR,
  GATEGORY_LIST_FETCH_SUCCESS,
  SINGLE_FILE_UPLOAD,
  SINGLE_FILE_UPLOAD_SUCCESS,
  SINGLE_FILE_UPLOAD_API_ERROR,
  SINGLE_FILE_UPLOAD_LIST,
  SINGLE_FILE_UPLOAD_LIST_FETCH_SUCCESS,
  SINGLE_FILE_UPLOAD_LIST_API_ERROR,
  SINGLE_FILE_UPLOAD_PERTICULAR_LIST,
  SINGLE_FILE_UPLOAD_PERTICULAR_LIST_FETCH_SUCCESS,
  SINGLE_FILE_UPLOAD_PERTICULAR_LIST_API_ERROR,
  DELETE_NODE,
  DELETE_NODE_API_ERROR,
  DELETE_NODE_SUCCESS,
  FILE_UPLOAD_SUMMARY,
  FILE_UPLOAD_SUMMARY_SUCCESS,
  FILE_UPLOAD_SUMMARY_API_ERROR,
  DELETE_WORKFLOW,
  DELETE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_API_ERROR,
  NODE_DETAILS_DATA_CLEAR,
  CHECK_WORKFLOW,
  CHECK_WORKFLOW_SUCCESS,
  CHECK_WORKFLOW_API_ERROR,
  MERGE_DATA_WORKFLOW,
  MERGE_DATA_WORKFLOW_SUCCESS,
  MERGE_DATA_WORKFLOW_API_ERROR,
  PERTICULAR_NODE_DATA_CLEAR,
  DOWNLOAD_MERGE_SUMMARY,
  DOWNLOAD_MERGE_SUMMARY_SUCCESS,
  DOWNLOAD_MERGE_SUMMARY_API_ERROR,
  CHECK_WORKFLOW_DATA_CLEAR,
  FILE_UPLOAD_SUMMARY_CLEAR,
  MERGE_DATA_CLEAR,
  TABLE_UPDATION,
  TABLE_UPDATION_SUCCESS,
  TABLE_UPDATION_API_ERROR,
  TABLE_UPDATION_DATA_CLEAR,
  GATEGORY_LIST_DATA_CLEAR,
  GET_FILE_DETAILS,
  GET_FILE_DETAILS_SUCCESS,
  GET_FILE_DETAILS_API_ERROR,
  FILE_DETAILS_DATA_CLEAR,
  SET_IS_DATA_LOADING,

} from './ActionType'


export const setIsDataLoading = (isLoading) => {
  return {
      type: SET_IS_DATA_LOADING,
      payload: isLoading
  };
};

export const ModelBuilderList = () => {
  return {
    type: MODEL_BUILDER_LIST
  };
};

export const ModelBuilderListFetchSuccess = (payload) => ({
  type: MODEL_BUILDER_LIST_FETCH_SUCCESS,
  payload: payload,
});

export const ModelBuilderError = error => {
  return {
    type: MODEL_BUILDER_LIST_API_ERROR,
    payload: error,
  };
};

export const getEclHistory = (id, sort, record, key) => {
  return {
    type: ECL_HISTORY,
    payload: { id, sort, record, key },
  };
};

export const EclHistoryFetchSuccess = (payload) => ({
  type: ECL_HISTORY_FETCH_SUCCESS,
  payload: payload,
});

export const EclHistoryError = error => {
  return {
    type: ECL_HISTORY_API_ERROR,
    payload: error,
  };
};
export const getNodeDetails = (id) => {
  return {
    type: NODE_DETAILS,
    payload: {id},
  };
};

export const NodeDetailsFetchSuccess = (payload) => ({
  type: NODE_DETAILS_FETCH_SUCCESS,
  payload: payload,
});

export const NodeDetailsError = error => {
  return {
    type: NODE_DETAILS_API_ERROR,
    payload: error,
  };
};
//category list 
export const getCategoryList = (id,level_two_id) => {
  return {
    type: GATEGORY_LIST,
    payload: {id,level_two_id},
  };
};

export const CategoryListFetchSuccess = (payload) => ({
  type: GATEGORY_LIST_FETCH_SUCCESS,
  payload: payload,
});

export const CategoryListError = error => {
  return {
    type: GATEGORY_LIST_API_ERROR ,
    payload: error,
  };
};

export const CategoryListDataClear = (payload) => ({
  type: GATEGORY_LIST_DATA_CLEAR,
  payload: payload,
});

//single file upload
export const singleFileUpload = (payload) => {
  // console.log(payload,'paylod form data');
  return {
    type: SINGLE_FILE_UPLOAD,
    payload: payload,
  };
};

export const singleFileUploadSuccess = (payload) => ({
  type: SINGLE_FILE_UPLOAD_SUCCESS,
  payload: payload,
});

export const singleFileUploadApiError = error => {
  return {
    type: SINGLE_FILE_UPLOAD_API_ERROR ,
    payload: error,
  };
};

//single file upload  list
export const getSingleFileUploadList = (payload) => {
  return {
    type: SINGLE_FILE_UPLOAD_LIST,
    payload: payload,
  };
};

export const singleFileUploadListFetchSuccess = (payload) => ({
  type: SINGLE_FILE_UPLOAD_LIST_FETCH_SUCCESS,
  payload: payload,
});

export const singleFileUploadListApiError = error => {
  return {
    type: SINGLE_FILE_UPLOAD_LIST_API_ERROR ,
    payload: error,
  };
};

//single file upload perticular list
export const getSingleFilePerticularList = (payload) => {
  return {
    type: SINGLE_FILE_UPLOAD_PERTICULAR_LIST,
    payload: payload,
  };
};

export const singleFilePerticularFetchSuccess = (payload) => ({
  type: SINGLE_FILE_UPLOAD_PERTICULAR_LIST_FETCH_SUCCESS,
  payload: payload,
});

export const singleFilePerticularApiError = error => {
  return {
    type: SINGLE_FILE_UPLOAD_PERTICULAR_LIST_API_ERROR ,
    payload: error,
  };
};

//delete perticular node 

export const getDeleteNode = (payload) => {
  return {
    type: DELETE_NODE,
    payload: payload,
  };
};

export const deleteNodeSuccess = (payload) => ({
  type: DELETE_NODE_SUCCESS,
  payload: payload,
});

export const deleteNodeApiError = error => {
  return {
    type: DELETE_NODE_API_ERROR ,
    payload: error,
  };
};

//file upload summary 

export const getFileUploadSummary = (payload) => {
  return {
    type: FILE_UPLOAD_SUMMARY,
    payload: payload,
    meta: {
      isDataLoading:true
  }
  };
};

export const fileUploadSummarySuccess = (payload) => ({
  type: FILE_UPLOAD_SUMMARY_SUCCESS,
  payload: payload,
});

export const fileUploadSummaryApiError = error => {
  return {
    type: FILE_UPLOAD_SUMMARY_API_ERROR ,
    payload: error,
  };
};

export const fileUploadSummaryClear = (payload) => {
  return {
    type: FILE_UPLOAD_SUMMARY_CLEAR,
    payload: payload,
  };
};

//delete workflow

export const deleteWorkFlow = (payload) => {
  return {
    type: DELETE_WORKFLOW,
    payload: payload,
  };
};

export const deleteWorkFlowSuccess = (payload) => ({
  type: DELETE_WORKFLOW_SUCCESS,
  payload: payload,
});

export const deleteWorkFlowApiError = error => {
  return {
    type: DELETE_WORKFLOW_API_ERROR ,
    payload: error,
  };
};

//data clear on workflow

export const NodeDetailsDataClear = (payload) => ({
  type: NODE_DETAILS_DATA_CLEAR,
  payload: payload,
});
//perticular node data clear
export const perticularNodeDataClear = (payload) => ({
  type: PERTICULAR_NODE_DATA_CLEAR ,
  payload: payload,
});

//check workflow node

// export const checkWorkFlow = (payload) => {
//   return {
//     type: CHECK_WORKFLOW,
//     payload: payload,
//   };
// };

// export const checkWorkFlowSuccess = (payload) => ({
//   type: CHECK_WORKFLOW_SUCCESS,
//   payload: payload,
// });

// export const checkWorkFlowApiError = error => {
//   return {
//     type: CHECK_WORKFLOW_API_ERROR ,
//     payload: error,
//   };
// };

// export const checkWorkFlowDataClear = (payload) => {
//   return {
//     type: CHECK_WORKFLOW_DATA_CLEAR,
//     payload: payload,
//   };
// };

//merge summary node

export const mergeSummaryFlow = (workflow_id,ecl_node_id) => {
  return {
    type: MERGE_DATA_WORKFLOW,
    payload: {workflow_id,ecl_node_id},
  };
};

export const mergeSummaryFlowSuccess = (payload) => ({
  type: MERGE_DATA_WORKFLOW_SUCCESS,
  payload: payload,
});

export const mergeSummaryFlowApiError = error => {
  return {
    type: MERGE_DATA_WORKFLOW_API_ERROR ,
    payload: error,
  };
};
export const mergeSummaryDataClear = (payload) => ({
  type: MERGE_DATA_CLEAR,
  payload: payload,
});


//download csv mergeSummary

export const downloadMergeSummary = (request,fileName) => {
  return {
    type: DOWNLOAD_MERGE_SUMMARY,
    payload: {request,fileName},
  };
};

export const downloadMergeSummarySuccess = (payload) => ({
  type: DOWNLOAD_MERGE_SUMMARY_SUCCESS,
  payload: payload,
});

export const downloadMergeSummaryApiError = error => {
  return {
    type: DOWNLOAD_MERGE_SUMMARY_API_ERROR ,
    payload: error,
  };
};

//API for Data Quality Review Table Changes

export const tableUpdation = (payload) => {
  return {
    type: TABLE_UPDATION,
    payload: {payload},
  };
};

export const tableUpdationSuccess = (payload) => ({
  type: TABLE_UPDATION_SUCCESS,
  payload: {payload},
});

export const tableUpdationApiError = error => {
  return {
    type: TABLE_UPDATION_API_ERROR ,
    payload: error,
  };
};
export const tableUpdateionDataClear = (payload) => ({
  type: TABLE_UPDATION_DATA_CLEAR,
  payload: {payload},
});

export const getFileDetails = (workflow_id,workflow_node_id,category_id) => ({
  type: GET_FILE_DETAILS,
  payload: {workflow_id,workflow_node_id,category_id},
});
export const fileDetailsFetchSuccess = (payload) => ({
  type: GET_FILE_DETAILS_SUCCESS,
  payload: payload,
});

export const fileDetailsFetchApiError = (payload) => ({
  type: GET_FILE_DETAILS_API_ERROR,
  payload: {payload},
});

export const fileDetailsDataClear = (payload) => ({
  type: FILE_DETAILS_DATA_CLEAR,
  payload: {payload},
});




