import {
    CHECK_WORKFLOW_SUCCESS,        
} from './ActionType'

const initalState = {    
    isLoading: true,
    detailsLoading:true
}

const CheckWorkFlowReducer = (state = initalState, action) => {    
    switch (action.type) {
        
        case CHECK_WORKFLOW_SUCCESS:
            // console.log(action?.payload,'CHECK_WORKFLOW_SUCCESS');
        state = {
        ...state,
        isLoading: false,
        reviewStatusData:action?.payload
        };   
        break;     
        default:
            state = { ...state };
            break;
        }
    return state;
};

export default CheckWorkFlowReducer;