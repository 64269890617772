
//Dependecies
import React from 'react'
import { useState, useEffect } from 'react'
import { useForm} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
//imgaes
import user from '../../Images/userProfile.png'
import key from '../../Images/key.svg'
//components
import Warning from '../Indications/Warning'
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../Store/action';
import withRouter from '../../Routes/withRouter';
import { NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import RouteSegments from '../../Routes/RouteSegments';
import axios from 'axios';
import { Baseurl } from '../../Utils/Constants';


const schema = yup.object().shape({
    newPassword: yup.string().required('Please enter password').matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
      // /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()\-_=+{};:'",.<>/?[\]\\^_`|~]*$/,
          'Password must be minimum 8 characters and alpha-numeric'
      ),
    confirmPassword: yup.string().required('Please enter confirm password').oneOf([yup.ref("newPassword"),null],"Passwords don't match"),

});

const ResetPassword = (props) => {
  const { register, handleSubmit, setFocus, formState: { errors} } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token'); // Replace 'token' with the actual parameter name
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showFields, setShowFields] = useState(false)
  const [error, setError] = useState('')
  const onSubmit = (data) => {
    // Append the token to the data object
    const newData = {
      ...data,
      token: token,
    };

    dispatch(resetPassword(newData, props.router.navigate));
  };

  useEffect(() => {
   if(errors?.firstname == undefined){
    setFocus("firstname");}
  }, [setFocus]);
  const {resetPasswordData} =useSelector(state => {
    return {
      resetPasswordData:state?.login?.resetPasswordData
    }
  })

  const navigate = useNavigate()
  useEffect(()=>{
    if(resetPasswordData){
      toast.success(resetPasswordData?.message)
      navigate(RouteSegments.HOME)
    }

  },[resetPasswordData])

  useEffect(()=>{
    axios(`${Baseurl}/setPassword/linkverify`, {
      method: "POST",  
      data:{
        "token":token
      } 
      })
        .then((response) => {
          if(response?.data?.status){
            setShowFields(true)
          }
         if(response?.data?.status == 205){
            setError(response?.data?.message)
          }
        })
        .catch((error) => {
          console.log("API not working", error);
        })  
  },[])

  return (
    <div className="w-full flex items-center justify-end h-full absolute right-[10%]">
      <div className="w-[75%] max-w-sm">
        <div className="p-4">
          <div className="py-3 flex items-center before:flex-[1_1_0%] before:border-t before:border-carrot-orange ltr:before:mr-6 rtl:before:ml-6 after:flex-[1_1_0%] after:border-t after:border-carrot-orange ltr:after:ml-6 rtl:after:mr-6 dark:text-white/70 dark:before:border-white/10 dark:after:border-white/10">
            <div className="w-[5rem] mx-auto">
              <img alt="userlogo" className='z-10' src={user} />
            </div>
          </div>
          <div className="mt-5">
          { showFields ?
           error ?
           <>
          <p className='text-[18px] text-center text-greeny-blue'>{error}</p>
          <div className='self-center text-center mt-6'>
          <NavLink to={RouteSegments.HOME} className="w-full">
                <button type="button" className="w-fit px-4 rounded py-1.5 text-white bg-red-orange focus:outline-none text-[16px]">Back to login</button>
          </NavLink>
          </div>
          </>
          :
            <form onSubmit={handleSubmit(onSubmit)}>
              {
                errors?.newPassword && errors?.newPassword.type=="required"  && errors?.confirmPassword && errors?.confirmPassword.type=="required" ?
                  <p className="text-yellow text-center text-sm mb-2">Please fill all the fields</p>
                : errors?.newPassword && errors?.newPassword.type=="required"  ? 
                  <p className="text-yellow text-center text-sm mb-2">{errors?.newPassword.message}</p>
                : errors?.newPassword && errors?.newPassword.type!=="required" ?
                  <p className="text-error-red text-center text-sm mb-2">{errors?.newPassword.message}</p>             
                : errors?.confirmPassword && errors?.confirmPassword.type=="required" ? 
                  <p className="text-yellow text-center text-sm mb-2">{errors?.confirmPassword.message}</p>              
                : errors?.confirmPassword && errors?.confirmPassword.type!=="required"?
                  <p className="text-error-red text-center text-sm mb-2">{errors?.confirmPassword.message}</p>
                 :<></>
              }
              
              <div className="relative mb-4">
                <img src={key} className="absolute top-1/2 left-3 transform -translate-y-1/2 w-4 brightness-0 invert-[1]" />
                <input type="password" id="newPassword" placeholder="New Password" name="newPassword"
                  {...register("newPassword",{
                    onChange:(e)=>{
                      setPassword(e.target.value)
                    }
                  })}
                  value={password}
                  className={`w-full text-white rounded px-0 py-2.5 ${errors?.newPassword ? errors?.newPassword?.type=="required" ? 'border border-yellow' : 'border border-error-red' :''} focus:outline-none pl-[2.5rem] bg-charcoal-gray placeholder:text-white text-[14px]`}
                  autoComplete="off" />
                {errors?.newPassword &&
                  <div className="absolute top-1/2 right-3 transform -translate-y-1/2 w-4" >
                    <Warning circleBg={errors?.newPassword && errors?.newPassword.type=="required" ? "yellow" : "#FF6174"} />
                  </div>}
              </div>
              <div className="relative mb-4">
                <img src={key} className="absolute top-1/2 left-3 transform -translate-y-1/2 w-4 brightness-0 invert-[1]" />
                <input type="password" id="confirmPassword" placeholder="Re-enter new password" name="confirmPassword"
                  {...register("confirmPassword",{
                    onChange:(e)=>{
                      setConfirmPassword(e.target.value)
                    }
                  })}
                  value={confirmPassword}
                  className={`w-full text-white rounded px-0 py-2.5 ${errors?.confirmPassword?.type=="required" ? 'border border-yellow' : errors?.confirmPassword?.type=="oneOf" ? 'border border-error-red' :''} focus:outline-none pl-[2.5rem] bg-charcoal-gray placeholder:text-white text-[14px]`}
                  autoComplete="off" />
                {errors?.confirmPassword &&
                  <div className="absolute top-1/2 right-3 transform -translate-y-1/2 w-4" >
                    <Warning circleBg={errors?.confirmPassword && errors?.confirmPassword.type=="required" ? "yellow" : "#FF6174"} />
                  </div>}
              </div>
           
              <div className="mt-6 flex items-center justify-center">
                <button type="submit" disabled={!password || !confirmPassword}
                className={`w-full rounded py-1.5 text-white ${!password || !confirmPassword ? 'bg-ash':'bg-greeny-blue  hover:bg-light-sea-green ' } focus:bg-gray-600 focus:outline-none text-[16px]`}>Set password</button>
              </div>
              
            </form>
            :<></>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(ResetPassword);


