import React from 'react'
import '../../CSS/Skeleton.css'

//skeleton loader
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function CardSkeleton({ cards, menu, marigin }) {

    return (
        <div className={`${'grid grid-cols-3 gap-5 mt-4 ml-[4rem] mr-[3rem]'}`}>
            {Array(cards)
                .fill(0)
                .map((item, i) => (
                    <div className={`w-full col-span-1 z-0 ${marigin ? '2xl:my-[1.5rem] my-[1rem]':''}`} key={i}>
                        <div className="bg-[#e0e0e0] shadow-[1px_1px_5px_1px_rgb(232,232,232)] rounded">
                            <div className="w-full sahdow-lg overflow-hidden p-2">
                                <Skeleton className='2xl:h-7 h-5 skeletonColor ' />
                                <div className='2xl:p-3 p-1'>
                                    <Skeleton className='card-skeleton-text skeletonColor'/>
                                    <Skeleton className='card-skeleton-line skeletonColor'/>
                                    <div className='flex gap-6'>
                                        <div className='flex items-center gap-2'>
                                            <Skeleton className='card-skeleton-text'  />
                                            <Skeleton circle height={30} width={30} className='skeletonColor'/>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <Skeleton className='card-skeleton-text skeletonColor'/>
                                            <Skeleton circle height={30} width={30} className='skeletonColor'/>
                                        </div>
                                    </div>
                                    <Skeleton className='card-skeleton-line skeletonColor' />
                                    <div className='flex gap-3'>
                                        <Skeleton className='card-skeleton-text skeletonColor'/>
                                        <Skeleton className='card-skeleton-textlong skeletonColor'/>
                                    </div>
                                    <div className='flex gap-3'>
                                        <Skeleton className='card-skeleton-text skeletonColor'/>
                                        <Skeleton className='card-skeleton-textlong skeletonColor'/>
                                    </div>
                                    <div className='grid grid-cols-12 w-full content-center'>
                                        <Skeleton className='card-skeleton-icons skeletonColor col-span-2' />
                                        <div className='col-span-10'>
                                        <Skeleton className='card-skeleton-line skeletonColor'/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}
