import React, { useState, useEffect, useRef } from 'react';
import { Tooltip } from 'antd';
// import 'antd/dist/antd.css';

const OverflowTooltip = ({ text, className = "lg:text-[12px] text-[13px]" }) => {
  const [isOverflowed, setIsOverflowed] = useState(false);
  const textRef = useRef();

  useEffect(() => {
    const handleOverflow = () => {
      const element = textRef.current;
      setIsOverflowed(element.scrollWidth > element.clientWidth);
    };

    handleOverflow(); // Check initially

    // Listen for resize events
    window.addEventListener('resize', handleOverflow);

    return () => {
      // Cleanup the event listener on component unmount
      window.removeEventListener('resize', handleOverflow);
    };
  }, []);

  return (
    <Tooltip title={isOverflowed ? text : null} placement='top'>
      <div ref={textRef} className={`${className}`} style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
        {text}
      </div>
    </Tooltip>
  );
};

export default OverflowTooltip;