// depentancies
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

//images components
import ModelBuilderIcon from '../CommonIcons/ModelBuilderIcon';
import CloseIcon from '../CommonIcons/CloseIcon';
import classNames from 'classnames';
import dropdownIcon from '../../Images/Polygon 1.svg';
//skeleton
import ModelBuilderSkeleton from '../Skeleton/ModelBuilderSkeleton';
import { PipelineSkelton } from '../Skeleton/NewCalculationSkeleton';

//constants
import { ImgBaseUrl, localStorageKeys } from '../../Utils/Constants';

//images
import addIcon from "../../Images/Group 201.svg";
import removeFile from "../../Images/removeFIle.svg";
import * as icons from '../CommonIcons/ModelBuilderIconsSrc'; 

//redux components
import { NodeDetailsDataClear, checkWorkFlowDataClear, fileUploadSummaryClear, getDeleteNode, getNodeDetails, mergeSummaryDataClear, perticularNodeDataClear } from '../../Store/action';
import { useDispatch, useSelector } from 'react-redux';

export const DragAndDropComponent = ({ closeDetails, handleItemClick, activeIndices, handleCardClick, drag, step, modelBuilderOpen,showSummaryLoader,clonedImages,workflowNodeData}) => {
  const location = useLocation() ;
  const sections = JSON.parse(localStorage.getItem(localStorageKeys.modelBuilderList)) ?? [];
  const isLoading = localStorage.getItem(localStorageKeys.pipelineSkeletonLoading); 

    return (
        <div id="rightPanel"
            className={`md:w-[45%] xl:w-[38%] lxl:w-[33%] 4xl:w-[28%] 5xl:w-[25%] 6xl:w-[22%] fixed right-0 xl:top-[3.9rem] top-[2.45rem] z-[999] h-full transform transition-transform ease-in-out duration-1000 ${modelBuilderOpen ? 'translate-x-0' : 'translate-x-full'
                }`}>
            <div className={`h-full border-l-2 border-[#42B2AC] model-builder-height-reduce`}>
                <div className={`w-full bg-[#42B2AC] flex justify-between py-5 pl-[1rem] pr-[1.2rem] h-[6%]`}>
                    <div className='flex items-center gap-2 '>
                        <ModelBuilderIcon className={'2xl:w-[25px] 2xl:h-[20px] w-[22px] h-[16px]'} bgColor={'#ffffff'} />
                        <p className='text-white font-semibold 2xl:text-[15px] text-[13px]'>{"Model Builder"}</p>
                    </div>
                    <div className='flex items-center gap-2 cursor-pointer' onClick={() => closeDetails('modelBuilder')}>
                        <p className='text-white font-semibold 2xl:text-[15px] text-[13px]'>Close</p>
                        <CloseIcon className={'2xl:w-[15px] 2xl:h-[15px] w-[12px] h-[12px]'}  bgColor={'#ffffff'} />
                    </div>
                </div>
                <div className={`w-full overflow-y-auto thin-scrollbar h-[91%] xlg:h-[92%] xl:h-[91%] lxl:h-[93%] bg-white`}>
                    <div className='py-2 px-[1rem]'>
                      {/* acordion header */}
                        {sections?.length >0 ?
                            sections?.map((item, index) => (
                                <div key={index} id={"section" + item.id} className="2xl:mb-4 mb-3">
                                    <div
                                        className="flex items-center justify-between p-2 bg-gray-200 cursor-pointer bg-[#331D53] text-white rounded"
                                        onClick={() => handleItemClick(index)}
                                    >
                                        <span className="2xl:text-[13px] text-[12px] text-center ">{item?.name}</span>
                                        <img
                                            src={dropdownIcon}
                                            className={classNames('2xl:w-3 2xl:h-3 w-[8px] h-[8px] brightness-0 invert-[1]', {
                                                'transform rotate-180': activeIndices.includes(index),
                                            })}
                                            alt="Dropdown Icon"
                                        />
                                    </div>
                                    {/* accordion content */}
                                    {activeIndices?.includes(index) && (
                                        <div className="py-3 px-3 bg-gray-100">
                                            <div className='grid grid-cols-3 items-center gap-3 2xl:gap-5'>
                                                {item?.ecl_nodes?.map((data, i) => {                                                                                                                                                                                                                                                                      
                                                    return (
                                                        <div key={i} 
                                                        onClick={(event) =>{
                                                          if(!location?.state?.isfromHistory && isLoading !='yes' ) {                                                              
                                                              handleCardClick(event, data?.id,item?.id)                                                              
                                                            }
                                                          }}
                                                        className={`col-span-1 mx-auto min-h-[7.5rem] h-[9rem] w-[8.5rem] min-w-[7.2rem] bg-[#E7E6E6] hover:bg-[#D0CECE] rounded-lg flex flex-col gap-3 p-1.5 items-center justify-center 
                                                         ${showSummaryLoader ? 'pointer-events-none'
                                                         :step < 4 || location?.state?.isfromHistory || (clonedImages?.length ==0 && item?.id > 1) || item?.id > 2 ||isLoading =='yes' || workflowNodeData?.length <= 0 && data?.id ==4  
                                                         ? 'cursor-not-allowed' 
                                                         : 'cursor-pointer shake'}`}>
                                                            <p className='text-[#331D53] xl:text-[12px] text-[11px] text-center'>{data?.name}</p>
                                                            <img id={data?.id} onDragStart={(event) => drag(event, data?.id,item.id)}
                                                                draggable={(step < 4 || location?.state?.isfromHistory || (clonedImages?.length ==0 && item?.id > 1)|| item?.id > 2 || isLoading =='yes' || workflowNodeData?.length <= 0 && data?.id ==4) ? false : true}
                                                                src={ImgBaseUrl + data?.image_name} className={`drag-enabled h-[30px] w-[25px]`} />
                                                            <p className='text-[#242B3B] text-[10px] text-center leading-[14px]'>{data?.description}</p>
                                                        </div>
                                                            
                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))
                            : <ModelBuilderSkeleton cards={3} />
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export const DropComponent = ({
    setModelBuilderOpen,
    workflow_node_id,
    ecl_workflow_id,
    selectedTileId,
    setSelectedTile,
    isFileSetToS3,setIsFileSentToS3,
    setIsNew,
    onDraggedTileClick,
    setCategory,
    workflowNodeData,
    setSelectedTileId,
    setClonedImages,
    error,
    setError,
    clonedImages,   
    setDeleteing,
    uploadAnother,
    saveAndProceed,
    setSaveAndProceed,
    categoryChanged,
    isUpdate,
    setIsUpdate,
    setDropped,
    setNearestNodeClicked,
    setUploadAnother,
    draggedTileId,
    setMergeSummaryLoader,
    clickedNode,   
    setClickedNode, 
    setShowSummaryLoader,
    replaceClicked,
    setCurrentAccordinOpen,
    setRendered,
    rendered,
    setCategoryId,
    setMergeSummaryTableData,
    setAssignDataTypeFormatData,
    setRenameColumnList,
    setRemoveColumnList,
    setRemoveDuplicateRowList

 }) => {
       const dispatch = useDispatch()
       const clonedImageRef = useRef()
       const location = useLocation();
       const [isLoading, setIsLoading] = useState(false);
       const sameCategory= localStorage.getItem(localStorageKeys.sameCategoryExist) 

       const {CheckWorkFlowData} = useSelector(state => {
        return {     
          CheckWorkFlowData: state?.CheckWorkFlowReducer?.reviewStatusData,     
        }
      })
    
      

       useEffect(()=>{  
        if(workflowNodeData?.length > 0){
           workflowNodeData?.map((items,i)=>{
            replaceParentElements(items,i,workflowNodeData?.length)
           })  
          const imageContaineLength = document.getElementsByClassName("image-container")?.length
          console.log("imageContaineLength ", workflowNodeData?.length, imageContaineLength);
           setIsLoading(false)    
        }else if(workflowNodeData?.length <= 0){
          setIsLoading(false)
        }
                
        if(workflowNodeData?.length ==0 ){
          setClonedImages([])
          setShowSummaryLoader(false)
        }
       },[workflowNodeData])

      useEffect(() => {
        if (clickedNode?.id !=null && !rendered) {
        setIsLoading(true);
        if(clickedNode.id > 0){
          drop(clickedNode.event, clickedNode.id, sameCategory);
          setRendered(true)
        }else if(clickedNode.id > 4 && Object.keys(CheckWorkFlowData)?.length > 0 && clickedNode?.by =='click'){
            if (CheckWorkFlowData?.sameCategoryExist) {
              drop(clickedNode.event, clickedNode.id, sameCategory);
              setRendered(true)
            }else{
              drop(clickedNode.event, clickedNode.id, sameCategory);
              setRendered(true)
            }    
        }    
        setIsLoading(false);
        localStorage.removeItem(localStorageKeys.pipelineSkeletonLoading);
      }
      }, [clickedNode?.id,CheckWorkFlowData,rendered]);
       
       const apiCallDeleteNode = async (request,workFlowId) => {
        try {
          const result = await  dispatch(getDeleteNode(request));
          // dispatch(NodeDetailsDataClear())
          // dispatch(perticularNodeDataClear())
          setShowSummaryLoader(true)
          if(result){
            setTimeout(async () => {
              try{
                const result = await dispatch(getNodeDetails(workFlowId));
                if(result){
                  setDeleteing(false)                   
                }
              }
              catch (error){
                console.error('Error uploading file:', error);
              }
            }, 4000);
        }   
         } catch (error) {
          setDeleteing(true) 
          console.error('Error uploading file:', error);
        }
      };

       const handleCloseIconClick = (id,selectedId,workFlowId,workFlowNodeId) => {
        // console.log(id,selectedId,workFlowId,workFlowNodeId,'delete request');
        localStorage.removeItem(localStorageKeys.sameCategoryExist)
        dispatch(perticularNodeDataClear())
        dispatch(NodeDetailsDataClear())
        dispatch(fileUploadSummaryClear())
        dispatch(mergeSummaryDataClear())
        setMergeSummaryTableData([])
        setClickedNode({ event: null, id: null,by:'delete' })
        dispatch(checkWorkFlowDataClear())
        setDeleteing(true)
        setShowSummaryLoader(true)
        setNearestNodeClicked(true)
        setIsFileSentToS3(true)
        setUploadAnother(true)
        setSaveAndProceed(false)
        setCurrentAccordinOpen(null)   
        if(draggedTileId == 4){
          setCategoryId(null)
          setMergeSummaryTableData([])
        }
       const request = {
        "ecl_workflow_node_id":workFlowNodeId,
        "workflow_id":workFlowId,
        "ecl_node_id":selectedId
     }

      if((workFlowNodeId !=null) && (workFlowId ) && (selectedId)){
        apiCallDeleteNode(request,workFlowId)
        setError(false)        
       }
       else{
        if(workFlowId == null){ 
          setNearestNodeClicked(true)
          setIsNew(false)
          setIsUpdate(false)
          setError(false)
          setDeleteing(false) 
          setShowSummaryLoader(false)
          localStorage.removeItem(localStorageKeys.lastNodeStatus)      
        }else{
          //for workflow node id null   
          dispatch(getNodeDetails(workFlowId))
          setNearestNodeClicked(true)
          setError(false)
          setIsNew(false)
          setIsUpdate(false)  
          setDeleteing(false)  
          localStorage.removeItem(localStorageKeys.lastNodeStatus)      
        }
       }

        setClonedImages((prevClonedImages) => {
          // Find the index of the removed image
          const removedIndex = prevClonedImages.findIndex((image) => image.clonedImageId == id);
          
          // Remove the image
          const updatedClonedImages = [...prevClonedImages.slice(0, removedIndex), ...prevClonedImages.slice(removedIndex + 1)];
      
          // Set selectedTileId to the selectedFieldId of the last element (if available)
          const lastElement = updatedClonedImages.length > 0 ? updatedClonedImages[updatedClonedImages.length - 1].selectedFieldId : null;
          // setSelectedTileId(lastElement);
      
          return updatedClonedImages;
        });
      
        const imageContainer = document.querySelector(`#${id}`);
        const databaseIcon = document.querySelector(`#databaseIcon`)
        if (imageContainer) {
          const hrElement = document.getElementById(`${id}hr`);
          if (hrElement) {
            hrElement.remove();
          }
          if(databaseIcon){
            databaseIcon.remove();
          }
          imageContainer.remove();
        }

      };  
     
      const drop = (event,clickedNodeId) => {          
          event.preventDefault();    
          event.stopPropagation();  
          setAssignDataTypeFormatData({})
          setRenameColumnList({});
          setRemoveColumnList({});
          setRemoveDuplicateRowList({});
          setMergeSummaryTableData([])                          
        try{
          if((isFileSetToS3 && uploadAnother) || clonedImages?.length ==0 ){
            localStorage.setItem(localStorageKeys.lastNodeStatus,'pending')
            setSelectedTileId(draggedTileId)                
          setDropped(true)
          setNearestNodeClicked(false)
          setSaveAndProceed(false)
          setModelBuilderOpen(false)
          setSelectedTile(true)          
          // setCategory(null)   
          categoryChanged=true       
          setIsFileSentToS3(false)
          setIsNew(true)
          setIsUpdate(false)
          // var data = event.dataTransfer.getData("text");
          var data = clickedNodeId ? clickedNodeId : JSON.parse(event.dataTransfer.getData("text"));  
          // console.log(data,clickedNodeId,'data for cloned');        
          var nodeCopy = document.getElementById(data)?.cloneNode(true);
          const dynamicId = `clonedImage_${new Date().getTime()}`;
          // const categoryUniqueId = ;
          nodeCopy.draggable = false;
          nodeCopy.id=dynamicId;
          nodeCopy.ref=clonedImageRef;
          nodeCopy.className = `self-center cursor-pointer relative ${data?.ecl_node_id == 2 ? 'max-w-[1.7rem] max-h-[1.8rem]' : 'max-h-[2rem] max-w-[2rem]'} color-change-image`;
          nodeCopy.onclick = () =>{dispatch(perticularNodeDataClear());onDraggedTileClick(data,dynamicId,null,null) }
          nodeCopy.title= workflow_node_id
          // Create a close icon
          var closeIcon = document.createElement('img');
          closeIcon.src=removeFile ;
          closeIcon.id='closeIcon'
          closeIcon.className="close-icon absolute top-[-1px] cursor-pointer right-[-1px] h-3 w-3"
          // closeIcon.onclick = () => handleCloseIconClick(dynamicId);
          closeIcon.onclick = () => handleCloseIconClick(dynamicId,selectedTileId,ecl_workflow_id,null);
      
          var hrElement = document.createElement('hr');
          hrElement.className = 'self-center bg-[#B8B5B5] text-[#B8B5B5] w-[2.5rem] max-w-[4.5rem] h-[3px]';
          hrElement.id = nodeCopy.id + 'hr';
      
          // Create a container for the image and close icon
          var imageContainer = document.createElement('div');
          imageContainer.className = `image-container relative self-center ${nodeCopy.id}`;
          imageContainer.appendChild(nodeCopy);          
            imageContainer.appendChild(closeIcon);

          // Append the imageContainer and hrElement before the addIcon
          const parentContainer = clickedNodeId ? document.getElementById('parent') : event.currentTarget.parentElement;
          parentContainer.insertBefore(imageContainer, parentContainer.lastChild);
          parentContainer.insertBefore(hrElement, parentContainer.lastChild);
          // console.log(parentContainer,"parentContainer");

           document.getElementsByClassName('clonedImage_1705579273328')
          // imageContainer.className = `image-container relative self-center ${nodeCopy.id} ${parentContainer?.includes(nodeCopy.id) ? 'block' : 'none'}`;

      
          // var insertBefore = event.currentTarget.className === "w-[3rem] self-center";
      
          // if (!insertBefore) {
          //   event.currentTarget.parentElement.insertBefore(imageContainer, event.currentTarget);
          //   event.currentTarget.parentElement.insertBefore(hrElement, event.currentTarget);
          // }else {
          //   event.currentTarget.parentElement.insertBefore(hrElement, event.currentTarget);
          //   event.currentTarget.parentElement.insertBefore(imageContainer, event.currentTarget);
          // }
          setClonedImages((prevClonedImages) => [
            ...prevClonedImages,
            { selectedFieldId:data, clonedImageId: dynamicId },
          ]);
          document.getElementById(hrElement.id).addEventListener('drop', drop);
          document.getElementById(hrElement.id).addEventListener('dragover', allowDrop);
          }else{
              setError(true)
              setModelBuilderOpen(false)                
              }
        }
          catch (error) {
            // Handle errors
            console.error('Error replacing parent elements:', error);
          }
      };
    
      const replaceParentElements = (data,index,length) => {
        setSelectedTileId(data?.ecl_node_id)      
        setDropped(true)
        try {
          // Replace this with your logic to create and append elements to the parent
          const parentElement = document.getElementById('parent'); // Replace 'parent' with your actual parent element ID
          const dynamicId = `clonedImage_${new Date().getTime()}`;
          // Create a close icon
          var closeIcon = document.createElement('img');
          closeIcon.src = removeFile;
          closeIcon.className = `close-icon absolute top-[-1px] cursor-pointer right-[-1px] h-3 w-3`;
          closeIcon.onclick = () => handleCloseIconClick(dynamicId,data?.ecl_node_id,data.ecl_workflow_id,data?.id);
      
          // Create a new image node
          var nodeCopy = document.createElement('img');
          nodeCopy.src = icons[`Id${data?.ecl_node_id}`]
          nodeCopy.draggable = false;
          nodeCopy.id = dynamicId;
          nodeCopy.ref = clonedImageRef;
          nodeCopy.className = `${length - 1 === index && (saveAndProceed || isUpdate) ? 'color-change-image' : ''} cursor-pointer ${data?.ecl_node_id == 2 ? 'max-w-[1.7rem] max-h-[1.8rem]' : 'max-h-[2rem] max-w-[2rem]'}`;
          nodeCopy.onclick = () => onDraggedTileClick(data?.ecl_node_id, dynamicId, data?.id,data?.nodeCategoryId);
          nodeCopy.title= data?.id
          // Create a new hr line
          var hrElement = document.createElement('hr');
          hrElement.className = 'self-center w-[2.5rem] max-w-[4.5rem] h-[3px] bg-[#B8B5B5] text-[#B8B5B5]';
          hrElement.id = nodeCopy.id + 'hr';
          // Create a new image container div
          var imageContainer = document.createElement('div');
          imageContainer.className = `image-container relative self-center ${nodeCopy.id}`;
          // imageContainer.className = `image-container relative self-center`;
          imageContainer.appendChild(nodeCopy);

          if (!location?.state?.isfromHistory) {
            imageContainer.appendChild(closeIcon);
          }        
          // Remove dynamic elements if they exist
          if(index==0){
          const existingDynamicElements = document.querySelectorAll('.image-container, hr');
          existingDynamicElements.forEach((element) => {
            element.remove();
          });
        }
      
          // Append elements in the correct order
          parentElement.appendChild(imageContainer);
          parentElement.appendChild(hrElement);
          var addImage = document.getElementById('addIcon') ;
          addImage.className=location?.state?.isfromHistory ? 'pointer-events-none w-[3.2rem]':'cursor-pointer w-[3.2rem]'
          parentElement.appendChild(addImage); // Replace 'addIcon' with the actual ID of your addIcon element
        // if(initialRedering){
          setClonedImages((prevClonedImages) => [
            ...prevClonedImages,
            { selectedFieldId: data?.ecl_node_id, clonedImageId: dynamicId },
          ]);
        // }      
          // document.getElementById(hrElement.id).addEventListener('drop', drop);
          // document.getElementById(hrElement.id).addEventListener('dragover', allowDrop);
        } catch (error) {
          // Handle errors
          console.error('Error replacing parent elements:', error);
        }
      };
                       
      const allowDrop = (ev) => {
        ev.preventDefault();
      };

      const onPlusIconCLick =()=>{
        if(((isFileSetToS3 && uploadAnother) || clonedImages.length ==0) && !replaceClicked) {
        setModelBuilderOpen(true)
      }
      else{
        setError(true);
      }
      
      }


  useLayoutEffect(() => {
    // Select all elements with the specified class
    var elements = document.querySelectorAll('.close-icon');

    elements.forEach(el => {
      el.style.display = "none"
    })

    // Get the last element from the NodeList
    var lastElement = elements[elements.length - 1];

    // Now you can manipulate the lastElement as needed
    if (lastElement) {
      lastElement.style.display = "block"
    }

    // console.log("close-elements", elements);
  })
      
    return (
      <>
        {isLoading ? <PipelineSkelton /> : <></>}
             
        <div className={`px-2 xl:py-2 py-1 border-b border-[#f0f0f0] overflow-x-auto thin-scrollbar w-full items-center ${isLoading ? 'hidden':'flex'}`} >
          <div className='items-center flex' id="parent" >
            <div className={`${location?.state?.isfromHistory ? 'pointer-events-none':'cursor-pointer'}`} id="child" onDrop={(event) => drop(event)} onDragOver={(event) => allowDrop(event)} >
                <img src={addIcon} alt="" id="addIcon" className='xl:w-[3.2rem] w-[2.5rem]' onClick={() => onPlusIconCLick() }/>
            </div>  
          </div>
            {error &&
            <p className='text-error-red text-[12px] ml-2'>Current step must be completed first</p>}          
        </div>
        
      </>
        
    )
}

