import { DatePicker, Select } from 'antd';
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import dayjs from "dayjs"
import { useLocation } from 'react-router-dom';
import CalenderIcon from '../../Components/CommonIcons/CalenderIcon';
import { checkTemplateName, nextStep, setCalculationDate, setCurrentTemplateName, showMenu } from '../../Store/Workflow/WorkflowSlice';
import DownArrow from '../../Components/CommonIcons/DownArrow';
import Button from '../../Components/Button';
import moment from 'moment';

const { Option } = Select;


function WorkflowSteps({formik, goToPrevPage }) {

    const [calAprochDropDown, setCalAprochDropDown] = useState(false)

    const location = useLocation()
    const dispatch = useDispatch()

    const step = useSelector((state) => state?.Workflow?.currentStep)
    const {showMenu,currentTemplateName} = useSelector(state => (
        {showMenu: state?.Workflow?.ui?.showMenu,
        currentTemplateName  : state?.Workflow?.currentTemplateName
        }
    ))
    const calculationApproach = useSelector(state => state?.calculationApproachList)
    const isCalculationDateDisabled = location?.state?.isfromHistory || step > 1


    const onNextClick = () => {
        dispatch(nextStep({calculationDate:formik?.values?.calculationDate}))
        // dispatch(setCalculationDate(formik?.values?.calculationDate));

    }

    const onTemplateChange = (e) => {
        formik.handleChange(e)
    }

    const onProceed = () => {
        dispatch(checkTemplateName(formik?.values?.currentTemplateName))        

    }    
    const disabledDate = current => {
        return current && current > moment().endOf('day');
      };
    return (
        <div className={`transition-all duration-700 ${showMenu ? 'w-[22%] xlg:p-2 p-1' : 'w-0 mt-2 opacity-0 brightness-0 invert-[1] invisible'} h-full bg-[#F2F2F2] relative`}>
            <div className='h-[92%] overflow-y-auto thin-scrollbar'>
                {step >= 1 &&
                    <div className='space-y-2'>
                        <div className='bg-[#4F2D7F] text-white text-[11px] 2xl:text-[14px] 2xl:p-2.5 xl:p-2 p-[0.3rem] whitespace-nowrap'>
                            Step 1 – Calculation date
                        </div>
                        <DatePicker
                            key={step}
                            id={"CalculationDate"}
                            value={formik?.values?.calculationDate ? dayjs(formik?.values?.calculationDate, 'DD-MMM-YYYY') : undefined}
                            name="calculationDate"
                            onBlur={formik.handleBlur}
                            showSearch={false}
                            allowClear={false}
                            placement="bottomLeft"
                            size="small"
                            className="custom-select-height text-[11px] pl-[0.5rem] custom-disabled-datepicker placeholder:text-[#242B3B]"

                            style={{
                                width: "100%",
                                border: " 2px solid #00A7B5",
                                borderRadius: '4px',
                                cursor: isCalculationDateDisabled ? 'not-allowed' : 'pointer',
                                backgroundColor: '#ffffff',
                            }}
                            disabled={isCalculationDateDisabled}
                            showToday={false}
                            disabledDate={disabledDate}
                            placeholder="Select date"
                            onChange={(date) => {
                                const val = dayjs(date).format('DD-MMM-YYYY')

                                formik.setFieldValue("calculationDate",val)                                
                            }
                            }
                            format={"DD-MMM-YYYY"}
                            suffixIcon={<CalenderIcon color="#707070" className={"xl:w-[15px] xl:h-[15px] w-[12px] h-[12px]"} />}
                        />
                        <p className={`text-[#331D53] underline 2xl:text-[14px] text-[11px] text-center font-semibold ${formik?.values?.calculationDate && step == 1 ? 'cursor-pointer' : 'pointer-events-none cursor-not-allowed'}`} onClick={formik?.values?.calculationDate ? onNextClick : null}>Save and proceed</p>
                    </div>
                }
                {step >= 2 &&
                    <div className='space-y-2 mt-4'>
                        <div className='bg-[#4F2D7F] text-white 2xl:text-[14px] text-[11px] 2xl:p-2.5 xl:p-2 p-[0.3rem] whitespace-nowrap'>
                            Step 2 – Calculation approach
                        </div>
                        <Select
                            showSearch
                            onDropdownVisibleChange={setCalAprochDropDown}
                            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                            name="calculationApproach"
                            onChange={(e) => {
                                // field.onChange(e);
                                // setCalculationApproach(e)
                            }}
                            suffixIcon={<DownArrow color="#cad3d0" className={`${calAprochDropDown ? 'transition-transform transform rotate-180' : ''} xl:w-[12px] xl:h-[12px] h-[10px] w-[10px]`} />}
                            placeholder="Select from pipeline"
                            disabled={location?.state?.isfromHistory || step > 2}
                            className={"custom-select-height custom-disabled-select border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"}
                            status={formik?.errors?.calculationApproach ? "error" : ""}
                            style={{
                                width: "100%",
                                border: " 2px solid #00A7B5",
                                borderRadius: '4px',
                                backgroundColor: '#ffffff',
                                cursor: location?.state?.isfromHistory || step > 2 ? 'not-allowed' : 'pointer',
                            }}
                        >
                            {calculationApproach?.map((currency, i) => (
                                <Option key={currency?.id} value={currency?.id}>
                                    {currency?.currency}
                                </Option>
                            ))}
                        </Select>

                        <p className={`text-[#331D53] underline 2xl:text-[14px] text-[11px] text-center font-semibold ${calculationApproach?.length > 0 && step == 2 ? 'cursor-pointer' : 'pointer-events-none'}`}>Save and proceed</p>
                        <p className='text-[#331D53] text-center text-[11px] 2xl:text-[14px]'>or</p>
                        <div className='flex items-center justify-center'>
                            <Button bgColor='#00A7B5' borderType="" label={'Set up new'} color={'#ffffff'} onClick={onNextClick} size={'md'} paddingY={'xl:py-1.5 py-1 rounded-[5px]'} disabled={!formik?.values?.calculationDate || step != 2} />
                        </div>

                    </div>
                }
                {step >= 3 &&
                    <div className='space-y-2 mt-4'>
                        <div className='bg-[#4F2D7F] text-white  text-[11px] 2xl:text-[14px] 2xl:p-2.5  xl:p-2 p-[0.3rem] whitespace-nowrap'>
                            Step 3 – Pipeline name
                        </div>
                        <input type="text" placeholder="Enter pipeline name" disabled={step == 4} onChange={onTemplateChange} name='currentTemplateName' value={formik?.values?.currentTemplateName} className={"w-full border-[2px] focus:border-[2px] rounded custom-select-height 2xl:text-[14px] text-[13px] pl-3 border-greeny-blue focus:outline-greeny-blue text-[#BEBEBE] placeholder:text-[#BEBEBE]"} />
                        <div className='flex items-center justify-center'>
                            <Button bgColor='#00A7B5' borderType="" label={'Proceed'} color={'#ffffff'} onClick={onProceed} size={'md'} paddingY={'xl:py-1.5 py-1 rounded-[5px]'} disabled={!formik?.values?.currentTemplateName || formik?.values?.currentTemplateName == null || step == 4} />
                        </div>

                    </div>
                }
            </div>
            <div className='w-full h-[8%]'>
                <div className='flex items-center justify-center w-full h-full'>
                    <div className='text-[#331D53] 2xl:text-[14px] text-[11px] cursor-pointer underline text-center whitespace-nowrap ' onClick={() => goToPrevPage()}>Exit wizard</div>
                </div>
            </div>
        </div>
    )
}

export default WorkflowSteps