import React from 'react'

const MinusIcon = ({ color, height, width,className }) => {
    return (
<svg
    fill={color}
    className={className}
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 83 83"
    space="preserve"
  >
    <g>
      <path d="M81,36.166H2c-1.104,0-2,0.896-2,2v6.668c0,1.104,0.896,2,2,2h79c1.104,0,2-0.896,2-2v-6.668 C83,37.062,82.104,36.166,81,36.166z" />
    </g>
  </svg>
);
    
}

export default MinusIcon
