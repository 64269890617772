import { React, useState } from "react";
import Button from '../Button';
import CloseIcon from '../../Images/popup-close.svg'

function ConfirmPopup({
    show,
    message,
    onChange,
    closeBtnName = "No, Cancel",
    activeBtnName = "Yes, I'm Sure",
    isAlertOnly = false,
    title = "Alert !",
    icon,
    isFrom ,
    onSubmit,
    onCancel,
    value,
    activeBtnColor,
    forWarning
}) {
    const [columnName, setColumnName] = useState(value);
    const handleChange = (event) => {
        const inputValue = event.target.value;
        const regex = /^[a-zA-Z0-9 ]*$/;
    
        if (regex.test(inputValue)) {
            setColumnName(inputValue);
        }    
    }
    return (
        !show
            ? <></>
            : <div className="relative z-[101]" aria-labelledby="modal-title" role="dialog" aria-modal="true">

                <div onClick={() => onChange(1)} className="h-screen w-screen fixed inset-0 bg-black bg-gray-500 bg-opacity-75 transition-opacity"></div>

                <div className="fixed inset-0 z-[101]">                    
                    <div className="flex min-h-full justify-center text-center items-center">
                       { isFrom=='rename' 
                       ? <form onSubmit={()=>onSubmit(columnName)} className="bg-white p-6 space-y-4 rounded-lg">
                        <div className="w-full">
                            <input className="w-[20rem] text-[14px] placeholder:text-[#BEBEBE] rounded-md px-0 py-1.5 border border-greeny-blue focus:border focus:border-greeny-blue focus:outline-greeny-blue bg-white placeholder:tracking-[3px] pl-[1rem]" value={columnName} placeholder="Enter new column name" onChange={handleChange}/>  
                        </div>
                        <div className="flex gap-4 float-right"> 
                        <Button bgColor="#2F5597"
                                borderType="normal"
                                type="submit"
                                size={'md'}
                                label={'Save'}
                                color={"#FFFFFF"}
                                borderColor={'#2F5597'}
                                disabled={!columnName}
                                onClick={() =>onSubmit(columnName)}
                        />   
                        <Button bgColor="#ffffff"
                                    borderType="normal"
                                    type="button"
                                    size={'md'}
                                    label={closeBtnName}
                                    color={"#000000"}
                                    onClick={() => onCancel()}
                                />  
                        </div>                    
                         {/* </div> */}
                         </form>
                       :
                        <div className="bg-white px-[3rem] py-[2rem] space-y-4 rounded-lg relative">
                            <img src={CloseIcon} className="absolute  w-6 right-[-0.7rem] top-[-0.8rem] cursor-pointer" onClick={() => onChange(0)}/>

                            <div className="flex items-center gap-2">
                                {icon || <div className="mx-auto flex flex-shrink-0 items-center justify-center rounded-full bg-red-100">
                                    <svg className="h-6 w-6 error-red" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="red" aria-hidden="true">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 10.5v3.75m-9.303 3.376C1.83 19.126 2.914 21 4.645 21h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 4.88c-.866-1.501-3.032-1.501-3.898 0L2.697 17.626zM12 17.25h.007v.008H12v-.008z" />
                                    </svg>

                                </div>}
                                <p className={`${forWarning ? 'text-[#5B0000] text-[17px] font-bold': 'text-gray-500 text-[14px]'} `}>{message}</p>
                            </div>
                            <div className="flex gap-4 justify-center pt-5">                               
                                {!isAlertOnly &&

                                    <Button bgColor={ activeBtnColor ?  activeBtnColor : "#2F5597"}
                                        borderType="normal"
                                        type="button"
                                        size={'xl'}
                                        paddingY="py-2.5"
                                        borerRadius="rounded-[5px]"
                                        label={activeBtnName}
                                        color={"#FFFFFF"}
                                        onClick={() => onChange(1)}
                                    />}
                                    <Button bgColor="#ffffff"
                                    borderType="normal"
                                    type="button"
                                    size={'xl'}
                                    paddingY="py-[0.7rem]"
                                    borerRadius="rounded-[5px]"
                                    label={closeBtnName}
                                    borderColor={'#2F5597'}
                                    color={"#000000"}
                                    onClick={() => onChange(0)}
                                />
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>)

}

export default ConfirmPopup