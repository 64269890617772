import React, { useState, useEffect } from 'react';
import Button from '../Button';
import SelectInput from '../SelectInput';
import axios from 'axios';
import { Baseurl } from '../../Utils/Constants';
import { IQR_CALCULATION } from '../../Helpers/EndPoints';
import { useDispatch, useSelector } from 'react-redux';
import { getCalculationFormData, getOutlierdata, getSaveOutlier, getSelectedMethodBasedData } from '../../Store/Workflow/WorkflowSlice';
import { useLocation } from 'react-router-dom';
import MethodSkeleton from '../Skeleton/MethodSkeleton';
import { toast } from 'react-toastify';

const SelectMethod = ({ columnName, methodId, selectedMethodData = {}, saveMethod, method, workflow_id, ecl_node_id, category_id, isFrom, text_input, afterMethodSave, hideSave, setAllData }) => {
    const dispatch = useDispatch()
    const location = useLocation();
    const reduxState = useSelector(state => state)
    const [selectedMethod, setSelectedMethod] = useState(text_input ?? undefined);
    const [isDisabled, setIsDisabled] = useState(false)
    const [inputValues, setInputValues] = useState({
        lower: selectedMethodData?.lower || '',
        upper: selectedMethodData?.upper || '',
        ...(selectedMethodData?.method === 'IQR' && { multiplier: selectedMethodData?.multiplier, iqr:selectedMethodData?.iqr || '' })
        });

    useEffect(() => {
        setInputValues({
            lower: selectedMethodData?.lower || '',
            upper: selectedMethodData?.upper || '',
            ...(selectedMethodData?.method === 'IQR' && { multiplier: selectedMethodData?.multiplier ,iqr:selectedMethodData?.iqr || '' }),
         });
    }, [selectedMethodData?.lower, selectedMethodData?.method, selectedMethodData?.multiplier, selectedMethodData?.upper]);

    useEffect(() => {
        if (columnName && methodId) {
            setAllData(prev => ({
                ...prev,
                [`${columnName}.${methodId}`]: {
                    "column_name": columnName,
                    "column_label": "",
                    "method": parseInt(methodId),
                    "actions": isFrom == "edit" ? "ignored" : "",
                    "data_imputation_method": "null",
                    "text_input": selectedMethod,
                    "multiplier": inputValues?.multiplier,
                    "lower_value": inputValues?.lower,
                    "higher_value": inputValues?.upper,
                    inputValues: { ...inputValues },
                    isDisabled
                },
            }))
        }
    }, [columnName, inputValues?.lower, inputValues?.multiplier, inputValues?.upper, isFrom, methodId, selectedMethod])


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        const validInput = /^[0-9.]*$/.test(value);
        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleKeyPress = (e) => {
        const { key, target } = e;
        const value = target.value;
      
        // Allow numbers (0-9), dots (.), and minus (-) at the beginning
        if (!/[\d.-]/.test(key) || (key === '-' && value.length > 0) || (key === '.' && value.includes('.'))) {
          e.preventDefault();
        }
      };

      const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData('text');
        const value = e.target.value + pasteData;
        if (!/^-?\d*\.?\d*$/.test(value)) {
          e.preventDefault();
        }
      };
  

    const multiplierOnBlur = () => {
      if(inputValues?.multiplier !=null){
        setIsDisabled(true)
        axios(`${Baseurl + IQR_CALCULATION}`, {
            method: "POST",
            data: {
                workflow_id,
                ecl_node_id,
                category_id,
                column_name: columnName,
                multiplier: inputValues.multiplier
            },
        })
            .then((response) => {
                if (response?.data?.status == 200) {
                    setInputValues(prevState => ({
                        ...prevState,
                        lower: response?.data?.iqrResults?.lower,
                        upper: response?.data?.iqrResults?.upper,
                        multiplier: response?.data?.iqrResults?.multiplier,
                        iqr:response?.data?.iqrResults?.iqr
                    }));
                    setIsDisabled(false)
                }
            })
            .catch((error) => {
                console.log("API not working", error);
            });
      }
    };
    const handleSave = () => {
        if(category_id){
        const requestData = {
            ecl_workflow_id: workflow_id,
            ecl_node_id: ecl_node_id,
            ecl_category_id: category_id,
            outlierDatas: [
                {
                    "column_name": columnName,
                    "column_label": "",
                    "method":parseInt(methodId),
                    "actions": isFrom == "edit" ? "ignored":"",
                    "data_imputation_method": "null",
                    "text_input": selectedMethod,
                    "multiplier": inputValues?.multiplier,
                    "lower_value": inputValues?.lower,
                    "higher_value": inputValues?.upper

                },
            ]
                
        }
      
        dispatch(getSaveOutlier(requestData) )
        .then((response) => {
            console.log(response,"response");
            if (response.type === `${getSaveOutlier.fulfilled}`) { 
                const requestData = {
                    workflow_id: workflow_id,
                    ecl_node_id: ecl_node_id,
                    category_id: category_id
                } 
                const requestFormData = getCalculationFormData({
                    client_branch_level_id: location?.state?.levelTwoCardId,
                    isFrom: "EA",
                    nodeStatus:"Pending",
                    category_id: category_id,
                    node_id: ecl_node_id,
                    state: reduxState
                })
                dispatch(getOutlierdata({requestFormData,requestData}))
                afterMethodSave(undefined)
            }else if(response.type === `${getSaveOutlier.rejected}`){
            }
        })
        .catch((error) => {
            console.log("API not working", error);
        }); 
    }else{
        toast.error('Category id must')
    }
    };

    useEffect(()=>{
        if(isFrom == 'edit' && selectedMethodData?.method === "User defined values"){
            const requestData = {
                workflow_id: workflow_id,
                ecl_node_id: ecl_node_id,
                category_id: category_id,
                column_name:columnName,
                selected_method:parseInt(methodId)
            }
            dispatch(getSelectedMethodBasedData(requestData))   
        }

    },[])
    
    return (
        <>
      {Object.keys(selectedMethodData).length > 0 ?
            <div className='flex items-center w-full gap-8'>
                <div className='w-[90%] grid grid-cols-4 gap-8'>
                    <div className="col-span-1">
                        <label className="block text-[13px] font-semibold text-[#000000]"> Method </label>
                        <p className="mt-1 w-full px-2 text-white rounded-[3px] border border-[#00A7B5] bg-[#8FABEE] shadow-sm sm:text-sm 2xl:py-2 py-1">
                            {method}
                        </p>
                    </div>
                    {selectedMethodData?.method === "User defined values" ?
                        <div className='col-span-1 mt-5'>
                            <SelectInput
                                value={selectedMethod ?? undefined}
                                options={selectedMethodData?.value}
                                placeholder={"Select method"}
                                customHeight={{ height: '2.2rem' }}
                                onChange={(e) => {
                                    setSelectedMethod(e);
                                }}
                                disabled={isDisabled}

                            />
                        </div>
                        :
                        (selectedMethodData?.method === 'Z Scores' || selectedMethodData?.method === 'User defined ranges' || selectedMethodData?.method === 'User defined percentile') ?
                            <>
                                <div className="col-span-1">
                                    <label className="block text-[13px] font-semibold text-[#000000]"> {`Lower ${selectedMethodData?.method === 'User defined percentile' ? 'Percentile' : 'End'}`} </label>
                                    <input
                                        className={`mt-1 w-full rounded-[3px] border border-[#00A7B5] shadow-sm sm:text-sm 2xl:py-2 2xl:px-2 py-1 px-1 focus:outline-none  ${isDisabled ? 'bg-[#E0DCD7]':'bg-white'}`}
                                        name="lower"
                                        // min={0}
                                        value={inputValues.lower}
                                        onKeyPress={handleKeyPress}
                                        onChange={handleInputChange}
                                        onPaste={handlePaste}
                                        disabled={isDisabled}
                                    />
                                </div>
                                <div className="col-span-1">
                                    <label className="block text-[13px] font-semibold text-[#000000]"> {`Upper ${selectedMethodData?.method === 'User defined percentile' ? 'Percentile' : 'End'}`} </label>
                                    <input
                                        className={`mt-1 w-full rounded-[3px] border border-[#00A7B5] shadow-sm sm:text-sm 2xl:py-2 2xl:px-2 py-1 px-1 focus:outline-none  ${isDisabled ? 'bg-[#E0DCD7]':'bg-white'}`}
                                        name="upper"
                                        // min={0}
                                        value={inputValues.upper}
                                        onKeyPress={handleKeyPress}
                                        onChange={handleInputChange}
                                        onPaste={handlePaste}
                                        disabled={isDisabled}

                                    />
                                </div>
                            </>
                            :
                            selectedMethodData?.method === 'IQR' ?
                                <>
                                <div className='col-span-3 w-full'>
                                    <div className='grid grid-rows-2 w-full space-y-2'>
                                        <div className='row-span-1'>
                                            <div className="grid grid-cols-3 gap-8">
                                                <div className=''>
                                                    <label className="block text-[13px] font-semibold text-[#000000]"> IQR </label>
                                                    <p className="mt-1 w-full px-2 text-white rounded-[3px] border border-[#00A7B5] bg-[#8FABEE] shadow-sm sm:text-sm 2xl:py-2 py-1">
                                                        {inputValues?.iqr}
                                                    </p>
                                                </div>
                                                <div className="">
                                                <label className="block text-[13px] font-semibold text-[#000000]"> Multiplier </label>
                                                <input
                                                    className={`mt-1 w-full rounded-[3px] border border-[#00A7B5] shadow-sm sm:text-sm 2xl:py-2 2xl:px-2 py-1 px-1 focus:outline-none  ${isDisabled ? 'bg-[#E0DCD7]':'bg-white'}`}
                                                    name="multiplier"
                                                    type='number'
                                                    // min={0}
                                                    value={inputValues.multiplier}
                                                    onKeyPress={handleKeyPress}
                                                    onChange={handleInputChange}
                                                    onBlur={multiplierOnBlur}
                                                    onPaste={handlePaste}
                                                    disabled={isDisabled}

                                                />
                                            </div>
                                            </div>                                            
                                        </div>
                                        <div className='row-span-1'>
                                            <div className='grid grid-cols-3 gap-8'>
                                            <div className="col-span-1">
                                                <label className="block text-[13px] font-semibold text-[#000000]"> Lower Limit </label>
                                                <input
                                                    className={`mt-1 w-full rounded-[3px] border border-[#00A7B5] shadow-sm sm:text-sm 2xl:py-2 2xl:px-2 py-1 px-1 focus:outline-none  ${isDisabled ? 'bg-[#E0DCD7]':'bg-white'}`}
                                                    name="lower"
                                                    type='number'
                                                    min={0}
                                                    value={inputValues.lower}
                                                    onKeyPress={handleKeyPress}
                                                    onChange={handleInputChange}
                                                    onPaste={handlePaste}
                                                    disabled={isDisabled}

                                                />
                                            </div>
                                            <div className="col-span-1">
                                                <label className="block text-[13px] font-semibold text-[#000000] "> Upper Limit </label>
                                                <input
                                                    className={`mt-1 w-full rounded-[3px] border border-[#00A7B5] shadow-sm sm:text-sm 2xl:py-2 2xl:px-2 py-1 px-1 focus:outline-none  ${isDisabled ? 'bg-[#E0DCD7]':'bg-white'}`}
                                                    name="upper"
                                                    type='number'
                                                    min={0}
                                                    value={inputValues.upper}
                                                    onKeyPress={handleKeyPress}
                                                    onChange={handleInputChange}
                                                    onPaste={handlePaste}
                                                    disabled={isDisabled}

                                                />
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </>
                                :
                                <></>
                    }
                </div>
                    {!hideSave && <div className={`w-[10%] ${selectedMethodData?.method == 'IQR' ? 'mt-[5.6rem] 2xl:mt-[6.5rem] 3xl:mt-[7rem]':'mt-5'}`}>
                    <Button
                        onClick={handleSave}
                        bgColor="#42B2AC"
                        borderType="normal"
                        label={"Save"}
                        color={"#ffffff"}
                        size={'sm'}
                        menu={"savemethod"}
                        type={'submit'}
                        disabled={isDisabled || (selectedMethodData?.method === "User defined values" ? !selectedMethod : Object.values(inputValues).some(value => value === ''))}
                    />
                    </div>}
            </div>
                :
                <div className="mb-4">
                    <MethodSkeleton />
                </div>
    }
        </>
        
    );
};

export default SelectMethod;
