import React, { useEffect } from 'react'
import { ImgBaseUrl, localStorageKeys } from '../../Utils/Constants'
import { useState } from 'react'
import dropdownIcon from '../../Images/Polygon 1.svg';
import classNames from 'classnames'
import ModelBuilderIcon from '../../Components/CommonIcons/ModelBuilderIcon';
import CloseIcon from '../../Components/CommonIcons/CloseIcon';
import { useDispatch, useSelector } from 'react-redux';
import { closeModalBuilder, newNodeAdded, selectCurrentNodeIds, selectIsDisableAction } from '../../Store/Workflow/WorkflowSlice';
import ModelBuilderSkeleton from '../../Components/Skeleton/ModelBuilderSkeleton';

const disabledCardIds = [3, 28, 35, 36]

function WrokflowNodesSlider() {
    const dispatch = useDispatch()
    const { currentEclNodeId } = useSelector(selectCurrentNodeIds)
    const isModalBuilderOpen = useSelector(state => state?.Workflow?.ui?.isModalBuilderOpen)
    const sections = JSON.parse(localStorage.getItem(localStorageKeys.modelBuilderList))
    const isDisableAction = useSelector(selectIsDisableAction)
    const isDisableOther = useSelector(state => state?.Workflow?.nodeList[0]?.id == null || state?.Workflow?.currentStep < 4)
    const isDisablePositinAnalysis = useSelector(state => state?.Workflow?.nodeList[0]?.id == null)
    const isDisableMismatch = useSelector(state => state?.Workflow?.nodeList?.findIndex(node => node?.ecl_node_id == 5 && node?.id != null) == -1)
    const [accordianOpenState, setAccordianOpenState] = useState([0])
    const windowHeight = window.innerHeight;
    const headerHeight = document.getElementById('header')?.clientHeight
    const footerHeight = document.getElementById('footer')?.clientHeight
    const pipeLineHeight = document.getElementById('sliderPaneHeader')?.clientHeight
    const calculatedHeight = windowHeight - (headerHeight + footerHeight + pipeLineHeight);
    const onDragStart = (e, section, item) => {
        e.dataTransfer.setData("Text", e.target.id);
    }

    const onCardClick = (e, section, item) => {
        dispatch(newNodeAdded({
            ecl_node_id: item?.id
        }))
        // dispatch(fileDetailsDataClear())
        // dispatch(perticularNodeDataClear())
    }

    const closeModal = () => {
        dispatch(closeModalBuilder())
    }


    const isDisableCard = ({ cardId, sectionIndex }) => {
        if (isDisableAction || disabledCardIds.includes(cardId)) return true

        if (sectionIndex > 0) {

            if (sectionIndex == 3) return true

            if (cardId > 5) return isDisableMismatch

            return isDisableOther
        } else {
            if (cardId == 4) return isDisablePositinAnalysis
        }

        return false

    }

    useEffect(()=>{
        const initialOpenIndex = sections.findIndex(section => section.ecl_nodes.some(node => node.id === currentEclNodeId));
        if(initialOpenIndex == -1){
            setAccordianOpenState([0])    
        }else{
            setAccordianOpenState([initialOpenIndex])    
        }

    },[currentEclNodeId])

    return (
        <div id="rightPanel"
            className={`w-[50%] lg:w-[42%] xxlg:w-[35%] lxl:w-[30%] 2xl:w-[28%] 4xl:w-[26%] 5xl:w-[25%] 6xl:w-[20%] fixed right-0 xl:top-[3.9rem] top-[2.45rem] z-[1] h-full transform transition-transform ease-in-out duration-1000 ${isModalBuilderOpen ? 'translate-x-0' : 'translate-x-full'
                }`}>
            <div className={`h-full border-l-2 border-[#42B2AC] `}>
                <div className={`w-full bg-[#42B2AC] flex justify-between py-5 pl-[1rem] pr-[1.2rem] h-[6%]`} id="sliderPaneHeader">
                    <div className='flex items-center gap-2 '>
                        <ModelBuilderIcon className={'2xl:w-[25px] 2xl:h-[20px] w-[22px] h-[16px]'} bgColor={'#ffffff'} />
                        <p className='text-white font-semibold 2xl:text-[15px] text-[13px]'>{"Model Builder"}</p>
                    </div>
                    <div className='flex items-center gap-2 cursor-pointer' onClick={closeModal}>
                        <p className='text-white font-semibold 2xl:text-[15px] text-[13px]'>Close</p>
                        <CloseIcon className={'2xl:w-[15px] 2xl:h-[15px] w-[12px] h-[12px]'} bgColor={'#ffffff'} />
                    </div>
                </div>
                {/* <div className={`w-full overflow-y-auto thin-scrollbar h-[91%] xlg:h-[92%] xl:h-[91%] lxl:h-[93%] bg-white`}> */}
                <div className={`w-full overflow-y-auto thin-scrollbar bg-white`} style={{ height: calculatedHeight + "px"}}>
                    <div className='py-2 px-[1rem]'>
                        {/* acordion header */}
                        {sections?.length > 0 ?
                            sections?.map((item, index) => (
                                <div key={index} id={"section" + item.id} className="2xl:mb-4 mb-3">
                                    <div
                                        className="flex items-center justify-between p-2 bg-gray-200 cursor-pointer bg-[#331D53] text-white rounded"
                                        // onClick={() => setAccordianOpenState((prevIndices) => {
                                        //     if (prevIndices.includes(index)) {
                                        //         return prevIndices.filter((i) => i !== index);
                                        //     } else {
                                        //         return [...prevIndices, index];
                                        //     }
                                        // })}
                                        onClick={() => setAccordianOpenState((prevIndices) => 
                                            prevIndices?.includes(index) ? [] : [index]
                                        )}
                                    >
                                        <span className="2xl:text-[13px] text-[12px] text-center ">{item?.name}</span>
                                        <img
                                            src={dropdownIcon}
                                            className={classNames('2xl:w-3 2xl:h-3 w-[8px] h-[8px] brightness-0 invert-[1]', {
                                                'transform rotate-180': accordianOpenState?.includes(index),
                                            })}
                                            alt="Dropdown Icon"
                                        />
                                    </div>
                                    {/* accordion content */}
                                    {accordianOpenState?.includes(index) && (
                                        <div className="py-3 px-3 bg-gray-100">
                                            <div className='grid grid-cols-3 items-center gap-3 2xl:gap-5'>
                                                {item?.ecl_nodes?.map((data, i) => {
                                                    const isDisable = isDisableCard({ cardId: data?.id, sectionIndex: index })
                                                    return (
                                                        <div key={i}
                                                            title={data?.id}
                                                            onClick={(event) => {
                                                                if (isDisable) return
                                                                onCardClick(event, item, data)
                                                            }}
                                                            className={`${isDisable ? "cursor-not-allowed" : "cursor-pointer shake"} col-span-1 mx-auto ${index==4 ? 'h-[9rem] 2xl:h-[9.7rem] 3xl:h-[10rem] 2xl:w-[8.5rem] 2xl:min-w-[7.2rem] w-[7.5rem] min-w-[6.2rem]': '2xl:min-h-[7.5rem] 2xl:h-[9rem] 2xl:w-[8.5rem] 2xl:min-w-[7.2rem]  min-h-[6.5rem] h-[8rem] w-[7.5rem] min-w-[6.2rem]'} bg-[#E7E6E6] hover:bg-[#D0CECE] rounded-lg flex flex-col gap-3 p-1.5 items-center justify-center  `}>
                                                            <p className='text-[#331D53] 2xl:text-[12px] text-[10px] text-center'>{data?.name}</p>
                                                            <img alt='' id={data?.id} onDragStart={(event) => onDragStart(event, item, data)}
                                                                draggable={!isDisable}
                                                                src={ImgBaseUrl + data?.image_name} className={`drag-enabled 2xl:h-[30px] 2xl:w-[25px] h-[25px] w-[20px]`} />
                                                            <p className='text-[#242B3B] 2xl:text-[10px] text-[8px] text-center 2xl:leading-[14px] leading-[12px]'>{data?.description}</p>
                                                        </div>

                                                    )
                                                })
                                                }
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))
                            : <ModelBuilderSkeleton cards={3} />
                        }
                    </div>
                </div>
            </div>
        </div>

    )
}

export default WrokflowNodesSlider