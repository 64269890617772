import React from 'react'

const ContinueIcon = ({width,height,bgColor,className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} viewBox="0 0 22.512 20">
    <g id="Group_239" data-name="Group 239" transform="translate(574.54 -168.843)">
      <path id="Path_185" data-name="Path 185" d="M-353.986,187.318c.027-2.366.053-4.686.08-7l.054-.018,1.392,1.484a9.647,9.647,0,0,0,.763-2.2,7.858,7.858,0,0,0-1.043-6.171,8.79,8.79,0,0,0-5.971-4.045,16,16,0,0,0-1.624-.126c-.116-.009-.23-.039-.344-.059l-.008-.077a.641.641,0,0,1,.19-.105,10.163,10.163,0,0,1,8.974,2.579,9.495,9.495,0,0,1,2.753,5.169,10.256,10.256,0,0,1-.772,6.576.624.624,0,0,0-.032.082.266.266,0,0,0,0,.055l1.959.492Z" transform="translate(-204.41)" fill={bgColor}/>
      <path id="Path_186" data-name="Path 186" d="M-572.571,207.217l-1.969-.494,6.363-3.363c-.026,2.36-.052,4.678-.078,7l-.05.027-1.411-1.5c-.153.387-.3.74-.432,1.1a8.718,8.718,0,0,0-.5,3.75,7.843,7.843,0,0,0,1.794,4.33,8.9,8.9,0,0,0,4.747,3.11,8.5,8.5,0,0,0,2.27.264,2.02,2.02,0,0,1,.358.058q0,.043,0,.087a.758.758,0,0,1-.2.1A10.269,10.269,0,0,1-570.2,219.5a9.387,9.387,0,0,1-3.109-5.183,10.283,10.283,0,0,1,.681-6.964C-572.606,207.316-572.593,207.275-572.571,207.217Z" transform="translate(0 -32.993)" fill={bgColor}/>
    </g>
  </svg>
  
  )
}

export default ContinueIcon
