import React, { useEffect, useState } from 'react'
import Button from '../../Components/Button'
import RouteSegments from '../../Routes/RouteSegments';
import { useLocation, useNavigate } from 'react-router-dom';
import CategoryIcon from '../../Components/CommonIcons/CategoryIcon';
import { Baseurl } from '../../Utils/Constants';
import axios from 'axios';
//image
import EditIcon from '../../Images/editIcon.svg'
import Delete from '../../Images/delete.svg'
import BreadcrumbArrow from '../../Images/Group 499.svg'
import { MANAGE_NULL_CHECK, MANAGE_NULL_MULTIPLE, NULL_AVAILABILITY } from '../../Helpers/EndPoints';
import AlertIcon from '../../Images/alert.svg'
import ConfirmPopup from '../../Components/PopUp/ConfirmPopUp';
import { toast } from 'react-toastify';


const ManageCategory = () => {
    const [nullValueError,setNullValueError] = useState("");
    const [nullValue,setNullValue] = useState("");
    const [nullValuesList,setNullValuesLIst] = useState([]);
    const [editableNullValueId,setEditableNullValueId] = useState(null)
    const [isedit, setIsEdit] = useState(false);
    const [editIndex, setEditIndex] = useState(null)
    const [disabledSave, setDisabledSave] = useState(true);
    const [undoChanges, setUndoChanges] = useState(false);
    const [disableAdd, setDisableAdd] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();
    const clientID = location?.state?.clientID
    const category_id = location?.state?.category_id
    const client_branch_level_two_id = location.state?.client_branch_level_two_id
    const movePreviousPage = (step, afterSave) => {
      if(step==1){
        const hasEmptyId = nullValuesList?.some(item => item?.id == "");
        const hasDelete  = nullValuesList?.some(item => item?.is_deleted == true);
        
    if((hasEmptyId || isedit || hasDelete) && !afterSave){
      setUndoChanges(true)
    } else{
        navigate(RouteSegments.MANAGE_CATEGORY,{ state: { 
          clientID: location?.state?.clientID,
          breadCrumbClientName: location?.state?.breadCrumbClientName,
          labelName:location?.state?.labelName,
          data:location?.state?.data,
          breadCrumbCountryName:location?.state?.breadCrumbCountryName,
          client_country_id:location?.state?.client_country_id,
          client_branch_level_two_id:location?.state?.client_branch_level_two_id,
          level_two_name:location?.state?.level_two_name
        } }) 
      }
      }else{
      navigate(RouteSegments.LEVEL_TWO,{ state: { 
        clientId:location?.state?.clientID,
        labelName:location?.state?.labelName,
        data:location?.state?.data,
        breadCrumbClientName:location?.state?.breadCrumbClientName,
        breadCrumbCountryName:location?.state?.breadCrumbCountryName,
        client_country_id:location?.state?.client_country_id,
      } })
    }
      
    }

    const nullValueCheck = (id,value,index,action) =>{
      axios(Baseurl+ MANAGE_NULL_CHECK , {
        method: "POST",  
        data:{
          "client_branch_level_two_id":client_branch_level_two_id,
          "category_id":category_id,
          "null_value_id":id
       }
        })
          .then((response) => {
            if (response?.data?.status == 200) {
                if(action == 'edit'){
                  editCategory(id,value,index)
                }else if(action=='remove'){
                  removeCategoryName(id,value,index)
                }
            }else if(response?.data?.status == 205){
              toast.error(response?.data?.message)
            }
          })
          .catch((error) => {
            console.log("API not working", error);
          })  
    }
    const removeCategoryName = (id,name,index) =>{  
      
      if (id) {
        const data = nullValuesList?.map(item => {
          if (item.id == id) {
  
            return { ...item, is_deleted: true };
          } else {
            return item;
          }
        })
        setNullValuesLIst(data)
        setDisabledSave(false)
        // getPrepareNullValues(data)
      } else if (index >= 0 && index < nullValuesList.length) {
        const updatedList = [...nullValuesList];
        updatedList.splice(index, 1); // Remove the item at the specified index
        setNullValuesLIst(updatedList);
      } else {
        console.error("Invalid id or index provided.");
      }      
    }
    const createCategory = (e) => {
        e.preventDefault();
        
          getPrepareNullValues(nullValuesList)
    };
    const editCategory = (id,name,index) => {
        setIsEdit(true)
        setNullValue(name);
        setEditableNullValueId(id)  
        setEditIndex(index)
    };
    const getNUllValuesList = ()=>{
        axios(`${Baseurl}/client/${category_id}/nullValue/list`, {
        method: "GET",         
        })
          .then((response) => {
            if (response?.data?.status == 200) {
                console.log(response?.data?.data,'list of null value');
                const responseWithIsDeleted = response?.data?.data.map(item => {
                  return { ...item, is_deleted: false };
              });
              
                setNullValuesLIst(responseWithIsDeleted)
            }
          })
          .catch((error) => {
            console.log("API not working", error);
          })  
    }
    const nullValuesCheckExist = (id, isEdit, indexOfItem) => {
      // var hasMatch = false;
      // for (var index = 0; index < nullValuesList?.length; ++index) {
      //   var data = nullValuesList[index];
      //   if (data?.value == nullValue) {
      //     hasMatch = true;
      //     break;
      //   }
      // }
      var hasMatch = false;
    for (var index = 0; index <  nullValuesList?.length; ++index) {
      var data =  nullValuesList[index];
     
      if(isEdit && id !=''){ 
          if((indexOfItem == index) && data?.value == nullValue) 
            hasMatch = false;
          // break;      
      }else if(isEdit && id==''){
          if(indexOfItem == index) {
            if(data?.value == nullValue){
              hasMatch = false;
            }
          }else{
            if (data?.value == nullValue) {
              hasMatch = true;
              break;
            }
          }

      }else{
      if (data?.value == nullValue) {
        hasMatch = true;
        break;
      }
    }
    }
      axios(`${Baseurl + NULL_AVAILABILITY}`, {
        method: "POST",
        data: {
          "category_id": category_id,
          "null_value": nullValue,  
          "id" : id  ?? null              
        },
        headers: {
          "Content-Type": "application/json",
          // "Authorization": getToken()
        },
      })
        .then((response) => {
          if (response?.data?.status == 200) {
            if (response?.data?.success) {
              if (hasMatch == false) {
                setNullValueError('')
                if (isedit) {
                  if (id) {
                    const data = nullValuesList?.map(item => {
                      if (item.id == id) {
                        return { ...item, value: nullValue };
                      } else {
                        return item;
                      }
                    })
                    setNullValuesLIst(data)
                    setDisabledSave(false)
                  } else if (indexOfItem >= 0 && indexOfItem < nullValuesList.length) {
                    const updatedList = [...nullValuesList];
                    updatedList[indexOfItem].value = nullValue; // Rename the item at the specified index
                    setNullValuesLIst(updatedList);
                    setDisabledSave(false)
                  } else {
                    console.error("Invalid id or index provided.");
                  }
                  setIsEdit(false)
                } else {
                  setNullValuesLIst(prev => [...prev, { id: '', value: nullValue, is_deleted: false }])
                  setIsEdit(false)
                  setDisabledSave(false)
                }
                setNullValue('')
              }
              else {
                setNullValueError('The null value already exists')
              }
  
            }
          } else if (response?.data?.status == 205) {
            setNullValueError(response?.data?.message)
          }
        })
        .catch((error) => {
          console.log("API not working", error);
        });
  
    }
    const getPrepareNullValues = (nullValuesList)=>{
        axios(`${Baseurl+MANAGE_NULL_MULTIPLE}`, {
        method: "POST",  
        data:{
            "client_branch_level_id": client_branch_level_two_id,
            "category_id" : category_id,
            "data": nullValuesList,           

        } 
        })
          .then((response) => {
            if (response?.data?.status == 200) {
                getNUllValuesList()   
                setIsEdit(false)  
                setUndoChanges(false) 
                setEditableNullValueId(null)    
                movePreviousPage(1,true) 
            }else if(response?.data?.status == 205){
                setNullValueError(response?.data?.message) 
            }
          })
          .catch((error) => {
            console.log("API not working", error);
          })  
    }
    useEffect(()=>{
      getNUllValuesList()
    },[])

    const handlePopup = (data)=>{
      if(data ==0){
        setUndoChanges(false)

      }else{
        navigate(RouteSegments.MANAGE_CATEGORY,{ state: { 
          clientId:location?.state?.clientID,
          breadCrumbClientName: location?.state?.breadCrumbClientName,
          labelName:location?.state?.labelName,
          data:location?.state?.data,
          breadCrumbCountryName:location?.state?.breadCrumbCountryName,
          client_country_id:location?.state?.client_country_id,
          client_branch_level_two_id:location?.state?.client_branch_level_two_id,
          level_two_name:location?.state?.level_two_name
        } })  
    }
  }

  useEffect(()=>{
    if(nullValue){
      setDisableAdd(false)
    }else{
      setDisableAdd(true)
    }

  },[nullValue])
    console.log(nullValuesList,'nullvaluesList');
    console.log(isedit,'isedit');

  return (
    <>
    {undoChanges && 
        <ConfirmPopup 
        show={true} 
        message={'Are you sure you want cancel & exit the page?'} 
        activeBtnName={'Yes'} 
        closeBtnName='No' 
        onChange={(data)=>handlePopup(data)}
        activeBtnColor="#42B2AC"
        forWarning={true}
        icon={<img className='' src={AlertIcon} />}
        /> }

    <div className="w-full  bg-whitee">
        <div className="py-3 mx-[4rem]">
          <Button
            bgColor="#FBE5D6"
            borderType="dashed"
            label={"Cancel and exit"}
            color={"#ED7D31"}
            onClick={()=>movePreviousPage(1)}
            icon={"minus"}
          /> 
          <div className='flex gap-2 items-center mt-3'>
              <p className='text-[#5B9BD5] text-[13px] cursor-pointer underline' onClick={()=>movePreviousPage(2)}>{location?.state?.level_two_name}</p>
              <img src={BreadcrumbArrow} alt='breadcrumb' className='w-3'/>
              <p className='text-[#5B9BD5] text-[13px] cursor-pointer underline' onClick={()=>movePreviousPage(1)}>{"Document Category"}</p>   
              <img src={BreadcrumbArrow} alt='breadcrumb' className='w-3'/>
              <p className='text-[#000000] text-[13px]'>{location?.state?.categoryName}</p>                                   
          </div>                  
        </div>
      </div>
      <div className={`pl-4 pr-4 flex justify-center items-center'}`}>
       <div className="xl:px-[3rem] px-[2rem] 2xl:py-[2.5rem] py-[1.5rem] bg-white shadow-[1px_1px_5px_1px_rgb(232,232,232)] rounded-lg min-h-fit xl:max-h-96 max-h-80 w-[55%] border border-[#E1E1E1]">
       <p className='text-[#4F49DF] font-semibold text-[14px] xl:text-[15px] mb-3'>Deﬁne null master</p>

            <div className="">
              <div className='space-y-2 max-h-[5rem] lg:max-h-[6rem] xlg:max-h-[7rem] 2xl:max-h-[20rem] 3xl:max-h-[25rem] overflow-y-auto thin-scrollbar'>
                    {nullValuesList?.map((data,i)=>{
                        return(
                          data?.is_deleted== false && 
                            <div className='border-b-2 border-[#E1E1E1] flex items-center justify-between' key={i}>
                                <p className={`${data?.value == '' || data?.value == null ? 'text-[#00A7B5]':'text-[#37384C]'} xl:text-[14px] text-[12px] pb-2`}>{data?.value == '' || data?.value == null? '(Empty cells)' : data?.value}</p>    
                                {data?.value &&                            
                                <div className='flex gap-3'>                                  
                                  <div className='flex gap-1'>
                                    <img src={EditIcon} alt='edit' className='xl:w-[0.7rem] w-[0.6rem]'/>
                                    <p className="underline  text-[#5B9BD5] text-[12px] xl:text-[14px] text-right cursor-pointer mr-2" onClick={()=>nullValueCheck(data?.id,data?.value,i,'edit')}>Edit</p>    
                                  </div> 
                                  <div className='flex gap-1'>     
                                  <img src={Delete} alt='delete' className='xl:w-[0.6rem] w-[0.5rem]'/>                      
                                    <p className="underline text-[#B70000] text-[12px] xl:text-[14px] text-right cursor-pointer mr-2" onClick={()=> nullValueCheck(data?.id,data?.value,i,'remove')}>Remove</p>   
                                  </div>
                                </div> 
                                }
                            </div>
                        )
                    })}                                                
                </div>
                <form onSubmit={(e)=>{setDisabledSave(true);createCategory(e, isedit?'edit':'add',editableNullValueId)}}>
                  <div className="relative mt-4">                  
                    <div className="absolute top-1/2 left-3 transform -translate-y-1/2 w-4">
                      <CategoryIcon bgColor={'#707070'} className="w-[12px] xl:w-[15px] h-[12px] xl:h-[15px]"/>
                    </div>
                    <input
                      id="nullValeus"
                      name="nullValeus"
                      type="text"
                      placeholder="Please enter null value"
                      onChange={(e) => {
                        setNullValue(e.target.value);
                        setNullValueError(false)
                      }}
                      value={nullValue}
                      className={`w-full xl:text-[14px] text-[12px] placeholder:text-[#BEBEBE] placeholder:tracking-[1px] rounded-md px-0 xl:py-1.5 py-[0.3rem] border-2 ${!nullValueError ? 'border-greeny-blue focus:border focus:border-greeny-blue focus:outline-greeny-blue' : 'border-error-red focus:outline-error-red'} pl-[2.2rem] placeholder:tracking-[3px]`}                  
                      autoComplete="off"
                    />                                                      
                  </div>
                  {nullValueError &&
                    <p className="text-error-red text-[14px] pt-1">{nullValueError}</p>
                    }
                  <div className='mt-4 flex justify-end items-center'>                     
                      <div className='space-y-2'>   
                        <div className='flex justify-end'>
                            <Button bgColor="#00A7B5"
                              borderType="normal"
                              label={"Add"}
                              color={"#ffffff"}
                              type={'button'}
                              onClick={() => { setDisableAdd(true);nullValuesCheckExist(isedit ? editableNullValueId : null, isedit, editIndex)}}
                              size={'md'}
                              disabled={!nullValue || disableAdd}
                              />
                          </div>                
                            <Button bgColor="#00A7B5"
                                borderType="normal"
                                label={isedit ? "Save & exit":"Save & exit"}
                                color={"#ffffff"}
                                type={'submit'}
                                size={'lg'}                                                                                    
                                disabled={nullValue || disabledSave}
                            />
                      </div>
                  </div>
                </form>
            </div>
            </div>
      </div>      
    </>
  )
}

export default ManageCategory;
