import React from 'react';
import Skeleton from 'react-loading-skeleton';

const DataTableSkeleton = ({ columnWidths, rowCount, columnCount, showPagination,showActionableButtons,from ,isDownload,showHeader,initial}) => {
  return (
    <>
    {isDownload && 
    <div className='m-2'>
      <Skeleton height={25} width={150}/>
    </div>
      }
    <div className={`w-full ${initial ? 'h-[8rem] xl:h-[12rem] lxl:h-[17rem] 2xl:h-[22rem] 5xl:h-fit':''} ${from =='mergeSummary'?'overflow-y-hidden':'overflow-y-auto thin-scrollbar'}`}>      
      <table className='w-full'>
        <thead className={`${showHeader ? '':'hidden'}`}>
          <tr>
            {Array.from({ length: columnCount }).map((_, columnIndex) => (
              <th key={columnIndex} className={`px-2 whitespace-nowrap`} style={{ width: columnWidths[columnIndex] || 'auto' }}>
                <Skeleton height={35} width={'100%'} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='text-[#707070] text-[12px]'>
          {Array.from({ length: rowCount }).map((_, rowIndex) => (
            <tr key={rowIndex}>
              {Array.from({ length: columnCount }).map((_, columnIndex) => (
                <td key={columnIndex} className='py-2.5 2xl:py-3 px-2' style={{ width: columnWidths[columnIndex] || 'auto' }}>
                  <Skeleton height={20} width={'100%'} />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {showPagination && (
        <div className='mt-[1rem] items-center flex justify-end'>
            <div className='flex gap-4'>
          <Skeleton height={25} width={25} />
          <Skeleton height={25} width={25} />
          <Skeleton height={25} width={25} />
          <Skeleton height={25} width={25} />
          <Skeleton height={25} width={25} />
          <Skeleton height={25} width={25} />
          </div>
        </div>
      )}      
    </div>
    {showActionableButtons &&(
      <div className='flex gap-4 justify-end mt-4'>
        <Skeleton height={30} width={100} />
        <Skeleton height={30} width={100} />
        <Skeleton height={30} width={100} />
        {/* <Skeleton height={30} width={100} /> */}
      </div>
      )}
    </>
  );
};

export default DataTableSkeleton;
