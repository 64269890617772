import React, { useEffect, useMemo, useState } from 'react'
import SummaryTable from '../Table/SummaryTable'
import { createColumnHelper } from '@tanstack/react-table'
import Button from '../Button'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { downloadExport, getCalculationFormData, getMergeSummaryFileCreation, imputationProceed, selectCurrentNodeData, selectCurrentNodeIds, openModalBuilder, mostFrequestlyOccuringDetail} from '../../Store/Workflow/WorkflowSlice'
import useDataTable from '../../hooks/useDataTable'
import DataTableSkeleton from '../Skeleton/DataTableSkeleton'

import XlsIcon from '../../Images/excel.svg'
import EditIcon from '../../Images/Group 809.svg'
import Eyeicon from '../../Images/eyeicon.svg'
import { useFormik } from 'formik'
import { IMPUTATION_MOST_FREQUENTLY_EXPORT } from '../../Helpers/EndPoints'
import { CircularProgress } from '@mui/material'
import DataTable from '../Table/DataTable'
import {  exportMostFreqOccuring, getSplitDataColumnDropdown, saveMostFreqOccuring } from '../../Helpers/ApiHelpers'
import DataPopup from '../PopUp/DataPopup'
import ProceedButton from '../CommonComponents/ProceedButton'
import DropDown from '../DropDown/DropDown'
import downloadingIcon from '../../Images/gif/downloading.gif'



const ColumnDownload = ({ workflow_id, ecl_node_id, category_id, column_name, parent_node_id ,disabled}) => {

    const [isDownloading, setisDownloading] = useState(false)

    const onDownloadClick = async () => {
        setisDownloading(true)
        const request ={
            workflow_id,
            ecl_node_id,
            category_id,
            column_name,
            is_export: true,
            parent_node_id
        }
        await downloadExport(request, IMPUTATION_MOST_FREQUENTLY_EXPORT)
        setisDownloading(false)
    }
    return isDownloading
        ? <img src={downloadingIcon} className='w-6' />
        : <img alt='' onClick={onDownloadClick} src={XlsIcon} className={`${disabled ? 'pointer-events-none color-change-image':'cursor-pointer'} w-4 h-4 xl:w-5 xl:h-5`} />
}

const ColumnView = ({ workflow_id, ecl_node_id, category_id, column_name, parent_node_id,disabled }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [showPopup, setShowPopup] = useState(null)

    const onClick = async () => {
        setIsLoading(true)
        const request = {
            workflow_id,
            ecl_node_id,
            category_id,
            column_name,
            is_export: false,
            parent_node_id
        }
        const response = await exportMostFreqOccuring(request)
        setIsLoading(false)

        if (response?.status == 200) {

        }
        setShowPopup(response?.data)
    }


    return <>
        {showPopup && <DataPopup
            source={showPopup}
            onClose={() => setShowPopup(null)}
            popupClassName={'min-w-[80vw]'}
            title={`Replace with most frequently occurring value based on another column - ${column_name}`} />}
        {isLoading
            ? < CircularProgress size={18} />
            : <button onClick={onClick} className={`${disabled ? 'text-[#707070]':'text-[#4F49DF]'}text-[12px] xl:text-[14px] flex items-center gap-2`}>
                <img alt='' src={Eyeicon} className={`w-3 ${disabled ? 'pointer-events-none color-change-image':'cursor-pointer'}`} />
                View
            </button>}
    </>
}

const columnHelper = createColumnHelper()

function MostFrequentlyOccuring() {


    const location = useLocation()
    const dispatch = useDispatch()
    const currentNodeData = useSelector(selectCurrentNodeData)
    const isDataLoading = useSelector(state => state?.Workflow?.ui?.isDataLoading)
    const isCalculating = useSelector(state => state?.Workflow?.ui?.isCalculating)
    const filesMerging = useSelector(state => state?.Workflow?.ui?.filesMerging)
    const reduxState = useSelector(state => state)
    const { currentEclNodeId, currentWorkflowId } = useSelector(selectCurrentNodeIds)
    const [isEditMode, setIsEditMode] = useState();
    const [columnsList,setColumnsList] = useState([])
    const [summaryTableData, setSummaryTableData]= useState([])

    useEffect(() => {
        if (currentNodeData?.mostFrequentlyOccuringData?.isEdit == false) {
            setIsEditMode(true)
        }
    }, [currentNodeData?.mostFrequentlyOccuringData?.isEdit])


    const formik = useFormik({
        initialValues: {
            baseColumn: currentNodeData?.mostFrequentlyOccuringData?.frequentlyOccurringDatas?.map(data => data?.base_column_value),
        },
        enableReinitialize: true,
    })

    const { dataTableColumns,
        getFilterOptions,
        summaryData,
        tableState,
        refreshDataTable,
        onTableStateChange,
        isDataLoading: isMergeSummaryLoading,
        isExportLoading,
        donwloadXl,
    } = useDataTable({ category_id: currentNodeData?.nodeCategoryId, ecl_node_id: currentEclNodeId, parent_node_id:currentNodeData?.parent_node_id, workflow_id: currentWorkflowId, from: "diFrequentlyOccurringAnotherColumnDatas" })


    const onBaseColumnChange = (index, value, columnDataType) => {
            formik.setFieldValue(`baseColumn.${index}`, value)
            setSummaryTableData(prevData => {
                const newData = [...prevData];
                const selectedColumn = columnsList.find(col => col.column_name === value);
                if (selectedColumn) {
                    newData[index] = {
                        ...newData[index],
                        base_column_name: selectedColumn.column_name,
                        base_column_label: selectedColumn.alias_name,
                    };
                }
                return newData;
            });

    }   
   

    const summaryColumns = useMemo(() => {
        if (isEditMode) {
            return [
                columnHelper.accessor("exception_analysis_method", {
                    header: "Exception Analysis",
                    mergeCells: true,
                }),
                columnHelper.accessor("column_name", {
                    header: "Destination Column",
                }),
                columnHelper.display({
                    id: "baseColumn",
                    header: "Base column",
                    tdClassName: "!py-2.5",
                    cell: ({ row }) => {
                        if(columnsList?.length > 0){
                        const filteredData = columnsList?.filter(val => val?.data_type_label?.toLowerCase() === (row.original.assigned_data_type ? row.original.assigned_data_type?.toLowerCase() : row.original?.column_datatype.toLowerCase()));                        
                        const dropDownOptions = filteredData?.map(option => ({
                            label: option.alias_name,
                            value: option.column_name,
                        })) || [];

                        return <DropDown
                        dropDownOptions={dropDownOptions}
                        placeholder={'Select Base Column'}
                        name={'baseColumn'}
                        border={'1px solid #E0DCD7'}
                        ordinary={true}
                        disabled={false}
                        customTags={false}
                        value={row?.original?.base_column_name != null  ? row?.original?.base_column_name : undefined}
                        onOptionChange={(value) => onBaseColumnChange(row.index, value,row?.original?.assigned_data_type ? row?.original?.assigned_data_type : row.original?.column_datatype)}
                    />
                    }
                    }

                }), 
                columnHelper.accessor("emptyCell", {
                    header: "",
                }),               
            ]
        } else {
            return [
                columnHelper.accessor("exception_analysis_method", {
                    header: "Exception Analysis",
                    mergeCells: true,
                }),
                columnHelper.accessor("column_label", {
                    header: "Destination Column",
                }),
                columnHelper.accessor("base_column_label", {
                    header: "Base column",
                }),               
                columnHelper.accessor("no_of_modified_values", {
                    header: "No. of modified values",
                }),
                columnHelper.accessor("percentage_of_modified_values", {
                    header: "%of modified values",
                    thClassName: "w-[150px]",
                    cell: ({ row }) => (<span>{row.original?.percentage_of_modified_values ? row.original?.percentage_of_modified_values + " %"  : '-'}</span>)

                }),


                columnHelper.display({
                    id: "Actions",
                    header: "Actions",
                    thClassName: "w-[120px]",
                    cell: ({ row }) => {
                        return <div className='space-x-4 flex items-center justify-between'>
                            <ColumnView
                                category_id={currentNodeData?.nodeCategoryId}
                                column_name={row.original.column_name}
                                ecl_node_id={currentEclNodeId}
                                workflow_id={currentWorkflowId}
                                parent_node_id={currentNodeData?.parent_node_id}
                                disabled={row.original.no_of_modified_values == 0}
                            />
                            <ColumnDownload
                                category_id={currentNodeData?.nodeCategoryId}
                                column_name={row.original.column_name}
                                ecl_node_id={currentEclNodeId}
                                workflow_id={currentWorkflowId}
                                parent_node_id={currentNodeData?.parent_node_id}
                                disabled={row.original.no_of_modified_values == 0}
                            />
                        </div>
                    }

                }),
            ]
        }

    }, [isEditMode,columnsList])





    useEffect(() => {
         if(currentNodeData?.mostFrequentlyOccuringData?.frequentlyOccurringDatas) {
            setSummaryTableData(currentNodeData?.mostFrequentlyOccuringData?.frequentlyOccurringDatas)
         }
    }, [currentNodeData?.mostFrequentlyOccuringData?.frequentlyOccurringDatas]);


    useEffect(() => {
        dispatch(getMergeSummaryFileCreation({
            workflow_id: currentWorkflowId,
            ecl_node_id: currentEclNodeId,
        })).then(res => {
            if (res.type == `${getMergeSummaryFileCreation.fulfilled}`) {
                refreshDataTable()
                dispatch(mostFrequestlyOccuringDetail({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": currentNodeData?.nodeCategoryId,
                    "parent_node_id": currentNodeData?.parent_node_id,
                }))
            }
        })
    }, [])

    useEffect(() => {
        if (currentNodeData?.nodeCategoryId) {
            const getColumnDropdown = async() => {
                const response  = await getSplitDataColumnDropdown({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": currentNodeData?.nodeCategoryId,
                    "data_type": ["Boolean","Integer","Date","Text"],
                })
                if(response?.data?.status == 200){
                    setColumnsList(response?.data?.columns)
                }
            }   
            getColumnDropdown()       
        }        
        
    }, [currentNodeData?.nodeCategoryId, currentNodeData?.mostFrequentlyOccuringData?.isEdit])
console.log(columnsList,'columnsList');

    const onProceedClick = async () => {
        if (currentNodeData?.node_status == 'Completed') {
            return dispatch(openModalBuilder())
        }

        const requestFormData = getCalculationFormData({
            client_branch_level_id: location?.state?.levelTwoCardId,
            isFrom: "mostFrequentOccurence",
            category_id: currentNodeData?.nodeCategoryId,
            node_id: currentEclNodeId,
            state: reduxState,
            nodeStatus:'Completed'
        })

        dispatch(imputationProceed({
            requestFormData,            
        })).then(res => {
            if (res.type == `${imputationProceed.fulfilled}`) {
                setIsEditMode(false)
                refreshDataTable()
                dispatch(mostFrequestlyOccuringDetail({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": currentNodeData?.nodeCategoryId,
                    "parent_node_id": currentNodeData?.parent_node_id,
                }))
            }
        })

    }

    const onSave = async ()=>{
        const request = {
            "ecl_workflow_id": currentWorkflowId,
            "ecl_node_id": currentEclNodeId,
            "ecl_category_id": currentNodeData?.nodeCategoryId,
            "parent_node_id": currentNodeData?.parent_node_id,
            "frequentlyOccurringDatas": summaryTableData
        }
        
             const response = await saveMostFreqOccuring(request)
             if(response.data.status ==200){
                setIsEditMode(false)
                        refreshDataTable()
                        dispatch(mostFrequestlyOccuringDetail({
                            "workflow_id": currentWorkflowId,
                            "ecl_node_id": currentEclNodeId,
                            "category_id": currentNodeData?.nodeCategoryId,
                            "parent_node_id": currentNodeData?.parent_node_id,
                        }))
             }
    }

    const onEditClick = () => {
        setIsEditMode(prev => !prev)
    }


    const renderTable = () => {
        if (isDataLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        const disableSave = summaryTableData?.length == 0 || formik?.values?.baseColumn?.length > 0 && summaryTableData?.some(data => data?.base_column_name == null)
        // if (currentNodeData?.nodeCategoryId) {
            return <>
                <div className={`mt-5`}>
                    {isEditMode
                        ? <div className='flex gap-3 items-center py-2'>
                            <button className='text-white bg-[#F7BE7E] text-[12px] py-1 px-3 rounded'>Data category</button>
                            <p className='font-semibold text-[12px]'>{currentNodeData?.mostFrequentlyOccuringData?.categoryName}</p>
                        </div>
                        : <div className='2xl:py-3 py-1.5 px-2 bg-[#4339A8] flex justify-between items-center'>
                            <div className=' flex gap-3 items-center'>
                                <button className='text-white bg-[#F7BE7E] py-0.5 px-2 text-[12px] rounded'>Data category</button>
                                <p className='text-[#ffffff] font-semibold text-[12px]'>{currentNodeData?.mostFrequentlyOccuringData?.categoryName}</p>
                            </div>                            
                            {currentNodeData?.node_status == 'Completed' 
                                 ? null
                            : <div className={`col-span-1 flex justify-end gap-1 items-center mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={onEditClick}>
                                <img alt='' src={EditIcon} className='w-4 h-4' />
                                <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
                            </div>}
                        </div>}
                </div>
                <div className={`max-h-[200px] overflow-y-auto thin-scrollbar ${isEditMode ? "" : ""}`}>
                    <SummaryTable
                        key={""}
                        columns={summaryColumns}
                        data={summaryTableData}
                        thClassName={` ${isEditMode ? "!bg-[#4339A8]" : ""} `}
                        tdClassName={`${isEditMode ? "p-4" : ""}`}
                    />
                </div>
                {currentNodeData?.node_status == 'Completed' || !isEditMode
                    ? null
                    : <div className='flex justify-center my-4'>
                        <Button
                            bgColor='#42B2AC'
                            borderType=""
                            disabled={disableSave}
                            label={'Save'}
                            color={'#ffffff'}
                            onClick={onSave}
                            size={'md'} />
                    </div>}
            </>
        // }

    }

    const renderResultTable = () => {

        if (!currentNodeData?.nodeCategoryId) return

        if (isDataLoading || isMergeSummaryLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        const mergeSummaryTableData = summaryData

        console.log({ summaryData });

        return <div className='mt-5'>

            <div className='flex justify-end items-center'>
                {isExportLoading
                    ? <img src={downloadingIcon} className='w-6 mr-2' />
                    : <img alt='' src={XlsIcon} className=' mb-2 object-cover mr-2 cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
            </div>

            <DataTable
                columns={dataTableColumns}
                data={mergeSummaryTableData?.columnDatas ?? []}
                onTableStateChange={onTableStateChange}
                totalCount={mergeSummaryTableData?.totalRecords}
                isOptionsDisabled={location?.state?.isfromHistory}
                getFilterOptions={getFilterOptions}
                tableState={{
                    pagination: {
                        pageIndex: tableState.pageIndex,
                        pageSize: tableState.pageSize,
                    },
                    sorting: tableState?.sortBy ? [
                        {
                            id: tableState?.sortBy,
                            desc: tableState?.sortOrder === "desc"
                        }
                    ] : [],
                    columnFilters: tableState.columnFilters
                        ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                        : [],
                    columnPinning: tableState.columnPinning,
                    columnVisibility: tableState?.columnVisibility,
                }}
            />
            <div className={`float-right 2xl:my-6 my-3 ${location?.state?.isfromHistory || currentNodeData?.node_status == 'Completed' || currentNodeData?.mostFrequentlyOccuringData?.isEdit == false ? 'hidden' : 'block'}`}>
                <ProceedButton
                    onClick={onProceedClick}
                />                

            </div>
        </div>
    }

    return (
        filesMerging || isCalculating
            ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            :
             <div className='my-2'>
                {renderTable()}
                {renderResultTable()}
            </div>
    )
}

export default MostFrequentlyOccuring