import React, { useEffect, useState } from 'react';
import { Select } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DownArrow from '../CommonIcons/DownArrow';
import Button from '../Button';
import HistoryDataTable from '../Table/HistoryDataTable';
import DataTableSkeleton from '../Skeleton/DataTableSkeleton';
import axios from 'axios';
import SelectInput from '../SelectInput'
import { toast } from 'react-toastify';

import { Baseurl, localStorageKeys, loginLocalStorageKeys } from '../../Utils/Constants';
import { ADD_COLUMN_DROPDOWN_LIST, CREATE_MERGE_SUMMARY, REMOVED_COLUMN_NAMES_LIST, REMOVE_COLUMN_DROPDOWN_LIST, REMOVE_COLUMN_NAME} from '../../Helpers/EndPoints';
import EditIcon from '../../Images/Group 809.svg'
import {  convertDateFormat } from '../../Utils/CommonFunctions';
import CloseIcon from "../../Images/undo.svg"
import DataTable from '../Table/DataTable';
import { useDispatch } from 'react-redux';
import { getNodeDetails } from '../../Store/action';
import { useLocation } from 'react-router-dom';
import useDataTable from '../../hooks/useDataTable';
import downloadingIcon from '../../Images/gif/downloading.gif'
import XlsIcon from '../../Images/excel.svg'
import { setCurrentNodeData } from '../../Store/Workflow/WorkflowSlice';
import CommonPopup from '../PopUp/CommonPopup';
import ProceedButton from '../CommonComponents/ProceedButton';

const schema = yup.object().shape({
  category: yup.string().required("Please select category"),
  columnName: yup.string().required("Please select column name"),
});

function RemoveColumnName({
  workflow_id,
  ecl_node_id,
  workflow_node_id,
  calculationDate,
  templateId,
  templateName,
  client_branch_level_id,
  nodeCategoryId,
  onProceedClick,
}) {
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(nodeCategoryId);
  const [disabled, setDisabled] = useState(true);
  const [selectedDataType,setSelectedDataType] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [initialLoading, setInitialLoading] = useState(true);  
  const [columnNameList,setColumnNameList] = useState([])
  const [deletableColumnName, setDeleteableColumnName] = useState();
  const [deleteableColumnList,setDeleteableColumnList] = useState([])
  const [showEdit, setShowEdit] =useState(false);
  const [showAddRename, setShowAddRename] = useState(false);
  const [undoDatas,setUndoDatas] = useState([]);
  const location = useLocation();
  const [isEdit, setIsEdit] = useState();
  const [columnAddName,setColumnAddName] = useState([])
  const [removeColumnList, setRemoveColumnList] = useState(null)
  const [showPopup,setshowPopup] =useState(false)

  const { dataTableColumns,
     getFilterOptions,
     summaryData, 
    tableState, 
    refreshDataTable,
     onTableStateChange ,
     isDataLoading,
     isExportLoading,
     donwloadXl,
  } = useDataTable({ category_id: selectedCategory, ecl_node_id, workflow_id, from:"removeColumn" })

  const { register, control, reset, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch()

  const columns = [
    {
      Header: 'Removed Columns',
      accessor: 'column_name',
    },    
  ];

  const calculationApiCall = async (isFrom,category_id) => {

    if (calculationDate == 'Invalid date') {
    }
    else {
      if(templateId || templateName){     
      const postFormData = new FormData();
      postFormData.append('ecl_workflow_id', workflow_id != null || workflow_id != undefined ? workflow_id : "");
      postFormData.append('user_id', localStorage.getItem(loginLocalStorageKeys.loginId));
      postFormData.append('client_branch_level_id', client_branch_level_id);
      postFormData.append('date', convertDateFormat(calculationDate));
      postFormData.append('template_type', 'New');
      postFormData.append('ecl_workflow_node_id', isEdit ? workflow_node_id :"");
      postFormData.append('node_id', ecl_node_id); 
      postFormData.append('is_proceed', "Completed");     
      if(isFrom == 'postionAnalysis' || isFrom == 'dataCleansing'){
        postFormData.append('category_id', category_id); 
      }
      postFormData.append('template_id', templateId ?? "");
      postFormData.append('template_name', templateId ? "" : templateName)

      axios.post("https://ecl.port0.org/laravelapi/api/ecl/calculation", postFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          // handle the response
          if (response?.data?.status == 200) {
            localStorage.setItem(localStorageKeys.currentWorkFlowId, response?.data?.workflowId)
            setShowEdit(true)
            setShowLoader(false)
            // setReplaceClicked(false)
            // setNodeSaved(true)
            dispatch(getNodeDetails(response?.data?.workflowId));
            dispatch(setCurrentNodeData({
              workflowNodeId: response?.data?.workflowNodeId,
              nodeCategoryId: category_id
            }))
            getTableData();                    
            // setIsFileSentToS3(true)
            // setError(false)            
            if (isFrom != 'another') {
              localStorage.setItem(localStorageKeys.lastNodeStatus, 'completed')
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }}
  }

  const getCategoryList = () => {
    axios(`${Baseurl}/ecl/calculation/${workflow_id}/${ecl_node_id}/nodeWise/categoryList`, {
      method: "GET",
    })
      .then((response) => {
        if (response?.data?.status === 200) {
          setCategoryList(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  };

  const filesMergeApiCall = () => {
    axios(`${Baseurl + CREATE_MERGE_SUMMARY}`, {
      method: "POST",
      data: {
        workflow_id: workflow_id,
        ecl_node_id: ecl_node_id
      },
    })
      .then((response) => {
        // console.log(response,'response');
        if (response?.data?.status == 200) {
          if(selectedCategory || nodeCategoryId){
          refreshDataTable()
        }
          setInitialLoading(false)          
          setDisabled(false)
          showLoader(false)
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  };

  useEffect(() => {
    if (nodeCategoryId) {
      getTableData()
    }
  }, [])

  const getTableData = (isFrom) => {
    setShowLoader(true);

    axios(`${Baseurl + REMOVED_COLUMN_NAMES_LIST}`, {
      method: "POST",
      data: {
        workflow_id: workflow_id,
        ecl_node_id: ecl_node_id,
        category_id: selectedCategory
      },
    })
      .then((response) => {
        if (response?.data?.status === 200) {
          refreshDataTable()
          setRemoveColumnList(response?.data);
          setShowLoader(false);
          setDisabled(true)
          if(isFrom == 'cateSave'){
            getColumnNameList()
          }
          if(response?.data?.removeDatas?.length ==0 ){
          setShowEdit(false)
          if(isFrom == 'cateSave'){
            setShowAddRename(true)
          }
        }else{
          setShowEdit(true)
        }
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  };

  const getColumnNameList = ()=>{
    axios(`${Baseurl + REMOVE_COLUMN_DROPDOWN_LIST}`, {
        method: "POST",
        data: {
          workflow_id: workflow_id,
          ecl_node_id: ecl_node_id,
          category_id: selectedCategory
        },
      })
        .then((response) => {
          if (response?.data?.status === 200) {
            // setShowAddRename(true)
            setColumnNameList(response?.data?.columnNames);
          }
        })
        .catch((error) => {
          console.log("API not working", error);
        });    
    
  }

  const getSaveRemovedColumns = ()=>{
    setShowLoader(true)
    axios(`${Baseurl+REMOVE_COLUMN_NAME}`, {
        method: "POST", 
        data: {
            ecl_workflow_id: workflow_id,
            ecl_node_id: ecl_node_id,
            ecl_category_id: selectedCategory,
            remove_datas: deleteableColumnList,
            remove_undo_datas: undoDatas,
          },      
      })
        .then((response) => {
          if (response?.data?.success == true) {       
            calculationApiCall('dataCleansing',selectedCategory)
            refreshDataTable()
            setUndoDatas([])
        }else{
          console.log(response?.data?.error,'error');
        }
        })
        .catch((error) => {
          console.log("API not working", error);
        });

  }

  useEffect(() => {
    if(initialLoading){
    getCategoryList();
  }
  filesMergeApiCall();   
  }, []);


  useEffect(()=>{
    if(removeColumnList?.removeDatas?.length > 0){
      setInitialLoading(false);
      setShowLoader(false)
      setShowEdit(removeColumnList?.isEdit)
      setIsEdit(removeColumnList?.isEdit)
    }

  },[removeColumnList])


  const handleClickAdd = () => {

    axios(`${Baseurl + ADD_COLUMN_DROPDOWN_LIST}`, {
      method: "POST",
      data: {
        workflow_id: workflow_id,
        category_id: selectedCategory,
        column_name:deletableColumnName
      },
    })
      .then((response) => {
        if (response?.data?.status === 200) {
          // setShowAddRename(true)
          setColumnAddName(response?.data?.message);
          // toast.error(response?.data?.message)
          setDeleteableColumnList([
            ...deleteableColumnList,
            { column_name: deletableColumnName }
        ]);
        setDeleteableColumnName(undefined);
        reset({ columnName: undefined });
        }
        else if(response?.data?.status === 205){
          toast.error(response?.data?.message)

          setColumnAddName(response?.data?.message);
          // setshowPopup(true)
        }
        // setshowPopup(true)
      })
      .catch((error) => {
        console.log("API not working", error);
      });    
  

  
};

  const handleRemoveColumn = (index,column) => {
    setDeleteableColumnList(deleteableColumnList?.filter((_, i) => i !== index));
    setUndoDatas([...undoDatas, column])
  };


  return (
    <div className='relative '>  
    {showPopup && 
    <CommonPopup onCancel={()=>setshowPopup(false)} title={" Error Message"}  content={columnAddName}/>} 
    {
      initialLoading ?
      <div className='mt-2'>
      <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
    </div>
    :  
    (
        <div className={`${showEdit ? 'hidden':'block'} flex space-x-4 mt-4`}>
          <Controller
            control={control}
            name="category"
            render={({ field }) => (
              <Select
                ref={field.ref}
                name={field.name}
                onBlur={field.onBlur}
                {...register("category")}
                onChange={(e) => {
                  field.onChange(e);
                  setSelectedCategory(e);
                }}
                value={nodeCategoryId ? nodeCategoryId : field.value ? field.value : undefined}
                placeholder="Select data category"
                options={categoryList?.map(cList => ({
                  label: cList?.category_name,
                  value: cList?.category_id,
                  disabled:cList?.is_disabled
                }))}
                disabled={disabled || nodeCategoryId}
                onDropdownVisibleChange={(visible) => setIsCategoryOpen(visible)}
                suffixIcon={<DownArrow color="#000000" width={'10px'} height={'10px'} className={isCategoryOpen ? 'transition-transform transform rotate-180' : 'transition-transform transform rotate-[-180]'} />}
                className="custom-select-height border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"
                style={{
                  width: '100%',
                  border: '2px solid #00A7B5',
                  borderRadius: '4px',
                }}
              />
            )}
          />
          <Button
            bgColor="#47B4AE"
            size="lg"
            label={'Save'}
            color={'#FFFFFF'}
            onClick={()=>getTableData('cateSave')}
            disabled={disabled || nodeCategoryId || !selectedCategory}
          />
        </div>
    )
      
    }
        
    {deleteableColumnList?.length > 0  ? 
      <div className={`2xl:mt-4 mt-3 ${removeColumnList?.removeDatas?.length > 0 && showEdit ? 'hidden':'block'}`}>
        <div className='bg-[#4339A8] grid grid-cols-3 text-white font-semibold text-[13px] py-2 px-4'>
          <div className='col-span-2'> Column name </div>
          <div className='col-span-1'> Action</div>
        </div>
        <div className='max-h-[5rem] xxlg:max-h-[8rem] 2xl:max-h-[15rem]  thin-scrollbar '>
          {deleteableColumnList?.map((column,i)=>{
            return(
                <div className='space-y-2 mt-2' key={i}>
                  <div className='w-full bg-[#ECECEC] py-2.5 px-4 grid grid-cols-3 rounded'>
                      <div className='col-span-2 text-[#707070] text-[12px] xl:text-[14px]'>
                          {column?.column_name}
                      </div>                    
                      <div className='col-span-1' onClick={()=>handleRemoveColumn(i,column)}>
                          <button className='text-[#4F49DF] text-[12px] xl:text-[14px] flex items-center gap-2'>
                              <img src={CloseIcon} className='w-3' />
                              Undo
                          </button>
                      </div>
                  </div>
                </div>
            )
          })}
        </div>
      </div>

       :<></>

    }   
    {
        !showLoader ? 
        (removeColumnList?.removeDatas?.length > 0  ?
          <div className={`${showEdit ? 'block':'hidden'}`}>          
            <div className='mt-5'>
                <div className='2xl:py-3 py-1.5 bg-[#4339A8] flex justify-between items-center'>
                  <div className='mx-4 flex gap-3 items-center'>
                    <button className='text-[#FFFFFF] bg-[#F7BE7E] py-0.5 px-2 text-[12px] rounded'>Data category</button>
                    <p className='text-[#ffffff] font-semibold text-[12px]'>{removeColumnList?.categoryName}</p>
                    </div>                  
                  <div className={`flex gap-1 items-center mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none':'cursor-pointer'}`} onClick={() => { getColumnNameList(); setShowEdit(false); setDeleteableColumnList(removeColumnList?.removeDatas); setShowAddRename(true); }}>
                        <img src={EditIcon}  className='w-4 h-4'/>
                    <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
                    </div>
                </div>            
              <HistoryDataTable
                columns={columns}
                data={removeColumnList?.removeDatas ?? []}
                tableCellClassName={'text-[#000000] 2xl:text-[14px] text-[12px] bg-white px-5 2xl:px-8 py-1.5 xl:py-3 2xl:py-3'}
                headerclassName={'2xl:py-4 py-2 px-5 2xl:px-8 bg-[#A06DFF] text-white text-[12px] xl:text-[13px] border-none max-w-[10rem] min-w-[6rem]'}                
                placeholder={'Select data type'}
                setSelectedDataType={setSelectedDataType}
                name={'dataType'}
                disabled={showEdit}
                setCurrentIndex={setCurrentIndex}
                tableHeight={'table-height max-h-[8.5rem] xxlg:max-h-[17rem]  2xl:max-h-[25rem] 3xl:max-[27rem] 4xl:max-h-[30rem] 5xl:max-[35rem]'}
              />
            </div>                                    
          </div>                    
            
          : <></>
                
        )
        :
        (
            <div className='mt-2'>
                <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={false} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            </div>
        )
      } 
       {showAddRename && columnNameList?.length >0 ?
        <div className={`${(isDataLoading || showLoader) || removeColumnList?.removeDatas?.length > 0 && showEdit ? 'hidden':'block'}`}>
          <div className='flex space-x-4 mt-6'>
                <Controller
                    control={control}
                    name={`columnName`}
                    id={`columnName`}
                    render={({ field }) => {
                        return (
                            <SelectInput
                                ref={field.ref}
                                name={field.name}
                                onBlur={field.onBlur}
                                onChange={(e)=>{
                                    field.onChange(e);
                                    setDeleteableColumnName(e)
                                }}
                                value={field.value}
                                placeholder="Select column to remove"
                                className="custom-select-height border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"
                                options={columnNameList?.map(cList => ({
                                    label: cList?.alias_name,
                                    value: cList?.column_name,
                                    disabled: deleteableColumnList?.some(Column => Column?.column_name == cList.column_name)

                                }))}
                            />
                        );
                    }}
                />
               
                <Button
                    bgColor="#47B4AE"
                    size="sm"
                    label={'Add'}
                    disabled={!deletableColumnName}
                    color={'#FFFFFF'}
                    onClick={() => {handleClickAdd()}}
                    className="!px-[1.6rem]"
                />
          </div> 
           {deleteableColumnList?.length >0 &&
            <div className='flex items-center justify-center mt-4'>
                <Button
                bgColor="#47B4AE"
                size="lg"
                label={'Save'}
                disabled={deleteableColumnList?.length <=0 || deletableColumnName}
                color={'#FFFFFF'}
                onClick={() => {getSaveRemovedColumns()}}
                />
            </div>
             } 
        </div>
            :
            <></>
        }
        {!showLoader ?
        isDataLoading ? 
        
          <div className='mt-2'>
              <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
          </div>
        
        :
        summaryData?.columnDatas?.length > 0 ?
            <div className='mt-5'>
               <div className='flex justify-end items-center'>
                    {isExportLoading
                    ? <img src={downloadingIcon} className='w-6 mr-2' />
                    : <img src={XlsIcon} alt='' className='object-cover  mb-2 mr-2 cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
              </div>

              <DataTable 
                columns={dataTableColumns}
                data={summaryData?.columnDatas ?? []}
                onTableStateChange={onTableStateChange}
                totalCount={summaryData?.totalRecords}
                isOptionsDisabled={location?.state?.isfromHistory}
                getFilterOptions={getFilterOptions}
                tableState={{
                  pagination: {
                    pageIndex: tableState.pageIndex,
                    pageSize: tableState.pageSize,
                  },
                  sorting: tableState?.sortBy ? [
                    {
                      id: tableState?.sortBy,
                      desc: tableState?.sortOrder === "desc"
                    }
                  ] : [],
                  columnFilters: tableState.columnFilters
                    ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                    : [],
                  columnPinning: tableState.columnPinning,
                  columnVisibility:tableState?.columnVisibility,
                 }}
                />

                <div className={`float-right 2xl:my-6 my-3  ${showEdit && !location?.state?.isfromHistory ? 'block':'hidden'}`}>
                <ProceedButton
                  onClick={onProceedClick}
                />
                {/* <Button
                    bgColor="#70AD47"
                    size="lg"
                    label={'Proceed'}
                    color={'#FFFFFF'}
                    onClick={onProceedClick}
                />                   */}
                </div>
            </div>            
            
            :<></>
            :
            <></>
        } 
    </div>
  );
}

export default RemoveColumnName;
