import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"
import {localStorageKeys, loginLocalStorageKeys } from "../../Utils/Constants";
import {
    createMergeSummary, deleteNodeApi, fileName_check, fileUploadSummaryApi,
    getNodeDetailsAPI, getPerticularNodeDetails, getPositionAnalysisCategory,
    mergeSummaryResult, nodeSave, templatename_check, renamedColumnList,
    nodeWiseCategoryList, columnListForRename, getFileDetailsApi, nodeUpdate,
    saveRenameColumn, activityLog, deleteCalculationFileCheck, outlierData,
    outlierColumnName, selectedMethodBasedData, saveOutlierAnalysis, mismatchDetail,
    dataImputationdList, saveMismatch, downloadMergeSummaryApi, nullBlankDetail,
    exportPost, saveNullBlank,
    getImputationUserDefinedDetails,
    getFloorAndCapDetails,
    getSplitDataDetails,
    getSplitsBySeedValue,
    getSplitDataColumnDropdown,
    saveSplitData,
    binningColumnList,
    binningDetail,
    bucketDetail,
    getZscoreDetails,
    getDtNumericDropdownList,
    saveZscore, savebinningdata,
    binningbucketdata,
    getNaturalLogDetails,
    saveNaturalLog,
    binningBucketingPercentileDetail,
    saveBinningBucketingPercentile,
    saveBinningBucketingUserdefined,
    createDuplicateColumnDetail,
    saveCreateDuplicateColumn,
    saveUnexpectedValues,
    geUnexpectedValuesDetails,
    getReplaceAverageDetails,
    getMostFreqOccuringDetails,
    getReplaceEarliestDetails,
    getReplaceLatestDetails,
    getImputationReplaceMappingDetails,
    getCumulativeDistibutionDetailsApi,
    saveCumulativeDistributionCalculation,
    getstandardizeMinMaxDetails,
    savestandardizeMinMax,
    getNormalizationPercentilesDetails,
    saveNormalizationPercentiles,
    getNormalizationDecimalScalingDetails,
    saveNormalizationDecimalScaling,
    getProbabilityDensityDetailsApi,
    saveProbabilityDensityCalculation,
    getStandardiseRobustScalingDetails,
    saveStandardiseRobustScaling
} from "../../Helpers/ApiHelpers";
import { toast } from "react-toastify";
import { getSingleFilePerticularList, setIsDataLoading } from "../action";
import { convertDateFormat } from "../../Utils/CommonFunctions";
import { post_method } from "../../Helpers/AxiosHelper";
import { reorderArray } from "../../Helpers/utils";


const initialWorkflowState = {

    ui: {
        isDataLoading: false,
        isDeletingNode: false,
        isModalBuilderOpen: false,
        nodeError: "",
        showMenu: true,
        selectSheetPopupOpen: false,
        fileUploading: false,
        uploadingPercentage: 0,
        sheetDeletePopupOpen: false,
        isCalculating: false,
        fileStatusInWorkFlow: { alreadyExist: false, message: '' },
        filesMerging: true,
        isActivityLogOpen: false,
        deleteFileStatus: {}

    },

    currentStep: 1,
    currentWorkflowId: null,
    currentWorkflowNodeId: null,
    currentEclNodeId: null,
    calculationDate: null,
    isInitialLoading: false,
    currentTileId: null,
    nodeList: [],
    currentTemplateName: '',
    currentTemplateId: '',
    deletableFileDetails: [],
    nodeWiseCategory: [],
    renamedColumns: [],
    columnsListForRename: [],
    activityLogs: [],
    selectedDeleteFiles: [],
    outlierColumns: [],
    selectedMethodBasedData: {},
    categoryTableData: []

}

export const getCalculationFormData = ({ state, isFrom, client_branch_level_id, category_id, node_id, data_imputation_ids, nodeStatus }) => {
    const requestFormData = new FormData()
    const currentTemplateId = state?.Workflow?.currentTemplateId;
    const calculationDate = state?.Workflow?.calculationDate;
    const currentWorkflowId = state?.Workflow?.currentWorkflowId;
    const currentWorkflowNodeId = state?.Workflow?.currentWorkflowNodeId;
    const currentTemplateName = state?.Workflow?.currentTemplateName;
    console.log({ currentTemplateId });

    if (currentTemplateId && calculationDate) {
        const nodeDetailsString = localStorage.getItem(localStorageKeys.nodeDetails);
        const nodeDetails = JSON.parse(nodeDetailsString);
        const file_details = JSON.parse(localStorage.getItem(localStorageKeys.file_details))

        requestFormData.append('ecl_workflow_id', currentWorkflowId ? currentWorkflowId : "");
        requestFormData.append('user_id', localStorage.getItem(loginLocalStorageKeys.loginId));
        requestFormData.append('client_branch_level_id', client_branch_level_id);
        requestFormData.append('date', convertDateFormat(calculationDate));
        requestFormData.append('template_type', 'new');
        requestFormData.append('ecl_workflow_node_id', currentWorkflowNodeId ? currentWorkflowNodeId : "");
        requestFormData.append('node_id', node_id);
        requestFormData.append('template_id', currentTemplateId);
        requestFormData.append('template_name', currentTemplateId ? "" : currentTemplateName)
        requestFormData.append('is_proceed', nodeStatus)

        if (isFrom == 'fileUpload') {
            requestFormData.append('node_value', JSON.stringify(nodeDetails));
        }
        if ((isFrom == 'postionAnalysis' || isFrom == 'dataCleansing' || isFrom == 'EA') || category_id) {
            requestFormData.append('category_id', category_id);
        }

        if (file_details && (isFrom == 'fileUpload')) {
            requestFormData.append('file_details', JSON.stringify(file_details));
        }

        if (data_imputation_ids) {
            requestFormData.append("data_imputation_ids", JSON.stringify(data_imputation_ids))
        }
    }

    return requestFormData
}

export const downloadExport = async (payload, url) => {
    try {
        if (payload?.is_export) {
            const response = await exportPost(url, payload)
            console.log("downloadExport", response);
            if (response.status === 200) {
                const blob = new Blob([response.data], { type: 'text/xls' });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", response.headers['content-disposition'].split('filename=')[1]); // Extract filename from response headers
                document.body.appendChild(link);
                link.click();
                return true
            } else {
                return null
            }
        } else {
            const response = await post_method(url, payload)
            console.log("downloadExport", response);
            if (response.status === 200) {
                return response?.data
            }
        }
    } catch (error) {
        return null
    }
}

const nodeSaveCases = (builder, action) => {
    builder
        .addCase(action.pending, (state) => {
            state.ui.isCalculating = true;
            state.ui.nodeError = "";
        })
        .addCase(action.fulfilled, (state, action) => {
            state.ui.isCalculating = false;

            const nodeIndex = state.nodeList.findIndex(node => node.id == state.currentWorkflowNodeId);
            state.currentWorkflowNodeId = action.payload?.calculationResponse?.workflowNodeId;
            state.nodeList[nodeIndex].id = state.currentWorkflowNodeId;
            state.nodeList[nodeIndex].isCurrentlySaved = true;

        })
        .addCase(action.rejected, (state, action) => {
            toast.error(action.payload);
        });
};
// Create an async thunk for the API call
export const checkTemplateName = createAsyncThunk(
    'workflow/checkTemplateName',
    async (templateName, { rejectWithValue }) => {
        try {
            const response = await templatename_check({ template_name: templateName })

            if (response.data.status === 200) {
                return { templateName };
            } else if (response.data.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

// Create an async thunk for the API call
export const checkFileName = createAsyncThunk(
    'workflow/checkFileName',
    async (request, { rejectWithValue }) => {
        try {
            const response = await fileName_check(request)

            if (response.data.status === 200) {
                return null
            } else if (response.data.status === 205) {
                return rejectWithValue({errors:response.data.message});
            }
            else if (response.data.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const eclNodesave = createAsyncThunk(
    'workflow/nodeSave',
    async ({ from, requestFormData }, { rejectWithValue, dispatch }) => {
        try {
            const calculationResponse = await nodeSave(requestFormData)
            if (calculationResponse.data.status === 200) {
                const request = {
                    workflow_node_id: calculationResponse?.data?.workflowNodeId,
                    page: 1,
                    record_per_page: 10,
                    sort_by: "",
                    sort_order: "",
                    file_path: "",

                }
                const nodeDetailsResponse = await getPerticularNodeDetails(request)
                // if (requestFormData.get("ecl_workflow_node_id")) {
                //     await createMergeSummary({
                //         "workflow_id": parseInt(requestFormData.get("ecl_workflow_id")),
                //         "ecl_node_id": parseInt(requestFormData.get("node_id"))
                //     })
                // }
                localStorage.setItem(localStorageKeys.currentWorkFlowId, calculationResponse?.data?.workflowId);
                return {
                    calculationResponse: calculationResponse?.data,
                    nodeDetailsResponse: nodeDetailsResponse?.data,
                    from
                };
            } else if (calculationResponse.data?.status === 500) {
                return rejectWithValue(calculationResponse.data?.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const mismatchNodesave = createAsyncThunk(
    'workflow/mismatchNodeSave',
    async ({ requestFormData, mismatchRequest }, { rejectWithValue, dispatch }) => {
        try {
            await saveMismatch(mismatchRequest)
            const calculationResponse = await nodeSave(requestFormData)
            // const nodeDetailsResponse = await mismatchDetail({
            //     "workflow_id": mismatchRequest?.ecl_workflow_id,
            //     "ecl_node_id": mismatchRequest?.ecl_node_id,
            //     "category_id": mismatchRequest?.ecl_category_id,
            // })
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    // nodeDetailsResponse: nodeDetailsResponse?.data,
                };
            } else if (calculationResponse.data?.status === 500) {
                return rejectWithValue(calculationResponse.data?.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const nullBlankNodesave = createAsyncThunk(
    'workflow/nullBlankNodeSave',
    async ({ requestFormData, request }, { rejectWithValue, dispatch }) => {
        try {
            await saveNullBlank(request)
            const calculationResponse = await nodeSave(requestFormData)
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                };
            } else if (calculationResponse.data?.status === 500) {
                return rejectWithValue(calculationResponse.data?.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const exceptionAnalysisProceed = createAsyncThunk(
    'workflow/exceptionAnalysisProceed',
    async ({ requestFormData, workflow_id, isProceeded }, { rejectWithValue, dispatch }) => {
        try {
            const calculationResponse = await nodeSave(requestFormData)
            const response = await getNodeDetailsAPI({ id: workflow_id })
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    nodeListData: response?.data,
                    isProceeded: isProceeded
                };
            } else if (calculationResponse.data?.status === 500) {
                return rejectWithValue(calculationResponse.data?.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const eclNodeUpdate = createAsyncThunk(
    'workflow/nodeUpdate',
    async (request, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setIsDataLoading(true));
            const response = await nodeUpdate(request)

            if (response.data.status === 200) {
                dispatch(updateWorkFlowValues(response.data))
                // dispatch(getNodeDetails(response?.data?.workflowId))   
                dispatch(getWorkflowList(response?.data?.workflowId))
                const request = {
                    workflow_node_id: response?.data?.workflowNodeId,
                    page: 1,
                    record_per_page: 10,
                    sort_by: "",
                    sort_order: "",
                    file_path: "",

                }
                dispatch(getSingleFilePerticularList(request))
                return response.data;
            } else if (response.data.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getWorkflowList = createAsyncThunk(
    'workflow/list',
    async (workflow_id, { rejectWithValue, dispatch, getState }) => {
        try {
            const response = await getNodeDetailsAPI({ id: workflow_id })
            if (response.data.status === 200) {
                const lastNode = response?.data?.workflowNodes?.at(response?.data?.workflowNodes?.length - 1)
                console.log(lastNode, 'lastNode');
                let request = {
                    workflow_node_id: lastNode?.id,
                    page: 1,
                    record_per_page: 10,
                    sort_by: "",
                    sort_order: "",
                    file_path: "",

                }

                dispatch(updateNodeDataInitial({ nodeListData: response.data, }))
                let nodeDetailsResponse = null;
                if (response?.data?.workflowNodes?.length != 0) {
                    if (lastNode?.ecl_node_id == 4) {
                        request = {
                            "workflow_id": workflow_id,
                            "ecl_node_id": lastNode.ecl_node_id,
                            "category_id": lastNode.nodeCategoryId,
                            "page": 1,
                            "record_per_page": 10,
                            "sort_by": "",
                            "sort_order": "",
                            "is_export": false
                        }
                        nodeDetailsResponse = await mergeSummaryResult(request)
                    }
                    else if (lastNode?.ecl_node_id == 1 || lastNode?.ecl_node_id == 2) {
                        nodeDetailsResponse = await getPerticularNodeDetails(request)
                    }
                }
                if (nodeDetailsResponse?.data?.status === 500) {
                    return rejectWithValue(nodeDetailsResponse.data.message)
                }
                return {
                    nodeDetailsResponse: nodeDetailsResponse?.data,
                    nodeListData: response.data,
                }
            } else if (response.data.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const setCurrentNode = createAsyncThunk(
    'workflow/setCurrentNode',
    async ({ workflowNodeId, eclNodeId, nodeCategoryId }, { rejectWithValue, dispatch, getState }) => {
        const workflow_id = getState()?.Workflow?.currentWorkflowId
        try {
            dispatch(nodeClicked({ nodeId: workflowNodeId }))
            let request = {
                workflow_node_id: workflowNodeId,
                page: 1,
                record_per_page: 10,
                sort_by: "",
                sort_order: "",
                file_path: "",

            }
            let nodeDetailsResponse = null
            if (eclNodeId == 1 || eclNodeId == 2) {
                nodeDetailsResponse = await getPerticularNodeDetails(request)
            } else if (eclNodeId >= 4) {
                request = {
                    "workflow_id": workflow_id,
                    "ecl_node_id": eclNodeId,
                    "category_id": nodeCategoryId,
                    "page": 1,
                    "record_per_page": 10,
                    "sort_by": "",
                    "sort_order": "",
                    "is_export": false
                }
                nodeDetailsResponse = await mergeSummaryResult(request)

            }
            if (nodeDetailsResponse?.data?.status === 500) {
                return rejectWithValue(nodeDetailsResponse.data.message)
            }
            return {
                nodeDetailsResponse: nodeDetailsResponse?.data,
                eclNodeId,
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const openSheetDeletePopup = createAsyncThunk(
    'workflow/openSheetDeletePopup',
    async (params, { dispatch }) => {
        dispatch(openSheetDeletePopupState());
        await dispatch(getFileDetails(params));
    }
);

export const getFileDetails = createAsyncThunk(
    'workflow/getFileDetails',
    async (parms, { rejectWithValue, dispatch }) => {
        try {
            const response = await getFileDetailsApi(parms)

            if (response.data.status === 200) {
                dispatch(setDeletableFileDetails(response?.data))
                return response.data;
            } else if (response.data.status === 500)
                return rejectWithValue(response.data.message);
        }
        catch (error) {
            return rejectWithValue(error.message);
        }
    });

export const fileUploadSummary = createAsyncThunk(
    'workflow/fileUploadSummary',
    async (request, { rejectWithValue, dispatch }) => {
        try {
            const response = await fileUploadSummaryApi(request)
            if (response?.data?.status === 200) {

                return response.data;
            }
            else if (response.data?.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const deleteNode = createAsyncThunk(
    'workflow/deleteNode',
    async (request, { rejectWithValue, dispatch, getState }) => {
        try {
            const response = await deleteNodeApi(request)
            console.log("deleteNode", response);
            if (response?.data?.status === 200) {
                dispatch(removeNode())
                const updatedNodes = getState()?.Workflow?.nodeList
                const lastNode = updatedNodes[updatedNodes.length - 1]
                console.log("deleteNode", getState()?.Workflow?.nodeList, lastNode?.id);
                if (selectIsNodeDataAvailable(updatedNodes, lastNode?.id)) {
                    // dispatch(nodeClicked({ nodeId: request?.ecl_workflow_node_id }))
                } else {
                    dispatch(setCurrentNode({
                        workflowNodeId: lastNode?.id,
                        eclNodeId: lastNode?.ecl_node_id,
                        nodeCategoryId: lastNode?.nodeCategoryId
                    }))
                }
                return response.data
            } else if (response.data?.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getPositionAnalysisCategoryList = createAsyncThunk(
    'workflow/positionAnalysisCategoryList',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            // if (payload?.currentWorkflowNodeId == null) {
            await createMergeSummary({
                "workflow_id": payload?.workflowId,
                "ecl_node_id": payload.eclNodeId
            })
            // }
            const response = await getPositionAnalysisCategory(payload.workflowId);
            if (response?.status === 200) {
                return response.data
            } else if (response?.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const positionAnalysisCategorySave = createAsyncThunk(
    'workflow/positionAnalysisSummary',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const request = {
                "workflow_id": payload.workflowId,
                "ecl_node_id": payload.eclNodeId,
                "category_id": payload.categoryId,
                "page": 1,
                "record_per_page": 10,
                "sort_by": "",
                "sort_order": "",
                "is_export": false
            }
            const response = await mergeSummaryResult(request);
            if (response?.status === 200) {
                const calculationResponse = await nodeSave(payload?.requestFormData)
                if (calculationResponse?.data?.status == 500) {
                    return rejectWithValue(calculationResponse.data.message);
                }
                const nodeResponse = await getNodeDetailsAPI({ id: calculationResponse?.data?.workflowId })
                return {
                    mergeResult: response.data,
                    workflowNodeId: calculationResponse?.data?.workflowNodeId,
                    eclNodeId: payload.eclNodeId,
                }
            } else if (response?.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getNodeWiseCategoryList = createAsyncThunk(
    'workflow/nodewise/categoryList',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            // if (payload?.workflow_node_id == null) {
            const mergeResponse = await createMergeSummary({
                "workflow_id": payload?.workflow_id,
                "ecl_node_id": payload?.ecl_node_id
            })
            if (mergeResponse.data.status == 200) {
                const response = await nodeWiseCategoryList(payload)
                if (response.data.status === 200) {
                    return { categoryList: response.data?.data };
                } else if (response.data.status === 500) {
                    return rejectWithValue(response.data.message);
                }

            } else if (mergeResponse.data.status === 500) {
                toast.error('Duck DB error')
                return rejectWithValue(mergeResponse.data.message);
            }
            // }            
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getMergeSummaryFileCreation = createAsyncThunk(
    'workflow/mergeSummaryFileCreation',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            if (payload?.workflow_node_id == null) {
                const mergeResponse = await createMergeSummary({
                    "workflow_id": payload?.workflow_id,
                    "ecl_node_id": payload?.ecl_node_id
                })
                if (mergeResponse.data.status === 200) {
                    return mergeResponse
                } else if (mergeResponse.data.status === 500) {
                    toast.error('Duck DB error')
                }
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getRenamedColumnList = createAsyncThunk(
    'datacleansing/renamedColumnList',
    async (parms, { rejectWithValue, dispatch }) => {
        try {
            const response = await renamedColumnList(parms)

            if (response.data.status === 200) {
                dispatch(getColumnListForRename(parms))
                return { renamedColumns: response.data };
            } else if (response.data.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getColumnListForRename = createAsyncThunk(
    'datacleansing/ColumnsListForRename',
    async (parms, { rejectWithValue, dispatch }) => {
        try {
            const response = await columnListForRename(parms)

            if (response.data.status === 200) {
                return { columnsListForRename: response.data?.columnNames };
            } else if (response.data.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getSaveRenamedColumname = createAsyncThunk(
    'datacleansing/saveRenameColumn',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const response = await saveRenameColumn(payload?.request)

            if (response?.status === 200) {
                const calculationResponse = await nodeSave(payload?.requestFormData)
                if (calculationResponse?.data?.status == 500) {
                    return rejectWithValue(calculationResponse.data.message);
                }
                const nodeResponse = await getNodeDetailsAPI({ id: calculationResponse?.data?.workflowId })
                // return {
                //     mergeResult: response.data,
                //     workflowNodeId: calculationResponse?.data?.workflowNodeId,
                //     eclNodeId: payload.eclNodeId,
                // }
                const requestData = {
                    workflow_id: calculationResponse?.data?.workflowId,
                    ecl_node_id: payload?.request?.ecl_node_id,
                    category_id: payload?.request?.ecl_category_id
                }
                dispatch(getRenamedColumnList(requestData))

            } else if (response?.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getActivityLog = createAsyncThunk(
    'workflow/activityLog',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const response = await activityLog(payload)

            if (response.data.status === 200) {
                return { activityLogs: response.data?.historyDatas };
            } else if (response.data.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const openActivityLog = createAsyncThunk(
    'workflow/openActivityLogSlider',
    async (payload, { dispatch }) => {
        dispatch(openActivityLogState(true));
        await dispatch(getActivityLog(payload));
    }
);

export const deleteCalculationCheck = createAsyncThunk(
    'workflow/delete/calcualtionCheck',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const response = await deleteCalculationFileCheck(payload)

            if (response.data.status === 200) {
                return { data: { message: 'Are you sure you want to delete the selected sheets?' } };
            } else if (response.data.status === 205) {
                return rejectWithValue({ data: response.data });
            } else if (response.data.status === 500) {
                return rejectWithValue({ data: response.data });
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getOutlierColumnName = createAsyncThunk(
    'exceptionAnalysis/outlierColumnName',
    async (parms, { rejectWithValue, dispatch }) => {
        try {
            const response = await outlierColumnName(parms)

            if (response.data.status === 200) {
                return { outlierColumns: response.data?.outlierColumns };
            } else if (response.data.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getOutlierdata = createAsyncThunk(
    'exceptionAnalysis/outlierData',
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const response = await outlierData(payload?.requestData)

            if (response.data.status === 200) {
                if (payload?.isFrom == 'edit' || response?.data?.isEdit == false) {
                    dispatch(getOutlierColumnName(payload?.requestData))
                    return { outlierData: response.data, }
                } else {
                    if (payload?.isFrom != 'edit') {
                        const calculationResponse = await nodeSave(payload?.requestFormData)
                        if (calculationResponse.data.status === 200) {
                            return {
                                outlierData: response.data,
                                calculationResponse: calculationResponse?.data,
                            };
                        } else if (calculationResponse?.data?.status == 500) {
                            return rejectWithValue(calculationResponse.data.message);
                        }
                    }
                }
            } else if (response.data.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getSelectedMethodBasedData = createAsyncThunk(
    'exceptionAnalysis/outlier/selectedMethodData',
    async (parms, { rejectWithValue, dispatch }) => {
        try {
            const response = await selectedMethodBasedData(parms)

            if (response.data.status === 200) {
                if(response?.data?.outlierMethodDatas?.iqr < 1){
                    toast.error(`There are errors identified in ${parms?.column_name} number of rows, please perform data cleansing to correct the same`)
                }else{
                    return { data: response?.data }
                }
            } else if (response.data.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getSaveOutlier = createAsyncThunk(
    'exceptionAnalysis/outlier/save',
    async (parms, { rejectWithValue, dispatch }) => {
        try {
            const response = await saveOutlierAnalysis(parms)

            if (response.data.status === 200) {
                return { data: response?.data }
            } else if (response.data.status === 500) {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getMergeSummaryResult = createAsyncThunk(
    'workflow/mergeSummaryFileRetrieve',
    async (payload, { rejectWithValue }) => {
        try {
            const request = {
                "workflow_id": payload?.workflow_id,
                "ecl_node_id": payload?.ecl_node_id,
                "category_id": payload?.category_id,
                "page": 1,
                "record_per_page": 10,
                "sort_by": "",
                "sort_order": "",
                "is_export": false
            }
            const response = await mergeSummaryResult(request)
            if (response.data.status === 200) {
                return response?.data?.mergedResults[0];
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const saveMismatchCategory = createAsyncThunk(
    'workflow/mismatchedDataType/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await mismatchDetail(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const saveNullblankCategory = createAsyncThunk(
    'workflow/excepnullValue/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await nullBlankDetail(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const imputationProceed = createAsyncThunk(
    'workflow/imputationProceed',
    async ({ requestFormData, request }, { rejectWithValue }) => {
        try {
            // await saveImputationUserDefined(request)
            const calculationResponse = await nodeSave(requestFormData)
            const nodeDetailsResponse = await getNodeDetailsAPI({ id: calculationResponse?.data?.workflowId })
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    nodeListData: nodeDetailsResponse?.data
                };
            }
            else {
                return rejectWithValue(calculationResponse?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const imputationUserDefinedDetail = createAsyncThunk(
    'workflow/imputationUserDefined/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getImputationUserDefinedDetails(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const imputationReplaceAverageDetail = createAsyncThunk(
    'workflow/replaceValueBasedOnAverage/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getReplaceAverageDetails(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const imputationReplaceEarliestDetail = createAsyncThunk(
    'workflow/dIReplaceValueBasedOnEarliest/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getReplaceEarliestDetails(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const imputationReplaceLatestDetail = createAsyncThunk(
    'workflow/dIReplaceValueBasedOnLatest/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getReplaceLatestDetails(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const floorAndCapDetail = createAsyncThunk(
    'workflow/floorCap/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getFloorAndCapDetails(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchSplitDataDetails = createAsyncThunk(
    'workflow/splitData/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getSplitDataDetails(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchSplitsBySeedvalue = createAsyncThunk(
    'workflow/getSeedValue',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getSplitsBySeedValue(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchColumnListDatatype = createAsyncThunk(
    'workflow/ptColumnsDropdownList',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getSplitDataColumnDropdown(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const splitDataNodesave = createAsyncThunk(
    'workflow/splitDataNodesave',
    async ({ requestFormData, request }, { rejectWithValue, dispatch }) => {
        try {
            await saveSplitData(request)
            const calculationResponse = await nodeSave(requestFormData)
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    // nodeDetailsResponse: nodeDetailsResponse?.data,
                };
            } else if (calculationResponse.data?.status === 500) {
                return rejectWithValue(calculationResponse.data?.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchZscoreDetails = createAsyncThunk(
    'workflow/dtZscore/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getZscoreDetails(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchNumericColumnList = createAsyncThunk(
    'workflow/numeric/dropdownList',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getDtNumericDropdownList(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const zScoreNodesave = createAsyncThunk(
    'workflow/zScoreNodesave',
    async ({ requestFormData, request }, { rejectWithValue, dispatch }) => {
        try {
            await saveZscore(request)
            const calculationResponse = await nodeSave(requestFormData)
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    // nodeDetailsResponse: nodeDetailsResponse?.data,
                };
            } else if (calculationResponse.data?.status === 500) {
                return rejectWithValue(calculationResponse.data?.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchNaturalLogDetails = createAsyncThunk(
    'workflow/dtNaturalLog/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getNaturalLogDetails(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const naturalLogNodeSave = createAsyncThunk(
    'workflow/naturalLogNodeSave',
    async ({ requestFormData, request }, { rejectWithValue, dispatch }) => {
        try {
            await saveNaturalLog(request)
            const calculationResponse = await nodeSave(requestFormData)
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    // nodeDetailsResponse: nodeDetailsResponse?.data,
                };
            } else if (calculationResponse.data?.status === 500) {
                return rejectWithValue(calculationResponse.data?.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getDataImputationList = createAsyncThunk(
    'workflow/dataImputation/dropdownList',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await dataImputationdList(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchBBPercentileDetail = createAsyncThunk(
    'workflow/dt/binningBucketting/percentile/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await binningBucketingPercentileDetail(payload)
            if (response.data.status === 200) {
                return { data: response?.data }
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const saveBinningUserdefined = createAsyncThunk(
    'workflow/binningBucketingUserMappingSave',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await saveBinningBucketingUserdefined(payload)
            if (response.data.status === 200) {
                console.log(response.data, 'jcsjkc bskcbk');
                return { data: response?.data }
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const detailbinningdataList = createAsyncThunk(
    'workflow/binningBucketingUserMapping/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await binningbucketdata(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const saveBBPercentile = createAsyncThunk(
    'workflow/dt/binningBucketting/percentile/save',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await saveBinningBucketingPercentile(payload)
            if (response.data.status === 200) {
                console.log(response.data, 'jcsjkc bskcbk');
                return { data: response?.data }
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const binningBuckettingNodeSave = createAsyncThunk(
    'workflow/dt/binningBucketting/percentile/nodeSave',
    async ({ requestFormData, request }, { rejectWithValue }) => {
        try {
            // await saveImputationUserDefined(request)
            const calculationResponse = await nodeSave(requestFormData)
            const nodeDetailsResponse = await getNodeDetailsAPI({ id: calculationResponse?.data?.workflowId })
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    nodeListData: nodeDetailsResponse?.data
                };
            }
            else {
                return rejectWithValue(calculationResponse?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const bbUserNodeSave = createAsyncThunk(
    'workflow/dt/binningBucketting/usedefind/nodeSave',
    async ({ requestFormData, request }, { rejectWithValue }) => {
        try {
            // await saveImputationUserDefined(request)
            const calculationResponse = await nodeSave(requestFormData)
            const nodeDetailsResponse = await getNodeDetailsAPI({ id: calculationResponse?.data?.workflowId })
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    nodeListData: nodeDetailsResponse?.data
                };
            }
            else {
                return rejectWithValue(calculationResponse?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getCreateDuplicateColumnDetail = createAsyncThunk(
    'workflow/dataCleansing/createDuplicateColumn',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await createDuplicateColumnDetail(payload)
            if (response.data.status === 200) {
                return response?.data
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const createDuplicateColumnSave = createAsyncThunk(
    'workflow/dataCleasing/createDuplicateColumn/save',
    async ({ requestFormData, request }, { rejectWithValue }) => {
        try {
            await saveCreateDuplicateColumn(request)
            const calculationResponse = await nodeSave(requestFormData)
            const nodeDetailsResponse = await getNodeDetailsAPI({ id: calculationResponse?.data?.workflowId })
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    nodeListData: nodeDetailsResponse?.data
                };
            }
            else {
                return rejectWithValue(calculationResponse?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const fetchUnexpecredValuesDetail = createAsyncThunk(
    'workflow/unexpectedValue/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await geUnexpectedValuesDetails(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const unexpectedValuesNodeSave = createAsyncThunk(
    'workflow/unexpectedValueSave',
    async ({ requestFormData, request }, { rejectWithValue, dispatch }) => {
        try {
            await saveUnexpectedValues(request)
            const calculationResponse = await nodeSave(requestFormData)
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    // nodeDetailsResponse: nodeDetailsResponse?.data,
                };
            } else if (calculationResponse.data?.status === 500) {
                return rejectWithValue(calculationResponse.data?.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const mostFrequestlyOccuringDetail = createAsyncThunk(
    'workflow/dataImputation/mostFrequestlyOccuring/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getMostFreqOccuringDetails(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const imputationReplaceMappingDetail = createAsyncThunk(
    'workflow/dataImputation/mappedValuesDifferentColumn/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getImputationReplaceMappingDetails(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getBinningColumnList = createAsyncThunk(
    'dataTransformation/numeric/dropdownList',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await binningColumnList(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const saveBinningCategory = createAsyncThunk(
    'workflowCategroyWise/binningBucketingRange/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await binningDetail(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const saveBinningBucket = createAsyncThunk(
    'binningBucketingRangeSave',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await bucketDetail(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getCumulativeDistibutionDetails = createAsyncThunk(
    'workflow/dtCumulativeDistibutionFunction/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getCumulativeDistibutionDetailsApi(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const dtCumulativeDisFunctionNodesave = createAsyncThunk(
    'workflow/dtCumulativeDisFunctionNode/save',
    async ({ requestFormData, request }, { rejectWithValue, dispatch }) => {
        try {
            await saveCumulativeDistributionCalculation(request)
            const calculationResponse = await nodeSave(requestFormData)
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    // nodeDetailsResponse: nodeDetailsResponse?.data,
                };
            } else if (calculationResponse.data?.status === 500) {
                return rejectWithValue(calculationResponse.data?.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const getProbabilityDensityDetail = createAsyncThunk(
    'workflow/dtProbabilityDensityFunction/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getProbabilityDensityDetailsApi(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const dtProbabilityDensityDetailNodesave = createAsyncThunk(
    'workflow/dtProbabilityDensityNode/save',
    async ({ requestFormData, request }, { rejectWithValue, dispatch }) => {
        try {
            await saveProbabilityDensityCalculation(request)
            const calculationResponse = await nodeSave(requestFormData)
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    // nodeDetailsResponse: nodeDetailsResponse?.data,
                };
            } else if (calculationResponse.data?.status === 500) {
                return rejectWithValue(calculationResponse.data?.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const standardizeMinMaxScalingDetails = createAsyncThunk(
    'workflow/minmaxscalling/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getstandardizeMinMaxDetails(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const minmaxscallingNodesave = createAsyncThunk(
    'workflow/minmaxscallingNodesave',
    async ({ requestFormData, request }, { rejectWithValue, dispatch }) => {
        try {
            await savestandardizeMinMax(request)
            const calculationResponse = await nodeSave(requestFormData)
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    // nodeDetailsResponse: nodeDetailsResponse?.data,
                };
            } else if (calculationResponse.data?.status === 500) {
                return rejectWithValue(calculationResponse.data?.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const dtNormalizationPercentilesDetail = createAsyncThunk(
    'workflow/dtNormalizationPercentiles/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getNormalizationPercentilesDetails(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const dtNormalizationPercentilesNodesave = createAsyncThunk(
    'workflow/dtNormalizationPercentiles/save',
    async ({ requestFormData, request }, { rejectWithValue, dispatch }) => {
        try {
            await saveNormalizationPercentiles(request)
            const calculationResponse = await nodeSave(requestFormData)
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    // nodeDetailsResponse: nodeDetailsResponse?.data,
                };
            } else if (calculationResponse.data?.status === 500) {
                return rejectWithValue(calculationResponse.data?.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const dtNormalizationDecimalScallingDetail = createAsyncThunk(
    'workflow/dtNormalizationDecimalScalling/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getNormalizationDecimalScalingDetails(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const dtNormalizationDecimalScallingNodesave = createAsyncThunk(
    'workflow/dtNormalizationDecimalScalling/save',
    async ({ requestFormData, request }, { rejectWithValue, dispatch }) => {
        try {
            await saveNormalizationDecimalScaling(request)
            const calculationResponse = await nodeSave(requestFormData)
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    // nodeDetailsResponse: nodeDetailsResponse?.data,
                };
            } else if (calculationResponse.data?.status === 500) {
                return rejectWithValue(calculationResponse.data?.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const updateCurrentNodeData = createAsyncThunk(
    'workflow/nodeData/update',
    async ({request }, { rejectWithValue, dispatch }) => {
        try {
            const nodeDetailsResponse = await getPerticularNodeDetails(request)
            if (nodeDetailsResponse.data.status === 200) {
                return {
                    nodeDetailsResponse: nodeDetailsResponse?.data,
                };
            } else if (nodeDetailsResponse.data?.status === 500) {
                return rejectWithValue(nodeDetailsResponse.data?.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const dtStandardizeRobustScalingDetail = createAsyncThunk(
    'workflow/dtStandardizeRobustScaling/detail',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await getStandardiseRobustScalingDetails(payload)
            if (response.data.status === 200) {
                return response?.data;
            } else {
                return rejectWithValue(response?.data)
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const dtStandardizeRobustScalingNodesave = createAsyncThunk(
    'workflow/dtStandardizeRobustScaling/save',
    async ({ requestFormData, request }, { rejectWithValue, dispatch }) => {
        try {
            await saveStandardiseRobustScaling(request)
            const calculationResponse = await nodeSave(requestFormData)
            if (calculationResponse.data.status === 200) {
                return {
                    calculationResponse: calculationResponse?.data,
                    // nodeDetailsResponse: nodeDetailsResponse?.data,
                };
            } else if (calculationResponse.data?.status === 500) {
                return rejectWithValue(calculationResponse.data?.message);
            }
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
const workflowSlice = createSlice({
    name: "workflow",
    initialState: initialWorkflowState,
    reducers: {
        updateInitialLoading(state, action) {
            state.isInitialLoading = action.payload
        },
        setCurrentNodeData(state, { payload }) {
            state.nodeList[state.nodeList.length - 1] = {
                ...state.nodeList[state.nodeList.length - 1],
                id: payload?.workflowNodeId,
                nodeCategoryId: payload?.nodeCategoryId,
            }
            state.currentWorkflowNodeId = payload?.workflowNodeId
            state.ui.nodeError = ""
        },
        nextStep(state, action) {

            if (action?.payload?.step) {
                state.currentStep = action.payload?.step;
            } else {
                state.currentStep += 1;
            }
            if (action?.payload?.calculationDate) {
                state.calculationDate = action?.payload?.calculationDate
            }

        },
        setCalculationDate(state, action) {
            state.calculationDate = action.payload;
        },
        newNodeAdded(state, action) {
            state.nodeList.push({
                ecl_node_id: action.payload?.ecl_node_id,
                id: null,
            });
            state.currentWorkflowNodeId = null;
            state.ui.isModalBuilderOpen = false;
        },
        removeNode(state, action) {
            state.nodeList.pop()
            state.ui.nodeError = ""
            const lastNode = state?.nodeList?.at(state?.nodeList?.length - 1)

            if (lastNode) {
                state.currentWorkflowNodeId = lastNode?.id;
                state.currentEclNodeId = lastNode?.ecl_node_id
            }
        },
        openModalBuilder(state, action) {
            const currentWorkflowNodeId = state?.currentWorkflowNodeId
            const currentNodeData = state?.nodeList?.find(node => node?.id == currentWorkflowNodeId)
            const nodeList = state?.nodeList


            if (nodeList?.length == 0) {
                state.ui.nodeError = "";
                state.ui.isModalBuilderOpen = true
            } else {
                let isCalculated = true;

                if (currentNodeData?.ecl_node_id == 4) {
                    isCalculated = currentNodeData?.mergedResults?.length > 0
                } else if (currentNodeData?.ecl_node_id == 1 || currentNodeData?.ecl_node_id == 2) {
                    isCalculated = currentNodeData?.isReplace !== true && currentNodeData?.node_value_summary?.length > 0
                }
                const nodeNotSaved = state.nodeList.some(item =>
                    item.ecl_node_id > 9 && (item?.node_status == "Pending" || item?.node_status == null)
                );

                if (currentNodeData?.id == null || !isCalculated || nodeNotSaved) {
                    state.ui.nodeError = "Current step must be completed first";
                    state.ui.isModalBuilderOpen = false;
                }
                else {
                    state.ui.nodeError = "";
                    state.ui.isModalBuilderOpen = true;
                }
            }
        },
        closeModalBuilder(state, action) {
            state.ui.isModalBuilderOpen = false
        },
        setShowMenu(state, action) {
            state.ui.showMenu = action.payload;
        },
        setCurrentTemplateName(state, action) {
            state.currentTemplateName = action.payload
        },
        openSheetSelectPopup(state, action) {
            state.ui.selectSheetPopupOpen = true;
            state.fileDetailsArray = action?.payload
        },
        closeSheetSelectPopup(state, action) {
            state.ui.selectSheetPopupOpen = false;
        },
        openSheetDeletePopupState(state, action) {
            state.fileDetailsArray = []
            state.ui.sheetDeletePopupOpen = true;
        },
        closeSheetDeletePopupState(state, action) {
            state.ui.sheetDeletePopupOpen = false;
        },
        setFileUploading(state, action) {
            state.ui.fileUploading = action?.payload?.status;
            state.ui.uploadingPercentage = action?.payload?.value
        },
        updateWorkFlowValues(state, action) {
            state.currentTemplateId = action.payload?.template_id;
            state.currentWorkflowNodeId = action.payload?.workflowNodeId;
            state.currentWorkflowId = action.payload?.workflowId;
            state.ui.sheetDeletePopupOpen = false;
        },
        updateNodeDataInitial(state, { payload }) {
            state.isInitialLoading = false
            state.ui.isDataLoading = true
            state.currentTemplateId = payload?.nodeListData?.ecl_workflow_template_id;
            state.currentTemplateName = payload?.nodeListData?.ecl_workflow_template_name;
            state.currentWorkflowId = payload?.nodeListData?.workflowId;
            state.calculationDate = payload?.nodeListData?.date;
            state.currentStep = 4;
            state.ui.showMenu = false;

            state.nodeList = [...payload?.nodeListData?.workflowNodes];

            const lastNode = state.nodeList?.at(state.nodeList?.length - 1)
            state.currentWorkflowNodeId = lastNode?.id;
        },
        updateEclWorkFlowValue(state, action) {
            state.currentTemplateId = action.payload?.ecl_workflow_template_id;
            state.currentTemplateName = action?.payload?.ecl_workflow_template_name;
            state.currentWorkflowId = action.payload?.workflowId;
            state.calculationDate = action?.payload?.date;
            state.currentStep = 4;
            state.ui.showMenu = false;


            // //updating
            // state.nodeList = action?.payload?.workflowNodes;
            // const lastNode = action?.payload?.workflowNodes?.at(action?.payload?.workflowNodes?.length - 1)

            // if (lastNode) {
            //     state.currentWorkflowNodeId = lastNode?.id;
            // }



        },
        updateNodeList(state, action) {
            state.nodeList = action?.payload;
            const lastNode = action?.payload?.at(action?.payload?.length - 1)

            if (lastNode) {
                state.currentWorkflowNodeId = lastNode?.id;
            }
        },
        setDeletableFileDetails(state, action) {
            state.deletableFileDetails = action?.payload
        },

        nodeClicked(state, action) {
            const clickedNodeIndex = state.nodeList.findIndex(node => node.id == action?.payload?.nodeId)
            const clickedNode = state.nodeList[clickedNodeIndex]
            const previousNode = clickedNode?.parent_node_id ? state.nodeList[clickedNodeIndex - 1] : null

            if (clickedNode?.parent_node_id && previousNode?.node_status == "Pending") {
                state.ui.nodeError = "Current step must be completed first";
            } else {
                state.currentWorkflowNodeId = action?.payload?.nodeId
                state.ui.nodeError = ""
            }

        },
        resetWorkflow(state, action) {
            return initialWorkflowState
        },
        cancelClicked(state, { payload }) {
            const nodeIndex = state.nodeList.findIndex(node => node?.id == payload?.workflowNodeId)

            state.nodeList[nodeIndex] = {
                ecl_node_id: state.nodeList[nodeIndex]?.ecl_node_id,
                id: state.nodeList[nodeIndex]?.id,
            }
        },
        openActivityLogState(state, action) {
            state.ui.isActivityLogOpen = action?.payload
        },
        setSelectedDeleteFiles(state, action) {
            console.log(action?.payload, 'ncjs cjhvc ');
            state.selectedDeleteFiles = action?.payload
        },

        toggleEditMode(state, action) {
            const nodeIndex = state.nodeList.findIndex(node => node.id == state.currentWorkflowNodeId)
            state.nodeList[nodeIndex].isEditMode = !state.nodeList[nodeIndex].isEditMode
        }

    },
    extraReducers: (builder) => {
        builder
            .addCase(checkTemplateName.fulfilled, (state, action) => {
                state.currentStep = 4;
                state.ui.showMenu = false
                state.currentTemplateName = action.payload.templateName;
            })
            .addCase(checkTemplateName.rejected, (state, action) => {
                toast.error(action.payload);
                // state.templateName = '';
            })
        builder
            .addCase(checkFileName.fulfilled, (state, action) => {
                state.ui.fileStatusInWorkFlow = { alreadyExist: false, message: action?.payload }
            })
            .addCase(checkFileName.rejected, (state, action) => {
                state.ui.fileStatusInWorkFlow = { alreadyExist: true, message: action?.payload?.errors}
            })
        builder
            .addCase(getNodeWiseCategoryList.fulfilled, (state, action) => {
                state.nodeWiseCategory = action?.payload?.categoryList;
                state.ui.filesMerging = false;
            })
            .addCase(getNodeWiseCategoryList.pending, (state, action) => {
                state.ui.filesMerging = true;
            })

        builder
            .addCase(fetchSplitsBySeedvalue.fulfilled, (state, action) => {
                state.splitsList = action?.payload?.data;
                state.ui.seedValueLoading = false;
            })
            .addCase(fetchSplitsBySeedvalue.pending, (state, action) => {
                state.ui.seedValueLoading = true;
            })

        builder
            .addCase(fetchColumnListDatatype.fulfilled, (state, action) => {
                state.columnList = action?.payload?.columns;
                state.ui.isColumnListLoading = false
            })
            .addCase(fetchColumnListDatatype.pending, (state, action) => {
                state.ui.isColumnListLoading = true
            })

        builder
            .addCase(getRenamedColumnList.fulfilled, (state, action) => {
                state.renamedColumns = action?.payload?.renamedColumns
            })
        builder
            .addCase(getColumnListForRename.fulfilled, (state, action) => {
                state.columnsListForRename = action?.payload?.columnsListForRename
            })

        builder
            .addCase(fileUploadSummary.pending, (state, action) => {
                state.ui.isFileSummaryLoading = true
            })
            .addCase(fileUploadSummary.fulfilled, (state, action) => {
                state.ui.isFileSummaryLoading = false

                const nodeId = action.payload?.workflowNodes[0]?.id
                const nodeIndex = state.nodeList.findIndex(node => node.id == nodeId)
                if (nodeId) {
                    state.nodeList[nodeIndex] = {
                        ...action.payload?.workflowNodes[0],
                        isReplace: true
                    }
                } else {
                    state.nodeList[nodeIndex] = action.payload?.workflowNodes[0]
                }
            })
            .addCase(fileUploadSummary.rejected, (state, action) => {
                toast.error(action.payload);
            })

        builder
            .addCase(eclNodesave.pending, (state, action) => {
                state.ui.isCalculating = true
                state.ui.nodeError = ""
            })
            .addCase(eclNodesave.fulfilled, (state, action) => {
                state.ui.isCalculating = false

                state.currentTemplateId = action.payload?.calculationResponse?.template_id;
                state.currentWorkflowId = action.payload?.calculationResponse?.workflowId;

                const nodeIndex = state.nodeList.findIndex(node => node.id == state.currentWorkflowNodeId)
                state.currentWorkflowNodeId = action.payload?.calculationResponse?.workflowNodeId;

                state.nodeList[nodeIndex] = action.payload?.nodeDetailsResponse?.workflowNodes[0]


                if (action?.payload?.from == "proceedNew") {
                    state.ui.isModalBuilderOpen = true
                } else if (action?.payload?.from == "uploadAnother") {
                    state.nodeList.push({
                        ecl_node_id: state.nodeList[nodeIndex]?.ecl_node_id,
                        id: null,
                    });
                    state.currentWorkflowNodeId = null;
                    // state.c
                    state.ui.isModalBuilderOpen = false
                }
            })
            .addCase(eclNodesave.rejected, (state, action) => {
                toast.error(action.payload);
            })

        builder
            .addCase(getPositionAnalysisCategoryList.pending, (state, action) => {
                state.ui.isPositionAnalysisListLoading = true
            })
            .addCase(getPositionAnalysisCategoryList.fulfilled, (state, action) => {
                state.ui.isPositionAnalysisListLoading = false
                const mData = action?.payload?.data?.map((obj) => ({
                    label: obj?.category_name,
                    value: obj?.category_id,
                }));
                state.positionAnalysisCategory = mData
            })

        builder
            .addCase(positionAnalysisCategorySave.pending, (state, action) => {
                state.ui.isCalculating = true
                state.ui.nodeError = ""
            })
            .addCase(positionAnalysisCategorySave.fulfilled, (state, action) => {
                state.ui.isCalculating = false

                const nodeIndex = state.nodeList.findIndex(node => node.id == state.currentWorkflowNodeId)
                state.nodeList[nodeIndex] = {
                    ...state.nodeList[nodeIndex],
                    id: action?.payload?.workflowNodeId,
                    mergedResults: action.payload?.mergeResult?.mergedResults
                }
                state.currentWorkflowNodeId = action?.payload?.workflowNodeId

            })
            .addCase(positionAnalysisCategorySave.rejected, (state, action) => {
                toast.error(action.payload);
            })

        builder
            .addCase(getWorkflowList.pending, (state, action) => {
                state.isInitialLoading = true
                state.ui.isDataLoading = true
            })
            .addCase(getWorkflowList.fulfilled, (state, { payload }) => {
                state.isInitialLoading = false
                state.ui.isDataLoading = false
                state.currentTemplateId = payload?.nodeListData?.ecl_workflow_template_id;
                state.currentTemplateName = payload?.nodeListData?.ecl_workflow_template_name;
                state.currentWorkflowId = payload?.nodeListData?.workflowId;
                state.calculationDate = payload?.nodeListData?.date;
                state.currentStep = 4;
                state.ui.showMenu = false;

                //updating
                state.nodeList = [...payload?.nodeListData?.workflowNodes];
                let lastNode = state.nodeList?.at(state.nodeList?.length - 1)

                let pendingImputationNode = lastNode?.parent_node_id ? state.nodeList.find(
                    node => node.parent_node_id && node.nodeCategoryId == lastNode.nodeCategoryId && node?.node_status == "Pending"
                ) : null

                console.log("dataimputationindex", lastNode, pendingImputationNode);
                if (pendingImputationNode) lastNode = pendingImputationNode


                if (lastNode) {
                    state.currentWorkflowNodeId = lastNode?.id;
                    if (lastNode?.ecl_node_id >= 4) {
                        const nodeIndex = state.nodeList.findIndex(node => node.id == state.currentWorkflowNodeId)
                        state.nodeList[nodeIndex] = {
                            ...state.nodeList[nodeIndex],
                            mergedResults: payload?.nodeDetailsResponse?.mergedResults
                        }
                    } else {
                        state.nodeList[state.nodeList?.length - 1] = payload?.nodeDetailsResponse?.workflowNodes[0]
                    }
                }

            })

        builder
            .addCase(setCurrentNode.pending, (state, action) => {
                state.ui.isDataLoading = true
                // state.currentWorkflowNodeId = action.payload?.workflowNodeId;

            })
            .addCase(setCurrentNode.fulfilled, (state, { payload }) => {
                state.ui.isDataLoading = false
                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                if (payload?.eclNodeId >= 4) {
                    state.nodeList[nodeIndex] = {
                        ...state.nodeList[nodeIndex],
                        mergedResults: payload?.nodeDetailsResponse?.mergedResults
                    }
                } else {
                    state.nodeList[nodeIndex] = payload?.nodeDetailsResponse?.workflowNodes[0]
                }

            })
            .addCase(setCurrentNode.rejected, (state, action) => {
                toast.error(action.payload);
            })

        builder
            .addCase(deleteNode.pending, (state, action) => {
                state.ui.isDeletingNode = true
            })
            .addCase(deleteNode.fulfilled, (state, action) => {
                state.ui.isDeletingNode = false
            })
        builder
            .addCase(getActivityLog.fulfilled, (state, action) => {
                state.activityLogs = action?.payload?.activityLogs
            })
        builder
            .addCase(deleteCalculationCheck.fulfilled, (state, action) => {
                state.ui.deleteFileStatus = action?.payload?.data
            })
            .addCase(deleteCalculationCheck.rejected, (state, action) => {
                state.ui.deleteFileStatus = action?.payload?.data
            })
        builder
            .addCase(getOutlierColumnName.fulfilled, (state, action) => {
                state.outlierColumns = action?.payload?.outlierColumns
            })
        builder
            .addCase(getSelectedMethodBasedData.fulfilled, (state, action) => {
                state.selectedMethodBasedData = action?.payload?.data?.outlierMethodDatas
            })

        builder
            .addCase(getMergeSummaryResult.pending, (state, action) => {
                state.ui.mergeSummaryLoading = true
            })
            .addCase(getMergeSummaryResult.fulfilled, (state, action) => {
                state.ui.mergeSummaryLoading = false
                const nodeIndex = state.nodeList.findIndex(node => node.id == state.currentWorkflowNodeId)
                state.nodeList[nodeIndex].mergedResults = action?.payload
            })

        builder
            .addCase(saveMismatchCategory.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(saveMismatchCategory.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex].mismatchTableData = action.payload
                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id

            })

        builder
            .addCase(getDataImputationList.pending, (state, action) => {
                state.ui.isDropdownLoading = true
            })
            .addCase(getDataImputationList.fulfilled, (state, action) => {
                state.ui.isDropdownLoading = false
                state.dataImputationdList = action.payload?.dataImputationNames
            })

        builder
            .addCase(mismatchNodesave.pending, (state, action) => {
                state.ui.isCalculating = true
                state.ui.nodeError = ""
            })
            .addCase(mismatchNodesave.fulfilled, (state, action) => {
                state.ui.isCalculating = false

                const nodeIndex = state.nodeList.findIndex(node => node.id == state.currentWorkflowNodeId)
                state.currentWorkflowNodeId = action.payload?.calculationResponse?.workflowNodeId;

                state.nodeList[nodeIndex].mismatchTableData = action.payload?.nodeDetailsResponse
                state.nodeList[nodeIndex].isEditMode = true
                state.nodeList[nodeIndex].id = state.currentWorkflowNodeId
                // state.ui.isModalBuilderOpen = true

            })
            .addCase(mismatchNodesave.rejected, (state, action) => {
                toast.error(action.payload);
            })

        builder
            .addCase(saveNullblankCategory.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(saveNullblankCategory.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex].nullBlankTableData = action.payload
                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id

            })

        builder
            .addCase(imputationUserDefinedDetail.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(imputationUserDefinedDetail.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex].imputationUserDefinedTableData = action.payload

            })

        builder
            .addCase(imputationReplaceAverageDetail.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(imputationReplaceAverageDetail.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex].replaceAverageTableData = action.payload

            })
        builder
            .addCase(imputationReplaceEarliestDetail.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(imputationReplaceEarliestDetail.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex].replaceEarliestData = action.payload

            })
        builder
            .addCase(imputationReplaceLatestDetail.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(imputationReplaceLatestDetail.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex].replaceLatestData = action.payload

            })
        builder
            .addCase(imputationReplaceMappingDetail.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(imputationReplaceMappingDetail.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex].replaceMappingData = action.payload

            })

        builder
            .addCase(floorAndCapDetail.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(floorAndCapDetail.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex].floorAndCapTableData = action.payload

            })

        builder
            .addCase(fetchSplitDataDetails.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(fetchSplitDataDetails.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex].splitTableData = action.payload
                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id

            })

        builder
            .addCase(fetchZscoreDetails.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(fetchZscoreDetails.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex].zscoreTableData = action.payload
                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id

            })

        builder
            .addCase(fetchNumericColumnList.fulfilled, (state, action) => {
                state.numericColumnList = action?.payload?.numericColumns;
            })

        builder
            .addCase(fetchNaturalLogDetails.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(fetchNaturalLogDetails.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex].naturalLogTableData = action.payload
                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id

            })

        builder
            .addCase(fetchUnexpecredValuesDetail.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(fetchUnexpecredValuesDetail.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex].unexpectedValuesTableData = {
                    ...action.payload,
                    unexpectedValueDatas: reorderArray([...action.payload?.unexpectedValueDatas], "column_name")
                }
                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id

            })

        builder
            .addCase(unexpectedValuesNodeSave.pending, (state, action) => {
                state.ui.isCalculating = true
                state.ui.nodeError = ""
            })
            .addCase(unexpectedValuesNodeSave.fulfilled, (state, action) => {
                state.ui.isCalculating = false

                const nodeIndex = state.nodeList.findIndex(node => node.id == state.currentWorkflowNodeId)
                state.currentWorkflowNodeId = action.payload?.calculationResponse?.workflowNodeId;
                state.nodeList[nodeIndex].id = state.currentWorkflowNodeId

            })
            .addCase(unexpectedValuesNodeSave.rejected, (state, action) => {
                toast.error(action.payload);
            })

        builder
            .addCase(splitDataNodesave.pending, (state, action) => {
                state.ui.isCalculating = true
                state.ui.nodeError = ""
            })
            .addCase(splitDataNodesave.fulfilled, (state, action) => {
                state.ui.isCalculating = false

                const nodeIndex = state.nodeList.findIndex(node => node.id == state.currentWorkflowNodeId)
                state.currentWorkflowNodeId = action.payload?.calculationResponse?.workflowNodeId;
                state.nodeList[nodeIndex].id = state.currentWorkflowNodeId
                state.nodeList[nodeIndex].isCurrentlySaved = true;

            })
            .addCase(splitDataNodesave.rejected, (state, action) => {
                toast.error(action.payload);
            })

        builder
            .addCase(zScoreNodesave.pending, (state, action) => {
                state.ui.isCalculating = true
                state.ui.nodeError = ""
            })
            .addCase(zScoreNodesave.fulfilled, (state, action) => {
                state.ui.isCalculating = false

                const nodeIndex = state.nodeList.findIndex(node => node.id == state.currentWorkflowNodeId)
                state.currentWorkflowNodeId = action.payload?.calculationResponse?.workflowNodeId;
                state.nodeList[nodeIndex].id = state.currentWorkflowNodeId

            })
            .addCase(zScoreNodesave.rejected, (state, action) => {
                toast.error(action.payload);
            })

        builder
            .addCase(detailbinningdataList.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(detailbinningdataList.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)
                console.log(state.nodeList, nodeIndex, action.payload, "action.payload");
                state.nodeList[nodeIndex].bucketMappingTableData = action.payload
                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id

            })

        builder
            .addCase(saveBinningUserdefined.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(saveBinningUserdefined.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex].bucketMappingTableData = action.payload

            })
        builder
            .addCase(naturalLogNodeSave.pending, (state, action) => {
                state.ui.isCalculating = true
                state.ui.nodeError = ""
            })
            .addCase(naturalLogNodeSave.fulfilled, (state, action) => {
                state.ui.isCalculating = false

                const nodeIndex = state.nodeList.findIndex(node => node.id == state.currentWorkflowNodeId)
                state.currentWorkflowNodeId = action.payload?.calculationResponse?.workflowNodeId;
                state.nodeList[nodeIndex].id = state.currentWorkflowNodeId

            })
            .addCase(naturalLogNodeSave.rejected, (state, action) => {
                toast.error(action.payload);
            })

        builder
            .addCase(getMergeSummaryFileCreation.pending, (state, action) => {
                state.ui.filesMerging = true
            })
            .addCase(getMergeSummaryFileCreation.fulfilled, (state, action) => {
                state.ui.filesMerging = false
            })

        builder
            .addCase(exceptionAnalysisProceed.pending, (state, action) => {
                state.ui.isProceedLoading = true
            })
            .addCase(exceptionAnalysisProceed.fulfilled, (state, { payload, meta }) => {
                state.ui.isProceedLoading = false
                const nodeIndex = state.nodeList.findIndex(node => node.id == state.currentWorkflowNodeId)
                state.nodeList = [...payload?.nodeListData?.workflowNodes];
                if (payload?.isProceeded) {
                    state.currentWorkflowNodeId = payload?.calculationResponse?.workflowNodeId
                } else {
                    state.currentWorkflowNodeId = state.nodeList[nodeIndex + 1]?.id
                }
                state.ui.nodeError = ""
                state.ui.isModalBuilderOpen = meta?.arg?.notOpenBuilder == true ? false : true
            })
        builder
            .addCase(imputationProceed.pending, (state, action) => {
                state.ui.isCalculating = true
                state.ui.nodeError = ""
            })
            .addCase(imputationProceed.fulfilled, (state, action) => {
                state.ui.isCalculating = false
                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)
                state.nodeList = action?.payload?.nodeListData?.workflowNodes

                const node = state?.nodeList?.at(nodeIndex + 1)
                if (node?.id) {
                    state.currentWorkflowNodeId = node?.id
                } else {
                    state.ui.isModalBuilderOpen = true
                }
            })
            .addCase(imputationProceed.rejected, (state, action) => {
                toast.error(action.payload);
            })

        builder
            .addCase(nullBlankNodesave.pending, (state, action) => {
                state.ui.isCalculating = true
                state.ui.nodeError = ""
            })
            .addCase(nullBlankNodesave.fulfilled, (state, action) => {
                state.ui.isCalculating = false

                const nodeIndex = state.nodeList.findIndex(node => node.id == state.currentWorkflowNodeId)
                state.currentWorkflowNodeId = action.payload?.calculationResponse?.workflowNodeId;
                state.nodeList[nodeIndex].id = state.currentWorkflowNodeId
                // state.ui.isModalBuilderOpen = true

            })
            .addCase(nullBlankNodesave.rejected, (state, action) => {
                toast.error(action.payload);
            })
            
        builder
            .addCase(getSaveOutlier.pending, (state, action) => {
                state.ui.isDataLoading = true;
            })
            .addCase(getSaveOutlier.fulfilled, (state, action) => {
                // state.ui.isDataLoading = true;
            })

        builder
            .addCase(getOutlierdata.fulfilled, (state, action) => {
                state.ui.isCalculating = false
                state.ui.isDataLoading = false;

                const nodeIndex = state.nodeList.findIndex(node => node.id == state.currentWorkflowNodeId)
                if (action.payload?.calculationResponse) {
                    state.currentWorkflowNodeId = action.payload?.calculationResponse?.workflowNodeId;
                }
                state.nodeList[nodeIndex].outLierTableData = action?.payload?.outlierData
                state.nodeList[nodeIndex].id = state.currentWorkflowNodeId
                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.requestData?.category_id


            })

            .addCase(getOutlierdata.pending, (state, action) => {
                state.ui.isCalculating = true
            })

        builder
            .addCase(getBinningColumnList.pending, (state, action) => {
                state.ui.isDropdownLoading = true
            })
            .addCase(getBinningColumnList.fulfilled, (state, action) => {
                state.ui.isDropdownLoading = false
                state.binningColumnList = action.payload?.numericColumns
            })

        builder
            .addCase(saveBinningCategory.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(saveBinningCategory.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)
                state.categoryTableData = action.payload;

                state.nodeList[nodeIndex].dtBinningRangeDatas = action.payload
                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id

            })

        builder
            .addCase(saveBinningBucket.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(saveBinningBucket.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex] = action.payload
                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id

            })

        builder
            .addCase(fetchBBPercentileDetail.pending, (state, action) => {

            })
            .addCase(fetchBBPercentileDetail.fulfilled, (state, action) => {

                const nodeIndex = state.nodeList.findIndex(node => node.id == state.currentWorkflowNodeId)
                state.nodeList[nodeIndex].dtBucketPercentileDatas = action?.payload?.data
                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id

            })

        builder
            .addCase(binningBuckettingNodeSave.pending, (state, action) => {
                state.ui.isCalculating = true
                state.ui.nodeError = ""
            })
            .addCase(binningBuckettingNodeSave.fulfilled, (state, action) => {
                state.ui.isCalculating = false
                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)
                if (action.payload?.calculationResponse) {
                    state.currentWorkflowNodeId = action.payload?.calculationResponse?.workflowNodeId;
                    state.currentTemplateId = action.payload?.calculationResponse?.template_id;
                    state.currentWorkflowId = action.payload?.calculationResponse?.workflowId;
                }
                state.nodeList = action?.payload?.nodeListData?.workflowNodes
            })
            .addCase(binningBuckettingNodeSave.rejected, (state, action) => {
                toast.error(action.payload);
            })

        builder
            .addCase(bbUserNodeSave.pending, (state, action) => {
                state.ui.isCalculating = true
                state.ui.nodeError = ""
            })
            .addCase(bbUserNodeSave.fulfilled, (state, action) => {
                state.ui.isCalculating = false
                state.nodeList = action?.payload?.nodeListData?.workflowNodes
                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)
                // state.nodeList = action?.payload?.nodeListData
                state.nodeList = action?.payload?.nodeListData?.workflowNodes
                state.nodeList[nodeIndex].id = state.currentWorkflowNodeId


            })
            .addCase(bbUserNodeSave.rejected, (state, action) => {
                toast.error(action.payload);
            })

        builder
            .addCase(getCreateDuplicateColumnDetail.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(getCreateDuplicateColumnDetail.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex].createDupColumnData = action.payload
                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id

            })

        builder
            .addCase(createDuplicateColumnSave.pending, (state, action) => {
                state.ui.isCalculating = true
                state.ui.nodeError = ""
            })
            .addCase(createDuplicateColumnSave.fulfilled, (state, action) => {
                state.ui.isCalculating = false
                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)
                if (action.payload?.calculationResponse) {
                    state.currentWorkflowNodeId = action.payload?.calculationResponse?.workflowNodeId;
                    state.currentTemplateId = action.payload?.calculationResponse?.template_id;
                    state.currentWorkflowId = action.payload?.calculationResponse?.workflowId;
                }
                state.nodeList = action?.payload?.nodeListData?.workflowNodes
            })
            .addCase(createDuplicateColumnSave.rejected, (state, action) => {
                toast.error(action.payload);
            })

        builder
            .addCase(mostFrequestlyOccuringDetail.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(mostFrequestlyOccuringDetail.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)

                state.nodeList[nodeIndex].mostFrequentlyOccuringData = action.payload

            })

        builder
            .addCase(getCumulativeDistibutionDetails.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(getCumulativeDistibutionDetails.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId);

                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id;

                state.nodeList[nodeIndex].cumulatativeDistributionData = action.payload;
            })      

        nodeSaveCases(builder, dtCumulativeDisFunctionNodesave);

        builder.addCase(standardizeMinMaxScalingDetails.pending, (state, action) => {
                state.ui.isDataLoading = true
            }).addCase(standardizeMinMaxScalingDetails.fulfilled, (state, action) => {
                state.ui.isDataLoading = false
    
                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId)
    
                state.nodeList[nodeIndex].minmaxscallingData = action.payload
                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id
    
            })
        nodeSaveCases(builder, minmaxscallingNodesave);
      
        builder
            .addCase(dtNormalizationPercentilesDetail.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(dtNormalizationPercentilesDetail.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId);

                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id;

                state.nodeList[nodeIndex].dtNormalizationPercentilesData = action.payload;

            })    
        
        nodeSaveCases(builder, dtNormalizationPercentilesNodesave);

        builder
            .addCase(dtNormalizationDecimalScallingDetail.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(dtNormalizationDecimalScallingDetail.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId);

                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id;

                state.nodeList[nodeIndex].dtNormalizationDecimalScalingData = action.payload;

            })    
    
        nodeSaveCases(builder, dtNormalizationDecimalScallingNodesave);

        builder
            .addCase(getProbabilityDensityDetail.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(getProbabilityDensityDetail.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId);

                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id;

                state.nodeList[nodeIndex].dtProbabilityDensityData = action.payload;

            })  
        nodeSaveCases(builder, dtProbabilityDensityDetailNodesave);  

        builder
            .addCase(updateCurrentNodeData.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase( updateCurrentNodeData.fulfilled, (state, action) => {

                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId);
                
                state.nodeList[nodeIndex] = action.payload?.nodeDetailsResponse?.workflowNodes[0];

            }) 

        builder
            .addCase(dtStandardizeRobustScalingDetail.pending, (state, action) => {
                state.ui.isDataLoading = true
            })
            .addCase(dtStandardizeRobustScalingDetail.fulfilled, (state, action) => {
                state.ui.isDataLoading = false

                const nodeIndex = state.nodeList?.findIndex(node => node?.id == state.currentWorkflowNodeId);

                state.nodeList[nodeIndex].nodeCategoryId = action?.meta?.arg?.category_id;

                state.nodeList[nodeIndex].dtStandardizeRobustScalingData = action.payload;

            }) 

        nodeSaveCases(builder, dtStandardizeRobustScalingNodesave);  

        
                
                        
    },
});

export const selectIsInitialLoading = (state) => state?.Workflow?.isInitialLoading

export const selectCurrentNodeData = (state) => {
    const currentWorkflowNodeId = state?.Workflow?.currentWorkflowNodeId
    return state?.Workflow?.nodeList?.find(node => node?.id == currentWorkflowNodeId)
}

export const selectCurrentEclNodeData = (state) => {
    const sections = JSON.parse(localStorage.getItem(localStorageKeys.modelBuilderList))
    const currentNodeData = selectCurrentNodeData(state)
    return sections?.find(section => section?.ecl_nodes?.find(item => item?.id == currentNodeData?.ecl_node_id))
}

export const selectCurrentNodeIds = (state) => {
    const nodeIndex = state?.Workflow?.nodeList.findIndex(node => node?.id == state?.Workflow?.currentWorkflowNodeId)
    return {
        currentEclNodeId: state?.Workflow?.nodeList[nodeIndex]?.ecl_node_id,
        nodeCategoryId: state?.Workflow?.nodeList[nodeIndex]?.nodeCategoryId,
        currentWorkflowId: state?.Workflow?.currentWorkflowId,
        currentWorkflowNodeId: state?.Workflow?.currentWorkflowNodeId
    }
}

export const selectIsNodeDataAvailable = (nodeList, workflowNodeId) => {
    const currentNodeData = nodeList?.find(node => node?.id == workflowNodeId)
    if ((currentNodeData?.ecl_node_id == 1 || currentNodeData?.ecl_node_id == 2)) {
        return (currentNodeData?.node_value_summary?.length > 0)
    } else if ((currentNodeData?.ecl_node_id == 4)) {
        return (currentNodeData?.mergedResults?.length > 0)
    }
    return true;
}

export const selectIsDisableAction = (state) => {
    const workflowState = state?.Workflow;

    return workflowState?.isInitialLoading || workflowState?.ui?.isDataLoading || workflowState?.ui?.isDeletingNode || workflowState?.ui?.isFileSummaryLoading
        || workflowState?.fileUploading || workflowState?.ui?.isCalculating
        || workflowState?.currentStep != 4
}

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const {
    newNodeAdded,
    nextStep,
    showMenu,
    setShowMenu,
    closeModalBuilder,
    openModalBuilder,
    removeNode,
    setCalculationDate,
    setCurrentTemplateName,
    openSheetSelectPopup,
    closeSheetSelectPopup,
    setFileUploading,
    updateNodeList,
    openSheetDeletePopupState,
    closeSheetDeletePopupState,
    deletableFileDetails,
    setDeletableFileDetails,
    nodeClicked,
    updateInitialLoading,
    updateNodeDataInitial,
    resetWorkflow,
    cancelClicked,
    setCurrentNodeData,
    openActivityLogState,
    setSelectedDeleteFiles,
    toggleEditMode,
} = workflowSlice.actions

export const { updateWorkFlowValues, updateEclWorkFlowValue } = workflowSlice.actions;

// Export the slice reducer as the default export
export default workflowSlice.reducer;