import React, { useEffect, useState } from 'react';
//Components
import Button from '../../Components/Button';
import SortDropDown from '../../Components/ClientPage/SortDropDown';
import LeftArrow from '../../Components/CommonIcons/LeftArrow';
import NoDataFound from '../../Components/ClientPage/NoDataFound';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteSegments from '../../Routes/RouteSegments';
import { useDispatch, useSelector } from 'react-redux';
import { clientsCountryList, getClearCountryData, getClearLevelOneData } from '../../Store/action';
import CardSkeleton from '../../Components/Skeleton/CardSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import PageCard from '../../Components/ClientPage/Pagecard'

import loadMore from '../../Images/loadmore.webp'
import { Constants } from '../../Utils/Constants';
import SearchInput from '../../Components/ClientPage/SearchInput';

let vari = [];

const ClientsCountry = ({dynamicHeight}) => {

  const location = useLocation();
  const navigate= useNavigate()
  const [sortBy,setSortBy] = useState('created_at:desc');
  const [currentListCount,setCurrentListCount] = useState(6)
  const [countrySort,setCountrySort] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [selected,setSelected] = useState('Recently added');
  const [isNext, setIsNext] = useState(false) ;
  const [showLoader, setShowLoader] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [isVerticalScrollbarVisible, setIsVerticalScrollbarVisible] = useState(false);


  const newCalculation =(clientId,isFrom)=>{
    if(isFrom =='manageCategory'){
      navigate(RouteSegments.MANAGE_CATEGORY,{ state: {
       clientID:clientId,
       breadCrumbClientName:location?.state?.breadCrumbClientName} })     
    }else if(isFrom =='manageNull'){
      navigate(RouteSegments.MANAGE_NULL_VALUES,{ state: {
       clientID:clientId,
       breadCrumbClientName:location?.state?.breadCrumbClientName} })     
    }
    else{
    navigate(RouteSegments.NEW_CLIENT,{ state: { isFrom: isFrom,step:3 ,clientID:clientId,isNewClient:true,breadCrumbClientName:location?.state?.breadCrumbClientName} })
  }
  }
  const dispatch = useDispatch()

  const { ClientsCountryList, isLoading,hasMore} = useSelector(state => {
    return {
        ClientsCountryList: state?.Clients?.clientsCountryList,
        isLoading: state?.Clients?.isLoading,
        hasMore:state?.Clients?.hasMore
    }
})

const onNext = () => {        
  // Increase currentListCount by 6
  setIsNext(true)
  let newCount = currentListCount + 6;
  setCurrentListCount(newCount);

  // Dispatch action with the updated count
  dispatch(clientsCountryList(sortBy,newCount,searchTerm,location?.state?.clientId,countrySort));        
};

useEffect(()=>{        
  if(searchTerm?.length > 0){
      if(searchTerm?.length ==1){
          setCurrentListCount(Constants.listSize) 
          dispatch(clientsCountryList(sortBy,Constants.listSize,searchTerm,location?.state?.clientId,countrySort));           
      }
      else{
          dispatch(clientsCountryList(sortBy,currentListCount,searchTerm,location?.state?.clientId,countrySort));
      }
  } 
  else if(searchTerm?.length ==0){
      if(countrySort){
          dispatch(clientsCountryList(null,currentListCount,searchTerm,location?.state?.clientId,'desc'))  
      }
      else{
        dispatch(clientsCountryList(sortBy,Constants.listSize,searchTerm,location?.state?.clientId,null));           
      }
  }
},[searchTerm])

useEffect(()=>{    
  if(isNext){
      ClientsCountryList?.map((client,i)=>{
          vari.push(client)
          setCountriesList(vari)
      })
      setIsNext(false)
  }         
  else{
      vari = []
      ClientsCountryList?.map((client,i)=>{
          vari.push(client)
          setCountriesList(vari)
      })
  }               
  
},[ClientsCountryList]) 


  useEffect(() => {
    const handleResize = () => {
      setIsVerticalScrollbarVisible(document.documentElement.scrollHeight > window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
    <div className='w-full bg-white'>
    <div className='flex justify-between xl:py-3 py-2 xl:px-[4rem] px-[3rem] sticky  bg-white z-10'>
        <div className='flex gap-2 items-center'>
          <div className='flex gap-2 items-center cursor-pointer' onClick={()=>navigate(RouteSegments.CLIENTPAGE)} >
          <LeftArrow bgColor={'#d0cece'} className={'xl:w-[25px] xl:h-[25px] w-[20px] h-[20px]'}/>
          <p className='text-gray-light text-[13px] xl:text-[15px]'>Back</p>
          </div>
          <p className='text-dark-blue font-medium text-[12px] xl:text-[14px]'>{location?.state?.breadCrumbClientName}</p>
        </div>
          <div className='flex gap-4 items-center'>          
              {/* <Button bgColor='#DCFFFD' borderType="dashed" label={'Manage null'} color={'#42B2AC'} onClick={()=>newCalculation(location?.state?.clientId,'manageNull')} icon={'plus'} /> */}
              {/* <Button bgColor='#DCFFFD' borderType="dashed" label={'Manage category'} color={'#42B2AC'} onClick={()=>newCalculation(location?.state?.clientId,'manageCategory')} icon={'plus'} /> */}
              <Button bgColor='#DCFFFD' borderType="dashed" label={'Manage countries'} color={'#42B2AC'} onClick={()=>newCalculation(location?.state?.clientId,'manageCountry')} icon={'plus'} />
              <div className='flex space-x-4 '>
                <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} icon={true} placeholder={'Search country'}/>
                <SortDropDown isFrom="countriesList" setCurrentListCount={setCurrentListCount} clientId={location?.state?.clientId} setSortBy={setSortBy} record={currentListCount} searchTerm={searchTerm} setSearchTerm={setSearchTerm} setCountrySort={setCountrySort} countrySort={countrySort} selected={selected} setSelected={setSelected} page={"dashboard"}/>
              </div>              
          </div>
     </div>
    <>
        {isLoading ?
            <div className='w-full'>
                <CardSkeleton cards={3} menu={"clientList"} />
                <CardSkeleton cards={3} menu={"clientList"} marigin={'2rem'} />
            </div>
            :
            !isLoading && countriesList?.length > 0 
            ?
            ClientsCountryList?.length <=0 && searchTerm?.length > 0 
            ? <NoDataFound msg={'new country'} clientID={location?.state?.clientId} redirection={true}/> 
            :
            <div className='overflow-y-auto thin-scrollbar' style={{ height: dynamicHeight+"px" }}>
              <div id="scroll-node">
              <InfiniteScroll
                  dataLength={countriesList.length + Constants.listSize}    
                  // next={onNext} 
                  onScroll={()=>setShowLoader(true)}                  
                  // hasMore={hasMore}
                  loader={(hasMore && showLoader )&& <div className='w-full flex items-center justify-center'>
                      <img src={loadMore} alt="load more..." className='w-[20%] h-[5%]' />
                  </div>}
                  // endMessage={ClientsCountryList?.length < Constants.listSize
                  //     ? <div className='pb-[4rem] pt-6 w-full flex items-center justify-center'>
                  //         <h1>No more Clients</h1>
                  //     </div> : null}
              >
                  {countriesList?.length > 0 &&
                  <div className={`grid grid-cols-3 gap-5 mb-[3.5rem] mt-2 ${isVerticalScrollbarVisible ? 'xl:ml-[4rem] xl:mr-[3.7rem] ml-[3rem] mr-[2.7rem]' :'xl:ml-[4rem] xl:mr-[4rem] ml-[3rem] mr-[3rem]'}`}>
                      {
                          countriesList?.map(client => (
                          <PageCard 
                          key={client.id} 
                          data={client} 
                          isFrom={'countries'}
                          title={client?.country_name} 
                          isPrimary={client?.is_primary} 
                          label={client?.level_one_name} 
                          count={client?.level_one_count} 
                          onClick={(name,id)=>{dispatch(getClearCountryData());dispatch(getClearLevelOneData()); navigate(RouteSegments.LEVEL_ONE ,{ 
                            state:{
                              client_country_id:id,
                              clientId:client?.client_id,
                              breadCrumbClientName:location?.state?.breadCrumbClientName,
                              breadCrumbCountryName:client?.country_name}})
                            }}
                          />))
                      }
                  </div>
                  } 
              </InfiniteScroll>
              {hasMore &&
                <div className='w-full flex items-center justify-center mb-16'>                  
                  <Button bgColor='#00A7B5' borderType="" label={isLoading ? 'Loading...' : 'Load More'} icon={isLoading ? 'processing' : ''} color={'#FFFFFF'} onClick={()=>onNext()} size={'sm'} />
                </div>
              }
              </div>
            </div>
            :
            <div className='w-full'>
                <CardSkeleton cards={3} menu={"clientList"} />
                <CardSkeleton cards={3} menu={"clientList"} marigin={'2rem'} />
            </div>
            
        }
    </>   
    </div>
    </>
  )
}

export default ClientsCountry
