//Dependecies
import React, {useEffect, useState} from 'react'
import { Popover } from 'antd';
//images
import userprofile from '../../Images/userProfile.png'
import logouticon  from '../../Images/logout.png'
//Redux components
import { useDispatch} from 'react-redux';
import { logoutUser } from '../../Store/action';
import withRouter from '../../Routes/withRouter';
//Constants
import { loginLocalStorageKeys } from '../../Utils/Constants';


const LogoutDropdown = (props) => {

  const [accessToken,setAccessToken] = useState('')
  const dispatch = useDispatch()
  const token = localStorage.getItem(loginLocalStorageKeys.token)
  const userName = localStorage.getItem(loginLocalStorageKeys.userName)

useEffect(()=>{
  if(token){
  setAccessToken(token)
}
},[token])

  const onLogout =()=>{
    const histroy = props.router.navigate;
    dispatch(logoutUser('abc',histroy , accessToken ));
  }


  return <Popover
    trigger="click"
    placement='bottomRight'
    openClassName='p-0'
    overlayInnerStyle={{
      padding: '5px'
    }}
    
    content={
    <div style={{ minWidth: '130px'}}>
      <div className="px-2 cursor-pointer py-2 font-bold  xl:text-[14px] text-[12px] text-[#333333] hover:bg-gray-100 capitalize">{userName}</div>
      <div onClick={() => onLogout()}  className=" flex cursor-pointer px-2  py-2 gap-1 items-center xl:text-[14px] text-[12px] text-[#333333] hover:bg-gray-100">
        <img className='xl:w-5 xl:h-5 h-4 w-4' src={logouticon}/>Logout</div>
    </div>}>
    <img
      className="xl:h-[2rem] xl:w-[2rem] w-[1.5rem] h-[1.5rem] rounded-full cursor-pointer"
      src={userprofile}
      alt=""
    />  
  </Popover>
}
export default withRouter(LogoutDropdown);
