
import {    
    API_ERROR,
    COUNTRY_LIST,
    COUNTRY_LIST_FETCH_SUCCESS,
    CURRENCY_LIST,
    CURRENCY_LIST_FETCH_SUCCESS, 
} from './ActionType'



export const countryList = () => {
return{
    type: COUNTRY_LIST
};
};

export const countryListFetchSuccess = (payload) => ({
    type: COUNTRY_LIST_FETCH_SUCCESS,
    payload: payload,
});

export const countryApiError = error => {
    return {
      type: API_ERROR,
      payload: error,
    };
  };

  export const currencyList = () => {
    return{
        type: CURRENCY_LIST
    };
    };

    export const currencyListFetchSuccess = (payload) => ({
        type: CURRENCY_LIST_FETCH_SUCCESS,
        payload: payload,
    });
    
    export const currencyError = error => {
        return {
          type: API_ERROR,
          payload: error,
        };
      };
