import React from 'react';
import { useTable, usePagination,useSortBy } from 'react-table';
//icons
import ViewIcon from '../CommonIcons/ViewIcon';
import DownloadIcon from '../CommonIcons/DownloadIcon';
//imgaes
import paginationLeft from '../../Images/paginationLeft.svg'
import paginationRight from '../../Images/paginationRight.svg'
import ContinueIcon from '../CommonIcons/ContinueIcon';
import DropDown from '../DropDown/DropDown';
import IndicationIcon from "../../Images/indication.svg";
import OverflowTooltip from '../ToolTip/OverFlowToolTip';

// ... (other imports)

const HistoryDataTable = ({ columns, data, headerclassName, bodyClassName, tableCellClassName, updatedDataType, onDatatypeChange, onViewClick, onDownloadClick, onReplacedClick, dropDownOptions, placeholder, name, selectedValue, tableHeight, disabled }) => {
 const tableInstance = useTable({
    columns,
    data,
    disableSortRemove:true,
   initialState: { pageSize: tableHeight ? 10 : data?.length },
},
    useSortBy,
    usePagination,
);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = tableInstance

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const totalPages = pageOptions?.length;

    if (totalPages <= 10) {
      // Show all page numbers if there are 10 or fewer pages
      for (let i = 0; i < totalPages; i++) {
        pageNumbers?.push(
          <button
            key={i}
            onClick={() => gotoPage(i)}
            className={`px-1.5 rounded 2xl:text-[13px] text-[12px] ${pageIndex === i ? 'active bg-[#707070] text-white' : 'text-[#707070]'}`}
          >
            {i + 1}
          </button>
        );
      }
    } else {
      // If more than 10 pages, show ellipsis before and after current page
      let startPage = Math?.max(0, pageIndex - 4);
      let endPage = Math?.min(startPage + 9, totalPages - 1);

      if (endPage - startPage < 9) {
        startPage = Math?.max(0, endPage - 9);
      }

      for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(
          <button
            key={i}
            onClick={() => gotoPage(i)}
            className={`px-1.5 rounded 2xl:text-[13px] text-[12px] ${pageIndex === i ? 'active bg-[#707070] text-white' : 'text-[#707070]'}`}
          >
            {i + 1}
          </button>
        );
      }

      // Add ellipsis before and after
      if (startPage > 0) {
        pageNumbers?.unshift(<span key="beforeEllipsis">...</span>);
      }
      if (endPage < totalPages - 1) {
        pageNumbers?.push(<span key="afterEllipsis">...</span>);
      }
    }

    return pageNumbers;
  };
  




  return (
    <>
      <div className={`w-full ${tableHeight}  thin-scrollbar`}>
      <table {...getTableProps()} className={`w-full sticky top-0 `}>
        {/* <thead className={`${headerclassName} sticky top-0`}> */}
          {headerGroups?.map(headerGroup => (
            <tr {...headerGroup?.getHeaderGroupProps()} className={`${headerclassName} sticky top-0 z-[1000]`}>
              {headerGroup?.headers.map((column, i) => (
                <th {...column?.getHeaderProps()} className={`whitespace-nowrap  ${headerclassName ? headerclassName : 'xl:py-2 py-1 2xl:text-[14px] xl:text-[13px] text-[11px] max-w-[10rem] min-w-[6rem]'} ${i==0 ? 'px-2' : ''}`}><OverflowTooltip text={column?.render('Header')} className={'2xl:text-[13px] text-[11px]'} /></th>
              ))}
            </tr>
          ))}
        {/* </thead> */}
        <tbody {...getTableBodyProps()} className={`custom-data-table ${tableCellClassName ?? 'text-[#707070] 2xl:text-[14px] xl:text-[12px] text-[9px] bg-white'}  divide-y divide-[#E1E1E1]`}>
        {data?.length === 1 && data[0].id==0 ? (
              <tr>
                <td colSpan={columns?.length} className='text-center py-16 2xl:text-[14px] xl:text-[13px] text-[12px]'>No data available</td>
              </tr>
            ) : (
            page?.map((row, rowIndex) => {
                prepareRow(row);
              console.log("rowIndex", row);
            return (
              <tr {...row.getRowProps()} className=''>
                {row?.cells?.map((cell, i) => {
                  const originalRow = JSON.parse(JSON.stringify(row?.original));
                  const isDownload = originalRow?.downloadAction;
                  const isView = originalRow?.viewAction;   
                  return (
                    cell?.column?.Header === 'Status' ?
                      <td {...cell.getCellProps()} className={`xl:py-3 py-1.5 px-2 `} key={i}>
                        <button className={`text-white xl:py-1 py-0.5 rounded xl:px-3 px-2 whitespace-nowrap cursor-default ${cell?.value === 'In progress' ? 'bg-[#ED7D31]' : 'bg-[#70AD47]'}`}>
                          {cell.render('Cell')}
                        </button>
                      </td>
                      :
                      cell?.column?.Header == 'Is replaced' ?
                      
                      <td {...cell.getCellProps()} className={`xl:py-3 py-1.5 px-2 ${cell?.value == 'Yes' ? 'cursor-pointer':'cursor-Default'} `} key={i} onClick={()=> cell?.value == 'Yes' ? onReplacedClick(originalRow?.id) :'/#' }>
                       {cell.render('Cell')} 
                      </td>
                      :
                      cell?.column?.Header === 'Actions' ?
                        <td {...cell.getCellProps()} className={`xl:py-3 py-1.5 px-2 flex items-center gap-4`} key={i}>
                          <div className='flex items-center gap-1 cursor-pointer' onClick={()=>onViewClick(originalRow?.id)}>
                            <ViewIcon className={'xl:w-[16px] xl:h-[16px] w-[13px] h-[13px]'} bgColor={'#4472c4'} />
                            <p className='underline text-[#4472C4]'>View</p>
                          </div>
                          <div className='flex items-center gap-1 cursor-pointer' onClick={()=>onDownloadClick(originalRow?.id,isDownload ? 'download':'continue')}>
                            {isDownload ? 
                            <DownloadIcon className={'xl:w-[16px] xl:h-[16px] w-[13px] h-[13px]'} bgColor={'#4472c4'} />
                            :
                            <ContinueIcon className={'xl:w-[16px] xl:h-[16px] w-[13px] h-[13px]'} bgColor={'#4472c4'} />
                            }
                            <p className='underline text-[#4472C4]'>{isDownload ? 'Download':'Continue'}</p>
                          </div>
                        </td>                        
                        :
                        cell?.column?.Header === 'Assign data type' ?
                        <td {...cell.getCellProps()} className={`xl:py-3 py-1.5 px-2 flex items-center gap-4`} key={i}>
                          <DropDown
                          dropDownOptions={dropDownOptions?.map((obj) => {
                          const isBoolean = obj?.data_type === "BOOLEAN";
                          const currentRowIsBoolean = originalRow?.current_data_type === 'Boolean';
                          const disableBoolean = isBoolean && originalRow?.maybe_boolean == false
                          ;
                          return {
                            label: obj?.label,
                            value: obj?.value,
                            data_type: obj?.data_type,
                            disabled:currentRowIsBoolean ? false : disableBoolean
                          };
                        })}                         
                          placeholder={placeholder}
                          name={name}
                          value={(updatedDataType ? updatedDataType[row?.id]?.currentDataType : null) || originalRow?.assigned_data_type_id}
                          ordinary={true}
                          disabled={disabled}
                          customTags={false}
                          selected={originalRow?.assigned_data_type_id ?? undefined}
                          border={'1px solid-[#E0DCD7]'}
                          className={'border-[1px] focus:border-[1px] focus:border-[#E0DCD7]'}
                          onOptionChange={(val, type, label) => onDatatypeChange(row?.id, val, originalRow?.assigned_data_type_id)}
                        />

                        </td>
                        :
                        cell?.column?.Header === 'Title' ?
                        <td {...cell.getCellProps()} className={`px-5 2xl:px-8 2xl:py-1.5 py-0.5 whitespace-nowrap bg-[#F4FFEE]`} key={i}>
                              <p>{cell.render('Cell')}</p>
                        </td>
                        :
                        cell?.column?.Header === 'Current data type' ?
                        <td {...cell.getCellProps()} className={`px-5 2xl:px-8 2xl:py-1.5 py-0.5 whitespace-nowrap`} key={i}>                              
                              {originalRow.multi_data_type == true ? (
                                    <div className='flex gap-6'>
                                        <p className='text-[#B70000]'>{cell.render('Cell')}</p> 
                                          <button className={`text-[#000000] 2xl:text-[11px] text-[10px] cursor-pointer xl:py-1 py-0.5 rounded xl:px-3 px-2 whitespace-nowrap  ${ originalRow.is_ignored == true ? 'bg-[#BDD4AD]':'bg-[#FEDBDB]'}`}>
                                            Ignored
                                          </button>
                                        </div>
                                      ) : 
                                      <>
                                      {originalRow?.maybe_boolean == true ?                                                                              
                                        <div className='flex gap-6'>
                                            <p className='text-[#000000]'>{cell.render('Cell')}</p> 
                                            <div className='flex border border-[#B70000] rounded-3xl gap-2 px-2 py-0.5'>
                                              <img src={IndicationIcon} className='w-3.5 2xl:w-4 3xl:w-5'/>
                                                <p className={`text-[#707070] 2xl:text-[11px] text-[10px] cursor-default whitespace-nowrap bg-white`}>
                                                It might be boolean
                                                </p>
                                              </div>
                                          </div> 
                                          :
                                          <p>{cell.render('Cell')}</p>  
                                      }
                                      </> 
                                }
                        </td>
                      
                        :
                        <td {...cell.getCellProps()} className={`${tableCellClassName ?? 'xl:py-3 py-1.5 px-2 whitespace-nowrap'} `} key={i}>{cell.render('Cell')}</td>
                  )
                })}
              </tr>
            );
          })
          )
        }
        </tbody>
      </table>     
    </div>
         
      {data?.length > 10 && tableHeight && (
      <div className={`mt-[10px] items-center flex justify-end`}>
        <button onClick={() => previousPage()} disabled={!canPreviousPage} className='border border-[#E1E1E1] p-1 mr-3'>
          <img src={paginationLeft}  className='2xl:w-3 2xl:h-3 w-2.5 h-2.5'/>
        </button>{'  '}
        {<div className='2xl:space-x-2 space-x-1 2xl:text-[13px] text-[12px]'>{renderPageNumbers()}</div>}
        <button onClick={() => nextPage()} disabled={!canNextPage} className='border border-[#E1E1E1] p-1 ml-3'>
            <img src={paginationRight} className='2xl:w-3 2xl:h-3 w-2.5 h-2.5'/>
        </button>{' '}
      </div>
    )} 
    </>
  ); 
};

export default HistoryDataTable;
