import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { binningBuckettingNodeSave, fetchBBPercentileDetail, fetchNumericColumnList, getCalculationFormData, getNodeWiseCategoryList, openModalBuilder, saveBBPercentile, selectCurrentNodeData, selectCurrentNodeIds } from '../../Store/Workflow/WorkflowSlice';
import DropDown from '../DropDown/DropDown';
import { useLocation } from 'react-router-dom';
import Button from '../Button';
import SelectInput, { TextInput } from '../SelectInput';
import * as Yup from 'yup';
import { createColumnHelper } from '@tanstack/react-table';
import SummaryTable from '../Table/SummaryTable';
import CloseIcon from "../../Images/remove.svg";
import ReadOnlyValueDisplay from '../CommonComponents/ReadOnlyValueDisplay';
import DataTableSkeleton from '../Skeleton/DataTableSkeleton';
//Components
import DataTable from '../Table/DataTable';

//imgaes
import XlsIcon from '../../Images/excel.svg'
import useDataTable from '../../hooks/useDataTable';
import { getBinningBucketingPercentileData } from '../../Helpers/ApiHelpers';
import EditableTable from '../Table/EditableTable';
import { toast } from 'react-toastify';
import ProceedButton from '../CommonComponents/ProceedButton';
import downloadingIcon from '../../Images/gif/downloading.gif'


const columnHelper = createColumnHelper();

function Binningapercentiles() {
    const dispatch = useDispatch();
    const currentNodeData = useSelector(selectCurrentNodeData) 
    const isBinningCalculatingData = useSelector(state => state?.Workflow?.ui?.isCalculating);
    const stateData = useSelector(state => state);
    const location = useLocation();
    const isCalculating = useSelector(state => state?.Workflow?.ui?.isCalculating)
    const { currentEclNodeId, currentWorkflowId, currentWorkflowNodeId } = useSelector(selectCurrentNodeIds)
    const isProceedLoading = useSelector(state => state?.Workflow?.ui?.isProceedLoading)
    const [showFields,setShowFields] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [percentileData, setpercentileData] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [showEdit,setShowEdit] = useState(false);
    const [categoryDisable, setCategoryDisable] = useState(false)
    const [selectedColumnData, setselectedColumnData] = useState([])
    const [selectedColumnDataPointTable, setSelectedColumnDataPointTable] = useState([])

    
    const formik = useFormik({
        initialValues: {
            category: currentNodeData?.nodeCategoryId,
            columnName: undefined,
            numberofBuckets: undefined
        },
        validationSchema: Yup.object({
            category: Yup.string().required('Required'),
        }),
        enableReinitialize: true,
    });

    const { dataTableColumns,
        getFilterOptions,
        summaryData,
        tableState,
        refreshDataTable,
        onTableStateChange,
        isDataLoading,
        isExportLoading,
        donwloadXl,
      } = useDataTable({ category_id: formik.values.category, ecl_node_id:currentEclNodeId , workflow_id:currentWorkflowId, from:"dtBucketPercentileDatas" })


    const onCategoryChange = (value) => {
        formik.setFieldValue("category", value);
    };
    const onColumnNameChange=(value)=> {
        formik.setFieldValue("columnName", value);
    };

    const onSave = () => {
        setLoading(true)
         const request = {

            "ecl_workflow_id":currentWorkflowId,
            "ecl_node_id":currentEclNodeId,
            "ecl_category_id":formik.values.category,
            "dt_binning_percentile_undo_datas": [],
            // "dt_binning_percentile_datas":[{
            //     "column_name": formik.values.columnName,
            //     "no_of_buckets": parseInt(formik.values.numberofBuckets),
            //     bucket_data: selectedColumnData
            // }] 
            "dt_binning_percentile_datas" : selectedColumnData

        }
        dispatch(saveBBPercentile(request)) .then(res => {
            if (res.type == `${saveBBPercentile.fulfilled}`) {
                // onCategorySave()  ;
                const requestFormData = getCalculationFormData({
                    client_branch_level_id: location?.state?.levelTwoCardId,
                    isFrom: "splitdata",
                    category_id: formik?.values?.category,
                    node_id: currentEclNodeId,
                    state: stateData,
                    nodeStatus:"Completed"
                });
        
                dispatch(binningBuckettingNodeSave({requestFormData}))   
                setLoading(false)         
            }            
        })        
     };
 
    const Bucketcolumns = [
        { accessor: 'start_percentile', header: 'Start percentile', editable: false },
        { accessor: 'start_point', header: 'Start value', editable: true},
        { accessor: 'end_percentile', header: 'End percentile', editable: false },
        { accessor: 'end_point', header: 'End value', editable: true },
        { accessor: 'system_bucket_name', header: 'Name of the bucket (System generated)', editable: false},
        { accessor: 'user_bucket_name', header: 'Name of the bucket (User defined)', editable: true },
      ];

    const BinningData = useMemo(() => {
        return percentileData?.buckets
    }, [percentileData]);

    const [binningTableData, setBinningtableData] = useState(BinningData);
    useEffect(() => {
        setBinningtableData(BinningData);
    }, [BinningData]);

    const{nodeWiseCategory,filesMerging,isTableDataLoading,numericColumnList}= useSelector(state => ({      
        nodeWiseCategory : state?.Workflow?.nodeWiseCategory,
        filesMerging : state?.Workflow?.ui?.filesMerging,        
        isTableDataLoading: state?.Workflow?.ui?.isDataLoading,
        numericColumnList: state?.Workflow?.numericColumnList

    }))

    useEffect(()=>{

        dispatch(getNodeWiseCategoryList({workflow_id:currentWorkflowId ,ecl_node_id:currentEclNodeId,workflow_node_id:currentWorkflowNodeId})) 

    },[])

    const BucketData = useMemo(() => {
        if(currentNodeData?.dtBucketPercentileDatas){
          setShowFields(true)
          setSelectedColumnDataPointTable(currentNodeData?.dtBucketPercentileDatas?.dtBinningPercentileDatas)
          setCategoryDisable(currentNodeData?.dtBucketPercentileDatas?.isEdit)
          setShowEdit(currentNodeData?.node_status == 'Completed')
        }
        return currentNodeData?.dtBucketPercentileDatas ?? []
      }, [currentNodeData?.dtBucketPercentileDatas])

      useEffect(() => {
        if (currentNodeData?.id && currentNodeData?.nodeCategoryId) {
            onCategorySave()                              
        }
      }, [currentNodeData?.nodeCategoryId,currentNodeData?.id])
      
      const onProceedClick = () => {
        dispatch(openModalBuilder())
      }

      const onCategorySave = () =>{
        const request ={
            "workflow_id":currentWorkflowId,
            "ecl_node_id":currentEclNodeId,
            "category_id":formik.values.category

        }
        dispatch(fetchBBPercentileDetail(request))
        .then(res => {
            if (res.type == `${fetchBBPercentileDetail.fulfilled}`) {
                // setIsEditMode(false)
                refreshDataTable()
                setLoading(false)
                setpercentileData([])
                setShowFields(true)
                setCategoryDisable(true)
                formik.setFieldValue("columnName", undefined);
                formik.setFieldValue("numberofBuckets", undefined);

               
        }})

      }
      useEffect(() => {
        if (currentNodeData?.nodeCategoryId) {
            dispatch(fetchNumericColumnList({
                "workflow_id": currentWorkflowId,
                "ecl_node_id": currentEclNodeId,
                "category_id": currentNodeData?.nodeCategoryId
            }))
        }
    }, [currentNodeData?.nodeCategoryId])


    const handleInputChange = (e) => {
        const { name, value } = e.target;        
        formik.setFieldValue("numberofBuckets", value);

    };
    

    const handleKeyPress = (e) => {
        const { key } = e;
        
        // Allow only numbers (1-9)
        if (!/^[0-9]$/.test(key)) {
            e.preventDefault();
        }
        };
      

      const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData('text');
        const value = e.target.value + pasteData;
        if (!/^[0-9]$/.test(value)) {
          e.preventDefault();
        }
      };

      const numberofBucketsOnblur = async (e)=>{
        
        if(formik.values.numberofBuckets != null && formik.values.numberofBuckets > 0  && formik.values.columnName){
            setIsDisabled(true)
            const request ={                        
            "workflow_id":currentWorkflowId,
            "ecl_node_id":currentEclNodeId,
            "category_id":formik.values.category,
            "column_name": formik.values.columnName,
            "no_of_buckets": formik.values.numberofBuckets
            }
            const response =await getBinningBucketingPercentileData(request)
            if(response.data?.status == 200){
                setIsDisabled(false)
              setpercentileData(response?.data?.dtBinningPercentileDatas)            
            }
        }
        else{
            toast.error('The number of buckets can only be positive numerical values other than 0')
        }
      }

      const onClickAdd = ()=>{
        setLoading(true)       
        setselectedColumnData(prev => ([
            ...prev,
            {
              "column_name": formik.values.columnName,
              "no_of_buckets": parseInt(formik.values.numberofBuckets),
              "bucket_data": binningTableData ,
            }
        ]))
  
        setSelectedColumnDataPointTable(prev => ([
            ...prev,
            ...binningTableData?.map((data,i)=>(
            {
              "column_name": formik.values.columnName,
              "no_of_buckets": parseInt(formik.values.numberofBuckets),              
              "user_bucket_name": data?.user_bucket_name,
              "bucket_column_name":formik.values.columnName+"_bucketed_percentile",
              "start_point":data?.start_point,
              "end_point":data?.end_point
            }
          ))
        ]))
        setLoading(false)
        setpercentileData([])
        setShowFields(true)
        setCategoryDisable(true)
        formik.setFieldValue("columnName", undefined);
        formik.setFieldValue("numberofBuckets", undefined);


      }


      const BucketDataColumns = useMemo(() => {
        return [
            columnHelper.accessor("column_name", {
                header: "Column name",
                mergeCells: true

            }),
            columnHelper.accessor("no_of_buckets", {
                header: "No. of buckets",
                mergeCells: true,
                anotherColumn:'column_name'

            }),
            columnHelper.accessor("user_bucket_name", {
                header: "Name of the bucket",

            }),
            columnHelper.accessor("start_point", {
                header: "Start Value",

            }),
            columnHelper.accessor("end_point", {
                header: "End Value",

            }),            

        ]
    }, [])

    const [isValid,setIsValid] = useState(true)
    
    return (
        <div className='my-2'>
            {
      filesMerging ?
        <div className='mt-2'>
        <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        </div>
        :  
        <div className={`flex gap-5 ${showEdit ? 'hidden':'block'}`}>
            <DropDown
                dropDownOptions={nodeWiseCategory?.map((obj) => ({
                    label: obj?.category_name,
                    value: obj?.category_id,
                    disabled: obj?.is_disabled
                }))}
                placeholder={'Select data category'}
                name={'usedCategory'}
                ordinary={true}
                disabled={location?.state?.isfromHistory || showEdit || categoryDisable}
                customTags={false}
                value={formik.values?.category ? formik.values?.category : undefined}
                onOptionChange={onCategoryChange}
            />
            <Button
                bgColor='#42B2AC'
                borderType=""
                disabled={!formik.values?.category || location?.state?.isfromHistory || showEdit || categoryDisable}
                label={'Save'}
                color={'#ffffff'}
                onClick={onCategorySave}
                size={'md'} />
        </div>
        }
        <div className='w-full flex gap-5'>
            <div className={`w-[50%] flex gap-5 items-end mt-[1.5rem] ${showFields && !showEdit ? 'block':'hidden'}`}>
                <DropDown
                    dropDownOptions={numericColumnList?.map((obj) => ({
                        label: obj?.column_label,
                        value: obj?.column_name,
                        disabled: selectedColumnDataPointTable?.some(method => method?.column_name == obj?.column_name)

                    }))}
                    placeholder={'Select Column Name'}
                    name={'column name'}
                    ordinary={true}
                    disabled={location?.state?.isfromHistory || isBinningCalculatingData}
                    customTags={false}
                    noDataFountMessage={'File must have integer column'}
                    value={formik.values?.columnName ? formik.values?.columnName : undefined}
                    onOptionChange={onColumnNameChange}
                />
                <div className='w-full'>
                    <h2 className='font-semibold text-[12px] 2xl:text-[13px] my-1 '>Number of buckets</h2>

                    <TextInput
                        placeholder="Enter number of buckets"
                        className={`place text-[12px] 2xl:text-[13px] custom-select-height ${isDisabled ? 'bg-[#E0DCD7]':'bg-white'}`} 
                        name="numberofBuckets"
                        min={1}
                        onKeyPress={handleKeyPress}
                        onChange={handleInputChange}
                        onPaste={handlePaste}
                        value={formik.values.numberofBuckets}
                        onBlur={numberofBucketsOnblur}
                        />
                </div>
            </div>
            <div className={`${Object.keys(percentileData).length > 0 ? 'flex gap-5 items-end w-[50%]':'hidden'}`} >
                <div className='w-full'>
                    <h2 className='font-semibold text-[12px] 2xl:text-[13px] my-1 '>Minimum value</h2>
                    <ReadOnlyValueDisplay>{(Number.isInteger(percentileData?.min_value) ? percentileData?.min_value : percentileData?.min_value?.toFixed(2))}</ReadOnlyValueDisplay>
                </div>
                <div className='w-full'>
                    <h2 className='font-semibold text-[12px] 2xl:text-[13px] my-1 '>Maximum value</h2>
                    <ReadOnlyValueDisplay>{(Number.isInteger(percentileData?.max_value) ? percentileData?.max_value : percentileData?.max_value?.toFixed(2))}</ReadOnlyValueDisplay>
                </div>
                <div className='w-full'>
                    <h2 className='font-semibold text-[12px] 2xl:text-[13px] my-1 '>Percentile</h2>
                    <ReadOnlyValueDisplay>{(Number.isInteger(percentileData?.percentile_range) ? percentileData?.percentile_range : percentileData?.percentile_range?.toFixed(2))}</ReadOnlyValueDisplay>

                </div>
            </div>
        </div>                            

           
            { binningTableData?.length > 0  ?            
            <>
                <div className={`w-full overflow-x-auto mt-6`}>               
                    <EditableTable 
                    columns={Bucketcolumns} 
                    tableData={binningTableData ?? []} 
                    setTableData={setBinningtableData} 
                    setIsValid={setIsValid}
                    thClassName={'bg-[#A06DFF] text-white text-[12px] 2xl:text-[14px] font-semibold p-2 whitespace-nowrap w-[15rem] px-4'}
                    tdClassName={'p-2 text-[12px] 2xl:text-[14px] text-[#A8A8A8] px-4'}
                    trClassName={'border-b border-[#E0DCD7]'}                    
                    />
                </div>
                <div className='flex justify-center mt-4'>
                <Button
                    bgColor='#42B2AC'
                    borderType=""
                    label={'Add'}
                    color={'#ffffff'}
                    onClick={onClickAdd}
                    disabled={!isValid || binningTableData?.some(data => data?.end_point == "" || data?.user_bucket_name == "" )}
                    size={'md'} />
                </div>
            </>
                : <></>
            }             
            
        <div className={``} >
        {
        isLoading || isDataLoading || isCalculating || selectedColumnDataPointTable?.length > 0  ? 
            isLoading || isDataLoading || isCalculating ? 
            <div className='mt-2'>
                <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={false} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            </div>
            :
            selectedColumnDataPointTable?.length > 0 &&
        
            <>
            <div className='mt-4'>
                <div className={`bg-[#2B144C] px-2 grid grid-cols-3 items-center ${showEdit ? 'block':'hidden'}`}>
                <div className='flex gap-3 items-center py-2 col-span-1'>
                    <h3 className='text-[#FFFFFF] text-[12px] whitespace-nowrap bg-[#F7BE7E] px-2 py-1 rounded-[4px] '>Data category</h3>
                    <p className='font-semibold text-[#FFFFFF] text-[10px] whitespace-nowrap'>{currentNodeData?.dtBucketPercentileDatas?.categoryName}</p>
                </div>
                <h2 className='text-white text-[12px] font-semibold text-center w-full col-span-1'>Calculated output</h2>                    
                </div>
                <SummaryTable
                    columns={BucketDataColumns}
                    // data={BucketData?.dtBinningPercentileDatas}
                    data={selectedColumnDataPointTable}

                    tdClassName='border-b border-[#E0DCD7] text-[#707070] whitespace-nowrap max-w-[14rem] w-[12rem]'
                    thClassName='bg-[#A06DFF] max-w-[14rem] w-[12rem]'
                    theadClassName='z-10'
                />
            </div>

                <div className={`flex justify-center mt-4 ${showEdit ? 'hidden':'block'}`}>
                    <Button
                        bgColor='#42B2AC'
                        borderType=""
                        disabled={binningTableData?.length > 0}
                        label={'Save'}
                        color={'#ffffff'}
                        onClick={onSave}
                        size={'md'} />
                </div>
            </>

            :<></>
        }
        </div>
            {(summaryData?.columnDatas?.length > 0 || isDataLoading || filesMerging || isCalculating || isTableDataLoading)  &&
            isDataLoading || isTableDataLoading
            ? <div className='mt-2'>
              <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            </div>
            : 
            summaryData?.columnDatas?.length > 0 ?
            <>
            <div className={`flex justify-end items-center`}>
                  {isExportLoading
                    ? <img src={downloadingIcon} className='w-6 mr-2' />
                    : <img src={XlsIcon} className=' mb-2 object-cover mr-2 cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
            </div>
              <DataTable
                columns={dataTableColumns}
                data={summaryData?.columnDatas ?? []}
                onTableStateChange={onTableStateChange}
                totalCount={summaryData?.totalRecords}
                isOptionsDisabled={location?.state?.isfromHistory}
                getFilterOptions={getFilterOptions}
                tableState={{
                  pagination: {
                    pageIndex: tableState.pageIndex,
                    pageSize: tableState.pageSize,
                  },
                  sorting: tableState?.sortBy ? [
                    {
                      id: tableState?.sortBy,
                      desc: tableState?.sortOrder === "desc"
                    }
                  ] : [],
                  columnFilters: tableState.columnFilters
                    ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                    : [],
                  columnPinning: tableState.columnPinning,
                  columnVisibility:tableState?.columnVisibility,
                }}
              />             
          {currentNodeData?.dtBucketPercentileDatas?.isEdit == false 
          ? null
          : <div className={`float-right 2xl:my-6 my-3 ${showEdit && !location?.state?.isfromHistory ? 'block' : 'hidden'}`}>
             <ProceedButton
                isLoading={isProceedLoading}
                onClick={onProceedClick}
              />           
          </div>}
            </>
            :
            <></>
          }

        </div>
    );
}

export default Binningapercentiles;
