import React, { useEffect, useMemo, useState } from 'react';
import { useTable } from 'react-table';
import grayicon from '../../Images/grayicon.svg';
import rediocn from '../../Images/redicon.svg';
import blueicon from '../../Images/blueicon.svg';
import excelicon from '../../Images/excel.svg';
import SelectInput from '../SelectInput';
import editiocn from '../../Images/Group 809.svg';
import OverflowTooltip from '../ToolTip/OverFlowToolTip';
import Button from '../Button';
import blockeditiocn from '../../Images/blockediticon.svg';
import SelectMethod from './SelectMethod';
import { downloadExport, getCalculationFormData,  getOutlierdata, getSaveOutlier, selectCurrentNodeData} from '../../Store/Workflow/WorkflowSlice';
import { useDispatch, useSelector } from 'react-redux';
import DropDown from '../DropDown/DropDown';
import { useFormik } from 'formik';
import { OUTLIER_ROWWISE_EXPORT } from '../../Helpers/EndPoints';
import { useLocation } from 'react-router-dom';
import downloadingIcon from '../../Images/gif/downloading.gif'
import { toast } from 'react-toastify';
import { reorderArray } from '../../Helpers/utils';


const RowWiseDownload = ({ workflow_id, ecl_node_id, category_id, column_name, method, disabled }) => {

    const [isDownloading, setisDownloading] = useState(false)
  
    const handleExportRow = async () => {
        setisDownloading(true);
        const request = {
            workflow_id,
            ecl_node_id,
            category_id,
            is_export: true,
            column_name: column_name,
            method: parseInt(method)
        };
        await downloadExport(request, OUTLIER_ROWWISE_EXPORT);
        setisDownloading(false);
    };
    return isDownloading
      ? <img src={downloadingIcon} className='w-6' />
      : <img alt='' onClick={handleExportRow} src={excelicon} className={`${disabled ? 'pointer-events-none color-change-image':'cursor-pointer'} w-4 h-4 xl:w-5 xl:h-5`}/>
  }

const MethodTable = ({
    tableData, workflow_id,
    ecl_node_id, category_id,
    setShowFields, showEdit, setShowEdit,
    selectedMethodData = {},
    setAllData, allData,
    selectedColumnName,
    selectedMethodId,
    selectedMethod,
    setSelectedMethod,
    afterMethodSave,
    onCoulmnEditClick, setIsColumnDisable
}) => {
    const [ignoredRows, setIgnoredRows] = useState([]);
    const [outlierDatas, setOutlierDatas] = useState(reorderArray([...tableData?.outlierAnalysisDatas], "column_label"));
    const [filteredData, setFilteredData] = useState([]);
    const location = useLocation()
    const reduxState = useSelector(state => state)

    const isAlreadySavedOnce = !tableData?.outlierAnalysisDatas?.filter(item => item?.actions === "selected" || !item?.actions)?.filter(item => !item?.data_imputation_method)?.length > 0
    const [editVisible, setEditVisible] = useState(isAlreadySavedOnce);
    const [showColumnEdit, setShowColumnEdit] = useState(!editVisible)
    const currentNodeData = useSelector(selectCurrentNodeData)




    console.log({ isAlreadySavedOnce, showEdit });
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            category: category_id,
            dataInputationList: tableData?.outlierAnalysisDatas?.map(data => ({ type: data?.data_imputation_method }))
        },
        enableReinitialize: true,
    });

    const onDataInputationChange = (index,id,columnName, value) => {
        formik.setFieldValue(`dataInputationList.${index}.type`, value);

        const updatedGroupedRows = outlierDatas.map(row => {
            console.log(row.id,'row.id');
            if (row.id === id) {
                return { ...row, data_imputation_method : value};
            } else if (row.column_name === columnName) {
                return { ...row, data_imputation_method : 'null' };
            }
            return row;
        });
         // Check if all rows with the same column_name are ignored
    const allIgnored = updatedGroupedRows.every(row => row.column_label === columnName && row.actions === 'ignored');

    // If all rows are ignored, change actions to ""
    if (allIgnored) {
        updatedGroupedRows.forEach(row => {
            if (row.column_name === columnName) {
                row.actions = "";
            }
        });
    }
    setOutlierDatas(updatedGroupedRows);

    };

    const dataImputationdList = useSelector(state => {
        return state?.Workflow?.dataImputationdList
    });

    const handleSelectTreatasException = (rowId, groupName) => {
        const updatedGroupedRows = outlierDatas.map(row => {
            if (row.id === rowId) {
                return { ...row, actions: 'selected' };
            } else if (row.column_name === groupName) {
                return { ...row, actions: 'ignored', data_imputation_method: null };
            }
            return row;
        });
         // Check if all rows with the same column_name are ignored
    const allIgnored = updatedGroupedRows.every(row => row.column_label === groupName && row.actions === 'ignored');

    // If all rows are ignored, change actions to ""
    if (allIgnored) {
        updatedGroupedRows.forEach(row => {
            if (row.column_name === groupName) {
                row.actions = "";
            }
        });
    }

        setOutlierDatas(updatedGroupedRows);       
    };

    const handleClickIgnored = (rowId,groupName,index) => {
        formik.setFieldValue(`dataInputationList.${index}.type`, undefined);
        const updatedGroupedRows = outlierDatas?.map(row =>
            row.id == rowId
                ? { ...row, actions: 'ignored', data_imputation_method: null }
                : row
        );
           // Check if all rows with the same column_name are ignored          
    // const allIgnored = updatedGroupedRows.every(row => row.column_label === groupName && row.actions === 'ignored');

    // // If all rows are ignored, change actions to ""
    // if (allIgnored) {
    //     updatedGroupedRows.forEach(row => {
    //         if (row.column_name === groupName) {
    //             row.actions = "";
    //         }
    //     });
    // }

        setOutlierDatas(updatedGroupedRows);
        setIgnoredRows([...ignoredRows, rowId]);
    };

    const handleEditClick = (columnName) => {
        //column edit
        const filteredData = outlierDatas?.filter(data => data.column_label === columnName);
        setFilteredData(filteredData)
        setEditVisible(false);
        setShowColumnEdit(false)
        setShowFields(true);
        setShowEdit(false)
        onCoulmnEditClick(filteredData, columnName)
    };

    const handleUndo = (rowId,groupName,index) => {
        const matchingIndices = outlierDatas
        .map((row, index) => (row.column_name === groupName ? index : -1))
        .filter(index => index !== -1);

    // Update formik values for matching indices
    formik.setValues(prevValues => {
        const updatedDataInputationList = prevValues.dataInputationList.map((item, index) => {
            if (matchingIndices.includes(index)) {
                return { ...item, type: undefined };
            }
            return item;
        });

        return { ...prevValues, dataInputationList: updatedDataInputationList };
    });
        const updatedGroupedRows = outlierDatas.map(row => {
            if (row.column_name === groupName) {
                return { ...row, actions: "" };
            }
            return row;
        });
         // Check if all rows with the same column_name are ignored
    const allIgnored = updatedGroupedRows.every(row => row.column_label === groupName && row.actions === 'ignored');

    // If all rows are ignored, change actions to ""
    if (allIgnored) {
        updatedGroupedRows.forEach(row => {
            if (row.column_name === groupName) {
                row.actions = "";
            }
        });
    }

        setOutlierDatas(updatedGroupedRows);    
    };
    
    const data = useMemo(() => outlierDatas, [outlierDatas]);

    const columns = useMemo(() => [
        {
            Header: 'Title',
            accessor: 'column_label',
            Cell: ({ row }) => {
                const span = row?.original?.span || 1;
                return (
                    <td rowSpan={span}>
                        <span className='whitespace-nowrap'>{row?.original?.column_label}</span>
                    </td>
                );
            },
        },
        {
            Header: 'Method',
            accessor: 'method_label',
            Cell: ({ row }) => (<span>{row.original?.method_label ? row.original?.method_label : '-'}</span>)
            
        },
        { Header: 'Input', accessor: 'input_label' , 
            Cell: ({ row }) => (<span>{row.original?.input_label ? row.original?.input_label : '-'}</span>)},
        { Header: 'Lower value', accessor: 'lower_value',
            Cell: ({ row }) => (<span>{row.original?.lower_value ? row.original?.lower_value : '-'}</span>)
         },
        { Header: 'Upper value', accessor: 'higher_value',
            Cell: ({ row }) => (<span>{row.original?.higher_value ? row.original?.higher_value : '-'}</span>)

         },
        { Header: 'No. of outliers', accessor: 'no_of_outliers',
            Cell: ({ row }) => (<span>{row.original?.no_of_outliers ? row.original?.no_of_outliers : '-'}</span>)
         },
        { Header: 'Percentage of outliers values', accessor: 'percentage_of_outliers_values',
            Cell: ({ row }) => (<span>{row.original?.percentage_of_outliers_values ? row.original?.percentage_of_outliers_values+ " %" : '-'}</span>)

        },            
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row }) => (
                    <div className={`underline ${row.original.actions === 'ignored' ? 'text-[#AFABAB]' : row.original.actions === 'selected' ? 'text-[#2F5597]' : 'text-[#FF6174]'} flex text-[12px] md:text-[10px] xl:text-[12px] gap-5 whitespace-nowrap items-center`}>
                        <button className={`flex gap-2 items-center ${editVisible ? 'hidden':'block'} ${row.original.actions === 'ignored' || row.original.actions === 'selected' ? 'pointer-events-none':'cursor-pointer'}`} onClick={() => handleSelectTreatasException(row.original.id, row.original.column_label)}>
                            <img src={row.original.actions === 'ignored' ? grayicon : row.original.actions === 'selected' ? blueicon:rediocn} className='w-3 h-3' alt="selected" /> Treat as exception
                        </button>
                        <span className={`flex items-center gap-2 underline ${editVisible ? 'hidden':'block'} ${row.original.actions === 'ignored' ? 'text-[#AFABAB]' : row.original.actions === 'selected' ? 'text-[#FF6174]':'text-[#FF6174]'}`}>
                            <button className={`flex gap-2 items-center ${row.original.actions === 'ignored' ? 'pointer-events-none':'cursor-pointer'}`} onClick={() => handleClickIgnored(row.original.id,row.original.column_label,row.index)}>
                            <img src={row.original.actions === 'ignored' ? grayicon : row.original.actions === 'selected' ? rediocn : rediocn} className='w-3 h-3' alt="Ignore" />Ignore
                            </button>
                        </span>
                        <RowWiseDownload  workflow_id={workflow_id} ecl_node_id={ecl_node_id} category_id={category_id} column_name={row.original.column_label} method={row.original.method} disabled={row.original?.no_of_outliers == 0}/>
                    </div>
            ),
        },
        {
            Header: 'Data imputation model',
            accessor: 'data_imputation_method',
            Cell: ({ row }) => {
                const value = formik.values.dataInputationList?.at(row.index)?.type || undefined;
                if(row.original.actions == ''){return "-"}
                return (
                    row.original.actions === 'selected' ? (
                        <div className='w-[13rem]'>
                        {!editVisible ?

                        // <DropDown
                        //     dropDownOptions={dataImputationdList?.map(data => ({
                        //         label: data?.name,
                        //         value: data?.id,
                        //         disabled: row.original.method_label == 'User defined values' ? data?.id !== 4 && data?.id !== 5 && data?.id !== 7 : data?.id !== 4 && data?.id !== 6 && data?.id !== 5 &&  data?.id !== 7
                        //     }))}
                        <DropDown
                            dropDownOptions={dataImputationdList?.map(data => {
                            const dataTypes = data?.data_type.split(',');
                            return {
                                label: data?.name,
                                value: data?.id,
                                disabled: data?.id == 1 ||data?.id == 6 
                                ? !dataTypes.includes(row.original.column_data_type) 
                                : (data?.id !== 4 && data?.id !== 5 && data?.id !== 7)
                            };
                            })}
                            placeholder={'Select any operation'}
                            name={'dataInputationList'}
                            ordinary={true}
                            disabled={false}
                            customTags={false}
                            value={value}
                            onOptionChange={(value) => onDataInputationChange(row.index, row.original.id,row.original.column_label, value)}
                        />
                        :
                        <OverflowTooltip text={dataImputationdList?.map(data => ({
                        label: data?.name,
                        value: data?.id})).find(element => element.value == value)?.label || null} /> 

                            }
                        </div>
                    ) : 
                    row.original.actions === 'ignored' ?
                    <div className={`${editVisible ? 'pointer-events-none':'cursor-pointer'} text-[12px] md:text-[10px] xl:text-[12px]`}>
                        <span className="text-[#FF6174]">ignored / </span>
                        <span className='text-[#00B0F0]' onClick={() => handleUndo(row.original.id,row.original.column_label,row.index)}>undo</span>
                    </div>
                    :
                    <></>
                    
                        
                );
            },
        },
    ], [ignoredRows, editVisible, outlierDatas, showEdit, formik.values.dataInputationList]);

    const tableInstance = useTable({ columns, data });

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = tableInstance;

    const mergedCells = {};
    let previousValue = null;
    let rowSpan = 1;

    rows.forEach((row, rowIndex) => {
        const cellValue = row.values.column_label;
        if (cellValue === previousValue) {
            rowSpan += 1;
        } else {
            if (previousValue !== null) {
                mergedCells[previousValue] = rowSpan;
            }
            rowSpan = 1;
        }
        previousValue = cellValue;
    });
    if (previousValue !== null) {
        mergedCells[previousValue] = rowSpan;
    }

    const handleSave = async () => {
        if(category_id){
        const requestData = {
            ecl_workflow_id: workflow_id,
            ecl_node_id: ecl_node_id,
            ecl_category_id: category_id,
            outlierDatas: outlierDatas
                
        }
      
        dispatch(getSaveOutlier(requestData) )
        .then((response) => {
            console.log(response,"response");
            if (response.type === `${getSaveOutlier.fulfilled}`) { 
                const requestData = {
                    workflow_id: workflow_id,
                    ecl_node_id: ecl_node_id,
                    category_id: category_id
                } 
                const requestFormData = getCalculationFormData({
                    client_branch_level_id: location?.state?.levelTwoCardId,
                    isFrom: "EA",
                    category_id: category_id,
                    node_id: ecl_node_id,
                    state: reduxState,
                    nodeStatus:"Pending",

                })
                 dispatch(getOutlierdata({requestFormData,requestData}))                 
                setShowEdit(true)
                setEditVisible(false)
            }else if(response.type === `${getSaveOutlier.rejected}`){
            }
        })
        .catch((error) => {
            console.log("API not working", error);
        }); 
    }else{
        toast.error('Category id must')
    }
    };
  
    const isButtonDisabled = outlierDatas?.some(outlier => {
        const colData = outlierDatas?.filter(data => data?.column_name == outlier?.column_name);
        return colData?.filter(data => !data?.data_imputation_method)?.length == colData?.length
    })

    const disableSave = Object.keys(allData)?.some(key => {
        const data = allData[key]
        return data?.isDisabled || (data?.method == 5 ? !data?.text_input : Object.values(data?.inputValues).some(value => value === ''))
    })

    useEffect(() => {
        if (editVisible) {
            setShowFields(false)
        }
    }, [editVisible])


    const handleEditSave = () => {
        if (category_id) {
            const requestData = {
                ecl_workflow_id: workflow_id,
                ecl_node_id: ecl_node_id,
                ecl_category_id: category_id,
                outlierDatas: Object.keys(allData)?.map(key => {
                    const data = allData[key]
                    return {
                        "column_name": data?.column_name,
                        "column_label": data?.column_label,
                        "method": data?.method,
                        "actions":  "",
                        "data_imputation_method": data?.data_imputation_method,
                        "text_input": data?.text_input,
                        "multiplier": data?.inputValues?.multiplier,
                        "lower_value": data?.inputValues?.lower,
                        "higher_value": data?.inputValues?.upper
                    }
                })
            }
            setIsColumnDisable(false)
            setShowFields(false)

            dispatch(getSaveOutlier(requestData))
                .then((response) => {
                    if (response.type === `${getSaveOutlier.fulfilled}`) {
                        const requestData = {
                            workflow_id: workflow_id,
                            ecl_node_id: ecl_node_id,
                            category_id: category_id
                        }
                        const requestFormData = getCalculationFormData({
                            client_branch_level_id: location?.state?.levelTwoCardId,
                            isFrom: "EA",
                            nodeStatus: "Pending",
                            category_id: category_id,
                            node_id: ecl_node_id,
                            state: reduxState
                        })
                        dispatch(getOutlierdata({ requestFormData, requestData }))
                        afterMethodSave(undefined)
                    } else if (response.type === `${getSaveOutlier.rejected}`) {
                    }
                })
                .catch((error) => {
                    console.log("API not working", error);
                });
        } else {
            toast.error('Category id must')
        }
    };

    return (
        <>

        <div className="w-full">
            {!showEdit && 
            <div className='space-y-4 my-6' id="methodView">
                {
                filteredData?.map((data,i)=>{
                    return <SelectMethod 
                        key={i}
                        hideSave={true}
                        setAllData={setAllData}
                        columnName={data?.column_name} 
                        methodId ={parseInt(data?.method)} 
                        selectedMethodData={{
                        lower: data?.lower_value,
                        upper: data?.higher_value,
                        method: data?.method_label,
                        multiplier : data?.multiplier,
                        iqr:data?.iqr,
                        ...(data?.method == 5 && {
                        value:selectedMethodData?.value})
                    }}
                    method ={data?.method_label}
                    workflow_id ={workflow_id}
                    ecl_node_id ={ecl_node_id}
                    category_id  ={category_id}
                    text_input={data?.text_input}
                        />

                })
                }
            </div>
                } 
                {selectedMethod ?
                    <div className='mb-4'>
                        <SelectMethod
                            setAllData={setAllData}
                            hideSave={(isAlreadySavedOnce && !showColumnEdit) || !showColumnEdit}
                            columnName={selectedColumnName}
                            methodId={selectedMethodId}
                            selectedMethodData={selectedMethodData ?? {}}
                            // saveMethod={saveMethod} 
                            method={selectedMethod}
                            afterMethodSave={(e) => setSelectedMethod(e)}
                            workflow_id={workflow_id}
                            ecl_node_id={ecl_node_id}
                            category_id={category_id}
                            isFrom={!showColumnEdit ? "edit": "save"}
                        />
                        </div>
                    : null}

                {!showEdit && <div className='flex justify-center'>
                    <div className='w-[10%] my-4'>
                        <Button
                            onClick={handleEditSave}
                            bgColor="#42B2AC"
                            borderType="normal"
                            label={"Save"}
                            color={"#ffffff"}
                            size={'sm'}
                            menu={"savemethod"}
                            type={'submit'}
                            disabled={disableSave}
                        />
                    </div>
                </div>}
                                   
          <div className='2xl:py-2 py-1.5 px-3 bg-[#2B144C] flex justify-between items-center'>
            <div className={`flex gap-3 items-center py-0.5 ${tableData?.dataImputationMethodIdresults?.length > 0 ?'block':'hidden'}`}>
                <button className='text-[#FFFFFF] bg-[#F7BE7E] py-0.5 px-2 text-[12px] rounded whitespace-nowrap'>Data category</button>
                <p className='font-semibold text-[12px] text-white whitespace-nowrap'>{tableData?.categoryName}</p>
            </div>
            <h2 className='text-white text-[12px] font-semibold text-center w-full'>Calculated output</h2>
                    <div className={`float-right flex gap-1 items-center mx-4 ${editVisible && currentNodeData?.node_status != 'Completed' ? 'block' : 'hidden'} ${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={() => { setEditVisible(false); setShowColumnEdit(true); setShowFields(true) }}>
              <img alt='' src={editiocn} className='w-4 h-4' />
              <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
            </div>
          </div>
        
        
            <div className="w-full overflow-x-auto thin-scrollbar">
                <table {...getTableProps()} className='w-full'>
                    <thead className="bg-[#A06DFF] text-[#FFFFFF] text-[14px] whitespace-nowrap">
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()} className="px-4 2xl:py-2 py-1.5 font-semibold text-left text-[12px] md:text-[10px] xl:text-[12px]" key={column.id}>
                                        <OverflowTooltip text={column.render('Header')} />
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()} className='text-[#000000] text-[12px] md:text-[10px] xl:text-[12px]'>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()} key={row.id}>
                                    {row.cells.map((cell, i) => {
                                        if (cell.column.id === 'column_label') {
                                            const value = cell.value;
                                            const rowSpan = mergedCells[value] || 1;
                                            if (rowSpan > 1 && rows[row.index - 1] && rows[row.index - 1].values.column_label === value) {
                                                return null;
                                            }
                                            return (
                                                <td
                                                    {...cell.getCellProps()}
                                                    rowSpan={rowSpan}
                                                    className="border-b border-[#d3d3d3] whitespace-nowrap"
                                                >
                                                    <div className='flex items-center pl-4 pr-6 gap-1'>
                                                        <span>{cell.render('Cell')}</span>
                                                        {showColumnEdit && (
                                                            <img src={blockeditiocn} className='2xl:w-4 2xl:h-4 w-3 h-3 cursor-pointer' alt="Edit" onClick={() => handleEditClick(row.original.column_label)}/>
                                                        )}
                                                    </div>
                                                </td>
                                            );
                                        }
                                        return (
                                            <td {...cell.getCellProps()} className="border-b border-[#d3d3d3] px-4 py-2 whitespace-nowrap" key={i}>
                                                {cell.render('Cell')}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
            <div className={`${(editVisible || !showEdit) ? 'hidden' : 'block'} flex items-center justify-center mt-4`}>
            <Button
                onClick={handleSave}
                bgColor="#42B2AC"
                borderType="normal"
                label={"Save"}
                color={"#ffffff"}
                size={'sm'}
                menu={"savemethod"}
                type={'submit'}
                disabled={isButtonDisabled}
                // disabled={!outlierDatas?.some(item => item?.actions === "selected") && outlierDatas?.every(item => item?.data_imputation_method !=null)}
            />
        </div>

        </>
    );
};

export default MethodTable;
