import {   
    COUNTRY_LIST, COUNTRY_LIST_FETCH_SUCCESS, CURRENCY_LIST, CURRENCY_LIST_FETCH_SUCCESS,    
} from './ActionType'

const initalState = {    
    isLoading: true,
    countries:[]    
}



const Common = (state = initalState, action) => {    
    switch (action.type) {
        case COUNTRY_LIST:
            state = {
            ...state,
            isLoading: false,
            };
            break;  
            case COUNTRY_LIST_FETCH_SUCCESS:   
            state = {
            ...state,
            isLoading: false,
            countries:action.payload.countryList
            }; 
            case CURRENCY_LIST:
            state = {
            ...state,
            isLoading: false,
            };
            break;  
            case CURRENCY_LIST_FETCH_SUCCESS
            :   
            state = {
            ...state,
            isLoading: false,
            currencies:action.payload.currencyList
            }; 
        default:
            state = { ...state };
            break;
        }
    return state;
};

export default Common;