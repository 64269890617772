import React from 'react'
import Button from '../Button'
import { CircularProgress } from '@mui/material'

function ProceedButton({ isLoading, onClick }) {
    return (
        <div className='relative'>
            <Button
                className={'bg-[#70AD47] text-white !px-[2rem] 2xl:px-[2rem] py-1.5 rounded-[5px]'}
                label={'Proceed'}
                disabled={isLoading}
                onClick={onClick}
            />
            {isLoading && (
                <CircularProgress
                    size={20}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
            )}
        </div>
    )
}

export default ProceedButton