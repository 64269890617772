import React from "react";
import closeIcon from '../../Images/popup-close.svg';


function CommonPopup({
    onCancel,
    content,
    title,
}) {

    return <div
        className="relative z-[101]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
    >
        <div className="h-screen w-screen fixed inset-0 bg-black bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-[101]">
            <div className="flex min-h-full justify-center items-center">
                <div className={`relative`}>
                    <img alt="" src={closeIcon} className="absolute right-[-0.8rem] top-[-1rem] w-[1.7rem] cursor-pointer" onClick={onCancel} />
                    <div className={`bg-white rounded-lg  space-y-5 pb-3`}>
                        {/* header */}
                        <div className="bg-[#4F2D7F] rounded-t-lg">
                            <p className="text-[14px] text-white text-center leading-[30px]">
                                {title}
                            </p>
                        </div>
                        {/* content */}
                        <div className="overflow-y-auto thin-scrollbar">
                            <div className="space-y-2 h-full px-2">
                                {content}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default CommonPopup;
