import React, { useState } from 'react';
import SelectInput, { SelectInputOutlierDropdown, SelectInputOutlierMethodDropdown } from '../SelectInput';
import SelectMethod from './SelectMethod';
// import DataTable from '../Table/DataTable';
import DynamicTable from '../Table/DynamicTable';
import MethodTable from './MethodTable';
import Datatable from './MismatchDatatable';
// import Datatable from './Datatable';
import Exceptiontable from './Exceptiontable';
import Transactiontable from './Transactiontable';
import { BASED_DATAS } from '../../Helpers/EndPoints';
import { Baseurl } from '../../Utils/Constants';

const options = [
  { value: 'Age', label: 'Age' },
  { value: 'Brand', label: 'Brand' },
  { value: 'Customerid', label: 'Customer ID' },
  // Add more options as needed
];

const methods = [
  { value: "IQR", label: "IQR" },
  { value: "Z Scores", label: "Z Scores" },
  { value: "User define range", label: "User define range" },
  { value: "User defined percentile", label: "User defined percentile" }
];

const Method = ({columnDropdown, methodDropdown, methodDropdownList, setSelectedColumnDropdown, methodDropdownListAPI, selectedMethodData, saveMethod,removeDuplicateRowsList,workflow_id,ecl_node_id,category_id}) => {
  const [selectedColumnName, setSelectedColumnName] = useState('');
  const [selectedMethod, setSelectedMethod] = useState('');
  const [selectedMethodId, setSelectedMethodId] = useState();

  console.log(removeDuplicateRowsList,workflow_id,ecl_node_id,category_id, "removeDuplicateRowsList");

 

  return (
    <div className='overflow-auto h-[95%]'>
      <div className="relative w-full flex gap-6 py-4 px-4">
        <SelectInputOutlierDropdown
          options={columnDropdown}
          placeholder={"Select column name"}
          selectedMethod={selectedMethod}
          onChange={(e)=>{
            const [columnName, dataType] = e.split('-');
            methodDropdownList(dataType)
            setSelectedColumnName(columnName)
        }}
        />
        <SelectInputOutlierMethodDropdown
          options={methodDropdown}
          placeholder={"Select method"}
          selectedMethod={selectedMethod}
          onChange={(e)=>{
            const [columnName, id] = e.split('-');
            setSelectedMethodId(id);
            methodDropdownListAPI(selectedColumnName, id)
            setSelectedMethod(columnName)
        }}
        />
      </div>
      {(selectedMethod) && (
        <SelectMethod columnName={selectedColumnName} methodId={selectedMethodId} selectedMethodData={selectedMethodData} saveMethod={saveMethod} method={selectedMethod} 
        workflow_id={workflow_id} ecl_node_id={ecl_node_id} category_id={category_id}/>
      )}
      {removeDuplicateRowsList && (
      <MethodTable removeDuplicateRowsList={removeDuplicateRowsList}/>
      )}

      {/* <Datatable /> */}

      {/* <Exceptiontable/> */}
      {/* <Transactiontable/> */}
    </div>
  );
};

export default Method;
