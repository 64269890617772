// dependencies
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import React,{useEffect,useState,Suspense,lazy} from 'react'

// components
import RouteSegments from "./Routes/RouteSegments";
import AppLayout from "./Components/LayOuts/AppLayout"

// pages
import Home from "./Pages/Home/Home";
import ClientPage from './Pages/Client/Clientpage';
import NewClient from './Pages/Client/NewClient'
// import TableComponent from './Components/Table/TableComponent';
import ClientsCountry from './Pages/Client/ClientsCountry';
import Calculation from './Pages/Client/Calculation';
import LevelOne from './Pages/Client/LevelOne';
import LevelTwo from './Pages/Client/LevelTwo';
import ManageLevel from './Pages/Client/ManageLevel';
import WizardLanding from './Pages/Client/WizardLandingPage';
import NewCalculation from './Pages/Client/NewCalcuation';
import { loginLocalStorageKeys } from './Utils/Constants';
import ManageCategory from './Pages/Client/ManageCategory';
import ManageNullValues from './Pages/Client/ManageNullValues';
import WorkflowPage from './Pages/Workflow/WorkflowPage';
import Method from './Components/Methods/Method';

function PrivateRoute({ element }) {
  // Check the user's authentication status here
  const isAuthenticated = localStorage.getItem(loginLocalStorageKeys.token)

  // If not authenticated, redirect to the home page
  if (!isAuthenticated) {
    // You can replace this with your home page route
    return <Navigate to={RouteSegments.HOME} replace />;
  }

  // If authenticated, render the provided element
  return element;
}

// const Home = lazy(() => import('./Pages/Home/Home'));
// const ClientPage = lazy(() => import('./Pages/Client/Clientpage'));
// const NewClient = lazy(() => import('./Pages/Client/NewClient'));
// const ClientsCountry = lazy(() => import('./Pages/Client/ClientsCountry'));
// const Calculation = lazy(() => import('./Pages/Client/Calculation'));
// const LevelOne = lazy(() => import('./Pages/Client/LevelOne'));
// const LevelTwo = lazy(() => import('./Pages/Client/LevelTwo'));
// const ManageLevel = lazy(() => import('./Pages/Client/ManageLevel'));
// const WizardLanding = lazy(() => import('./Pages/Client/WizardLandingPage'));
// const NewCalculation = lazy(() => import('./Pages/Client/NewCalcuation'));


function App() {
  const [dynamicHeight, setDynamicHeight] = useState('96'); // Initial height, adjust as needed


  useEffect(() => {
    const calculateDynamicHeight = () => {
      // Calculate the dynamic height based on the available space
      const windowHeight = window.innerHeight;
      const headerHeight = 90
      const footerHeight = 30
      const calculatedHeight = windowHeight - headerHeight - footerHeight;

      // Set the dynamic height
      setDynamicHeight(`${calculatedHeight}`);
    };

    // Attach the event listener to recalculate height on window resize
    window.addEventListener('resize', calculateDynamicHeight);

    // Initial calculation
    calculateDynamicHeight();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', calculateDynamicHeight);
    };
  }, []); // Empty dependency array ensures the effect runs only once on mount
  return (
    <Router>
      {/* <Suspense fallback={null}> */}
        <Routes>
          <Route path={RouteSegments.HOME} element={<Home isFrom="login"/>} />
          <Route path={RouteSegments.REGISTER} element={<Home isFrom="register"/>} />
          <Route path={RouteSegments.FORGOTPASSWORD} element={<Home isFrom="forgotPassword"/>} />
          <Route path={RouteSegments.RESETPASSWORD} element={<Home isFrom="resetPassword"/>} />
          <Route path="/" element={<AppLayout />}>
            <Route path={RouteSegments.CLIENTPAGE} element={<PrivateRoute element={<ClientPage dynamicHeight={dynamicHeight}/>} />} />
            <Route path={RouteSegments.NEW_CLIENT} element={<PrivateRoute element={<NewClient dynamicHeight={dynamicHeight}/>} />}/> 
            <Route path={RouteSegments.CLIENT_COUNTRIES} element={<PrivateRoute element={<ClientsCountry dynamicHeight={dynamicHeight}/>} />} /> 
            <Route path={RouteSegments.CALCULATION} element={<PrivateRoute element={<Calculation dynamicHeight={dynamicHeight}/>} />} />
            {/* level 1 */}
            <Route path={RouteSegments.LEVEL_ONE} element={<PrivateRoute element={<LevelOne dynamicHeight={dynamicHeight}/>} />} />
            {/* level 2 */}
            <Route path={RouteSegments.LEVEL_TWO} element={<PrivateRoute element={<LevelTwo dynamicHeight={dynamicHeight}/>} />} />
            {/* Manage category */}
            <Route path={RouteSegments.MANAGE_CATEGORY} element={<PrivateRoute element={<ManageCategory />} />} />
             {/* Manage null values */}
             <Route path={RouteSegments.MANAGE_NULL_VALUES} element={<PrivateRoute element={<ManageNullValues />} />} />
           {/* Manage levels */}
             <Route path={RouteSegments.MANAGELEVEL} element={<PrivateRoute element={<ManageLevel />} />} />
            {/* Landing page of wizard */}
            <Route path={RouteSegments.WIZARD_LANDING} element={<PrivateRoute element={<WizardLanding dynamicHeight={dynamicHeight}/>} />} />
            {/* New calculation initiation */}
            <Route path={RouteSegments.NEW_CALCULATION} element={<PrivateRoute element={<WorkflowPage />} />} />
            <Route path={RouteSegments.METHOD} element={<PrivateRoute element={<Method/>} />} />

            {/* <Route path={RouteSegments.TABLE} element={<TableComponent  />} />     */}
            {/* <Route path={RouteSegments.CUSTOM_TABLE} element={<MaterialReactTable />} />     */}
          </Route>       
        </Routes>
      {/* </Suspense> */}
    </Router>
  );
}

export default App;
