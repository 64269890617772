import React from 'react'

function ReadOnlyValueDisplay({ children }) {
    return (
        <div className={`w-full flex items-center text-white text-[14px] bg-[#8FABEE] rounded h-full focus:outline-none pl-4 custom-select-height`}>
            {children}
        </div>
    )
}

export default ReadOnlyValueDisplay