import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React from 'react'
import OverflowTooltip from '../ToolTip/OverFlowToolTip';

function SummaryTable({
    columns,
    data,
    thClassName = "",
    tdClassName = "",
    className = "",
    theadClassName = "",
}) {
    console.log(data, "datadatadata");
    const table = useReactTable({
        columns,
        data,
        getCoreRowModel: getCoreRowModel(),
    })

    return (
        <>
        <table className={`  h-full ${className}`} 
        style={{width:"-webkit-fill-available"}}>
            <thead className={`${theadClassName ? theadClassName : 'sticky top-0 !z-0'}`}>
                {table.getHeaderGroups().map(headerGroup => (
                    <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th key={header.id} className={`bg-[#A06DFF] text-white  text-[12px] 2xl:text-[14px] font-semibold p-2 ${thClassName} ${header.column.columnDef.thClassName} `}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
            </thead>
            {/* <tbody className=''>
                {table.getRowModel().rows.map(row => (
                    <tr key={row.id} className='border-b border-[#E0DCD7]'>
                        {row.getVisibleCells().map(cell => {
                            console.log(cell,"cellcell");
                            if (cell.column.columnDef.mergeCells === true) {
                                const value = cell.getValue();
                                const rowSpan = table.getRowModel().rows.reduce((count, row) => {
                                    return row.original[cell.column.id] === value ? count + 1 : count;
                                }, 0);

                                    // Only render cell if it is the first occurrence
                                    if (row.index === table.getRowModel().rows.findIndex(r => r.original[cell.column.id] === value)) {
                                        return (
                                            <td rowSpan={rowSpan} key={cell.id}
                                                className={`p-2 text-[12px] 2xl:text-[14px] ${tdClassName} ${cell.column.columnDef.tdClassName} `
                                                }>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        );
                                    } else {
                                        return null; // Do not render cell if it's a duplicate
                                    }
                                }
                                return <td key={cell.id} className={`p-2 text-[12px] 2xl:text-[14px]
                            ${tdClassName} ${cell.column.columnDef.tdClassName} `
                            }>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </td>
                        })}
                    </tr>
                ))}
            </tbody> */}
            <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id} className='border-b border-[#E0DCD7]'>
                            {row.getVisibleCells().map(cell => {
                                const mergeCells = cell.column.columnDef.mergeCells;
                                const anotherColumn = cell.column.columnDef.anotherColumn;

                                if (mergeCells) {
                                    const value = cell.getValue();
                                    const rowSpan = table.getRowModel().rows.reduce((count, currentRow) => {
                                        const matchCondition = anotherColumn
                                            ? currentRow.original[cell.column.id] === value && currentRow.original[anotherColumn] === row.original[anotherColumn]
                                            : currentRow.original[cell.column.id] === value;
                                        return matchCondition ? count + 1 : count;
                                    }, 0);

                                    const firstOccurrence = table.getRowModel().rows.findIndex(currentRow => {
                                        const matchCondition = anotherColumn
                                            ? currentRow.original[cell.column.id] === value && currentRow.original[anotherColumn] === row.original[anotherColumn]
                                            : currentRow.original[cell.column.id] === value;
                                        return matchCondition;
                                    });

                                    if (row.index === firstOccurrence) {
                                        return (
                                            <td rowSpan={rowSpan} key={cell.id}
                                                className={`p-2 text-[12px] 2xl:text-[14px] ${tdClassName} ${cell.column.columnDef.tdClassName} `
                                                } style={{verticalAlign: 'middle'}}>
                                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                            </td>
                                        );
                                    } else {
                                        return null; // Do not render cell if it's a duplicate
                                    }
                                }
                                return <td key={cell.id} className={`p-2 text-[12px] 2xl:text-[14px]
                            ${tdClassName} ${cell.column.columnDef.tdClassName} `
                                }>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>;
                            })}
                        </tr>
                    ))}
                </tbody>
            <tfoot>
                {table.getFooterGroups().map(footerGroup => (
                    <tr key={footerGroup.id}>
                        {footerGroup.headers.map(header => (
                            <th key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.footer,
                                        header.getContext()
                                    )}
                            </th>
                        ))}
                    </tr>
                ))}
            </tfoot>
        </table>
            {data && data?.length == 0
                ? <div className='flex justify-center text-[12px] 2xl:text-[13px] py-4'>No data found</div>
                : null}
        </>

    )
}

export default SummaryTable