import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFileSize } from '../../Utils/CommonFunctions';
import XlsInActiveIcon from '../../Images/xls-gray.svg';
import XlsSelected from '../../Images/xls-red.svg';
import SelectedTickIcon from '../../Images/tick-white.svg'
import { localStorageKeys } from '../../Utils/Constants';
import DataTableSkeleton from '../Skeleton/DataTableSkeleton';
import { setSelectedDeleteFiles} from '../../Store/Workflow/WorkflowSlice';

const SubSheets = ({ sheets, rowIndex, handleCheckboxChange,nodeId,ecl_node_id ,isDisable}) => (
  <div className='space-y-2'>
    {sheets.map((sheet, sheetIndex) => (     
      <React.Fragment key={sheetIndex}>
        <div key={sheetIndex} className={`flex gap-2 w-fit px-2 py-1 rounded ${isDisable ? 'pointer-events-none':'cursor-pointer'} items-center`} onClick={() => handleCheckboxChange(sheet?.sheet_name,sheet?.sheet_url,nodeId,rowIndex,ecl_node_id)}>        
            <React.Fragment>
              <input
                type="checkbox"
                id="file"
                checked={sheet.checked}
                onChange={() => handleCheckboxChange(sheet?.sheet_name,sheet.sheet_url,nodeId,rowIndex,ecl_node_id)}
                className='2xl:w-4 2xl:h-4 w-3 h-3 rounded-[3px] border border-[#D2D2D2] bg-white cursor-pointer'
              />
              {/* <div className='w-4 h-4 rounded-[3px] border border-[#D2D2D2] bg-white'></div> */}
            </React.Fragment>                    
            <img src={XlsInActiveIcon} className='2xl:w-4 w-3' />
          <span className={`text-[#3B3B3B]`}>{sheet.sheet_name}</span>   
        </div>
      </React.Fragment>
    ))}
  </div>
);

const DeletePopUpContent = ({headers, headerclassName, workflow_node_id,setCalculationMultipleReq,setIsdeletableFileDetails,isDisable}) => {
  const [nodeDetails,setNodeDetails] = useState({});
  const [fileDetailsArray, setFileDetailsArray] = useState({});
  const [fileDetails, setFileDetails] = useState([])
  const [selectAll, setSelectAll] = useState(false); 
  const [currentUrl,setCurrentUrl] = useState('');
  const [currentNodeId, setCurrentNodeId] = useState(null);
  const [currentRowIndex, setCurrentRowIndex] = useState(null)
  const [eclNodeId, setEclNodeId] = useState(workflow_node_id)
  const dispatch = useDispatch()
  
  const { fileDetilsResponse } = useSelector(state => {
    return {
      fileDetilsResponse: state?.Workflow?.deletableFileDetails,
    } 
  });
  useEffect(()=>{
  if(fileDetilsResponse?.data){
    setFileDetails(fileDetilsResponse?.data)
  }
  if(fileDetilsResponse?.nodeValueArray
    ){
    setNodeDetails(fileDetilsResponse?.nodeValueArray)
  }
  if(fileDetilsResponse?.fileDetailsArray){
    setFileDetailsArray(fileDetilsResponse?.fileDetailsArray)
  }
  },[fileDetilsResponse])  

  const handleCheckboxChange = (sheetName, url, nodeId, rowIndex, ecl_node_id) => {
    setCurrentUrl(url)
    setCurrentNodeId(nodeId)
    setCurrentRowIndex(rowIndex)
    setEclNodeId(ecl_node_id)
    // Update fileDetails array
    const updatedFileDetails = fileDetails.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...row,
          sub_sheets: row.sub_sheets.map(sheet => {
            if (sheet.sheet_name === sheetName && sheet.sheet_url === url) {
              return {
                ...sheet,
                checked: !sheet.checked
              };
            }
            return sheet;
          })
        };
      }
      return row;
    });
    setFileDetails(updatedFileDetails);
    dispatch(setSelectedDeleteFiles(updatedFileDetails))
    setIsdeletableFileDetails(updatedFileDetails)
               
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    const updatedFileDetails = fileDetails.map(row => ({
      ...row,
      sub_sheets: row.sub_sheets.map(sheet => ({
        ...sheet,
        checked: !selectAll
      }))
    }));
    setFileDetails(updatedFileDetails);
    dispatch(setSelectedDeleteFiles(updatedFileDetails))
    setIsdeletableFileDetails(updatedFileDetails)

  };

  useEffect(() => {
    // Create copies of fileDetailsArray and nodeValueArray
    const updatedFileDetailsArray = { ...fileDetailsArray };
    const updatedNodeValueArray = { ...nodeDetails };

    // Perform operations on updatedFileDetailsArray and updatedNodeValueArray
    // Remove matching entries from updatedFileDetailsArray
    for (const key in updatedFileDetailsArray) {
      if (updatedFileDetailsArray.hasOwnProperty(key)) {
        updatedFileDetailsArray[key] = updatedFileDetailsArray[key].map(file => ({
          ...file,
          sub_sheets: file.sub_sheets.filter(subSheet => subSheet.sheet_url !== currentUrl)
        }));
      }
    }
  
    // Remove matching entries from updatedNodeValueArray
    for (const key in updatedNodeValueArray) {
      if (updatedNodeValueArray.hasOwnProperty(key)) {
        updatedNodeValueArray[key] = updatedNodeValueArray[key].filter(item => item.file_path !== currentUrl);
      }
    }

    // Update state with modified arrays
    setFileDetailsArray(updatedFileDetailsArray);
    setNodeDetails(updatedNodeValueArray);

    const allChecked = fileDetails.every(row => row?.sub_sheets.every(sheet => sheet.checked));
    setSelectAll(allChecked);

  // Clear subSheets array in fileDetailsArray if all checkboxes are checked
  if (allChecked) {
    const updatedFileDetailsArrayWithEmptySubSheets = { ...fileDetailsArray };
    for (const key in updatedFileDetailsArrayWithEmptySubSheets) {
      if (updatedFileDetailsArrayWithEmptySubSheets.hasOwnProperty(key)) {
        updatedFileDetailsArrayWithEmptySubSheets[key] = updatedFileDetailsArrayWithEmptySubSheets[key].map(file => ({
          ...file,
          sub_sheets: []
        }));
      }
    }
    
    setFileDetailsArray(updatedFileDetailsArrayWithEmptySubSheets);
    setNodeDetails({});
  }
     
  }, [fileDetails,currentUrl]);

  console.log(fileDetails,'fileDetails');


  useEffect(()=>{
         // Create form data for response
         const formData = new FormData();
         formData.append(`ecl_workflow_node_id[${currentRowIndex}]`, currentNodeId);
         formData.append(`node_id[${currentRowIndex}]`, eclNodeId);
         formData.append('is_proceed', "Completed"); 
         formData.append(`node_value[${currentNodeId}]`, Object.keys(nodeDetails).length > 0 ? JSON.stringify(nodeDetails[currentNodeId]):[]);
         formData.append(`file_details[${currentNodeId}]`,Object.keys(fileDetailsArray).length > 0 ? JSON.stringify(fileDetailsArray[currentNodeId]):[]);
         setCalculationMultipleReq(formData);

  },[nodeDetails,fileDetailsArray])
  
  return (

    <div className='max-h-[26rem] overflow-y-auto thin-scrollbar w-full px-2'>
      <div className="flex gap-6 items-center mb-4">
          <p className='text-[#A8A8A8] 2xl:text-[13px] text-[12px] font-semibold pl-2'>Data category</p>
          <p className='text-[#4339A8] 2xl:text-[13px] text-[12px] font-semibold'>{fileDetails[0]?.category_name}</p>
      </div>
      <table className={`w-full`}>
        <thead className={`${headerclassName} sticky top-0 w-full 2xl:text-[13px] text-[12px]`}>
          <tr className='w-full'>
            {headers.map((header, i) => (
              <th key={i} className={`whitespace-nowrap py-2 ${i === 0 ? 'px-2' : 'px-4'}`}>
                <div className='flex items-center gap-1 2xl:text-[13px] text-[12px]'>
                {header?.accessor == 'sub_sheets' && 
                <input type="checkbox" className={`2xl:w-4 2xl:h-4 w-3 h-3 bg-white ${isDisable ? 'pointer-events-none' : 'cursor-pointer'}`} onChange={handleSelectAllChange} checked={selectAll}/>
                }
                <span className='2xl:text-[13px] text-[12px]'>{header.Header}</span>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='bg-white custom-data-table text-[#3B3B3B] text-[12px] divide-y divide-[#E1E1E1]'>
          {fileDetails?.length > 0 ?
          fileDetails?.map((row, rowIndex) => { 
            const subSheetsValue = <SubSheets nodeId={row?.workflow_node_id} ecl_node_id={row?.ecl_node_id} sheets={row?.sub_sheets} rowIndex={rowIndex} handleCheckboxChange={handleCheckboxChange} isDisable={isDisable}/>;
            const getSheetSize = <div className='space-y-4'>{row?.sub_sheets?.map((sheet, i)=>{return (<p>{getFileSize(sheet?.sheet_size)}</p>)})} </div>
            const getSheetRows = <div className='space-y-4'>{row?.sub_sheets?.map((sheet, i)=>{return (<p>{`${sheet?.row_count} rows`}</p>)})} </div>

            return (
              <tr key={rowIndex} className=''>
                {headers.map((header, cellIndex) => {
                  const key = header.accessor;
                  const value = key === 'file_size' ? getSheetSize : key === 'sub_sheets' ? subSheetsValue : key==='row_count' ? getSheetRows : row[key];
                  return (
                    <td key={cellIndex} className={`py-4 ${cellIndex === 0 || header.accessor =='sub_sheets'? 'px-2' : 'px-4'} whitespace-nowrap text-[#000000] 2xl:text-[13px] text-[11px]`}>
                      {value}
                    </td>
                  );
                })}
              </tr>
            );
          })
          : 
          <td className='w-full' colSpan={headers?.length}>
          <DataTableSkeleton showHeader={false}  columnWidths={''} rowCount={5} columnCount={7} showPagination={false} showActionableButtons={false} from='mergeSummary' isDownload={false} />
          </td>
          }

        </tbody>
      </table>
    </div>
  );
};

export default DeletePopUpContent;
