import React, { useEffect, useState } from 'react';
//Components
import Button from '../../Components/Button';
import SortDropDown from '../../Components/ClientPage/SortDropDown';
import LeftArrow from '../../Components/CommonIcons/LeftArrow';
import NoDataFound from '../../Components/ClientPage/NoDataFound';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteSegments from '../../Routes/RouteSegments';
import { useDispatch, useSelector } from 'react-redux';
import { clientsCountryList } from '../../Store/action';
import CardSkeleton from '../../Components/Skeleton/CardSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import PageCard from '../../Components/ClientPage/Pagecard'

import loadMore from '../../Images/loadmore.webp'
import { Constants } from '../../Utils/Constants';
import SearchInput from '../../Components/ClientPage/SearchInput';

let vari = [];

const ClientsCountry = ({ClientName}) => {

  const location = useLocation();
  const navigate= useNavigate()
  const [sortBy,setSortBy] = useState('created_at:desc');
  const [currentListCount,setCurrentListCount] = useState(6)
  const [countrySort,setCountrySort] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [selected,setSelected] = useState('Recently added');
  const [isNext, setIsNext] = useState(false) ;
  const [showLoader, setShowLoader] = useState(false);
  const [countriesList, setCountriesList] = useState([]);



  const newCalculation =(clientId)=>{
    // navigate(RouteSegments.NEW_CLIENT,{ state: { isFrom: 'manageCountry',step:3 ,clientID:clientId,isNewClient:true} })
  }
  const dispatch = useDispatch()

  // useEffect(()=>{
  //   if(location?.state?.clientId){
  //   dispatch(clientsCountryList(sortBy,Constants.listSize,searchTerm,location?.state?.clientId)) //sort,record,key,id
  // }
  // },[location?.state?.clientId])

  const { ClientsCountryList, isLoading,hasMore} = useSelector(state => {
    return {
        ClientsCountryList: state?.Clients?.clientsCountryList,
        isLoading: state?.Clients?.isLoading,
        hasMore:state?.Clients?.hasMore
    }
})
// console.log(ClientsCountryList,'ClientsCountryList');

const onNext = () => {        
  // Increase currentListCount by 6
  setIsNext(true)
  let newCount = currentListCount + 6;
  setCurrentListCount(newCount);

  // Dispatch action with the updated count
  dispatch(clientsCountryList(sortBy,newCount,searchTerm,location?.state?.clientId,countrySort));        
};

// useEffect(()=>{        
//   if(searchTerm?.length > 0){
//       if(searchTerm?.length ==1){
//           setCurrentListCount(Constants.listSize) 
//           dispatch(clientsCountryList(sortBy,Constants.listSize,searchTerm,location?.state?.clientId,countrySort));           
//       }
//       else{
//           dispatch(clientsCountryList(sortBy,currentListCount,searchTerm,location?.state?.clientId,countrySort));
//       }
//   } 
//   else if(searchTerm?.length ==0){
//       if(countrySort){
//           dispatch(clientsCountryList(null,currentListCount,searchTerm,location?.state?.clientId,'desc'))  
//       }
//       else{
//         dispatch(clientsCountryList(sortBy,Constants.listSize,searchTerm,location?.state?.clientId,null));           
//       }
//   }
// },[searchTerm])

// useEffect(()=>{    
//   if(isNext){
//       ClientsCountryList?.map((client,i)=>{
//           vari.push(client)
//           setCountriesList(vari)
//       })
//       setIsNext(false)
//   }         
//   else{
//       vari = []
//       ClientsCountryList?.map((client,i)=>{
//           vari.push(client)
//           setCountriesList(vari)
//       })
//   }               
  
// },[ClientsCountryList]) 

  return (
    <>
    <div className='w-full bg-white'>
    <div className='flex justify-between py-3 xl:px-[4rem] px-[3rem] sticky bg-white z-10'>
        <div className='flex gap-2 items-center'>
          <div className='flex gap-2 items-center cursor-pointer' onClick={()=>navigate(RouteSegments.CLIENTPAGE)} >
          <LeftArrow bgColor={'#d0cece'} height="25" width="25"/>
          <p className='text-gray-light text-[15px]'>Back</p>
          </div>
          <p className='text-dark-blue font-medium'>{location?.state?.name}</p>
        </div>
          <div className='flex gap-4 items-center'>
              <Button bgColor='#DCFFFD' borderType="dashed" label={'New Calcuation'} color={'#42B2AC'} onClick={()=>newCalculation(location?.state?.clientId)} icon={'plus'} />
              <div className='flex space-x-4 '>
                <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} icon={true} placeholder={'Search country'}/>
                <SortDropDown isFrom="countriesList" clientId={location?.state?.clientId} setSortBy={setSortBy} record={currentListCount} searchTerm={searchTerm} setSearchTerm={setSearchTerm} setCountrySort={setCountrySort} countrySort={countrySort} selected={selected} setSelected={setSelected} page={"dashboard"}/>
              </div>              
          </div>
     </div>
    {/* <>
        {isLoading ?
            <div className='w-full'>
                <CardSkeleton cards={3} menu={"clientList"} />
                <CardSkeleton cards={3} menu={"clientList"} marigin={'2rem'} />
            </div>
            :
            !isLoading && countriesList?.length > 0 
            ?
            ClientsCountryList?.length <=0 && searchTerm?.length > 0 
            ? <NoDataFound /> 
            :
            <div id="scroll-node">
            <InfiniteScroll
                dataLength={countriesList.length + Constants.listSize}    
                // next={onNext} 
                onScroll={()=>setShowLoader(true)}                  
                // hasMore={hasMore}
                loader={(hasMore && showLoader )&& <div className='w-full flex items-center justify-center'>
                    <img src={loadMore} alt="load more..." className='w-[20%] h-[5%]' />
                </div>}
                // endMessage={ClientsCountryList?.length < Constants.listSize || noMoreClient
                //     ? <div className='pb-[4rem] pt-6 w-full flex items-center justify-center'>
                //         <h1>No more Clients</h1>
                //     </div> : null}
            >
                {countriesList?.length > 0 &&
                <div className='grid grid-cols-3 gap-5 mb-[3.5rem] mt-2 mx-[4rem]'>
                    {
                        countriesList?.map(client => (
                        <PageCard 
                        key={client.id} 
                        data={client} 
                        title={client?.country_name} 
                        isPrimary={client?.is_primary} 
                        label={client?.level_one_name} 
                        count={client?.level_one_count} 
                        onClick={(name,id)=>navigate(RouteSegments.LEVEL_ONE ,{ 
                          state:{
                            client_country_id:id,
                            breadCrumbClientName:location?.state?.breadCrumbClientName,
                            breadCrumbCountryName:client?.country_name}})
                          }
                        />))
                    }
                </div>
                } 
            </InfiniteScroll>
            {hasMore &&
            <div className='w-full flex items-center justify-center mb-16'>
                <button className='bg-greeny-blue text-white px-4 py-2 rounded text-[14px]' onClick={onNext}>
                {isLoading ? 'Loading...':'Load More'} 
            </button>
            </div> }

            </div>
            :
            <NoDataFound /> 
        }
    </>    */}
    <NoDataFound /> 
    </div>
    </>
  )
}

export default ClientsCountry
