export const CLIENTS_LIST     = "CLIENT_LIST "
export const CLIENT_LIST_MORE = "CLIENT_LIST_MORE "
export const API_ERROR      = "CLIENTS_API_ERROR"

export const CLIENTS_LIST_FETCH_REQUESTED = "CLIENTS_LIST_FETCH_REQUESTED"
export const CLIENTS_LIST_FETCH_MORE_REQUESTED = "CLIENTS_LIST_FETCH_MORE_REQUESTED"

export const CLIENTS_LIST_FETCH_SUCCEEDED = "CLIENTS_LIST_FETCH_SUCCEEDED"
export const CLIENTS_LIST_FETCH_MORE_SUCCEEDED = "CLIENTS_LIST_FETCH_MORE_SUCCEEDED"

export const CLIENTS_LIST_FETCH_FAILED = "CLIENTS_LIST_FETCH_FAILED"

export const CLIENTS_DATA_CLEARED = "CLIENTS_DATA_CLEARED";
export const CLIENTS_COUNTRY_DATA_CLEARED = "CLIENTS_COUNTRY_DATA_CLEARED";
export const LEVELONE_DATA_CLEARED = "LEVELONE_DATA_CLEARED";
export const LEVELTWO_DATA_CLEARED = "LEVELTWO_DATA_CLEARED";

//Create 
export const CREATE_CLIENT          = "CREATE_CLIENT";
export const CLIENT_CREATE_SUCCESS  = "CLIENT_CREATE_SUCCESS";

//Update
//Create 
export const UPDATE_CLIENT          = "UPDATE_CLIENT ";
export const UPDATE_CLIENT_SUCCESS  = "UPDATE_CLIENT_SUCCESSS";

//client list sorting

export const CLIENTS_LIST_SORTING = "CLIENTS_LIST_SORTING";
export const CLIENTS_LIST_SORTING_SUCCESS = "CLIENTS_LIST_SORTING_SUCCESS";
export const CLIENTS_LIST_SORTING_FAILURE = "CLIENTS_LIST_SORTING_FAILURE";

//Client country list
export const CLIENT_COUNTRY_LIST ="CLIENT_COUNTRY_LIST";
export const CLIENT_COUNTRY_LIST_FETCH_SUCCEEDED = "CLIENT_COUNTRY_LIST_FETCH_SUCCEEDED"

//Client country  level one list
export const CLIENT_COUNTRY_LEVEL_ONE_LIST ="CLIENT_COUNTRY_LEVEL_ONE_LIST";
export const CLIENT_COUNTRY_LEVEL_ONE_LIST_FETCH_SUCCEEDED = "CLIENT_COUNTRY_LEVEL_ONE_LIST_FETCH_SUCCEEDED"

//Client country  level two list
export const CLIENT_COUNTRY_LEVEL_TWO_LIST ="CLIENT_COUNTRY_LEVEL_TWO_LIST";
export const CLIENT_COUNTRY_LEVEL_TWO_LIST_FETCH_SUCCEEDED = "CLIENT_COUNTRY_LEVEL_TWO_LIST_FETCH_SUCCEEDED"

//level name update
export const LEVEL_NAME_UPDATE = "LEVEL_NAME_UPDATE";
export const LEVEL_NAME_UPDATE_SUCCESS = "LEVEL_NAME_UPDATE_SUCCESS";

//category name update

export const CATEGORY_NAME_UPDATE = "CATEGORY_NAME_UPDATE";
export const CATEGORY_NAME_UPDATE_SUCCESS = "CATEGORY_NAME_UPDATE_SUCCESS";

//manage country
export const MANAGE_COUNTRY = "MANAGE_COUNTRY";
export const MANAGE_COUNTRY_SUCCESS = "MANAGE_COUNTRY_SUCCESS";

//manage level
export const MANAGE_LEVEL = "MANAGE_LEVEL";
export const MANAGE_LEVEL_SUCCESS = "MANAGE_LEVEL_SUCCESS";

//create country level
export const CREATE_COUNTRY_LEVEL = "CREATE_COUNTRY_LEVEL";
export const CREATE_COUNTRY_LEVEL_SUCCESS = "CREATE_COUNTRY_LEVEL_SUCCESS";






