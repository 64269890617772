export const Baseurl = 'https://ecl.port0.org/laravelapi/api';
// export const Baseurl = 'http://127.0.0.1:8000/api';
export const ImgBaseUrl = 'https://ecl.port0.org/laravelapi/';

export const loginLocalStorageKeys ={
    showLoader:'showLoader',
    isLoggedIn:'isLoggedIn',
    userName:'userName',
    loginId:'loginId',
    loginEmail:'loginEmail',
    token:'token'
}
export const localStorageKeys ={
    currentWorkFlowId:'currentWorkFlowId',
    levelTwocardId:'levelTwocardId',
    currentNodeId:'currentNodeId',
    uploadedFiles3Url:'uploadedFiles3Url',
    currentCalculationDate:'currentCalculationDate',
    nodeDetails:'nodeDetails',
    oldCategory:'oldCategory',
    enableDataQuality:'enableDataQuality',
    sameCategoryExist:'sameCategoryExist',
    modelBuilderList:'modelBuilderList',
    categoryList:'categoryList',
    lastNodeStatus:'lastNodeStatus',
    pipelineSkeletonLoading:'pipelineSkeletonLoading',
    downloading:"downloading",
    clientsList:'clientsList',
    hasMoreClients:'hasMoreClients',
    currentClientsCount:'currentClientsCount',
    clientSearchTerm:'clientSearchTerm',
    file_details:'file_details',
    selected_file_details:'selected_file_details',
    currentTemplateName:'currentTemplateName',
    currentTemplateId:'currentTemplateId',

}

export const Constants ={
    listSize: 6,
    clientListSize : window.innerWidth <= 1057 ? 6 : window.innerWidth >= 2312 ? 18 : 12
}

export const monthNames = [
    {id:1, value:'Jan'},
    {id:2, value:'Feb'},
    {id:3, value:'Mar'},
    {id:4, value:'Apr'},
    {id:5, value:'May'},
    {id:6, value:'Jun'},
    {id:7, value:'Jul'},
    {id:8, value:'Aug'},
    {id:9, value:'Sep'},
    {id:10, value:'Oct'},
    {id:11, value:'Nov'},
    {id:12, value:'Dec'},
]

export const NumericValues = [
  "BIGINT",
  "DOUBLE",
  "INTEGER",
  "BOOLEAN",
  "TINYINT",
  "SMALLINT",
  "FLOAT",
  "DECIMAL",
  "PRECISION",
]

export const columnNumberOperations = [
  {
    lable: "Between",
    operation: "between"
  },
  {
    lable: "Equals",
    operation: "equals"
  },
  {
    lable: "Dones not equal",
    operation: "not_equals"
  },
  {
    lable: "Less than",
    operation: "less_than"
  },
  {
    lable: "Less than or equal to",
    operation: "less_than_equals"
  },
  {
    lable: "Greater than",
    operation: "greater_than"
  },
  {
    lable: "Greater than or equal to",
    operation: "greater_than_equals"
  },
  {
    lable: "Not Blank",
    operation: "not_blank"
  },
  {
    lable: "Blank",
    operation: "blank"
  },

]

export const positionAnalysisTableHeader = [
    {
      header: 'Column name',
      accessor: 'column_name',
    meta: {
      filterType: "select"
    }
    },
    {
      header: 'Data type',
      accessor: 'data_type_label',
      meta: {
        filterType: "select"
      }
    },
    {
      header: 'Sum',
      accessor: 'sum_value',
      meta: {
        filterType: "range"
      }
    },
    {
      header: 'Average',
      accessor: 'avg_value',
      meta: {
        filterType: "range"
      }
    },  
    {
      header: 'Minimum',
      accessor: 'min_value',
      meta: {
        filterType: "range"
      }
    },
    {
      header: 'Maximum',
      accessor: 'max_value',
      meta: {
        filterType: "range"
      }
    },
    {
      header: 'Mode',
      accessor: 'mode_value',
      meta: {
        filterType: "select"
      }
    },
    {
      header: '25th percentile',
      accessor: '25th_percentile',
      meta: {
        filterType: "range"
      }
    },  
    {
      header: '50th percentile',
      accessor: '50th_percentile',
      meta: {
        filterType: "range"
      }
    },
    {
      header: '75th percentile',
      accessor: '75th_percentile',
      meta: {
        filterType: "range"
      }
    },
    {
      header: 'No. of unique values',
      accessor: 'unique_count',
      meta: {
        filterType: "range"
      }
    },
    {
      header: 'No. of Null values',
      accessor: 'null_count',
      meta: {
        filterType: "range"
      }
    },
    {
      header: 'Action',
      accessor: 'action',
    },
    
  
  ];

  export const deletePopupTableHeader = [
    
    {
      Header: 'File Name',
      accessor: 'file_name',
    },
    {
      Header: 'Uploaded Date',
      accessor: 'uploaded_date',
    },
    {
      Header: 'Uploaded Time',
      accessor: 'uploaded_time',
    },  
    {
      Header: 'File Size',
      accessor: 'file_size',
    },
    {
      Header: 'Select sheet(s) to be removed',
      accessor: 'sub_sheets',
    },
    {
      Header: 'No of rows',
      accessor: 'row_count',
    },
      
  ];

 export const modelBuilderSections = [
    {
      name:'Single file upload',
      id:1
    },
    {
      name:'Multi file upload',
      id:2
    },
    {
      name:'Data Connector',
      id:3
    },
    {
      name:'Position Analysis',
      id:4
    },
    {
      name:'Assign Data format',
      id:5
    },
    {
      name:'Rename Column',
      id:6
    },
    {
      name:'Remove Column',
      id:7
    },
    {
      name:'Create Duplicate column',
      id:8
    },
    {
      name:'Remove duplicate row',
      id:9
    },
    {
      name:'Identify and treat mismatched data types',
      id:10
    },
    {
      name:'Identification and treatment of NULL/blank values',
      id:11
    },
    {
      name:'Identification and treatment of unexpected values',
      id:12
    },
    {
      name:'Identification and treatment of outliers',
      id:13
    },
    {
      name:'Replace with average/minimum/maximum value based on another column',
      id:14
    },
    {
      name:'Replace with earliest value based on another column',
      id:15
    },
    {
      name:'Replace with latest value based on another column',
      id:16
    },
    {
      name:'Replace with a value specified by the user',
      id:17
    },
   {
      name:'Replace with most frequently occurring value based on another column',
      id:18
    },
    {
      name:'Replace with floors and caps',
      id:19
    },
    {
      name:'Replace based on mapped values to a different column',
      id:20
    },
    {
      name:'User defined equation',
      id:21
    },
    {
      name:'Split data',
      id:22
    },
    {
      name:'Z-Score standardization',
      id:23
    },
    {
      name:'Natural log transformation',
      id:24
    },
    {
      name:'Standardize using Min/Max Scaling',
      id:25
    },
    {
      name:'Calculate cumulative distribution function',
      id:26
    },
    {
      name:'Calculate probability density function value',
      id:27
    },
    {
      name:'Apply BOX COX transformation',
      id:28
    },
    {
      name:'Binning and bucketing based on equal ranges',
      id:29
    },
    {
      name:'Binning and bucketing based on percentiles',
      id:30
    },
    {
      name:'Binning and bucketing based on user defined mapping',
      id:31
    },
    {
      name:'Normalization using percentiles',
      id:32
    },
    {
      name:'Normalization using decimal scaling',
      id:33
    },
    {
      name:'Standardize using robust scaling',
      id:34
    },
    {
      name:'Add new column with custom calculation',
      id:35
    },
    {
      name:'Weight of evidence calculation',
      id:36
    }
]

export const methodDropdDown = [
  {
  label: "Minimum",
  value : "minimum",
  },
  {
    label: "Maximum",
    value : "maximum",
    },
    {
      label: "Average",
      value : "average",
      },
]
