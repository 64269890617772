import { Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
//image
import dropdownIcon from '../../Images/Polygon 1.svg'
import { useDispatch } from 'react-redux';
import { clientsCountryLevelOneList, clientsCountryLevelTwoList, clientsCountryList, clientsListSorting } from '../../Store/action';
import { Constants } from '../../Utils/Constants';
//compoent

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SortDropDown({setSortBy,record,searchTerm,setCurrentListCount, setSearchTerm,setCountrySort,countrySort,page,setSelected,selected,isFrom,clientId,data}) {
    // console.log(data,"datadatadata");
    const dispatch = useDispatch()

//     useEffect(()=>{
//         setSearchTerm('');
    
//   },[selected])


    const selectOption = (value, type) => {
        if(!type){
            setCountrySort('desc')
        }
        setSelected(value);
        setSortBy(type)
        setCurrentListCount(Constants?.listSize)
        if(isFrom=='countriesList'){
            if(type==null || !type){
                dispatch(clientsCountryList(type,Constants?.listSize,searchTerm,clientId,'desc'))
            }else{
                dispatch(clientsCountryList(type,Constants?.listSize,searchTerm,clientId,null))
            }
        }
        else if(isFrom=="levelOne"){
            if(type==null || !type){
                dispatch(clientsCountryLevelOneList(type,Constants?.listSize,searchTerm,clientId,'desc'))
            }else{
                dispatch(clientsCountryLevelOneList(type,Constants?.listSize,searchTerm,clientId,null))
            }
        }
        else if(isFrom=="levelTwo"){
            if(type==null){
                dispatch(clientsCountryLevelTwoList(type,Constants?.listSize,searchTerm,data,'desc'))
            }else{
                dispatch(clientsCountryLevelTwoList(type,Constants?.listSize,searchTerm,data,null))
            }
        }
        else{
        dispatch(clientsListSorting(type,Constants?.listSize,searchTerm,!type ? 'desc':'', Constants.clientListSize));}
    }

    return (
            <Menu as="div" className="relative inline-block text-left ">
                <div className='flex'>

                    <div className='flex justify-between w-[13.6rem]'>

                        <Menu.Button className={`inline-flex w-full rounded-[5px] relative items-center rounded-base border border-[#CAD3D0] bg-white px-5 pb-0.5 ${page=="dashboard" ? 'xl:h-[2.2rem] h-[1.7rem]':'xl:h-[2rem] h-[1.7rem]'} xl:text-[12px] xl:leading-[21px] text-[11px] leading-[15px] whitespace-nowrap text-[#707070] shadow-sm gap-2`}>
                        {({ open }) => (
                        <>
                            {selected}
                            <div
                                className={`absolute right-[1rem] transition-transform transform ${open ? 'rotate-180' : 'rotate-0'
                                    }`}
                            >
                                <img className="xl:h-[10px] xl:w-[10px] w-[9px] h-[9px]" src={dropdownIcon} alt="Dropdown Icon" />
                            </div>
                        </>
                        )}
                        </Menu.Button>

                    </div>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-10 bg-white  w-[13.5rem]  origin-top-right text-[#707070] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none rounded-b-lg">
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <div

                                        className={classNames(
                                            'text-[#707070] border-b border-[#CAD3D0] cursor-pointer',
                                            'block px-4 mt-1 xl:text-[13px] xl:leading-[21px] text-[11px] leading-[15px] py-[0.2rem]'
                                        )}
                                        onClick={(e) => selectOption('Recently added','created_at:desc')}

                                    >
                                        Recently added

                                    </div>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <div

                                        className={classNames(
                                            'text-[#707070] border-b border-[#CAD3D0] cursor-pointer',
                                            'block px-4 mt-1 xl:text-[13px] xl:leading-[21px] text-[11px] leading-[15px] py-[0.2rem]'
                                        )}
                                        onClick={(e) => selectOption('Recently edited','updated_at:desc')}

                                    >
                                        Recently edited
                                    </div>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <div

                                        className={classNames(
                                            'text-[#707070] border-b border-[#CAD3D0] cursor-pointer',
                                            'block px-4 mt-1 xl:text-[13px] xl:leading-[21px] text-[11px] leading-[15px] py-[0.2rem]'
                                        )}
                                        onClick={(e) => isFrom=='countriesList' ? selectOption('By country - A-Z','country_name:asc'): isFrom=="levelOne" || isFrom=="levelTwo" ? selectOption('By category name - A-Z','category_name:asc') :selectOption('By client name - A-Z','client_name:asc')}

                                    >
                                        {isFrom=='countriesList' ? 'By country - A-Z': isFrom=="levelOne" || isFrom=="levelTwo" ? 'By category name - A-Z': 'By client name - A-Z'}
                                    </div>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <div

                                        className={classNames(
                                            `text-[#707070] ${isFrom!="levelTwo" ? 'border-b border-[#CAD3D0]' :""} cursor-pointer`,
                                            'block px-4 mt-1 xl:text-[13px] xl:leading-[21px] text-[11px] leading-[15px] py-[0.2rem] mb-2'
                                        )}
                                        onClick={(e) => isFrom=='countriesList' ? selectOption('By country - Z-A','country_name:desc'): isFrom=="levelOne" || isFrom=="levelTwo" ? selectOption('By category name - Z-A ','category_name:desc') :selectOption('By client name - Z-A','client_name:desc')}

                                    >
                                       {isFrom=='countriesList' ? 'By country - Z-A': isFrom=="levelOne" || isFrom=="levelTwo" ? "By category name - Z-A" :'By client name - Z-A'}
                                    </div>
                                )}
                            </Menu.Item>
                            {isFrom !="levelTwo"?
                            <Menu.Item>
                                {({ active }) => (
                                    <div

                                        className={classNames(
                                            'text-[#707070] cursor-pointer',
                                            'block px-4 mt-1 xl:text-[13px] xl:leading-[21px] text-[11px] leading-[15px] py-[0.2rem]'
                                        )}
                                        // onClick={(e) => selectOption('By most countries',null)}
                                        onClick={(e) => isFrom=='countriesList' ? selectOption('By most L1',null): isFrom=="levelOne" || isFrom=="levelTwo" ? selectOption('By most L2',null): selectOption('By most countries',null)}

                                    >
                                        {isFrom=='countriesList' ? 'By most L1': isFrom=="levelOne" || isFrom=="levelTwo" ? "By most L2":'By most countries'}
                                    </div>
                                )}
                            </Menu.Item>    
                            :
                            <></>}                        
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
    )
}
