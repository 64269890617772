import { yupResolver } from '@hookform/resolvers/yup';
import { Divider } from '@mui/material'
import { Button, Select } from 'antd'
import React, { useState } from 'react'
import { Controller, useForm, useWatch } from 'react-hook-form';
import * as yup from "yup";
import DownArrow from '../CommonIcons/DownArrow';
import { columnNumberOperations } from '../../Utils/Constants';



function RangeFilterPopup({ onApply, onReset, defaultValues }) {
    const [isOperationFieldOpen, setIsOperationFieldOpen] = useState(false)
    const { register, handleSubmit, getValues, control } = useForm({
        mode: "onSubmit",
        defaultValues
    });
    useWatch({ control, name: ["min", "max", "operation"] })


    const onSubmit = (data) => {
        onApply(data)
    }
    console.log(getValues("min"));
    return (
        <div className='max-w-[250px] flex flex-col'>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='flex gap-2 items-center mb-2 flex-col'>
                    <Controller
                        control={control}
                        name="operation"
                        id="operation"
                        render={({ field }) => {
                            return (
                                <Select
                                    ref={field.ref}
                                    name={field.name}
                                    onChange={(value, option) => field.onChange(value)}
                                    onBlur={field.onBlur}
                                    value={field.value}
                                    options={columnNumberOperations.map(option => ({ label: option.lable, value: option.operation }))}
                                    suffixIcon={<DownArrow color="#cad3d0" width={'12px'} height={'12px'} className={isOperationFieldOpen ? 'transition-transform transform rotate-180' : ''} />}
                                    className={"filterRangeSelect text-[12px] custom-disabled-select border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"}
                                    style={{
                                        width: "100%",
                                        border: " 2px solid #00A7B5",
                                        height: '28px',
                                        borderRadius: '4px',
                                        backgroundColor: '#ffffff',
                                    }}
                                />
                            );
                        }}
                    />
                    <input
                        type="number"
                        placeholder="Filter"
                        min={0}
                        required
                        {...register("min")}
                        className={"w-full border-[1px] h-7 focus:border-[1px] rounded text-[12px] pl-3 border-greeny-blue focus:outline-greeny-blue"} />
                    {getValues("operation") === "between" && <input
                        type="number"
                        min={getValues("min") ?? "1"}
                        placeholder="Filter"
                        required
                        {...register("max")}
                        className={"w-full border-[1px] h-7 focus:border-[1px] rounded text-[12px] pl-3 border-greeny-blue focus:outline-greeny-blue"} />}

                </div>
                <Divider />
                <div className='flex items-center justify-between'>
                    <Button onClick={onReset} style={{ fontSize: "12px" }} className='mt-2' size='small'>Reset</Button>
                    <Button htmlType='submit' style={{ backgroundColor: "#42b2ac", color: "white", fontSize: "12px" }} className='mt-2' size='small'>Apply</Button>
                </div>
            </form>
        </div>
    )
}

export default RangeFilterPopup