import React from 'react'

const LeftArrow = ({bgColor,width,height,onClick,className}) => {
    return (
        <svg id="Group_92" data-name="Group 92" xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 25.009 25" className={`${className} cursor-pinter`} onClick={onclick}>
            <path id="Path_77" data-name="Path 77" d="M-508.311,136.727c.542-.538,1.018-1.008,1.491-1.481q3.965-3.963,7.928-7.928a1.782,1.782,0,0,0,.2-2.464,1.776,1.776,0,0,0-2.425-.319,3.021,3.021,0,0,0-.334.294q-5.247,5.244-10.492,10.491a1.794,1.794,0,0,0-.014,2.731q5.263,5.268,10.531,10.531a1.783,1.783,0,0,0,2.806-.147,1.758,1.758,0,0,0,0-2.057,3.306,3.306,0,0,0-.332-.375q-4.524-4.528-9.052-9.052A2.8,2.8,0,0,0-508.311,136.727Z" transform="translate(523.299 -124.194)" fill={bgColor} />
            <path id="Path_78" data-name="Path 78" d="M-316.33,136.685c.166-.131.277-.2.368-.293q4.538-4.533,9.073-9.071a1.784,1.784,0,0,0,.188-2.486,1.774,1.774,0,0,0-2.406-.306,3.155,3.155,0,0,0-.355.313q-5.228,5.224-10.453,10.451a1.806,1.806,0,0,0,0,2.791q5.245,5.246,10.492,10.491a1.789,1.789,0,0,0,2.485.21,1.774,1.774,0,0,0,.329-2.4,3.338,3.338,0,0,0-.351-.4q-4.514-4.518-9.032-9.032C-316.074,136.875-316.169,136.814-316.33,136.685Z" transform="translate(320.576 -124.193)" fill={bgColor} />
        </svg>

    )
}

export default LeftArrow
