import React, { useEffect,useState } from 'react';
import { Select } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DownArrow from '../CommonIcons/DownArrow';
import Button from '../Button';
import HistoryDataTable from '../Table/HistoryDataTable';
import DataTableSkeleton from '../Skeleton/DataTableSkeleton';
import axios from 'axios';
import SelectInput, { CheckBox } from '../SelectInput'

import { Baseurl, localStorageKeys, loginLocalStorageKeys } from '../../Utils/Constants';
import { CREATE_MERGE_SUMMARY,DUPLICATE_ROWS_ALIAS_COLUMN_NAME, REMOVE_DUPLICATE_CHECK, REMOVE_DUPLICATE_ROW, REMOVE_DUPLICATE_ROW_SAVE,} from '../../Helpers/EndPoints';
import EditIcon from '../../Images/Group 809.svg'
import {convertDateFormat } from '../../Utils/CommonFunctions';
import DataTable from '../Table/DataTable';
import { useDispatch } from 'react-redux';
import { getNodeDetails } from '../../Store/action';
import { useLocation } from 'react-router-dom';
import useDataTable from '../../hooks/useDataTable';
import XlsIcon from '../../Images/excel.svg'
import { toast } from 'react-toastify';
import { setCurrentNodeData } from '../../Store/Workflow/WorkflowSlice';
import CommonPopupLayout from '../PopUp/CommonPopupLayout';
import ProceedButton from '../CommonComponents/ProceedButton';
import downloadingIcon from '../../Images/gif/downloading.gif'

const schema = yup.object().shape({
  category: yup.string().required("Please select category"),
  columnName: yup.string().required("Please select column name"),
});

function RemoveDuplicateRow({
  workflow_id,
  ecl_node_id,
  workflow_node_id,
  calculationDate,
  templateId,
  templateName,
  client_branch_level_id,
  nodeCategoryId,
  onProceedClick
  }) {
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(nodeCategoryId);
  const [disabled, setDisabled] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);  
  const [columnNameList,setColumnNameList] = useState([])
  const [sequenceColumnName, setSequenceColumnName] = useState([]);
  const [showEdit, setShowEdit] =useState(false);
  const [showAddRename, setShowAddRename] = useState(false);
  const [retained,setRetained] = useState(undefined)
  const [categoryDisable,setCategoryDisable] = useState(false)
  const [anotherColumnName,setAnotherColumnName] = useState(undefined)
  const [isEdit, setIsEdit] = useState();
  const [saving,setSaving] = useState(false)
  const [fileMerging, setFileMerging] = useState()
  const [removeDuplicateRowsList, setRemoveDuplicateRowList] = useState();
  const [showPopup,setShowPopup] = useState(false);
  const [duplicateCheckList,setDuplicateCheckList] = useState([])
  const [isCalculating, setIsCalculating] = useState(false)


  const { dataTableColumns,
    getFilterOptions,
    summaryData,
    tableState,
    refreshDataTable,
    onTableStateChange,
    isDataLoading,
    isExportLoading,
    donwloadXl,
  } = useDataTable({ category_id: selectedCategory, ecl_node_id, workflow_id, from:"removeDuplicateRow" })

  const { register, control, reset, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const location = useLocation();

  const SequenceColumns = [
    {
      Header: 'Columns based on which duplicates will be identified',
      accessor: 'selected_column_label',
    }, 
    {
        Header: 'Method',
        accessor: 'method_label',
    },  
      {
        Header: 'Retained row',
        accessor: 'retained_label',
      },  
      {
        Header: 'Number of duplicate rows removed',
        accessor: 'no_of_rows_removed',
      },  
    
  ];

  const AnotherColumns = [
    {
      Header: 'Columns based on which duplicates will be identified',
      accessor: 'selected_column_label',
    }, 
    {
        Header: 'Method',
        accessor: 'method_label',
    },  
    {
      Header: 'Identift Earlist/Latest Rows based on another coloumn',
      accessor: 'another_columns_label',
    },      
      {
        Header: 'Retained row',
        accessor: 'retained_label',
      },  
      {
        Header: 'Number of duplicate rows removed',
        accessor: 'no_of_rows_removed',
      },  
        
  ]

  const sequanceOptions = [
    {
        Header: 'Earliest Row',
        accessor: 'earliest',
      }, 
      {
        Header:'Latest Row',
        accessor: 'latest',
      },   
  ]

  const calculationApiCall = async (isFrom,category_id) => {

    if (calculationDate == 'Invalid date') {
    }
    else {
      if(templateId || templateName){     
      const postFormData = new FormData();
      postFormData.append('ecl_workflow_id', workflow_id != null || workflow_id != undefined ? workflow_id : "");
      postFormData.append('user_id', localStorage.getItem(loginLocalStorageKeys.loginId));
      postFormData.append('client_branch_level_id', client_branch_level_id);
      postFormData.append('date', convertDateFormat(calculationDate));
      postFormData.append('template_type', 'New');
      postFormData.append('ecl_workflow_node_id', isEdit ? workflow_node_id :"");
      postFormData.append('node_id', ecl_node_id);   
      postFormData.append('is_proceed', "Completed");   
      if(isFrom == 'postionAnalysis' || isFrom == 'dataCleansing'){
        postFormData.append('category_id', category_id); 
      }
      postFormData.append('template_id', templateId ?? "");
      postFormData.append('template_name', templateId ? "" : templateName)

      axios.post("https://ecl.port0.org/laravelapi/api/ecl/calculation", postFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          // handle the response
          if (response?.data?.status == 200) {
            localStorage.setItem(localStorageKeys.currentWorkFlowId, response?.data?.workflowId)
            setShowEdit(true)
            setShowLoader(false)
            setIsCalculating(false)
            dispatch(getNodeDetails(response?.data?.workflowId));
            dispatch(setCurrentNodeData({
              workflowNodeId: response?.data?.workflowNodeId,
              nodeCategoryId: category_id
            }))
            getTableData();                    
            // setReplaceClicked(false)
            // setIsFileSentToS3(true)
            // setNodeSaved(true)
            // setError(false)
            if (isFrom != 'another') {
              localStorage.setItem(localStorageKeys.lastNodeStatus, 'completed')
            }
          }
          else if(response?.data?.status == 500){
            toast.error('Duck DB connection error please refresh the page')
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }}
  }

  const getCategoryList = () => {
    axios(`${Baseurl}/ecl/calculation/${workflow_id}/${ecl_node_id}/nodeWise/categoryList`, {
      method: "GET",
    })
      .then((response) => {
        if (response?.data?.status === 200) {
          setCategoryList(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  };

  const filesMergeApiCall = () => {
    setFileMerging(true)
    axios(`${Baseurl + CREATE_MERGE_SUMMARY}`, {
      method: "POST",
      data: {
        workflow_id: workflow_id,
        ecl_node_id: ecl_node_id
      },
    })
      .then((response) => {
        if (response?.data?.status == 200) {
          setFileMerging(false)
          refreshDataTable()
          setInitialLoading(false)          
          setDisabled(false)
          showLoader(false)
        }
        else if(response?.data?.status == 500){
          toast.error('Duck DB connection error please refresh the page')
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  };

  useEffect(() => {
    if (nodeCategoryId) {
      getTableData()
    }
  }, [])


  const getTableData = (isFrom) => {
    setShowLoader(true);

    axios(`${Baseurl + REMOVE_DUPLICATE_ROW}`, {
      method: "POST",
      data: {
        workflow_id: workflow_id,
        ecl_node_id: ecl_node_id,
        category_id: selectedCategory
      },
    })
      .then((response) => {
        if (response?.data?.status === 200) {
          setRemoveDuplicateRowList(response?.data);
          setShowLoader(false);
        //   setDisabled(true)
        setCategoryDisable(true)
        refreshDataTable()
          if(isFrom == 'cateSave'){
            getColumnNameList()
          }
          if(response?.data?.removeDuplicateRowDatas?.length ==0 ){
          setShowEdit(false)
          if(isFrom == 'cateSave'){
            setShowAddRename(true)
          }
        }else{
          setShowEdit(true)
        }
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  };

  const getColumnNameList = ()=>{
    axios(`${Baseurl + DUPLICATE_ROWS_ALIAS_COLUMN_NAME }`, {
        method: "POST",
        data: {
          workflow_id: workflow_id,
          ecl_node_id: ecl_node_id,
          category_id: selectedCategory
        },
      })
        .then((response) => {
          if (response?.data?.status === 200) {
            setShowAddRename(true)
            setColumnNameList(response?.data?.columnNames);
          }
        })
        .catch((error) => {
          console.log("API not working", error);
        });    
    
  }




  useEffect(() => {
    if(initialLoading){
      getCategoryList();
    }

      filesMergeApiCall();
                 
  }, []);


  useEffect(()=>{
    if(removeDuplicateRowsList?.removeDuplicateRowDatas?.length > 0){
      setInitialLoading(false);
      setShowLoader(false)
      setShowEdit(removeDuplicateRowsList?.isEdit)
      setIsEdit(removeDuplicateRowsList?.isEdit)

      setChecked({
        checkbox1: removeDuplicateRowsList?.removeDuplicateRowDatas[0]?.method =='sequence',
        checkbox2: removeDuplicateRowsList?.removeDuplicateRowDatas[0]?.method =='another_column'
      })

      if(removeDuplicateRowsList?.removeDuplicateRowDatas[0]?.selected_column){
        setSequenceColumnName([removeDuplicateRowsList?.removeDuplicateRowDatas[0]?.selected_column])
      }
      if(removeDuplicateRowsList?.removeDuplicateRowDatas[0]?.another_columns){
        setAnotherColumnName(removeDuplicateRowsList?.removeDuplicateRowDatas[0]?.another_columns?.split(',').map(item => item.trim()))
      }
      if(removeDuplicateRowsList?.removeDuplicateRowDatas[0]?.retained){
        setRetained(removeDuplicateRowsList?.removeDuplicateRowDatas[0]?.retained)
      }

    }

  },[removeDuplicateRowsList])


  const [checked, setChecked] = useState({
    checkbox1: false,
    checkbox2: false,
  });

  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    setChecked({
      checkbox1: name === 'checkbox1',
      checkbox2: name === 'checkbox2',
    });
  };

  const onClickSave = () =>{
    setSaving(true)
    setIsCalculating(true)

    axios(`${Baseurl + REMOVE_DUPLICATE_ROW_SAVE}`, {
        method: "POST",
        data: {
          ecl_workflow_id: workflow_id,
          ecl_node_id: ecl_node_id,
          ecl_category_id: selectedCategory,
          selected_columns: checked?.checkbox1 ? sequenceColumnName?.map(value => value.trim()).join(',') : [...sequenceColumnName, ...anotherColumnName]?.map(value => value.trim()).join(','),
          method:checked?.checkbox1 ? 'sequence': checked?.checkbox2 ? 'another_column':'',
          retained:retained
        },
      })
        .then((response) => {
          if (response?.data?.status === 200) {
            setShowAddRename(true)
            setDisabled(true)
            calculationApiCall('dataCleansing',selectedCategory)
            refreshDataTable()
          }
        })
        .catch((error) => {
          console.log("API not working", error);
        });        

  }

  useEffect(()=>{
    if(summaryData?.columnDatas?.length >0){
      setSaving(false)
    }

  },[summaryData])

  const getRemoveDuplicateCheck = () =>{
    axios(`${Baseurl + REMOVE_DUPLICATE_CHECK}`, {
      method: "POST",
      data: {
        ecl_workflow_id: workflow_id,
        ecl_node_id: ecl_node_id,
        ecl_category_id: selectedCategory,
        selected_columns: checked?.checkbox1 ? sequenceColumnName?.map(value => value.trim()).join(',') : [...sequenceColumnName, ...anotherColumnName]?.map(value => value.trim()).join(','),
        method:checked?.checkbox1 ? 'sequence': checked?.checkbox2 ? 'another_column':'',
        retained:retained
      },
    })
      .then((response) => {
        if (response?.data?.status === 200) {
         console.log(response?.data);
         setDuplicateCheckList(response?.data?.removeDuplicateRowConfirmCheck)         
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });    
  
    setShowPopup(true)
  }

  return (
    <div className='relative '>   
    {showPopup &&
    <CommonPopupLayout
        show={true}
        title={`Remove duplicate rows`}
        buttons={true}
        minWidth={'2xl:min-w-[40%] min-w-[30%]'}
        cancelButtonName="No"
        sumbitButtonName={"Yes"}
        // disabled={}
        onCancel={()=>setShowPopup(false)}
        onSubmit={()=>{onClickSave()}}
        content={
          <div className='px-6'>
          <HistoryDataTable
          columns={checked.checkbox1 ? SequenceColumns :AnotherColumns}
          data={duplicateCheckList ?? []}
          tableCellClassName={'text-[#000000] 2xl:text-[14px] text-[12px] bg-white px-5 2xl:px-8 py-1.5 xl:py-3 2xl:py-3'}
          headerclassName={'2xl:py-4 py-2 px-5 2xl:px-8 bg-[#A06DFF] text-white text-[12px] xl:text-[13px] border-none 2xl:max-w-[15rem] max-w-[10rem] min-w-[6rem]'}                
          placeholder={'Select data type'}
          name={'dataType'}
          disabled={showEdit}
          tableHeight={'table-height max-h-[8.5rem] xxlg:max-h-[17rem]  2xl:max-h-[25rem] 3xl:max-[27rem] 4xl:max-h-[30rem] 5xl:max-[35rem]'}
        />
        </div>
        } />
    }
    {
        initialLoading || isCalculating ?
      <div className='mt-2'>
      <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
    </div>
    :  
    (
        <div className={`${showEdit ? 'hidden':'block'} flex space-x-4 mt-4 items-center`}>
          <Controller
            control={control}
            name="category"
            render={({ field }) => (
              <Select
                ref={field.ref}
                name={field.name}
                onBlur={field.onBlur}
                {...register("category")}
                onChange={(e) => {
                  field.onChange(e);
                  setSelectedCategory(e);
                }}
                value={nodeCategoryId ? nodeCategoryId : field.value ? field.value : undefined}
                placeholder="Select data category"
                options={categoryList?.map(cList => ({
                  label: cList?.category_name,
                  value: cList?.category_id,
                  disabled:cList?.is_disabled
                }))}
                disabled={disabled || nodeCategoryId || categoryDisable}
                onDropdownVisibleChange={(visible) => setIsCategoryOpen(visible)}
                suffixIcon={<DownArrow color="#000000" width={'10px'} height={'10px'} className={isCategoryOpen ? 'transition-transform transform rotate-180' : 'transition-transform transform rotate-[-180]'} />}
                className="custom-select-height border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"
                style={{
                  width: '100%',
                  border: '2px solid #00A7B5',
                  borderRadius: '4px',
                }}
              />
            )}
          />
          <Button
            bgColor="#47B4AE"
            size="lg"
            label={'Save'}
            color={'#FFFFFF'}
            onClick={()=>getTableData('cateSave')}
            disabled={disabled || nodeCategoryId || !selectedCategory || categoryDisable}
          />
        </div>
    )
      
    }
            
    {
        !showLoader ? 
        (removeDuplicateRowsList?.removeDuplicateRowDatas?.length > 0 ?
            <div className={`${showEdit ? 'block':'hidden'} mt-5`}>
                <div className='2xl:py-3 py-1.5 bg-[#4339A8] flex justify-between items-center'>
                  <div className='mx-4 flex gap-3 items-center'>
                    <button className='text-[#FFFFFF] bg-[#F7BE7E] py-0.5 px-2 text-[12px] rounded'>Data category</button>
                    <p className='text-[#ffffff] font-semibold text-[12px]'>{removeDuplicateRowsList?.categoryName}</p>
                    </div>                  
                <div className={`flex gap-1 items-center mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none':'cursor-pointer'}`} onClick={() => { getColumnNameList(); setShowEdit(false);setShowAddRename(true); }}>
                        <img src={EditIcon}  className='w-4 h-4'/>
                    <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
                    </div>
                </div>            
              <HistoryDataTable
                columns={checked.checkbox1 ? SequenceColumns :AnotherColumns}
                data={removeDuplicateRowsList?.removeDuplicateRowDatas ?? []}
                tableCellClassName={'text-[#000000] 2xl:text-[14px] text-[12px] bg-white px-5 2xl:px-8 py-1.5 xl:py-3 2xl:py-3'}
                headerclassName={'2xl:py-4 py-2 px-5 2xl:px-8 bg-[#A06DFF] text-white text-[12px] xl:text-[13px] border-none max-w-[10rem] min-w-[6rem]'}                
                placeholder={'Select data type'}
                name={'dataType'}
                disabled={showEdit}
                tableHeight={'table-height max-h-[8.5rem] xxlg:max-h-[17rem]  2xl:max-h-[25rem] 3xl:max-[27rem] 4xl:max-h-[30rem] 5xl:max-[35rem]'}
              />
            </div>
                                                 
          : <></>
                
        )
        :
        (
            <div className='mt-2'>
                <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            </div>
        )
    } 
      <div className={`${(showEdit || isCalculating) ? 'hidden' : 'block'}`}>
       {showAddRename && columnNameList?.length >0 ?
        <>
        <div className={`${removeDuplicateRowsList?.removeDatas?.length > 0 && showEdit ? 'hidden':'block'}`}>
          <div className='flex space-x-4 mt-6'>
                <Controller
                    control={control}
                    name={`columnName`}
                    id={`columnName`}
                    render={({ field }) => {
                        return (
                            <SelectInput
                                ref={field.ref}
                                name={field.name}
                                onBlur={field.onBlur}
                                onChange={(e)=>{
                                    field.onChange(e);
                                    setSequenceColumnName([e]);
                                    setAnotherColumnName([])
                                }}
                                // disabled={disabled}
                                value={sequenceColumnName ?? field.value}
                                placeholder="Select column/columns based on which duplicates will be identified"
                                className="custom-select-height border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"
                                options={columnNameList?.map(cList => ({
                                    label: cList?.alias_name,
                                    value: cList?.column_name,

                                }))}
                            />
                        );
                    }}
                />
                               
          </div>            
        </div>
        <div className={`${sequenceColumnName?.length > 0 ? 'block':'hidden'} space-x-7 my-3 px-5 flex items-center`}>
          <CheckBox
              className="rounded-[3px] border-[#707070] border w-4 h-4"
              checked={checked.checkbox1}
              onChange={handleCheckboxChange}
              label="Identify earliest/latest row based on sequence"
              labelClassName="text-[#707070] 2xl:text-[14px] text-[12px]"
              name="checkbox1"
          />
          <CheckBox
              className="rounded-[3px] border-[#707070] border w-4 h-4"
              checked={checked.checkbox2}
              onChange={handleCheckboxChange}
              label="Identify earliest/latest row based on another columns"
              labelClassName="text-[#707070] 2xl:text-[14px] text-[12px]"
              name="checkbox2"
          />
        </div>   
        <div className={`mt-6 ${checked.checkbox2 ? 'block':'hidden'}`}>
                <Controller
                    control={control}
                    name={`another`}
                    key={1}
                    id={`another`}
                    render={({ field }) => {
                        return (
                            <SelectInput
                                key={1}
                                ref={field.ref}
                                mode={'multiple'}
                                name={field.name}
                                onBlur={field.onBlur}
                                onChange={(e)=>{
                                    field.onChange(e);
                                    setAnotherColumnName(e)
                                }}
                                value={anotherColumnName ? anotherColumnName : field.value}
                                placeholder="Please specify whether earliest or latest row shall be retained. Other rows will be deleted"
                                className="border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"
                                options={columnNameList?.filter(item => item.column_name != sequenceColumnName)?.map(cList => ({
                                    label: cList?.alias_name,
                                    value: cList?.column_name,


                                }))}
                            />
                        );
                    }}
                />
                               
        </div>  
        </>         
        :
        <></>
        }       
      <>
        <div className={`mt-6 ${(checked.checkbox1 || checked.checkbox2) && (checked.checkbox1 ? sequenceColumnName?.length > 0 : anotherColumnName?.length > 0) ? 'block' : 'hidden'}`}>
                <Controller
                    control={control}
                    name={`retained`}
                    id={`retained`}
                    render={({ field }) => {
                        return (
                            <SelectInput
                                ref={field.ref}
                                name={field.name}
                                onBlur={field.onBlur}
                                onChange={(e)=>{
                                    field.onChange(e);
                                    // setSequenceColumnName(e)
                                    setRetained(e)
                                }}
                                value={retained ?? field.value}
                                placeholder="Please specify whether earliest or latest row shall be retained. Other rows will be deleted"
                                className="custom-select-height border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"
                                options={sequanceOptions?.map(cList => ({
                                    label: cList?.Header,
                                    value: cList?.accessor,

                                }))}
                            />
                        );
                    }}
                />
                               
          </div>
      
          { sequenceColumnName && retained
          ?
          <div className='mt-6 flex justify-center'>
            <Button
              bgColor="#42B2AC"
              size="md"
              label={ saving ? 'Processing...' : 'Save'}
              disabled={saving || checked?.checkbox1 ? sequenceColumnName?.length <=0: anotherColumnName?.length <=0}
              color={'#FFFFFF'}
              onClick={() => {getRemoveDuplicateCheck()}}
            />           
            </div>  
            :<></>  
          }        
      </>
                        
      </div>
      <div className={` ${(showEdit || sequenceColumnName) && !showLoader ? 'block':'hidden'} mt-6`}>
          {(summaryData?.columnDatas?.length > 0 || isDataLoading || fileMerging)  &&
            isDataLoading
            ? <div className='mt-2'>
              <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            </div>
            : 
            summaryData?.columnDatas?.length > 0 ?
            <>
              <div className='flex justify-end items-center'>
                {isExportLoading
                    ? <img src={downloadingIcon} className='w-6 mr-2' />
                    : <img src={XlsIcon} alt='' className=' mb-2 object-cover mr-2 cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
              </div>
              <DataTable
                columns={dataTableColumns}
                data={summaryData?.columnDatas ?? []}
                onTableStateChange={onTableStateChange}
                totalCount={summaryData?.totalRecords}
                isOptionsDisabled={location?.state?.isfromHistory}
                getFilterOptions={getFilterOptions}
                tableState={{
                  pagination: {
                    pageIndex: tableState.pageIndex,
                    pageSize: tableState.pageSize,
                  },
                  sorting: tableState?.sortBy ? [
                    {
                      id: tableState?.sortBy,
                      desc: tableState?.sortOrder === "desc"
                    }
                  ] : [],
                  columnFilters: tableState.columnFilters
                    ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                    : [],
                  columnPinning: tableState.columnPinning,
                  columnVisibility:tableState?.columnVisibility,
                }}
              />
              <div className={`float-right 2xl:my-6 my-3  ${showEdit && !location?.state?.isfromHistory ? 'block':'hidden'}`}>
                <ProceedButton
                  onClick={onProceedClick}
                />
                {/* <Button
                    bgColor="#70AD47"
                    size="lg"
                    label={'Proceed'}
                    color={'#FFFFFF'}
                    onClick={onProceedClick}
                />                   */}
              </div>
            </>
            :
            <></>
          }
        </div>
    </div>
  );
}

export default RemoveDuplicateRow;
