//Dependencies
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
//components
import RouteSegments from '../../Routes/RouteSegments'
import SortDropDown from '../../Components/ClientPage/SortDropDown'
import PageCard from '../../Components/ClientPage/Pagecard'
import Button from '../../Components/Button'
import SearchInput from '../../Components/ClientPage/SearchInput'
import NoDataFound from '../../Components/ClientPage/NoDataFound'
import CardSkeleton from '../../Components/Skeleton/CardSkeleton'
//lazy loader
import InfiniteScroll from 'react-infinite-scroll-component';
//imgaes
import loadMore from '../../Images/loadmore.webp'
//Redux 
import { useDispatch,useSelector } from 'react-redux'
import { Constants, localStorageKeys } from '../../Utils/Constants'
import { clientsListSorting, getClearClientData, getClearCountryData} from '../../Store/Clients/Action'
//Libraries
import {toast } from "react-toastify";

let vari = [];
const Clientpage = ({dynamicHeight}) => {
    const navigate = useNavigate();
    const dispatch=useDispatch();
    const location = useLocation();
    const [isDataFound, setIsDatafound] = useState(false);
    const [currentListCount, setCurrentListCount] = useState(Constants.clientListSize)    
    const [clientsLists, setClientsList] = useState([]);
    const [isHasMore,setIsHasMore] = useState(false)
    const [isNext, setIsNext] = useState(false) ;
    const [searchTerm, setSearchTerm] = useState('');
    const [sortBy,setSortBy] = useState('created_at:desc')
    const [countrySort,setCountrySort] = useState('')
    const [selected, setSelected] = useState('Recently added');
    const [isCreateNewClient,setIsCreateNewClient] = useState(true);
    const [isDataLoading,setIsDataLoading] = useState(false)
    const [showLoader, setShowLoader] = useState(false);
    const [isVerticalScrollbarVisible, setIsVerticalScrollbarVisible] = useState(false);
    const [numSkeletons, setNumSkeletons] = useState(2); 
    const clientSearchTerm = localStorage.getItem(localStorageKeys.clientSearchTerm)   
    const hasMoreClients = JSON.parse(localStorage.getItem(localStorageKeys.hasMoreClients))  
    const clientList = JSON.parse(localStorage.getItem(localStorageKeys.clientsList)) || []
    const listCount = JSON.parse(localStorage.getItem(localStorageKeys.currentClientsCount))

    const createNewClient = () => {
        navigate(RouteSegments.NEW_CLIENT,{ state: { isNewClient: isCreateNewClient } })
    }

    useEffect(() => {
        if (location?.state?.isClient) {
            setIsDatafound(true)
        }
        if(location?.state?.setisNewClient){
            setIsCreateNewClient(location?.state?.setisNewClient)
        }
    }, [location?.state])

    const { Clients, isLoading,hasMore} = useSelector(state => {
        return {
            Clients: state?.Clients?.clientsList,
            isLoading: state?.Clients?.isLoading,
            hasMore:state?.Clients?.hasMore
        }
    })

    const onNext = () => {  
        // Increase currentListCount by 6
        setIsNext(true)
        let newCount = currentListCount + Constants.clientListSize;
        setCurrentListCount(newCount);
      
        // Dispatch action with the updated count
        dispatch(clientsListSorting(sortBy,newCount,searchTerm,countrySort,Constants.clientListSize)); 
        localStorage.removeItem(localStorageKeys.hasMoreClients);       
      };   

    useEffect(()=>{   
        if(searchTerm && !clientSearchTerm){ 
        setCurrentListCount(Constants.clientListSize)
    }
    },[sortBy,searchTerm])

      useEffect(()=>{   
        if(!clientSearchTerm){  
        localStorage.removeItem(localStorageKeys.clientsList)   
        if(searchTerm?.length > 0){   
            setCurrentListCount(Constants.clientListSize)          
            if(searchTerm?.length ==1){
                dispatch(clientsListSorting(sortBy,Constants.clientListSize,searchTerm,countrySort,Constants.clientListSize));           
            }
            else{                
                dispatch(clientsListSorting(sortBy,Constants.clientListSize,searchTerm,countrySort,Constants.clientListSize));
            }
        } 
        else if(searchTerm?.length ==0){
            if(countrySort ){
                // dispatch(clientsListSorting(null,currentListCount,searchTerm,'asc'))  
            }
            else{
                
                // if(!listCount){              
                    dispatch(clientsListSorting(sortBy,Constants.clientListSize,null,null,Constants.clientListSize))
                // }
            }
        }
    }
      },[searchTerm])
      
      useEffect(()=>{  
                    
        if(isNext){
            Clients?.map((client,i)=>{
                vari.push(client)
                setClientsList(vari)
            })
            setIsNext(false)
        }         
        else{
            
            if(clientList?.length > 0){
                setIsDatafound(true)
                setIsDataLoading(false)
                setClientsList(clientList)
                setSearchTerm(clientSearchTerm)
                setCurrentListCount(listCount)
            }else{
                vari = []
            Clients?.map((client,i)=>{
                vari.push(client)
                setClientsList(vari)
            })
            }            
        }               
        
      },[Clients])   
      useEffect(()=>{
        if(hasMoreClients){
            setIsHasMore(hasMoreClients)
            setIsDataLoading(false)
        }else{
            setIsHasMore(hasMore) 
            // setIsDataLoading(isLoading) 
        }      
      },[Clients])      
           
      useEffect(() => {
        const handleResize = () => {
          setIsVerticalScrollbarVisible(document.documentElement.scrollHeight > window.innerHeight);
        };
    
        window.addEventListener('resize', handleResize);
        handleResize(); // Initial check
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

  const determineNumSkeletons = () => {
    const width = window.innerWidth;
    if (width > 2312) {
      return 6;
    } else if (width > 1057) {
      return 4;
    } else {
      return 2;
    }
  };
  console.log(numSkeletons,'numSkeletons');

  useEffect(() => {
    const handleResize = () => {
      setNumSkeletons(determineNumSkeletons());
    };

    window.addEventListener('resize', handleResize);
    setNumSkeletons(determineNumSkeletons()); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    return (
        <>            
            <div className='w-full sticky bg-white'>
                <div className='flex justify-between xl:py-3 py-2 xl:mx-[4rem] mx-[3rem]'>
                    <Button bgColor='#DCFFFD' borderType="dashed" label={'Add new client'} paddingY={'py-0.5 xl:py-1.5 rounded-[5px]'} color={'#42B2AC'} onClick={createNewClient} icon={'plus'} />
                    <div className='flex space-x-4 '>
                        <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} icon={true} placeholder={'Search client'}/>
                        <SortDropDown setSortBy={setSortBy} record={currentListCount} setCurrentListCount={setCurrentListCount} searchTerm={searchTerm} setSearchTerm={setSearchTerm} setCountrySort={setCountrySort} countrySort={countrySort} selected={selected} setSelected={setSelected}/>
                    </div>
                </div>
            </div>
            <>
                {isDataLoading ?
                    <div className='w-full overflow-y-auto thin-scrollbar' style={{ height: dynamicHeight+'px' }}>
                    {Array(numSkeletons).fill(0).map((item, index) => (
                        <CardSkeleton key={index} cards={3} menu={"clientList"} margin={index > 0 ? '2rem' : undefined} />
                    ))}
                    </div>
                    :
                    isDataFound || !isDataLoading && clientsLists?.length > 0 
                    ?
                    Clients?.length <=0 && searchTerm?.length > 0 
                    ? <NoDataFound msg={'new client'} clientID={null} redirection={true}/> 
                    :
                    <div className='overflow-y-auto thin-scrollbar' id="scroll-node" style={{ height: dynamicHeight+'px' }}>
                    <InfiniteScroll
                        dataLength={clientsLists.length}    
                        next={onNext} 
                        className='h-full'
                        onScroll={()=>setShowLoader(true)}                  
                        hasMore={isHasMore}
                        loader={(isHasMore && showLoader )&& <div className='w-full flex items-center justify-center'>
                            <img src={loadMore} alt="load more" className='w-[20%] h-[5%]' />
                        </div>}      
                        scrollableTarget="scroll-node"
                                            
                        // endMessage={
                        //      <div className='pt-6 w-full flex items-center justify-center'>
                        //         <p className='text-[13px]'>No more Clients</p>
                        //     </div>}
                    >
                        {clientsLists?.length > 0 &&
                        <div className={`grid grid-cols-3 gap-5 mb-[3.5rem] mt-2 ${isVerticalScrollbarVisible ? 'xl:ml-[4rem] xl:mr-[3.7rem] ml-[3rem] mr-[2.7rem]' :'xl:ml-[4rem] xl:mr-[4rem] ml-[3rem] mr-[3rem]'}`}>
                            {
                                clientsLists?.map(client => (<PageCard key={client.id} data={client} title={client?.client_name} label={'Countries'}  count={client?.country_count} isFrom={"clients"} onClick={(name,id)=>{dispatch(getClearClientData()) ;
                                    dispatch(getClearCountryData());                                   
                                    navigate(RouteSegments.CLIENT_COUNTRIES,{ state: { 
                                    clientId:id,
                                    breadCrumbClientName: name,                                    
                                } })}}/>))
                            }
                        </div>
                        } 
                    </InfiniteScroll>
                    {/* {isHasMore &&
                    <div className='w-full flex items-center justify-center mb-16'>                        
                        <Button bgColor='#00A7B5' borderType="" label={isDataLoading ? 'Loading...' : 'Load More'} icon={isDataLoading ? 'processing' : ''} color={'#FFFFFF'} onClick={()=>onNext()} size={'sm'} />
                    </div> } */}

                   </div>                
                    :                      
                    <div className='w-full overflow-y-auto thin-scrollbar' style={{ height: dynamicHeight+'px' }}>
                    {Array(numSkeletons).fill(0).map((item, index) => (
                        <CardSkeleton key={index} cards={3} menu={"clientList"} margin={index > 0 ? '2rem' : undefined} />
                    ))}
                    </div>
                }
            </>   
        </>

    )
}
export default Clientpage;
