import React, { useEffect, useState } from 'react'
import Button from '../../Components/Button'
import RouteSegments from '../../Routes/RouteSegments';
import { useLocation, useNavigate } from 'react-router-dom';
import CategoryIcon from '../../Components/CommonIcons/CategoryIcon';
import { useDispatch, useSelector } from 'react-redux';
import { createCountryLevel, manageLevel } from '../../Store/action';
import { Baseurl } from '../../Utils/Constants';
import { CATEGORY_AVAILABILITY, MANAGAE_LEVEL_REMOVE } from '../../Helpers/EndPoints';
import axios from 'axios';
import ConfirmPopup from '../../Components/PopUp/ConfirmPopUp';
import AlertIcon from '../../Images/alert.svg'
import { toast } from 'react-toastify';


const ManageLevel = () => {
  const [categoryError, setCategoryError] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [categoryNameList, setCategoryNameList] = useState([]);
  const [categoryIdArray, setCategoryIdArray] = useState([]);
  const [undoChanges, setUndoChanges] = useState(false);
  const [isClickedRemove, setIsClickedRemove] = useState(false);
  const [disabledSave, setDisabledSave] = useState(true);
  const [disableAdd, setDisableAdd] = useState(true)

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch()

  // console.log(location?.state,'location?.state')

  const movePreviousPage = () => {
    const hasEmptyId = categoryNameList?.some(item => item?.id == "");
    if (hasEmptyId || isClickedRemove || categoryName) {
      setUndoChanges(true)
    } else {
      if (location?.state?.level == 1) {
        navigate(RouteSegments.LEVEL_ONE,
          {
            state: {
              breadCrumbClientName: location?.state?.breadCrumbClientName,
              breadCrumbCountryName: location?.state?.breadCrumbCountryName,
              client_country_id: location?.state?.client_country_id,
              clientId: location?.state?.clientId
            }
          });
      }
      else {
        navigate(RouteSegments.LEVEL_TWO,
          {
            state: {
              breadCrumbLevelName: location?.state?.breadCrumbLevelName,
              breadCrumbClientName: location?.state?.breadCrumbClientName,
              breadCrumbCountryName: location?.state?.breadCrumbCountryName,
              labelName: location?.state?.breadCrumbLevelName,
              client_country_id: location?.state?.client_country_id,
              data: location?.state?.data?.data
            }
          });
      }
    }
  };

  useEffect(() => {
    dispatch(manageLevel(location?.state?.clientId, location?.state?.client_country_id, location?.state?.level, location?.state?.parent_level_id))
  }, [location?.state?.clientId])

  const { ManageLevelDetails } = useSelector(state => {
    return {
      ManageLevelDetails: state?.Clients?.manageLevelDetails,
    }
  })
  // useEffect(() => {
  //     if (ManageLevelDetails) {
  //       setCategoryNameList((prevCategoryNameList) =>
  //         ManageLevelDetails.map((data) => ({
  //           id: data?.id,
  //           category_name: data?.category_name,
  //         }))
  //       );
  //     }
  //   }, [ManageLevelDetails]);

  useEffect(() => {
    if (ManageLevelDetails) {
      const updatedCategoryNameList = ManageLevelDetails.map((data) => ({
        id: data?.id,
        category_name: data?.category_name,
      }));

      setCategoryNameList(updatedCategoryNameList);

      const updatedCategoryIdArray = ManageLevelDetails.map((data) => data?.id);
      setCategoryIdArray(updatedCategoryIdArray);
    }
  }, [ManageLevelDetails]);

  const checkCategoryName = () => {
    setDisableAdd(true)
    var hasMatch = false;
    for (var index = 0; index < categoryNameList.length; ++index) {
      var data = categoryNameList[index];
      if (data.category_name == categoryName) {
        hasMatch = true;
        break;
      }
    }
    axios(`${Baseurl + CATEGORY_AVAILABILITY}`, {
      method: "POST",
      data: {
        "client_id": location?.state?.clientId,
        "client_country_id": location?.state?.client_country_id,
        "level": location?.state?.level,
        "parent_level_id": location?.state?.parent_level_id,
        "category_name": categoryName
      },
      headers: {
        "Content-Type": "application/json",
        // "Authorization": getToken()
      },
    })
      .then((response) => {
        if (response?.data?.status == 200) {
          if (response?.data?.success) {
            if (hasMatch == false) {
              setCategoryError('')
              setCategoryNameList(prev => [...prev, { id: '', category_name: categoryName }])
              setCategoryName('')
              setDisabledSave(false)
            }
            else {
              setCategoryError('Category name already exists')
            }

          }
        } else if (response?.data?.status == 205) {
          setCategoryError(response?.data?.message)
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });

  }
  const newRequestArray = ({
    "client_id": location?.state?.clientId,
    "client_country_id": location?.state?.client_country_id,
    "level": location?.state?.level,
    "parent_level_id": location?.state?.parent_level_id,
    "level_name": location?.state?.level_name,
    "category_id_array": categoryIdArray,
    "category_list": categoryNameList
  });

  const removeCategoryName = (indexValue,client_branch_level_id) => {

    if (categoryNameList?.length == 1) {
      toast.warning('Atleast one category is mandatory');
    } else {
      axios(`${Baseurl+MANAGAE_LEVEL_REMOVE}`, {
        method: "POST",
        data:{
          "client_branch_level_id":client_branch_level_id,
          "direct_delete":false
        },         
        headers: {
          "Content-Type": "application/json",
          // "Authorization": getToken()
        },
      })
        .then((response) => {
          if (response?.data?.status==200) { 
            
            setCategoryIdArray((prev) => {
              const updatedArray = [...prev];
              updatedArray.splice(indexValue, 1);
              return updatedArray;
            });
      
            setCategoryNameList((prev) => {
              const indexToDelete = indexValue;
      
              const updatedArray = prev.filter((_, index) => index !== indexToDelete);
      
              return updatedArray;
            });
            setDisabledSave(false)
             
          }else if(response?.data?.status==205){  
            toast.error(response?.data?.message)                    
          }
        })
        .catch((error) => {
          console.log("API not working", error);
        });            
    }
  }

  const handlePopup = (data) => {
    if (data == 0) {
      setUndoChanges(false)

    } else {
      if (location?.state?.level == 1) {
        navigate(RouteSegments.LEVEL_ONE,
          {
            state: {
              breadCrumbClientName: location?.state?.breadCrumbClientName,
              breadCrumbCountryName: location?.state?.breadCrumbCountryName,
              client_country_id: location?.state?.client_country_id,
              clientId: location?.state?.clientId
            }
          });
      }
      else {
        navigate(RouteSegments.LEVEL_TWO,
          {
            state: {
              breadCrumbLevelName: location?.state?.breadCrumbLevelName,
              breadCrumbClientName: location?.state?.breadCrumbClientName,
              breadCrumbCountryName: location?.state?.breadCrumbCountryName,
              labelName: location?.state?.breadCrumbLevelName,
              client_country_id: location?.state?.client_country_id,
              data: location?.state?.data?.data
            }
          });
      }
    }
  }

  useEffect(()=>{
    if(categoryName){
      setDisableAdd(false)
    }else{
      setDisableAdd(true)
    }

  },[categoryName])
  return (
    <>
      {undoChanges &&
        <ConfirmPopup
          show={true}
          message={'Are you sure you want cancel & exit the page?'}
          activeBtnName={'Yes'}
          closeBtnName='No'
          onChange={(data) => handlePopup(data)}
          activeBtnColor="#42B2AC"
          forWarning={true}
          icon={<img className='' src={AlertIcon} />}
        />}
      <div className="w-full sticky top-[4rem] bg-white">
        <div className="flex justify-between py-3 mx-[4rem]">
          <Button
            bgColor="#FBE5D6"
            borderType="dashed"
            label={"Cancel and exit"}
            color={"#ED7D31"}
            onClick={movePreviousPage}
            icon={"minus"}
          />
        </div>
      </div>
      <div className={`pl-4 pr-4 flex justify-center items-center h-fit mt-10 2xl:mt-16'}`}>
        <div className="px-[2rem] py-[1.5rem] bg-white shadow-[1px_1px_5px_1px_rgb(232,232,232)] rounded-lg w-[55%] border border-[#E1E1E1]">
          <div className="">
            <div className='space-y-2 max-h-[5rem] lg:max-h-[6rem] xlg:max-h-[7rem] 2xl:max-h-[20rem] 3xl:max-h-[25rem] overflow-y-auto thin-scrollbar'>
              {categoryNameList?.map((data, i) => {
                return (
                  <div className='border-b-2 border-[#E1E1E1] flex items-center justify-between' key={i}>
                    <p className='text-[#37384C] xl:text-[14px] text-[12px] pb-2'>{data?.category_name}</p>
                    <p className="underline text-error-red xl:text-[14px] text-[12px] text-right cursor-pointer mr-2" onClick={() => { removeCategoryName(i,data?.id); setIsClickedRemove(true) }}>Remove</p>
                  </div>
                )
              })}
            </div>
            <div className="relative mt-4">
              <div className="absolute top-1/2 left-3 transform -translate-y-1/2 w-4">
                <CategoryIcon bgColor={'#707070'} width={'15px'} height={'15px'} />
              </div>
              <input
                id="categoryName"
                name="categoryName"
                type="text"
                placeholder="Please enter category name"
                onChange={(e) => {
                  setCategoryName(e.target.value);
                  setCategoryError(false);
                }}
                value={categoryName}
                className={`w-full text-[14px] placeholder:text-[#BEBEBE] placeholder:tracking-[1px] rounded-md px-0 xl:py-1.5 py-[0.3rem] border-2 ${!categoryError ? 'border-greeny-blue focus:border focus:border-greeny-blue focus:outline-greeny-blue' : 'border-error-red focus:outline-error-red'} pl-[2.2rem] placeholder:tracking-[3px]`}
                autoComplete="off"
              />
            </div>
            {categoryError &&
              <p className="text-error-red text-[14px] pt-1">{categoryError}</p>
            }
            <div className='float-right mt-4 space-y-2'>
              <div className='flex justify-end'>
                <Button bgColor="#00A7B5"
                  borderType="normal"
                  label={"Add"}
                  color={"#ffffff"}
                  type={'button'}
                  onClick={(e) => { e.stopPropagation(); checkCategoryName(); }}
                  size={'md'}
                  disabled={!categoryName || disableAdd}
                />
              </div>
              <Button bgColor="#00A7B5"
                borderType="normal"
                label={"Save & exit"}
                color={"#ffffff"}
                type={'button'}
                size={'lg'}
                onClick={(async) => {
                  try {
                    setDisabledSave(true)
                    dispatch(createCountryLevel(newRequestArray));
                    setIsClickedRemove(false)
                    setDisableAdd(false)
                    new Promise((resolve) => {
                      setTimeout(() => {
                        location?.state?.level == 1 ?
                          navigate(RouteSegments.LEVEL_ONE, {
                            state: {
                              breadCrumbClientName: location?.state?.breadCrumbClientName,
                              breadCrumbCountryName: location?.state?.breadCrumbCountryName,
                              clientId: location?.state?.clientId,
                              client_country_id: location?.state?.client_country_id,
                            }
                          })
                          :
                          navigate(RouteSegments.LEVEL_TWO, {
                            state: {
                              labelName: location?.state?.breadCrumbLevelName,
                              data: location?.state?.data?.data,
                              breadCrumbClientName: location?.state?.breadCrumbClientName,
                              breadCrumbCountryName: location?.state?.breadCrumbCountryName,
                              breadCrumbLevelName: location?.state?.breadCrumbLevelName,
                              clientId: location?.state?.clientId,
                              client_country_id: location?.state?.client_country_id,
                            }
                          })
                      }, 500);
                    });
                  }
                  catch (error) {
                    // Handle errors
                    console.error('Error updating category name', error);
                  }
                }
                }
                // disabled={categoryNameList?.length == 0}
                disabled={categoryName || disabledSave}

              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ManageLevel
