import React from 'react'
import '../../CSS/Skeleton.css'

//skeleton loader
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function ModelBuilderSkeleton({ cards, menu, marigin }) {

    return (
        <>
        <Skeleton height={30}  />
        <div className='mt-4 grid grid-cols-3 items-center gap-3 2xl:gap-8'>
        {Array(cards)
                .fill(0)
                .map((item, i) => (
                    <div key={i} className={`col-span-1 h-[7.5rem] w-[7.2rem] bg-[#E7E6E6] rounded-lg flex flex-col gap-3 p-1.5 items-center justify-center  `}>
                        <Skeleton height={30} width={25} />
                        <Skeleton height={30} width={25} />
                        <Skeleton height={14} width={70} />
                      </div>
                ))
            }
        </div>
        <div className='mt-4'>
        <Skeleton height={30}  />
        <div className='mt-4 grid grid-cols-3 items-center gap-3 2xl:gap-8'>
        {Array(cards)
                .fill(0)
                .map((item, i) => (
                    <div key={i} className={`col-span-1 h-[7.5rem] w-[7.2rem] bg-[#E7E6E6] rounded-lg flex flex-col gap-3 p-1.5 items-center justify-center  `}>
                        <Skeleton height={30} width={25} />
                        <Skeleton height={30} width={25} />
                        <Skeleton height={14} width={70} />
                      </div>
                ))
            }
        </div>
        </div>
        <div className='mt-4'>
        <Skeleton height={30}  />
        </div>       
         </>
    )
}
