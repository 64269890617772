//Dependecies
import React from 'react'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import { useForm} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
//imgaes
import avatar from '../../Images/avatar.png'
import key from '../../Images/key.svg'
import user from '../../Images/userProfile.png'
//components
import Warning from '../Indications/Warning'
import { NavLink} from 'react-router-dom';
import RouteSegments from '../../Routes/RouteSegments';
//Redux components
import {useDispatch, useSelector } from "react-redux";
import { loginUser, resetLoginError } from "../../Store/Authentication/Action";
import withRouter from '../../Routes/withRouter';

//Libraries
import { ToastContainer} from 'react-toastify';


const schema = yup.object().shape({  
  username: yup
  .string()
  .email('Please enter a valid email id')
  .required('Please enter email'),  
  password: yup.string().required('Please enter password')  
});

const Login = (props) => {
//States
  const [userName, setUserName] =useState(''); 
  const [password, setPassword] = useState('');
  const [logging, setLogging] = useState(false);
  const [loginErrorMsg,setLogginErrorMsg] = useState('')
//redux 
  const dispatch = useDispatch();
  const location = useLocation();


  //react-hook-form-components

  const { register, handleSubmit, setFocus, formState: { errors} } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });


  const onSubmit = (data) => {
    setLogging(true)
    dispatch(loginUser(data, props.router.navigate))
  };

  const { loginData,loginError} = useSelector(state => {
    return {
      loginData: state?.login?.LoginData,
      loginError:state?.login?.LoginError
    }
  })
 
  useEffect(()=>{
    if(loginData || loginError){
      setLogging(false)
    }  
    setLogginErrorMsg(loginError?.message)    

  },[loginData, loginError,location])

  useEffect(() => {
    // Reset the login error when the component is unmounted or when navigating away
    return () => {
      dispatch(resetLoginError());
    };
  }, []);

const {resetPasswordData,registerResponse} =useSelector(state => {
  return {
    resetPasswordData:state?.login?.resetPasswordData,
    registerResponse:state?.login?.registerData

  }
})


  useEffect(() => {
   if(errors.username == undefined){
    setFocus("username");}
  }, [setFocus]);

  return (
    <div className="w-full flex items-center justify-end h-full absolute right-[10%]">
      <div className="w-[75%] xl:max-w-sm max-w-xs">
        <div className="p-4">
          <div className="py-3 flex items-center before:flex-[1_1_0%] before:border-t before:border-carrot-orange ltr:before:mr-6 rtl:before:ml-6 after:flex-[1_1_0%] after:border-t after:border-carrot-orange ltr:after:ml-6 rtl:after:mr-6 dark:text-white/70 dark:before:border-white/10 dark:after:border-white/10">
            <div className="xl:w-[5rem] w-[3.5rem] mx-auto">
              <img alt="userlogo" className='z-10' src={user} />
            </div>
          </div>
          <div className="mt-5">
            <form onSubmit={handleSubmit(onSubmit)} >
              {
                errors.username && errors.username.type=="required" && errors.password && errors.password.type=="required" ?
                  <p className="text-yellow text-center text-sm mb-2">Please enter username and password</p>
                : errors.username && errors.username.type=="required"  ? 
                  <p className="text-yellow text-center text-sm mb-2">{errors.username.message}</p>
                : errors.username && errors.username.type!=="required" ?
                  <p className="text-error-red text-center text-sm mb-2">{errors.username.message}</p>
                : errors.password && errors.password.type=="required" ? 
                  <p className="text-yellow text-center text-sm mb-2">{errors.password.message}</p>
                : errors.password && errors.password.type!=="required"?
                  <p className="text-error-red text-center text-sm mb-2">{errors.password.message}</p>
                 :<></>
              }
              {
                loginErrorMsg && <p className="text-error-red text-center text-sm mb-2">{loginErrorMsg }</p>
              }
              
              <div className="relative mb-4">
                <img src={avatar} className="absolute top-1/2 left-3 transform -translate-y-1/2 w-4 brightness-0 invert-[1]" />
                <input type="text" id="username" placeholder="Username" name="username"
                  {...register("username",{
                    onChange: (e) => {
                      setUserName(e.target.value)
                      setLogginErrorMsg(null)
                  },
                  })}
                  className={` w-full text-white rounded px-0 py-2.5 ${errors.username ? errors.username.type=="required" ? 'border border-yellow' : 'border border-error-red' :''} ${loginErrorMsg && 'border border-error-red'} focus:outline-none pl-[2.5rem] bg-charcoal-gray placeholder:text-white xl:text-[14px] text-[13px]`}
                  autoComplete="off"
                  style={{
                    WebkitBoxShadow: "0 0 0 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  }} />
                {errors.username &&
                  <div className="absolute top-1/2 right-3 transform -translate-y-1/2 w-4" >
                    <Warning circleBg={errors.username && errors.username.type=="required" ? "yellow" : "#FF6174"} />
                  </div>}
                  {loginErrorMsg && 
                  <div className="absolute top-1/2 right-3 transform -translate-y-1/2 w-4" >
                   <Warning circleBg={"#FF6174"} />
                  </div>
                  } 
              </div>
              <div className="relative">
                <img src={key} className="absolute top-1/2 left-3 transform -translate-y-1/2 w-4 brightness-0 invert-[1]" />
                <input type="password" id="password" placeholder="Password" name="password"
                  {...register("password",{
                    onChange: (e) => {
                      setPassword(e.target.value);
                      setLogginErrorMsg(null)
                  },
                  })}
                  // value={password}
                  className={` w-full text-white rounded px-0 py-2.5 ${errors.password ? errors.password.type=="required" ? 'border border-yellow' : 'border border-error-red' :''} ${loginErrorMsg && 'border border-error-red'} focus:outline-none pl-[2.5rem] bg-charcoal-gray placeholder:text-white xl:text-[14px] text-[13px]`}
                  autoComplete="off"
                  style={{
                    WebkitBoxShadow: "0 0 0 1000px transparent inset",
                    transition: "background-color 5000s ease-in-out 0s",
                  }} 
                   />                
                {errors.password &&
                  <div className="absolute top-1/2 right-3 transform -translate-y-1/2 w-4" >
                    <Warning circleBg={errors.password && errors.password.type=="required" ? "yellow" : "#FF6174"} />
                  </div>}
                  {loginErrorMsg && 
                  <div className="absolute top-1/2 right-3 transform -translate-y-1/2 w-4" >
                   <Warning circleBg={"#FF6174"} />
                  </div>}
              </div>
              <div className="mt-8 flex items-center justify-center">                
                <button type="submit" disabled={userName.length <=0 || password.length <=0 ? true :false}className={`w-full rounded py-1.5 focus:outline-none xl:text-[16px] text-[14px] ${userName.length <=0 || password.length <=0 ? 'bg-ash text-[#707070]' : 'bg-greeny-blue text-white '}`}>{logging ? ' Logging in' : 'Login'}</button>
              </div>
              <p className='text-center pt-2.5 pb-4'>
              <NavLink to ={RouteSegments.FORGOTPASSWORD} className='xl:text-[14px] text-[13px] leading-[17px] text-greeny-blue cursor-pointe'>
                Forgot password?
              </NavLink>
              </p>
              <div className="flex items-center justify-center">
                <NavLink to={RouteSegments.REGISTER} className="w-full">
                  <button type="button" className="w-full rounded py-1.5 text-white bg-dark-orange focus:bg-gray-600 focus:outline-none xl:text-[16px] text-[14px]">Register new user</button>
                </NavLink>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
            position={"top-center"}
            autoClose={3000}
            toastClassName="bg-white shadow-lg rounded-lg flex items-center"
            bodyClassName="text-sm font-medium text-gray-900"
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            />  
    </div>
  )
}

export default withRouter(Login)
