import axios from "axios";

export const post_method = (url, data,headers = {},contentType = 'application/json',) => {
       return axios.post(url, data, {
           headers: {
               'Content-Type': contentType,
               ...headers
           }
       });
   };
export const get_method = (url) => {
       return axios.get(url);
};
export const get_method_with_headers = (url,headers) => {
       return axios.get(url,headers);
};

export const download_post_method = (url, data, headers = {}, responseType = "blob") => {
    return axios.post(url, data, {
        headers: {
            ...headers
        },
        responseType,
    },
    );
};