//modal builder
export const MODEL_BUILDER_LIST                = "MODEL_BUILDER_LIST";
export const MODEL_BUILDER_LIST_FETCH_SUCCESS  = "MODEL_BUILDER_LIST_FETCH_SUCCESS";
export const MODEL_BUILDER_LIST_API_ERROR      = "MODEL_BUILDER_LIST_API_ERROR";

//ecl history

export const ECL_HISTORY                       = "ECL_HISTORY";
export const ECL_HISTORY_FETCH_SUCCESS         = "ECL_HISTORY_FETCH_SUCCESS";
export const ECL_HISTORY_API_ERROR             = "ECL_HISTORY_API_ERROR";

//get Node Details

export const NODE_DETAILS                      = "NODE_DETAILS";
export const NODE_DETAILS_FETCH_SUCCESS        = "NODE_DETAILS_FETCH_SUCCESS";
export const NODE_DETAILS_API_ERROR            = "NODE_DETAILS_API_ERROR ";
export const NODE_DETAILS_DATA_CLEAR           = "NODE_DETAILS_DATA_CLEAR";
export const PERTICULAR_NODE_DATA_CLEAR        = "PERTICULAR_NODE_DATA_CLEAR ";


//Category list

export const GATEGORY_LIST                     = "GATEGORY_LISTS";
export const GATEGORY_LIST_FETCH_SUCCESS       = "GATEGORY_LIST_FETCH_SUCCESS";
export const GATEGORY_LIST_API_ERROR           = "GATEGORY_LIST_API_ERROR";
export const GATEGORY_LIST_DATA_CLEAR          = "GATEGORY_LIST_DATA_CLEAR";

//Single file upload
export const SINGLE_FILE_UPLOAD                = "SINGLE_FILE_UPLOAD";
export const SINGLE_FILE_UPLOAD_SUCCESS        = "SINGLE_FILE_UPLOAD_SUCCESS";
export const SINGLE_FILE_UPLOAD_API_ERROR      = "SINGLE_FILE_UPLOAD_API_ERROR";

//single uplopad listing
export const SINGLE_FILE_UPLOAD_LIST                = "SINGLE_FILE_UPLOAD_LIST";
export const SINGLE_FILE_UPLOAD_LIST_FETCH_SUCCESS  = "SINGLE_FILE_UPLOAD_LIST_FETCH_SUCCESS";
export const SINGLE_FILE_UPLOAD_LIST_API_ERROR      = "SINGLE_FILE_UPLOAD_LIST_API_ERROR";

//single uplopad perticular list
export const SINGLE_FILE_UPLOAD_PERTICULAR_LIST                = "SINGLE_FILE_UPLOAD_PERTICULAR_LIST";
export const SINGLE_FILE_UPLOAD_PERTICULAR_LIST_FETCH_SUCCESS  = "SINGLE_FILE_UPLOAD_PERTICULAR_LIST_FETCH_SUCCESS";
export const SINGLE_FILE_UPLOAD_PERTICULAR_LIST_API_ERROR      = "SINGLE_FILE_UPLOAD_PERTICULAR_LIST_API_ERROR";


//delete node 
export const DELETE_NODE            = "DELETE_NODE";
export const DELETE_NODE_SUCCESS    = "DELETE_NODE_SUCCESS";
export const DELETE_NODE_API_ERROR  = "DELETE_NODE_API_ERROR";

//delete workflow
export const DELETE_WORKFLOW            = "DELETE_WORKFLOW";
export const DELETE_WORKFLOW_SUCCESS    = "DELETE_WORKFLOW_SUCCESS";
export const DELETE_WORKFLOW_API_ERROR  = "DELETE_WORKFLOW_API_ERROR";

//CHECK workflow
export const CHECK_WORKFLOW            = "CHECK_WORKFLOW";
export const CHECK_WORKFLOW_SUCCESS    = "CHECK_WORKFLOW_SUCCESS";
export const CHECK_WORKFLOW_API_ERROR  = "CHECK_WORKFLOW_API_ERROR";
export const CHECK_WORKFLOW_DATA_CLEAR  = "CHECK_WORKFLOW_DATA_CLEAR";

//merge data workflow
export const MERGE_DATA_WORKFLOW            = "MERGE_DATA_WORKFLOW";
export const MERGE_DATA_WORKFLOW_SUCCESS    = "MERGE_DATA_WORKFLOW_SUCCESS";
export const MERGE_DATA_WORKFLOW_API_ERROR  = "MERGE_DATA_WORKFLOW_API_ERROR";
export const MERGE_DATA_CLEAR               = "MERGE_DATA_CLEAR";


//file upload summary
export const FILE_UPLOAD_SUMMARY            = "FILE_UPLOAD_SUMMARY";
export const FILE_UPLOAD_SUMMARY_SUCCESS    = "FILE_UPLOAD_SUMMARY_SUCCESS";
export const FILE_UPLOAD_SUMMARY_API_ERROR  = "FILE_UPLOAD_SUMMARY_API_ERROR";
export const FILE_UPLOAD_SUMMARY_CLEAR      = "FILE_UPLOAD_SUMMARY_CLEAR";

//file download mergesummary
export const DOWNLOAD_MERGE_SUMMARY            = "DOWNLOAD_MERGE_SUMMARY";
export const DOWNLOAD_MERGE_SUMMARY_SUCCESS    = "DOWNLOAD_MERGE_SUMMARY_SUCCESS";
export const DOWNLOAD_MERGE_SUMMARY_API_ERROR  = "DOWNLOAD_MERGE_SUMMARY_API_ERROR";

//table updation

export const TABLE_UPDATION            = "TABLE_UPDATION";
export const TABLE_UPDATION_SUCCESS    = "TABLE_UPDATION_SUCCESS";
export const TABLE_UPDATION_API_ERROR  = "TABLE_UPDATION_API_ERROR";
export const TABLE_UPDATION_DATA_CLEAR  = "TABLE_UPDATION_DATA_CLEAR";

//delete popup content
export const GET_FILE_DETAILS  = "GET_FILE_DETAILS";
export const GET_FILE_DETAILS_SUCCESS  = "GET_FILE_DETAILS_SUCCESS";
export const GET_FILE_DETAILS_API_ERROR  = "GET_FILE_DETAILS_API_ERROR";
export const FILE_DETAILS_DATA_CLEAR  = "FILE_DETAILS_DATA_CLEAR";

//data loading

export const SET_IS_DATA_LOADING = "SET_IS_DATA_LOADING"