import React, { useEffect, useMemo, useState } from 'react'
import SummaryTable from '../Table/SummaryTable'
import { createColumnHelper } from '@tanstack/react-table'
import Button from '../Button'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { downloadExport, getCalculationFormData, getMergeSummaryFileCreation, imputationUserDefinedDetail, imputationProceed, openModalBuilder, selectCurrentNodeData, selectCurrentNodeIds } from '../../Store/Workflow/WorkflowSlice'
import useDataTable from '../../hooks/useDataTable'
import DataTableSkeleton from '../Skeleton/DataTableSkeleton'

import XlsIcon from '../../Images/excel.svg'
import EditIcon from '../../Images/Group 809.svg'
import Eyeicon from '../../Images/eyeicon.svg'
import { useFormik } from 'formik'
import { IMPUTATION_USER_DEFINED_EXPORT } from '../../Helpers/EndPoints'
import { CircularProgress } from '@mui/material'
import DataTable from '../Table/DataTable'
import { exportImputationUserDefined, saveImputationUserDefined } from '../../Helpers/ApiHelpers'
// import editicon from '../../Images/editIcon.svg'
import DataPopup from '../PopUp/DataPopup'
import dayjs from "dayjs"
import { DatePicker } from 'antd'
import CalenderIcon from '../CommonIcons/CalenderIcon'
import DropDown from '../DropDown/DropDown'
import ProceedButton from '../CommonComponents/ProceedButton'
import downloadingIcon from '../../Images/gif/downloading.gif'


const ColumnDownload = ({ workflow_id, ecl_node_id, category_id, column_name, parent_node_id ,disabled}) => {

    const [isDownloading, setisDownloading] = useState(false)

    const onDownloadClick = async () => {
        setisDownloading(true)
        const request = {
            workflow_id,
            ecl_node_id,
            category_id,
            column_name,
            is_export: true,
            parent_node_id
        }
        await downloadExport(request, IMPUTATION_USER_DEFINED_EXPORT)
        setisDownloading(false)
    }
    return isDownloading
        ?<img src={downloadingIcon} className='w-6' />
        : <img alt='' onClick={onDownloadClick} src={XlsIcon} className={`${disabled ? 'pointer-events-none color-change-image':'cursor-pointer'} w-4 h-4 xl:w-5 xl:h-5`} />
}

const ColumnView = ({ workflow_id, ecl_node_id, category_id, column_name, parent_node_id,disabled }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [showPopup, setShowPopup] = useState(null)

    const onClick = async () => {
        setIsLoading(true)
        const request = {
            workflow_id,
            ecl_node_id,
            category_id,
            column_name,
            is_export: false,
            parent_node_id
        }
        const response = await exportImputationUserDefined(request)
        setIsLoading(false)
        setShowPopup(response?.data)
    }



    return <>
        {showPopup && <DataPopup
            source={showPopup}
            onClose={() => setShowPopup(null)}
            title={`Replace with a value specified by the user - ${column_name}`} />}
        {isLoading
            ? < CircularProgress size={18} />
            : <button onClick={onClick} className={`${disabled ? 'text-[#707070]':'text-[#4F49DF]'}text-[12px] xl:text-[14px] flex items-center gap-2`}>
                <img alt='' src={Eyeicon} className={`w-3 ${disabled ? 'pointer-events-none color-change-image':'cursor-pointer'}`} />
                View
            </button>}
    </>
}



const columnHelper = createColumnHelper()

function ValueSpecifiedByUser() {


    const location = useLocation()
    const dispatch = useDispatch()
    const nodeWiseCategory = useSelector(state => state?.Workflow?.nodeWiseCategory)
    const currentNodeData = useSelector(selectCurrentNodeData)
    const isDataLoading = useSelector(state => state?.Workflow?.ui?.isDataLoading)
    const isCalculating = useSelector(state => state?.Workflow?.ui?.isCalculating)
    const filesMerging = useSelector(state => state?.Workflow?.ui?.filesMerging)
    const reduxState = useSelector(state => state)
    const { currentEclNodeId, currentWorkflowId, currentWorkflowNodeId } = useSelector(selectCurrentNodeIds)
    const [isEditMode, setIsEditMode] = useState()

    useEffect(() => {
        if (currentNodeData?.imputationUserDefinedTableData?.isEdit == false) {
            setIsEditMode(true)
        }
    }, [currentNodeData?.imputationUserDefinedTableData?.isEdit])


    const formik = useFormik({
        initialValues: {
            userEnterValue: currentNodeData?.imputationUserDefinedTableData?.userDefinedDatas?.map(data => data?.user_defined_value)
        },
        enableReinitialize: true,
    })

    const { dataTableColumns,
        getFilterOptions,
        summaryData,
        tableState,
        refreshDataTable,
        onTableStateChange,
        isDataLoading: isMergeSummaryLoading,
        isExportLoading,
        donwloadXl,
    } = useDataTable({ category_id: currentNodeData?.nodeCategoryId, 
        ecl_node_id: currentEclNodeId,
         workflow_id: currentWorkflowId, 
         from: "imputationUserDefinedDatas",  
         parent_node_id: currentNodeData?.parent_node_id
        })


    const onUserEnterValueChange = (index, value) => {
        formik.setFieldValue(`userEnterValue.${index}`, value)
    }

    const getValueByIndex = index => formik.values.userEnterValue?.at(index)

    console.log(currentNodeData, 'currentNodeData userdefined');
    const summaryColumns = useMemo(() => {
        if (isEditMode) {
            return [
                columnHelper.accessor("exception_analysis_method", {
                    header: "Exception Analysis",
                    mergeCells: true,
                    // cell :({row}) =>{
                    //     return<>
                    //     <div className='flex'>
                    //     <p className='!text-[#4339A8]'>{currentNodeData?.imputationUserDefinedTableData?.userDefinedDatas[0]?.exception_analysis_method}</p>
                    //     {currentNodeData?.imputationUserDefinedTableData?.isEdit == true
                    //             ?
                    //             <div className={`flex gap-1 items-center mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={onEditClick}>
                    //             <img alt='' src={editicon} className='w-4 h-4' />
                    //         </div>
                    //             :""}
                    //     </div></>
                    // }

                }),
                columnHelper.accessor("column_name", {
                    header: "Destination Column",
                }),
                columnHelper.display({
                    id: "entervalue",
                    header: "Enter Value",
                    tdClassName: "!py-2.5",
                    cell: ({ row }) => {

                        const value = getValueByIndex(row.index) || undefined
                        const dataType = row.original?.assigned_data_type ? row.original?.assigned_data_type : row.original?.column_datatype
                        if (dataType == "TEXT") {
                            return <input
                                className={`place text-[12px] 2xl:text-[13px] h-full !border-[1px] border-[#E0DCD7] w-full rounded  2xl:h-[2.2rem]  focus:outline-none pl-4 placeholder:text-[#BEBEBE] `}
                                placeholder="Enter value"
                                onChange={(e) => onUserEnterValueChange(row.index, e.target.value, dataType)}
                                defaultValue={row.original?.user_defined_value}
                                // value={value}
                            />
                        } else if (dataType == "BOOLEAN") {
                            return <DropDown
                                dropDownOptions={row.original?.boolean_values?.map(val => ({ value: `${val}`, label: `${val}` }))}
                                placeholder={'Select value'}
                                name={'usedCategory'}
                                ordinary={true}
                                border={'1px solid #E0DCD7'}
                                disabled={false}
                                customTags={false}
                                value={value || undefined}
                                onOptionChange={(value) => onUserEnterValueChange(row.index, value, dataType)}
                            />
                        } else if (dataType == "DATE") {
                            return <DatePicker
                                value={value ? dayjs(value, 'DD-MMM-YYYY') : undefined}
                                onBlur={formik.handleBlur}
                                showSearch={false}
                                allowClear={false}
                                size="small"
                                className="custom-select-height text-[11px] pl-[0.5rem] custom-disabled-datepicker placeholder:text-[#242B3B]"
                                style={{
                                    width: "100%",
                                    border: " 1px solid #E0DCD7",
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    backgroundColor: '#ffffff',
                                }}
                                placeholder="Select date"
                                onChange={(date) => {
                                    const val = dayjs(date).format('YYYY-MM-DD')
                                    onUserEnterValueChange(row.index, val, dataType)
                                }
                                }
                                format={"YYYY-MM-DD"}
                                suffixIcon={<CalenderIcon color="#707070" className={"xl:w-[15px] xl:h-[15px] w-[12px] h-[12px]"} />}
                            />
                        } else {
                            return <input
                                className={`place text-[12px] 2xl:text-[13px] h-full !border-[1px] border-[#E0DCD7] w-full rounded  2xl:h-[2.2rem]  focus:outline-none pl-4 placeholder:text-[#BEBEBE] `}
                                placeholder="Enter value"
                                onChange={(e) => onUserEnterValueChange(row.index, e.target.value, dataType)}
                                defaultValue={row.original?.user_defined_value}
                                value={value}
                            />
                        }
                    }


                }),
            ]
        } else {
            return [
                columnHelper.accessor("exception_analysis_method", {
                    header: "Exception Analysis",
                    mergeCells: true,
                    // cell :({row}) =>{
                    //     return<>
                    //     <div className='flex'>
                    //     <p className='!text-[#4339A8]'>{currentNodeData?.imputationUserDefinedTableData?.userDefinedDatas[0]?.exception_analysis_method}</p>
                    //     {currentNodeData?.imputationUserDefinedTableData?.isEdit == true
                    //             ?
                    //             <div className={`flex gap-1 items-center mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={onEditClick}>
                    //             <img alt='' src={editicon} className='w-4 h-4' />
                    //         </div>
                    //             :""} 
                    //     </div></>
                    // }
                }),
                columnHelper.accessor("column_name", {
                    header: "Destination Column",
                }),
                columnHelper.accessor("user_defined_value", {
                    header: "User Specified Value",
                }),
                columnHelper.accessor("no_of_modified_values", {
                    header: "No. of modified values",
                }),
                columnHelper.accessor("percentage_of_modified_values", {
                    header: "Percentage of modified values",
                    thClassName: "w-[200px]",
                    cell: ({ row }) => (<span>{row.original?.percentage_of_modified_values ? row.original?.percentage_of_modified_values + " %"  : '-'}</span>)

                }),


                columnHelper.display({
                    id: "Actions",
                    header: "Actions",
                    thClassName: "w-[120px]",
                    cell: ({ row }) => {
                        return <div className='space-x-4 flex items-center justify-between'>
                            <ColumnView
                                category_id={currentNodeData?.nodeCategoryId}
                                column_name={row.original.column_name}
                                ecl_node_id={currentEclNodeId}
                                workflow_id={currentWorkflowId}
                                parent_node_id={currentNodeData?.parent_node_id}
                                disabled={row.original.no_of_modified_values == 0}

                            />
                            <ColumnDownload
                                category_id={currentNodeData?.nodeCategoryId}
                                column_name={row.original.column_name}
                                ecl_node_id={currentEclNodeId}
                                workflow_id={currentWorkflowId}
                                parent_node_id={currentNodeData?.parent_node_id}
                                disabled={row.original.no_of_modified_values == 0}

                            />
                        </div>
                    }

                }),
            ]
        }

    }, [isEditMode])





    const summaryTableData = useMemo(() => {
        return currentNodeData?.imputationUserDefinedTableData?.userDefinedDatas ?? []
    }, [currentNodeData?.imputationUserDefinedTableData?.userDefinedDatas]);

    console.log({ summaryTableData, formik });


    useEffect(() => {
        dispatch(getMergeSummaryFileCreation({
            workflow_id: currentWorkflowId,
            ecl_node_id: currentEclNodeId,
        })).then(res => {
            if (res.type == `${getMergeSummaryFileCreation.fulfilled}`) {
                refreshDataTable()
                dispatch(imputationUserDefinedDetail({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": currentNodeData?.nodeCategoryId,
                    "parent_node_id": currentNodeData?.parent_node_id,
                }))
            }
        })
    }, [])



    const onProceedClick = async () => {
        if (currentNodeData?.node_status == 'Completed') {
            return dispatch(openModalBuilder())
        }

        const requestFormData = getCalculationFormData({
            client_branch_level_id: location?.state?.levelTwoCardId,
            isFrom: "DataImputationUserDefined",
            category_id: currentNodeData?.nodeCategoryId,
            node_id: currentEclNodeId,
            state: reduxState,
            nodeStatus: 'Completed'
        })

        dispatch(imputationProceed({
            requestFormData,

        }))

            .then(res => {
                if (res.type == `${imputationProceed.fulfilled}`) {
                    setIsEditMode(false)
                    refreshDataTable()
                    dispatch(imputationUserDefinedDetail({
                        "workflow_id": currentWorkflowId,
                        "ecl_node_id": currentEclNodeId,
                        "category_id": currentNodeData?.nodeCategoryId,
                        "parent_node_id": currentNodeData?.parent_node_id,
                    }))
                }
            })

    }
    const onSave = async () => {
        const request = {
            "ecl_workflow_id": currentWorkflowId,
            "ecl_node_id": currentEclNodeId,
            "ecl_category_id": currentNodeData?.nodeCategoryId,
            "parent_node_id": currentNodeData?.parent_node_id,
            "userDefinedDatas": currentNodeData?.imputationUserDefinedTableData?.userDefinedDatas?.map((data, index) => ({
                "column_name": data?.column_name,
                "user_defined_value": formik.values.userEnterValue[index]
            }))
        }
        const response = await saveImputationUserDefined(request)
        if (response.data.status = 200) {
            setIsEditMode(false)
            refreshDataTable()
            dispatch(imputationUserDefinedDetail({
                "workflow_id": currentWorkflowId,
                "ecl_node_id": currentEclNodeId,
                "category_id": currentNodeData?.nodeCategoryId,
                "parent_node_id": currentNodeData?.parent_node_id,
            }))

        }
    }

    const renderTable = () => {
        if (isDataLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        const disableSave = formik?.values?.userEnterValue?.filter(data => !data)?.length > 0 || summaryTableData?.length == 0
        if (currentNodeData?.nodeCategoryId) {
            return <>
                <div className={`mt-5`}>
                    {isEditMode
                        ? <div className='flex gap-3 items-center py-2'>
                                <button className='text-white bg-[#F7BE7E] py-0.5 px-2 text-[12px] rounded'>Data category</button>
                                <p className='font-semibold text-[12px]'>{currentNodeData?.imputationUserDefinedTableData?.categoryName}</p>
                        </div>
                        : <div className='2xl:py-3 py-1.5 px-2 bg-[#4339A8] grid grid-cols-3 items-center'>
                            <div className='col-span-1 flex gap-3 items-center'>
                                <button className='text-[#ffffff]  py-0.5 px-2 text-[12px] bg-[#FFC048] rounded-[4px] whitespace-nowrap'>Data category</button>
                                <p className='text-[#ffffff] font-semibold text-[12px] whitespace-nowrap'>{currentNodeData?.imputationUserDefinedTableData?.categoryName}</p>
                            </div>
                            <h2 className='col-span-1 text-white text-[12px] font-semibold text-center w-full'>Calculated output</h2>
                            {currentNodeData?.node_status == 'Completed' ? null
                            : <div className={`col-span-1 flex justify-end gap-1 items-center mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={() => setIsEditMode(true)}>
                                <img alt='' src={EditIcon} className='w-4 h-4' />
                                <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
                            </div>}
                        </div>}
                </div>
                <div className={`max-h-[200px] overflow-y-auto thin-scrollbar ${isEditMode ? "" : ""}`}>
                    <SummaryTable
                        key={""}
                        columns={summaryColumns}
                        data={summaryTableData}
                        thClassName={` ${isEditMode ? "!bg-[#4339A8]" : ""} `}
                        tdClassName={`${isEditMode ? "p-4" : ""}`}
                    />
                </div>
                {isEditMode
                    ? <div className='flex justify-center my-4'>
                        <Button
                            bgColor='#42B2AC'
                            borderType=""
                            disabled={disableSave}
                            label={'Save'}
                            color={'#ffffff'}
                            onClick={onSave}
                            size={'md'} />
                    </div>
                    : ""}
            </>
        }

    }


    const renderResultTable = () => {

        if (!currentNodeData?.nodeCategoryId) return

        if (isDataLoading || isMergeSummaryLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        const mergeSummaryTableData = summaryData

        console.log({ summaryData });

        return <div className='mt-5'>

            <div className='flex justify-end items-center'>
                {isExportLoading
                    ? <img src={downloadingIcon} className='w-6 mr-2' />
                    : <img alt='' src={XlsIcon} className=' mb-2 mr-2 object-cover cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
            </div>

            <DataTable
                columns={dataTableColumns}
                data={mergeSummaryTableData?.columnDatas ?? []}
                onTableStateChange={onTableStateChange}
                totalCount={mergeSummaryTableData?.totalRecords}
                isOptionsDisabled={location?.state?.isfromHistory}
                getFilterOptions={getFilterOptions}
                tableState={{
                    pagination: {
                        pageIndex: tableState.pageIndex,
                        pageSize: tableState.pageSize,
                    },
                    sorting: tableState?.sortBy ? [
                        {
                            id: tableState?.sortBy,
                            desc: tableState?.sortOrder === "desc"
                        }
                    ] : [],
                    columnFilters: tableState.columnFilters
                        ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                        : [],
                    columnPinning: tableState.columnPinning,
                    columnVisibility: tableState?.columnVisibility,
                }}
            />
            {currentNodeData?.imputationUserDefinedTableData?.isEdit == false ? null :
                <div className={`float-right 2xl:my-6 my-3 ${location?.state?.isfromHistory || currentNodeData?.node_status == 'Completed' ? 'hidden' : 'block'}`}>
                    <ProceedButton
                        onClick={onProceedClick}
                    />                    

                </div>
            }

        </div>
    }

    return (
        filesMerging || isCalculating
            ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            : <div className='my-2'>
                {renderTable()}
                {renderResultTable()}
            </div>
    )
}

export default ValueSpecifiedByUser