import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States

import { CountrysList, CurrencyList} from "../../Helpers/ApiHelpers";
import { COUNTRY_LIST, COUNTRY_LIST_FETCH_SUCCESS, CURRENCY_LIST } from "./ActionType";
import { countryApiError, countryListFetchSuccess, currencyListFetchSuccess ,currencyError} from "./Action";



function* countryList() {
    
  try {
    const response = yield call(CountrysList)
  // console.log(response,'response for countries');
    if (response.status === 200) {
      if (response.data.status === 200) {
        yield put(countryListFetchSuccess(response?.data,COUNTRY_LIST_FETCH_SUCCESS))
      }      
    }else {
      yield put(countryApiError(response));
    }
  } catch (error) {
    console.log('API ERROR:',error);
    yield put(countryApiError(error));
  }
}

function* currencyList() {
    
  try {
    const response = yield call(CurrencyList)
  // console.log(response,'response for currencies');
    if (response.status === 200) {
      if (response.data.status === 200) {
        yield put(currencyListFetchSuccess(response?.data))
      }      
    }else {
      yield put(currencyError(response));
    }
  } catch (error) {
    console.log('API ERROR:',error);
    yield put(currencyError(error));
  }
}

function* CommonSaga() {
  yield takeEvery(COUNTRY_LIST, countryList);  
  yield takeEvery(CURRENCY_LIST,currencyList);  
    
}

export default CommonSaga;
