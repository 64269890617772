import React from 'react'
import WorkflowMainHeader from './WorkflowMainHeader'
import { useFormik } from 'formik'
import WorkflowNodes from './WorkflowNodes'
import { useSelector } from 'react-redux'

function WorkflowMain() {

    const step = useSelector((state) => state?.Workflow?.currentStep)
    const showMenu = useSelector((state) => state?.Workflow?.ui?.showMenu)


    return (
        <div className={`transition-all duration-700 pl-2 ${step > 3 ? 'block' : 'hidden'} ${showMenu ? "w-[78%]" : "w-full"}`}>
            <WorkflowMainHeader />
            <WorkflowNodes />
        </div>
    )
}

export default WorkflowMain