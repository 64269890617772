export const parseCategotyValueLabel = (dataList = []) => {
    return dataList?.map(data => ({
        label: data?.category_name,
        value: data?.category_id,
        disabled: data?.is_disabled
    })) ?? []
}


/**
 * The function `validateNumber` checks if a given string consists of only numeric characters.
 * @param str - The `str` parameter is a string that represents a number that needs to be validated.
 */
export const validateNumber = (str) => str === "" || /^[0-9]*$/.test(str)


/**
 * The function checks if a given string represents a valid positive or negative number.
 * @param str - The `str` parameter in the `isValidPositiveOrNegativeNumber` function represents the
 * input string that you want to check for being a valid positive or negative number. This function
 * checks if the input string is either an empty string or a valid positive or negative number format.
 */
export const isValidPositiveOrNegativeNumber = (str) => str === "" || /^-?\d+(\.\d+)?$/.test(str)


/**
 * The `debounce` function in JavaScript delays the execution of a function until a specified amount of
 * time has passed without the function being called again.
 * @param func - The `func` parameter is the function that you want to debounce, meaning that you want
 * to limit the rate at which the function can fire.
 * @param wait - The `wait` parameter in the `debounce` function represents the amount of time in
 * milliseconds to wait before invoking the `func` function. This parameter determines the delay
 * between the last invocation of the debounced function and when `func` is actually called.
 * @returns A function is being returned.
 */
export function debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }


/**
 * The `reorderArray` function sorts an array of objects based on a specified key within each object.
 * @param array - The `array` parameter in the `reorderArray` function is the array that you want to
 * reorder based on a specific key. This function takes an array of objects as input and reorders the
 * array based on the values of the specified key within each object.
 * @param key - The `reorderArray` function takes an array of objects and a key as parameters. The key
 * is used to specify the property of the objects by which the array should be reordered. The function
 * sorts the array of objects based on the values of the specified key in each object.
 * @returns The `reorderArray` function is returning the input `array` sorted based on the values of
 * the property specified by the `key` parameter.
 */
export const reorderArray = (array, key) => {
  return array.sort((a, b) => {
    if (a[key] < b[key]) {
      return -1;
    }
    if (a[key] > b[key]) {
      return 1;
    }
    return 0;
  });
};




/**
 * The `removeQuotes` function removes single and double quotes from a given string.
 * @param str - A string that may contain single or double quotes that need to be removed.
 * @returns The `removeQuotes` function is being returned.
 */
export function removeQuotes(str) {
  return str.replace(/['"]/g, '');
}





/**
 * The `capitalizeString` function capitalizes the first letter of a string and converts the rest of
 * the string to lowercase.
 * @param [string] - The `capitalizeString` function takes a string as an argument and returns the
 * string with the first letter capitalized and the rest of the letters in lowercase. If no string is
 * provided, it defaults to an empty string.
 * @returns The function `capitalizeString` takes a string as an argument, capitalizes the first
 * letter, and converts the rest of the string to lowercase. The capitalized string is then returned.
 */
export function capitalizeString(string = "") {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}


