import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    API_ERROR,    
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_API_ERROR,        
    LOGOUT_USER,
    FORGOT_PASSWORD_SUCCESS,
    REGISTER_EMAIL_EXIST,
    REGISTER_SUCCESS,
    RESET_LOGIN_ERROR,
    RESET_REGISRER_ERROR,
    FORGOT_PASSWORD_ERROR,
  } from "./ActionType";
  
  const initialState = {
    error: "",
    loading: false,
  };
  
  const login = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            state = {
            ...state,
            loading: true,
            isLoginIn:action.payload.data,
            };
            break;
        case LOGIN_ERROR:
            state = {
            ...state,
            LoginStatus:action.payload.data,
            loading: false,
            };
            break;
        case LOGIN_SUCCESS:
            state = {
            ...state,
            LoginData:action.payload.data,
            loading: false,
            };    
            break;
        case API_ERROR:
            state = {
            ...state,
            LoginError: action.payload,
            };
            break;
            case RESET_LOGIN_ERROR:
            state = {
            ...state,
            LoginError: null,
            };
            break;
        case LOGOUT_USER:
            state = {
            ...state,
            error: action.payload.data,
            loading: false,
            };
        break;
        case FORGOT_PASSWORD_SUCCESS:
                state = {
                    ...state,
                    forgotPasswordData:action.payload.data,
                    loading: false,
                };
        break;
        case FORGOT_PASSWORD_ERROR:
                state = {
                    ...state,
                    forgotPasswordData:null,
                    loading: false,
                };
        break;
        case REGISTER_EMAIL_EXIST:
        state = {
            ...state,
            error:action.payload.user,
        };
        break;
        case RESET_REGISRER_ERROR:
        state = {
            ...state,
            error:null,
        };
        break;
        case REGISTER_SUCCESS:
        state = {
            ...state,
            registerData:action.payload,
        };
        break;
                       
        case RESET_PASSWORD:
                state = {
                    ...state,
                    loading: true,
                };
                break;
        case RESET_PASSWORD_SUCCESS:
            // console.log('RESET_PASSWORD_SUCCESS',action.payload);
                state = {
                    ...state,
                    resetPasswordData:action.payload,
                    loading: false,
                };
                break;
        case RESET_PASSWORD_API_ERROR:
                state = {
                ...state,
                resetPasswordError: action.payload.data,
                loading: false,
                };
                break;
        default:
            state = { ...state };
            break;
        }
    return state;
  };
  
  export default login;
  