import React, { useEffect } from 'react'
import OverflowTooltip from '../../../Components/ToolTip/OverFlowToolTip';
import DropDown from '../../../Components/DropDown/DropDown';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { getCalculationFormData, getPositionAnalysisCategoryList, openModalBuilder, positionAnalysisCategorySave, selectCurrentNodeData, selectCurrentNodeIds } from '../../../Store/Workflow/WorkflowSlice';
import DataTableSkeleton from '../../../Components/Skeleton/DataTableSkeleton';
import Button from '../../../Components/Button';
import PositionAnalysisTable from '../../../Components/PositionAnalysis/PositionAnalysisTable';
import { positionAnalysisTableHeader } from '../../../Utils/Constants';
import ProceedButton from '../../../Components/CommonComponents/ProceedButton';

function PositionAnalysis() {
    const location = useLocation()
    const positionAnalysisCategoryList = useSelector(state => state?.Workflow?.positionAnalysisCategory)
    const isDataLoading = useSelector(state => state?.Workflow?.ui?.isDataLoading || state?.Workflow?.ui?.isPositionAnalysisListLoading)
    const isCalculating = useSelector(state => state?.Workflow?.ui?.isCalculating)
    const reduxState = useSelector(state => state)
    const { currentEclNodeId, currentWorkflowId, currentWorkflowNodeId } = useSelector(selectCurrentNodeIds)
    const currentNodeData = useSelector(selectCurrentNodeData)
    const mergeSummaryTableData = currentNodeData?.mergedResults


    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            category: currentNodeData?.nodeCategoryId || (mergeSummaryTableData ? mergeSummaryTableData[0]?.CategoryId : undefined)
        },
        enableReinitialize: true,
    })


    const onCategoryChange = (value) => {
        formik.setFieldValue("category", value)
    }

    useEffect(() => {
        dispatch(getPositionAnalysisCategoryList({
            workflowId: currentWorkflowId,
            eclNodeId: currentEclNodeId,
            categoryId: formik?.values?.category,
            currentWorkflowNodeId
        }))
    }, [])



    const onSave = () => {
        const requestFormData = getCalculationFormData({
            client_branch_level_id: location?.state?.levelTwoCardId,
            isFrom: "postionAnalysis",
            category_id: formik?.values?.category,
            node_id: currentEclNodeId,
            state: reduxState
        })

        dispatch(positionAnalysisCategorySave({
            workflowId: currentWorkflowId,
            eclNodeId: currentEclNodeId,
            categoryId: formik?.values?.category,
            requestFormData,
        }))

    }

    return (
        isDataLoading
            ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={false} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            : <div className='my-2'>
                <div className='flex gap-5'>
                <DropDown
                    dropDownOptions={positionAnalysisCategoryList}
                    placeholder={'Select data category'}
                    name={'usedCategory'}
                    ordinary={true}
                    disabled={location?.state?.isfromHistory || isCalculating || mergeSummaryTableData?.length > 0}
                    customTags={false}
                    value={formik.values?.category}
                    onOptionChange={onCategoryChange}
                />
                <Button
                    bgColor='#42B2AC'
                    borderType=""
                        disabled={!formik.values?.category || location?.state?.isfromHistory || isCalculating || mergeSummaryTableData?.length > 0}
                    label={'Save'}
                    color={'#ffffff'}
                    onClick={onSave}
                    size={'md'} />
            </div>
                {isCalculating
                    ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={false} showActionableButtons={false} from='mergeSummary' isDownload={false} />
                    : <div className=''>
                    {
                        mergeSummaryTableData?.length > 0 ?
                            <div className='overflow-y-auto thin-scrollbar'>
                                <div className='p-2 text-[13px] space-y-3 mt-4 rounded overflow-y-auto thin-scrollbar border border-[#D0CECE]'>
                                    <div className="grid grid-cols-3">
                                        <div className='col-span-1'>
                                            <div className='grid grid-cols-5 gap-3'>
                                                <p className=' col-span-2 whitespace-nowrap'>Data category</p>
                                                <p className='col-span-3 font-semibold'><OverflowTooltip text={mergeSummaryTableData[0].CategoryName} /></p>
                                            </div>
                                        </div>
                                        <div className='col-span-1'>
                                            <div className='grid grid-cols-5 gap-3'>
                                                <p className=' col-span-2 whitespace-nowrap'>No.of rows</p>
                                                <p className='col-span-3 font-semibold'><OverflowTooltip text={mergeSummaryTableData[0].totalRecords} /></p>
                                            </div>
                                        </div>
                                        <div className='col-span-1'>
                                            <div className='grid grid-cols-5 gap-3'>
                                                <p className=' col-span-2 whitespace-nowrap'>No.of columns</p>
                                                <p className='col-span-3 font-semibold'><OverflowTooltip text={mergeSummaryTableData[0].totalColumns} /></p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='mt-2 w-full'>
                                <div className='w-full overflow-x-hidden overflow-y-auto thin-scrollbar'>
                                {mergeSummaryTableData[0]?.columnDatas &&
                                            <PositionAnalysisTable
                                                initialData={mergeSummaryTableData[0]}
                                                category_id={mergeSummaryTableData[0]?.CategoryId}
                                                ecl_node_id={currentEclNodeId}
                                                workflow_id={currentWorkflowId}
                                                // request={mergeSummaryTableRequest}
                                                current_headers={mergeSummaryTableData[0]?.headers}
                                                tableHeaders={positionAnalysisTableHeader}
                                                isDownloadxls={true}
                                                totalColumns={mergeSummaryTableData[0]?.totalColumns}
                                                totalRecords={mergeSummaryTableData[0]?.totalRecords}
                                                summary={mergeSummaryTableData[0]}
                                                isFor={'positionAnalysis'}
                                                isOptionsDisabled={location?.state?.isfromHistory}
                                            />}
                                    </div>
                                </div>
                                <div className={`float-right 2xl:my-6 my-3 ${location?.state?.isfromHistory ? 'hidden':'block'}`}>
                                        <ProceedButton
                                            onClick={() => dispatch(openModalBuilder())}
                                        />
                                        {/* <Button
                                    bgColor="#70AD47"
                                    size="lg"
                                    label={'Proceed'}
                                    color={'#FFFFFF'}
                                    onClick={() => dispatch(openModalBuilder())}
                                /> */}
                                
                                </div>
                            </div> : <></>
                        // : 
                        // categoryId && mergeSummaryTableData?.length <= 0 && positionAnalysisLoader ?
                        //     <div className='mt-2'>
                        //         <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={false} showActionableButtons={false} from='mergeSummary' isDownload={false} />
                        //     </div>
                        //     : <></>

                    }
                    </div>}
            
        </div>
    )
}

export default PositionAnalysis