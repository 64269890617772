const RouteSegments = {
    HOME : "/",
    REGISTER:'/registration',
    FORGOTPASSWORD:'/forgot-password',
    RESETPASSWORD:'/reset-password',
    CLIENTPAGE:'/clients',
    NEW_CLIENT:'/new-client',
    TABLE:'/table',
    CUSTOM_TABLE:'/custom-table',
    CLIENT_COUNTRIES:'/client-countries',
    LEVEL_ONE:'/client-levelone',
    LEVEL_TWO:'/client-leveltwo',
    CALCULATION:'/calculation',
    MANAGELEVEL:'/manage-level',
    WIZARD_LANDING:'/wizard-landing',
    NEW_CALCULATION:'/new-calculation',
    MANAGE_CATEGORY:'/manage-category',
    MANAGE_NULL_VALUES:'/manage-nullvalues',
    METHOD:"/method"


}

export default RouteSegments;