import React from 'react'

const Footer = () => {
    return (
        <footer className='fixed inset-x-0 bottom-0  w-full'>
            <div className=" w-full ">
                <div className="px-5 2xl:py-3 py-1.5 bg-[#2B144D] flex items-center justify-between">
                    <span className="xl:text-[13px] text-[11px] text-white text-center">2024 © Grant Thornton Bharat LLP. All rights reserved.
                    </span>

                </div>
            </div>
        </footer>

    )
}
export default Footer;