import React from 'react'

const CalenderIcon = ({color,height,width,className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 22 20.528">
            <g id="Group_91" data-name="Group 91" transform="translate(572.839 -168.989)">
                <path id="Path_63" data-name="Path 63" d="M-569.92,200.745c0,.527,0,1.023,0,1.518a1.482,1.482,0,0,0,1.381,1.5,1.474,1.474,0,0,0,1.549-1.239,8.326,8.326,0,0,0,.02-1.1c0-.213,0-.427,0-.658h3.652c0,.466,0,.937,0,1.407a2.122,2.122,0,0,0,.046.475,1.48,1.48,0,0,0,1.537,1.112,1.482,1.482,0,0,0,1.365-1.464c0-.5,0-1.008,0-1.53h3.652c0,.3-.006.6,0,.9a5.357,5.357,0,0,0,.052.979,1.455,1.455,0,0,0,1.53,1.115,1.481,1.481,0,0,0,1.367-1.463c0-.5,0-1.008,0-1.511a2.283,2.283,0,0,1,2.915,2.2c.014,2.3,0,4.6,0,6.9q0,3.817,0,7.634a2.188,2.188,0,0,1-1.669,2.212,2.546,2.546,0,0,1-.611.071q-8.723.005-17.445,0a2.2,2.2,0,0,1-2.268-2.275q0-7.256,0-14.511a2.2,2.2,0,0,1,2.27-2.273C-570.362,200.744-570.156,200.745-569.92,200.745Zm17.626,5.874h-19.093v.265q0,4.952,0,9.9a.745.745,0,0,0,.832.833q8.7,0,17.4.008a.833.833,0,0,0,.87-.88c-.017-3.286-.009-6.572-.009-9.857Z" transform="translate(0 -30.289)" fill="#707070" />
                <path id="Path_64" data-name="Path 64" d="M-491.4,170.818c0,.344,0,.687,0,1.031a.744.744,0,0,1-.717.791.745.745,0,0,1-.732-.8q0-1.031,0-2.061a.74.74,0,0,1,.716-.79.744.744,0,0,1,.733.8C-491.395,170.13-491.4,170.474-491.4,170.818Z" transform="translate(-76.321 0)" fill={color} />
                <path id="Path_65" data-name="Path 65" d="M-348.846,170.818c0-.351,0-.7,0-1.053a.737.737,0,0,1,.732-.775.738.738,0,0,1,.717.768q.008,1.053,0,2.107a.739.739,0,0,1-.732.775.742.742,0,0,1-.717-.769c0-.176,0-.351,0-.527S-348.846,170.994-348.846,170.818Z" transform="translate(-213.72 -0.002)" fill={color} />
                <path id="Path_66" data-name="Path 66" d="M-204.845,170.806c0-.344,0-.687,0-1.031a.739.739,0,0,1,.724-.783.741.741,0,0,1,.725.783q.006,1.042,0,2.084a.739.739,0,0,1-.724.783.742.742,0,0,1-.725-.783C-204.848,171.508-204.845,171.157-204.845,170.806Z" transform="translate(-351.118 -0.003)" fill={color} />
                <path id="Path_67" data-name="Path 67" d="M-411.392,360.943c.336,0,.672,0,1.008,0a.379.379,0,0,1,.427.422c0,.45.006.9,0,1.351a.374.374,0,0,1-.416.409q-1.042.007-2.084,0a.374.374,0,0,1-.415-.41c-.005-.458-.006-.916,0-1.374a.369.369,0,0,1,.4-.4C-412.11,360.939-411.751,360.944-411.392,360.943Z" transform="translate(-152.626 -183.151)" fill={color} />
                <path id="Path_68" data-name="Path 68" d="M-315.414,360.943c.344,0,.687,0,1.031,0a.378.378,0,0,1,.427.421q.007.676,0,1.351a.374.374,0,0,1-.415.41q-1.042.006-2.084,0a.373.373,0,0,1-.415-.41c-.005-.458-.006-.916,0-1.374a.368.368,0,0,1,.4-.4C-316.117,360.939-315.765,360.943-315.414,360.943Z" transform="translate(-244.225 -183.15)" fill={color} />
                <path id="Path_69" data-name="Path 69" d="M-219.381,360.948c.336,0,.672,0,1.008,0a.379.379,0,0,1,.423.426q.006.664,0,1.328a.378.378,0,0,1-.421.428q-1.042.006-2.084,0a.373.373,0,0,1-.411-.414q-.008-.676,0-1.351a.379.379,0,0,1,.432-.417C-220.084,360.945-219.733,360.948-219.381,360.948Z" transform="translate(-335.828 -183.155)" fill={color} />
                <path id="Path_70" data-name="Path 70" d="M-507.407,427.128c-.343,0-.687,0-1.03,0a.379.379,0,0,1-.424-.425q-.007-.664,0-1.328c0-.292.144-.429.442-.43q1.019,0,2.038,0a.383.383,0,0,1,.436.436q.005.653,0,1.305c0,.307-.141.439-.454.442C-506.736,427.13-507.071,427.128-507.407,427.128Z" transform="translate(-61.04 -244.215)" fill={color} />
                <path id="Path_71" data-name="Path 71" d="M-411.393,427.125c-.336,0-.672,0-1.008,0-.314,0-.453-.135-.455-.44q-.005-.653,0-1.305a.383.383,0,0,1,.434-.437q1.03-.005,2.061,0a.378.378,0,0,1,.421.428q.007.664,0,1.328a.38.38,0,0,1-.423.426C-410.706,427.129-411.05,427.125-411.393,427.125Z" transform="translate(-152.644 -244.213)" fill={color} />
                <path id="Path_72" data-name="Path 72" d="M-315.391,427.127c-.343,0-.687,0-1.03,0a.384.384,0,0,1-.432-.44q0-.653,0-1.305a.383.383,0,0,1,.434-.437q1.031,0,2.061,0a.378.378,0,0,1,.421.428q.007.664,0,1.328a.38.38,0,0,1-.423.426C-314.7,427.131-315.048,427.127-315.391,427.127Z" transform="translate(-244.244 -244.215)" fill={color} />
                <path id="Path_73" data-name="Path 73" d="M-219.406,424.95c.344,0,.687,0,1.031,0a.379.379,0,0,1,.426.423c0,.45.006.9,0,1.351a.374.374,0,0,1-.417.408q-1.042.006-2.084,0a.373.373,0,0,1-.414-.411q-.008-.676,0-1.351a.378.378,0,0,1,.428-.42C-220.094,424.947-219.75,424.95-219.406,424.95Z" transform="translate(-335.829 -244.223)" fill={color} />
                <path id="Path_74" data-name="Path 74" d="M-507.381,488.947c.328,0,.657,0,.985,0s.45.135.452.443q0,.653,0,1.305a.383.383,0,0,1-.438.434q-1.019,0-2.038,0c-.3,0-.438-.138-.44-.432q-.006-.664,0-1.328a.379.379,0,0,1,.426-.423C-508.083,488.944-507.732,488.947-507.381,488.947Z" transform="translate(-61.042 -305.285)" fill={color} />
                <path id="Path_75" data-name="Path 75" d="M-411.386,488.944c.336,0,.672,0,1.008,0a.384.384,0,0,1,.434.439q.005.653,0,1.305a.383.383,0,0,1-.434.438q-1.03.005-2.061,0a.378.378,0,0,1-.421-.427q-.006-.664,0-1.328a.379.379,0,0,1,.422-.427C-412.088,488.94-411.737,488.944-411.386,488.944Z" transform="translate(-152.639 -305.281)" fill={color} />
                <path id="Path_76" data-name="Path 76" d="M-315.4,491.134c-.328,0-.656,0-.985,0s-.464-.133-.466-.453q0-.641,0-1.282c0-.31.138-.447.447-.448q1.008,0,2.015,0c.313,0,.452.135.454.441q.005.652,0,1.305a.384.384,0,0,1-.436.437C-314.713,491.137-315.057,491.134-315.4,491.134Z" transform="translate(-244.247 -305.286)" fill={color} />
            </g>
        </svg>
    )
}

export default CalenderIcon
