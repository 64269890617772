import { call, put, takeEvery } from "redux-saga/effects";
//Libraries
import {toast } from "react-toastify";
// Login Redux States
import { LOGIN_SUCCESS, LOGIN_USER,LOGIN_ERROR, LOGOUT_USER, REGISTER_USER, FORGOTPASSWORD, FORGOT_PASSWORD_SUCCESS, RESET_PASSWORD, REGISTER_RESPONSE_DATACLEAR } from "./ActionType";
import { apiError, forgotPasswordSuccess, loginError, loginSuccess, registerEmailExist, registerSuccess, resetPasswordSuccess} from "./Action";
import { ForgotPassword, Login, Logout, Register, ResetPassword } from "../../Helpers/ApiHelpers";
import { loginLocalStorageKeys } from "../../Utils/Constants";
import RouteSegments from "../../Routes/RouteSegments";



function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(Login, {
      email: user.username ?? null,
      password: user.password,
    });
    if (response.status === 200) {
      if (response.data.status === 200) {
        localStorage.setItem(loginLocalStorageKeys.token, response.data.accessToken)
        localStorage.setItem(loginLocalStorageKeys.userName, response.data.userName)
        localStorage.setItem(loginLocalStorageKeys.loginId, response.data.userId)
        yield put(loginSuccess(response,LOGIN_SUCCESS));
        history(`${RouteSegments.CLIENTPAGE}`)   
      }
      else {
        yield put(loginError(response,LOGIN_ERROR));
      }
    }
    else if(response.status == 401){
      yield put(loginError(response,LOGIN_ERROR));

    }else {
      yield put(apiError(response));
    }
  } catch (error) {
    console.log('API ERROR:', error);
    yield put(apiError(error?.response?.data));
  }
}

function* logoutUser({ payload: { user, history,headers } }) {
  try {    
    const axiosConfig = {
      headers: {
        Authorization: `Bearer ${headers}`,
      },
    };

    // Make the API call with headers
    const response = yield call(Logout ,axiosConfig);

    if (response.status === 200) {
      if (response.data.status === 200) {
        localStorage.clear();
        history(`${RouteSegments.HOME}`)  
      }          
    }else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* registerUser({ payload: { user, history,headers } }) {
  try{
   
    const response = yield call(Register,{
      first_name: user.firstname,
      last_name:user.lastname,
      email: user.email ?? null,

    });

    if (response.status === 200) {
      if (response.data.status === 200) {
        yield put(registerSuccess(response.data))
        // history(`${RouteSegments.HOME}`)   
      }  
      else if(response.data.status == 205) {  
        yield put(registerEmailExist(response.data))
      }          
    }            
    else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* forgotPassword({ payload: { user, history,headers } }) {

  try{   
    const response = yield call(ForgotPassword,{
      email:user.username
    });
    if (response.status === 200) {
      if (response.data.status === 200) {
        yield put(forgotPasswordSuccess(response,FORGOT_PASSWORD_SUCCESS));
      }          
    }else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* resetPassword({ payload: { data, history} }) {

  try{   
    const response = yield call(ResetPassword,{
      token:data?.token,
      new_password:data?.newPassword,
      confirm_password:data?.confirmPassword
   
    });
    if (response.status === 200) {
      if (response.data.status === 200) {
        yield put(resetPasswordSuccess(response.data));
        // history(`${RouteSegments.HOME}`)   
      }          
    }else {
      yield put(apiError(response));
    }
  } catch (error) {
    yield put(apiError(error));
  }
}
function* registerDataClear(data) {
  try {    
    yield put(registerSuccess([]));
  } catch (error) {
    // Handle any potential errors
    console.error('Error clearing API response:', error);
  }
}

function* loginSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);  
  yield takeEvery(REGISTER_USER, registerUser); 
  yield takeEvery(FORGOTPASSWORD, forgotPassword);  
  yield takeEvery(RESET_PASSWORD, resetPassword);  
  yield takeEvery(REGISTER_RESPONSE_DATACLEAR, registerDataClear);  
     
}

export default loginSaga;
