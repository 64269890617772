import { ECL_CATEGORY_LIST } from '../../Helpers/EndPoints';
import {
    CHECK_WORKFLOW_SUCCESS,
    ECL_HISTORY_FETCH_SUCCESS,
    FILE_UPLOAD_SUMMARY_API_ERROR,
    FILE_UPLOAD_SUMMARY_SUCCESS,
    GATEGORY_LIST_FETCH_SUCCESS,
    GET_FILE_DETAILS_SUCCESS,
    MERGE_DATA_WORKFLOW_SUCCESS,
    MMODEL_BUILDER_LIST_API_ERROR,
    MODEL_BUILDER_LIST,
    MODEL_BUILDER_LIST_FETCH_SUCCESS,
    NODE_DETAILS_FETCH_SUCCESS,
    SET_IS_DATA_LOADING,
    SINGLE_FILE_UPLOAD_PERTICULAR_LIST_FETCH_SUCCESS,
    SINGLE_FILE_UPLOAD_SUCCESS,
    TABLE_UPDATION_SUCCESS
     
} from './ActionType'

const initalState = {    
    isLoading: true,
    detailsLoading:true,
    isDataLoading: false,
}



const ModelBuilder = (state = initalState, action) => {    
    switch (action.type) {
        case SET_IS_DATA_LOADING:
            return {
            ...state,
            isDataLoading: action.payload
            };
        case MODEL_BUILDER_LIST:
            state = {
            ...state,
            isLoading: false,
            };
        break;
        case MODEL_BUILDER_LIST_FETCH_SUCCESS:
            state = {
            ...state,
            isLoading: false,
            modelBuilder:action?.payload?.modelBuilderDatas
            };
        break;
        case ECL_HISTORY_FETCH_SUCCESS:
        state = {
        ...state,
        isLoading: false,
        eclHistoryList:action?.payload?.eclHistoryList
        };
        break;
        case NODE_DETAILS_FETCH_SUCCESS:
        state = {
        ...state,
        isLoading: false,
        detailsLoading:false,
        workflowNodes:action?.payload,
        nodeListData:action?.payload,
        };
        break;
        case SINGLE_FILE_UPLOAD_PERTICULAR_LIST_FETCH_SUCCESS:
        state = {
        ...state,
        isLoading: false,
        perticularNodeData:action?.payload,
        };
        break;
        case GATEGORY_LIST_FETCH_SUCCESS:
        state = {
        ...state,
        isLoading: false,
        categoryListData:action?.payload?.categoryList
        };  
        break;
        case SINGLE_FILE_UPLOAD_SUCCESS:
        state = {
        ...state,
        isLoading: false,
        singleUploadData:action?.payload
        };  
        break;   
        case FILE_UPLOAD_SUMMARY_SUCCESS:
        state = {
        ...state,
        isDataLoading: false,
        filesSummaryData:action?.payload
        }; 
        case FILE_UPLOAD_SUMMARY_API_ERROR :
        state = {
        ...state,
        isLoading: false,
        filesSummaryMessage:action?.payload
        };   
        break;  
        case CHECK_WORKFLOW_SUCCESS:
        state = {
        ...state,
        isLoading: false,
        reviewStatusData:action?.payload
        };   
        break;
        case MERGE_DATA_WORKFLOW_SUCCESS:
        state = {
        ...state,
        isLoading: false,
        mergeSummaryData:action?.payload
        }; 
        break;  
        case TABLE_UPDATION_SUCCESS:
        state = {
        ...state,
        isLoading: false,
        successMessage:action?.payload?.payload
        }; 
        case GET_FILE_DETAILS_SUCCESS:
        state = {
        ...state,
        isLoading: false,
        fileDetails:action?.payload
        }; 
        break;  
        default:
            state = { ...state };
            break;
        }
    return state;
};

export default ModelBuilder;