import React, { useEffect, useState } from 'react';
//Components
import Button from '../../Components/Button';
import SortDropDown from '../../Components/ClientPage/SortDropDown';
import LeftArrow from '../../Components/CommonIcons/LeftArrow';
import NoDataFound from '../../Components/ClientPage/NoDataFound';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteSegments from '../../Routes/RouteSegments';
import { useDispatch, useSelector } from 'react-redux';
import { clientsCountryLevelTwoList, getCategoryList, getClearLevelTwoData, levelNameUpdate } from '../../Store/action';
import CardSkeleton from '../../Components/Skeleton/CardSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import PageCard from '../../Components/ClientPage/Pagecard'
import loadMore from '../../Images/loadmore.webp'
import { Baseurl, Constants } from '../../Utils/Constants';
import SearchInput from '../../Components/ClientPage/SearchInput';
//packages
import axios from 'axios';
import { toast } from 'react-toastify';
//images
import editIcon from '../../Images/edit.svg'
import BreadCrumbArrow from '../../Images/Group 166.svg'
import OverflowTooltip from '../../Components/ToolTip/OverFlowToolTip';
import ConfirmPopup from '../../Components/PopUp/ConfirmPopUp';
import { MANAGAE_LEVEL_REMOVE } from '../../Helpers/EndPoints';
import AlertIcon from '../../Images/alert.svg'



let vari = [];

const LevelTwo = ({dynamicHeight}) => {
  const location = useLocation();
  const navigate= useNavigate()
  const [sortBy,setSortBy] = useState('created_at:desc');
  const [currentListCount,setCurrentListCount] = useState(6)
  const [countrySort,setCountrySort] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [selected,setSelected] = useState('Recently added');
  const [isNext, setIsNext] = useState(false) ;
  const [showLoader, setShowLoader] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [levelTwoLabel,setLevelTwoLable] = useState('Level 2');
  const [levelEditShow,setShowLevelEdit] = useState(false);
  const [isVerticalScrollbarVisible, setIsVerticalScrollbarVisible] = useState(false);
  const [undoChanges, setUndoChanges] = useState(false);
  const [deletableCardDetail,setDeletableCardDetail] = useState({})


  const newCalculation =()=>{
    navigate(RouteSegments.MANAGELEVEL,{ state: {
      breadCrumbClientName:location?.state?.breadCrumbClientName,
      breadCrumbCountryName:location?.state?.breadCrumbCountryName,
      clientId:location?.state?.data?.client_id,
      client_country_id:ClientCountryId,
      parent_level_id:location?.state?.data?.id,
      level:2,
      level_name:levelTwoLabel,
      data:location?.state,
      breadCrumbLevelName:location?.state?.labelName
    } })
  }
  const dispatch = useDispatch()


  const levelTwoListCallBack = ()=>{
    dispatch(clientsCountryLevelTwoList(sortBy,Constants.listSize,searchTerm,location?.state,countrySort)) //sort,record,key,id
  }

  const { ClientLevelTwoList, isLoading,hasMore,ClientLevelName,UpdatedLevelName,ClientCountryId,ClientLevelTwoData} = useSelector(state => {
    return {
        ClientLevelTwoList: state?.Clients?.clientLevelTwoLists,
        isLoading: state?.Clients?.isLoading,
        hasMore:state?.Clients?.hasMore,
        ClientLevelName:state?.Clients?.clientLevelName,
        UpdatedLevelName:state?.Clients?.updatedLevelName,
        ClientCountryId:state?.Clients?.clientCountryId,
        ClientLevelTwoData: state?.Clients?.clientLevelTwoData

    }
})


const onNext = () => {        
  // Increase currentListCount by 6
  setIsNext(true)
  let newCount = currentListCount + 6;
  setCurrentListCount(newCount);

  // Dispatch action with the updated count
  dispatch(clientsCountryLevelTwoList(sortBy,newCount,searchTerm,location?.state,countrySort));        
};


useEffect(()=>{        
  if(searchTerm?.length > 0){
      if(searchTerm?.length ==1){
          setCurrentListCount(Constants.listSize) 
          dispatch(clientsCountryLevelTwoList(sortBy,Constants.listSize,searchTerm,location?.state,countrySort));           
      }
      else{
          dispatch(clientsCountryLevelTwoList(sortBy,currentListCount,searchTerm,location?.state,countrySort));
      }
  } 
  else if(searchTerm?.length ==0){
      if(countrySort){
          dispatch(clientsCountryLevelTwoList(null,currentListCount,searchTerm,location?.state,'desc'))  
      }
      else{
        dispatch(clientsCountryLevelTwoList(sortBy,Constants.listSize,searchTerm,location?.state,null));           
      }
  }
  },[searchTerm])

useEffect(()=>{    
  if(isNext){
      ClientLevelTwoList?.map((client,i)=>{
          vari.push(client)
          setCountriesList(vari)
      })
      setIsNext(false)
  }         
  else{
      vari = []
      ClientLevelTwoList?.map((client,i)=>{
          vari.push(client)
          setCountriesList(vari)
      })
  }               
  
},[ClientLevelTwoList]) 

const updateLevelName =(e)=>{
    e.preventDefault(); // Prevent the default form submission behavior
    const countryId = location?.state?.client_country_id;
    const level = 2;
    const parrent =location?.state?.data?.id;
    const name=levelTwoLabel;
    dispatch(levelNameUpdate(countryId,level,parrent,name))
    setShowLevelEdit(false)

}

useEffect(()=>{
    if(UpdatedLevelName){
        setLevelTwoLable(UpdatedLevelName)
        setShowLevelEdit(false)
    }
},[UpdatedLevelName])

useEffect(() => {
    const handleResize = () => {
      setIsVerticalScrollbarVisible(document.documentElement.scrollHeight > window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Initial check

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const onLevelTwoCardClick = (client)=>{
    axios(`${Baseurl+'/ecl'+`/${location?.state?.data?.client_id}`+`/${client?.id}`+'/manageCategory/list'}`, {
      method: "GET",     
    })
      .then((response) => {
        if (response?.data?.status == 200) {
          if(response?.data?.categoryList?.length > 0){
              dispatch(getClearLevelTwoData());
              navigate(RouteSegments.WIZARD_LANDING ,{ state: 
              {
                client_country_id:ClientCountryId,
                clientId:location?.state?.data?.client_id,
                breadCrumbClientName:location?.state?.breadCrumbClientName,
                breadCrumbCountryName:location?.state?.breadCrumbCountryName,
                breadCrumbLevelOne:location?.state?.labelName,
                breadCrumbLevelTwo:levelTwoLabel,
                levelTwoCategory: client?.category_name,
                data:location?.state?.data,
                levelTwoCardId:client?.id,
              }})
            }
          else{
            toast.warning('Category must for making a calculation. Please add a category!')
          }
          }
      })
      .catch((error) => {
        console.log("API not working", error);
      })  
    // dispatch(getCategoryList(location?.state?.data?.client_id))
    
  }

  const handlePopup= (isAction) =>{
    if(isAction ==0){
      setUndoChanges(false)
    }else{
    axios(`${Baseurl+MANAGAE_LEVEL_REMOVE}`, {
      method: "POST",
      data:{
        "client_branch_level_id":deletableCardDetail?.id,
        "direct_delete":true
      },         
      headers: {
        "Content-Type": "application/json",
        // "Authorization": getToken()
      },
    })
      .then((response) => {
        if (response?.data?.status==200) {  
          setUndoChanges(false)
          levelTwoListCallBack();                            
          toast.success(response?.data?.message)
        }else if(response?.data?.status==205){  
          toast.error(response?.data?.message)     
          setUndoChanges(false)               
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      }); 
  }    

  }

  return (
    <>  
    {undoChanges &&
        <ConfirmPopup
          show={true}          
          message={
            <div className='text-left'>
              {`This action would delete this ${deletableCardDetail?.name}`}
              <br />
              {"Are you sure you want to continue?"}
            </div>
          }          
          activeBtnName={'Yes'}
          closeBtnName='No'
          onChange={(data) => handlePopup(data)}
          activeBtnColor="#42B2AC"
          forWarning={true}
          icon={<img className='' src={AlertIcon} />}
        />}  
    <div className='w-full bg-white'>
    <div className='flex justify-between xl:py-3 py-2 xl:px-[4rem] px-[3rem] sticky bg-white z-10'>
        <div className='flex gap-2 items-center'>
          <div className='flex gap-2 items-center cursor-pointer' onClick={()=>navigate(RouteSegments.LEVEL_ONE,{ 
            state:{
                client_country_id:ClientCountryId,
                clientId:location?.state?.data?.client_id,
                breadCrumbClientName:location?.state?.breadCrumbClientName,
                breadCrumbCountryName:location?.state?.breadCrumbCountryName}})
              
            } >
          <LeftArrow bgColor={'#d0cece'} className={'xl:w-[25px] xl:h-[25px] w-[20px] h-[20px]'}/>
          <p className='text-gray-light xl:text-[15px] text-[13px]'>Back</p>
          </div>
          <div className='flex gap-1 items-center text-dark-blue font-medium'>

          <p className='text-[13px] xl:text-[14px]'>{location?.state?.breadCrumbClientName} </p>
          <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 
          <p className='text-[13px] xl:text-[14px]'>{location?.state?.breadCrumbCountryName}</p>  
          <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 
          <p className='text-[13px] xl:text-[14px]'>{location?.state?.labelName}</p>
          <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 

          {levelEditShow ?   
        <form onSubmit={(e) => { e.preventDefault(); updateLevelName(e); }}>       
            <div className='flex items-center gap-2'>
                <SearchInput searchTerm={levelTwoLabel} setSearchTerm={setLevelTwoLable} size={'sm'} icon={false} name="levelTwoNameUpdate"/>
                <Button bgColor="#70AD47"
                        borderType="normal"
                        label={"Save"}
                        color={"#ffffff"}
                        size={'sm'} 
                        disabled={!levelTwoLabel}
                        type={'submit'}
                />            
            </div>
        </form>
        :
        <div className='flex items-center gap-1'>
            <p className='max-w-[10rem]'><OverflowTooltip text={levelTwoLabel} /></p>
            <img src={editIcon} alt='edit' className='w-3 cursor-pointer' onClick={()=>setShowLevelEdit(true)}/>
        </div>       
         }  
          </div>
        </div>
          <div className='flex gap-4 items-center'>
              <Button bgColor='#DCFFFD' borderType="dashed" label={`Manage ${(levelEditShow ? ClientLevelTwoData?.clientLevelName : levelTwoLabel)}`} color={'#42B2AC'} onClick={()=>newCalculation(location?.state?.client_country_id)} icon={'plus'} />
              <div className='flex space-x-4 '>
                <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} icon={true} placeholder={`Search ${(levelEditShow ? ClientLevelTwoData?.clientLevelName : levelTwoLabel)}`} isFrom="levelTwo"/>
                <SortDropDown setCurrentListCount={setCurrentListCount} data={location?.state} clientId={location?.state?.client_country_id} setSortBy={setSortBy} record={currentListCount} searchTerm={searchTerm} setSearchTerm={setSearchTerm} setCountrySort={setCountrySort} countrySort={countrySort} selected={selected} setSelected={setSelected} page={"dashboard"} isFrom="levelTwo"/>
              </div>              
          </div>
     </div>
     <>
        {isLoading ?
            <div className='w-full'>
                <CardSkeleton cards={3} menu={"clientList"} />
                <CardSkeleton cards={3} menu={"clientList"} marigin={'2rem'} />
            </div>
            :
            !isLoading && countriesList?.length > 0 
            ?
            ClientLevelTwoList?.length <=0 && searchTerm?.length > 0 
            ? <NoDataFound redirection={false}/> 
            :
            <div className='overflow-y-auto thin-scrollbar' style={{ height: dynamicHeight+"px" }}>
            <div id="scroll-node">
            <InfiniteScroll
                dataLength={countriesList.length + Constants.listSize}    
                onScroll={()=>setShowLoader(true)}                  
                loader={(hasMore && showLoader )&& <div className='w-full flex items-center justify-center'>
                    <img src={loadMore} alt="load more..." className='w-[20%] h-[5%]' />
                </div>}
            >
                {countriesList?.length > 0 &&
                <div className='grid grid-cols-3 gap-5 mb-[3.5rem] mt-2 ml-[3rem] mr-[2.7rem] xl:ml-[4rem] xl:mr-[3.7rem'>
                    {
                        countriesList?.map(client => (
                        <PageCard 
                        key={client.id} 
                        data={client} 
                        dataLength ={countriesList?.length}
                        title={client?.category_name} 
                        isPrimary={client?.is_primary} 
                        label={client?.level_two_name} 
                        count={client?.level_two_count} 
                        level={2}
                        parent_level_id={location?.state?.data?.id}
                        client_branch_level_id={client.id}
                        ClientCountryId={ClientCountryId}
                        levelOneListCallBack={levelTwoListCallBack}
                        setUndoChanges={setUndoChanges}
                        setDeletableCardDetail={setDeletableCardDetail}
                        location={{
                          labelName:location?.state?.labelName,
                          data:location?.state?.data,
                          breadCrumbClientName:location?.state?.breadCrumbClientName,
                          breadCrumbCountryName:location?.state?.breadCrumbCountryName,
                          client_country_id:ClientCountryId,
                          client_branch_level_two_id:client?.id,
                          level_two_name:client?.category_name
                      }}

                        onClick={(name,id)=>{
                              onLevelTwoCardClick(client)
                            }}
                        />))
                    }
                </div>
                } 
            </InfiniteScroll>
            {hasMore &&
            <div className='w-full flex items-center justify-center mb-16'>
                <button className='bg-greeny-blue text-white px-4 py-2 rounded text-[14px]' onClick={onNext}>
                {isLoading ? 'Loading...':'Load More'} 
            </button>
        </div> }

            </div>
            </div>
            :
            <div className='w-full'>
                <CardSkeleton cards={3} menu={"clientList"} />
                <CardSkeleton cards={3} menu={"clientList"} marigin={'2rem'} />
            </div>
        }
    </>   
     </div>
    </>
  )
}

export default LevelTwo
