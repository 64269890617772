import React, { useState } from 'react';
import { toast } from 'react-toastify';

const EditableTable = ({ columns, thClassName, trClassName, tdClassName, tableData, setTableData ,setIsValid, inputClassName}) => {  

  const updateCell = (rowIndex, columnId, value, eventType) => {   

    setTableData(old => {
      const updatedTable = old?.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            // [columnId]: columnId === 'user_bucket_name' ? value : value > 0 ? parseInt(value) : '' ,
            [columnId]: value,
          };
        }
        return row;
      });

      if (columnId === 'end_point') {
        if (eventType === 'blur') {

    const lastRowOfEndValue = updatedTable[updatedTable?.length - 1]?.end_point
    const currentRowOfStartValue = updatedTable[rowIndex].start_point

    console.log(lastRowOfEndValue,currentRowOfStartValue,value,'currentRowOfStartValue');
    
      if(parseFloat(value) < lastRowOfEndValue && parseFloat(value) >currentRowOfStartValue)
    { 
          // Update start_point of the next row if end_point value is changed
          if (rowIndex < updatedTable.length - 1) {
            updatedTable[rowIndex + 1] = {
              ...updatedTable[rowIndex + 1],
              start_point: parseFloat(value ?? 0),
            };
          }

          // Clear subsequent rows' start_point and end_point values, except for the last row's end_point
          for (let i = rowIndex + 1; i < updatedTable.length; i++) {
            updatedTable[i] = {
              ...updatedTable[i],
              start_point: i === rowIndex + 1 ? parseFloat(value ?? 0) : '',
              end_point: i === updatedTable.length - 1 ? updatedTable[i].end_point : '',
            };
          }
          setIsValid(true)

        }
        else{
          setIsValid(false)
          toast.error('Value must be greater than minumum start point and lesser than maximum end point')
        }
        }
      }

      return updatedTable;
    
    });
  
  };

  return (
    <table className='w-full'>
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index} className={thClassName}>
              {column.header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className='border-l border-r border-b border-[#E0DCD7]'>
        {tableData.map((row, rowIndex) => (
          <tr key={rowIndex} className={trClassName}>
            {columns.map((column, colIndex) => (
              <td key={colIndex} className={tdClassName}>
                {column.editable ? (
                  <input
                    type={column.accessor === 'user_bucket_name' ? 'text' : 'number'}
                    // min={column.accessor === 'user_bucket_name' ? '' : 1}
                    step={'0.01'}
                    className={`border border-[#E0DCD7] px-2 py-0.5 rounded-[4px] outline-none ${inputClassName ? inputClassName : column.accessor === 'user_bucket_name' ? 'w-[10rem] 2xl:w-[12rem]' :'w-[6rem] 2xl:w-[8rem]'}`}
                    value={row[column.accessor]}
                    onKeyDown={(event) => {
                    if(column.accessor !== 'user_bucket_name'){

                      if (event.key === 'e' || event.key === 'E') {
                          event.preventDefault();
                      }
                    }
                  }} 
                    onChange={e => updateCell(rowIndex, column.accessor, e.target.value, 'change')}
                    onBlur={e => updateCell(rowIndex, column.accessor, e.target.value, 'blur')}
                    disabled={(column.accessor === 'start_point') || (rowIndex === tableData.length - 1 && column.accessor === 'end_point')}
                  />
                ) : (
                  <span>{column.accessor === 'user_bucket_name' || column.accessor === 'system_bucket_name' 
                    ? row[column.accessor] 
                    :  (Number.isInteger(row[column.accessor]) ? row[column.accessor] : row[column.accessor]?.toFixed(2))}</span>
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default EditableTable;
