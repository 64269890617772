import { Tooltip } from '@mui/material'
import React, { useMemo } from 'react'

const Slot = ({ color, left = "", right = "", isTop, label = "", labelClass }) => {
    return <Tooltip title={label}>
        <div
            style={{
                backgroundColor: color,
                left,
                right,
            }}
            className='rounded-full h-[24px] w-[24px] border-[4px] border-white shadow-lg absolute -top-[7px]'>
            <div className={`absolute ${labelClass}`}>
                <p className='text-[12px] whitespace-nowrap'>{label}</p>
            </div>
        </div>
    </Tooltip>
}

function calculatePercentage(value, min, max) {
    return (((value - Math.round(min)) / (Math.round(max) - Math.round(min))) * 100)
}

function percentageDifference(A, B) {
    // Calculate the absolute difference
    let absoluteDifference = Math.abs(A - B);

    // Calculate the average of A and B
    let average = (A + B) / 2;

    // Calculate the percentage difference
    let percentageDifference = (absoluteDifference / average) * 100;

    return percentageDifference;
}


function RangeChart({ data }) {

    const meanVal = data?.mean
    const modeVal = data?.mode



    // const meanDifferncePercentage = 100 * Math.abs( (A - B) / ( (A+B)/2 ) );



    const meanPercentage = calculatePercentage(meanVal, data?.min, data?.max)
    const modePercentage = calculatePercentage(modeVal, data?.min, data?.max)


    let meanPos = meanPercentage;
    let modePos = modePercentage;



    console.log({ meanPos, modePos });
    return (
        <div className='mx-6'>
            <div
                style={{ background: "linear-gradient(270deg, #199D7B 0%, #4C5288 100%) 0% 0% no-repeat padding-box" }}
                className='w-full h-[10px]  mt-16 rounded-md relative'>
                <Slot color="#4C5288" labelClass="top-5 left-0" label={`Minimum (${Math.round(data?.min)})`} />
                <div className='relative ml-4 mr-12'>
                <Slot color="#4C6986" labelClass={`${meanPos < modePos ? "-top-6 left-0" : "-top-6"}`} left={`${meanPos < modePos ? meanPos : modePos}%`} isTop={true} label={`Mean (${Math.round(data?.mean)})`} />
                <Slot color="#258095" labelClass={`${meanPos < modePos ? "top-5" : "top-5 left-0"}`} left={`${meanPos < modePos ? modePos : meanPos}%`} label={`Mode (${Math.round(data?.mode)})`} />
                </div>
                 
                <Slot color="#199D7B" labelClass="-top-6 right-0"  right='0px' label={`Maximum (${Math.round(data?.max)})`} /> </div>
        </div>
    )
}

export default RangeChart