import React from 'react'
import { NavLink } from 'react-router-dom'

//images
import gtlogo from '../../Images/gtbharat-logo.png'
import notificationIcon from '../../Images/Group 32.svg'
import userprofile from '../../Images/userProfile.png'
import LogoutDropdown from './LogoutDropdown'
import RouteSegments from '../../Routes/RouteSegments'

const Header = ({setNotificationOpen,notificationOpen}) => {
    return (
        <header>
            <nav className="bg-[#2B144D] xl:py-3 py-1 ">
                <div className="flex flex-wrap py-1 justify-between items-center xl:mx-[4rem] mx-[3rem] ">
                    <div className="flex items-center">
                    <NavLink to={RouteSegments.HOME}>
                        <img src={gtlogo} className="xl:w-[8rem] xl:h-[2rem] w-[6rem] h-[1.5rem] brightness-0 invert-[1] cursor-pointer" />
                    </NavLink>
                    </div>
                    <div className="flex items-center lg:order-2 space-x-5 ">
                        <div className='relative cursor-pointer shake' onClick={()=> setNotificationOpen(!notificationOpen)}>
                            <img className='xl:w-6 xl:h-[1.5rem] w-4 h-[1rem]' src={notificationIcon} />
                            <button className="absolute inline-flex items-center justify-center xl:top-[-11px] top-[-7px] xl:right-[-9px] right-[-5px]  xl:w-5 xl:h-5 w-3 h-3 xl:text-[12px] text-[10px] font-bold text-white bg-[#D74B29] rounded-full   ">4</button>
                        </div>
                        <LogoutDropdown />
                    </div>

                </div>
            </nav>
        </header>
    )
}

export default Header
