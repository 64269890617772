import React, {useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Button from '../CommonComponents/Button';
import FileSelectIon from '../CommonIcons/FileSelectIon';
import threeDot from '../../Images/three-dot.svg';
import JSZip from 'jszip';
import AWS from 'aws-sdk';
import { localStorageKeys } from '../../Utils/Constants';
import CancelIcon from '../../Images/cancel.svg'
import { toast } from 'react-toastify';
import { checkFileName, openSheetSelectPopup } from '../../Store/Workflow/WorkflowSlice';
import { useDispatch, useSelector } from 'react-redux';
import uploadingGif from '../../Images/gif/uploadingWhite.gif'


const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

const FileInputSelect = ({
    fieldIndex,
    formik,
    isSingleUpload,
    removeField,
    setIsSubmitted
}) => {

  const dispatch = useDispatch()
  // const [error, setError] = useState('');
  let file_details=[];
  const [loading,setIsloading] = useState(false)
  const ecl_workflow_id = useSelector((state) => state?.Workflow?.currentWorkflowId)
  const categoryList = useSelector(state => state?.ModelBuilder?.categoryListData);
  const fileStatusInWorkFlow = useSelector((state) => state?.Workflow?.ui.fileStatusInWorkFlow);

//   const setError = (msgArray) => {
//     if(msgArray?.length >0) {
//     msgArray?.forEach(error => {
//         formik.setFieldError(`uploadField[${error.key}].selectedFiles`, error.error_message);
//     });
//   }
// };


//   const handleFileSelect = async (e, indexToReplace) => {
//     setIsloading(false)
//     let files = e.target.files;
//     let iszip = false
    
//   if(!isSingleUpload){
//     const zip = new JSZip();
//     const fileExtension = files[0]?.name?.split('.').pop();

//     console.log({ fileExtension });

//     if (['zip'].includes(fileExtension)) {
//       try {
//         const zipData = await zip.loadAsync(files[0]);
//         const fileNames = Object.keys(zipData.files);

//         console.log({ fileNames, zipData });

//         files = await Promise.all(
//           fileNames.map(async (fileName) => {
//             const fileData = await zipData.files[fileName].async('blob');
//             return new File([fileData], fileName);
//           })
//         );

//         iszip = true;

//         console.log({ files });
//       } catch (error) {
//         console.log(error);
//       }
//     }
//   }

//     const allowedExtensions = ['.csv', '.xls', '.xlsx','xlx'];

//     let errorFlag = 0;

//     for (let index = 0; index < files.length; index++) {
//       const fileEx = files[index]?.name?.split('.').pop();
//       if (!allowedExtensions.includes('.' + fileEx.toLowerCase())) {
//         errorFlag += 1
//         setError([{
//           key: 0,
//           error_message: "File must be in xls, xlsx, ,xlx or csv format."
//         }]);
//       }
//       setError([]);
//     }
//     if (errorFlag > 0) {
//       if(isSingleUpload){
//         setError([{
//           key: 0,
//           error_message: "File must be in xls, xlsx, ,xlx or csv format."
//         }]);
//          }else if (!isSingleUpload){
//           setError([{
//             key:1,
//             error_message: "File must be in xls, xlsx, ,xlx or csv format."
//           }]);      }
//       return;
//     }

//     const fileArray = Array.from(files);

//     if (fileArray?.length > 1) {
//       formik.setFieldValue('uploadField', [
//         ...formik.values.uploadField.map((uploadField, i) => i == fieldIndex ? ({ ...uploadField, selectedFiles: fileArray[0] }) : uploadField),
//         ...fileArray.slice(1).map((file, i) => ({ category: formik.values?.uploadField[fieldIndex]?.category, selectedFiles: file }))
//       ]);
//     } else {
//       formik.setFieldValue(`uploadField[${fieldIndex}].selectedFiles`, fileArray[0]);
//     }

//   };

// const setError = (msgArray) => {
//   if (msgArray?.length > 0) {
//     msgArray?.forEach(error => {
//       formik.setFieldError(`uploadField[${error.key}].selectedFiles`, error.error_message);
//     });
//   }
// };



const setError = (msgArray) => {
  if (msgArray?.length > 0) {
    msgArray?.forEach(error => {
      formik.setFieldError(`uploadField[${error.key}].selectedFiles`, error.error_message);
    });
  }
};

const handleFileSelect = async (e, fieldIndex) => {
  setIsloading(false);
  let files = e.target.files;
  let iszip = false;

  // Clear previous errors for this field
  setError([{ key: fieldIndex, error_message: '' }]);

  if (!isSingleUpload) {
    const zip = new JSZip();
    const fileExtension = files[0]?.name?.split('.').pop();

    if (['zip'].includes(fileExtension)) {
      try {
        const zipData = await zip.loadAsync(files[0]);
        const fileNames = Object.keys(zipData.files);

        files = await Promise.all(
          fileNames.map(async (fileName) => {
            const fileData = await zipData.files[fileName].async('blob');
            return new File([fileData], fileName);
          })
        );

        iszip = true;
      } catch (error) {
        console.log(error);
      }
    }
  }

  const allowedExtensions = ['csv', 'xls', 'xlsx', 'xlx'];
  let errorFlag = false;

  for (let index = 0; index < files.length; index++) {
    const fileEx = files[index]?.name?.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(fileEx)) {
      errorFlag = true;
      setError([{
        key: fieldIndex,
        error_message: "File must be in xls, xlsx, xlx or csv format."
      }]);
      // Do not clear the file value here, only show the error message
      formik.setFieldValue(`uploadField[${fieldIndex}].selectedFiles`, files[0]); // Still set the file to display the path
    }
  }

  if (errorFlag) {
    return; // Exit function if there is an error, but do not clear the file path
  }

  const fileArray = Array.from(files);

  // If multiple files, handle them accordingly
  if (fileArray?.length > 1) {
    formik.setFieldValue('uploadField', [
      ...formik.values.uploadField.map((uploadField, i) => i === fieldIndex ? { ...uploadField, selectedFiles: fileArray[0] } : uploadField),
      ...fileArray.slice(1).map((file, i) => ({ category: formik.values?.uploadField[fieldIndex]?.category, selectedFiles: file }))
    ]);
  } else {
    formik.setFieldValue(`uploadField[${fieldIndex}].selectedFiles`, fileArray[0]);
  }
};

  const fileNameCheck = () => {
    setIsloading(true);
    let sample = [];
    let fileNamesSet = new Set();

    // Check for duplicate file names
    const duplicates = [];
    formik.values.uploadField?.forEach((data, i) => {
        const fileName = data?.selectedFiles?.name;
        if (fileNamesSet.has(fileName)) {
            duplicates.push({ file_name: fileName, index: i });
        } else {
            fileNamesSet.add(fileName);
            sample.push({ file_name: fileName, id: data?.category });
        }
    });

    if (duplicates.length > 0) {
        // Set error messages for duplicates
        duplicates.forEach((dup) => {
            formik.setFieldError(`uploadField[${dup.index}].selectedFiles`, `${dup.file_name} already exist.`);
        });
        setIsloading(false);
        return;
    }

    const request = {
        workflow_id: ecl_workflow_id,
        files: sample
    };

    dispatch(checkFileName(request))
        .then((response) => {
            if (response.type === `${checkFileName.fulfilled}`) {
                handleUpload();
                // setIsloading(false);
            } else if (response.type === `${checkFileName.rejected}`) {
                // setIsloading(false);
                setError(response?.payload?.errors);
            }
        })
        .catch((error) => {
            console.log("API not working", error);
            setIsloading(false);
        });
};


  const handleUpload = async () => {
    // setError(''); 
   
    try {
      const fileUploadPromises = formik?.values?.uploadField?.map(async (file, i) => {
        const allowedExtensions = ['.csv', '.xls', '.xlsx'];
        const fileExtension = file?.selectedFiles?.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes('.' + fileExtension)) {
          if(isSingleUpload){
            setError('File must be in xls, xlsx, or csv format.');
          }else{
            setError('File must be in zip, xls, xlsx, or csv format.');
          }
          return;
        }
        const convertedFiles = await convertToCSV(file?.selectedFiles,file?.category);
         const convertedFilePromises = convertedFiles.map(async (convertedFile, index) => {
          if(convertedFile?.error){
            setError(convertedFile?.error)
            return;
          }
            const csvFileName = convertedFile?.subsheetName !=null ? changeFileExtension('',`${convertedFile.subsheetName}.csv`) :  changeFileExtension(file?.selectedFiles.name,`.csv`);
            return {
              file:convertedFile?.blob,
              size: convertedFile?.blob.size,
              fileName:csvFileName,
              category:categoryList.find(category => category.id == file?.category)?.category_name ,
              id:file?.category,
              mainFileName:file?.selectedFiles.name ,
              selected: index ==0 ? true : false ,
            };             
  
          });        
        return Promise.all(convertedFilePromises);                  
      });

      const urlsArray = await Promise.all(fileUploadPromises);
      const urls = urlsArray.flat();   
    const groupedData = urls.reduce((acc, obj) => {
      const existingEntry = acc.find(entry => entry.fileName === obj.mainFileName);
      if (existingEntry) {
          existingEntry.subsheets.push(obj);
      } else {
          acc.push({ fileName: obj.mainFileName, subsheets: [obj] ,category:obj.category});
      }
      return acc;
  }, []);
    
      if(urls?.length > 0){
        if (urls[0] !=undefined ) {   
        //   setFileDetails(groupedData)
        dispatch(openSheetSelectPopup(groupedData))
        setIsloading(false)   
   
        } 
      }
          
    } catch (error) {
      console.error('Error uploading files:', error);
    //   setProgress(0);
    }
  
  };

  const transformSheets1 = (fileName,subSheets, workbook) => {
    const fileNameWithoutExtension = fileName.split('.')[0];
    const sheetsInfo = [];

    workbook?.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const range = XLSX.utils.decode_range(sheet['!ref']);
        const sheetSize = (range.e.c + 1) * (range.e.r + 1); // Calculate size of sheet

        const sheetInfo = {
            sheet_name: sheetName,
            sheet_size: sheetSize,
            sheet_url: `https://ecl-images.s3.us-east-1.amazonaws.com/${fileNameWithoutExtension}_${sheetName.replace(/\s/g, ' ')}.csv`
        };

        sheetsInfo.push(sheetInfo);
    });

    return sheetsInfo;
  };

  const transformSheets = (fileName, subSheets, workbook) => {
    const fileNameWithoutExtension = fileName.split('.')[0];
    const sheetsInfo = [];

    workbook?.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const range = XLSX.utils.decode_range(sheet['!ref']);
        const sheetSize = (range.e.c + 1) * (range.e.r + 1); // Calculate size of sheet

        // Count non-empty rows
        let nonEmptyRowsCount = 0;
        for (let rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
            let rowEmpty = true;
            for (let colNum = range.s.c; colNum <= range.e.c; colNum++) {
                const cellAddress = XLSX.utils.encode_cell({ r: rowNum, c: colNum });
                const cell = sheet[cellAddress];
                if (cell && cell.v !== undefined && cell.v !== null && cell.v.toString().trim() !== '') {
                    rowEmpty = false;
                    break;
                }
            }
            if (!rowEmpty) {
                nonEmptyRowsCount++;
            }
        }

        const sheetInfo = {
            sheet_name: sheetName,
            sheet_size: sheetSize,
            sheet_url: `https://ecl-images.s3.us-east-1.amazonaws.com/${fileNameWithoutExtension}_${sheetName.replace(/\s/g, ' ')}.csv`,
            row_count: nonEmptyRowsCount-1 // Add row count to the sheet info
        };

        sheetsInfo.push(sheetInfo);
    });

    return sheetsInfo;
};


  // const convertToCSV = async (file, category_id) => {
  //   return new Promise((resolve, reject) => {
  //       const reader = new FileReader();

  //       reader.onload = (event) => {
  //           try {
  //               const content = event.target.result;
  //               const workbook = XLSX.read(content, { type: 'binary' });
               
  //               let duplicateFound = false;
  //               let duplicateColumns = new Set();
  //               let subsheetsWithDuplicates = [];
  //               let emptySheets = [];
  //               let emptyColumnsIndex =[]
  //               // let emptyHeaderFound = false;
  //               let emptyHeaders = [];
  //               console.log(duplicateFound,"duplicateFound");
               

  //               // Function to convert column index to Excel-like column name (A, B, C, etc.)
  //               const getColumnLetter = (colIndex) => {
  //                 let letter = '';
  //                 while (colIndex >= 0) {
  //                     letter = String.fromCharCode((colIndex % 26) + 65) + letter;
  //                     colIndex = Math.floor(colIndex / 26) - 1;
  //                 }
  //                 return letter;
  //             };
               
               
  //               // Check for duplicate column names in each subsheet
               
  //               workbook.SheetNames.forEach((sheetName) => {
  //                   const columnNames = new Set();

  //                   console.log(emptyColumnsIndex,'columnNames');
  //                   let worksheet = workbook.Sheets[sheetName];
  //                   const headers = XLSX.utils.sheet_to_csv(worksheet, { header: 1}).split('\n')[0].split(',');    
                
  //                   headers.forEach((header,i) => {
  //                     if (!header.trim()) {           
  //                       // emptyHeaderFound = true;  
  //                       emptyHeaders.push(`Column ${getColumnLetter(i)} does not have a header`);
             
  //                         emptyColumnsIndex.push(i)
  //                         return;
  //                     }
                      
  //                     if (columnNames.has(header)) {
  //                         duplicateFound = true;
  //                         duplicateColumns.add(header);
  //                         if (!subsheetsWithDuplicates.includes(sheetName)) {
  //                             subsheetsWithDuplicates.push(sheetName);
  //                         }                           
  //                         // return;
  //                     }
  //                     columnNames.add(header);
  //                 });                   
  //               });    

  //                 // If empty headers are found, show an error message
  //               if (emptyHeaders.length > 0) {
  //                 const fileName = file.name;
  //                 const errorMsg = `${fileName} \n${emptyHeaders.join('\n')}`;
  //                 console.log(errorMsg, "errorMsg");

  //                 setError([{
  //                     key: fieldIndex,
  //                     error_message: errorMsg
  //                 }]);
  //                 setIsloading(false);

  //                 return reject({ error: errorMsg });
  //             }
  //                // Remove empty columns                
          
  //               console.log(emptyColumnsIndex,'emptycolumnIndex');
  //               // If no duplicates found, proceed with conversion
  //               const convertedFiles = workbook.SheetNames.map((sheetName) => {
  //               const worksheet = workbook.Sheets[sheetName];
  //               const csvContent = XLSX.utils.sheet_to_csv(worksheet);

  //               // Split the CSV content into rows
  //                 const rows = csvContent.split('\n');

  //                 // Remove empty columns
  //                 const headers = rows[0].split(',').filter(header => header.trim() !== '');
  //                 const nonEmptyRows = rows.map(row => {
  //                   const cells = row.split(',');
  //                   return headers.map((header, index) => {
  //                     if (cells[index]?.startsWith('"') && cells[index]?.endsWith('"')) {
  //                       // If value is enclosed in double quotes, return as is
  //                       return cells[index];
  //                     } else {
  //                       // Otherwise, split by comma
  //                       return cells[index] || '';
  //                     }
  //                   }).join(',');
  //                 });

  //                 //  Filter out rows that contain only empty values
  //                 // const filteredRows = nonEmptyRows.filter(row => {
  //                 //   return row.replace(/,/g, '').trim() !== '' || row.includes('""');
  //                 // });

  //                 const filteredRows = rows.map(row => {
  //                   const cells = row.split(',');
  //                   return cells.filter((_, index) => !emptyColumnsIndex.includes(index)).join(',');
  //               }).filter(row => {
  //                   return row.replace(/,/g, '').trim() !== '' || row.includes('""');
  //               });


  //                 // Reconstruct CSV content with filtered rows
  //                 const filteredCsvContent = filteredRows.join('\n');

  //                 // Create Blob with filtered CSV content
  //                 const csvFile = new Blob([filteredCsvContent], { type: 'text/csv' });


  //                   console.log(csvContent,'csvContent');
                                
                    
  //                   if (rows.length <= 1 || (rows.length === 2 && rows[1].trim() === '')) {
  //                     const fileName = file.name;
  //                     const errorMsg = `${fileName} ${sheetName ? ' - ' + sheetName : ''} must have data.`;
  //                     return { blob: csvFile, subsheetName: sheetName, error: errorMsg, subSheetsCount: workbook.SheetNames.length };
  //                   }else if (duplicateFound) {
  //                     const duplicateColumnNames = Array.from(duplicateColumns).join(', ');                  
  //                     const fileName = file.name;
  //                     const subsheetNames = subsheetsWithDuplicates.join(', ');                     
  //                   //  const errorMsg = (`${fileName} has duplicate columns in the following  subsheet(s): ${subsheetNames}`);
  //                   //  const errorMsg = `${fileName} has duplicate columns (${duplicateColumnNames}) in the following subsheet(s): ${subsheetNames}`;
  //                    const errorMsg = `The ${fileName} contains multiple columns with the same name, please make the correction & upload the right file`
  //                    console.log(errorMsg,"errorMsg");
  //                    setError([{
  //                     key: fieldIndex,
  //                     error_message: errorMsg
  //                   }]);
  //                   setIsloading(false)
  //                   //  formik.setFieldError(`uploadField[${category_id}].selectedFiles`, errorMsg);
  //                    console.log('Formik', formik);
  //                     return { blob: csvFile, subsheetName: sheetName, error: errorMsg, subSheetsCount: workbook.SheetNames.length };

  //                    }else {
  //                       return { blob: csvFile, subsheetName: sheetName, error: null , subSheetsCount: workbook.SheetNames.length };
  //                   }
  //               });

  //               file_details.push({ file_name: file.name, file_size: file.size, category_id: category_id, sub_sheets: transformSheets(file?.name, workbook.SheetNames,workbook) });
  //               localStorage.setItem(localStorageKeys.file_details, JSON.stringify(file_details))
  //               resolve(convertedFiles);
  //           } catch (error) {
  //               reject(error);
  //           }
  //       };

  //       reader.readAsBinaryString(file);
  //   });
  // };

  const convertToCSV = async (file, category_id) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async (event) => {
            try {
                const content = event.target.result;
                const workbook = XLSX.read(content, { type: 'binary' });

                let duplicateFound = false;
                let duplicateColumns = new Set();
                let subsheetsWithDuplicates = [];
                let emptyColumnsIndex = [];
                let emptyHeaders = [];
                let hiddenSheets = [];

                // Function to convert column index to Excel-like column name (A, B, C, etc.)
                const getColumnLetter = (colIndex) => {
                    let letter = '';
                    while (colIndex >= 0) {
                        letter = String.fromCharCode((colIndex % 26) + 65) + letter;
                        colIndex = Math.floor(colIndex / 26) - 1;
                    }
                    return letter;
                };


                const processWorkbook = () => {
                  // Check for duplicate column names in each visible sheet
                  workbook.SheetNames.forEach((sheetName) => {
                      const columnNames = new Set();
                      let worksheet = workbook.Sheets[sheetName];
                      const headers = XLSX.utils.sheet_to_csv(worksheet, { header: 1 }).split('\n')[0].split(',');

                      headers.forEach((header, i) => {
                          if (!header.trim()) {
                              emptyHeaders.push(`Column ${getColumnLetter(i)} does not have a header`);
                              emptyColumnsIndex.push(i);
                              return;
                          }

                          if (columnNames.has(header)) {
                              duplicateFound = true;
                              duplicateColumns.add(header);
                              if (!subsheetsWithDuplicates.includes(sheetName)) {
                                  subsheetsWithDuplicates.push(sheetName);
                              }
                          }
                          columnNames.add(header);
                      });
                  });

                  if (emptyHeaders.length > 0) {
                      const fileName = file.name;
                      const errorMsg = `${fileName} \n${emptyHeaders.join('\n')}`;
                      setError([{ key: fieldIndex, error_message: errorMsg }]);
                      setIsloading(false);
                      return reject({ error: errorMsg });
                  }

                  const convertedFiles = workbook.SheetNames.map((sheetName) => {
                      const worksheet = workbook.Sheets[sheetName];
                      const csvContent = XLSX.utils.sheet_to_csv(worksheet);
                      const rows = csvContent.split('\n');

                      const headers = rows[0].split(',').filter(header => header.trim() !== '');
                      const filteredRows = rows.map(row => {
                          const cells = row.split(',');
                          return cells.filter((_, index) => !emptyColumnsIndex.includes(index)).join(',');
                      }).filter(row => {
                          return row.replace(/,/g, '').trim() !== '' || row.includes('""');
                      });

                      const filteredCsvContent = filteredRows.join('\n');
                      const csvFile = new Blob([filteredCsvContent], { type: 'text/csv' });

                      if (rows.length <= 1 || (rows.length === 2 && rows[1].trim() === '')) {
                          const fileName = file.name;
                          const errorMsg = `${fileName} ${sheetName ? ' - ' + sheetName : ''} must have data.`;
                          return { blob: csvFile, subsheetName: sheetName, error: errorMsg, subSheetsCount: workbook.SheetNames.length };
                      } else if (duplicateFound) {
                          const duplicateColumnNames = Array.from(duplicateColumns).join(', ');
                          const fileName = file.name;
                          const subsheetNames = subsheetsWithDuplicates.join(', ');
                          const errorMsg = `The ${fileName} contains multiple columns with the same name. Please make the correction & upload the right file.`;
                          setError([{ key: fieldIndex, error_message: errorMsg }]);
                          setIsloading(false);
                          return { blob: csvFile, subsheetName: sheetName, error: errorMsg, subSheetsCount: workbook.SheetNames.length };
                      } else {
                          return { blob: csvFile, subsheetName: sheetName, error: null, subSheetsCount: workbook.SheetNames.length };
                      }
                  });

                  file_details.push({ file_name: file.name, file_size: file.size, category_id: category_id, sub_sheets: transformSheets(file?.name, workbook.SheetNames, workbook) });
                  localStorage.setItem(localStorageKeys.file_details, JSON.stringify(file_details));
                  resolve(convertedFiles);
              };
                // Check for hidden sheets
                workbook.SheetNames.forEach((sheetName) => {
                    const sheetVisibility = workbook.Workbook.Sheets.find(sheet => sheet.name === sheetName)?.Hidden;
                    if (sheetVisibility !== undefined && sheetVisibility !== 0) {
                        hiddenSheets.push(sheetName); // Add hidden sheet to the list
                    }
                });

                if (hiddenSheets.length > 0) {
                    const fileName = file.name;
                    const hiddenSheetsList = hiddenSheets.join(', ');

                  
                            // Remove hidden sheets from the workbook
                            hiddenSheets.forEach(sheetName => {
                                delete workbook.Sheets[sheetName];
                                const index = workbook.SheetNames.indexOf(sheetName);
                                if (index !== -1) {
                                    workbook.SheetNames.splice(index, 1);
                                }
                            });
                            // Continue processing with visible sheets
                            processWorkbook();
                        
                       
                   
                } else {
                    // No hidden sheets, continue processing
                    processWorkbook();
                }

            
              
            

                
            } catch (error) {
                reject(error);
            }
        };

        reader.readAsBinaryString(file);
    });
  }

  const changeFileExtension = (filename, newExtension) => {
    const lastDotIndex = filename.lastIndexOf('.');
    const newName = filename.substring(0, lastDotIndex) + newExtension;
    return newName;
  };

  const isAnyFieldEmpty = useMemo(() => {
    return formik?.values?.uploadField?.filter(data => data.category == null || data.selectedFiles == "")?.length > 0
  }, [formik?.values?.uploadField])

//   useEffect(() => {
//     if (fileStatusInWorkFlow?.alreadyExist) {
//         setError(fileStatusInWorkFlow?.message);
//     }
// }, [fileStatusInWorkFlow]);


  const onSubmit = async () => {
    setIsSubmitted(true)
    const errors = await formik.validateForm()
    console.log(errors,'errors');

    if (Object.keys(errors).length == 0) {
      fileNameCheck()
    }
  }

  useEffect(() => {
    console.log('Formik', formik.errors);
  }, [formik.errors]);
  
 return (
    <>
    <div className='flex w-full gap-3'>
      <div className='w-[85%]'>
        <div className='flex gap-2 w-full items-center'>
        <label className="w-[95%] relative flex items-center cursor-pointer border-2 border-greeny-blue focus:border-greeny-blue rounded-md overflow-hidden xl:h-[2.2rem]">
          <div className="px-2 ">
            <FileSelectIon bgColor={'#707070'} className={'2xl:h-[18px] 2xl:w-[19px] h-[16px] w-[17px]'}/>
          </div>        
           <div className="px-2">
           <span className={`${formik.values.uploadField[fieldIndex].selectedFiles ? 'text-black' : 'text-[#BEBEBE]'} text-[13px]`}>
            {formik.values.uploadField[fieldIndex].selectedFiles ? formik.values.uploadField[fieldIndex].selectedFiles.name : 'Select file to upload'}
          </span>
          </div>
          <input
            type="file"
            // onChange={handleFileSelect}
            onChange={(e) => handleFileSelect(e, fieldIndex)}

            accept=".csv, .xls, .xlsx, .xlx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip"
            className="hidden"
          />
          <div className="px-2 ml-auto">
            <img src={threeDot} className='2xl:w-6 w-5' alt="Three Dots" />
          </div>
        </label> 
        {formik.values.uploadField?.length > 1 &&  
        <div className='w-[5%]'>
          <img src={CancelIcon} className='w-[22px] cursor-pointer' onClick={()=>{removeField(fieldIndex)}}/>
        </div>
        }
        </div>

         {formik.errors.uploadField?.at(fieldIndex)?.selectedFiles && <p className='text-[12px] 2xl:text-[14px] text-error-red mt-1'>
           {formik.errors.uploadField?.at(fieldIndex)?.selectedFiles}
         </p>}

         {/* <div className={`${error || fileStatusInWorkFlow?.message ? 'text-error-red':'text-[#000000]'} text-[12px] mt-3 w-[95%] text-wrap flex justify-end ${fieldIndex === formik.values.uploadField?.length - 1 ? 'block':'hidden'}`}>
        {(error || fileStatusInWorkFlow?.message) ? (error || fileStatusInWorkFlow?.message) : isSingleUpload ? 'File must be in xls, xlsx, or csv format.':'File must be in zip, xls, xlsx, or csv format.'}
        </div>         */}

      </div>
      <div className='w-[10%]'>
        {
          fieldIndex == formik.values.uploadField?.length - 1 ?
        
             <Button 
             onClick={onSubmit} 
             disabled={loading}             
             className={'bg-[#47B4AE] text-white px-4 py-1.5 rounded-[5px] font-bold max-w-[9rem]'}
             label={loading ? 'Uploading' : 'Upload'} 
             icon={loading ? <img src={uploadingGif} className='w-5 h-5' alt="Loading..." /> : null}             />
            : null          
        }
      </div>
    </div>
    </>
  );
};

export default FileInputSelect;

