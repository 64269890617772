import { Fragment, useEffect, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
//image
import dropdownIcon from '../../Images/Polygon 1.svg'
import { useDispatch } from 'react-redux';
import { Constants } from '../../Utils/Constants';
import { getEclHistory } from '../../Store/action';
//compoent

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function SortDropDown({sortBy,setSortBy,page,setSelected,selected,isFrom,id,data,setUserSort,disabled}) {
    const dispatch = useDispatch()

    const selectOption = (value, type) => {
        
        setSelected(value);
        setSortBy(type)
        if(!type){
            setUserSort('asc')
            dispatch(getEclHistory(id,type,null,'asc'))
        }else{
        dispatch(getEclHistory(id,type,null,null))
    }
    }

    return (
            <Menu as="div" className="relative inline-block text-left ">
                <div className='flex'>

                    <div className='flex justify-between w-[13.6rem]'>

                        <Menu.Button className={`inline-flex w-full rounded-[5px] relative items-center rounded-base border border-[#CAD3D0] bg-white px-5 pb-0.5 ${page=="dashboard" ? 'xl:h-[2.2rem] h-[1.7rem]':'xl:h-[2rem] h-[1.7rem]'} xl:text-[12px] xl:leading-[21px] text-[11px] leading-[15px] whitespace-nowrap text-[#707070] shadow-sm gap-2`}>
                        {({ open }) => (
                        <>
                            {selected}
                            <div
                                className={`absolute right-[1rem] transition-transform transform ${open ? 'rotate-180' : 'rotate-0'
                                    }`}
                            >
                                <img className="xl:h-[10px] xl:w-[10px] h-[9px] w-[9px]" src={dropdownIcon} alt="Dropdown Icon" />
                            </div>
                        </>
                        )}
                        </Menu.Button>

                    </div>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-0 z-[1000] bg-white  w-[13.5rem]  origin-top-right text-[#707070] shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none rounded-b-lg">
                        <div className="py-1">
                            <Menu.Item>
                                {({ active }) => (
                                    <div

                                        className={classNames(
                                            `text-[#707070] border-b border-[#CAD3D0] ${disabled ? 'pointer-events-none':'cursor-pointer'}`,
                                            'block px-4 mt-1 xl:text-[13px] xl:leading-[21px] text-[11px] leading-[15px] py-[0.2rem]'
                                        )}
                                        onClick={(e) => selectOption('Recently added','created_at:desc')}

                                    >
                                        Recently added

                                    </div>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <div

                                        className={classNames(
                                            `first:text-[#707070] border-b border-[#CAD3D0] ${disabled ? 'pointer-events-none':'cursor-pointer'}`,
                                            'block px-4 mt-1 xl:text-[13px] xl:leading-[21px] text-[11px] leading-[15px] py-[0.2rem]'
                                        )}
                                        onClick={(e) => selectOption('Recently edited','updated_at:desc')}

                                    >
                                        Period – oldest first
                                    </div>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <div

                                        className={classNames(
                                            `text-[#707070] border-b border-[#CAD3D0] ${disabled ? 'pointer-events-none':'cursor-pointer'}`,
                                            'block px-4 mt-1 xl:text-[13px] xl:leading-[21px] text-[11px] leading-[15px] py-[0.2rem]'
                                        )}
                                        onClick={(e) => selectOption('Recently edited','updated_at:desc')}

                                    >
                                    Period – newest first
                                    </div>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <div

                                    className={classNames(
                                        `text-[#707070] border-b border-[#CAD3D0] ${disabled ? 'pointer-events-none':'cursor-pointer'}`,
                                        'block px-4 mt-1 xl:text-[13px] xl:leading-[21px] text-[11px] leading-[15px] py-[0.2rem]'
                                    )}
                                        onClick={(e) => selectOption('User',null)}

                                    >
                                        User
                                    </div>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <div

                                    className={classNames(
                                        `text-[#707070] border-b border-[#CAD3D0] ${disabled ? 'pointer-events-none':'cursor-pointer'}`,
                                        'block px-4 mt-1 xl:text-[13px] xl:leading-[21px] text-[11px] leading-[15px] py-[0.2rem]'
                                    )}
                                        onClick={(e) => selectOption('Status','status:asc')}
                                    >
                                        Status
                                    </div>
                                )}
                            </Menu.Item>  
                            <Menu.Item>
                                {({ active }) => (
                                    <div

                                    className={classNames(
                                        `text-[#707070] border-b border-[#CAD3D0] ${disabled ? 'pointer-events-none':'cursor-pointer'}`,
                                        'block px-4 mt-1 xl:text-[13px] xl:leading-[21px] text-[11px] leading-[15px] py-[0.2rem]'
                                    )}
                                        onClick={(e) => selectOption('Recently modified','updated_at:desc')}
                                    >
                                        Recently modified
                                    </div>
                                )}
                            </Menu.Item>  
                            <Menu.Item>
                                {({ active }) => (
                                    <div

                                    className={classNames(
                                        `text-[#707070] border-b border-[#CAD3D0] ${disabled ? 'pointer-events-none':'cursor-pointer'}`,
                                        'block px-4 mt-1 xl:text-[13px] xl:leading-[21px] text-[11px] leading-[15px] py-[0.2rem]'
                                    )}
                                        onClick={(e) => selectOption('ECL value – highest first','updated_at:desc')}
                                    >
                                        ECL value – highest first
                                    </div>
                                )}
                            </Menu.Item>    
                            <Menu.Item>
                                {({ active }) => (
                                    <div

                                        className={classNames(
                                            `text-[#707070] ${disabled ? 'pointer-events-none':'cursor-pointer'}`,
                                            'block px-4 mt-1 xl:text-[13px] xl:leading-[21px] text-[11px] leading-[15px] py-[0.2rem]'
                                        )}
                                        onClick={(e) => selectOption('ECL value – lowest first','updated_at:desc')}
                                    >
                                        ECL value – lowest first
                                    </div>
                                )}
                            </Menu.Item>                                                   
                        </div>
                    </Menu.Items>
                </Transition>
            </Menu>
    )
}
