import React, { useEffect, useState } from 'react'
import Button from '../../../Components/CommonComponents/Button';
import { Select } from 'antd';
import { useFormik } from 'formik';
import DownArrow from '../../../Components/CommonIcons/DownArrow';
import { useDispatch, useSelector } from 'react-redux';
import FileInputSelect from '../../../Components/wizardPage/FileInputSelect'

// images
import AccorDinOpend from '../../../Images/accordinOpend.svg'
import AccorDinClosed from '../../../Images/accordinClosed.svg'
import removeFile from "../../../Images/removeFIle.svg"
import deleteIcon from "../../../Images/Group 442.svg"
import XlsActiveIcon from "../../../Images/xls-green.svg"
import OverflowTooltip from '../../../Components/ToolTip/OverFlowToolTip';
import moment from 'moment';
import { convertDateFormat, getFileSize } from '../../../Utils/CommonFunctions';
import DynamicTable from '../../../Components/Table/DynamicTable';
import FileUploadListSkeleton from '../../../Components/Skeleton/FileUploadListSkeleton';
import { useLocation } from 'react-router-dom';
import AddIcon from '../../../Images/Group 123.svg'
import { localStorageKeys, loginLocalStorageKeys } from '../../../Utils/Constants';
import { cancelClicked,getFileDetails, selectCurrentNodeIds,eclNodesave, openSheetDeletePopup,selectCurrentNodeData  } from '../../../Store/Workflow/WorkflowSlice';
import PositionAnalysisTable from '../../../Components/PositionAnalysis/PositionAnalysisTable';
import * as Yup from "yup"
import { getCategoryList } from '../../../Store/action';


const FileUploadSummaryAccordian = ({ showCategoryHeader, files, isOpen, toggleAccordion, isCalculated, onDeleteClick, workflowId, eclNodeId, isLastItem }) => {
  const location = useLocation()
  const newSummary = files?.new_summary
  const main_file_name = files?.main_file_name
  const category_id = newSummary[0]?.CategoryId
  const categoryName = newSummary[0]?.CategoryName
  return <div className={`${isLastItem ? '':'border-b border-[#E0DCD7]'} pr-2 pb-3`}>
    {(isCalculated && showCategoryHeader) && <div className='justify-between flex p-2'>
      <div className="flex gap-6 items-center">
        <p className='text-[#A8A8A8] text-[13px] pl-2 font-semibold'>Data category</p>
        <p className='text-[#4339A8] text-[13px] font-semibold'>{categoryName}</p>
      </div>
      {!location?.state?.isfromHistory && (
        <img alt='' src={deleteIcon} className='2xl:w-5 2xl:h-5 w-4 h-4 cursor-pointer m-2 float-right' onClick={()=>onDeleteClick(category_id)} />
      )}
    </div>}
    <div className='justify-between flex p-2'>
      <div className="flex gap-2 items-center">
        <img alt='' src={XlsActiveIcon} className='w-4' />
        <p className='text-[#000000] text-[13px] font-bold'>{main_file_name}</p>
      </div>
    </div>
    {newSummary?.map((data, i) => {
      return (
        // data?.workflow_node_id == tempWorkFlowId &&
        <React.Fragment key={i}>
          <div id={data?.workflow_node_id} className='flex items-center w-full gap-3 pl-2 cursor-pointer mt-2 2xl:mt-3' onClick={() => { toggleAccordion(data?.uniqueId); }}>
            <img alt='' src={data?.uniqueId == isOpen ? AccorDinOpend : AccorDinClosed} className='w-5 cursor-pointer' />
            <div className={`border-2 border-greeny-blue text-[12px] xl:p-3 p-2 space-y-2 w-full`}>
              <div className='grid grid-cols-7 gap-2' title={data?.workflow_node_id} id={data?.workflow_node_id}>
                <div className='col-span-2'>
                  <div className='grid grid-cols-3'>
                    <p className='col-span-1 whitespace-nowrap'>Sheet name :</p>
                    <p className='col-span-2 font-semibold'><OverflowTooltip text={isCalculated ? data?.fileName : data?.sheetName} /></p>
                  </div>
                </div>
                <div className='col-span-2'>
                  <div className='grid grid-cols-3'>
                    <p className='col-span-1 whitespace-nowrap'>Category :</p>
                    <p className='col-span-2 font-semibold'><OverflowTooltip text={data?.CategoryName} /></p>
                  </div>
                </div>
                <div className='col-span-2'>
                  <div className='grid grid-cols-3'>
                    <p className='col-span-1 whitespace-nowrap '>Uploaded on :</p>
                    <p className='col-span-2 font-semibold '><OverflowTooltip text={moment.utc(data?.uploadedOn).local().format('DD-MMM-YYYY [at] h:mmA')} /></p>
                  </div>
                </div>
                <div className='col-span-1'>
                  <div className='grid grid-cols-2'>
                    <p className='col-span-1 whitespace-nowrap'>File size :</p>
                    <p className='col-span-1 font-semibold'><OverflowTooltip text={getFileSize(data?.file_size)} /></p>
                  </div>
                </div>
              </div>
              {data?.uniqueId == isOpen ? (
                <div className='grid grid-cols-7 gap-2'>
                  <div className='col-span-2'>
                    <div className='grid grid-cols-3'>
                      <p className='col-span-1 whitespace-nowrap'>Total records :</p>
                      <p className='col-span-2 font-semibold'>{data?.totalRecords + " rows"}</p>
                    </div>
                  </div>
                  <div className='col-span-2'>
                    <div className='grid grid-cols-3'>
                      <p className='col-span-1 whitespace-nowrap'>Total columns :</p>
                      <p className='col-span-2 font-semibold'>{data?.totalColumns}</p>
                    </div>
                  </div>
                  <div className='col-span-2'>
                    <div className='grid grid-cols-3'>
                      <p className='col-span-1 whitespace-nowrap'>Uploaded by :</p>
                      <p className='col-span-2 font-semibold'><OverflowTooltip text={data?.uploadedBy} /></p>
                    </div>
                  </div>
                </div>

              ) : null}
            </div>
          </div> 
          {data?.uniqueId == isOpen ?
            isCalculated
              ? <div className='ml-[2.5rem]'>
                <div className='mt-4 w-full '>
                  <PositionAnalysisTable
                    initialData={newSummary[i]?.summary?.data}
                    category_id={isCalculated ? data?.CategoryId : data?.category_id}
                    ecl_node_id={eclNodeId}
                    workflow_id={workflowId}
                    workFlow_node_id={newSummary[i].workflow_node_id}
                    // request={mergeSummaryTableRequest} 
                    tableHeaders={newSummary[i]?.summary?.headers?.map((header, ind) => ({
                      header: header,
                      accessor: header,
                      meta: {
                        filterType: newSummary[i]?.summary?.dataTypes[ind] === "VARCHAR" || newSummary[i]?.summary?.dataTypes[ind] === "DATE"
                          ? "select"
                          : "range"
                      }
                    }))}
                    summary={newSummary[i]?.summary}
                    current_headers={newSummary[i]?.summary?.headers}
                    isDownloadxls={false}
                    totalColumns={newSummary[i]?.totalColumns}
                    totalRecords={newSummary[i]?.totalRecords}
                    filePath={newSummary[i]?.file_path}
                    file_name={newSummary[i]?.fileName}
                    isOptionsDisabled={location?.state?.isfromHistory}

                  />
                </div>
              </div>
              : <div className='ml-[2.5rem]'>
                <div className='mt-4 w-full overflow-x-auto thin-scrollbar'>
                  <DynamicTable key={data?.uniqueId} nodeValueSummary={files?.new_summary} index={i ?? 0} fixedHeader={false} />
                </div>
              </div>
            : <></>
          }
        </React.Fragment>
      )
    })}
  </div>
}


function FileUpload({ isSingleUpload = true }) {

    const { Option } = Select;
    const location= useLocation();
    const dispatch = useDispatch();
    const [currentAccordinOpen,setCurrentAccordinOpen] = useState(null);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false)

    const categoryList = useSelector(state => state?.ModelBuilder?.categoryListData);
    const { calculationDate, currentTemplateName, currentTemplateId, isCalculating, isFileSummaryLoading ,isDataLoading} = useSelector(state => ({
        calculationDate: state?.Workflow?.calculationDate,
        currentTemplateName:state?.Workflow?.currentTemplateName,
        currentTemplateId: state?.Workflow?.currentTemplateId,
        isCalculating: state?.Workflow?.ui?.isCalculating,
        isFileSummaryLoading: state?.Workflow?.ui?.isFileSummaryLoading,
        isDataLoading : state?.Workflow?.ui?.isDataLoading

    }))
  const { currentEclNodeId } = useSelector(selectCurrentNodeIds)

  const currentNodeData = useSelector(selectCurrentNodeData)

    const formik = useFormik({
        initialValues: {
        uploadField: currentNodeData?.node_value
          ? currentNodeData?.node_value?.map(nodeValue => ({
            category: nodeValue?.id,
            selectedFiles: nodeValue?.file_path,
          }))
          : [{
                category: null,
                selectedFiles: "",
            }]
        },
      validationSchema: Yup.object().shape({
        uploadField: Yup.array().of(
          Yup.object({
            category: Yup.string().required("Please select the document category"),
            selectedFiles: Yup.string().required("Please select the file for upload"),
          })
        )
      }),
      validateOnChange: isSubmitted,  // Enable validation on change after submit
      validateOnBlur: false,    // Optionally enable validation on blur
      enableReinitialize: true,
      onSubmit: (values) => {
        setIsSubmitted(true);
        // Handle form submission
        console.log(values);
      }
    })


    const addMoreFiles = () => {
        formik.setFieldValue('uploadField', [
            ...formik.values.uploadField,
            { category: null, selectedFiles: "" }
          ],false);

    }
    const removeField = (index) => {
        const updatedFiles = formik.values.uploadField.filter((_, i) => i !== index);
        formik.setFieldValue('uploadField', updatedFiles,false);
    }
    
    const { nodeList, isFileUploading, uploadingPercentage } = useSelector(state => {
    return {
            nodeList            : state?.Workflow?.nodeList,
            isFileUploading     : state.Workflow?.ui?.fileUploading ,
      uploadingPercentage: state.Workflow?.ui?.uploadingPercentage,
        }
    })


    const ecl_workflow_id = useSelector(state=>state?.Workflow?.currentWorkflowId)
    const currentWorkflowNodeId =  useSelector(state =>state?.Workflow?.currentWorkflowNodeId)
    


    const toggleAccordion = (index) => {
      if (index == currentAccordinOpen) {
        setCurrentAccordinOpen(null)
        
      } else {
        setCurrentAccordinOpen(index)      
      }
    };

      const saveAndProceed = async (isFrom, isUploadAnother) => {        
        
        const requestFormData = new FormData();

          if(currentTemplateId || currentTemplateName && calculationDate){
          const nodeDetailsString = localStorage.getItem(localStorageKeys.nodeDetails);
          const nodeDetails = JSON.parse(nodeDetailsString);
          const file_details = JSON.parse(localStorage.getItem(localStorageKeys.file_details))
    
          requestFormData.append('ecl_workflow_id',  ecl_workflow_id ?  ecl_workflow_id : "");
          requestFormData.append('user_id', localStorage.getItem(loginLocalStorageKeys.loginId));
          requestFormData.append('client_branch_level_id', location?.state?.levelTwoCardId);
          requestFormData.append('date', convertDateFormat(calculationDate));
          requestFormData.append('template_type','new');
            requestFormData.append('ecl_workflow_node_id', currentWorkflowNodeId ?? "");
            requestFormData.append('node_id', currentEclNodeId);
          requestFormData.append('template_id', currentTemplateId ?? "");
          requestFormData.append('template_name', currentTemplateId ? "" : currentTemplateName)
          requestFormData.append('is_proceed', "Completed"); 

          if(isFrom != 'postionAnalysis'){
          requestFormData.append('node_value', JSON.stringify(nodeDetails));
          }                 
    
          if (file_details && (isFrom != 'postionAnalysis' && isFrom != 'dataCleansing')) {
            requestFormData.append('file_details', JSON.stringify(file_details));
          }
        }
                
         dispatch(eclNodesave({
          requestFormData,
           from: isUploadAnother == true ? "uploadAnother" : "proceedNew",
        }))
      };
      const onDeleteClick = (category_id) => {
        // dispatch(fileDetailsDataClear())
        dispatch(openSheetDeletePopup({workflow_id:ecl_workflow_id, workflow_node_id:currentWorkflowNodeId, category_id:category_id}))

      }

      useEffect(() => {
        const container = document.getElementById('container');
        const isOverflow = container.scrollHeight > container.clientHeight;
        setIsOverflowing(isOverflow);

      }, [formik.values.uploadField]);

      useEffect(()=>{

        dispatch(getCategoryList(location?.state?.clientId, location.state?.levelTwoCardId));

      },[])


  const isJustUploaded = (currentNodeData?.id == null || currentNodeData?.isReplace === true) && currentNodeData?.node_value_summary?.length > 0
  const isCalculated = (currentNodeData?.id != null && currentNodeData?.isReplace !== true) && currentNodeData?.node_value_summary?.length > 0

  const renderFileUpload = () => {
    if (isFileUploading) {
      return <div className='w-full mt-10'>
        {/* progress bar*/}
        <div className='flex justify-center'>
          <progress value={uploadingPercentage} max='100' style={{ width: '60%' }} />
        </div>
        <p className='text-[#000000] text-[12px] text-center mt-3'>{`File upload in progress - ${uploadingPercentage}% completed`}</p>
      </div>
    } else if (isCalculating || isFileSummaryLoading || isDataLoading) {
      return <FileUploadListSkeleton />
    } else if (isJustUploaded) {
      return <div className='border-2 border-greeny-blue pb-2 pr-2' >
      {currentNodeData?.node_value_summary?.map((files, i) => {
        return <div key={i}>
          <FileUploadSummaryAccordian
            files={files}
            showCategoryHeader={true}
            isOpen={currentAccordinOpen}
            toggleAccordion={toggleAccordion}
            onDeleteClick={onDeleteClick}
            isLastItem={i==currentNodeData?.node_value_summary?.length-1} 
          />
        </div>
      })
    }
    </div>
    } else if (isCalculated) {
      return <div className='border-2 border-greeny-blue pb-2' >
        {currentNodeData?.node_value_summary?.map((files, i) => {
          return files?.newSummaryGroup?.map((newSummary, index) => {
            return <div key={i}>
              <FileUploadSummaryAccordian
                showCategoryHeader={index == 0}
                files={newSummary}
                isOpen={currentAccordinOpen}
                toggleAccordion={toggleAccordion}
                isCalculated={true}
                eclNodeId={currentEclNodeId}
                workflowId={ecl_workflow_id}
                onDeleteClick={onDeleteClick}
                isLastItem={i == currentNodeData?.node_value_summary?.length - 1}

              />
            </div>
          })
        })}
      </div>
  } else {
      return <div className='flex gap-4 w-full p-2 2xl:mt-12'>
        <div className='w-[100%]'>
          <div className="w-full p-2 pt-6">
            <div className=''>
              {formik?.values?.uploadField?.map((rowField, fieldIndex) => (
                <div key={fieldIndex} className="flex w-full gap-4 mb-2">
                  <div className="w-[30%]">
                    <Select
                      name={`uploadField[${fieldIndex}].category`}
                      onChange={(e) => {
                        formik.setFieldValue(`uploadField[${fieldIndex}].category`, e)
                      }}
                      value={rowField?.category}
                      placeholder="Data category"
                      suffixIcon={<DownArrow color="#000000" width={'10px'} height={'10px'} className={false ? 'transition-transform transform rotate-180' : 'transition-transform transform rotate-[-180]'} />}
                      className="custom-select-height border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"
                      style={{
                        width: '100%',
                        border: ' 2px solid #00A7B5',
                        // height: '2.2rem',
                        borderRadius: '4px',
                      }}
                    >
                      {categoryList?.map((data, i) => (
                        <Option key={data?.id} value={data?.id}>
                          {data?.category_name}
                        </Option>
                      ))}
                    </Select>
                    {formik.errors.uploadField?.at(fieldIndex)?.category && 
                    <p className='text-[12px] 2xl:text-[14px] text-error-red mt-1'>
                      {formik.errors.uploadField?.at(fieldIndex)?.category}
                    </p>}
                  </div>
                  <div className="w-[65%] flex gap-3">
                    <FileInputSelect
                      fieldIndex={fieldIndex}
                      formik={formik}
                      isSingleUpload={isSingleUpload}
                      removeField={removeField}
                      setIsSubmitted={setIsSubmitted}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className={`${isSingleUpload ? 'hidden':'block'} ${isOverflowing ? 'absolute left-0 bottom-[-2.2rem]' :''}`}>
              <Button
                label={'Add more files'}
                onClick={addMoreFiles}
                icon={<img src={AddIcon} className='h-[14px] w-[14px]' />}
                className={'bg-[#47B4AE] text-white px-4 py-1.5 rounded-[5px] font-bold'}
              />
            </div>            
          </div>
        </div>
      </div>
    }
  }

  const isSaveAndProceedDisabled = location?.state?.isfromHistory || isCalculated || isCalculating || isFileSummaryLoading || isFileUploading || !isJustUploaded
  const isCancelDisabled = location?.state?.isfromHistory || isCalculated || isCalculating || isFileSummaryLoading || isFileUploading


  const onCancel = () => {
    dispatch(cancelClicked({ workflowNodeId: currentWorkflowNodeId }))
  }

  return (
    <>
      {renderFileUpload()}
      <div className={`${currentNodeData?.node_value_summary?.length > 0 && (!isCalculating && !isFileSummaryLoading && !isDataLoading)  ? 'block':'hidden'} absolute right-0 bottom-[-2.4rem]`}>
        <div className='flex items-center gap-3 '>
          <Button className={'bg-[#AFABAB] text-white px-4 py-1.5 rounded-[5px]'} label={'Cancel'} onClick={onCancel} disabled={location?.state?.isfromHistory} />
          <Button className={'bg-[#70AD47] text-white px-4 py-1.5 rounded-[5px]'} label={'Save & upload another'} disabled={isSaveAndProceedDisabled} onClick={() => saveAndProceed('fileUpload', true)} />
          <Button className={'bg-[#70AD47] text-white px-4 py-1.5 rounded-[5px]'} label={'Save & proceed'} disabled={isSaveAndProceedDisabled} onClick={() => saveAndProceed('fileUpload')} />
        </div>
      </div>


        </>
    )
}

export default FileUpload