//Dependencies
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';


//packages
import * as yup from "yup";
import { useFormik } from 'formik';

//components
import WorkflowHeader from './WorkflowHeader';
import CommonPopupLayout from '../../Components/PopUp/CommonPopupLayout';
import DeletePopUpContent from '../../Components/wizardPage/DeletePopUpContent';
import WrokflowNodesSlider from './WrokflowNodesSlider';
import WorkflowSteps from './WorkflowSteps';
import WorkflowMain from './WorkflowMain';
import SubSheetsListPopup from '../../Components/PopUp/SubSheetsListPopUp'

//skeleton components
import NewCalculationSkeleton from '../../Components/Skeleton/NewCalculationSkeleton';

// redux components
import { closeSheetDeletePopupState, closeSheetSelectPopup, eclNodeUpdate, fileUploadSummary, getWorkflowList, resetWorkflow, selectIsInitialLoading} from '../../Store/Workflow/WorkflowSlice';
import { ModelBuilderList, getCategoryList} from '../../Store/action';

// constants
import { deletePopupTableHeader, localStorageKeys, loginLocalStorageKeys } from '../../Utils/Constants';
import RouteSegments from '../../Routes/RouteSegments';
import { getCurrentDateTime } from '../../Utils/CommonFunctions';


function WorkflowPage() {

    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isdeletableFileDetails,setIsdeletableFileDetails] = useState([]);
    const [CalculationMultipleReq,setCalculationMultipleReq] = useState({});

    const isInitialLoading = useSelector(selectIsInitialLoading)
    const{ showSubSheetPopup , fileDetails,nodeList,calculationDate,currentTemplateName,currentWorkflowNodeId,sheetDeletePopupOpen}= 
    useSelector(state => ({
        showSubSheetPopup: state?.Workflow?.ui?.selectSheetPopupOpen,
        sheetDeletePopupOpen :state?.Workflow?.ui?.sheetDeletePopupOpen,
        fileDetails      : state?.Workflow?.fileDetailsArray,
        nodeList         : state?.Workflow?.nodeList,
        calculationDate  : state?.Workflow?.calculationDate,
        currentTemplateName  : state?.Workflow?.currentTemplateName,
        currentWorkflowNodeId : state?.Workflow?.currentWorkflowNodeId


    }))

    const formik = useFormik({
        initialValues: {
            calculationDate: calculationDate ?? null,
            calculationApproach: "",
            currentTemplateName:currentTemplateName ?? null
        },
        enableReinitialize:true,
        validationSchema: yup.object().shape({
            calculationDate: yup.string().required("Please select date"),
        })
    })

    useEffect(() => {
        dispatch(getCategoryList(location?.state?.clientId, location.state?.levelTwoCardId));
        dispatch(ModelBuilderList())
    }, [])
   

    const goToPrevPage = () => {
        localStorage.removeItem(localStorageKeys.oldCategory);
        localStorage.removeItem(localStorageKeys.currentCalculationDate);
        localStorage.removeItem(localStorageKeys.currentWorkFlowId);
        localStorage.removeItem(localStorageKeys.currentTemplateId);
        localStorage.removeItem(localStorageKeys.currentTemplateName);
        navigate(RouteSegments.LEVEL_TWO, {
            state: {
                client_country_id: location?.state?.client_country_id,
                clientId: location?.state?.clientId,
                breadCrumbClientName: location?.state?.breadCrumbClientName,
                breadCrumbCountryName: location?.state?.breadCrumbCountryName,
                breadCrumbLevelOne: location?.state?.breadCrumbLevelOne,
                breadCrumbLevelTwo: location?.state?.breadCrumbLevelTwo,
                levelTwoCategory: location?.state?.levelTwoCategory,
                data: location?.state?.data,
                levelTwoCardId: location?.state?.levelTwoCardId,
                labelName: location?.state?.breadCrumbLevelOne,
            }
        })
    }


    const workflow_node_id = currentWorkflowNodeId
    const ecl_workflow_id = useSelector(state=>state?.Workflow?.currentWorkflowId)
    const ecl_node_id =nodeList[nodeList?.length -1]?.ecl_node_id;

    
    const getSummaryApiCall = async (data, urls) => {
        try {
          const categoriesWithFilePath = data?.map((field, i) => ({
            id: field?.id,
            category_name: field?.category,
            file_path: urls[i],
            uploaded_on: getCurrentDateTime(),
            file_size: field?.size,
            fileName: field?.fileName,
            sheetName: field?.sheetName
          }));
    
          const request = {
            "user_id": localStorage.getItem(loginLocalStorageKeys.loginId),
            "ecl_workflow_node_id": workflow_node_id ?? "",
            "ecl_workflow_id": ecl_workflow_id != null || ecl_workflow_id != undefined ? ecl_workflow_id : "",
            "ecl_node_id": ecl_node_id,
            "category_datas": categoriesWithFilePath,
            "old_category_datas": [],
            "client_id": location?.state?.clientId
          }          
    
          const response = await dispatch(fileUploadSummary(request))
          if (response) {
            localStorage.setItem(localStorageKeys.nodeDetails, JSON.stringify(categoriesWithFilePath));
            localStorage.setItem(localStorageKeys.oldCategory, JSON.stringify(categoriesWithFilePath));            
          }
        }
        catch (error) {
          // Handle any unexpected errors during the API call
          console.error('Error during API call:', error);
        }
      }

      useEffect(()=>{
        const workflowId = localStorage.getItem(localStorageKeys.currentWorkFlowId)
        if (location?.state?.workFlowId || workflowId) {
          dispatch(getWorkflowList(location?.state?.workFlowId || workflowId))        
    }
    },[location?.state?.workFlowId])

  useEffect(() => {

    return () => {
      dispatch(resetWorkflow())
    }
  }, [])



    const anyOneChecked = isdeletableFileDetails?.some(row => row?.sub_sheets.some(sheet => sheet?.checked == true));

    const calculationApiCallMultiple = async () => {    
      const requestFormData = CalculationMultipleReq;
      requestFormData.append('ecl_workflow_id', ecl_workflow_id != null || ecl_workflow_id != undefined ? ecl_workflow_id : "");
      requestFormData.append('user_id', localStorage.getItem(loginLocalStorageKeys.loginId));
      requestFormData.append('client_branch_level_id', location?.state?.levelTwoCardId);          
      
      await dispatch(eclNodeUpdate(requestFormData))

    }
    const [isDisable,setIsDisable] = useState(false)

    return (
        <>
         {showSubSheetPopup &&
            <SubSheetsListPopup
              show={true}       
              data={fileDetails}
              onSubmit={(data, urls) => {getSummaryApiCall(data,urls)}}
              onCancel={() => dispatch(closeSheetSelectPopup())}        
            />
          }
          {(sheetDeletePopupOpen) &&
            <CommonPopupLayout
              show={true}
              title={'Select sheets to remove'}
              buttons={true}
              disabled={!anyOneChecked}
              isFrom="delete"
              onCancel={() => {setIsDisable(false);dispatch(closeSheetDeletePopupState())}}
              onSubmit={() => {calculationApiCallMultiple() }}
              handleDisable={(data)=>setIsDisable(data)}
              content={
                <DeletePopUpContent
                  headers={deletePopupTableHeader}
                  workflow_node_id={workflow_node_id}
                  isDisable={isDisable}
                  setIsdeletableFileDetails={setIsdeletableFileDetails}
                  setCalculationMultipleReq={setCalculationMultipleReq}              
                  headerclassName={'bg-[#2B144C] text-[13px] text-white'} />
              }
            />
          }             
        <div className='flex flex-col xl:px-[4rem] px-[3rem] h-full overflow-hidden'>
            <WorkflowHeader goToPrevPage={goToPrevPage} />
            <WrokflowNodesSlider />
            {isInitialLoading
                ? <NewCalculationSkeleton />
                : <div className='w-full flex flex-grow pb-[5%]'>
                    <WorkflowSteps
                        formik={formik}
                        goToPrevPage={goToPrevPage}
                         />
                    <WorkflowMain />
                </div>}
        </div>
        </>
    )
}

export default WorkflowPage