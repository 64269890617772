import { useFormik } from 'formik'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { downloadExport, exceptionAnalysisProceed, fetchColumnListDatatype, fetchSplitDataDetails, fetchSplitsBySeedvalue, getCalculationFormData, getNodeWiseCategoryList, openModalBuilder, selectCurrentNodeData, selectCurrentNodeIds, splitDataNodesave } from '../../Store/Workflow/WorkflowSlice'
import DropDown from '../DropDown/DropDown'
import { useLocation } from 'react-router-dom'
import Button from '../Button'
import { TextInput } from '../SelectInput'
import * as Yup from 'yup';
import { createColumnHelper } from '@tanstack/react-table'
import SummaryTable from '../Table/SummaryTable'
import { debounce, parseCategotyValueLabel, validateNumber } from '../../Helpers/utils'
import DataTableSkeleton from '../Skeleton/DataTableSkeleton'
import Skeleton from 'react-loading-skeleton'
import useDataTable from '../../hooks/useDataTable'
import DataTable from '../Table/DataTable'
import XlsIcon from '../../Images/excel.svg'
import EditIcon from '../../Images/Group 809.svg'
import Eyeicon from '../../Images/eyeicon.svg'
import { SPLIT_DATA_EXPORT } from '../../Helpers/EndPoints'
import { CircularProgress } from '@mui/material'
import ReadOnlyValueDisplay from '../CommonComponents/ReadOnlyValueDisplay'
import DataPopup from '../PopUp/DataPopup'
import { checkCategoryExist, exportSplitData } from '../../Helpers/ApiHelpers'
import ProceedButton from '../CommonComponents/ProceedButton'
import downloadingIcon from '../../Images/gif/downloading.gif'

const columnHelper = createColumnHelper()

const ColumnDownload = ({ workflow_id, ecl_node_id, category_id, column_name, parent_node_id }) => {

    const [isDownloading, setisDownloading] = useState(false)

    const onDownloadClick = async () => {
        setisDownloading(true)
        const request = {
            workflow_id,
            ecl_node_id,
            category_id,
            is_export: true,
        }
        await downloadExport(request, SPLIT_DATA_EXPORT)
        setisDownloading(false)
    }
    return isDownloading
        ? <img src={downloadingIcon} className='w-6' />
        : <img alt='' onClick={onDownloadClick} src={XlsIcon} className='cursor-pointer w-4 h-4 xl:w-5 xl:h-5' />
}

const ColumnView = ({ workflow_id, ecl_node_id, category_id, category_name }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [showPopup, setShowPopup] = useState(null)

    const onClick = async () => {
        setIsLoading(true)
        const request = {
            workflow_id,
            ecl_node_id,
            category_id,
            is_export: false,
        }
        const response = await exportSplitData(request)
        setIsLoading(false)
        setShowPopup(response?.data)
    }



    return <>
        {showPopup && <DataPopup
            source={showPopup}
            onClose={() => setShowPopup(null)}
            title={`Replace with a value specified by the user - ${category_name}`} />}
        {isLoading
            ? < CircularProgress size={18} />
            : <button onClick={onClick} className='text-[#4F49DF] text-[12px] xl:text-[14px] flex items-center gap-2'>
                <img alt='' src={Eyeicon} className='w-3' />
                View
            </button>}
    </>
}


function SplitData() {
    const dispatch = useDispatch()


    const currentNodeData = useSelector(selectCurrentNodeData)
    const isDataLoading = useSelector(state => state?.Workflow?.ui?.isDataLoading)
    const isCalculating = useSelector(state => state?.Workflow?.ui?.isCalculating)
    const filesMerging = useSelector(state => state?.Workflow?.ui?.filesMerging)
    const reduxState = useSelector(state => state)
    const nodeWiseCategory = useSelector(state => state?.Workflow?.nodeWiseCategory)
    const dependentDropwdownList = useSelector(state => state?.Workflow?.columnList ?? [])
    const allCategoryList = useSelector(state => state?.ModelBuilder?.categoryListData)
    const { currentEclNodeId, currentWorkflowId } = useSelector(selectCurrentNodeIds)
    const isProceedLoading = useSelector(state => state?.Workflow?.ui?.isProceedLoading)
    const [isEditMode, setIsEditMode] = useState(false)
    const [filesMerged, setFilesMerged] = useState(false)
    const { seedValueLoading, splitsBySeed } = useSelector(state => ({
        splitsBySeed: state?.Workflow?.splitsList,
        seedValueLoading: state?.Workflow?.ui?.seedValueLoading
    }))
    console.log({ splitsBySeed });
    const location = useLocation()
    const [currentViewCategory, setCurrentViewCategory] = useState(null)
    const formik = useFormik({
        initialValues: {
            category: currentNodeData?.nodeCategoryId,
            noOfSplits: currentNodeData?.splitTableData?.no_of_split ? currentNodeData?.splitTableData?.no_of_split : 3,
            dependentVariable: currentNodeData?.splitTableData?.dependent_variable,
            seedValue: currentNodeData?.splitTableData?.seed_value,
            splitList: currentNodeData?.splitTableData?.splitDatas?.length > 0 ? currentNodeData?.splitTableData?.splitDatas?.map(split => ({
                splitPercentage: split?.split_percentage,
                categoryName: split?.category_name,
            })) : Array.from(Array(3)).map((n, i) => ({
                splitPercentage: i == 0 ? 60 : 20,
                categoryName: undefined,
            }))
        },
        enableReinitialize: true,
        validationSchema: Yup.object().shape({
            splitList: Yup.array().of(
                Yup.object({
                    categoryName: Yup.string().notOneOf(allCategoryList?.map(cate => cate?.category_name) ?? [], "The category name already exists"),
                })
            )
        })
    })

    // allCategoryList
    const { dataTableColumns,
        getFilterOptions,
        summaryData,
        tableState,
        refreshDataTable,
        onTableStateChange,
        isDataLoading: isMergeSummaryLoading,
        isExportLoading,
        donwloadXl,
    } = useDataTable({ category_id: currentNodeData?.splitTableData?.isEdit == true ? currentViewCategory : formik.values.category, ecl_node_id: currentEclNodeId, workflow_id: currentWorkflowId, from: "splitData" })
    const debouncedFetchSeed = useRef(debounce((seedValue) => {
        dispatch(fetchSplitsBySeedvalue(seedValue)).then(res => {
            console.log("res", res, res.type == `${fetchSplitsBySeedvalue.fulfilled}`);
            if (res.type == `${fetchSplitsBySeedvalue.fulfilled}`) {
                const data = res?.payload?.data
                if (data?.no_of_split) {
                    formik.setFieldValue("noOfSplits", data?.no_of_split)
                    formik.setFieldValue("splitList", data?.split_percentage?.map(
                        percentage => {
                            return {
                                splitPercentage: percentage,
                                categoryName: undefined,
                            }
                        }
                    ))
                }
            }
        });
    }, 500)).current;

    const setSplits = (count) => {
        formik.setFieldValue("splitList", Array.from(Array(count ?? 0)).map(
            n => {
                return {
                    splitPercentage: formik.values.splitList?.at(n)?.splitPercentage,
                    categoryName: formik.values.splitList?.at(n)?.categoryName,
                }
            }
        ))
    }


    useEffect(() => {
        if(!currentNodeData?.isCurrentlySaved){
        dispatch(getNodeWiseCategoryList({
            workflow_id: currentWorkflowId,
            ecl_node_id: currentEclNodeId,
        }))
        .then(async(response) => {
            if (response.type === `${getNodeWiseCategoryList.fulfilled}`) {  
                setFilesMerged(true)
                // refreshDataTable()
            }
        })               
    }       
    }, [])

    useEffect(()=>{
        if (currentNodeData?.id) {
            dispatch(fetchSplitDataDetails({
                "workflow_id": currentWorkflowId,
                "ecl_node_id": currentEclNodeId,
                "category_id": formik.values.category
            }))
        }

    },[currentNodeData?.id])

    console.log(currentViewCategory,'currentViewCategory');
   
    useEffect(() => {
        if (currentNodeData?.nodeCategoryId && currentNodeData?.splitTableData?.splitDatas) {
            setCurrentViewCategory(currentNodeData?.splitTableData?.splitDatas?.at(0)?.category_id ?? currentNodeData?.nodeCategoryId)
        } else {
            setCurrentViewCategory(currentNodeData?.nodeCategoryId)
        }
    }, [currentNodeData?.nodeCategoryId, currentNodeData?.splitTableData?.splitDatas])

    useEffect(() => {
        if (currentViewCategory && (!filesMerging && !isCalculating)) {
            refreshDataTable()
        }
    }, [currentViewCategory,filesMerging,isCalculating])



    useEffect(() => {
        if (currentNodeData?.nodeCategoryId && currentNodeData?.splitTableData?.isEdit == false) {
            dispatch(fetchColumnListDatatype({
                "workflow_id": currentWorkflowId,
                "ecl_node_id": currentEclNodeId,
                "category_id": currentNodeData?.nodeCategoryId,
                "data_type": ["Boolean"],
            }
            ))
        }
    }, [currentNodeData?.nodeCategoryId, currentNodeData?.splitTableData?.isEdit])

    const onCategoryChange = (value) => {
        formik.setFieldValue("category", value)
    }

    const onDependentVariableChange = (value) => {
        formik.setFieldValue("dependentVariable", value)
    }

    const onCategorySave = () => {
        dispatch(fetchSplitDataDetails({
            "workflow_id": currentWorkflowId,
            "ecl_node_id": currentEclNodeId,
            "category_id": formik.values.category
        }))
    }

    const onNoSplitChange = (e) => {
        if (validateNumber(e.target.value)) {
            formik.setFieldValue("noOfSplits", e.target.value)
            setSplits(Number(e.target.value))
        }
    }

    const onSeedValueChange = (e) => {
        formik.setFieldValue("seedValue", e.target.value)
        if (e.target.value) {
            debouncedFetchSeed({ seedValue: e.target.value })
        }
    }

    const onSeedValueBlur = (e) => {

    }
    console.log({ location });


    const onProceedClick = () => {
        if (currentNodeData?.node_status == 'Completed') {
            return dispatch(openModalBuilder())
        }
        const requestFormData = getCalculationFormData({
            client_branch_level_id: location?.state?.levelTwoCardId,
            isFrom: "splitdata",
            category_id: formik?.values?.category,
            node_id: currentEclNodeId,
            state: reduxState,
            nodeStatus: "Completed",
        })

        dispatch(exceptionAnalysisProceed({
            requestFormData,
            workflow_id: currentWorkflowId,
            isProceeded: true
        })).then((res) => {
            if (res.type == `${exceptionAnalysisProceed.fulfilled}`) {
                dispatch(fetchSplitDataDetails({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": formik.values.category
                }))
            }
        }).catch((error) => {
        });
    }

    const onSubmit = async () => {
        const res = await formik.validateForm()
        if (res.splitList?.length > 0) return
        const requestFormData = getCalculationFormData({
            client_branch_level_id: location?.state?.levelTwoCardId,
            isFrom: "splitdata",
            category_id: formik?.values?.category,
            node_id: currentEclNodeId,
            state: reduxState,
            nodeStatus: "Pending",
        })

        dispatch(splitDataNodesave({
            requestFormData,
            request: {
                "ecl_workflow_id": currentWorkflowId,
                "ecl_node_id": currentEclNodeId,
                "ecl_category_id": formik?.values?.category,
                "dependent_variable": formik.values.dependentVariable,
                "seed_value": formik.values.seedValue,
                "no_of_split": formik.values.noOfSplits,
                "splitDatas": formik.values.splitList.map(split => ({
                    "split_percentage": Number(split?.splitPercentage),
                    "category_name": split?.categoryName
                }))

            }
        }))
        // .then(res => {
        //     if (res.type == `${splitDataNodesave.fulfilled}`) {
        //         dispatch(fetchSplitDataDetails({
        //             "workflow_id": currentWorkflowId,
        //             "ecl_node_id": currentEclNodeId,
        //             "category_id": formik.values.category
        //         }))
        //     }
        // })
    }


    const summaryColumns = useMemo(() => {
        if (isEditMode) {
            return [
                columnHelper.accessor("dependentVariable", {
                    header: "Dependent variable",
                    tdClassName: "align-top",
                    mergeCells: true
                }),
                columnHelper.accessor("noOfSplit", {
                    header: "No. of Splits",
                    tdClassName: "align-top",
                    mergeCells: true
                }),
                columnHelper.accessor("seedValue", {
                    header: "Seed value",
                    tdClassName: "align-top",
                    mergeCells: true
                }),
                columnHelper.accessor("split_percentage", {
                    header: "Split %",
                    thClassName: "",
                }),
                columnHelper.accessor("no_of_rows", {
                    header: "No. of rows",
                    thClassName: "",
                }),
                columnHelper.display({
                    header: "Actions",
                    thClassName: "w-[100px]",
                    cell: ({ row }) => {
                        return <div className='space-x-4 flex items-center justify-between'>
                            <ColumnView
                                category_id={row?.original?.category_id}
                                ecl_node_id={currentEclNodeId}
                                workflow_id={currentWorkflowId}
                                category_name={row?.original?.category_name}
                            />
                            <ColumnDownload
                                category_id={row?.original?.category_id}
                                ecl_node_id={currentEclNodeId}
                                workflow_id={currentWorkflowId}
                            />
                        </div>
                    }
                }),
            ]
        } else {
            return [
                columnHelper.accessor("dependentVariable", {
                    header: "Dependent variable",
                    tdClassName: "align-top",
                    mergeCells: true,
                    cell: ({ getValue }) => {
                        return getValue() ? getValue() : "-"
                    }
                }),
                columnHelper.accessor("seedValue", {
                    header: "Seed value",
                    tdClassName: "align-top",
                    mergeCells: true
                }),
                columnHelper.accessor("noOfSplit", {
                    header: "No. of Splits",
                    tdClassName: "align-top",
                    mergeCells: true
                }),
                columnHelper.accessor("split_percentage", {
                    header: "Split %",
                    thClassName: "",
                }),
                columnHelper.accessor("category_name", {
                    header: "Data Category name",
                    thClassName: "",
                }),
                columnHelper.accessor("no_of_rows", {
                    header: "No. of rows",
                    thClassName: "",
                }),
                columnHelper.display({
                    header: "Actions",
                    thClassName: "w-[100px]",
                    cell: ({ row }) => {
                        return <div className='space-x-4 flex items-center justify-between'>
                            <ColumnView
                                category_id={row?.original?.category_id}
                                ecl_node_id={currentEclNodeId}
                                workflow_id={currentWorkflowId}
                                category_name={row?.original?.category_name}
                            />
                            <ColumnDownload
                                category_id={row?.original?.category_id}
                                ecl_node_id={currentEclNodeId}
                                workflow_id={currentWorkflowId}
                            />
                        </div>
                    }
                }),
            ]
        }
    }, [isEditMode])


    const onSplitePercentageChange = (e, index) => {
        formik.setFieldValue(`splitList.${index}.splitPercentage`, e.target.value)
    }

    const onSpliteCategoryChange = (e, index) => {
        formik.setFieldValue(`splitList.${index}.categoryName`, e.target.value)
    }


    const summaryTableData = useMemo(() => {

        return currentNodeData?.splitTableData?.splitDatas?.map(data => {
            return {
                ...data,
                dependentVariable: currentNodeData?.splitTableData?.dependent_variable,
                seedValue: currentNodeData?.splitTableData?.seed_value,
                noOfSplit: currentNodeData?.splitTableData?.no_of_split,
            }
        })
    }, [currentNodeData?.splitTableData])


    const columnDropdownValues = useMemo(() => {
        let formattedValues = []

        dependentDropwdownList?.forEach(col => {
            formattedValues.push({
                label: `${col?.column_name} - true`,
                value: `${col?.column_name} - true`,
                data: col,
            })
            formattedValues.push({
                label: `${col?.column_name} - false`,
                value: `${col?.column_name} - false`,
                data: col,
            })
        })
        return formattedValues

    }, [dependentDropwdownList])


    const renderSplitInputs = () => {

        if (isDataLoading) {
            return <>
                <Skeleton height={20} width={100} className='mt-6' />
                <div className='grid grid-cols-10 gap-4 mt-2'>
                    <div className='col-span-2'>
                        <Skeleton height={20} />
                    </div>
                </div>

                <Skeleton height={20} width={100} className='mt-6' />
                <div className='grid grid-cols-10 gap-4 mt-2'>
                    <div className='col-span-2'>
                        <Skeleton height={20} />
                    </div>
                    <div className='col-span-5'>
                        <Skeleton height={20} />
                    </div>
                </div>

                <Skeleton height={20} width={100} className='mt-6' />
                {Array.from(Array(3)).map(d => (<div className='grid grid-cols-10 gap-4 mt-2'>
                    <div className='col-span-2'>
                        <Skeleton height={20} />
                    </div>
                    <div className='col-span-5'>
                        <Skeleton height={20} />
                    </div>
                </div>))}
            </>
        }
        if (!currentNodeData?.nodeCategoryId || currentNodeData?.splitTableData?.isEdit == true) return;


        // splitsList


        const uniqueCategoryValues = new Set(formik.values.splitList?.map(v => v.categoryName));
        const totalSplitPercentage = formik.values.splitList?.reduce((n, { splitPercentage }) => n + Number(splitPercentage ?? 0), 0) ?? 0
        const isSameCategory = uniqueCategoryValues.size != formik.values.splitList?.length
        const disableSave = !formik.values?.seedValue || !formik.values?.noOfSplits
            || formik.values.splitList?.filter(split => !split?.splitPercentage
                || !split?.categoryName)?.length != 0
            || totalSplitPercentage != 100 || isSameCategory || !formik.isValid

        const isValueFromSeed = splitsBySeed?.no_of_split ? true : false

        console.log({ totalSplitPercentage, allCategoryList });
        return <>
            <h2 className='font-semibold text-[12px] 2xl:text-[13px] mt-6'>Seed Value</h2>
            <div className='grid grid-cols-10 gap-4 mt-2'>
                <div className='col-span-2'>
                    <TextInput
                        placeholder="Enter seed value"
                        className={'place text-[12px] 2xl:text-[13px] custom-select-height'}
                        value={formik.values?.seedValue}
                        onChange={onSeedValueChange}
                        onBlur={onSeedValueBlur}
                    />
                </div>
            </div>

            {seedValueLoading
                ? <>
                    <Skeleton height={20} width={100} className='mt-6' />
                    <div className='grid grid-cols-10 gap-4 mt-2'>
                        <div className='col-span-2'>
                            <Skeleton height={20} />
                        </div>
                        <div className='col-span-5'>
                            <Skeleton height={20} />
                        </div>
                    </div>

                    <Skeleton height={20} width={100} className='mt-6' />
                    {Array.from(Array(3)).map(d => (<div className='grid grid-cols-10 gap-4 mt-2'>
                        <div className='col-span-2'>
                            <Skeleton height={20} />
                        </div>
                        <div className='col-span-5'>
                            <Skeleton height={20} />
                        </div>
                    </div>))}
                </>
                : <>
                    <h2 className='font-semibold text-[12px] 2xl:text-[13px] mt-6 '>Number of splits</h2>
                    <div className='grid grid-cols-10 gap-4 mt-2'>
                        <div className='col-span-2'>
                            {isValueFromSeed
                                ? <ReadOnlyValueDisplay>{formik.values?.noOfSplits}</ReadOnlyValueDisplay>
                                : <TextInput
                                    placeholder="Enter number of Splits"
                                    className={'place text-[12px] 2xl:text-[13px] h-full'}
                                    value={formik.values?.noOfSplits}
                                    onChange={onNoSplitChange}
                                    disabled={isValueFromSeed}

                                />}
                        </div>
                        <div className='col-span-5'>
                            <DropDown
                                dropDownOptions={columnDropdownValues}
                                placeholder={'Select dependent variable'}
                                name={'dependentVariable'}
                                ordinary={true}
                                disabled={false}
                                customTags={false}
                                value={formik.values?.dependentVariable ? formik.values?.dependentVariable : undefined}
                                onOptionChange={onDependentVariableChange}
                                noDataFountMessage={'No boolean columns found'}

                            />
                        </div>
                    </div>


                    <h2 className='font-semibold text-[12px] 2xl:text-[13px] mt-6 '>Split %</h2>
                    {formik.values.splitList?.map((split, index) => {
                        return <div className='grid grid-cols-10 gap-4 mt-2'>
                            <div className='col-span-2'>
                                {isValueFromSeed
                                    ? <ReadOnlyValueDisplay>{split.splitPercentage}</ReadOnlyValueDisplay>
                                    : <TextInput
                                        placeholder="Enter split percentage"
                                        className={'place text-[12px] 2xl:text-[13px] custom-select-height'}
                                        value={split.splitPercentage}
                                        name={`splitList.${index}.splitPercentage`}
                                        onBlur={formik.handleBlur}
                                        onChange={e => onSplitePercentageChange(e, index)}
                                        disabled={isValueFromSeed}
                                    />}
                                {formik.errors.splitList?.at(index)?.splitPercentage &&
                                    <p className='text-[12px] text-error-red py-1'>{formik.errors.splitList[index]?.splitPercentage}</p>}
                            </div>
                            <div className='col-span-5'>
                                <TextInput
                                    placeholder="Enter data category name"
                                    className={'place text-[12px] 2xl:text-[13px] custom-select-height'}
                                    value={split.categoryName}
                                    onBlur={formik.handleBlur}
                                    name={`splitList.${index}.categoryName`}
                                    onChange={(e) => onSpliteCategoryChange(e, index)}
                                />
                                {formik.errors.splitList?.at(index)?.categoryName &&
                                    <p className='text-[12px] text-error-red py-1'>{formik.errors.splitList[index]?.categoryName}</p>}
                            </div>
                        </div>
                    })}
                    {formik.values.splitList?.length > 0 && <div className='grid grid-cols-10 gap-4'>
                        <div className='col-span-2'>
                            {totalSplitPercentage != 100 && <p className='text-[12px] text-error-red py-1'>Percentage total must be equal to 100%</p>}
                        </div>
                        <div className='col-span-5'>
                            {isSameCategory && <p className='text-[12px] text-error-red py-1'>Category must not be same</p>}
                        </div>
                    </div>}





                    {currentNodeData?.splitTableData?.isEdit == true ? null
                        : <div className='flex items-center justify-center mt-4'>
                            <Button
                                bgColor="#42B2AC"
                                size="lg"
                                label={'Save'}
                                disabled={disableSave}
                                color={'#FFFFFF'}
                                onClick={onSubmit}
                            />
                        </div>}
                </>}



        </>
    }

    const renderSplitTable = () => {

        if (isDataLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        if (currentNodeData?.splitTableData?.splitDatas?.length > 0) {
            return <>
                {isEditMode
                    ? null
                    : <div className='2xl:py-3 py-1.5 bg-[#2B144C] grid grid-cols-3 items-center mt-4'>
                        <div className='col-span-1 flex gap-3 items-center py-0.5 ml-2'>
                            <button className='text-[#FFFFFF] bg-[#F7BE7E] py-0.5 px-2 text-[12px] rounded whitespace-nowrap'>Data category</button>
                            <p className='font-semibold text-[12px] text-white whitespace-nowrap'>{currentNodeData?.splitTableData?.categoryName}</p>
                        </div>
                        <h2 className='col-span-1 text-white text-[12px] font-semibold text-center w-full'>Calculated output</h2>
                        {currentNodeData?.splitTableData?.isEdit == true ? null
                            : <div className={`col-span-1 flex justify-end gap-1 items-center mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={() => setIsEditMode(true)}>
                                <img alt='' src={EditIcon} className='w-4 h-4' />
                                <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
                            </div>}
                    </div>}
                <SummaryTable
                    columns={summaryColumns}
                    data={summaryTableData}
                    thClassName={`${isEditMode ? "" : "bg-[#A06DFF]"}`}
                    className=''
                />

            </>
        }
    }

    const renderResultTable = () => {

        if (!currentNodeData?.nodeCategoryId) return

        if ((isDataLoading || isMergeSummaryLoading) && currentNodeData?.splitTableData?.isEdit == false) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        const mergeSummaryTableData = summaryData


        return <div className='mt-5'>

            <div className='flex justify-between items-center'>
                {<div className='flex space-x-2'>
                    {/* #42B2AC */}
                    {currentNodeData?.splitTableData?.isEdit == false
                        ? null
                        : currentNodeData?.splitTableData?.splitDatas?.map(data => (<button
                            onClick={() => setCurrentViewCategory(data?.category_id)}
                            className={`rounded-tl-md rounded-tr-md ${data?.category_id == currentViewCategory ? "bg-[#42B2AC] text-white" : "bg-[#F2F0EE]"} border border-[#E0DCD7] px-12 py-2 text-[12px] xl:text-[14px]`}>
                            {data?.category_name}
                        </button>))}
                </div>}
                {isExportLoading
                    ? <img src={downloadingIcon} className='w-6 mr-2' />
                    : <img alt='' src={XlsIcon} className=' mb-2 mr-2 object-cover cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
            </div>

            {isMergeSummaryLoading
                ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
                : <>
                    <DataTable
                        columns={dataTableColumns}
                        data={mergeSummaryTableData?.columnDatas ?? []}
                        onTableStateChange={onTableStateChange}
                        totalCount={mergeSummaryTableData?.totalRecords}
                        isOptionsDisabled={location?.state?.isfromHistory}
                        getFilterOptions={getFilterOptions}
                        tableState={{
                            pagination: {
                                pageIndex: tableState.pageIndex,
                                pageSize: tableState.pageSize,
                            },
                            sorting: tableState?.sortBy ? [
                                {
                                    id: tableState?.sortBy,
                                    desc: tableState?.sortOrder === "desc"
                                }
                            ] : [],
                            columnFilters: tableState.columnFilters
                                ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                                : [],
                            columnPinning: tableState.columnPinning,
                            columnVisibility: tableState?.columnVisibility,
                        }}
                    />
                    {currentNodeData?.splitTableData?.isEdit == false
                        ? null
                        : currentNodeData?.id && <div className={`float-right 2xl:my-6 my-3 ${location?.state?.isfromHistory ? 'hidden' : 'block'}`}>
                            <ProceedButton
                                isLoading={isProceedLoading}
                                onClick={onProceedClick}
                            />
                        </div>}
                </>}

        </div>
    }

    const disableCategory = location?.state?.isfromHistory || isDataLoading || currentNodeData?.nodeCategoryId
    return (
        filesMerging || isCalculating
            ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            : <div className='my-2'>
                {currentNodeData?.splitTableData?.isEdit == true
                    ? null
                    : <div className='flex gap-5'>
                    <DropDown
                        dropDownOptions={parseCategotyValueLabel(nodeWiseCategory)}
                        placeholder={'Select data category'}
                        name={'usedCategory'}
                        ordinary={true}
                        disabled={disableCategory}
                        customTags={false}
                        value={formik.values?.category ? formik.values?.category : undefined}
                        onOptionChange={onCategoryChange}
                    />
                    <Button
                        bgColor='#42B2AC'
                        borderType=""
                        disabled={disableCategory || !formik.values.category}
                        label={'Save'}
                        color={'#ffffff'}
                        onClick={onCategorySave}
                        size={'md'} />
                    </div>}
                {renderSplitInputs()}
                {renderSplitTable()}
                {renderResultTable()}
            </div>
    )
}

export default SplitData