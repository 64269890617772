import React, { useEffect, useMemo, useState } from 'react'
import SummaryTable from '../Table/SummaryTable'
import { createColumnHelper } from '@tanstack/react-table'
import Button from '../Button'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { downloadExport, getCalculationFormData, getMergeSummaryFileCreation, floorAndCapDetail, imputationProceed, selectCurrentNodeData, selectCurrentNodeIds, openModalBuilder, nodeClicked } from '../../Store/Workflow/WorkflowSlice'
import useDataTable from '../../hooks/useDataTable'
import DataTableSkeleton from '../Skeleton/DataTableSkeleton'

import XlsIcon from '../../Images/excel.svg'
import EditIcon from '../../Images/Group 809.svg'
import Eyeicon from '../../Images/eyeicon.svg'
import { useFormik } from 'formik'
import { IMPUTATION_FLOOR_AND_CAP_EXPORT } from '../../Helpers/EndPoints'
import { CircularProgress } from '@mui/material'
import DataTable from '../Table/DataTable'
import { validateNumber } from '../../Helpers/utils'
import { exportFloorAndCap, saveFloorAndCap } from '../../Helpers/ApiHelpers'
import DataPopup from '../PopUp/DataPopup'
import ProceedButton from '../CommonComponents/ProceedButton'
import downloadingIcon from '../../Images/gif/downloading.gif'


const ColumnDownload = ({ workflow_id, ecl_node_id, category_id, column_name, parent_node_id ,disabled}) => {

    const [isDownloading, setisDownloading] = useState(false)

    const onDownloadClick = async () => {
        setisDownloading(true)
        const request ={
            workflow_id,
            ecl_node_id,
            category_id,
            column_name,
            is_export: true,
            parent_node_id
        }
        await downloadExport(request, IMPUTATION_FLOOR_AND_CAP_EXPORT)
        setisDownloading(false)
    }
    return isDownloading
        ? <img src={downloadingIcon} className='w-6' />
        : <img alt='' onClick={onDownloadClick} src={XlsIcon} className={`${disabled ? 'pointer-events-none color-change-image':'cursor-pointer'} w-4 h-4 xl:w-5 xl:h-5`} />
}

const ColumnView = ({ workflow_id, ecl_node_id, category_id, column_name, parent_node_id,disabled }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [showPopup, setShowPopup] = useState(null)

    const onClick = async () => {
        setIsLoading(true)
        const request = {
            workflow_id,
            ecl_node_id,
            category_id,
            column_name,
            is_export: false,
            parent_node_id
        }
        const response = await exportFloorAndCap(request)
        setIsLoading(false)

        if (response?.status == 200) {

        }
        setShowPopup(response?.data)
    }


    return <>
        {showPopup && <DataPopup
            source={showPopup}
            onClose={() => setShowPopup(null)}
            title={`Replace with a Floor and caps - ${column_name}`} />}
        {isLoading
            ? < CircularProgress size={18} />
            : <button onClick={onClick} className={`${disabled ? 'text-[#707070]':'text-[#4F49DF]'}text-[12px] xl:text-[14px] flex items-center gap-2`}>
                <img alt='' src={Eyeicon} className={`w-3 ${disabled ? 'pointer-events-none color-change-image':'cursor-pointer'}`} />
                View
            </button>}
    </>
}

const columnHelper = createColumnHelper()

function FloorAndCaps() {


    const location = useLocation()
    const dispatch = useDispatch()
    const currentNodeData = useSelector(selectCurrentNodeData)
    const isDataLoading = useSelector(state => state?.Workflow?.ui?.isDataLoading)
    const isCalculating = useSelector(state => state?.Workflow?.ui?.isCalculating)
    const filesMerging = useSelector(state => state?.Workflow?.ui?.filesMerging)
    const reduxState = useSelector(state => state)
    const { currentEclNodeId, currentWorkflowId } = useSelector(selectCurrentNodeIds)
    const [isEditMode, setIsEditMode] = useState()

    useEffect(() => {
        if (currentNodeData?.floorAndCapTableData?.isEdit == false) {
            setIsEditMode(true)
        }
    }, [currentNodeData?.floorAndCapTableData?.isEdit])


    const formik = useFormik({
        initialValues: {
            floorValues: currentNodeData?.floorAndCapTableData?.floorCapDatas?.map(data => data?.floor_value),
            capValues: currentNodeData?.floorAndCapTableData?.floorCapDatas?.map(data => data?.cap_value)
        },
        enableReinitialize: true,
    })

    const { dataTableColumns,
        getFilterOptions,
        summaryData,
        tableState,
        refreshDataTable,
        onTableStateChange,
        isDataLoading: isMergeSummaryLoading,
        isExportLoading,
        donwloadXl,
    } = useDataTable({ category_id: currentNodeData?.nodeCategoryId, ecl_node_id: currentEclNodeId,parent_node_id: currentNodeData?.parent_node_id, workflow_id: currentWorkflowId, from: "floorCapDatas" })


    const onFloorValueChange = (index, value) => {
        if (validateNumber(value)) {
            formik.setFieldValue(`floorValues.${index}`, value)
        }
    }

    const onCapValueChange = (index, value) => {
        if (validateNumber(value)) {
            formik.setFieldValue(`capValues.${index}`, value)
        }
    }

    const summaryColumns = useMemo(() => {
        if (isEditMode) {
            return [
                columnHelper.accessor("exception_analysis_method", {
                    header: "Exception Analysis",
                    mergeCells: true,
                }),
                columnHelper.accessor("column_name", {
                    header: "Destination Column",
                }),
                columnHelper.display({
                    id: "floorValue",
                    header: "Floor Value",
                    tdClassName: "!py-2.5",
                    cell: ({ row }) => {
                        // const value = formik.values.userEnterValue?.at(row.index) || undefined
                        return <input
                            className={`place text-[12px] 2xl:text-[13px] h-full !border-[1px] border-[#E0DCD7] w-full rounded  2xl:h-[2.2rem]  focus:outline-none pl-4 placeholder:text-[#BEBEBE] `}
                            placeholder="Enter value"
                            onChange={(e) => onFloorValueChange(row.index, e.target.value)}
                            defaultValue={row.original?.floor_value}
                        // value={value}
                        />
                    }

                }),
                columnHelper.display({
                    id: "capValue",
                    header: "Cap Value",
                    tdClassName: "!py-2.5",
                    cell: ({ row }) => {
                        // const value = formik.values.userEnterValue?.at(row.index) || undefined
                        return <input
                            className={`place text-[12px] 2xl:text-[13px] h-full !border-[1px] border-[#E0DCD7] w-full rounded  2xl:h-[2.2rem]  focus:outline-none pl-4 placeholder:text-[#BEBEBE] `}
                            placeholder="Enter value"
                            onChange={(e) => onCapValueChange(row.index, e.target.value)}
                            defaultValue={row.original?.cap_value}
                        // value={value}
                        />
                    }

                }),
            ]
        } else {
            return [
                columnHelper.accessor("exception_analysis_method", {
                    header: "Exception Analysis",
                    mergeCells: true,
                }),
                columnHelper.accessor("column_name", {
                    header: "Destination Column",
                }),
                columnHelper.accessor("floor_value", {
                    header: "Floor Value",
                }),
                columnHelper.accessor("cap_value", {
                    header: "Cap Value",
                }),
                columnHelper.accessor("no_of_modified_values", {
                    header: "No. of modified values",
                }),
                columnHelper.accessor("percentage_of_modified_Values", {
                    header: "%of modified values",
                    thClassName: "w-[150px]",
                    cell: ({ row }) => (<span>{row.original?.percentage_of_modified_Values ? row.original?.percentage_of_modified_Values + " %"  : '-'}</span>)
                }),


                columnHelper.display({
                    id: "Actions",
                    header: "Actions",
                    thClassName: "w-[120px]",
                    cell: ({ row }) => {
                        return <div className='space-x-4 flex items-center justify-between'>
                            <ColumnView
                                category_id={currentNodeData?.nodeCategoryId}
                                column_name={row.original.column_name}
                                ecl_node_id={currentEclNodeId}
                                workflow_id={currentWorkflowId}
                                parent_node_id={currentNodeData?.parent_node_id}
                                disabled={row.original.no_of_modified_values == 0}
                            />
                            <ColumnDownload
                                category_id={currentNodeData?.nodeCategoryId}
                                column_name={row.original.column_name}
                                ecl_node_id={currentEclNodeId}
                                workflow_id={currentWorkflowId}
                                parent_node_id={currentNodeData?.parent_node_id}
                                disabled={row.original.no_of_modified_values == 0}

                            />
                        </div>
                    }

                }),
            ]
        }

    }, [isEditMode])





    const summaryTableData = useMemo(() => {
        return currentNodeData?.floorAndCapTableData?.floorCapDatas ?? []
    }, [currentNodeData?.floorAndCapTableData?.floorCapDatas]);

    console.log({ summaryTableData });


    useEffect(() => {
        dispatch(getMergeSummaryFileCreation({
            workflow_id: currentWorkflowId,
            ecl_node_id: currentEclNodeId,
        })).then(res => {
            if (res.type == `${getMergeSummaryFileCreation.fulfilled}`) {
                refreshDataTable()
                dispatch(floorAndCapDetail({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": currentNodeData?.nodeCategoryId,
                    "parent_node_id": currentNodeData?.parent_node_id,
                }))
            }
        })
    }, [])



    const onProceedClick = async () => {
        if (currentNodeData?.node_status == 'Completed') {
            return dispatch(openModalBuilder())
        }

        const requestFormData = getCalculationFormData({
            client_branch_level_id: location?.state?.levelTwoCardId,
            isFrom: "floorAndCap",
            category_id: currentNodeData?.nodeCategoryId,
            node_id: currentEclNodeId,
            state: reduxState,
            nodeStatus:'Completed'
        })

        dispatch(imputationProceed({
            requestFormData,            
        })).then(res => {
            if (res.type == `${imputationProceed.fulfilled}`) {
                setIsEditMode(false)
                refreshDataTable()
                dispatch(floorAndCapDetail({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": currentNodeData?.nodeCategoryId,
                    "parent_node_id": currentNodeData?.parent_node_id,
                }))
            }
        })

    }

    const onSave = async ()=>{
        const request = {
            "ecl_workflow_id": currentWorkflowId,
            "ecl_node_id": currentEclNodeId,
            "ecl_category_id": currentNodeData?.nodeCategoryId,
            "parent_node_id": currentNodeData?.parent_node_id,
            "floor_cap_datas": currentNodeData?.floorAndCapTableData?.floorCapDatas?.map((data, index) => ({
                "column_name": data?.column_name,
                "floor_value": parseFloat(formik.values.floorValues[index]),
                "cap_value": parseFloat(formik.values.capValues[index]),
            }))
        }
        
             const response = await saveFloorAndCap(request)
             if(response.data.status ==200){
                setIsEditMode(false)
                        refreshDataTable()
                        dispatch(floorAndCapDetail({
                            "workflow_id": currentWorkflowId,
                            "ecl_node_id": currentEclNodeId,
                            "category_id": currentNodeData?.nodeCategoryId,
                            "parent_node_id": currentNodeData?.parent_node_id,
                        }))
             }
    }

    const onEditClick = () => {
        setIsEditMode(prev => !prev)
    }


    const renderTable = () => {
        if (isDataLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        const disableSave = formik?.values?.floorValues?.filter(data => !data)?.length > 0 || formik?.values?.capValues?.filter(data => !data)?.length > 0 || summaryTableData?.length == 0
        if (currentNodeData?.nodeCategoryId) {
            return <>
                <div className={`mt-5`}>
                    {isEditMode
                        ? <div className='flex gap-3 items-center py-2'>
                                <button className='text-white bg-[#F7BE7E] py-0.5 px-2 text-[12px] rounded'>Data category</button>
                                <p className='font-semibold text-[12px]'>{currentNodeData?.floorAndCapTableData?.categoryName}</p>
                        </div>
                        : <div className='2xl:py-3 py-1.5 px-2 bg-[#4339A8] grid grid-cols-3 items-center'>
                            <div className='col-span-1 flex gap-3 items-center'>
                                <button className='text-white bg-[#F7BE7E] py-0.5 px-2 text-[12px] rounded'>Data category</button>
                                <p className='text-[#ffffff] font-semibold text-[12px]'>{currentNodeData?.floorAndCapTableData?.categoryName}</p>
                            </div>
                            <h2 className='text-white text-[12px] font-semibold text-center w-full col-span-1'>Calculated output</h2>   
                            {currentNodeData?.node_status == 'Completed'
                                ? null
                                : <div className={`col-span-1 flex gap-1 items-center justify-end mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={onEditClick}>
                                    <img alt='' src={EditIcon} className='w-4 h-4' />
                                    <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
                                </div>}
                                
                        </div>}
                </div>
                <div className={`max-h-[200px] overflow-y-auto thin-scrollbar ${isEditMode ? "" : ""}`}>
                    <SummaryTable
                        key={""}
                        columns={summaryColumns}
                        data={summaryTableData}
                        thClassName={` ${isEditMode ? "!bg-[#4339A8]" : ""} `}
                        tdClassName={`${isEditMode ? "p-4" : ""}`}
                    />
                </div>
                {isEditMode ?
                     <div className='flex justify-center my-4'>
                        <Button
                            bgColor='#42B2AC'
                            borderType=""
                            disabled={disableSave}
                            label={'Save'}
                            color={'#ffffff'}
                            onClick={onSave}
                            size={'md'} />
                    </div>
                    :""
                    }
            </>
        }

    }


    const renderResultTable = () => {

        if (!currentNodeData?.nodeCategoryId) return

        if (isDataLoading || isMergeSummaryLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        const mergeSummaryTableData = summaryData

        console.log({ summaryData });

        return <div className='mt-5'>

            <div className='flex justify-end items-center'>
                {isExportLoading
                    ? <img src={downloadingIcon} className='w-6 mr-2' />
                    : <img alt='' src={XlsIcon} className=' mb-2 object-cover mr-2 cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
            </div>

            <DataTable
                columns={dataTableColumns}
                data={mergeSummaryTableData?.columnDatas ?? []}
                onTableStateChange={onTableStateChange}
                totalCount={mergeSummaryTableData?.totalRecords}
                isOptionsDisabled={location?.state?.isfromHistory}
                getFilterOptions={getFilterOptions}
                tableState={{
                    pagination: {
                        pageIndex: tableState.pageIndex,
                        pageSize: tableState.pageSize,
                    },
                    sorting: tableState?.sortBy ? [
                        {
                            id: tableState?.sortBy,
                            desc: tableState?.sortOrder === "desc"
                        }
                    ] : [],
                    columnFilters: tableState.columnFilters
                        ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                        : [],
                    columnPinning: tableState.columnPinning,
                    columnVisibility: tableState?.columnVisibility,
                }}
            />
            <div className={`float-right 2xl:my-6 my-3 ${location?.state?.isfromHistory || currentNodeData?.node_status == 'Completed' || currentNodeData?.floorAndCapTableData?.isEdit == false ? 'hidden' : 'block'}`}>
                <ProceedButton
                    onClick={onProceedClick}
                />                
            </div>
        </div>
    }

    return (
        filesMerging || isCalculating
            ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            : <div className='my-2'>
                {renderTable()}
                {renderResultTable()}
            </div>
    )
}

export default FloorAndCaps