import { Timeline } from 'antd';
import * as React from 'react';
import ActivityLogIcons from '../CommonIcons/ActivityLogIcons';
import * as icons from '../../Components/CommonIcons/ActivityLogIconsSrc';


export default function ActivityTimeLine({ data = [] }) {
    
    return (
        data.length == 0
            ? <div className='flex items-center justify-center h-full'>
                <p className='text-[#AFABAB]'>No data found</p>
            </div>
            : <Timeline
            items={data?.map(historyItem => ({
                // dot: <ActivityLogIcons icon={historyItem?.node_id} />,
                dot: <img 
                src={icons[`Id${historyItem?.node_id}`]}
                 height={25} width={25} alt={historyItem?.operation_type}/>,
                children: <div className="ml-2.5">
                    <p className="2xl:text-[14px] text-[12px] font-semibold">{historyItem?.operation_type}</p>
                    <p className="text-[#AFABAB] 2xl:text-[13px] text-[11px]">{historyItem?.message}</p>
                    <p className='text-[#707070] 2xl:text-[11px] text-[9px]'>{historyItem?.date}</p>
                </div>,
            }))}
        />
    );
}
