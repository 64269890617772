//Dependencies
import React from 'react';
//Components
import Login from '../../Components/Authentication/Login';
import Register from '../../Components/Authentication/Register';
import ForgotPassword from '../../Components/Authentication/Forgotpassword';
import ResetPassword from '../../Components/Authentication/ResetPassword';
import Logo from '../../Components/LayOuts/Logo';
import BgImage from '../../Images/picture1.jpg';

export default function Home({isFrom}) {
  return (
    <div className="w-full h-screen overflow-hidden relative bg-tealish-blue"> 
      <div
        className="absolute inset-0 bg-no-repeat bg-cover bg-center"
        style={{ backgroundImage: `url(${BgImage})` }}
      />
      <Logo />  
      {isFrom =='login'? 
        <Login />
      :isFrom =='register'?
        <Register />
      :isFrom =='forgotPassword'?
        <ForgotPassword />
      :isFrom =='resetPassword'?
        <ResetPassword />
      :
      <></>
      }
      
    </div>  
  );
}
