import {FORGOT_PASSWORD,LOGIN,LOGOUT, REGISTER, 
    CLIENT_LIST, COUNTRY_LIST, CLIENTS_LIST_SORTING, 
    CREATE_CLIENT, RESET_PASSWORD, CURRENCY_LIST, CLIENTS_COUNTRY_LIST,
    CLIENTS_COUNTRY_LEVEL_ONE_LIST, UPDATE_LEVEL_NAME, UPDATE_CATEGORY_NAME, 
    MANAGAE_COUNTRY, UPDATE_CLIENT, MANAGAE_LEVEL, CREATE_COUNTRY_LEVEL, 
    CLIENTS_COUNTRY_LEVEL_TWO_LIST, MODEL_BUILDER_LIST, ECL_HISTORY_LIST, 
    ECL_CATEGORY_LIST, SINGLE_FILE_UPLOAD_API, DELETE_NODE_API, GET_FILE_UPLOAD_SUMMARY, 
    CHECK_WORKFLOW_NODE_API, MERGE_SUMMARY_API, DOWNLOAD_MERGE_SUMMARY_API, TABLE_UPDATION_API, 
    POSITION_ANALYSIS_CATEGORY, TEMPLATE_NAME_CHECK, FILE_NAME_EXIST_CHECK, 
    NODE_SAVE,NODE_UPDATE,RENAMED_COLUMN_LIST, GET_COLUMN_NAME_LIST,CREATE_MERGE_SUMMARY, 
    GET_MERGE_SUMMARY_RESULT,RENAME_COLUMN_SAVE, 
    CALCULATION_EXIST_CHECK,
    OUTLIER_DATA,
    OUTLIER_COLUMN_DROPDOWN_LIST,
    METHOD_DROPDOWN_LIST,
    BASED_DATAS,
    OUTLIER_ANALYSIS_SAVE,
    MISMCH_DATA,
    MISMCH_DATA_DROPDOWN,
    MISMCH_DATA_SAVE,
    NULLBLANK_VALUE_DATA,
    NULLBLANK_VALUE_DATA_SAVE,
    IMPUTATION_USER_DEFINED_DETAIL,
    IMPUTATION_USER_DEFINED_SAVE,
    IMPUTATION_USER_DEFINED_EXPORT,
    IMPUTATION_FLOOR_AND_CAP_DETAIL,
    IMPUTATION_FLOOR_AND_CAP_SAVE,
    IMPUTATION_FLOOR_AND_CAP_EXPORT,
    SPLIT_DATA_DETAIL,
    SPLIT_DATA_SEED_VALUE,
    SPLIT_DATA_SAVE,
    SPLIT_DATA_COLUMN_DROPDOWN,
    SPLIT_DATA_EXPORT,
    BINNING_COLUMN_DROPDOWN_LIST,
    BINNING_DATA_DETAIL,
    BINNING_BUCKET_DATA_LIST,
    BUCKET_TABLE_LIST,
    ZSCORE_DETAIL,
    ZSCORE_SAVE,
    ZSCORE_CALCULATION,
    DT_NUMERIC_DROPDOWN,
    BINNING_BUCKETING_DETAIL,
    BINNING_BUCKETING_PERCENTILE_DATA,
    SAVE_BINNING_BUCKETING_PERCENTILE,
    BINNING_BUCKETING_USERMAPPING_VALUE,
    NATUTAL_LOG_DETAIL,
    NATUTAL_LOG_SAVE,
    MANAGE_CATEGORY_AVAILABILITY,
    ONCHANGE_BUCKETING_USERMAPPING_VALUE,
    SAVE_BINNING_BUCKETING_USERDEFIND,
    CREATE_DUPLICATE_COLUMN_DETAIL,
    REMOVE_COLUMN_DROPDOWN_LIST,
    COLUMN_NAME_EXITS_CHECK,
    CREATE_DUPLICATE_COLUMN_SAVE,
    ADD_COLUMN_DROPDOWN_LIST,
    UNEXPECTED_VALUES_DETAIL,
    UNEXPECTED_VALUES_SAVE,
    UNEXPECTED_VALUES_EXPORT,
    REPLACE_AVERAGE_DETAIL,
    REPLACE_AVERAGE_SAVE,
    REPLACE_AVERAGE_EXPORT,
    IMPUTATION_MOST_FREQUENTLY_DETAIL,
    IMPUTATION_MOST_FREQUENTLY_SAVE,
    IMPUTATION_MOST_FREQUENTLY_EXPORT,
    REPLACE_EARLIEST_DETAIL,
    REPLACE_EARLIEST_SAVE,
    REPLACE_EARLIEST_EXPORT,
    REPLACE_LATEST_DETAIL,
    REPLACE_LATEST_SAVE,
    REPLACE_LATEST_EXPORT,
    IMPUTATION_REPLACE_MAPPING_DETAIL,
    IMPUTATION_REPLACE_MAPPING_SAVE,
    IMPUTATION_REPLACE_MAPPING_EXPORT,
    DI_MAPPING_UNIQUE_COLUMN_VALUES,
    DT_MinMaxScaling_DETAIL,
    DT_MinMaxScaling_SAVE,
    DT_MinMaxScaling_CALCULATION,
    DT_CUMULATIVE_DISTRIBUTION_DETAILS,
    DT_CUMULATIVE_DISTRIBUTION_SAVE,
    DT_NORMALIZATION_PERCENTILES_DETAILS,
    DT_NORMALIZATION_PERCENTILES_SAVE,
    DT_NORMALIZATION_DECIMAL_SCALING_DETAILS,
    DT_NORMALIZATION_DECIMAL_SCALING_SAVE,
    CALCULATE_SCALING_FACTOR,
    DT_PROBABILITY_DENSITY_DETAILS,
    DT_PROBABILITY_DENSITY_SAVE,
    DT_STANDARDISE_ROBUST_SCALING_DETAILS,
    DT_STANDARDISE_ROBUST_SCALING_SAVE,
    CALCULATE_STANDARDISE_ROBUST_SCALING
} from '../Helpers/EndPoints'

import { Baseurl } from '../Utils/Constants';
import { get_method, post_method, get_method_with_headers, download_post_method } from './AxiosHelper';


export const Login      = data => post_method(Baseurl+LOGIN, data);
export const Logout     = headers => get_method_with_headers(Baseurl + LOGOUT, headers)
export const Register   = data => post_method(Baseurl+REGISTER, data);
export const ForgotPassword  = data => post_method(Baseurl+FORGOT_PASSWORD, data);
export const ResetPassword  = data => post_method(Baseurl+RESET_PASSWORD, data);
export const ClientsList = data => post_method(Baseurl+CLIENT_LIST,data)
export const CountrysList  =()=> get_method(Baseurl+COUNTRY_LIST)
export const CurrencyList  =()=> get_method(Baseurl+CURRENCY_LIST)
export const CreateClient = data => post_method(Baseurl+CREATE_CLIENT,data)
export const UpdateClient = data => post_method(Baseurl+UPDATE_CLIENT,data)

//client list sorting

export const SortedClientsList = data => get_method(Baseurl+CLIENTS_LIST_SORTING+`?sort=${data?.sort}`+`&record=${data?.record}`+`&record_count=${data?.record_count}`);
export const SortedClientsListWithSearch = data => get_method(Baseurl+CLIENTS_LIST_SORTING+`?sort=${data?.sort}`+`&record=${data?.record}`+`&record_count=${data?.record_count}`+`&key=${data?.key}`);
export const SortedClientsListWithCountry = data => get_method(Baseurl+CLIENTS_LIST_SORTING+`?record=${data?.record}`+`&record_count=${data?.record_count}`+`&country=${data?.country}`);
export const SortedClientsListWithCountryAndSearch = data => get_method(Baseurl+CLIENTS_LIST_SORTING+`?record=${data?.record}`+`&record_count=${data?.record_count}`+`&country=${data?.country}`+`&key=${data?.key}`);

//Clients country list

export const SortedClientsCountryList = data => get_method(Baseurl+CLIENTS_COUNTRY_LIST+`/${data?.id}`+'/country/filter'+`?sort=${data?.sort}`+`&record=${data?.record}`);
export const SortedClientsCountryListWithSearch = data => get_method(Baseurl+CLIENTS_COUNTRY_LIST+`/${data?.id}`+'/country/filter'+`?sort=${data?.sort}`+`&record=${data?.record}`+`&key=${data?.key}`);
export const SortedClientsCountryListWithCountry = data =>  get_method(Baseurl+CLIENTS_COUNTRY_LIST+`/${data?.id}`+'/country/filter'+`?record=${data?.record}`+`&level=${data?.level}`);
export const SortedClientsCountryListWithCountryAndSearch = data => get_method(Baseurl+CLIENTS_COUNTRY_LIST`/${data?.id}`+'/country/filter'+`?record=${data?.record}`+`&level=${data?.level}`+`&key=${data?.key}`);


//Clients country Level one list
// export const ClientsCountryLevelOneList = data => get_method(Baseurl+CLIENTS_COUNTRY_LEVEL_ONE_LIST+`/${data?.id}`+'/levelOne/list/filter'+`?record=${data?.record}`);

export const SortedClientsCountryLevelOneList = data => get_method(Baseurl+CLIENTS_COUNTRY_LEVEL_ONE_LIST+`/${data?.id}`+'/levelOne/list/filter'+`?sort=${data?.sort}`+`&record=${data?.record}`);
export const SortedClientsCountryLevelOneListWithSearch = data => get_method(Baseurl+CLIENTS_COUNTRY_LEVEL_ONE_LIST+`/${data?.id}`+'/levelOne/list/filter'+`?sort=${data?.sort}`+`&record=${data?.record}`+`&key=${data?.key}`);
export const SortedClientsCountryLevelOneListWithCountry = data =>  get_method(Baseurl+CLIENTS_COUNTRY_LEVEL_ONE_LIST+`/${data?.id}`+'/levelOne/list/filter'+`?record=${data?.record}`+`&level=${data?.level}`);
export const SortedClientsCountryLevelOneListWithCountryAndSearch = data => get_method(Baseurl+CLIENTS_COUNTRY_LEVEL_ONE_LIST`/${data?.id}`+'/country/filter'+`?record=${data?.record}`+`&level=${data?.level}`+`&key=${data?.key}`);

export const SortedClientsCountryLevelTwoList = data => get_method(Baseurl+CLIENTS_COUNTRY_LEVEL_TWO_LIST+`/${data?.data?.data?.client_country_id}`+'/levelTwo'+`/${data?.data?.data?.id}`+'/list/filter'+`?sort=${data?.sort}`+`&record=${data?.record}`);
export const SortedClientsCountryLevelTwoListWithSearch = data => get_method(Baseurl+CLIENTS_COUNTRY_LEVEL_TWO_LIST+`/${data?.data?.data?.client_country_id}`+'/levelTwo/'+`${data?.data?.data?.id}`+'/list/filter'+`?sort=${data?.sort}`+`&record=${data?.record}`+`&key=${data?.key}`);
export const SortedClientsCountryLevelTwoListWithCountry = data =>  get_method(Baseurl+CLIENTS_COUNTRY_LEVEL_TWO_LIST+`/${data?.data?.data?.client_country_id}`+'/levelTwo/'+`${data?.data?.data?.id}`+'/list/filter'+`?record=${data?.record}`+`&level=${data?.level}`);
export const SortedClientsCountryLevelTwoListWithCountryAndSearch = data => get_method(Baseurl+CLIENTS_COUNTRY_LEVEL_TWO_LIST`/${data?.data?.data?.client_country_id}`+'/levelTwo/'+`${data?.data?.data?.id}`+'/list/filter'+`?record=${data?.record}`+`&level=${data?.level}`+`&key=${data?.key}`);


//level name update
export const LevelNameUpdate = data => post_method(Baseurl+UPDATE_LEVEL_NAME,data)
//category name update
export const CategoryNameUpdate = data => post_method(Baseurl+UPDATE_CATEGORY_NAME,data)
//Manage country
export const ManageCountry = data => post_method(Baseurl+MANAGAE_COUNTRY,data)
//Manage Level
export const ManageLevel = data => post_method(Baseurl+MANAGAE_LEVEL,data)
//Create country Level
export const CreateCountryLevel = data => post_method(Baseurl+CREATE_COUNTRY_LEVEL,data)
//Model builder list
export const ModelBuilderList =()=> get_method(Baseurl+MODEL_BUILDER_LIST)
//Ecl history
export const SortedEclHistoryList = data => get_method(Baseurl+ECL_HISTORY_LIST+`/${data?.id}`+'/history/filter'+`?sort=${data?.sort}`);
export const SortedEclHistoryListForName = data => get_method(Baseurl+ECL_HISTORY_LIST+`/${data?.id}`+'/history/filter'+`?user=${data?.key}`);
//get Node Details
export const getNodeDetailsAPI = data => get_method(Baseurl+'/ecl/calculation'+`/${data?.id}`+`/details`);
//Category list
export const EclCategoryList =(data)=> get_method(Baseurl+'/ecl'+`/${data?.id}`+`/${data?.level_two_id}`+'/manageCategory/list')
//single fle uoload
export const SingleFileUpload =(data,headers)=>post_method(Baseurl+SINGLE_FILE_UPLOAD_API,data,headers)
//get perticular node details
export const getPerticularNodeDetails = (data) => post_method(Baseurl+'/ecl/calculation/nodeDetails',data);
//delete node 
export const deleteNodeApi = data => post_method(Baseurl+DELETE_NODE_API,data);
//file upload summary 
export const fileUploadSummaryApi = data => post_method(Baseurl+GET_FILE_UPLOAD_SUMMARY,data);
//check workflow node 
export const checkWorkflowNodeApi = data => get_method(Baseurl+CHECK_WORKFLOW_NODE_API+`/${data}`+'/workflowNode/check',data);
//check workflow node 
export const mergeSummaryApi = data => get_method(Baseurl+MERGE_SUMMARY_API+`/${data?.workflow_id}`+`/${data?.ecl_node_id}`+'/mergeSummary',data);
//download merged summary
export const downloadMergeSummaryApi = data => post_method(Baseurl+DOWNLOAD_MERGE_SUMMARY_API,data);

//table updation
export const tableUpdationApi = data => post_method(Baseurl+ TABLE_UPDATION_API ,data);
//get file details
export const getFileDetailsApi = data => get_method(Baseurl+'/ecl/calculation'+`/${data?.workflow_id}/${data?.category_id}`+`/fileDetails`);

//get postion analysis categoy
export const getPositionAnalysisCategory = id => get_method(Baseurl+'/ecl/calculation'+`/${id}`+POSITION_ANALYSIS_CATEGORY);


export const createMergeSummary = data => post_method(Baseurl + CREATE_MERGE_SUMMARY, data);


export const mergeSummaryResult = data => post_method(Baseurl + GET_MERGE_SUMMARY_RESULT, data);


// get template name check

export const templatename_check = (data) => post_method(Baseurl+TEMPLATE_NAME_CHECK , data);

//check file name in workflow
export const fileName_check = (data) => post_method(Baseurl+FILE_NAME_EXIST_CHECK , data);

//node save

export const nodeSave = (data,headers)=> post_method(Baseurl+NODE_SAVE,data,headers,null,'multipart/form-data')

//node update

export const nodeUpdate = (data,headers)=> post_method(Baseurl+NODE_UPDATE,data,headers,null,'multipart/form-data')

//node wise catgory list 

export const nodeWiseCategoryList = data =>  get_method(Baseurl+'/ecl/calculation'+`/${data?.workflow_id}`+`/${data?.ecl_node_id}`+'/nodeWise/categoryList'); 

//renamed column list

export const renamedColumnList = data => post_method(Baseurl+RENAMED_COLUMN_LIST,data);

//renamable column list

export const columnListForRename = data => post_method(Baseurl+GET_COLUMN_NAME_LIST,data)

//save rename column

export const saveRenameColumn = data => post_method(Baseurl+RENAME_COLUMN_SAVE,data);

//activity log

export const activityLog = data => get_method(Baseurl+'/ecl/calculation/activityLog'+`/${data?.workflow_id}`);

//delete file calculation check

export const deleteCalculationFileCheck = data => post_method(Baseurl+CALCULATION_EXIST_CHECK,data)

//get outlier data

export const outlierData = data => post_method(Baseurl+OUTLIER_DATA,data);

//get column name

export const outlierColumnName = data => post_method(Baseurl+OUTLIER_COLUMN_DROPDOWN_LIST,data);

//get outlaier methods

export const getOutLierMethods = data => post_method(Baseurl+METHOD_DROPDOWN_LIST ,data);

//selected method based data

export const selectedMethodBasedData = data => post_method(Baseurl+BASED_DATAS ,data);

//save outlier

export const saveOutlierAnalysis = data => post_method(Baseurl+OUTLIER_ANALYSIS_SAVE ,data);



export const mismatchDetail = data => post_method(Baseurl + MISMCH_DATA, data)

export const dataImputationdList = data => post_method(Baseurl + MISMCH_DATA_DROPDOWN, data)

export const saveMismatch = data => post_method(Baseurl + MISMCH_DATA_SAVE, data)

export const nullBlankDetail = data => post_method(Baseurl + NULLBLANK_VALUE_DATA, data)

export const saveNullBlank = data => post_method(Baseurl + NULLBLANK_VALUE_DATA_SAVE, data)

export const exportPost = (url, data) => download_post_method(Baseurl + url, data);

export const getImputationUserDefinedDetails = data => post_method(Baseurl + IMPUTATION_USER_DEFINED_DETAIL, data)
export const saveImputationUserDefined = data => post_method(Baseurl + IMPUTATION_USER_DEFINED_SAVE, data)
export const exportImputationUserDefined = data => post_method(Baseurl + IMPUTATION_USER_DEFINED_EXPORT, data)

export const getReplaceAverageDetails = data => post_method(Baseurl + REPLACE_AVERAGE_DETAIL, data)
export const saveReplaceAverage = data => post_method(Baseurl + REPLACE_AVERAGE_SAVE, data)
export const exportReplaceAverage = data => post_method(Baseurl + REPLACE_AVERAGE_EXPORT, data)

export const getReplaceEarliestDetails = data => post_method(Baseurl + REPLACE_EARLIEST_DETAIL, data)
export const saveReplaceEarliest = data => post_method(Baseurl + REPLACE_EARLIEST_SAVE, data)
export const exportReplaceEarliest= data => post_method(Baseurl + REPLACE_EARLIEST_EXPORT, data)

export const getReplaceLatestDetails = data => post_method(Baseurl + REPLACE_LATEST_DETAIL, data)
export const saveReplaceLatest = data => post_method(Baseurl + REPLACE_LATEST_SAVE, data)
export const exportReplaceLatest= data => post_method(Baseurl + REPLACE_LATEST_EXPORT, data)

export const getFloorAndCapDetails = data => post_method(Baseurl + IMPUTATION_FLOOR_AND_CAP_DETAIL, data)
export const saveFloorAndCap = data => post_method(Baseurl + IMPUTATION_FLOOR_AND_CAP_SAVE, data)
export const exportFloorAndCap = data => post_method(Baseurl + IMPUTATION_FLOOR_AND_CAP_EXPORT, data)


export const getSplitDataDetails = data => post_method(Baseurl + SPLIT_DATA_DETAIL, data)
export const getSplitsBySeedValue = data => get_method(`${Baseurl + SPLIT_DATA_SEED_VALUE}/${data?.seedValue}`)
export const saveSplitData = data => post_method(Baseurl + SPLIT_DATA_SAVE, data)
export const getSplitDataColumnDropdown = data => post_method(Baseurl + SPLIT_DATA_COLUMN_DROPDOWN, data)
export const exportSplitData = data => post_method(Baseurl + SPLIT_DATA_EXPORT, data)

export const binningDetail = data => post_method(Baseurl + BINNING_DATA_DETAIL, data)
export const binningColumnList = data => post_method(Baseurl + BINNING_COLUMN_DROPDOWN_LIST, data)
export const binningOnchangeData = data => post_method(Baseurl + BINNING_BUCKET_DATA_LIST, data)
export const bucketDetail = data => post_method(Baseurl + BUCKET_TABLE_LIST, data)



export const getZscoreDetails = data => post_method(Baseurl + ZSCORE_DETAIL, data)
export const saveZscore = data => post_method(Baseurl + ZSCORE_SAVE, data)
export const getZscoreCalculation = data => post_method(Baseurl + ZSCORE_CALCULATION, data)

export const getDtNumericDropdownList = data => post_method(Baseurl + DT_NUMERIC_DROPDOWN, data)

export const binningBucketingPercentileDetail = data => post_method(Baseurl + BINNING_BUCKETING_DETAIL, data)
export const getBinningBucketingPercentileData = data => post_method(Baseurl+BINNING_BUCKETING_PERCENTILE_DATA,data)
export const saveBinningBucketingPercentile = data => post_method(Baseurl + SAVE_BINNING_BUCKETING_PERCENTILE, data)


export const binningbucketdata = data => post_method(Baseurl + BINNING_BUCKETING_USERMAPPING_VALUE , data)
export const onChangebucketdata = data => post_method(Baseurl + ONCHANGE_BUCKETING_USERMAPPING_VALUE , data)
export const saveBinningBucketingUserdefined = data => post_method(Baseurl + SAVE_BINNING_BUCKETING_USERDEFIND, data)


export const getNaturalLogDetails = data => post_method(Baseurl + NATUTAL_LOG_DETAIL, data)
export const saveNaturalLog = data => post_method(Baseurl + NATUTAL_LOG_SAVE, data)

export const checkCategoryExist = data => post_method(Baseurl + MANAGE_CATEGORY_AVAILABILITY, data)

export const createDuplicateColumnDetail = data => post_method(Baseurl + CREATE_DUPLICATE_COLUMN_DETAIL, data)

export const duplicateColumnNamesList = data => post_method(Baseurl+ REMOVE_COLUMN_DROPDOWN_LIST,data);

export const columnNameExistCheck = data => post_method(Baseurl+ COLUMN_NAME_EXITS_CHECK,data)

export const saveCreateDuplicateColumn = data => post_method(Baseurl+CREATE_DUPLICATE_COLUMN_SAVE, data)

export const duplicateRemoveCheck = data => post_method(Baseurl+ ADD_COLUMN_DROPDOWN_LIST, data)

export const geUnexpectedValuesDetails = data => post_method(Baseurl + UNEXPECTED_VALUES_DETAIL, data)
export const saveUnexpectedValues = data => post_method(Baseurl + UNEXPECTED_VALUES_SAVE, data)
export const exportUnexpectedValues = data => post_method(Baseurl + UNEXPECTED_VALUES_EXPORT, data)

export const getMostFreqOccuringDetails = data => post_method(Baseurl + IMPUTATION_MOST_FREQUENTLY_DETAIL, data)
export const saveMostFreqOccuring = data => post_method(Baseurl +  IMPUTATION_MOST_FREQUENTLY_SAVE, data)
export const exportMostFreqOccuring = data => post_method(Baseurl + IMPUTATION_MOST_FREQUENTLY_EXPORT, data)

export const getImputationReplaceMappingDetails = data => post_method(Baseurl + IMPUTATION_REPLACE_MAPPING_DETAIL, data)
export const saveImputationReplaceMapping = data => post_method(Baseurl +  IMPUTATION_REPLACE_MAPPING_SAVE, data)
export const exportImputationReplaceMapping = data => post_method(Baseurl + IMPUTATION_REPLACE_MAPPING_EXPORT, data)
export const getUniqueValuesInColumn = data => post_method(Baseurl+ DI_MAPPING_UNIQUE_COLUMN_VALUES, data) 

export const getstandardizeMinMaxDetails = data => post_method(Baseurl + DT_MinMaxScaling_DETAIL, data)
export const savestandardizeMinMax = data => post_method(Baseurl + DT_MinMaxScaling_SAVE, data)
export const getStandardizeMinMaxCalculation = data => post_method(Baseurl + DT_MinMaxScaling_CALCULATION, data)

export const getCumulativeDistibutionDetailsApi = data => post_method(Baseurl+ DT_CUMULATIVE_DISTRIBUTION_DETAILS,data)
export const getCumulativeDistibutionCalculation = data => post_method(Baseurl + ZSCORE_CALCULATION, data)
export const saveCumulativeDistributionCalculation = data => post_method(Baseurl + DT_CUMULATIVE_DISTRIBUTION_SAVE,data)

export const getProbabilityDensityDetailsApi = data => post_method(Baseurl+ DT_PROBABILITY_DENSITY_DETAILS,data)
export const getProbabilityDensityCalculation = data => post_method(Baseurl + ZSCORE_CALCULATION, data)
export const saveProbabilityDensityCalculation = data => post_method(Baseurl + DT_PROBABILITY_DENSITY_SAVE,data)

export const getNormalizationPercentilesDetails = data => post_method(Baseurl + DT_NORMALIZATION_PERCENTILES_DETAILS, data)
export const saveNormalizationPercentiles = data => post_method(Baseurl + DT_NORMALIZATION_PERCENTILES_SAVE,data)

export const getNormalizationDecimalScalingDetails = data => post_method(Baseurl + DT_NORMALIZATION_DECIMAL_SCALING_DETAILS, data)
export const saveNormalizationDecimalScaling = data => post_method(Baseurl + DT_NORMALIZATION_DECIMAL_SCALING_SAVE,data)
export const calculateScalingFactor = data => post_method(Baseurl + CALCULATE_SCALING_FACTOR, data)

export const getStandardiseRobustScalingDetails = data => post_method(Baseurl + DT_STANDARDISE_ROBUST_SCALING_DETAILS, data)
export const saveStandardiseRobustScaling = data => post_method(Baseurl + DT_STANDARDISE_ROBUST_SCALING_SAVE,data)
export const calculateRobustScaling = data => post_method(Baseurl + CALCULATE_STANDARDISE_ROBUST_SCALING, data)