import React, { useEffect, useMemo, useState } from 'react'
import SummaryTable from '../Table/SummaryTable'
import { createColumnHelper } from '@tanstack/react-table'
import Button from '../Button'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { downloadExport, getCalculationFormData, getMergeSummaryFileCreation, imputationReplaceAverageDetail, imputationProceed, openModalBuilder, selectCurrentNodeData, selectCurrentNodeIds, selectCurrentEclNodeData, fetchColumnListDatatype } from '../../Store/Workflow/WorkflowSlice'
import useDataTable from '../../hooks/useDataTable'
import DataTableSkeleton from '../Skeleton/DataTableSkeleton'

import XlsIcon from '../../Images/excel.svg'
import Eyeicon from '../../Images/eyeicon.svg'
import { useFormik } from 'formik'
import { REPLACE_AVERAGE_EXPORT } from '../../Helpers/EndPoints'
import { CircularProgress } from '@mui/material'
import downloadingIcon from '../../Images/gif/downloading.gif'
import DataTable from '../Table/DataTable'
import { exportReplaceAverage, saveReplaceAverage } from '../../Helpers/ApiHelpers'
import DataPopup from '../PopUp/DataPopup'
import EditIcon from '../../Images/Group 809.svg'
import DropDown from '../DropDown/DropDown'
import ProceedButton from '../CommonComponents/ProceedButton'
import { methodDropdDown } from '../../Utils/Constants'

const ColumnDownload = ({ workflow_id, ecl_node_id, category_id, column_name, parent_node_id ,disabled}) => {

    const [isDownloading, setisDownloading] = useState(false)

    const onDownloadClick = async () => {
        setisDownloading(true)
        const request = {
            workflow_id,
            ecl_node_id,
            category_id,
            column_name,
            is_export: true,
            parent_node_id
        }
        await downloadExport(request, REPLACE_AVERAGE_EXPORT)
        setisDownloading(false)
    }
    return isDownloading
        ? <img src={downloadingIcon} className='w-6' />
        : <img alt='' onClick={onDownloadClick} src={XlsIcon} className={`${disabled ? 'pointer-events-none color-change-image':'cursor-pointer'} w-4 h-4 xl:w-5 xl:h-5`} />
}

const ColumnView = ({ workflow_id, ecl_node_id, category_id, column_name, parent_node_id,disabled }) => {

    const [isLoading, setIsLoading] = useState(false)
    const [showPopup, setShowPopup] = useState(null)
    const currentEclNodeData = useSelector(selectCurrentEclNodeData)

    const onClick = async () => {
        setIsLoading(true)
        const request = {
            workflow_id,
            ecl_node_id,
            category_id,
            column_name,
            is_export: false,
            parent_node_id
        }
        const response = await exportReplaceAverage(request)
        setIsLoading(false)
        console.log({ response });
        setShowPopup(response?.data)
    }



    return <>
        {showPopup && <DataPopup
            source={showPopup}
            onClose={() => setShowPopup(null)}
            title={`${currentEclNodeData?.name || ""} - ${column_name}`} />}
        {isLoading
            ? < CircularProgress size={18} />
            : <button onClick={onClick} className={`${disabled ? 'text-[#707070]':'text-[#4F49DF]'}text-[12px] xl:text-[14px] flex items-center gap-2`}>
                <img alt='' src={Eyeicon} className={`w-3 ${disabled ? 'pointer-events-none color-change-image':'cursor-pointer'}`} />
                View
            </button>}
    </>
}



const columnHelper = createColumnHelper()

function ReplaceAverageByColumn() {


    const location = useLocation()
    const dispatch = useDispatch()
    const nodeWiseCategory = useSelector(state => state?.Workflow?.nodeWiseCategory)
    const currentNodeData = useSelector(selectCurrentNodeData)
    const isDataLoading = useSelector(state => state?.Workflow?.ui?.isDataLoading)
    const isCalculating = useSelector(state => state?.Workflow?.ui?.isCalculating)
    const filesMerging = useSelector(state => state?.Workflow?.ui?.filesMerging)
    const reduxState = useSelector(state => state)
    const { currentEclNodeId, currentWorkflowId, currentWorkflowNodeId } = useSelector(selectCurrentNodeIds)
    const [isEditMode, setIsEditMode] = useState()
    const baseColumnList = useSelector(state => state?.Workflow?.columnList ?? [])

    useEffect(() => {
        if (currentNodeData?.replaceAverageTableData?.isEdit == false) {
            setIsEditMode(true)
        }
    }, [currentNodeData?.replaceAverageTableData?.isEdit])


    const formik = useFormik({
        initialValues: {
            base_column: currentNodeData?.replaceAverageTableData?.replaceValueBasedOnAverageDatas?.map(data => data?.base_column),
            replace_method: currentNodeData?.replaceAverageTableData?.replaceValueBasedOnAverageDatas?.map(data => data?.replace_method)
        },
        enableReinitialize: true,
    })

    console.log(currentNodeData,'currentNodeData?.replaceAverageTableData');

    const { dataTableColumns,
        getFilterOptions,
        summaryData,
        tableState,
        refreshDataTable,
        onTableStateChange,
        isDataLoading: isMergeSummaryLoading,
        isExportLoading,
        donwloadXl,
    } = useDataTable({
        category_id: currentNodeData?.nodeCategoryId,
        ecl_node_id: currentEclNodeId,
        workflow_id: currentWorkflowId,
        from: "imputationReplaceAverage",
        parent_node_id: currentNodeData?.parent_node_id
    })


    const columnDropdownValues = useMemo(() => {
        let formattedValues = []

        baseColumnList?.forEach(data => {
            formattedValues.push({
                label: data?.column_name,
                value: data?.column_name,
                data: data,
            })
        })
        return formattedValues

    }, [baseColumnList])

    const onUserEnterValueChange = (index, value) => {
        formik.setFieldValue(`base_column.${index}`, value)
    }
    const onReplaceMethodChange = (index, value) => {
        formik.setFieldValue(`replace_method.${index}`, value)
    }

    const getValueByIndex = index => formik.values.base_column?.at(index)

    const summaryColumns = useMemo(() => {
        if (isEditMode) {
            return [
                columnHelper.accessor("exception_analysis_method", {
                    header: "Exception Analysis",
                    mergeCells: true,
                    tdClassName: "!py-2.5 !p-2",

                }),
                columnHelper.accessor("column_name", {
                    header: "Destination Column",
                    tdClassName: "!py-2.5 !p-2",

                }),
                columnHelper.display({
                    id: "baseColumn",
                    header: "Base column",
                    tdClassName: "!py-2.5 !p-2",
                    cell: ({ row }) => {
                        // const dataType = row?.original?.column_datatype
                        const dataType = row?.original?.column_datatype;
                        const currentBaseColumn = formik.values.base_column[row.index];
    
                        const baseColumnOptions = columnDropdownValues.map(data => {
                            return {
                                label: data?.label,
                                value: data?.value,
                                disabled: data?.value === row.original.column_name || data?.value === currentBaseColumn,
                            };
                        });
                        return <div className='w-[14rem]'><DropDown
                            dropDownOptions={baseColumnOptions}
                            placeholder={'Select column'}
                            name={'columnName'}
                            border={'1px solid #E0DCD7'}
                            ordinary={true}
                            disabled={false}
                            customTags={false}
                            value={formik.values.base_column[row.index] || undefined}
                            onOptionChange={(value) => onUserEnterValueChange(row.index, value, dataType)}
                        /></div>
                    }
                }),
                columnHelper.display({
                    id: "method",
                    header: "Method",
                    tdClassName: "!py-2.5 !p-2",
                    cell: ({ row }) => {
                        const dataType = row?.original?.column_datatype
                        return <div className='w-[14rem]'><DropDown
                            dropDownOptions={methodDropdDown}
                            placeholder={'Select Method'}
                            name={'selectmethod'}
                            ordinary={true}
                            border={'1px solid #E0DCD7'}
                            disabled={false}
                            customTags={false}
                            value={formik.values.replace_method[row.index] || undefined}
                            onOptionChange={(value) => onReplaceMethodChange(row.index, value, dataType)}
                        />
                        </div>
                    }
                }),
            ]
        } else {
            return [
                columnHelper.accessor("exception_analysis_method", {
                    header: "Exception Analysis",
                    mergeCells: true,
                }),
                columnHelper.accessor("column_name", {
                    header: "Destination Column",
                }),
                columnHelper.accessor("base_column", {
                    header: "Base column",
                }),
                columnHelper.accessor("replace_method_label", {
                    header: "Method",
                }),
                columnHelper.accessor("no_of_modified_values", {
                    header: "No. of modified values",
                }),
                columnHelper.accessor("percentage_of_modified_values", {
                    header: "Percentage of modified values",
                    thClassName: "w-[200px]",
                    cell: ({ row }) => (<span>{row.original?.percentage_of_modified_values ? row.original?.percentage_of_modified_values + " %"  : '-'}</span>)

                }),


                columnHelper.display({
                    id: "Actions",
                    header: "Actions",
                    thClassName: "w-[120px]",
                    cell: ({ row }) => {
                        return <div className='space-x-4 flex items-center justify-between'>
                            <ColumnView
                                category_id={currentNodeData?.nodeCategoryId}
                                column_name={row.original.column_name}
                                ecl_node_id={currentEclNodeId}
                                workflow_id={currentWorkflowId}
                                disabled={row.original.no_of_modified_values == 0}
                                parent_node_id={currentNodeData?.parent_node_id}
                            />
                            <ColumnDownload
                                category_id={currentNodeData?.nodeCategoryId}
                                column_name={row.original.column_name}
                                ecl_node_id={currentEclNodeId}
                                disabled={row.original.no_of_modified_values == 0}
                                workflow_id={currentWorkflowId}
                                parent_node_id={currentNodeData?.parent_node_id}
                            />
                        </div>
                    }

                }),
            ]
        }

    }, [isEditMode, columnDropdownValues,formik.values])


    const summaryTableData = useMemo(() => {
        return currentNodeData?.replaceAverageTableData?.replaceValueBasedOnAverageDatas ?? []
    }, [currentNodeData?.replaceAverageTableData?.replaceValueBasedOnAverageDatas]);

    console.log({ summaryTableData, formik });


    useEffect(() => {
        dispatch(getMergeSummaryFileCreation({
            workflow_id: currentWorkflowId,
            ecl_node_id: currentEclNodeId,
        })).then(res => {
            if (res.type == `${getMergeSummaryFileCreation.fulfilled}`) {
                refreshDataTable()
                dispatch(imputationReplaceAverageDetail({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": currentNodeData?.nodeCategoryId,
                    "parent_node_id": currentNodeData?.parent_node_id,
                })).then(response => {
                    if (response.type == `${imputationReplaceAverageDetail.fulfilled}`) {

                    dispatch(fetchColumnListDatatype({
                        "workflow_id": currentWorkflowId,
                        "ecl_node_id": currentEclNodeId,
                        "category_id": currentNodeData?.nodeCategoryId,
                        "data_type": ["Integer"],
                    }))
                }
                })
                
            }
            
        })       
    }, [])


    const onProceedClick = async () => {
        if (currentNodeData?.node_status == 'Completed') {
            return dispatch(openModalBuilder())
        }

        const requestFormData = getCalculationFormData({
            client_branch_level_id: location?.state?.levelTwoCardId,
            isFrom: "DataImputationReplaceAverage",
            category_id: currentNodeData?.nodeCategoryId,
            node_id: currentEclNodeId,
            state: reduxState,
            nodeStatus: 'Completed'
        })

        dispatch(imputationProceed({
            requestFormData,

        }))

            .then(res => {
                if (res.type == `${imputationProceed.fulfilled}`) {
                    setIsEditMode(false)
                    refreshDataTable()
                    dispatch(imputationReplaceAverageDetail({
                        "workflow_id": currentWorkflowId,
                        "ecl_node_id": currentEclNodeId,
                        "category_id": currentNodeData?.nodeCategoryId,
                        "parent_node_id": currentNodeData?.parent_node_id,
                    }))
                }
            })

    }
    const onSave = async () => {
        const request = {
            "ecl_workflow_id": currentWorkflowId,
            "ecl_node_id": currentEclNodeId,
            "ecl_category_id": currentNodeData?.nodeCategoryId,
            "parent_node_id": currentNodeData?.parent_node_id,
            "replaceBasedOnAverageDatas": currentNodeData?.replaceAverageTableData?.replaceValueBasedOnAverageDatas?.map((data, index) => ({
                "column_name": data?.column_name,
                "base_column": formik.values.base_column[index],
                "replace_method" : formik.values.replace_method[index]
            }))
        }
        const response = await saveReplaceAverage(request)
        if (response.data.status == 200) {
            setIsEditMode(false)
            refreshDataTable()
            dispatch(imputationReplaceAverageDetail({
                "workflow_id": currentWorkflowId,
                "ecl_node_id": currentEclNodeId,
                "category_id": currentNodeData?.nodeCategoryId,
                "parent_node_id": currentNodeData?.parent_node_id,
            }))

        }
        console.log(response, 'response data');

    }


    const onEditClick = () => {
        setIsEditMode(prev => !prev)
    }

    const renderTable = () => {
        if (isDataLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        const disableSave = formik?.values?.base_column?.filter(data => !data)?.length > 0 || summaryTableData?.length == 0
        if (currentNodeData?.nodeCategoryId) {
            return <>
                <div className={`mt-5`}>
                    {isEditMode
                        ? <div className='flex gap-3 items-center py-2'>
                                <button className='text-[#ffffff]  py-0.5 px-2 text-[12px] bg-[#FFC048] rounded-[4px] whitespace-nowrap'>Data category</button>
                                <p className='font-semibold text-[12px]'>{currentNodeData?.replaceAverageTableData?.categoryName}</p>
                        </div>
                        : <div className='2xl:py-3 py-1.5 px-2 bg-[#4339A8] grid grid-cols-3 items-center'>
                            <div className=' flex gap-3 items-center col-span-1'>
                            <button className='text-[#ffffff]  py-0.5 px-2 text-[12px] bg-[#FFC048] rounded-[4px] whitespace-nowrap'>Data category</button>
                            <p className='text-[#ffffff] font-semibold text-[12px] whitespace-nowrap'>{currentNodeData?.replaceAverageTableData?.categoryName}</p>
                            </div>
                            <h2 className='text-white text-[12px] font-semibold text-center w-full col-span-1'>Calculated output</h2>   
                           { currentNodeData?.node_status == 'Completed' ? null
                            : <div className={`col-span-1 flex justify-end gap-1 items-center mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={() => setIsEditMode(true)}>
                                <img alt='' src={EditIcon} className='w-4 h-4' />
                                <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
                            </div>
                           }                         
                        </div>}
                </div>
                <div className={`max-h-[200px] overflow-y-auto thin-scrollbar ${isEditMode ? "" : ""}`}>
                    <SummaryTable
                        key={""}
                        columns={summaryColumns}
                        data={summaryTableData}
                        thClassName={` ${isEditMode ? "!bg-[#4339A8]" : ""} `}
                        tdClassName={`${isEditMode ? "p-4" : ""}`}
                    />
                </div>
                {isEditMode
                    ? <div className='flex justify-center my-4'>
                        <Button
                            bgColor='#42B2AC'
                            borderType=""
                            disabled={disableSave}
                            label={'Save'}
                            color={'#ffffff'}
                            onClick={onSave}
                            size={'md'} />
                    </div>
                    : ""}
            </>
        }

    }


    const renderResultTable = () => {

        if (!currentNodeData?.nodeCategoryId) return

        if (isDataLoading || isMergeSummaryLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        const mergeSummaryTableData = summaryData

        console.log({ summaryData });

        return <div className='mt-5'>

            <div className='flex justify-end items-center'>
                {isExportLoading
                    ? <img src={downloadingIcon} className='w-6 mr-2' />
                    : <img alt='' src={XlsIcon} className=' mb-2 object-cover mr-2 cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
            </div>

            <DataTable
                columns={dataTableColumns}
                data={mergeSummaryTableData?.columnDatas ?? []}
                onTableStateChange={onTableStateChange}
                totalCount={mergeSummaryTableData?.totalRecords}
                isOptionsDisabled={location?.state?.isfromHistory}
                getFilterOptions={getFilterOptions}
                tableState={{
                    pagination: {
                        pageIndex: tableState.pageIndex,
                        pageSize: tableState.pageSize,
                    },
                    sorting: tableState?.sortBy ? [
                        {
                            id: tableState?.sortBy,
                            desc: tableState?.sortOrder === "desc"
                        }
                    ] : [],
                    columnFilters: tableState.columnFilters
                        ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                        : [],
                    columnPinning: tableState.columnPinning,
                    columnVisibility: tableState?.columnVisibility,
                }}
            />
            {currentNodeData?.replaceAverageTableData?.isEdit == false ? null :
                <div className={`float-right 2xl:my-6 my-3 ${location?.state?.isfromHistory || currentNodeData?.node_status == 'Completed' ? 'hidden' : 'block'}`}>
                    <ProceedButton
                        onClick={onProceedClick}
                    />
                    {/* <Button
                        className={'bg-[#70AD47] text-white !px-[2rem] !2xl:px-[2rem] py-1.5 rounded-[5px]'}
                        label={'Proceed'}
                        onClick={() => onProceedClick()}
                    /> */}

                </div>
            }

        </div>
    }

    return (
        filesMerging || isCalculating
            ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            : <div className='my-2'>
                {renderTable()}
                {renderResultTable()}
            </div>
    )
}

export default ReplaceAverageByColumn