
//Dependecies
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useForm} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
//imgaes
import avatar from '../../Images/avatar.png'
import user from '../../Images/userProfile.png'
//components
import Warning from '../Indications/Warning'
import { NavLink } from 'react-router-dom';
import RouteSegments from '../../Routes/RouteSegments';
//Redux components
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword, forgotPasswordError } from '../../Store/action';
import withRouter from '../../Routes/withRouter';


const schema = yup.object().shape({  
  username: yup
  .string()
  .email('Please enter a valid email id')
  .required('Please enter email'),  
});

const Forgotpassword = (props) => {
  const { register, handleSubmit, setFocus, formState: { errors} } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(forgotPassword(data))
  };

   const { forgotPasswordData } = useSelector(state => ({
    forgotPasswordData: state?.login?.forgotPasswordData?.message
  }));


  useEffect(()=>{console.log(forgotPasswordData,'forgotPasswordData');},[forgotPasswordData])

  const [username, setUserName] = useState('')

  useEffect(() => {
   if(errors?.username == undefined){
    setFocus("username");}
  }, [setFocus]);

  useEffect(() => {
    // Reset the login error when the component is unmounted or when navigating away
    return () => {
      dispatch(forgotPasswordError());
    };
  }, []);
  
  return (
    <div className="w-full flex items-center justify-end h-full absolute right-[10%]">
      <div className="w-[75%] max-w-sm">
        <div className="p-4">
          <div className="py-3 flex items-center before:flex-[1_1_0%] before:border-t before:border-carrot-orange ltr:before:mr-6 rtl:before:ml-6 after:flex-[1_1_0%] after:border-t after:border-carrot-orange ltr:after:ml-6 rtl:after:mr-6 dark:text-white/70 dark:before:border-white/10 dark:after:border-white/10">
            <div className="w-[5rem] mx-auto">
              <img alt="userlogo" className='z-10' src={user} />
            </div>
          </div>
          <div className="mt-5">
          { forgotPasswordData && forgotPasswordData !='Email id does not exist' ?
          <p className='text-[18px] text-center text-greeny-blue'>{forgotPasswordData}</p>
          :
            <form onSubmit={handleSubmit(onSubmit)}>
              {   forgotPasswordData =='Email id does not exist' ?     
                  <p className="text-error-red text-center text-sm mb-3">{forgotPasswordData}</p>   
                :     
                  errors?.username && errors?.username.type=="required"  ? 
                  <p className="text-yellow text-center text-sm mb-3">{errors?.username.message}</p>
                : errors?.username && errors?.username.type!=="required" ?
                  <p className="text-error-red text-center text-sm mb-3">{errors?.username.message}</p>                
                 :<></>
              }
              
              <div className="relative mb-4">
                <img src={avatar} className="absolute top-1/2 left-3 transform -translate-y-1/2 w-4 brightness-0 invert-[1]" />
                <input type="text" id="username" placeholder="Username" name="username"
                  {...register("username",{
                    onChange:(e)=>{
                      setUserName(e.target.value)
                    }
                  })}
                  className={`w-full text-white rounded px-0 py-2.5 ${errors?.username ? errors?.username.type=="required" ? 'border border-yellow' : 'border border-error-red' :''} ${forgotPasswordData =='Email id does not exist' ? 'border border-error-red' :''} focus:outline-none pl-[2.5rem] bg-charcoal-gray placeholder:text-white text-[14px] placeholder:tracking-[3px]`}
                  autoComplete="off" />
                {errors?.username &&
                  <div className="absolute top-1/2 right-3 transform -translate-y-1/2 w-4" >
                    <Warning circleBg={errors?.username && errors?.username.type=="required" ? "yellow" : "#FF6174"} />
                  </div>}
              </div>
                            
              <div className="mt-4 flex items-center justify-center">
                    <button type="submit" disabled ={username.length <=0} className={`w-full rounded py-1.5 ${username.length <=0 ? 'bg-ash text-[#707070]':' bg-greeny-blue text-white'} focus:outline-none text-[16px]`}>Reset password</button>
              </div>
              <div className="flex items-center justify-center mt-6">
              <NavLink to={RouteSegments.HOME} className="w-full">
                <button type="button" className="w-full rounded py-1.5 text-white bg-red-orange focus:outline-none text-[16px]">Back to login</button>
            </NavLink>
              </div>
            
              <div className="flex items-center justify-center mt-6">
                <NavLink to={RouteSegments.REGISTER} className="w-full">
                  <button type="button" className="w-full rounded py-1.5 text-white bg-dark-orange focus:outline-none text-[16px]">Register new user</button>
                </NavLink>
              </div>
            </form>
          }
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Forgotpassword)

