export const LOGIN = "/login"; 
export const LOGOUT = "/logout"; 
export const REGISTER = "/register"; 
export const FORGOT_PASSWORD = "/forgotPassword"; 
export const RESET_PASSWORD = "/setPassword"; 
export const CLIENT_LIST = "/client/list";
export const COUNTRY_LIST = "/country";
export const CURRENCY_LIST = "/currency";
export const CLIENT_AVAILABILITY = "/client/exist"
export const CATEGORY_AVAILABILITY = "/client/categoryName/exist";
export const MANAGE_CATEGORY_AVAILABILITY ="/client/manageCategory/existCheck"
export const MANAGE_CATEGORY = "/client/manageCategory"
export const CHECK_REMOVABLE_CATEGORY = "/client/manageCategory/removeCheck"
export const NULL_AVAILABILITY = "/client/check/nullValue/exists"
export const MANAGE_NULL_MULTIPLE = "/client/manage/multiple/nullValues"
export const CREATE_CLIENT = "/client/create"
export const UPDATE_CLIENT = "/client/update"
export const MANAGE_NULL_CHECK ="/client/manageNull/editRemoveCheck"
//client list sorting
export const CLIENTS_LIST_SORTING = "/client/filter";
//clients country list
export const CLIENTS_COUNTRY_LIST = "/client"
export const CLIENTS_COUNTRY_LEVEL_ONE_LIST = "/clientCountry"
export const CLIENTS_COUNTRY_LEVEL_TWO_LIST = "/clientCountry"
//Levelname update
export const UPDATE_LEVEL_NAME = "/client/levelName/update"
//Categoryname update
export const UPDATE_CATEGORY_NAME = "/client/categoryName/update"
//Manage Country
export const MANAGAE_COUNTRY = "/client/country/manage"
//Manage Level
export const MANAGAE_LEVEL = "/client/level/manage"
//Manage Level remove
export const MANAGAE_LEVEL_REMOVE = "/client/manageLevel/remove"
//Manage Country
export const CREATE_COUNTRY_LEVEL = "/client/categoryName/create"
//modelBuilder
export const MODEL_BUILDER_LIST = "/modelBuilder"
//ecl hisory
export const ECL_HISTORY_LIST = "/ecl";
//ecl category list
export const ECL_CATEGORY_LIST = "/ecl/category"
//single file upload
export const SINGLE_FILE_UPLOAD_API = "/ecl/calculation";
//deleteing node 
export const DELETE_NODE_API = "/ecl/workflowNode/delete";
//deleteing node 
export const GET_FILE_UPLOAD_SUMMARY ="/ecl/calculation/fileUpload/summary";
//check workflow node 
export const CHECK_WORKFLOW_NODE_API = "/ecl/calculation";
//merge summary 
export const MERGE_SUMMARY_API = "/ecl/calculation";
//download merge summary 
export const DOWNLOAD_MERGE_SUMMARY_API = "/ecl/calculation/mismatchedDataType/export";
//table updation 
export const TABLE_UPDATION_API = "/ecl/calculation/dataQualityTableUpdation"
//data type table data get
export const GET_DATA_QUALITY_TYPE ="/ecl/calculation/dataQualityDataTypeValues"
//graph data get
export const GET_DATA_QUALITY_TYPE_PERCENTAIL ="/ecl/calculation/dataQualityPercentileValues"
//replacement values get
export const GET_REPLACEMENTVALUES ="/ecl/calculation/validator/replacementValues"
//get null replacement values
export const GET_NULL_REPLACEDVALUES = "/ecl/calculation/dataNullReplacement";
//create merge summary
export const CREATE_MERGE_SUMMARY ="/ecl/calculation/mergeSummaryFileCreation";
//retrive merge summary 
export const GET_MERGE_SUMMARY_RESULT ="/ecl/calculation/mergeSummaryFileRetrieve";
//postition analysis categpory
export const POSITION_ANALYSIS_CATEGORY ="/positionAnalysis/categoryList";
//template name check
export const TEMPLATE_NAME_CHECK = "/ecl/calculation/template/existCheck"

//get column unique values
export const POSITION_ANALYSIS_UNIQUE_VALUES = "/ecl/calculation/positionAnalysis/uniqueValues"

//get node details column unique values
export const CALCULATION_UNIQUE_VALUES = "/ecl/calculation/uniqueValues";

//get asigned data type table values
export const ASSIGNED_DATA_TYPE_LIST = "/ecl/calculation/workflowCategroyWise/assignDataType/detail";

//get duckDb datatypes

export const DUCK_DB_DATATYPES = "/duckDb/dataTypes/list";

//save datatype format

export const SAVE_DATA_TYPE_FORMAT = "/ecl/calculation/assignDataTypeSave";

//data type formats

export const DUCK_DB_DATATYPE_FORMATS = "/dataType/format/list";

//renamed column list

export const  RENAMED_COLUMN_LIST = "/ecl/calculation/workflowCategroyWise/rename/detail";

//column name list
 
export const GET_COLUMN_NAME_LIST = "/ecl/calculation/columnNames/dropdownValues";


//column names exist check

export const COLUMN_NAME_EXITS_CHECK ="/ecl/calculation/columnNames/existCheck";

//rename save

export const RENAME_COLUMN_SAVE = "/ecl/calculation/renameSave";

//remove column name alias data

export const REMOVE_COLUMN_DROPDOWN_LIST ="/ecl/calculation/columnWithAliasNames/dropdownValues";

export const ADD_COLUMN_DROPDOWN_LIST ="/ecl/calculation/removeCheck";

// export const REMOVE_COLUMN_DROPDOWN_LIST ="/ecl/calculation/aliasNames/dropdownValues";

//removed column names list

export const REMOVED_COLUMN_NAMES_LIST = "/ecl/calculation/workflowCategroyWise/remove/detail";

//remove save

export const REMOVE_COLUMN_NAME ="/ecl/calculation/removeSave";

//remove duplicate rows

export const REMOVE_DUPLICATE_ROW ="/ecl/calculation/workflowCategroyWise/removeDuplicateRow/detail";

//remove duplicates row save

export const REMOVE_DUPLICATE_ROW_SAVE ="/ecl/calculation/removeDuplicateRowSave";

// duplicate row alisa column names

export const DUPLICATE_ROWS_ALIAS_COLUMN_NAME ="/ecl/calculation/columnWithAliasNames/dropdownValues";

//file name check

export const FILE_NAME_EXIST_CHECK ="/file/upload/existCheck";

//node save
export const NODE_SAVE = "/ecl/calculation"

//node update]
export const NODE_UPDATE = "/ecl/calculation/multiple";

//uploaded file already used any calculation check

export const CALCULATION_EXIST_CHECK ="/ecl/calculation/deleteFile/calculationCheck";

//

export const REMOVE_DUPLICATE_CHECK = "/ecl/calculation/removeDuplicateRow/confirmCheck"

//mismatched datatype
export const MISMCH_DATA = "/ecl/calculation/workflowCategroyWise/mismatchedDataType/detail"

//dropdown values in mismatch data type
export const MISMCH_DATA_DROPDOWN = "/ecl/calculation/dataImputation/dropdownList"

export const MISMCH_DATA_SAVE = "/ecl/calculation/mismatchedDataTypeSave"

//outlier datatype
export const OUTLIER_DATA = "/ecl/calculation/workflowCategroyWise/outlierAnalysis/detail"

export const OUTLIER_COLUMN_DROPDOWN_LIST= "/ecl/calculation/outlierColumns/dropdownList";

export const METHOD_DROPDOWN_LIST= "/ecl/calculation/outlierMethods/dropdownList";

export const BASED_DATAS= "/ecl/calculation/outlierMethods/dropdownValue/basedDatas"; 

export const OUTLIER_ANALYSIS_SAVE = "/ecl/calculation/outlierAnalysisSave";

export const IQR_CALCULATION= "/ecl/calculation/iqr/calculation";

export const OUTLIER_ROWWISE_EXPORT ="/ecl/calculation/outlierAnalysis/export"


//EA - unexpected values
export const UNEXPECTED_VALUES_DETAIL = "/ecl/calculation/workflowCategroyWise/unexpectedValue/detail"
export const UNEXPECTED_VALUES_SAVE = "/ecl/calculation/unexpectedValueSave"
export const UNEXPECTED_VALUES_EXPORT = "/ecl/calculation/unexpectedValue/export"





//null blank datatype
export const NULLBLANK_VALUE_DATA = "/ecl/calculation/workflowCategroyWise/excepnullValue/detail"
export const NULLBLANK_EXPORT = "/ecl/calculation/excepNullValue/export"


export const NULLBLANK_VALUE_DATA_SAVE = "/ecl/calculation/excepnullValueSave"

//imputation user defined
export const IMPUTATION_USER_DEFINED_DETAIL = "/ecl/calculation/workflowCategroyWise/imputationUserDefined/detail"
export const IMPUTATION_USER_DEFINED_SAVE = "/ecl/calculation/imputationUserDefinedSave"
export const IMPUTATION_USER_DEFINED_EXPORT = "/ecl/calculation/imputationUserDefined/export"


//imputation replace average
export const REPLACE_AVERAGE_DETAIL = "/ecl/calculation/workflowCategroyWise/replaceValueBasedOnAverage/detail"
export const REPLACE_AVERAGE_SAVE = "/ecl/calculation/replaceValueBasedOnAverageSave"
export const REPLACE_AVERAGE_EXPORT = "/ecl/calculation/replaceValueBasedOnAverage/export"

//imputation replace earliest
export const REPLACE_EARLIEST_DETAIL = "/ecl/calculation/workflowCategroyWise/replaceValueBasedOnEarliest/detail"
export const REPLACE_EARLIEST_SAVE = "/ecl/calculation/replaceValueBasedOnEarliestSave"
export const REPLACE_EARLIEST_EXPORT = "/ecl/calculation/replaceValueBasedOnEarliest/export"

//imputation replace latest
export const REPLACE_LATEST_DETAIL = "/ecl/calculation/workflowCategroyWise/replaceValueBasedOnLatest/detail"
export const REPLACE_LATEST_SAVE = "/ecl/calculation/replaceValueBasedOnLatestSave"
export const REPLACE_LATEST_EXPORT = "/ecl/calculation/replaceValueBasedOnLatest/export"

//imputation floor and caps
export const IMPUTATION_FLOOR_AND_CAP_DETAIL = "/ecl/calculation/workflowCategroyWise/floorCap/detail"
export const IMPUTATION_FLOOR_AND_CAP_SAVE = "/ecl/calculation/floorCapSave"
export const IMPUTATION_FLOOR_AND_CAP_EXPORT = "/ecl/calculation/floorCap/export"


export const DT_NUMERIC_DROPDOWN = "/ecl/calculation/dataTransformation/numeric/dropdownList"

//data transformation split data
export const SPLIT_DATA_DETAIL = "/ecl/calculation/workflowCategroyWise/splitData/detail"
export const SPLIT_DATA_SEED_VALUE = "/ecl/calculation/seedValue"
export const SPLIT_DATA_SAVE = "/ecl/calculation/splitDataSave"
export const SPLIT_DATA_COLUMN_DROPDOWN = "/ecl/calculation/optColumnsDropdownList"
export const SPLIT_DATA_EXPORT = "/ecl/calculation/splitData/export"


//data transformation binning & bucketing
export const BINNING_DATA_DETAIL= "/ecl/calculation/workflowCategroyWise/binningBucketingRange/detail";
export const BINNING_COLUMN_DROPDOWN_LIST= "/ecl/calculation/dataTransformation/numeric/dropdownList";
export const BINNING_BUCKET_DATA_LIST= "/ecl/calculation/getBinningBucketingRangeData";
export const BUCKET_TABLE_LIST= "/ecl/calculation/binningBucketingRangeSave";
//data transformation zscore
export const ZSCORE_DETAIL = "/ecl/calculation/workflowCategroyWise/dtZscore/detail"
export const ZSCORE_SAVE = "/ecl/calculation/dtZscoreSave"
export const ZSCORE_CALCULATION = "/ecl/calculation/calculate/columnAverageAndStandard"

//DT - binning bucketting percentile

export const BINNING_BUCKETING_DETAIL = "/ecl/calculation/workflowCategroyWise/binningBucketingPercentile/detail";
export const BINNING_BUCKETING_PERCENTILE_DATA = "/ecl/calculation/getBinningBucketingPercentileData";
export const SAVE_BINNING_BUCKETING_PERCENTILE ="/ecl/calculation/binningBucketingPercentileSave"


//data transformation user defined mapping

export const BINNING_BUCKETING_USERMAPPING_VALUE = "/ecl/calculation/workflowCategroyWise/binningBucketingUserMapping/detail"
export const ONCHANGE_BUCKETING_USERMAPPING_VALUE = "/ecl/calculation/getBinningBucketingUserMappingData"
export const SAVE_BINNING_BUCKETING_USERDEFIND ="/ecl/calculation/binningBucketingUserMappingSave"

//data transformations natural log
export const NATUTAL_LOG_DETAIL = "/ecl/calculation/workflowCategroyWise/dtNaturalLog/detail"
export const NATUTAL_LOG_SAVE = "/ecl/calculation/dtNaturalLogSave"

//create duplicate column 
export const CREATE_DUPLICATE_COLUMN_DETAIL = "/ecl/calculation/workflowCategroyWise/createDuplicateColumn/detail"

export const CREATE_DUPLICATE_COLUMN_SAVE ="/ecl/calculation/createDuplicateColumnSave"


//imputation most frequently occuring
export const IMPUTATION_MOST_FREQUENTLY_DETAIL = "/ecl/calculation/workflowCategroyWise/frequentlyOccurringAnotherColumn/detail"
export const IMPUTATION_MOST_FREQUENTLY_SAVE = "/ecl/calculation/frequentlyOccurringAnotherColumnSave"
export const IMPUTATION_MOST_FREQUENTLY_EXPORT = "/ecl/calculation/frequentlyOccurringAnotherColumn/export"

//imputation replace mapping based another column
export const IMPUTATION_REPLACE_MAPPING_DETAIL = "/ecl/calculation/workflowCategroyWise/mappedValuesDifferentColumn/detail"
export const IMPUTATION_REPLACE_MAPPING_SAVE = "/ecl/calculation/mappedValuesDifferentColumnSave"
export const IMPUTATION_REPLACE_MAPPING_EXPORT = "/ecl/calculation/mappedValuesDifferentColumn/export";
export const DI_MAPPING_UNIQUE_COLUMN_VALUES ="/ecl/calculation/getColumnUniqueValues";

// DT  standardize Using MinMax Scaling

export const DT_MinMaxScaling_DETAIL = "/ecl/calculation/workflowCategroyWise/standardizeUsingMinMaxScaling/detail"
export const DT_MinMaxScaling_SAVE = "/ecl/calculation/standardizeUsingMinMaxScalingSave"
export const DT_MinMaxScaling_CALCULATION = "/ecl/calculation/calculate/columnMinimumAndMaximum"
//dt cumulative distribution
export const DT_CUMULATIVE_DISTRIBUTION_DETAILS ="/ecl/calculation/workflowCategroyWise/calculateCumulativeDistributionFunction/detail";
export const DT_CUMULATIVE_DISTRIBUTION_SAVE ="/ecl/calculation/calculateCumulativeDistributionFunctionSave";

//dt probability density 

export const DT_PROBABILITY_DENSITY_DETAILS ="/ecl/calculation/workflowCategroyWise/calculateProbabilityDensityFunction/detail";
export const DT_PROBABILITY_DENSITY_SAVE ="/ecl/calculation/calculateProbabilityDensityFunctionSave";

//dt normalization using percentiles
export const DT_NORMALIZATION_PERCENTILES_DETAILS ="/ecl/calculation/workflowCategroyWise/normalizationPercentile/detail";
export const DT_NORMALIZATION_PERCENTILES_SAVE ="/ecl/calculation/normalizationPercentileSave";

//dt normalization using decimal scaling
export const DT_NORMALIZATION_DECIMAL_SCALING_DETAILS ="/ecl/calculation/workflowCategroyWise/normalizationDecimalScaling/detail";
export const DT_NORMALIZATION_DECIMAL_SCALING_SAVE ="/ecl/calculation/normalizationDecimalScalingSave";
export const CALCULATE_SCALING_FACTOR = "/ecl/calculation/calculateScalingFactor"

//dt Standardize using robust scaling
export const DT_STANDARDISE_ROBUST_SCALING_DETAILS ="/ecl/calculation/workflowCategroyWise/standardizeRobustScaling/detail";
export const DT_STANDARDISE_ROBUST_SCALING_SAVE ="/ecl/calculation/standardizeRobustScalingSave";
export const CALCULATE_STANDARDISE_ROBUST_SCALING = "/ecl/calculation/calculateRobustScaling"