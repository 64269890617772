import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Select } from 'antd';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DownArrow from '../../Components/CommonIcons/DownArrow';

const schema = yup.object().shape({
  destination: yup.string().required(),
});

const DropDown = ({ dropDownOptions, onOptionChange, onBlur, selected, placeholder, name, setDestinationDataType, ordinary, disabled, mode, className, border, value = undefined, initialFocus = false, noDataFountMessage = undefined }) => {
  const { control, reset } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const { Option } = Select;
  const [columnsDropDown, setColumnsDropDown] = useState(false);
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    if (selected === undefined) {
      reset({ [name]: undefined });
    }
  }, [selected, name, reset]);

  useEffect(() => {
    if(value == '' || value == 'undefined'){
      setSelectedValue(undefined);

    }else{
      setSelectedValue(value);

    }
  }, [value]);

  const handleOnChange = (value, field) => {
    console.log("handleOnChange called with value:", value); // Debugging
    const selectedColumn = dropDownOptions?.find(data => data?.column_name === value) ?? {};
    const selectedColumnLabel = dropDownOptions?.find(data => data?.value === value) ?? {};
    field.onChange(value);
    onOptionChange(value, selectedColumnLabel?.data_type, selectedColumnLabel?.label);
    if (selectedColumn?.length > 0 || Object.keys(selectedColumn)?.length > 0) {
      setDestinationDataType(selectedColumn?.data_type);
    }
  };

  const handleDropdownVisibleChange = (visible) => {
    if (!visible) {
      setSelectedValue(value);
    }
    setColumnsDropDown(visible);
  };

  const handleSelect = (value,field) => {
    console.log("handleSelect called with value:", value); // Debugging
    handleOnChange(value, field);
  };

  const NoDataFoundMessage = () => (
    <div className='p-4'>
      <p className='text-[13px] text-[#A8A8A8] text-center'>{noDataFountMessage}</p>
    </div>
  );

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Select
            showSearch
            filterOption={(input, option) => {
              if (typeof option.children === 'string') {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }
              return false;
            }}
            options={ordinary ? dropDownOptions : undefined}
            mode={mode ?? "single"}
            name={field.name}
            maxTagCount={1}
            defaultValue={selected}
            onBlur={onBlur}
            {...field}
            ref={initialFocus ? ref => ref?.focus() : undefined}
            value={selectedValue}
            onSelect={(value) => {
              console.log("onSelect called with value:", value); // Debugging
              handleSelect(value,field);
            }}
            placeholder={placeholder}
            disabled={disabled}
            autoFocus={false}
            open={columnsDropDown}
            onDropdownVisibleChange={handleDropdownVisibleChange}
            notFoundContent={<NoDataFoundMessage />}
            suffixIcon={<DownArrow color="#000000" className={`xl:w-[10px] xl:h-[10px] w-[8px] h-[8px] ${columnsDropDown ? 'transition-transform transform rotate-180' : 'transition-transform transform rotate-[-180]'}`} />}
            className={`custom-select-height custom-disabled-select ${className ?? 'border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none focus-within:border-none'}  text-[#BEBEBE] placeholder:text-[#BEBEBE]`}
            style={{
              width: '100%',
              border: border ?? '2px solid #00A7B5',
              borderRadius: '4px',
              backgroundColor: '#ffffff',
              padding: disabled ? '0' : '0 0 0 6px',
              cursor: 'pointer',
            }}
          >
            {ordinary ? undefined :
              dropDownOptions?.map((data, i) => (
                <Option key={data?.column_name} value={data?.column_name}>
                  {`${data?.column_name} (${data?.is_numeric === 'Numeric' ? 'Number' : data?.is_numeric === 'Non-Numeric' ? 'String' : data?.is_numeric })`}
                </Option>
              ))
            }
          </Select>
        )}
      />
    </>
  );
};

export default DropDown;
