import { FORGOT_PASSWORD } from "../../Helpers/EndPoints";
import {
    LOGIN_USER,
    LOGIN_SUCCESS,
    LOGIN_ERROR,
    API_ERROR,    
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_API_ERROR,
    LOGOUT_USER,
    REGISTER_USER,
    FORGOTPASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    REGISTER_EMAIL_EXIST,
    REGISTER_SUCCESS,
    RESET_LOGIN_ERROR,
    RESET_REGISRER_ERROR,
    FORGOT_PASSWORD_ERROR,
    REGISTER_RESPONSE_DATACLEAR,    
      
  } from "./ActionType";
  
  export const loginUser = (user, history) => {
    return {
      type: LOGIN_USER,
      payload: { user, history },
    };
  };
  
  export const loginSuccess = user => {
    return {
      type: LOGIN_SUCCESS,
      payload: user,
    };
  };

  export const loginError= user => {
    // console.log(user,"loginerror");
    return {
      type: LOGIN_ERROR,
      payload: user,
    };
  };
  
  
  export const apiError = error => {
    // console.log(error ,'error data');
    return {
      type: API_ERROR,
      payload: error,
    };
  };

  export const resetLoginError = () => {
    return {
      type: RESET_LOGIN_ERROR,
    };
  };

//logout

export const logoutUser = (user,history,headers) => {
  // console.log(headers,'headers1');
  return {
    type: LOGOUT_USER,
    payload: {user,history,headers},
  };
};


//Register

export const registerUser = (user,history,headers) => {
  return {
    type: REGISTER_USER,
    payload: {user,history,headers},
  };
};

export const registerEmailExist = (user,history,headers) => {
  return {
    type:  REGISTER_EMAIL_EXIST,
    payload: {user,history,headers},
  };
};

export const resetRegisterError = () => {
  return {
    type: RESET_REGISRER_ERROR,
  };
};

export const registerSuccess = (payload) => {
  return {
    type:  REGISTER_SUCCESS,
    payload: payload,
  };
};
export const registerResponseDataClear = (payload) => {
  return {
    type:  REGISTER_RESPONSE_DATACLEAR,
    payload: payload,
  };
};

  //reset password

  export const forgotPassword = (user) => {
    return {
      type: FORGOTPASSWORD,
      payload: { user},
    };
  };

  export const forgotPasswordSuccess = user => {
    return {
      type: FORGOT_PASSWORD_SUCCESS,
      payload: user,
    };
  };

  export const forgotPasswordError = user => {
    return {
      type: FORGOT_PASSWORD_ERROR,
      payload: user,
    };
  }; 

  export const resetPassword = (data,history) => {
    return {
      type: RESET_PASSWORD,
      payload: {data,history},
    };
  };

  export const resetPasswordSuccess = data => {
    //console.log(user,"12344");
    return {
      type: RESET_PASSWORD_SUCCESS,
      payload: data,
    };
  };
  
  export const resetPasswordApiError = error => {
    //console.log(error,"12345");
    return {
      type: RESET_PASSWORD_API_ERROR,
      payload: error,
    };
  };


 