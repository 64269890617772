import React, { useEffect, useState } from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import PieChartSkeleton from '../Skeleton/PieChartSkeleton';

const BarChartComponent = ({ data }) => {
  const { 'x-axis': xAxisCategories, rangeCounts } = data;
  const dataValues = xAxisCategories?.map(category => rangeCounts[category] || 0);

  return (
    <BarChart
      xAxis={[
        {
          id: 'barCategories',
          data: xAxisCategories,
          scaleType: 'band',
          categoryGapRatio: 0.1,
          barGapRatio: 0.1,
        },
      ]}
      series={[
        {
          data: dataValues,
          color: '#00A7B5',
        },
      ]}
      // width={500}
      // height={300}
      maxBarSize={16}
    />
  );
};

const PieChartComponent = ({ data }) => {
  const [pieChartData, setPieChartData] = useState([])
  const valueFormatter= (value) => value ? `${value}%` : undefined;
  const colors = ['#0080ff', '#0000ff', '#8000ff', '#ff00ff', '#ff0080', '#80ff00', '#ff8000', '#00ffff', '#254058', '#54869e', '#385668', '#FF33FF'];

  useEffect(()=>{
    const datas = data?.labels?.map((label, index) => ({
      id: index,
      value: `${data?.value[index]}`,
      label: `${label}`,
      color:colors[index]
    }));
    setPieChartData(datas)
  },[data])


  return (
    <>
    {pieChartData?.length > 0 ?
    <PieChart
      series={[
        {          
          data: pieChartData,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' },
          valueFormatter:(value)=>valueFormatter(value?.value),
        },
      ]}
      slotProps={{
        legend: {
          direction: 'column',
          position: { vertical: 'top', horizontal: 'right' },
          padding: 0,
          itemMarkWidth: 10,
          itemMarkHeight: 10,
          markGap: 4,
          itemGap: 7,
          labelStyle: {
            fontSize: 12,
            fill: '#000000',
          },
        },
      }}
      // width={data?.labels?.length > 10 ? 800: 400}
      // height={220}
    />
    :
    <PieChartSkeleton />
    }
    </>
  );
};

const SimpleCharts = ({ data, chartType }) => {
  return (
    <>
      {chartType === 'bar' ? (
        <BarChartComponent data={data} />
      ) : (
        <PieChartComponent data={data} />
      )}
    </>
  );
};

export default SimpleCharts;
