import React, { useEffect, useState } from 'react'
import { useForm, Controller } from "react-hook-form";
import { Select } from "antd";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import UploadImageToS3WithReactS3 from './MultiFIleUpload';

//imgaes
import DownArrow from '../../Components/CommonIcons/DownArrow';
//Components
import Button from '../../Components/Button';

const schema = yup.object().shape({

    category: yup.string().required("Please select category"),
});
let selectedCategoryName;


const MultiFileUploadFeilds = ({ 
    categoryList,    
    setIsUploading,
    setProgress,    
    ecl_workflow_id,        
    setDropPointError,    
    setShowSubSheetPopup,
    setFileDetails,
    isFrom,
    setIsFileSentToS3,
    setShowSummaryLoader,
    onSubmit
     }) => {
    const { Option } = Select;

    const {
        register,
        handleSubmit,
        control,
        reset,
        setFocus,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({
        mode: "onChange",
        resolver: yupResolver(schema),
    });  
    const [dynamicFields, setDynamicFields] = useState([{ id: 1, category: null, selectedFiles: ''}]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [category,setCategory] = useState([]);
    const [categoryDropDown, setCategoryDropDown] = useState(false)

  const addMoreFiles = () => {
    const newId = dynamicFields.length > 0 ? dynamicFields[dynamicFields.length - 1].id + 1 : 1;
    const newField = { id: newId, category: null, selectedFiles: '' };
    setDynamicFields([...dynamicFields, newField]);
};

    const removeField = (indexToRemove) => {
        setDynamicFields(dynamicFields.filter((_, index) => index !== indexToRemove));
        selectedCategoryName=undefined;
        
    };
    
  
    const addMoreFromZipFile = (file, category) => {
        console.log({ category });
        setDynamicFields(prev => {
            const newField = { id: prev.length + 1, category, selectedFiles: [file], name: file?.name };
            setValue(`category-${newField.id}`, category ? category[0]?.id : null)
            return [...prev, newField]
        });
    };
    // console.log(dynamicFields, getValues(), 'dynamicFields');


    return (
        <div className="w-full p-2 pt-6">
            <div className='lg:max-h-[13rem] xlg:max-h-[17rem] xl:max-h-[18rem] lxl:max-h-[20rem] 2xl:max-h-[28rem] overflow-x-hidden overflow-y-auto thin-scrollbar'>
            {dynamicFields.map((rowField,i) => (
                <div key={i} className="flex w-full gap-4 mb-2">
                    <div className="w-[30%]">
                        <Controller
                            control={control}                            
                            name={`category-${rowField.id}`}
                            id={`category-${rowField.id}`}
                            render={({ field }) => {
                                return (
                                    <Select                                        
                                        ref={field.ref}
                                        name={field.name}
                                        // key={i}
                                        onBlur={field.onBlur}
                                        {...register(`category-${field?.id}`)}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            setCategory(e);
                                            setDynamicFields((prevFields) => {
                                                const updatedFields = [...prevFields];
                                                const index = updatedFields.findIndex(f => f?.id === rowField?.id);
                                                updatedFields[index].category = categoryList?.filter(data=> e==data.id);
                                                return updatedFields;
                                            })
                                        }}
                                        // value={field.value || categoryList?.find((data) => data.id == selectedCategoryName)?.id}
                                        value={rowField?.category != null ? rowField?.category[0]?.id :null}
                                        placeholder="Data category"
                                        // onDropdownVisibleChange={(visible) => setCategoryDropDown(visible)}
                                        onDropdownVisibleChange={(visible) => setCategoryDropDown((prevState) => ({
                                            ...prevState,
                                            [rowField.id]: visible
                                        }))}
                                        suffixIcon={<DownArrow color="#000000" width={'10px'} height={'10px'} className={categoryDropDown[rowField.id] ? 'transition-transform transform rotate-180' : 'transition-transform transform rotate-[-180]'} />}
                                        className="custom-select-height border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"
                                        style={{
                                            width: '100%',
                                            border: ' 2px solid #00A7B5',
                                            // height: '2.2rem',
                                            borderRadius: '4px',
                                        }}
                                    >
                                        {categoryList?.map((data, i) => (
                                            <Option key={data?.id} value={data?.id}>
                                                {data?.category_name}
                                            </Option>
                                        ))}
                                    </Select>
                                );
                            }}
                        />
                    </div>
                    <div className="w-[65%] flex gap-3">
                        <UploadImageToS3WithReactS3                                         
                            category={categoryList?.filter((data) => data.id == category)}                            
                            selectedFiles={selectedFiles} 
                            setSelectedFiles={setSelectedFiles}
                            setIsUploading={setIsUploading}
                            setProgress={setProgress}
                            ecl_workflow_id={ecl_workflow_id}
                            isMultiple={true}
                            dynamicFields={dynamicFields}
                            removeField={removeField}
                            rowFieldId={rowField.id}
                            index={i}
                            setDropPointError={setDropPointError}                           
                            fileName={rowField.selectedFiles[0]?.name}    
                            setShowSubSheetPopup={setShowSubSheetPopup}
                            setFileDetails={setFileDetails}
                            isFrom={isFrom}
                            onSubmit={onSubmit}
                            setShowSummaryLoader={setShowSummaryLoader}
                            setIsFileSentToS3={setIsFileSentToS3}
                            onFileInputChange={(files, iszip) => {
                                if (iszip) {
                                    files?.map((fl, ind) => {
                                        if (ind == 0) {
                                            setValue(`category-${rowField.id}`, rowField.category ? rowField.category[0]?.id : null)
                                            setDynamicFields((prevFields) => {
                                                const updatedFields = [...prevFields];
                                                const index = updatedFields.findIndex(f => f.id === rowField.id);
                                                updatedFields[index].selectedFiles = [fl];
                                                return updatedFields;
                                            })
                                        } else {
                                            addMoreFromZipFile(fl, rowField.category)
                                        }
                                    })
                                } else {
                                    setDynamicFields((prevFields) => {
                                        const updatedFields = [...prevFields];
                                        const index = updatedFields.findIndex(f => f.id === rowField.id);
                                        updatedFields[index].selectedFiles = files;
                                        return updatedFields;
                                    })
                                }
                            }}                            
                            />                        
                    </div>                    
                </div>
            ))}
            </div>
            {isFrom !='single' &&
            <Button
                bgColor="#47B4AE"
                size="xs"
                label={'Add more files'}
                color={'#FFFFFF'}
                onClick={addMoreFiles}
                icon={'add'}
                weight="bold"
            />
            }
        </div>
    );
};

export default MultiFileUploadFeilds
