import React, { useEffect, useState } from 'react'
import HistroyIcon from '../CommonIcons/HistroyIcon'
import CloseIcon from '../CommonIcons/CloseIcon'
import SortDropDown from './SortDropdown'
import HistoryDataTable from '../Table/HistoryDataTable'
import { useDispatch, useSelector } from 'react-redux'
import { getEclHistory } from '../../Store/action'
import { useNavigate } from 'react-router-dom'
import RouteSegments from '../../Routes/RouteSegments'
import DataTableSkeleton from '../Skeleton/DataTableSkeleton'
import SlidingPane from "react-sliding-pane";
import ReplacementHistory from './ReplacementHistory'


const columns = [
  {
    Header: 'Date',
    accessor: 'date',
  },
  {
    Header: 'Template name',
    accessor: 'template_name',
  },
  {
    Header: 'Data period',
    accessor: 'data_period',
  },
  {
    Header: 'User',
    accessor: 'user_name',
  },
  {
    Header: 'Last accessed',
    accessor: 'last_accessed',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'ECL value',
    accessor: 'ecl_value',
  },
  {
    Header: 'Is replaced',
    accessor: 'is_replaced',
  },  
  {
    Header: 'Actions',
    accessor: 'actions',
  },
];

const data = [
  {
    date: '',
    data_period: '',
    user_name: '',
    last_accessed: '',
    status: '',
    ecl_value: '',
    actions: '',
    id: 0
  }
]
const EclHistory = ({ title, onClose, headerBg, borderColor, levelTwoCardId, navigationData, isOpen }) => {

  const [sortBy, setSortBy] = useState('created_at:desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState('Recently added');
  const [userSort, setUserSort] = useState('')
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [replacementOpen, setReplacementOpen] = useState();
  const [workFlowId,setWorkFlowId] = useState()
  useEffect(() => {
    if (levelTwoCardId && isOpen) {
      dispatch(getEclHistory(levelTwoCardId, sortBy, null, null))
    }
  }, [levelTwoCardId])

  const { EclHistoryList } = useSelector(state => {
    return {
      EclHistoryList: state?.ModelBuilder?.eclHistoryList,
    }
  })
  // console.log(EclHistoryList, 'EclHistoryList');

  const closeDetails =(isFrom) =>{
    if(isFrom=='replacement'){
      setReplacementOpen(false)
    }
  }

  return (
    <>
    <SlidingPane       
          className="custom-slide-pane-overflow"
          overlayClassName="custom-overlay-class z-[101]"
          isOpen={replacementOpen}
          width="68%"
          hideHeader
          shouldCloseOnEsc={true}
          onRequestClose={() => {
            // closeDetails('history')
            setReplacementOpen(true)
          }}
        >
          <ReplacementHistory title={'Replacement History'} isOpen={replacementOpen} workFlowId={workFlowId} onClose={closeDetails} headerBg={'bg-[#5B9BD5]'} borderColor={'border-[#5B9BD5]'}/>

        </SlidingPane>
    <div className={`border-l-2 ${borderColor} h-full`}>
      <div className={`w-full ${headerBg} flex justify-between xl:py-3 py-2 pl-[1rem] pr-[2.1rem]`}>
        <div className='flex items-center gap-2 '>
          <HistroyIcon bgColor={'#ffffff'} className={"xl:w-[25px] xl:h-[20px] w-[20px] h-[15px]"}/>
          <p className='text-white font-semibold xl:text-[13px] text-[12px]'>{title}</p>
        </div>
        <div className='flex items-center gap-2 cursor-pointer' onClick={() => onClose()}>
          <p className='text-white font-semibold xl:text-[12px] text-[11px]'>Close</p>
          <CloseIcon className={'xl:w-[15px] xl:h-[15px] w-[12px] h-[12px]'} bgColor={'#ffffff'} />
        </div>
      </div>
      <div className='py-2 pr-[2rem] pl-[1rem]'>
        <div className='float-right xl:mb-6 mb-3'>
          <SortDropDown setSortBy={setSortBy} sortBy={sortBy} id={levelTwoCardId} searchTerm={searchTerm} setSearchTerm={setSearchTerm} setUserSort={setUserSort} selected={selected} setSelected={setSelected} page={"dashboard"} isFrom="history" disabled={EclHistoryList?.length == 0}/>
        </div>     
        <div className='max-h-[17rem] xlg:max-h-[20rem] xl:max-h-[21rem] lxl:max-h-[28rem] 2xl:max-h-[31rem] 3xl:max-h-[34rem]'>   
          {EclHistoryList?.length > 0 ? (
            <HistoryDataTable
              columns={columns}
              data={EclHistoryList}
              tableHeight={'xl:max-h-[19rem] lxl:max-h-[28rem] 2xl:max-h-fit'}
              headerclassName={'2xl:py-2 py-1.5 bg-[#5B9BD5] text-white 2xl:text-[12px] text-[10px] border-none'}
              onViewClick={(id) => navigationData(id, 'existNew')}
              onDownloadClick={(id,from) => navigationData(id,from)}
              onReplacedClick={(id)=>{setWorkFlowId(id);setReplacementOpen(true)}}
            />
          ) : EclHistoryList !== undefined ? ( // Check if EclHistoryList is defined (not null or undefined)
            <>
              <HistoryDataTable
              columns={columns}
              data={data}
              headerclassName={'py-2 bg-[#5B9BD5] text-white text-[12px] border-none'}
              onViewClick={(id) => navigationData(id, 'existNew')}
              onDownloadClick={(id) => console.log(id, 'onDownloadClick')}
              onReplacedClick={(id)=>{setWorkFlowId(id);setReplacementOpen(true)}}

            />
            </>
          ) : (
            <DataTableSkeleton showHeader={true}  columnWidths={''} rowCount={5} columnCount={7} showPagination={true} />
          )}
        </div>

      </div>
    </div>
    </>
  )
}

export default EclHistory
