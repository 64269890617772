import React from 'react'

const ViewIcon = ({width,height,bgColor,className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} viewBox="0 0 22.521 15">
            <g id="Group_172" data-name="Group 172" transform="translate(609.175 -167.233)">
                <path id="Path_118" data-name="Path 118" d="M-597.931,182.233a11.314,11.314,0,0,1-6.188-1.94,19.094,19.094,0,0,1-4.116-3.7c-.166-.195-.332-.391-.488-.593a2,2,0,0,1,.005-2.535,18.532,18.532,0,0,1,5.472-4.81,10.852,10.852,0,0,1,5.628-1.415,11.6,11.6,0,0,1,6.536,2.366,20.1,20.1,0,0,1,3.983,3.881,1.961,1.961,0,0,1,.047,2.428,18.132,18.132,0,0,1-5.866,5.074A10.894,10.894,0,0,1-597.931,182.233Zm.072-13.128a9,9,0,0,0-3.97.928,16.24,16.24,0,0,0-5.391,4.547.221.221,0,0,0,.008.316c.137.162.271.327.409.487a16.716,16.716,0,0,0,4.08,3.568,9.315,9.315,0,0,0,4.643,1.4,8.92,8.92,0,0,0,4.3-1.037,16.581,16.581,0,0,0,5.16-4.426.229.229,0,0,0-.009-.333c-.139-.161-.271-.326-.41-.487a17.355,17.355,0,0,0-3.6-3.262A9.518,9.518,0,0,0-597.859,169.1Z" fill={bgColor} />
                <path id="Path_119" data-name="Path 119" d="M-459.765,235.8a4.716,4.716,0,0,1,4.7-4.687,4.716,4.716,0,0,1,4.681,4.708,4.717,4.717,0,0,1-4.692,4.676A4.717,4.717,0,0,1-459.765,235.8Zm1.879-.005a2.823,2.823,0,0,0,2.791,2.824,2.822,2.822,0,0,0,2.835-2.8,2.822,2.822,0,0,0-2.792-2.824A2.821,2.821,0,0,0-457.887,235.794Z" transform="translate(-142.844 -61.072)" fill={bgColor} />
            </g>
        </svg>

    )
}

export default ViewIcon
