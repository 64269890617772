import { useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { exceptionAnalysisProceed, fetchNumericColumnList, getCumulativeDistibutionDetails, getCalculationFormData, getNodeWiseCategoryList, openModalBuilder, selectCurrentNodeData, selectCurrentNodeIds, dtCumulativeDisFunctionNodesave } from '../../Store/Workflow/WorkflowSlice'
import DropDown from '../DropDown/DropDown'
import { useLocation } from 'react-router-dom'
import Button from '../Button'
import { createColumnHelper } from '@tanstack/react-table'
import SummaryTable from '../Table/SummaryTable'
import CloseIcon from "../../Images/remove.svg"
import XlsIcon from '../../Images/excel.svg'
import EditIcon from '../../Images/Group 809.svg'
import ReadOnlyValueDisplay from '../CommonComponents/ReadOnlyValueDisplay'
import { parseCategotyValueLabel } from '../../Helpers/utils'
import DataTableSkeleton from '../Skeleton/DataTableSkeleton'
import Skeleton from 'react-loading-skeleton'
import { getZscoreCalculation } from '../../Helpers/ApiHelpers'
import DataTable from '../Table/DataTable'
import { CircularProgress } from '@mui/material'
import useDataTable from '../../hooks/useDataTable'
import ProceedButton from '../CommonComponents/ProceedButton'
import downloadingIcon from '../../Images/gif/downloading.gif'


const columnHelper = createColumnHelper()

function CumulativeDistributionFunction() {

    const dispatch = useDispatch()
    const currentNodeData = useSelector(selectCurrentNodeData)
    const isDataLoading = useSelector(state => state?.Workflow?.ui?.isDataLoading)
    const isCalculating = useSelector(state => state?.Workflow?.ui?.isCalculating)
    const filesMerging = useSelector(state => state?.Workflow?.ui?.filesMerging)
    const reduxState = useSelector(state => state)
    const nodeWiseCategory = useSelector(state => state?.Workflow?.nodeWiseCategory)
    const columnList = useSelector(state => state?.Workflow?.numericColumnList ?? [])
    const [isGettingScore, setIsGettingScore] = useState(false)
    const [columnMeanAndStandard, setcolumnMeanAndStandard] = useState(null)
    const { currentEclNodeId, currentWorkflowId } = useSelector(selectCurrentNodeIds)
    const [selectedColumnData, setselectedColumnData] = useState([])
    const location = useLocation()
    const [isEditMode, setIsEditMode] = useState(true)
    const isProceedLoading = useSelector(state => state?.Workflow?.ui?.isProceedLoading)


    const formik = useFormik({
        initialValues: {
            category: currentNodeData?.nodeCategoryId,
            columnName: undefined,
        },
        enableReinitialize: true,
    })

    const { dataTableColumns,
        getFilterOptions,
        summaryData,
        tableState,
        refreshDataTable,
        onTableStateChange,
        isDataLoading: isMergeSummaryLoading,
        isExportLoading,
        donwloadXl,
    } = useDataTable({ category_id: formik.values.category, ecl_node_id: currentEclNodeId, workflow_id: currentWorkflowId, from: "dtCalculateCumulativeDistributionFunctionDatas" })

    useEffect(() => {
        dispatch(getNodeWiseCategoryList({
            workflow_id: currentWorkflowId,
            ecl_node_id: currentEclNodeId,
        }))

        if (currentNodeData?.id) {
            dispatch(getCumulativeDistibutionDetails({
                "workflow_id": currentWorkflowId,
                "ecl_node_id": currentEclNodeId,
                "category_id": currentNodeData?.nodeCategoryId
            }))
        }
    }, [])

    useEffect(() => {
        if (currentNodeData?.nodeCategoryId) {
            refreshDataTable()
        }
    }, [currentNodeData?.nodeCategoryId])


    const onCategoryChange = (value) => {
        formik.setFieldValue("category", value)
    }

    const onColumnNameChange = (value) => {
        formik.setFieldValue("columnName", value)

        setIsGettingScore(true)
        getZscoreCalculation({
            "workflow_id": currentWorkflowId,
            "ecl_node_id": currentEclNodeId,
            "category_id": currentNodeData?.nodeCategoryId,
            "column_name": value
        }).then(res => {
            setIsGettingScore(false)
            if (res.status === 200) {
                setcolumnMeanAndStandard({
                    ...res.data,
                    normalDistribution: "Yes"
                });
            }
        }).catch(e => {
            console.log(e);
        })
    }

    const onSave = () => {
        const requestFormData = getCalculationFormData({
            client_branch_level_id: location?.state?.levelTwoCardId,
            isFrom: "zscore",
            category_id: currentNodeData?.nodeCategoryId,
            node_id: currentEclNodeId,
            state: reduxState,
            nodeStatus: "Pending",
        })

        dispatch(dtCumulativeDisFunctionNodesave({
            request: {
                "ecl_workflow_id": currentWorkflowId,
                "ecl_node_id": currentEclNodeId,
                "ecl_category_id": currentNodeData?.nodeCategoryId,
                "dt_calculate_cumulative_distribution_function_datas": selectedColumnData?.map(col => ({
                    "column_name": col?.column_name,
                    "mean": col?.mean ? col?.mean.toString() : "",
                    "standard_deviation": col?.standard_deviation ? col?.standard_deviation.toString() : "",
                    "follows_normal_distribution": col?.follows_normal_distribution,
                    "cumulative_distribution_function_column_name": col?.cumulative_distribution_function_column_name ? col?.cumulative_distribution_function_column_name : ""
                })),
                "dt_calculate_cumulative_distribution_function_undo_datas": []
            },
            requestFormData,
        })).then(res => {
            if (res.type == `${dtCumulativeDisFunctionNodesave.fulfilled}`) {
                dispatch(getCumulativeDistibutionDetails({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": currentNodeData?.nodeCategoryId
                }))
                refreshDataTable()
                dispatch(fetchNumericColumnList({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": currentNodeData?.nodeCategoryId
                }))
            }
        })

        setIsEditMode(false)

    }

    const onRemoveClick = (column) => {
        setselectedColumnData(prev => prev?.filter(col => col.column_name != column?.column_name))
    }

    const getColumnDropdownByValue = value => columnList?.find(column => column?.column_name == value)

    const onAddClick = () => {
        setselectedColumnData(prev => ([
            ...prev,
            {
                "column_name": formik.values.columnName,
                "column_label": getColumnDropdownByValue(formik.values.columnName)?.column_label,
                "mean": columnMeanAndStandard?.average,
                "standard_deviation": columnMeanAndStandard?.standardDeviation,
                "follows_normal_distribution": columnMeanAndStandard?.normalDistribution,
                isAddedNew: true,
            }
        ]))
        formik.setFieldValue("columnName", undefined)
        setcolumnMeanAndStandard(null)
    }

    useEffect(() => {
        if (currentNodeData?.nodeCategoryId) {
            dispatch(fetchNumericColumnList({
                "workflow_id": currentWorkflowId,
                "ecl_node_id": currentEclNodeId,
                "category_id": currentNodeData?.nodeCategoryId
            }))
        }
    }, [currentNodeData?.nodeCategoryId])


    useEffect(() => {
        if (currentNodeData?.cumulatativeDistributionData?.isEdit == true) {
            setIsEditMode(false)
        }
    }, [currentNodeData?.cumulatativeDistributionData?.isEdit])


    useEffect(() => {
        if (currentNodeData?.cumulatativeDistributionData?.dtCalculateCumulativeDistributionFunctionDatas) {
            setselectedColumnData(currentNodeData?.cumulatativeDistributionData?.dtCalculateCumulativeDistributionFunctionDatas)
        }
    }, [currentNodeData?.cumulatativeDistributionData?.dtCalculateCumulativeDistributionFunctionDatas])



    const onCategorySave = () => {
        dispatch(getCumulativeDistibutionDetails({
            "workflow_id": currentWorkflowId,
            "ecl_node_id": currentEclNodeId,
            "category_id": formik.values.category
        }))
    }

    const editDataColumns = useMemo(() => {
        if(isEditMode){
        return [
            columnHelper.accessor("column_label", {
                header: "Column name",
                thClassName: "",
            }),
            columnHelper.accessor("mean", {
                header: "Mean",
                thClassName: "",
            }),
            columnHelper.accessor("standard_deviation", {
                header: "Standard deviation",
                thClassName: "",
            }),
            columnHelper.accessor("follows_normal_distribution", {
                header: "Normal distribution",
                thClassName: "",
            }),
            columnHelper.display({
                header: "Action",
                thClassName: "w-[10%]",
                cell: ({ row }) => {
                    return row?.original?.isAddedNew == true ? <div className='col-span-1' onClick={() => onRemoveClick(row.original)}>
                        <button className='text-[#4F49DF] text-[12px] xl:text-[14px] flex items-center gap-2'>
                            <img alt='remove' src={CloseIcon} className='w-3' />
                            Remove
                        </button>
                    </div> : null
                }
            }),
        ]}
        else{
            return[
            columnHelper.accessor("column_label", {
                header: "Column name",
                thClassName: "",
            }),
            columnHelper.accessor("mean", {
                header: "Mean",
                thClassName: "",
            }),
            columnHelper.accessor("standard_deviation", {
                header: "Standard deviation",
                thClassName: "",
            }),
            columnHelper.accessor("follows_normal_distribution", {
                header: "Normal distribution",
                thClassName: "",
            })           
        ]
        }
    }, [])

    const editData = useMemo(() => {
        return selectedColumnData
    }, [selectedColumnData])


    const cumulativeFunctionTableColumns = useMemo(() => {
        return [
            columnHelper.accessor("column_label", {
                header: "Column name",
                thClassName: "",
            }),
            columnHelper.accessor("mean", {
                header: "Mean",
                thClassName: "",
            }),
            columnHelper.accessor("standard_deviation", {
                header: "Standard deviation",
                thClassName: "",
            }),
            columnHelper.accessor("follows_normal_distribution", {
                header: "Normal distribution",
                thClassName: "",
            }),           
        ]
    }, [])

    const cumulativeFunctionTableData = useMemo(() => {
        return currentNodeData?.cumulatativeDistributionData?.dtCalculateCumulativeDistributionFunctionDatas ?? []
    }, [currentNodeData?.cumulatativeDistributionData?.dtCalculateCumulativeDistributionFunctionDatas])


    const onProceedClick = () => {
        if (currentNodeData?.node_status == 'Completed') {
            return dispatch(openModalBuilder())
        }
        const requestFormData = getCalculationFormData({
            client_branch_level_id: location?.state?.levelTwoCardId,
            isFrom: "cumulativeCalculation",
            category_id: currentNodeData?.nodeCategoryId,
            node_id: currentEclNodeId,
            state: reduxState,
            nodeStatus: "Completed",
        })

        dispatch(exceptionAnalysisProceed({
            requestFormData,
            workflow_id: currentWorkflowId,
            isProceeded: true

        })).then((res) => {
            if (res.type == `${exceptionAnalysisProceed.fulfilled}`) {
                dispatch(getCumulativeDistibutionDetails({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": formik.values.category
                }))
            }
        }).catch((error) => {

        });
    }

    const onRadioButtonChange = (value) => {
        setcolumnMeanAndStandard((prev) => ({
            ...prev,
            normalDistribution: value
        }));
    };
    
    const renderCumulativeDistibutionInputs = () => {

        if (currentNodeData?.cumulatativeDistributionData?.isEdit == true && !isEditMode) return

        if (isDataLoading) {
            return <>
                <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={4} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
                <div className='grid grid-cols-10 gap-4 mt-2'>
                    {Array.from(Array(3)).map(d => (
                        <div className='col-span-2'>
                            <Skeleton height={20} />
                        </div>
                    ))}
                    <div className='col-span-1'>
                        <Skeleton height={20} />
                    </div>
                </div>
            </>
        }
        if (!currentNodeData?.nodeCategoryId || !isEditMode) return;





        const disableAdd = !formik.values?.columnName || !columnMeanAndStandard?.average
            || !columnMeanAndStandard?.standardDeviation || isGettingScore || isCalculating

        const disableSave = editData?.filter(data => data?.isAddedNew)?.length < 1 || formik.values.columnName != undefined

        return <>
            {editData?.length > 0 && <SummaryTable
                columns={editDataColumns}
                data={editData}
                tdClassName='bg-[#ECECEC] text-[#707070]'
                thClassName='!bg-[#4339A8]'
                className='mt-4 border-separate border-spacing-y-2'
            />}

            <div className='flex gap-5 items-end'>
                <DropDown
                    dropDownOptions={columnList?.map(data => ({
                        label: data?.column_label,
                        value: data?.column_name,
                        disabled: selectedColumnData?.findIndex(col => (col?.column_name == data?.column_name && col?.isAddedNew)) != -1
                    })) ?? []}
                    placeholder={'Select Column Name'}
                    name={'usedCategory'}
                    ordinary={true}
                    disabled={location?.state?.isfromHistory || isCalculating}
                    customTags={false}
                    noDataFountMessage={'File must have integer column'}
                    value={formik.values?.columnName ? formik.values?.columnName : undefined}
                    onOptionChange={onColumnNameChange}
                />
                <div className='w-full'>
                    <p className='text-[12px] text-[#37384C] whitespace-nowrap'>Whether it follows standard normal distribution?</p>
                    <div className='flex space-x-4'>
                        <div className='flex space-x-2 text-[12px]'>
                            <input className='cursor-pointer' onChange={()=>onRadioButtonChange('Yes')} checked={columnMeanAndStandard?.normalDistribution == 'Yes'} id='yes' value="yes" type='radio' />
                            <label className='cursor-pointer' htmlFor='yes'>Yes</label>
                        </div>

                        <div className='flex space-x-2 text-[12px]'>
                        <input className='cursor-pointer' onChange={()=>onRadioButtonChange('No')} checked={columnMeanAndStandard?.normalDistribution == 'No'} id='no' value="no" type='radio' />
                        <label className='cursor-pointer' htmlFor='no'>No</label>
                        </div>
                     </div>
                </div>
                <div className='w-full'>
                    <h2 className='font-semibold text-[12px] 2xl:text-[13px] my-1 '>Mean</h2>
                    {isGettingScore
                        ? <Skeleton height={20} className='w-full h-full' />
                        : <ReadOnlyValueDisplay>{columnMeanAndStandard?.average ?? 0}</ReadOnlyValueDisplay>}
                </div>
                <div className='w-full'>
                    <h2 className='font-semibold text-[12px] 2xl:text-[13px] my-1 '>Standard deviation</h2>
                    {isGettingScore
                        ? <Skeleton height={20} className='w-full h-full' />
                        : <ReadOnlyValueDisplay>{columnMeanAndStandard?.standardDeviation ?? 0}</ReadOnlyValueDisplay>}
                </div>
                <Button
                    bgColor='#42B2AC'
                    borderType=""
                    disabled={disableAdd}
                    label={'Add'}
                    color={'#ffffff'}
                    onClick={onAddClick}
                    size={'md'} 
                    className="!px-[1.7rem]"
                    />
            </div>

            <div className="mt-4 flex justify-center">
                <Button
                    bgColor='#42B2AC'
                    borderType=""
                    disabled={disableSave}
                    label={'Save'}
                    color={'#ffffff'}
                    onClick={onSave}
                    size={'md'} />
            </div>
        </>

    }

    const renderCumulativeDistibutionTable = () => {
        if (isDataLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        if (currentNodeData?.cumulatativeDistributionData?.dtCalculateCumulativeDistributionFunctionDatas?.length > 0) {
            return <>
                {isEditMode
                    ? null
                    : 
                    <>
                        <div className='2xl:py-3 py-1.5 bg-[#2B144C] grid grid-cols-3 items-center mt-4'>
                            <div className='flex gap-3 items-center py-0.5 ml-2 col-span-1'>
                                <button className='text-[#FFFFFF] bg-[#F7BE7E] py-0.5 px-2 text-[12px] rounded whitespace-nowrap'>Data category</button>
                                <p className='font-semibold text-[12px] text-white whitespace-nowrap'>{currentNodeData?.cumulatativeDistributionData?.categoryName}</p>
                            </div>
                            <h2 className='text-white text-[12px] font-semibold text-center w-full col-span-1'>Calculated output</h2>
                            {currentNodeData?.node_status == 'Completed' ? null
                                : <div className={`col-span-1 flex gap-1 items-center justify-end mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={() => setIsEditMode(true)}>
                                    <img alt='' src={EditIcon} className='w-4 h-4' />
                                    <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
                                </div>}
                        </div>
                        <SummaryTable
                            columns={cumulativeFunctionTableColumns}
                            data={cumulativeFunctionTableData}
                            theadClassName="z-10"
                            className={`${isEditMode ? "mt-4" : ""}`}
                        />
                    </>
            }
            </>
        }
    }

    const renderResultTable = () => {

        if (!currentNodeData?.nodeCategoryId) return

        if ((isDataLoading || isMergeSummaryLoading) && currentNodeData?.cumulatativeDistributionData?.isEdit == false) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        const mergeSummaryTableData = summaryData

        return <div className='mt-5'>

            <div className='flex justify-end items-center'>
                {isExportLoading
                    ? <img src={downloadingIcon} className='w-6 mr-2' />
                    : <img alt='' src={XlsIcon} className=' mb-2 object-cover mr-2 cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
            </div>

            {isMergeSummaryLoading
                ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
                : <>
                    <DataTable
                        columns={dataTableColumns}
                        data={mergeSummaryTableData?.columnDatas ?? []}
                        onTableStateChange={onTableStateChange}
                        totalCount={mergeSummaryTableData?.totalRecords}
                        isOptionsDisabled={location?.state?.isfromHistory}
                        getFilterOptions={getFilterOptions}
                        tableState={{
                            pagination: {
                                pageIndex: tableState.pageIndex,
                                pageSize: tableState.pageSize,
                            },
                            sorting: tableState?.sortBy ? [
                                {
                                    id: tableState?.sortBy,
                                    desc: tableState?.sortOrder === "desc"
                                }
                            ] : [],
                            columnFilters: tableState.columnFilters
                                ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                                : [],
                            columnPinning: tableState.columnPinning,
                            columnVisibility: tableState?.columnVisibility,
                        }}
                    />
                    {currentNodeData?.cumulatativeDistributionData?.isEdit == false
                        ? null
                        : currentNodeData?.id && <div className={`float-right 2xl:my-6 my-3 ${location?.state?.isfromHistory ? 'hidden' : 'block'}`}>
                            <ProceedButton
                                onClick={onProceedClick}
                                isLoading={isProceedLoading}
                        />
                    </div>}
                </>}

        </div>
    }

    const disableCategory = location?.state?.isfromHistory || isDataLoading || currentNodeData?.nodeCategoryId
    return (
         //note : need to change the state names and api's and redux based on the module

        filesMerging || isCalculating
            ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            : <div className='my-2'>
                {(currentNodeData?.cumulatativeDistributionData?.isEdit == true  && !isEditMode)
                    ? null
                    : <div className='flex gap-5'>
                    <DropDown
                        dropDownOptions={parseCategotyValueLabel(nodeWiseCategory)}
                        placeholder={'Select data category'}
                        name={'usedCategory'}
                        ordinary={true}
                        disabled={disableCategory}
                        customTags={false}
                        value={formik.values?.category ? formik.values?.category : undefined}
                        onOptionChange={onCategoryChange}
                    />
                    <Button
                        bgColor='#42B2AC'
                        borderType=""
                        disabled={disableCategory || !formik.values.category}
                        label={'Save'}
                        color={'#ffffff'}
                        onClick={onCategorySave}
                        size={'md'} />
                    </div>}

                {renderCumulativeDistibutionInputs()}
                {renderCumulativeDistibutionTable()}
                {renderResultTable()}
            </div>

    )
}

export default CumulativeDistributionFunction