import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { categoryNameUpdateSuccess, clientCreateSuccess, clientsApiError, clientsCountryLevelOneListFetchSuccess, clientsCountryListFetchSuccess, clientsListFetchSuccess, createCountryLevelSuccess, levelNameUpdateSuccess, manageCountrySuccess, manageLevelSuccess, clientsCountryLevelTwoListFetchSuccess} from "./Action";
import { CATEGORY_NAME_UPDATE, CLIENTS_COUNTRY_DATA_CLEARED, CLIENTS_DATA_CLEARED, CLIENTS_LIST, CLIENTS_LIST_FETCH_MORE_REQUESTED, CLIENTS_LIST_FETCH_SUCCEEDED, CLIENTS_LIST_SORTING, CLIENT_COUNTRY_LEVEL_ONE_LIST, CLIENT_COUNTRY_LEVEL_TWO_LIST, CLIENT_COUNTRY_LIST, CLIENT_CREATE_SUCCESS, CREATE_CLIENT, CREATE_COUNTRY_LEVEL, LEVELONE_DATA_CLEARED, LEVELTWO_DATA_CLEARED, LEVEL_NAME_UPDATE, MANAGE_COUNTRY, MANAGE_LEVEL, UPDATE_CLIENT } from "./ActionType";
import { Constants, localStorageKeys, loginLocalStorageKeys } from "../../Utils/Constants";
import { CategoryNameUpdate, ClientsCountryLevelOneList, ClientsCountryList, ClientsList, CreateClient, CreateCountryLevel, LevelNameUpdate, ManageCountry, ManageLevel, SortedClientsCountryLevelOneList, SortedClientsCountryLevelOneListWithCountry, SortedClientsCountryLevelOneListWithCountryAndSearch, SortedClientsCountryLevelOneListWithSearch, SortedClientsCountryList, SortedClientsCountryListWithCountry, SortedClientsCountryListWithCountryAndSearch, SortedClientsCountryListWithSearch, SortedClientsList, SortedClientsListWithCountry, SortedClientsListWithCountryAndSearch, SortedClientsListWithSearch, UpdateClient, SortedClientsCountryLevelTwoList, SortedClientsCountryLevelTwoListWithCountry, SortedClientsCountryLevelTwoListWithCountryAndSearch, SortedClientsCountryLevelTwoListWithSearch } from "../../Helpers/ApiHelpers";
import { logoutUser } from "../action";


function* clientsList(action) {
  
  try {
    const response = yield call(ClientsList, {
        record:Constants.listSize,
        search_term:action?.payload?.searchTerm ?? ""
    });

    if (response.status === 200) {
      if (response.data.status === 200) {
       yield put(clientsListFetchSuccess(response?.data,CLIENTS_LIST_FETCH_SUCCEEDED))
      }      
    }else {
      yield put(clientsApiError(response));
    }
  } catch (error) {
    yield put(clientsApiError(error));
  }
}

function* getMoreClientsList({payload:{count,searchTerm}}) {
  
    try {
      const response = yield call(ClientsList, {
          record:count,
          search_term: searchTerm
      });
  
      if (response.status === 200) {
        if (response.data.status === 200) {
         yield put(clientsListFetchSuccess(response?.data,CLIENTS_LIST_FETCH_SUCCEEDED))
        }      
      }else {
        yield put(clientsApiError(response));
      }
    } catch (error) {
      yield put(clientsApiError(error));
    }
}

function* getCreateClient(data) {

  try {
    const response = yield call(CreateClient, {
      client_name:data?.payload?.client_name,
      primary_country_id:data?.payload?.primary_country_id,
      client_branch:data?.payload?.client_branch
    });

    if (response.status === 200) {
      if (response.data.status === 200) {
       yield put(clientCreateSuccess(response?.data))
      }      
    }else {
      yield put(clientsApiError(response));
    }
  } catch (error) {
    yield put(clientsApiError(error));
  }
  }

function* getUpdateClient(data) {

    try {
      const response = yield call(UpdateClient, {
        client_id:data?.payload?.client_name,
        primary_country_id:data?.payload?.primary_country_id,
        client_branch:data?.payload?.client_branch,
        client_branch_id_array:data?.payload?.client_branch_array
      });
  
      if (response.status === 200) {
        if (response.data.status === 200) {
         yield put(clientCreateSuccess(response?.data))
        }      
      }else {
        yield put(clientsApiError(response));
      }
    } catch (error) {
      yield put(clientsApiError(error));
    }
}

  //client list sorting
  function* getSortedClientsList(data) {
    try {
      if(localStorage.getItem(loginLocalStorageKeys.token)){
        const response = yield call(data?.payload?.country && data?.payload?.key ? SortedClientsListWithCountryAndSearch :data?.payload?.country ? SortedClientsListWithCountry: data?.payload?.key ? SortedClientsListWithSearch : SortedClientsList,data?.payload);
        // console.log(response,"payload")
        if (response.status === 200) {
          if (response.data.status === "Success") {
           yield put(clientsListFetchSuccess(response?.data))
          }      
        }else {
          yield put(clientsApiError(response));
        }
      }else{
        window.location.href = '/';      
      }

    } catch (error) {
      yield put(clientsApiError(error));
    }   
}

//client country list 
function* clientsCountryList(data) {
  // console.log(data?.payload,'data?.payload');
  try {
    const response = yield call(data?.payload?.level && data?.payload?.key ? SortedClientsCountryListWithCountryAndSearch :data?.payload?.level ? SortedClientsCountryListWithCountry: data?.payload?.key ? SortedClientsCountryListWithSearch : SortedClientsCountryList,data?.payload);
    // const response = yield call(ClientsCountryList,data?.payload);
    // console.log(response,"ClientsCountryList")
    if (response.status === 200) {
      if (response.data.status === "Success") {
       yield put(clientsCountryListFetchSuccess(response?.data))
      }      
    }else {
      yield put(clientsApiError(response));
    }
  } catch (error) {
    yield put(clientsApiError(error));
  }   
}
//client country level one list 
function* clientsCountryLevelOneList(data) {
  try {
    const response = yield call(data?.payload?.level && data?.payload?.key ? SortedClientsCountryLevelOneListWithCountryAndSearch :data?.payload?.level ? SortedClientsCountryLevelOneListWithCountry  : data?.payload?.key ? SortedClientsCountryLevelOneListWithSearch : SortedClientsCountryLevelOneList ,data?.payload);
    if (response.status === 200) {
      if (response.data.status ==200) {
       yield put(clientsCountryLevelOneListFetchSuccess(response?.data))
      }      
    }else {
      yield put(clientsApiError(response));
    }
  } catch (error) {
    yield put(clientsApiError(error));
  }   
}

//client country level two list 
function* clientsCountryLevelTwoList(data) {
  try {
    const response = yield call(data?.payload?.level && data?.payload?.key ? SortedClientsCountryLevelTwoListWithCountryAndSearch :data?.payload?.level ? SortedClientsCountryLevelTwoListWithCountry  : data?.payload?.key ? SortedClientsCountryLevelTwoListWithSearch : SortedClientsCountryLevelTwoList ,data?.payload);
    if (response.status === 200) {
      if (response.data.status ==200) {
       yield put(clientsCountryLevelTwoListFetchSuccess(response?.data))
      }      
    }else {
      yield put(clientsApiError(response));
    }
  } catch (error) {
    yield put(clientsApiError(error));
  }   
}

//level name update

function* levelNameUpdate(data) {

  try {
    const response = yield call(LevelNameUpdate, {   
      client_country_id:data?.payload?.countryId,
      level:data?.payload?.level,
      parent_level_id:data?.payload?.parrent,
      level_name:data?.payload?.name

    });

    if (response.status === 200) {
      if (response.data.status === 200) {
       yield put(levelNameUpdateSuccess(response?.data))
      }      
    }else {
      yield put(clientsApiError(response));
    }
  } catch (error) {
    yield put(clientsApiError(error));
  }
  }

//category name update

function* categoryNameUpdate(data) {

  try {
    const response = yield call(CategoryNameUpdate, {   
      client_country_id:data?.payload?.countryId,
      level:data?.payload?.level,
      parent_level_id:data?.payload?.parrent,
      client_branch_level_id:data?.payload?.client_branch,
      category_name:data?.payload?.name,

    });

    if (response.status === 200) {
      if (response.data.status === 200) {
       yield put(categoryNameUpdateSuccess(response?.data))
      }      
    }else {
      yield put(clientsApiError(response));
    }
  } catch (error) {
    yield put(clientsApiError(error));
  }
  }

//Manage country

function* manageCountry(data) {

  try {
    const response = yield call(ManageCountry, {   
      client_id:data?.payload
    });


    if (response.status === 200) {
      if (response.data.status === 200) {
       yield put(manageCountrySuccess(response?.data))
      }      
    }else {
      yield put(clientsApiError(response));
    }
  } catch (error) {
    yield put(clientsApiError(error));
  }
  }

//Manage Level

function* manageLevel(data) {
  // console.log(data?.payload,"payload data");
  try {
    const response = yield call(ManageLevel, {   
      client_id:data?.payload?.client_id,
      client_country_id:data?.payload?.client_country_id,
      level:data?.payload?.level,
      parent_level_id:data?.payload?.parent_level_id,

    });

    if (response.status === 200) {
      if (response.data.status === 200) {
       yield put(manageLevelSuccess(response?.data))
      }      
    }else {
      yield put(clientsApiError(response));
    }
  } catch (error) {
    yield put(clientsApiError(error));
  }
  }

//
function* getCreateCountryLevel(data) {
  const requestBody = data?.payload

try {
  const response = yield call(CreateCountryLevel,requestBody);

  if (response.status === 200) {
    if (response.data.status === 200) {
     yield put(createCountryLevelSuccess(response?.data))
    }      
  }else {
    yield put(clientsApiError(response));
  }
} catch (error) {
  yield put(clientsApiError(error));
}
}

function* getClientsDataClear(data) {
  try {    
    yield put(clientsListFetchSuccess([]));
  } catch (error) {
    // Handle any potential errors
    console.error('Error clearing API response:', error);
  }
}
function* getClientsCountryDataClear(data) {
  try {    
    yield put(clientsCountryListFetchSuccess([]));
  } catch (error) {
    // Handle any potential errors
    console.error('Error clearing API response:', error);
  }
}
function* getLevelOneDataClear(data) {
  try {    
    yield put(clientsCountryLevelOneListFetchSuccess([]));
  } catch (error) {
    // Handle any potential errors
    console.error('Error clearing API response:', error);
  }
}
function* getLevelTwoDataClear(data) {
  try {    
    yield put(clientsCountryLevelTwoListFetchSuccess([]));
  } catch (error) {
    // Handle any potential errors
    console.error('Error clearing API response:', error);
  }
}
  
function* clientsSaga() {
    yield takeEvery(CLIENTS_LIST, clientsList);  
    yield takeEvery(CLIENTS_LIST_FETCH_MORE_REQUESTED, getMoreClientsList);  
    yield takeEvery(CREATE_CLIENT, getCreateClient);        
    yield takeEvery(CLIENTS_LIST_SORTING, getSortedClientsList);  
    yield takeEvery(CLIENT_COUNTRY_LIST,clientsCountryList)
    yield takeEvery(CLIENT_COUNTRY_LEVEL_ONE_LIST,clientsCountryLevelOneList)
    yield takeEvery(CLIENT_COUNTRY_LEVEL_TWO_LIST,clientsCountryLevelTwoList)
    yield takeEvery(LEVEL_NAME_UPDATE,levelNameUpdate)
    yield takeEvery(CATEGORY_NAME_UPDATE,categoryNameUpdate)
    yield takeEvery(MANAGE_COUNTRY,manageCountry);
    yield takeEvery(UPDATE_CLIENT, getUpdateClient);  
    yield takeEvery(MANAGE_LEVEL, manageLevel);  
    yield takeEvery(CREATE_COUNTRY_LEVEL, getCreateCountryLevel);  
    yield takeEvery(CLIENTS_DATA_CLEARED, getClientsDataClear);  
    yield takeEvery(CLIENTS_COUNTRY_DATA_CLEARED, getClientsCountryDataClear);  
    yield takeEvery(LEVELONE_DATA_CLEARED, getLevelOneDataClear);  
    yield takeEvery(LEVELTWO_DATA_CLEARED, getLevelTwoDataClear);  
}

export default clientsSaga;
