import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
//image
import Star from "../../Images/Group 188.svg"
import editIcon from '../../Images/edit.svg'
import HamburgerMenu from "../../Images/hamburgerMenu.svg"
//Components
import SearchInput from './SearchInput'
import Button from '../Button'
//redux
import { useDispatch } from 'react-redux'
import { CategoryListDataClear, categoryNameUpdate } from '../../Store/action'
import { Baseurl } from '../../Utils/Constants'
import { CATEGORY_AVAILABILITY, MANAGAE_LEVEL_REMOVE } from '../../Helpers/EndPoints'
//popup
import { toast } from 'react-toastify'
//tooltip
import { Tooltip,Popover } from 'antd';
import OverflowTooltip from '../ToolTip/OverFlowToolTip';
import RouteSegments from '../../Routes/RouteSegments'





const Pagecard = ({ data, onClick,title,isPrimary,ClientCountryId,client_branch_level_id,label,count,level,levelOneListCallBack, parent_level_id ,location,dataLength,setDeletableCardDetail,setUndoChanges,isFrom}) => {
    // console.log(client_branch_level_id,'client_branch_level_id');
    const { client_name, clientBranch } = data;
    const [heading, setHeading] = useState(title)
    const dispatch = useDispatch();
    const [showEdit, setShowEdit] = useState(false);
    // const [undoChanges, setUndoChanges] = useState(false);

    // const location = useLocation();
    const navigate = useNavigate()

    const fetchData = async () => {                                    
        try {
          // Make the API call and wait for it to complete
           dispatch(categoryNameUpdate(ClientCountryId, level, parent_level_id, client_branch_level_id, heading));
           new Promise((resolve) => {
            setTimeout(() => {
                levelOneListCallBack();
                setShowEdit(false);                            
            }, 500);
          });  
        } catch (error) {
          // Handle errors
          console.error('Error updating category name', error);
        }
      }
    
    const categoryNameCheck = (e)=>{
        e.preventDefault(); // Prevent the default form submission behavior
    axios(`${Baseurl+CATEGORY_AVAILABILITY}`, {
      method: "POST",
      data: {                
            "client_id":data?.client_id,
            "client_country_id":ClientCountryId,
            "level":level,
            "parent_level_id":parent_level_id,
            "category_name":heading ,
            "id": client_branch_level_id                               
      },
      headers: {
        "Content-Type": "application/json",
        // "Authorization": getToken()
      },
    })
      .then((response) => {
        if (response?.data?.status==200) {                
          if(response?.data?.success){
            fetchData()            
          }
        }else if(response?.data?.status==205){
          toast.error(response?.data?.message)                    
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
    }
    const navigateTo = ()=>{
        dispatch(CategoryListDataClear())
        navigate (RouteSegments.MANAGE_CATEGORY,{ state: {
            clientID:data?.client_id,
            labelName:location?.labelName,
            data:location?.data,
            breadCrumbClientName:location?.breadCrumbClientName,
            breadCrumbCountryName:location?.breadCrumbCountryName,
            client_country_id:location?.client_country_id,
            client_branch_level_two_id:location?.client_branch_level_two_id,
            level_two_name:location?.level_two_name
        } })
    }
    const [open, setOpen] = useState(false);

    const handleRemove = (title,id) =>{

      if(dataLength == 1){
        toast.warning('Atleast one category is mandatory')
      }else{           
        setUndoChanges(true)
        setDeletableCardDetail({name:title, id:id })       
      }
      setOpen(false)
      
    }   

    const handleOpenChange = (newOpen) => {
      setOpen(newOpen);
    };
  
       return (
        <>        
        <div className="block rounded-lg rounded-b-md bg-white text-center shadow-[0_9px_31px_6px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] cursor-pointer" onClick={level ?()=> '/#': ()=>onClick(title,data?.id)}>
                <div className="grid grid-cols-12 items-center border-b-4 border-[#00B0F0] rounded-t-md px-4 text-start text-[15px] text-white rounded-sm bg-[#2B144D] h-[2.5rem]">                                    
                    <div className=' gap-2 col-span-8 flex'>
                    {isPrimary && <img src={Star} alt="star" className='w-3'/>}

                    {level && showEdit ?
                        <form onSubmit={(e) => { e.preventDefault(); categoryNameCheck(e); }}>
                    <div className='flex items-center gap-2'>
                        <SearchInput searchTerm={heading} setSearchTerm={setHeading} size={'sm'} icon={false} forLevel={true} name="categoryName"/>
                        <Button bgColor="#70AD47"
                                borderType="normal"
                                label={"Save"}
                                color={"#ffffff"}
                                size={'sm'} 
                                forLevel={true}
                                disabled={!heading}
                                type={'submit'}                                
                                // onClick={()=>categoryNameCheck()}                                                                
                        />    
                    </div>
                    </form>
                    : level && !showEdit ?
                    <div className='flex items-center gap-2 w-full'>
                    <p className='xl:leading-[40px] leading-[30px] font-semibold xl:max-w-[98%] max-w-[80%]'><OverflowTooltip text={title} /></p>

                    <img src={editIcon} alt="edit" className='w-3 brightness-0 invert-[1] cursor-pointer' onClick={()=>setShowEdit(true)}/>
                    </div>
                    :
                    <p className='leading-[40px] font-semibold xl:max-w-[98%] max-w-[80%]'><OverflowTooltip text={title} /></p>
                    }
                    
                    </div>
                    {level == 2 ?
                    <div className='col-span-4 flex justify-end'>                       
                        <Popover
                            trigger="click"
                            placement='bottomRight'
                            openClassName='p-0'
                            overlayInnerStyle={{
                            padding: '8px 10px 8px 10px',
                            boxShadow: '1px 2px 3px 0.5px #D0CECE',
                            borderRadius:'3px',
                            zIndex:'10'
                            }}
                            
                            content={ 
                            <div style={{ minWidth: '130px'}} >
                            <div className="cursor-pointer text-[13px] text-[#707070] hover:text-[#000000]  border-b border-b-[#D0CECE]" onClick={()=>navigateTo()}>Define data categories</div>
                            <div className="cursor-pointer text-[13px] text-[#707070] hover:text-[#000000]" onClick={()=>handleRemove(title,client_branch_level_id)}>Remove</div>                            
                            </div>
                            }
                            open={open}
                        onOpenChange={handleOpenChange} 
                            >
                            <img src={HamburgerMenu} alt="menu" className='w-[1rem] h-[1rem]'/>

                        </Popover>
                    </div>
                    :
                    <div className='flex rounded-lg  h-[1.5rem] col-span-4 justify-end'>
                        <p className="rounded-l-sm flex items-center pr-[1rem] bg-white text-[#000000] px-2 whitespace-nowrap  xl:text-[13px] text-[11px] font-semibold" >{label}</p>
                        <p className="rounded-r-sm text-center bg-[#00B050] text-[#ffffff] px-2 flex items-center  xl:text-[11px] text-[9px] font-semibold" >{count}</p>
                    </div>                    
                    }
                </div>
               
            <div className="px-1 py-1 cursor-pointer" onClick={()=>onClick(title,data?.id)}>                           
                <div className="grid grid-cols-5 gap-1">                        
                        <div className='col-span-3'></div>
                        <div className='bg-[#E8E8E8] col-span-2 xl:min-h-[11rem] min-h-[9rem]'>
                            <div className='bg-[#E8E8E8] px-[0.3rem] py-2'>
                                <p className='text-[#000000] xl:text-[11px] text-[9px] whitespace-nowrap  py-0.5 border-2 border-[#707070] rounded'>Most recent execution</p>
                            </div>
                            <div className='space-y-1 mb-2 text-start xl:px-4 lg:px-2'>
                                <div className='grid grid-cols-2 gap-3'>
                                    <p className='text-[#707070] xl:text-[11px] text-[9px]'>Date</p>

                                    <p className='text-[#000000] xl:text-[11px] text-[9px] font-semibold whitespace-nowrap'>12-02-23</p>
                                </div>
                                <div className='grid grid-cols-2 gap-3'>
                                    <p className='text-[#707070] xl:text-[11px] text-[9px]'>Status</p>

                                    <p className='text-[#000000] xl:text-[11px] text-[9px] font-semibold'>Completed</p>
                                </div>
                                {!level && isFrom != 'countries' &&
                                <div className='grid grid-cols-2 gap-3'>
                                    <p className='text-[#707070] xl:text-[11px] text-[9px]'>Country</p>

                                    <p className='text-[#000000] xl:text-[11px] text-[9px] font-semibold'>Australia</p>
                                </div>
                                }
                                {!level &&
                                <div className='grid grid-cols-2 gap-3'>
                                    <p className='text-[#707070] xl:text-[11px] text-[9px]'>Segment</p>

                                    <p className='text-[#000000] xl:text-[11px] text-[9px] font-semibold'>MSME</p>
                                </div>
                                }
                                {level !=2 &&
                                <div className='grid grid-cols-2 gap-3'>
                                    <p className='text-[#707070] xl:text-[11px] text-[9px]'>Product</p>

                                    <p className='text-[#000000] xl:text-[11px] text-[9px] font-semibold'>WC</p>
                                </div>
                                }
                                <div className='grid grid-cols-2 gap-3'>
                                    <p className='text-[#707070] xl:text-[11px] text-[9px]'>ECL</p>

                                    <p className='text-[#000000] xl:text-[11px] text-[9px] font-semibold'>A $ 3.4M</p>
                                </div>
                            </div>
                        </div>
                </div>

            </div>
        </div>
        </>

    )
    }
export default Pagecard;