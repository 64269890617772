import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import dropdownIcon from '../../Images/Polygon 1.svg';
import { Baseurl, ImgBaseUrl, localStorageKeys } from '../../Utils/Constants';
import ModelBuilderSkeleton from '../Skeleton/ModelBuilderSkeleton';
import { useDispatch, useSelector } from 'react-redux';
import { ModelBuilderList } from '../../Store/action';

const CustomAccordion = ({ items,drag ,isFrom}) => {
  const [activeIndices, setActiveIndices] = useState([0]);
  const [isDisabled,setIsDisabled] = useState(true)
  const [sections, setSections] = useState();

  const handleItemClick = (index) => {
    setActiveIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [...prevIndices, index];
      }
    });
  };
  const handleCardClick =()=>{
    // setIsDisabled(false)
  } 
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(ModelBuilderList())
  },[])
  
  const { ModelBuilderData} = useSelector(state => {
    return {
        ModelBuilderData: state?.ModelBuilder?.modelBuilder,        
    }
})
  const data = JSON.parse(localStorage.getItem(localStorageKeys.modelBuilderList))

useEffect(()=>{
  if(ModelBuilderData){
  setSections(ModelBuilderData)
}
},[ModelBuilderData])

  return (
    <div className=''>
      {sections ?
      sections?.map((item, index) => (
        <div key={index} className="mb-4">
          <div
            className="flex items-center justify-between p-2 bg-gray-200 cursor-pointer bg-[#331D53] text-white rounded"
            onClick={() => handleItemClick(index)}
          >
            <span className="text-[13px]">{item?.name}</span>
            <img
              src={dropdownIcon}
              className={classNames('w-3 h-3 brightness-0 invert-[1]', {
                'transform rotate-180': activeIndices.includes(index),
              })}
              alt="Dropdown Icon"
            />
          </div>
          {activeIndices?.includes(index) && (
            <div className="py-3 px-3 bg-gray-100">
              <div className='grid grid-cols-3 items-center gap-3 2xl:gap-5'>
                {item?.ecl_nodes?.map((data,i)=>{
                  return(
                    <React.Fragment key={i}>
                    {isFrom =='landingPage'
                    ?
                    <div className={`col-span-1 min-h-[7.5rem] h-[9rem] w-[8.5rem] min-w-[7.2rem] bg-[#E7E6E6] hover:bg-[#D0CECE] rounded-lg flex flex-col gap-3 p-1.5 items-center justify-center  ${isDisabled ? 'cursor-not-allowed':'cursor-pointer'}`} onClick={()=>handleCardClick()}>
                      <p className='text-[#331D53] text-[12px] text-center'>{data?.name}</p>
                      <img src={ImgBaseUrl+data?.image_name} className={`h-[30px] w-[25px] ${isDisabled ? 'color-change-image':''}`} draggable="false"/>
                      <p className='text-[#242B3B] text-[10px] text-center leading-[14px]'>{data?.description}</p>
                  </div>
                  :
                  <div className={`col-span-1 min-h-[7.5rem] h-[9rem] w-[8.5rem] min-w-[7.2rem] bg-[#E7E6E6] hover:bg-[#D0CECE] rounded-lg flex flex-col gap-3 p-1.5 items-center justify-center  ${isDisabled && item?.id !=1 ? 'cursor-not-allowed':'cursor-pointer'}`} onClick={()=>handleCardClick()}>
                  <p className='text-[#331D53] text-[12px]'>{data?.name}</p>
                    <img id={item?.id+i} onDragStart={(event) => drag(event)} 
                    draggable="true" src={ImgBaseUrl+data?.image_name} className={`drag-enabled h-[30px] w-[25px] ${isDisabled && item?.id !=1 ? 'color-change-image':''}`}/>
                  <p className='text-[#242B3B] text-[10px] text-center leading-[14px]'>{data?.description}</p>
                  </div>
                    }
                 </React.Fragment>
                  )})
                }                
                </div>                
            </div>
          )}
        </div>
      ))
      :<ModelBuilderSkeleton cards={3} />
    }
    </div>
  );
};

export default CustomAccordion;
