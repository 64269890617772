export const LOGIN_USER     = "LOGIN_USER"
export const LOGOUT_USER    = "LOGOUT_USER"
export const REGISTER_USER  = "REGISTER_USER"
export const REGISTER_EMAIL_EXIST  = "REGISTER_EMAIL_EXIST"
export const RESET_REGISRER_ERROR  = "RESET_REGISRER_ERROR"
export const REGISTER_SUCCESS  = "REGISTER_SUCCESS"
export const REGISTER_RESPONSE_DATACLEAR  = "REGISTER_RESPONSE_DATACLEAR"
export const LOGIN_SUCCESS  = "LOGIN_SUCCESS"
export const API_ERROR      = "LOGIN_API_ERROR"
export const LOGIN_ERROR    = "LOGIN_ERROR"
export const RESET_LOGIN_ERROR    = "RESET_LOGIN_ERROR"

//forgot password
export const FORGOTPASSWORD = "FORGOTPASSWORD"
export const FORGOT_PASSWORD_SUCCESS = "FORGOTPASSWORD_SUCCESS"
export const FORGOT_PASSWORD_ERROR = "FORGOT_PASSWORD_ERROR"

//reset password
export const RESET_PASSWORD           = "RESET_PASSWORD_USER"
export const RESET_PASSWORD_SUCCESS   = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_API_ERROR = "RESET_PASSWORD_API_ERROR"

//login from admin
export const LOGIN_FROM_ADMIN_USER      = "LOGIN_FROM_ADMIN_USER"
export const LOGIN_FROM_ADMIN_SUCCESS   = "LOGIN_FROM_ADMIN_SUCCESS"
export const LOGIN_FROM_ADMIN_API_ERROR = "LOGIN_FROM_ADMIN_API_ERROR"
