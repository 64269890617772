import { Select } from 'antd';
import React, { useEffect, useState } from 'react'
import DownArrow from './CommonIcons/DownArrow';
import Tick from "../../src/Images/tick.png"

function SelectInput({
    ref,
    name,
    onBlur,
    onChange,
    value,
    placeholder,
    options,
    mode,
    menu,
    ...props
}) {
    const [isOpen, setIsOpen] = useState(false)

    console.log(placeholder,"placeholder");
    return (
        <Select
            ref={ref}
            name={name}
            mode={mode ?? "single"}            
            onBlur={onBlur}
            showSearch          
            filterOption={(input, option) => 
              option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            options={options}
            onDropdownVisibleChange={(visible) => setIsOpen((prevState) => visible)}
            suffixIcon={<DownArrow color="#000000" width={'10px'} height={'10px'} className={isOpen ? 'transition-transform transform rotate-180' : 'transition-transform transform rotate-[-180]'} />}
            className={` ${menu==="datatable" ?"border-[1px]" :"border-[2px]"} focus:border-[2px] custom-select-height focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none`}
            style={{
                width: '100%',
                border: ' 2px solid #00A7B5',
                // height: '2.2rem',
                borderRadius: '4px',
            }}
            {...props}
        />
    )
}

export const SelectInputMismatch = ({
  defaultValue,
  ref,
  name,
  onBlur,
  onChange,
  placeholder,
  options,
  mode,
  menu,
  ...props
}) =>{
  const [value, setValue] = useState(defaultValue);
  const [isOpen, setIsOpen] = useState(false);

  // Update value when defaultValue changes
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (newValue) => {
    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };
  const renderOption = (option) => (
    <div>
      {option?.label} ({option?.value})
    </div>
  );
  
  console.log(options,defaultValue,"placeholder");
  return (
      <Select
          ref={ref}
          name={name}
          mode={mode ?? "single"}            
          onBlur={onBlur}
          showSearch          
          filterOption={(input, option) => 
            option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={handleChange}
          value={value}
          placeholder={placeholder}
          // options={options}
          options={options?.map(option => ({
            label: `${option.name}`,
            value: option.ecl_node_id
          }))}
          onDropdownVisibleChange={(visible) => setIsOpen((prevState) => visible)}
          suffixIcon={<DownArrow color="#000000" width={'10px'} height={'10px'} className={isOpen ? 'transition-transform transform rotate-180' : 'transition-transform transform rotate-[-180]'} />}
          className={` ${menu==="datatable" ?"border-[1px]" :"border-[2px]"} focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none`}
          style={{
              width: '100%',
              border: ' 2px solid #00A7B5',
              // height: '2.2rem',
              borderRadius: '4px',
          }}
          {...props}
      >
        {options?.map(option => (
      <Select.Option key={option.ecl_node_id} value={option.ecl_node_id}>
        {renderOption(option)}
      </Select.Option>
    ))}
    </Select>
  )
}

export const SelectInputOutlierDropdown = ({
  selectedMethod,
  ref,
  name,
  onBlur,
  onChange,
  placeholder,
  options,
  mode,
  menu,
  ...props
}) =>{
  const [value, setValue] = useState();
  const [isOpen, setIsOpen] = useState(false);

  // Update value when defaultValue changes
  // useEffect(() => {
  //   setValue(defaultValue);
  // }, [defaultValue]);

  const handleChange = (newValue) => {
    setValue(newValue);
    if (onChange) {
      console.log(newValue,"newValue");
      onChange(newValue);
    }
  };
  const renderOption = (option) => (
    <div>
      {option?.label} ({option?.value})
    </div>
  );
  
  console.log(options,"placeholder");
  return (
      <Select
          ref={ref}
          name={name}
          mode={mode ?? "single"}            
          onBlur={onBlur}
          showSearch          
          filterOption={(input, option) => 
            option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={handleChange}
          value={value}
          placeholder={placeholder}
          // options={options}
          options={options?.map(option => ({
            label: `${option.column_name}`,
            value: `${option.column_name}-${option.data_type}`
          }))}
          disabled={selectedMethod?true:false}
          onDropdownVisibleChange={(visible) => setIsOpen((prevState) => visible)}
          suffixIcon={<DownArrow color="#000000" width={'10px'} height={'10px'} className={isOpen ? 'transition-transform transform rotate-180' : 'transition-transform transform rotate-[-180]'} />}
          className={` ${menu==="datatable" ?"border-[1px]" :"border-[2px]"} focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none`}
          style={{
              width: '100%',
              border: ' 2px solid #00A7B5',
              // height: '2.2rem',
              borderRadius: '4px',
          }}
          {...props}
      >
        {options?.map(option => (
      <Select.Option key={option.column_name-option.data_type} value={option.column_name}>
        {renderOption(option)}
      </Select.Option>
    ))}
    </Select>
  )
}

export const SelectInputOutlierMethodDropdown = ({
  selectedMethod,
  ref,
  name,
  onBlur,
  onChange,
  placeholder,
  options,
  mode,
  menu,
  ...props
}) =>{
  const [value, setValue] = useState();
  const [isOpen, setIsOpen] = useState(false);

  // Update value when defaultValue changes
  // useEffect(() => {
  //   setValue(defaultValue);
  // }, [defaultValue]);

  const handleChange = (newValue) => {
    setValue(newValue);
    if (onChange) {
      console.log(newValue,"newValue");
      onChange(newValue);
    }
  };
  const renderOption = (option) => (
    <div>
      {option?.label} ({option?.value})
    </div>
  );
  
  console.log(options,"placeholder");
  return (
      <Select
          ref={ref}
          name={name}
          mode={mode ?? "single"}            
          onBlur={onBlur}
          showSearch          
          filterOption={(input, option) => 
            option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={handleChange}
          value={value}
          placeholder={placeholder}
          // options={options}
          options={options?.map(option => ({
            label: `${option.name}`,
            value: `${option.name}-${option.id}`
          }))}
          disabled={selectedMethod?true:false}
          onDropdownVisibleChange={(visible) => setIsOpen((prevState) => visible)}
          suffixIcon={<DownArrow color="#000000" width={'10px'} height={'10px'} className={isOpen ? 'transition-transform transform rotate-180' : 'transition-transform transform rotate-[-180]'} />}
          className={` ${menu==="datatable" ?"border-[1px]" :"border-[2px]"} focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none`}
          style={{
              width: '100%',
              border: ' 2px solid #00A7B5',
              // height: '2.2rem',
              borderRadius: '4px',
          }}
          {...props}
      >
        {options?.map(option => (
      <Select.Option key={option.name} value={option.name}>
        {renderOption(option)}
      </Select.Option>
    ))}
    </Select>
  )
}

export const TextInput = ({ placeholder, className, onChange, value, onBlur, disabled ,onKeyPress,onPaste,min,name}) => {
    return <input
        className={`${className} w-full rounded border-[2px] h-[1.7rem] 2xl:h-[2.2rem] border-[#00A7B5] focus:outline-none pl-4 placeholder:text-[#BEBEBE]`}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        value={value ?? ''}
        name={name}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        onPaste={onPaste}
        min={min}
    />
}

export const CheckBox = ({ checked, className, onChange, label, labelClassName, name }) => {
    return (
      <label className="inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          name={name}
          id="name"
          checked={checked}
          onChange={onChange}
          className={`hidden`}
        />
        <span className={`${className} flex items-center justify-center`}>
          {checked && (
            <img src={Tick} alt="Tick" className="w-2.5 h-2.5" />
          )}
        </span>
        <span className={`ml-2 ${labelClassName}`}>{label}</span>
      </label>
    );
  };
  

export default SelectInput