import React from 'react';
import { Box } from '@mui/material';
import Skeleton from 'react-loading-skeleton';

const PieChartSkeleton = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '300px', // Set the height of the container
      }}
    >
      <div style={{ position: 'relative', width: '200px', height: '200px' }}>
        <Skeleton
          circle
          width="100%"
          height="100%"
          style={{ position: 'absolute', top: '0', left: '0' }}
        />
        <Skeleton
          circle
          width="80%"
          height="80%"
          style={{ position: 'absolute', top: '10%', left: '10%', transform: 'rotate(-45deg)' }}
        />
        <Skeleton
          circle
          width="60%"
          height="60%"
          style={{ position: 'absolute', top: '20%', left: '20%', transform: 'rotate(45deg)' }}
        />
        {/* Add more Skeleton components for each field */}
      </div>
    </Box>
  );
};

export const BarChartSkeleton = () => {
  // Define the number of bars and their heights
  const bars = [
    { height: '40%' },
    { height: '60%' },
    { height: '80%' },
    { height: '30%' },
    { height: '50%' },
  ];

  return (
    <div className="flex items-end justify-around h-[20rem] w-full p-5 box-border space-x-4">
      {bars.map((bar, index) => (
        <div key={index} style={{ height: bar.height }} className="flex justify-center w-1/5 animate-pulse bg-gray-light">

        </div>
      ))}
    </div>
  );
};

export default PieChartSkeleton;
