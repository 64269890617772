import React, {useMemo, useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Button from '../../Components/Button';
import FileSelectIon from '../CommonIcons/FileSelectIon';
import threeDot from '../../Images/three-dot.svg';
import JSZip from 'jszip';
import AWS from 'aws-sdk';
import { localStorageKeys } from '../../Utils/Constants';
import CancelIcon from '../../Images/cancel.svg'
import { toast } from 'react-toastify';


const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

const UploadImageToS3WithReactS3 = ({
  category,
  selectedFiles, 
  setSelectedFiles,     
  isUploading, 
  setIsUploading,
  setProgress,   
  ecl_workflow_id,
  rowFieldId,
  isMultiple,
  dynamicFields,
  removeField,
  index,
  setDropPointError,  
  onFileInputChange,  
  fileName,
  setShowSubSheetPopup,
  setFileDetails,
  isFrom,
  setShowSummaryLoader,
  setIsFileSentToS3,
  onSubmit
}) => {
  const [error, setError] = useState('');
  const [dispalyFileName, setDisplayFileName] = useState(fileName ?? '');
  let file_details=[];
  const [loading,setIsloading] = useState(false)
 
  const handleFileInput = async (e, indexToReplace) => {
    setIsloading(false)
    let files = e.target.files;
    let iszip = false

    if (!files || files.length === 0) {
      setError('No files selected.');
      return;
    }
  if(isFrom == 'multiple'){
    const zip = new JSZip();
    const fileExtension = files[0].name.split('.').pop();

    if (['zip'].includes(fileExtension)) {
      try {
        const zipData = await zip.loadAsync(files[0]);
        const fileNames = Object.keys(zipData.files);

        console.log({ fileNames, zipData });

        files = await Promise.all(
          fileNames.map(async (fileName) => {
            const fileData = await zipData.files[fileName].async('blob');
            return new File([fileData], fileName);
          })
        );

        iszip = true;

        console.log({ files });
      } catch (error) {
        console.log(error);
      }
    }
  }

    const allowedExtensions = ['.csv', '.xls', '.xlsx'];

    let errorFlag = 0;

    for (let index = 0; index < files.length; index++) {
      const fileEx = files[index]?.name?.split('.').pop();
      if (!allowedExtensions.includes('.' + fileEx.toLowerCase())) {
        errorFlag += 1
      }
    }


    if (errorFlag > 0 && iszip) {
      if(isFrom=='single'){
        setError('File must be in xls, xlsx, or csv format.');
      }else{
        setError('File must be in zip, xls, xlsx, or csv format.');
      }
      return;
    }

    const fileArray = Array.from(files);
    // console.log(fileArray,'fileArray');

    // setIsFileChanged(true);
    setDisplayFileName(files[0]?.name)
    setError('');  
    setDropPointError(false)  
    setSelectedFiles(prevFiles => [...prevFiles, ...fileArray]);    
    
    onFileInputChange(fileArray, iszip)

  };

  const fileNameCheck =()=>{
    setIsloading(true)
      let sample =[];
      dynamicFields?.map((file,i)=>{
        const csvFileName = changeFileExtension(file?.selectedFiles[0].name, '.csv');
        sample.push({file_name:file?.selectedFiles[0].name})       
      })
      apiForFilnameCheck(sample)    
  }

  const apiForFilnameCheck = async (csvFileName)=> {   
   
    axios
    .post(
      'https://ecl.port0.org/laravelapi/api/file/upload/existCheck',
      {
        workflow_id: ecl_workflow_id,
        files: csvFileName,
      }
    )
    .then(response => {
      if (response?.status === 200) {{
        if(response?.data?.status ==200){
          handleUpload()
        }else if(response.data?.status == 205) {
          setError(response.data?.message)
          // console.log(response.message,'response.message');
        }
      }        
      }
    })
    .catch(error => {
      // Handle errors here if needed
      console.error('Error:', error);
    });
  
  };

  const handleSubmit = async (fileData) => {
    setIsUploading(true);
    try {
        const totalFiles = fileData?.length;
        let totalProgress = 0;
        const convertedFilePromises = fileData.map(async (convertedFile, index) => {
            const params = {
                ACL: 'public-read',
                Body: convertedFile.file,
                Bucket: S3_BUCKET,
                Key: convertedFile?.fileName,
            };
            setProgress(10);
            const s3UploadResponse = await myBucket.upload(params).on('httpUploadProgress', (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                totalProgress += percentCompleted / totalFiles; // Add progress percentage for each file
                const clampedProgress = Math.min(Math.round(totalProgress), 100); // Clamp progress to 100
                setProgress(clampedProgress);
            }).promise();
            
            console.log(s3UploadResponse,'s3UploadResponse');

            return {
                url: `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${convertedFile?.fileName}`,
                size: convertedFile?.size,
                fileName: convertedFile?.fileName
            };
        });
        const urlsArray = await Promise.all(convertedFilePromises);
        const urls = urlsArray.map(item => item.url);
        if (urls) {
            setShowSummaryLoader(true)
            setDropPointError(false)
            onSubmit(fileData, urls);
        }
        setIsFileSentToS3(true);

    } catch (error) {
        console.error('Error uploading files:', error);
        setProgress(0);
    } finally {
      setIsUploading(false);
        setProgress(0);
    }

  };

  const handleUpload = async () => {
    setError('');
    if (!selectedFiles || selectedFiles.length === 0 || !category) {
      setError('Both category and file must be selected.');
      return;
    }
   
    try {
      const fileUploadPromises = dynamicFields?.map(async (file, i) => {
        const allowedExtensions = ['.csv', '.xls', '.xlsx'];
        const fileExtension = file?.selectedFiles[0].name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes('.' + fileExtension)) {
          if(isFrom=='single'){
            setError('File must be in xls, xlsx, or csv format.');
          }else{
            setError('File must be in zip, xls, xlsx, or csv format.');
          }
          return;
        }
        const convertedFiles = await convertToCSV(file?.selectedFiles[0],file?.category[0].id);
         const convertedFilePromises = convertedFiles.map(async (convertedFile, index) => {
          if(convertedFile?.error){
            setError(convertedFile?.error)
            return;
          }
            const csvFileName = convertedFile?.subsheetName !=null ? changeFileExtension('',`${convertedFile.subsheetName}.csv`) :  changeFileExtension(file?.selectedFiles[0].name,`.csv`);
            return {
              file:convertedFile?.blob,
              size: convertedFile?.blob.size,
              fileName:csvFileName,
              category:file?.category[0].category_name,
              id:file?.category[0].id,
              mainFileName:file?.selectedFiles[0].name ,
              selected: convertedFile?.subSheetsCount == 1 ? true : false ,
            };             
  
          });        
        return Promise.all(convertedFilePromises);                  
      });

      const urlsArray = await Promise.all(fileUploadPromises);
      const urls = urlsArray.flat();   
    const groupedData = urls.reduce((acc, obj) => {
      const existingEntry = acc.find(entry => entry.fileName === obj.mainFileName);
      if (existingEntry) {
          existingEntry.subsheets.push(obj);
      } else {
          acc.push({ fileName: obj.mainFileName, subsheets: [obj] ,category:obj.category});
      }
      return acc;
  }, []);
    
      if(urls?.length > 0){
        if (urls[0] !=undefined ) {   
          setFileDetails(groupedData)
          setShowSubSheetPopup(true) 
          setIsloading(false)      
        } 
      }else{
        localStorage.removeItem(localStorageKeys.file_details)
        setIsUploading(true);
        handleSubmit(urls)
      }
          
    } catch (error) {
      console.error('Error uploading files:', error);
      setProgress(0);
    }
  
  };

  const transformSheets = (fileName,subSheets, workbook) => {
    const fileNameWithoutExtension = fileName.split('.')[0];
    const sheetsInfo = [];

    workbook?.SheetNames.forEach((sheetName) => {
        const sheet = workbook.Sheets[sheetName];
        const range = XLSX.utils.decode_range(sheet['!ref']);
        const sheetSize = (range.e.c + 1) * (range.e.r + 1); // Calculate size of sheet

        const sheetInfo = {
            sheet_name: sheetName,
            sheet_size: sheetSize,
            sheet_url: `https://ecl-images.s3.us-east-1.amazonaws.com/${fileNameWithoutExtension}_${sheetName.replace(/\s/g, ' ')}.csv`
        };

        sheetsInfo.push(sheetInfo);
    });

    return sheetsInfo;
  };

  const convertToCSV2 = async (file, category_id) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            try {
                const content = event.target.result;
                const workbook = XLSX.read(content, { type: 'binary' });
              
                let duplicateFound = false;
                let duplicateColumns = new Set();
                let subsheetsWithDuplicates = [];
                let emptySheets = [];
                
                // Check for duplicate column names in each subsheet
                workbook.SheetNames.forEach((sheetName) => {
                    const columnNames = new Set();
                    const worksheet = workbook.Sheets[sheetName];
                    const csvContent = generateCSVContent(worksheet);
                    const headers = csvContent.split('\n')[0].split(',');
                    headers.forEach((header) => {
                        if (!header.trim()) {
                            // Skip empty column names
                            return;
                        }

                        if (columnNames.has(header)) {
                            duplicateFound = true;
                            duplicateColumns.add(header);
                            if (!subsheetsWithDuplicates.includes(sheetName)) {
                                subsheetsWithDuplicates.push(sheetName);
                            }
                            return;
                        }
                        columnNames.add(header);
                    });
                });

                // If no duplicates found, proceed with conversion
                const convertedFiles = workbook.SheetNames.map((sheetName) => {
                    const worksheet = workbook.Sheets[sheetName];
                    // const csvContent = XLSX.utils.sheet_to_csv(workbook.Sheets[sheetName]);
                    const csvContent = generateCSVContent(worksheet);
                    const csvFile = new Blob([csvContent], { type: 'text/csv' });
                    const rows = csvContent.split('\n');

                    if (rows.length <= 1 || (rows.length === 2 && rows[1].trim() === '')) {
                        const fileName = file.name;
                        const errorMsg = `${fileName} ${sheetName ? ' - ' + sheetName : ''} must have data.`;
                        return { blob: csvFile, subsheetName: sheetName, error: errorMsg, subSheetsCount: workbook.SheetNames.length };
                    } else if (duplicateFound) {
                        const duplicateColumnNames = Array.from(duplicateColumns).join(', ');
                        const fileName = file.name;
                        const subsheetNames = subsheetsWithDuplicates.join(', ');
                        // const errorMsg = `${fileName} has duplicate columns (${duplicateColumnNames}) in the following subsheet(s): ${subsheetNames}`;
                        //  const errorMsg = (`${fileName} has duplicate columns in the following  subsheet(s): ${subsheetNames}`);
  //                    //  const errorMsg = `${fileName} has duplicate columns (${duplicateColumnNames}) in the following subsheet(s): ${subsheetNames}`;
                        const errorMsg = `The ${fileName} contains multiple columns with the same name, please make the correction & upload the right file`
                    } else {
                        return { blob: csvFile, subsheetName: sheetName, error: null , subSheetsCount: workbook.SheetNames.length };
                    }
                });

                file_details.push({ file_name: file.name, file_size: file.size, category_id: category_id, sub_sheets: transformSheets(file?.name, workbook.SheetNames,workbook) });
                localStorage.setItem(localStorageKeys.file_details, JSON.stringify(file_details));
                resolve(convertedFiles);
            } catch (error) {
                reject(error);
            }
        };

        reader.readAsBinaryString(file);
    });
  };

  // Function to generate CSV content while removing entirely empty columns and enclosing values with commas in double quotes
  function generateCSVContent(worksheet) {
    const csvLines = [];
    const range = XLSX.utils.decode_range(worksheet['!ref']);
    const nonEmptyColumns = new Set(); // Set to store non-empty column indices

    // Iterate over each cell to find non-empty columns
    for (let C = range.s.c; C <= range.e.c; ++C) {
        for (let R = range.s.r; R <= range.e.r; ++R) {
            const cellAddress = { c: C, r: R };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            if (worksheet[cellRef]) {
                nonEmptyColumns.add(C);
                break;
            }
        }
    }

    // Iterate over each row to construct CSV content with non-empty columns
    for (let R = range.s.r; R <= range.e.r; ++R) {
        const row = [];
        for (let C = range.s.c; C <= range.e.c; ++C) {
            if (!nonEmptyColumns.has(C)) continue; // Skip entirely empty columns

            const cellAddress = { c: C, r: R };
            const cellRef = XLSX.utils.encode_cell(cellAddress);
            let cellValue = '';
            if (worksheet[cellRef]) {
                cellValue = worksheet[cellRef].v;
                if (typeof cellValue === 'string' && cellValue.includes(',')) {
                    // If the cell value contains a comma, enclose it in double quotes
                    cellValue = `"${cellValue}"`;
                }
            }
            row.push(cellValue);
        }
        csvLines.push(row.join(','));
    }
    return csvLines.join('\n');
  }
    
  const convertToCSV = async (file, category_id) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => {
            try {
                const content = event.target.result;
                const workbook = XLSX.read(content, { type: 'binary' });
               
                let duplicateFound = false;
                let duplicateColumns = new Set();
                let subsheetsWithDuplicates = [];
                let emptySheets = [];
                let emptyColumnsIndex =[]
               
                // Check for duplicate column names in each subsheet
                workbook.SheetNames.forEach((sheetName) => {
                    const columnNames = new Set();
                    let worksheet = workbook.Sheets[sheetName];
                    const headers = XLSX.utils.sheet_to_csv(worksheet, { header: 1}).split('\n')[0].split(',');                    
                    headers.forEach((header,i) => {
                      // if (!header.trim()) {                          
                      //     emptyColumnsIndex.push(i)
                      //     return;
                      // }
                      
                      if (columnNames.has(header)) {
                          duplicateFound = true;
                          duplicateColumns.add(header);
                          if (!subsheetsWithDuplicates.includes(sheetName)) {
                              subsheetsWithDuplicates.push(sheetName);
                          }                           
                          return;
                      }
                      columnNames.add(header);
                  });                   
                });    
                 // Remove empty columns                
          
                console.log(emptyColumnsIndex,'emptycolumnIndex');
                // If no duplicates found, proceed with conversion
                const convertedFiles = workbook.SheetNames.map((sheetName) => {
                const worksheet = workbook.Sheets[sheetName];
                const csvContent = XLSX.utils.sheet_to_csv(worksheet);

                // Split the CSV content into rows
                  const rows = csvContent.split('\n');

                  // Remove empty columns
                  const headers = rows[0].split(',').filter(header => header.trim() !== '');
                  const nonEmptyRows = rows.map(row => {
                    const cells = row.split(',');
                    return headers.map((header, index) => {
                      if (cells[index]?.startsWith('"') && cells[index]?.endsWith('"')) {
                        // If value is enclosed in double quotes, return as is
                        return cells[index];
                      } else {
                        // Otherwise, split by comma
                        return cells[index] || '';
                      }
                    }).join(',');
                  });

                  // Filter out rows that contain only empty values
                  const filteredRows = nonEmptyRows.filter(row => {
                    return row.replace(/,/g, '').trim() !== '' || row.includes('""');
                  });

                  // Reconstruct CSV content with filtered rows
                  const filteredCsvContent = filteredRows.join('\n');

                  // Create Blob with filtered CSV content
                  const csvFile = new Blob([filteredCsvContent], { type: 'text/csv' });


                    console.log(csvContent,'csvContent');
                                
                    
                    if (rows.length <= 1 || (rows.length === 2 && rows[1].trim() === '')) {
                      const fileName = file.name;
                      const errorMsg = `${fileName} ${sheetName ? ' - ' + sheetName : ''} must have data.`;
                      return { blob: csvFile, subsheetName: sheetName, error: errorMsg, subSheetsCount: workbook.SheetNames.length };
                    }else if (duplicateFound) {
                      const duplicateColumnNames = Array.from(duplicateColumns).join(', ');                  
                      const fileName = file.name;
                      const subsheetNames = subsheetsWithDuplicates.join(', ');                     
                    //  const errorMsg = (`${fileName} has duplicate columns in the following  subsheet(s): ${subsheetNames}`);
                    //  const errorMsg = `${fileName} has duplicate columns (${duplicateColumnNames}) in the following subsheet(s): ${subsheetNames}`;
                     const errorMsg = `The ${fileName} contains multiple columns with the same name, please make the correction & upload the right file`

                      return { blob: csvFile, subsheetName: sheetName, error: errorMsg, subSheetsCount: workbook.SheetNames.length };

                     }else {
                        return { blob: csvFile, subsheetName: sheetName, error: null , subSheetsCount: workbook.SheetNames.length };
                    }
                });

                file_details.push({ file_name: file.name, file_size: file.size, category_id: category_id, sub_sheets: transformSheets(file?.name, workbook.SheetNames,workbook) });
                localStorage.setItem(localStorageKeys.file_details, JSON.stringify(file_details))
                resolve(convertedFiles);
            } catch (error) {
                reject(error);
            }
        };

        reader.readAsBinaryString(file);
    });
  };

  const changeFileExtension = (filename, newExtension) => {
    const lastDotIndex = filename.lastIndexOf('.');
    const newName = filename.substring(0, lastDotIndex) + newExtension;
    return newName;
  };

  const isAnyFieldEmpty = useMemo(() => {
    return dynamicFields?.filter(data => data.category == null || data.selectedFiles == "")?.length > 0
  }, [dynamicFields])


 return (
    <>
    <div className='flex w-full gap-3'>
      <div className='w-[85%]'>
        <div className='flex gap-2 w-full items-center'>
        <label className="w-[95%] relative flex items-center cursor-pointer border-2 border-greeny-blue focus:border-greeny-blue rounded-md overflow-hidden">
          <div className="px-2 xl:py-[0.4rem] xxlg:py-[0.32rem] py-[0.30rem]">
            <FileSelectIon bgColor={'#707070'} height={'18px'} width={'19px'} />
          </div>        
           <div className="px-2">
            <span className={`${fileName ? 'text-black' : 'text-[#BEBEBE]'} text-[13px]`}>{fileName ? fileName : 'Select file to upload'}</span>
          </div>
          <input
            type="file"
            onChange={handleFileInput}
            accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip"
            className="hidden"
          />
          <div className="px-2 ml-auto">
            <img src={threeDot} className='w-6' alt="Three Dots" />
          </div>
        </label> 
        {dynamicFields.length > 1 &&  
        <div className='w-[5%]'>
          <img src={CancelIcon} className='w-[22px] cursor-pointer' onClick={()=>{removeField(index);setDisplayFileName('')}}/>
        </div>
        }
        </div>

        <div className={`${error ? 'text-error-red':'text-[#000000]'} text-[12px] mt-3 w-[95%] text-wrap flex justify-end`}>
          {isMultiple && index === dynamicFields.length - 1 ? (error ? error :isFrom=='single' ? 'File must be in xls, xlsx, or csv format.':'File must be in zip, xls, xlsx, or csv format.') : null}
        </div>        

      </div>
      <div className='w-[10%]'>
        {isMultiple ?
          index === dynamicFields.length - 1 ?
            <Button bgColor='#00A7B5' borderType="" onClick={() => fileNameCheck()} label={loading ? 'Uploading...' : 'Upload'} color={'#ffffff'} size={'md'} disabled={isUploading || isAnyFieldEmpty || loading} />
            : null
          : 
          <Button bgColor='#00A7B5' borderType="" onClick={() => fileNameCheck()} label={loading ? 'Uploading...' : 'Upload'} color={'#ffffff'} size={'md'} disabled={isUploading || loading} />
        }
      </div>
    </div>
    </>
  );
};

export default UploadImageToS3WithReactS3;

