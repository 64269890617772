import React from 'react'

const DownloadIcon = ({ width, height, bgColor,className }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} viewBox="0 0 15.001 20">
            <g id="Group_171" data-name="Group 171" transform="translate(499.871 -211.314)">
                <path id="Path_96" data-name="Path 96" d="M-499.871,221.313q0-4.671,0-9.342c0-.544.111-.654.659-.654q4.9,0,9.805,0a.813.813,0,0,1,.626.256q1.821,1.836,3.657,3.656a.79.79,0,0,1,.253.6q-.005,7.451,0,14.9c0,.441-.143.581-.586.581h-13.83c-.441,0-.581-.14-.581-.586Q-499.871,226.021-499.871,221.313Zm14.147,9.157V216.324H-486q-1.659,0-3.318,0c-.411,0-.556-.15-.556-.562q0-1.671,0-3.342c0-.085-.008-.171-.012-.255h-9.127V230.47Z" fill={bgColor} />
                <path id="Path_97" data-name="Path 97" d="M-443.45,365.221h4.414c.431,0,.55.117.551.548q0,3.609,0,7.219c0,.429-.121.55-.553.551q-4.451,0-8.9,0c-.4,0-.526-.133-.526-.529q0-3.634,0-7.268c0-.4.128-.521.528-.521Zm-.016,2.479h4.132v-1.634h-4.132Zm0,.856v1.631h4.134v-1.631Zm4.132,4.136v-1.641h-4.13v1.641Zm-8.29-4.995h3.3v-1.629h-3.3Zm0,2.491h3.3V368.56h-3.3Zm3.307.866h-3.3v1.634h3.3Z" transform="translate(-48.897 -146.398)" fill={bgColor} />
            </g>
        </svg>


    )
}

export default DownloadIcon
