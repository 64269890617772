import { Divider } from '@mui/material';
import { Button, Checkbox, Input } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton';


const CheckboxGroup = Checkbox.Group;





function AsyncSelectPopup({ selected, onChange, getFilterOptions, reset }) {

    const [options, setOptions] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [internalSelected, setInternalSelected] = useState(selected)
    const [searchTerm, setSearchTerm] = useState(null)


    useEffect(() => {
        getFilterOptions().then((data) => {
          // Replace 'null' values with 'Blank'
          const updatedData = data.map(item => item == 'null' ? 'Blank' : item);
          // Ensure 'Blank' is at the 0th index
          if (!updatedData.includes('Blank')) {
            // updatedData.unshift('Blank');
          } else {
            const blankIndex = updatedData.indexOf('Blank');
            updatedData.splice(blankIndex, 1);
            updatedData.unshift('Blank');
          }
          setOptions(updatedData);
          setIsLoading(false);
        });
      }, []);

    const onApply = () => {
        onChange(internalSelected)
    }

    const filteredOptions = useMemo(() => {
        if (searchTerm) {
            return options.filter(option =>
                option.toLowerCase().includes(searchTerm?.toLowerCase())
            );
        }

        return options
    }, [options, searchTerm])

    const checkAll = filteredOptions.length === internalSelected.length;
    const indeterminate = internalSelected.length > 0 && internalSelected.length < filteredOptions.length;

    const onCheckAllChange = (e) => {
        setInternalSelected(e.target.checked ? filteredOptions : []);
    };


    return (
        <div>
            <Input allowClear onChange={(e) => setSearchTerm(e.target.value)} value={searchTerm} placeholder="Search..." className='mb-2 h-[1.5rem] 2xl:h-[2rem]' />
            <Divider />
            <div className='max-h-[8rem] 2xl:max-h-[10rem] py-2 overflow-y-auto min-w-fit'>
                {isLoading
                    ? <div className='flex flex-col space-y-2'>
                        <Skeleton height={20} width={'100%'} />
                        <Skeleton height={20} width={'100%'} />
                        <Skeleton height={20} width={'100%'} />
                    </div>
                    : <>
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                            <span className='text-[13px]' >(Select all)</span>
                        </Checkbox>
                        <CheckboxGroup rootClassName='flex flex-col analysisCheckGroup text-[13px]' options={filteredOptions} value={internalSelected} onChange={setInternalSelected} />
                    </>}
            </div>
            <Divider />
            <div className='flex items-center justify-between'>
                <Button onClick={reset} style={{ fontSize: "12px" }} className='mt-2' size='small'>Reset</Button>
                <Button onClick={onApply} style={{ backgroundColor: "#42b2ac", color: "white", fontSize: "12px" }} className='mt-2' size='small'>Apply</Button>
            </div>
        </div>
    )
}

export default AsyncSelectPopup