import React from 'react';
import OverflowTooltip from '../ToolTip/OverFlowToolTip';

const DynamicTable = ({ nodeValueSummary,index,fixedHeader }) => {
  return (
    <table className='w-full custom-data-table overflow-auto thin-scrollbar'>
      <thead className={`bg-[#2B144C] text-white text-[12px] ${fixedHeader ? 'sticky top-0 z-1':''}`}>
        {nodeValueSummary?.length > 0 &&
          nodeValueSummary[index]?.summary?.headers.map((header, index) => (
            <th key={index} className={`max-w-[6rem] py-2 ${index ==0 ? 'px-2' :''}`}>
              <OverflowTooltip text={header} />              
              </th>
          ))}
      </thead>
      <tbody className='bg-white custom-data-table text-[12px]'>
        {nodeValueSummary?.length > 0 &&
          nodeValueSummary[index]?.summary?.data.map((row, rowIndex) => (
            <tr key={rowIndex} className='border-b border-[#E7E6E6] hover:bg-[#E7E6E6]'>
              {Object.values(row).map((cell, cellIndex) => (
                <>
                <td key={cellIndex} className='py-2 px-2 max-w-[6rem]'><OverflowTooltip text={cell=== true ? "True" : cell === false ? "False" : cell } />              
                </td>
                </>
              ))}
            </tr>
          ))}
      </tbody>

    </table>
  );
};

export default DynamicTable;



