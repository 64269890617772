import React from 'react'

const DownArrow = ({color,height,width,className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 20.348 18" className={className}>
            <path id="Polygon_1" data-name="Polygon 1" d="M10.174,0,20.348,18H0Z" transform="translate(20.348 18) rotate(180)" fill={color} />
        </svg>
    )
}

export default DownArrow
