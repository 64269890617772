import React, { useState } from 'react'
import SlidingPane from "react-sliding-pane";
import { useLocation, useNavigate } from 'react-router-dom';
import LeftArrow from '../../Components/CommonIcons/LeftArrow';
import HamburgerMenu from '../../Components/CommonIcons/HamburgerMenu';
import Button from '../../Components/Button';
import EclHistory from '../../Components/wizardPage/EclHistory';
import ActivityHistoryList from '../../Components/wizardPage/ActivityLog';
import { useDispatch, useSelector } from 'react-redux';
import { openActivityLog, openActivityLogState, openModalBuilder, selectIsDisableAction, setShowMenu } from '../../Store/Workflow/WorkflowSlice';
import BreadCrumbArrow from '../../Images/Group 166.svg'

function WorkflowHeader({ goToPrevPage }) {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const isDisableAction = useSelector(selectIsDisableAction)
    const step = useSelector((state) => state?.Workflow?.currentStep)

    const {
        isModalBuilderOpen,
        showMenu,
        workflow_id,
        activityLogs,
        isActivityLogOpen
    } = useSelector(state => ({
        isModalBuilderOpen: state?.Workflow?.ui?.isModalBuilderOpen,
        showMenu: state?.Workflow?.ui?.showMenu,
        workflow_id:state?.Workflow?.currentWorkflowId,
        activityLogs : state?.Workflow?.activityLogs,
        isActivityLogOpen : state?.Workflow?.ui.isActivityLogOpen
    }))

    const onActionClick = (id, type) => {
        if (type === "modelBuilder") {
            dispatch(openModalBuilder())
        }
    }

    return (
        <div className='flex justify-between 2xl:py-3 py-1.5 bg-white' id="workflowHeader">
            <div className='flex gap-2 items-center'>
                <div className={`flex gap-2 items-center ${isDisableAction && step == 4 ?'pointer-events-none':'cursor-pointer'}`} onClick={() => goToPrevPage()} >
                    <LeftArrow bgColor={'#d0cece'} className={'xl:w-[25px] xl:h-[25px] w-[20px] h-[20px]'} />
                    <p className='text-gray-light xl:text-[15px] text-[13px]'>Back</p>
                </div>
                <div className='text-[13px] 2xl:text-[14px] flex gap-1 items-center text-dark-blue font-medium'>

         
                    {location?.state?.breadCrumbClientName}
                    <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 
                    {location?.state?.breadCrumbCountryName}
                    <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 
                    {location?.state?.breadCrumbLevelOne}
                    <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 
                    {location?.state?.breadCrumbLevelTwo}
                    <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 
                    {location?.state?.levelTwoCategory}
                    </div>
                {/* <p className='text-[12px] xl:text-[14px]'>{location?.state?.breadCrumbClientName + "-" + location?.state?.breadCrumbCountryName + "-" + location?.state?.breadCrumbLevelOne + "-" + location?.state?.breadCrumbLevelTwo + "-" + location?.state?.levelTwoCategory}

                </p> */}
            </div>
            <div className='flex items-center gap-4'>
                <HamburgerMenu color={'#707070'} className={'xl:w-[35px] xl:h-[35px] w-[30px] h-[30px]'} onClick={() => {dispatch(setShowMenu(!showMenu)) }} showMenu={showMenu} />
                <Button
                    bgColor='#5B9BD5'
                    size="xs"
                    paddingY={'xl:py-1.5 py-1 rounded-[5px]'}
                    label={'Activity log'}
                    color={'#FFFFFF'}
                    onClick={()=> dispatch(openActivityLog({workflow_id:workflow_id}))}
                    icon={'activeLog'} 
                    disabled={isDisableAction}
                    />
                <Button
                    bgColor='#47B4AE'
                    size="xs"
                    paddingY={'xl:py-1.5 py-1 rounded-[5px]'}
                    label={'Model builder'}
                    color={'#FFFFFF'}
                    onClick={() => onActionClick(location?.state?.clientId, 'modelBuilder')}
                    icon={'modelBuilder'}
                    disabled={isDisableAction} />
                <Button bgColor='#00B050'
                    size="xs"
                    paddingY={'xl:py-1.5 py-1 rounded-[5px]'}
                    label={'New'}
                    color={'#FFFFFF'}
                    onClick={() => onActionClick(location?.state?.clientId, 'new')}
                    icon={'new'}
                    disabled={location?.state?.newButtonDisable} />
            </div>
            {/* history sliding pane */}
            <SlidingPane
                className="custom-slide-pane-overflow"
                overlayClassName="custom-overlay-class z-[101]"
                isOpen={false}
                width="68%"
                hideHeader
                shouldCloseOnEsc={true}
                onRequestClose={() => { }}
            >
                <EclHistory title={'ECL History'}
                    onClose={() => { }}
                    headerBg={'bg-[#5B9BD5]'}
                    borderColor={'border-[#5B9BD5]'}
                    isOpen={false}
                />

            </SlidingPane>
            {/* activity log sliding pane */}
            <SlidingPane
                className="custom-slide-pane-overflow"
                overlayClassName="custom-overlay-class z-[101]"
                isOpen={isActivityLogOpen}
                width="35%"
                hideHeader
                shouldCloseOnEsc={true}
                onRequestClose={() => { }}
            >
                <ActivityHistoryList isOpen={false} onClose={() => {dispatch(openActivityLogState(false)) }} activityData={activityLogs} headerBg={'bg-[#42B2AC]'} borderColor={'border-[#42B2AC]'} title={'Activity logs'} />
            </SlidingPane>
        </div>
    )
}

export default WorkflowHeader