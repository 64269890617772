import React from 'react'
import { Tooltip } from 'antd';

const HamburgerMenu = ({ color, width, height,onClick , showMenu,className}) => {
    return (
        <Tooltip title={showMenu ? 'Collapse':'Expand'} placement='top' >
        <svg height={height} version="1.1" onClick={onClick} className={`${className}  cursor-pointer`} viewBox="0 0 48 48" width={width} xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
            <title />
            <desc />
            <g fill="none" fillRule="evenodd" id="Page-1" stroke="none" strokeWidth="1">
                <g id="Artboard-Copy" transform="translate(-287.000000, -249.000000)">
                    <path d="M297,260 C295.895,260 295,260.896 295,262.001 L295,264 C295,265.105 295.895,266.001 297,266.001 L325,266.001 C326.105,266.001 327,265.105 327,264 L327,262.001 C327,260.896 326.105,260 325,260 L297,260 Z M297,269 C295.895,269 295,269.895 295,271 L295,273 C295,274.106 295.895,275 297,275 L325,275 C326.105,275 327,274.106 327,273 L327,271 C327,269.895 326.105,269 325,269 L297,269 Z M297,277.999 C295.895,277.999 295,278.895 295,280 L295,281.999 C295,283.104 295.895,284 297,284 L325,284 C326.105,284 327,283.104 327,281.999 L327,280 C327,278.895 326.105,277.999 325,277.999 L297,277.999 Z" 
                    fill={showMenu ? color:'#1F4E79'} id="list2" />
                    <g id="slices" transform="translate(47.000000, 9.000000)" /></g>
            </g>
        </svg>
        </Tooltip>
    )
}

export default HamburgerMenu
