
import React from 'react';
import notesicon from '../../Images/notesicon.png';
import { NavLink ,useLocation, useNavigate } from 'react-router-dom';
import RouteSegments from '../../Routes/RouteSegments';

const  NoDataFound = ({msg,clientID,redirection}) => {
    const location = useLocation();
    const navigate = useNavigate();
   const redirectTo = (clientId)=>{
    if(clientID){
        navigate(RouteSegments.NEW_CLIENT,{ state: { isFrom: 'manageCountry',step:3 ,clientID:clientId,isNewClient:true,breadCrumbClientName:location?.state?.breadCrumbClientName} })   
    }else{
        navigate(RouteSegments.NEW_CLIENT)   
    }   
   }
    return (
        <div className='font-Rubik flex space-y-2 flex-col items-center justify-center h-96'>
            <div className=''>
                <img src={notesicon} alt="Notes Icon" className="w-[5rem] h-[6rem]" />
            </div>

            <p className='text-center text-error-red text-[14px]'>No results found</p>
            {redirection ?
            <p className='text-[14px] text-center text-gray-600'>
                Try a different search or <span className="text-[#00B050] text-[14px] cursor-pointer" onClick={()=>redirectTo(clientID)}>click here</span> to add a {msg ?? 'new client'}
            </p>
            :<></>}
        </div>
    );
};
export default  NoDataFound;

