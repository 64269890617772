import React from 'react'
import { localStorageKeys } from '../../Utils/Constants';

const SearchInput = ({searchTerm,setSearchTerm,size,icon,placeholder,forLevel,name,maxLength}) => {
    const handleChange = (e)=>{
        localStorage.removeItem(localStorageKeys.currentClientsCount)
        localStorage.removeItem(localStorageKeys.clientSearchTerm);
        localStorage.removeItem(localStorageKeys.hasMoreClients);
        setSearchTerm(e.target.value);
    }
    return (
        <div className={`relative ${size == 'sm' ? 'w-[7rem]': 'w-[13.6rem]'}`}>
            <div className={`absolute inset-y-0 pb-1 flex items-center pointer-events-none ${icon ? 'pl-[0.5rem]':''}`}>
                {icon &&
                <svg className="xl:w-[15px] xl:h-[15px] w-[12px] h-[12px] text-[#CAD3D0] " aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                </svg>
                }
            </div>
            <input type="search" id="default-search" name={name} maxLength={maxLength}  onChange={(e)=>{handleChange(e)}} value={searchTerm} className={`block focus:outline-none w-full ${icon ? 'xl:pl-[1.8rem] pl-[1.5rem]  ':''} xl:text-[12px] text-[11px] ${forLevel ? 'text-black p-[0.1rem] pl-2 rounded-[3px]':'text-gray-900 xl:p-[0.4rem] p-[0.3rem] rounded-[5px]'} border border-[#CAD3D0] focus:ring-[#CAD3D0] focus:border-[#CAD3D0]" `} placeholder={placeholder} />
        </div>
    )
}

export default SearchInput
