import React from 'react'
import DataTableSkeleton from './DataTableSkeleton'
import Skeleton from 'react-loading-skeleton';

const FileUploadListSkeleton = ({initial}) => {
  return (
    <div className='w-full flex'>
      <div className='w-[5%] pt-5 pl-2'>
        <Skeleton height={25} width={25} circle/>
      </div>
      <div className='w-[95%]'>
        <Skeleton height={70}/>
        <div className='pl-3'>
          <Skeleton height={10} width={100}/>
        </div>
        <DataTableSkeleton showHeader={true} initial={initial}  columnWidths={''} rowCount={10} columnCount={7} showPagination={false} showActionableButtons={true}/>             
      </div>  
    </div>
  )
}

export default FileUploadListSkeleton
