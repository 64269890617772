//Dependencies
import React from 'react'
//Images
import logo from '../../Images/gtbharat-logo.png'
import { NavLink } from 'react-router-dom'
import RouteSegments from '../../Routes/RouteSegments'
import { useDispatch } from 'react-redux'
import { registerResponseDataClear } from '../../Store/action'

const Logo = () => {
  const dispatch = useDispatch()
  return (
  <NavLink to={RouteSegments.HOME} onClick={()=>dispatch(registerResponseDataClear())} className="brightness-0 invert-[1] xl:w-[12rem] w-[10rem] cursor-pointer absolute top-[8.5%] left-[3%] z-10">
      <img src={logo} alt="Logo" />
    </NavLink>  )
}

export default Logo
