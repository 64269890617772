import React, { useEffect, useMemo, useState } from 'react'
import Button from '../Button'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import DropDown from '../DropDown/DropDown'
import { downloadExport, exceptionAnalysisProceed, getCalculationFormData, getDataImputationList, getMergeSummaryResult, getNodeWiseCategoryList, mismatchNodesave, openModalBuilder, saveMismatchCategory, selectCurrentNodeData, selectCurrentNodeIds, toggleEditMode } from '../../Store/Workflow/WorkflowSlice'
import { useFormik } from 'formik'
import DataTableSkeleton from '../Skeleton/DataTableSkeleton'
import { parseCategotyValueLabel } from '../../Helpers/utils'
import SummaryTable from '../Table/SummaryTable'
import { createColumnHelper } from '@tanstack/react-table'
import XlsIcon from '../../Images/excel.svg'
import EditIcon from '../../Images/Group 809.svg'
import useDataTable from '../../hooks/useDataTable'
import DataTable from '../Table/DataTable'
import { CircularProgress } from '@mui/material'
import { DOWNLOAD_MERGE_SUMMARY_API } from '../../Helpers/EndPoints'
import ProceedButton from '../CommonComponents/ProceedButton'
import downloadingIcon from '../../Images/gif/downloading.gif'


const columnHelper = createColumnHelper()


const ColumnDownload = ({ workflow_id, ecl_node_id, category_id, column_name }) => {

  const [isDownloading, setisDownloading] = useState(false)

  const onMismatchColumnDownloadClick = async () => {
    setisDownloading(true)
    const request = {
      workflow_id,
      ecl_node_id,
      category_id,
      column_name,
      fileName: column_name,
      is_export: true
    }
    await downloadExport(request, DOWNLOAD_MERGE_SUMMARY_API)
    setisDownloading(false)
  }
  return isDownloading
  ? <img src={downloadingIcon} className='w-6' />
  : <img alt='' onClick={onMismatchColumnDownloadClick} src={XlsIcon} className='cursor-pointer w-4 h-4 xl:w-5 xl:h-5' />
}


function MismatchedDataType() {

  const location = useLocation()
  const dispatch = useDispatch()
  const nodeWiseCategory = useSelector(state => state?.Workflow?.nodeWiseCategory)
  const currentNodeData = useSelector(selectCurrentNodeData)
  const isDataLoading = useSelector(state => state?.Workflow?.ui?.isDataLoading)
  const isCalculating = useSelector(state => state?.Workflow?.ui?.isCalculating)
  const filesMerging = useSelector(state => state?.Workflow?.ui?.filesMerging)
  const reduxState = useSelector(state => state)
  const { currentEclNodeId, currentWorkflowId, currentWorkflowNodeId } = useSelector(selectCurrentNodeIds)
  const [isEditMode, setIsEditMode] = useState(currentWorkflowNodeId != null)
  const isProceedLoading = useSelector(state => state?.Workflow?.ui?.isProceedLoading)

  const dataImputationdList = useSelector(state => {
    return state?.Workflow?.dataImputationdList?.map(data => ({
      label: data?.name,
      value: data?.id,
      data,
      // disabled: data?.id != 4 && data?.id != 5
    }))
  })

  const formik = useFormik({
    initialValues: {
      category: currentNodeData?.nodeCategoryId,
      dataInputationList: currentNodeData?.mismatchTableData?.mismatchedDatas?.map(data => ({ type: data?.data_imputation_method }))
    },
    enableReinitialize: true,
  })

  const { dataTableColumns,
    getFilterOptions,
    summaryData,
    tableState,
    refreshDataTable,
    onTableStateChange,
    isDataLoading: isMergeSummaryLoading,
    isExportLoading,
    donwloadXl,
  } = useDataTable({ category_id: formik.values.category, ecl_node_id: currentEclNodeId, workflow_id: currentWorkflowId, from: "mismatchedDataTypes" })


  const onCategoryChange = (value) => {
    formik.setFieldValue("category", value)
  }

  const onCategorySave = () => {
    dispatch(saveMismatchCategory({
      "workflow_id": currentWorkflowId,
      "ecl_node_id": currentEclNodeId,
      "category_id": formik.values.category
    }
    ))
    dispatch(getDataImputationList({
      "workflow_id": currentWorkflowId,
      "ecl_node_id": currentEclNodeId,
      "category_id": formik.values.category
    }
    ))
    refreshDataTable()    
  }


  const onSave = (isSave) => {

    const requestFormData = getCalculationFormData({
      client_branch_level_id: location?.state?.levelTwoCardId,
      isFrom: "mismatch",
      category_id: formik?.values?.category,
      node_id: currentEclNodeId,
      state: reduxState,
      nodeStatus:isSave ? "Pending" : "Completed",
    })

    dispatch(mismatchNodesave({
      requestFormData,
      isSave: isSave,
      mismatchRequest: {
        "ecl_workflow_id": currentWorkflowId,
        "ecl_node_id": currentEclNodeId,
        "ecl_category_id": formik?.values?.category,
        "mismatched_data_type_datas": currentNodeData?.mismatchTableData?.mismatchedDatas?.map((data, index) => ({
          ...data,
          data_imputation_method: formik.values.dataInputationList[index].type
        }))
      }
    }))
    .then((response) => {
      if (response.type === `${mismatchNodesave.fulfilled}`) {
        setIsEditMode(true)
        dispatch(saveMismatchCategory({
          "workflow_id": currentWorkflowId,
          "ecl_node_id": currentEclNodeId,
          "category_id": formik.values.category
        }
        ))
        refreshDataTable()
      }
    })

  }

  useEffect(() => {
    dispatch(getNodeWiseCategoryList({
      workflow_id: currentWorkflowId,
      ecl_node_id: currentEclNodeId,
    }))
  }, [currentEclNodeId, currentWorkflowId, dispatch])


  useEffect(() => {
    if (currentNodeData?.mismatchTableData?.mismatchedDatas || currentNodeData?.id) {
      refreshDataTable()
    }
    if (currentNodeData?.id) {
      dispatch(getDataImputationList({
        "workflow_id": currentWorkflowId,
        "ecl_node_id": currentEclNodeId,
        "category_id": formik.values.category
      }
      ))
      dispatch(saveMismatchCategory({
        "workflow_id": currentWorkflowId,
        "ecl_node_id": currentEclNodeId,
        "category_id": formik.values.category
      }
      ))
    }
  }, [currentNodeData?.id])

  const onDataInputationChange = (index, value) => {
    formik.setFieldValue(`dataInputationList.${index}.type`, value)
  }



  const summaryColumns = useMemo(() => {
    return [
      columnHelper.accessor("column_name", {
        header: "Column name",

      }),
      columnHelper.accessor("system_detected_data_type", {
        header: "System detected data type",
        thClassName: "w-[150px]",
      }),
      columnHelper.accessor("assigned_data_type", {
        header: "Assigned data type",
        thClassName: "",
      }),
      columnHelper.accessor("no_of_mismatched_values", {
        header: "No. of mismatched values",
        thClassName: "w-[150px]",
      }),
      columnHelper.accessor("percentage_of_mismatched_values", {
        header: "Percentage of mismatched values",
        thClassName: "w-[190px]",
        cell: ({ row }) => (<span>{row.original?.percentage_of_mismatched_values ? row.original?.percentage_of_mismatched_values + " %"  : '-'}</span>)

      }),
      columnHelper.display({
        header: "Actions",
        thClassName: "w-[100px]",
        cell: ({ row }) => {
          return <ColumnDownload
            category_id={formik.values.category}
            column_name={row.original.column_name}
            ecl_node_id={currentEclNodeId}
            workflow_id={currentWorkflowId}
          />
        }
      }),
      columnHelper.display({
        header: "Data imputation method",
        thClassName: "w-[25%]",
        cell: ({ row }) => {
          const value = formik.values.dataInputationList?.at(row.index)?.type
          return isEditMode
            ? dataImputationdList?.find(data => data?.value == value)?.label
            
            : <DropDown
              dropDownOptions={dataImputationdList?.map(data => {
              const dataTypes = data?.data?.data_type.split(',');
              return {
                label: data?.label,
                value: data?.value,
                disabled: data?.value == 1 
                  ? !dataTypes.includes(row.original.assigned_data_type) 
                  : (data?.value !== 4 && data?.value !== 5 && data?.value !== 7)
              };
            })}
              placeholder={'Select any operation'}
              name={'usedCategory'}
              ordinary={true}
              disabled={false}
              customTags={false}
              value={value || undefined}
              onOptionChange={(value) => onDataInputationChange(row.index, value)}
            />
      }
      }),
    ]
  }, [dataImputationdList, formik.values.dataInputationList, isEditMode])


  const summaryTableData = useMemo(() => {
    return currentNodeData?.mismatchTableData?.mismatchedDatas ?? []
  }, [currentNodeData?.mismatchTableData])


  const onEditClick = () => {
    setIsEditMode(prev => !prev)
  }


  const renderMistmatchTable = () => {
    if (isDataLoading) {
      return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
    }

    const disableSave = formik?.values?.dataInputationList?.filter(data => !data?.type)?.length > 0 || isEditMode
    if (currentNodeData?.nodeCategoryId) {
      return <>
        {isEditMode && <>
          
          <div className=' bg-[#2B144C] px-2 grid grid-cols-3 items-center'>
          <div className='flex gap-3 items-center py-2 col-span-1'>
            <h3 className='text-[#FFFFFF] text-[12px] whitespace-nowrap bg-[#F7BE7E] px-2 py-1 rounded-[4px] '>Data category</h3>
            <p className='font-semibold text-[#FFFFFF] text-[10px] whitespace-nowrap'>{currentNodeData?.mismatchTableData?.categoryName}</p>
          </div>
            <h2 className='text-white text-[12px] font-semibold text-center w-full col-span-1'>Calculated output</h2>
            {currentNodeData?.node_status != 'Completed' &&
            <div className={`col-span-1 justify-end flex gap-1 items-center mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={onEditClick}>
              <img alt='' src={EditIcon} className='w-4 h-4' />
              <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
            </div>
            }
          </div>
        </>
        }
        <SummaryTable
          columns={summaryColumns}
          data={summaryTableData}
          thClassName='!text-[10px] 2xl:text-[14px]'
          tdClassName='!text-[10px] 2xl:text-[14px]'
          className={isEditMode ? "" : "mt-4"}
        />
        {currentNodeData?.mismatchTableData?.isEdit == true && disableSave || summaryTableData?.length <= 0
          ? 
          
          ""
          : <div className='flex justify-center my-4'>
          <Button
            bgColor='#42B2AC'
            borderType=""
            disabled={disableSave}
            label={'Save'}
            color={'#ffffff'}
            onClick={()=>onSave(true)}
            size={'md'} />
          </div>}
      </>
    }

  }


  const onProceedClick = () => {
    const isProceeded = summaryTableData?.length <= 0
    
    const requestFormData = getCalculationFormData({
      client_branch_level_id: location?.state?.levelTwoCardId,
      isFrom: "mismatch",
      category_id: formik?.values?.category,
      node_id: currentEclNodeId,
      state: reduxState,
      nodeStatus:"Completed",
      data_imputation_ids: [...new Set(formik.values.dataInputationList?.map(list =>
        dataImputationdList?.find(imputationData => imputationData.value == list?.type)?.data?.ecl_node_id))].filter(id => id !== null)
    })
 
    dispatch(exceptionAnalysisProceed({
      requestFormData,
      workflow_id: currentWorkflowId,
      notOpenBuilder:!isProceeded,
      isProceeded: isProceeded
    }))    
  }
  

  const renderResultTable = () => {

    if (!currentNodeData?.nodeCategoryId) return

    if (isDataLoading || isMergeSummaryLoading) {
      return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
    }

    const mergeSummaryTableData = summaryData
   

    return <div className='mt-5'>

      <div className='flex justify-end items-center'>
        {isExportLoading
          ? <img src={downloadingIcon} className='w-6 mr-2' />
          : <img alt='' src={XlsIcon} className=' mb-2 mr-2 object-cover cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
      </div>

      <DataTable
        columns={dataTableColumns}
        data={mergeSummaryTableData?.columnDatas ?? []}
        onTableStateChange={onTableStateChange}
        totalCount={mergeSummaryTableData?.totalRecords}
        isOptionsDisabled={location?.state?.isfromHistory}
        getFilterOptions={getFilterOptions}
        tableState={{
          pagination: {
            pageIndex: tableState.pageIndex,
            pageSize: tableState.pageSize,
          },
          sorting: tableState?.sortBy ? [
            {
              id: tableState?.sortBy,
              desc: tableState?.sortOrder === "desc"
            }
          ] : [],
          columnFilters: tableState.columnFilters
            ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
            : [],
          columnPinning: tableState.columnPinning,
          columnVisibility: tableState?.columnVisibility,
        }}
      />
      {currentNodeData?.mismatchTableData?.isEdit == false && summaryTableData?.length > 0 || currentNodeData?.node_status == 'Completed'
        ? null
        :
         <div className={`float-right 2xl:my-6 my-3 ${location?.state?.isfromHistory ? 'hidden' : 'block'}`}>
          <ProceedButton
            isLoading={isProceedLoading}
            onClick={onProceedClick}
          />          
        </div>}
    </div>
  }



  const disableCategory = location?.state?.isfromHistory || isDataLoading || currentNodeData?.nodeCategoryId
  const disableSave = formik?.values?.dataInputationList?.filter(data => !data?.type)?.length > 0 || isEditMode

  return (
    filesMerging || isCalculating
      ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
      : <div className='my-2'>
        {isEditMode === true?"":
          <div className='flex gap-5'>
            <DropDown
              dropDownOptions={parseCategotyValueLabel(nodeWiseCategory)}
              placeholder={'Select data category'}
              name={'usedCategory'}
              ordinary={true}
              disabled={disableCategory}
              customTags={false}
              value={formik.values.category}
              onOptionChange={onCategoryChange}
            />
            <Button
              bgColor='#42B2AC'
              borderType=""
              disabled={disableCategory || !formik.values.category}
              label={'Save'}
              color={'#ffffff'}
              onClick={onCategorySave}
              size={'md'} />
          </div>
          }
        {renderMistmatchTable()}
        {renderResultTable()}
      </div>
  )
}

export default MismatchedDataType