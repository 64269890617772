
export const getCurrentDateTime = () => {
    const currentDate = new Date();
    const isoString = currentDate.toISOString();
    return isoString;
  };

 export const convertDateFormat = (originalDate) => {
    console.log(originalDate, 'originalDate');
  
    // Regular expression to identify date formats
    const dateFormatRegex = /(\d{1,4})[-/](\d{1,2})[-/](\d{2,4})/;
  
    // Extract date components using regular expression
    const match = originalDate.match(dateFormatRegex);
  
    if (!match) {
      console.error('Invalid date format:', originalDate);
      return originalDate;
    }
  
    // Extract day, month, and year components
    const day = match[1].padStart(2, '0');
    const monthNumber = match[2].padStart(2, '0');
    const year = match[3].length === 2 ? `20${match[3]}` : match[3];
  
    // Month names in abbreviated form
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[parseInt(monthNumber) - 1];
  
    // Combine components into the desired format
    const formattedDate = `${day}-${month}-${year}`;
    console.log(formattedDate, 'new formattedDate');
  
    return formattedDate;
  };
  
  export const getFileSize = (selectedFile) => {
    if (selectedFile) {
      const fileSizeInMB = selectedFile / 1024 / 1024; // Convert to MB
      if (fileSizeInMB >= 1) {
        return `${fileSizeInMB.toFixed(2)} MB`;
      } else {
        // Show original file size in KB without rounding
        return `${(selectedFile / 1024).toFixed(2)} KB`;
      }
    }
    return '';
  };
  


export const getWordCloudOptions = (totalTextCount) => {

  let fontSizeMapper;

  if (totalTextCount < 10) {
    fontSizeMapper = (word) => Math.max(15, Math.sqrt(word.value));
  } else {
    fontSizeMapper = (word) => Math.sqrt(word.value);
  }

  return {
    fontSizes: [14, 30],
    fontSizeMapper: fontSizeMapper,
    rotations: 2,
    rotationAngles: [-90, 0],
  }
}

export const capitalizeFirstLetter =(str) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
}