import React,{useState} from 'react'
import { Outlet} from 'react-router-dom';
//Components
import Header from '../../Components/LayOuts/Header'
import Footer from '../../Components/LayOuts/Footer'
//Libraries
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";



const AppLayout = () => {
    const getView = () => {
        return <Outlet />;
    }
const [notificationOpen,setNotificationOpen] = useState(false)
    return (
        <div className='h-screen flex flex-col overflow-hidden'>
            <div className='sticky top-0 z-[1]' id="header">
                <Header setNotificationOpen={setNotificationOpen} notificationOpen={notificationOpen}/>
            </div>
            <main className='h-full overflow-hidden'>
            <SlidingPane       
                className="custom-slide-pane-overflow"
                overlayClassName="custom-overlay-class z-[101]"
                isOpen={notificationOpen}
                width="40%"
                hideHeader
                shouldCloseOnEsc={true}
                onRequestClose={() => {
                    setNotificationOpen(false)
                }}
                >
                    <p className='text-[15px] font-semibold h-full flex items-center justify-center'>Notifications comming soon...</p>
                </SlidingPane>
                {getView()}                
            </main>
            <div className='fixed inset-x-0 bottom-0 z-10  w-full h-[5%]' id="footer">
                <Footer />
            </div> 
            <ToastContainer
            position={"top-center"}
            autoClose={3000}
            toastClassName="bg-white shadow-lg rounded-lg flex items-center py-1 h-[2rem]"
            bodyClassName="text-[11px] 2xl:text-[13px] font-medium text-gray-900"
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            // className='bg-success text-white'
            />           
        </div>
    )
}

export default AppLayout
