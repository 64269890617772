import React from 'react'
import { useSelector } from 'react-redux'
import { modelBuilderSections } from '../../Utils/Constants'
import { selectCurrentNodeData } from '../../Store/Workflow/WorkflowSlice'

function WorkflowMainHeader() {
    const {
        calculationDate,
        currentTemplateName
    } = useSelector(state => ({
        calculationDate: state?.Workflow?.calculationDate,
        currentTemplateName:state?.Workflow?.currentTemplateName
    }))


    const currentNodeData = useSelector(selectCurrentNodeData)
    const currentTileId = currentNodeData?.ecl_node_id

    return (
        <div className='bg-[#4F2D7F] text-white text-[11px] 2xl:text-[14px] flex justify-between 2xl:p-2.5 xl:p-2 p-[0.5rem] xlg:mt-2 mt-1 whitespace-nowrap'>
            <span>
                Step 4 – ECL Calculation
            </span>
            {currentTileId &&
                <span className='text-[#FFC048] text-[11px] 2xl:text-[14px]'>{modelBuilderSections?.find(data => data?.id == currentTileId)?.name || ""}</span>
            }
            <span className='2xl:text-[12px] text-[11px] font-normal'>
                {calculationDate}&nbsp;/&nbsp;{currentTemplateName}
            </span>
        </div>
    )
}

export default WorkflowMainHeader