import { ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Pagination, Popover as MatPopover, Divider, Popper, ClickAwayListener } from '@mui/material'
import { flexRender, getCoreRowModel, getFacetedMinMaxValues, getFacetedRowModel, getFacetedUniqueValues, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import React, { useEffect, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';

import SwapIcon from '@mui/icons-material/ImportExport';
import UpArrowIcon from '@mui/icons-material/North';
import DownArrowIcon from '@mui/icons-material/South';

import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Popover } from 'antd';
import AsyncSelectPopup from './AsyncSelectPopup';
import OverflowTooltip from '../ToolTip/OverFlowToolTip';
import RangeFilterPopup from './RangeFilterPopup';
import TableMoreVert from "../../Images/TableMoreVert.svg"
import PushPinIcon from '@mui/icons-material/PushPin';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const getHeadingStyles = (column) => {
    const isPinned = column.getIsPinned()
    const isLastLeftPinnedColumn =
        isPinned === 'left' && column.getIsLastColumn('left')
    const isFirstRightPinnedColumn =
        isPinned === 'right' && column.getIsFirstColumn('right')

    // console.log("getHeadingStyles", column);

    return {
        boxShadow: isLastLeftPinnedColumn
            ? '-4px 0 4px -4px gray inset'
            : isFirstRightPinnedColumn
                ? '4px 0 4px -4px gray inset'
                : undefined,
        left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
        right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
        opacity: isPinned ? 0.95 : 1,
        position: isPinned ? 'sticky' : 'relative',
        // width: column?.getSize(),
        // minWidth: column?.columnDef?.minSize,
        // maxWidth: column?.columnDef?.maxSize,
        zIndex: isPinned ? 1 : 0,
    }
}




const ColumnMenuOptions = ({ showhideEl, onClose, onColumnActionClick, column }) => {
    // console.log("ColumnMenuOptions", column);
    const pinDir = column?.getIsPinned()

    const isColumnSorted = column?.getIsSorted()
    const canColumnSorted = column?.getCanSort()

    const columnId = column?.id; // Get the ID of the clicked column
    const sortOrder = column?.getNextSortingOrder(); // Get the sort order

    const canFilter = column?.getCanFilter()

    const filterType = column?.columnDef?.meta?.filterType


    return <MatPopover
        anchorEl={showhideEl}
        disableScrollLock
        onClose={onClose}
        open={showhideEl != null}
    >
         <MenuList dense>
            {canColumnSorted &&
                <>
                <MenuItem disabled={isColumnSorted == false} onClick={() => onColumnActionClick("sorting", { sortBy: columnId, sortOrder: false })}>
                        <ListItemIcon>
                            <ClearAllIcon className='mr-2' style={{ color: '#999' , width: "16px"}} />
                        </ListItemIcon>
                        <ListItemText ><span className='text-[13px]'>Clear sort</span></ListItemText>
                    </MenuItem>

                <MenuItem disabled={isColumnSorted == "asc"} onClick={() => onColumnActionClick("sorting", { sortBy: columnId, sortOrder: "asc" })}>
                        <ListItemIcon>
                            <ArrowDownwardIcon className='mr-2' style={{ color: '#999',width: "16px", transform: "rotate(180deg)" }} />
                        </ListItemIcon>
                        <ListItemText><span className='text-[13px]'>Sort ascending</span></ListItemText>
                    </MenuItem>

                <MenuItem disabled={isColumnSorted == "desc"} onClick={() => onColumnActionClick("sorting", { sortBy: columnId, sortOrder: "desc" })}>
                        <ListItemIcon>
                            <ArrowDownwardIcon className='mr-2' style={{ color: '#999' , width: "16px"}} />
                        </ListItemIcon>
                        <ListItemText><span className='text-[13px]'>Sort descending</span> </ListItemText>
                    </MenuItem>

                    <Divider />
                </>}

            <MenuItem disabled={pinDir === "left"} onClick={() => onColumnActionClick("pinLeft", column)}>
                <ListItemIcon>
                    <PushPinIcon className='mr-2' style={{ color: '#999', width: "16px",transform: "rotate(90deg)" }} />
                </ListItemIcon>
                <ListItemText><span className='text-[13px]'>Pin to left</span> </ListItemText>

            </MenuItem>

            <MenuItem disabled={pinDir === "right"} onClick={() => onColumnActionClick("pinRight", column)}>
                <ListItemIcon>
                    <PushPinIcon className='mr-2' style={{ color: '#999',width: "16px", transform: "rotate(-90deg)" }} />
                </ListItemIcon>
                <ListItemText><span className='text-[13px]'>Pin to right</span> </ListItemText>

            </MenuItem>

            <MenuItem disabled={pinDir === false} onClick={() => onColumnActionClick("pinClear", column)}>
                <ListItemIcon>
                    <PushPinIcon className='mr-2' style={{ color: '#999' , width: "16px"}} />
                </ListItemIcon>
                <ListItemText>Clear Pin</ListItemText>
            </MenuItem>

            {/* {canFilter && <>
                <Divider />
                <MenuItem onClick={() => onColumnActionClick("filterOpen", column)}>
                    <ListItemIcon>
                        <FilterAltIcon className='mr-2' style={{ color: '#999' , width: "16px"}} />
                    </ListItemIcon>
                    <ListItemText><span className='text-[13px]'>{filterType === "select" ? "Filter by values" : "Filter by condition"}</span></ListItemText>
                </MenuItem>
            </>} */}
        </MenuList>        
    </MatPopover>
}


const ColumnFilter = ({ column, getFilterOptions, onFilterApply, filterState, onReset, onClose, showhideEl }) => {
    const columnMeta = column?.columnDef?.meta;
    const filterIndex = column?.getFilterIndex()
    const filterValues = filterState?.at(filterIndex) ? filterState?.at(filterIndex) : null

    const [selected, setSelected] = useState(() => {
        const uniqueValues = Array.from(column.getFacetedUniqueValues().keys());
        return uniqueValues.map(value => value === null ? 'Blank' : value);
      });
    const [rangeValues, setRangeValues] = useState({ min: "", max: "", operation: "equals" })




    useEffect(() => {
        if (filterValues) {
            if (columnMeta?.filterType === "range") {
                setRangeValues(filterValues.filterData)
            } else {
                setSelected(filterValues.value)
            }
        }
    }, [columnMeta?.filterType, filterValues])



    const onChange = (list) => {        
        setSelected(list)
        onFilterApply(list)    
    }

    const onRangeApply = (value) => {
        onFilterApply(value)
        setRangeValues(value)
    }

    return <ClickAwayListener onClickAway={onClose}>
        <Popper
            className='z-[100]'
            anchorEl={showhideEl}
            open={showhideEl != null}>
            <div className='p-4 bg-white rounded-md shadow-lg min-w-fit max-w-[15rem]'>
                {columnMeta?.filterType === "range"
            ? <RangeFilterPopup
                defaultValues={rangeValues}
                onApply={onRangeApply}
                onReset={onReset}
            />
            : <AsyncSelectPopup
                getFilterOptions={getFilterOptions}
                onChange={onChange}
                selected={selected}
                reset={onReset} 
            />
                }
        </div>
        </Popper>
    </ClickAwayListener>
}


function DataTable({ columns, data, totalCount, onTableStateChange, tableState, getFilterOptions,isOptionsDisabled, thClassName, tdClassName ,isFrom}) {

    const [showhideEl, setShowhideEl] = useState(null);
    const [selectedColumnName, setSelectedColumnName] = useState(null)
    const [filterEl, setFilterEl] = useState(null)

    const windowHeight = window.innerHeight;

    const headerHeight = document.getElementById('header')?.clientHeight
    const pipeLineHeight = document.getElementById('pipeline')?.clientHeight
    console.log(headerHeight,pipeLineHeight,"pipeLineHeight");
    const calculatedHeight = (headerHeight,pipeLineHeight);

    const table = useReactTable({
        columns,
        data,
        enableSortingRemoval: true,
        sortDescFirst: false,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(), //client-side sorting
        getPaginationRowModel: getPaginationRowModel(),
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        getFacetedMinMaxValues: getFacetedMinMaxValues(),
        manualPagination: true,
        manualSorting: true,
        manualFiltering: true,
        state: { ...tableState },
    })

    
    const handleColumnMenuClose = () => {
        setShowhideEl(null)
        setSelectedColumnName(null)
    }

    const handleFilterClose = () => {
        setFilterEl(null)
        setSelectedColumnName(null)
    }

    const handleColumnMenuOpen = (e, name) => {
        setShowhideEl(e)
        setSelectedColumnName(name)
    }

    const onColumnActionClick = (actionType, column,e) => {
        if (actionType === "filterOpen") {
            setFilterEl(e)
            setShowhideEl(showhideEl)
            setSelectedColumnName(column)
        } else {
            handleColumnMenuClose()
        }

        if (onTableStateChange && actionType != "filterOpen") {
            onTableStateChange(actionType, column)
        }

    }

    return (
        <>
            <ColumnMenuOptions
                column={selectedColumnName}
                onClose={handleColumnMenuClose}
                onColumnActionClick={onColumnActionClick}
                showhideEl={showhideEl}
            />
            {(selectedColumnName && filterEl) && <ColumnFilter
                filterState={table.getState().columnFilters}
                showhideEl={filterEl}
                onClose={handleFilterClose}
                onFilterApply={(selected) => onTableStateChange("filter", selectedColumnName, selected)}
                onReset={() => onTableStateChange("filterReset", selectedColumnName)}
                column={selectedColumnName}
                getFilterOptions={() => getFilterOptions(selectedColumnName?.id)} />}

            <div className='overflow-x-auto overflow-y-auto relative z-0 max-h-[220px] 2xl:max-h-[400px] w-full'>
                <table className=' '>
                <thead className=' ' 
               >
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr className='' key={headerGroup.id}>
                            {headerGroup.headers.map(header => {
                                    const canFilter = header.column?.getCanFilter()
                                    const isActionColumn =  header.column.columnDef.id == 'action'
                                    console.log(header.column.columnDef.header,'isActionColumn');
                                return (
                                    <th style={{ ...getHeadingStyles(header.column) }} key={header.id} colSpan={header.colSpan} 
                                    className={`bg-[#2B144C] text-white py-1 pl-2 pr-4
                                     whitespace-nowrap ${isActionColumn ? '2xl:w-[8rem] w-[6rem]' :thClassName ? thClassName : "2xl:w-[14rem] w-[13rem]"} 
                                      ${isOptionsDisabled ? 'pointer-events-none' : ''}`}>
                                        {header.isPlaceholder ? null : (
                                            <div className='flex items-center'>
                                                <div
                                                    className={
                                                        header.column.getCanSort()
                                                            ? 'cursor-pointer select-none flex items-center whitespace-nowrap w-fit'
                                                            : 'flex gap-2'
                                                    }
                                                    onClick={() => {
                                                        if (header.column.getCanSort()) {
                                                            const columnId = header.column.id; // Get the ID of the clicked column
                                                            const sortOrder = header.column.getNextSortingOrder(); // Get the sort order
                                                            onTableStateChange("sorting", { sortBy: columnId, sortOrder: sortOrder })
                                                        }
                                                    }}>

                                                    <p className='2xl:max-w-[11rem] max-w-[10rem]'>
                                                        <OverflowTooltip
                                                            className="max-w-[3rem] text-[12px] md:text-[10px] xl:text-[12px] py-0.5 text-white"
                                                            text={flexRender(
                                                                header.column.columnDef.header,
                                                                header.getContext()
                                                            )}
                                                        />
                                                    </p>

                                                    <div className='flex gap-1 items-center'>

                                                    {

                                                        header.column.getCanSort()
                                                            ? header.column.getIsSorted() === 'asc'
                                                                ? <UpArrowIcon className='!text-[12px] xl:!text-[14px] ml-1' style={{ color: "#c0d4ac" }} />
                                                                : header.column.getIsSorted() === 'desc'
                                                                    ? <DownArrowIcon className='!text-[12px] xl:!text-[14px] ml-1' style={{ color: "#c0d4ac" }} />
                                                                    : <SwapIcon className='!text-[12px] xl:!text-[14px] ml-1' style={{ color: "#c0d4ac" }} />
                                                            : null
                                                    }                                                     
                                                    </div>
                                                </div>    
                                                <div className='cursor-pointer shrink-0 ' onClick={(e) => handleColumnMenuOpen(e.currentTarget, header.column)}>
                                                        <img src={TableMoreVert} className='w-2 h-2 xl:w-2.5 xl:h-2.5' alt='' />
                                                </div> 
                                                {canFilter && (
                                                    (isFrom == 'positionAnalysis' ? 
                                                    <>
                                                    {(header.column.columnDef.id == 'column_name' || header.column.columnDef.id == 'data_type') && (
                                                        <div className='cursor-pointer shrink-0' onClick={(e) => onColumnActionClick("filterOpen", header.column, e.currentTarget)}>
                                                            <FilterAltIcon className='mr-2' style={{ width: "16px", height: "12px" }} />
                                                        </div>)
                                                    }
                                                    </>
                                                    :
                                                        <div className='cursor-pointer shrink-0' onClick={(e) => onColumnActionClick("filterOpen", header.column, e.currentTarget)}>
                                                        <FilterAltIcon className='mr-2' style={{ width: "16px", height: "12px" }} />
                                                    </div>
                                                    )
                                                )}
                               
                                            </div>
                                        )}                                                                           
                                    </th>
                                )
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody>
                   
                    {table.getRowModel().rows?.length > 0 ?
                    table.getRowModel().rows.map(row => {
                            return (
                                <tr key={row.id} className='custom-tr group'>
                                    {row.getVisibleCells().map(cell => {
                                        return (
                                            <td style={{ ...getHeadingStyles(cell.column) }} key={cell.id} className={`p-1 xl:p-2 pl-2 border-b border-[#E0DCD7] text-[12px] ${tdClassName ?? "2xl:w-[11rem] w-[10rem]"} bg-white group-hover:bg-[#E7E6E6] ${cell.column.columnDef.cellClassName}  `}>
                                                
                                                <OverflowTooltip
                                                    className="text-[12px] md:text-[10px] xl:text-[12px]"
                                                    text={cell.getIsPlaceholder() ? null :(flexRender(cell.column.columnDef.cell, cell.getContext()))} 
                                                />

                                            </td>
                                        )
                                    })}
                                </tr>
                            )
                        })
                        :
                        <tr>
                            <td
                                colSpan={table.getHeaderGroups()?.at(0)?.headers?.length}
                                className="text-[12px] 2xl:text-[13px] text-center p-4"
                            >
                                No data found
                            </td>
                        </tr>                    
                        }
                </tbody>
            </table>
            </div>
            <div className={`flex items-center justify-end py-2 mr-2 ${totalCount > table.getState().pagination.pageSize ? 'block' : 'hidden'}`}>
                <Pagination
                    className='table-pagination'
                    size='small'
                    page={table.getState().pagination.pageIndex + 1}
                    count={Math.ceil(totalCount / table.getState().pagination.pageSize)}
                    onChange={(e, page) => onTableStateChange("pagination", page)}
                    shape="rounded" />
            </div>
        </>
    )
}

export default DataTable