import { all, fork } from "redux-saga/effects";
import loginSaga from "./Authentication/Saga";
import clientsSaga from "./Clients/Saga";
import commonSaga from "./Common/Saga";
import WizardSaga from "./Wizard/Saga"
import CheckWorkFlow from "./Wizard/CheckWorkFlow/Saga"

export default function* rootSaga() {
  yield all([ 
    fork(loginSaga),
    fork(clientsSaga),
    fork(commonSaga),
    fork(WizardSaga),
    fork(CheckWorkFlow)
  ]);
}
