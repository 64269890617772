import React from 'react'

const HistroyIcon = ({width,height,bgColor,color,className}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 22.715 20">
            <g id="Group_169" data-name="Group 169" transform="translate(358.812 -198.589)">
                <path id="Path_115" data-name="Path 115" d="M-347.453,198.6c3.03,0,6.06.031,9.088-.015a2.132,2.132,0,0,1,2.259,2.231c-.027,2.125-.009,4.251-.006,6.376q0,3.816.014,7.632c0,.54-.016,1.079-.013,1.619a2.092,2.092,0,0,1-2.176,2.142c-4.957-.027-9.915-.012-14.872-.012q-1.735,0-3.47,0a2.023,2.023,0,0,1-2.168-2.167c-.006-1.234,0-2.467,0-3.7q-.006-4.047-.014-8.094,0-1.916.015-3.832a2.025,2.025,0,0,1,2.19-2.178Zm-10.625,2.243h21.243a1.335,1.335,0,0,0-1.4-1.571c-2.059,0-4.118,0-6.177,0q-6.111,0-12.222,0A1.338,1.338,0,0,0-358.077,200.848Zm14.88,3.428V201.59h-5.635v2.686Zm.712-.011h5.655V201.6h-5.655Zm-7.092,3.394v-2.7h-5.064c-.608,0-.608,0-.608.613,0,.69,0,1.381,0,2.087Zm7.1-2.679v2.673h5.639V204.98Zm-12.755,6.1h5.646v-2.671h-5.646Zm12.041-2.677h-5.644v2.675h5.644Zm.7,2.676h5.656v-2.668h-5.656Zm-12.74.715v2.676h5.64v-2.676Zm6.39,0v2.684h5.642v-2.684Zm12.013,2.687v-2.683h-5.648v2.683Zm-18.394.725v2.685h5.636V215.2Zm6.374,2.682h5.645v-2.675h-5.645Zm.008-12.9v2.68h5.649c.007-.123.017-.23.017-.336,0-.672-.011-1.343.007-2.014.007-.271-.071-.357-.349-.355-1.673.012-3.347.006-5.02.007C-348.629,204.961-348.725,204.973-348.836,204.98Zm6.357,12.921c1.5,0,2.966.02,4.428-.009a1.26,1.26,0,0,0,1.222-1.1c.052-.519.011-1.048.011-1.578h-5.66Zm-13.44-16.308h-2.154v2.7c.557,0,1.085,0,1.613,0,.621-.005.536.081.54-.545C-355.917,203.031-355.92,202.319-355.92,201.594Zm-2.165,6.057h2.143v-2.664h-2.143Zm0,3.416h2.141v-2.674h-2.141Zm0,3.4h2.14v-2.68h-2.14Zm2.156.74H-358.1a10.924,10.924,0,0,0,.013,1.531c.135.933.942,1.358,2.153,1.1Z" transform="translate(0 0)" 
                fill={bgColor} />
            </g>
        </svg>

    )
}

export default HistroyIcon
