import {CHECK_WORKFLOW,CHECK_WORKFLOW_SUCCESS,CHECK_WORKFLOW_API_ERROR,CHECK_WORKFLOW_DATA_CLEAR} from './ActionType';

export const checkWorkFlow = (payload) => {
    return {
      type: CHECK_WORKFLOW,
      payload: payload,
    };
  };
  
  export const checkWorkFlowSuccess = (payload) => ({
    type: CHECK_WORKFLOW_SUCCESS,
    payload: payload,
  });
  
  export const checkWorkFlowApiError = error => {
    return {
      type: CHECK_WORKFLOW_API_ERROR ,
      payload: error,
    };
  };
  
  export const checkWorkFlowDataClear = (payload) => {
    return {
      type: CHECK_WORKFLOW_DATA_CLEAR,
      payload: payload,
    };
  };