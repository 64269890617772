
//Dependecies
import React from 'react'
import { useState, useEffect } from 'react'
import { useForm} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
//imgaes
import avatar from '../../Images/avatar.png'
import email from '../../Images/email.png'
import user from '../../Images/userProfile.png'
import tick from '../../Images/tick.png'
//components
import Warning from '../Indications/Warning'
import { NavLink } from 'react-router-dom';
import RouteSegments from '../../Routes/RouteSegments';
//Redux components
import {useDispatch, useSelector } from "react-redux";
import { registerUser, resetRegisterError } from "../../Store/Authentication/Action";
import withRouter from '../../Routes/withRouter';
import { toast } from 'react-toastify';

const schema = yup.object().shape({
  firstname: yup.string()
    .required('Please enter first name')
    .matches(/^[A-Za-z ]+$/, 'Please enter valid name'),    
  lastname: yup
    .string()
    .trim()
    .required('Please enter last name')
    .matches(/^[\w]+$/, 'Please enter valid last name'),
email: yup
        .string()
        .email('Please enter a valid email id')
        // .matches(/@in\.gt\.com$/, 'Email must end with @in.gt.com')
        .matches(/@(gmail\.com|in\.gt\.com)$/, 'Email must end with @gmail.com or @in.gt.com')
        .required('Please enter email')        

});

const Register = (props) => {
  const { register, handleSubmit, setFocus, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  const dispatch = useDispatch()
  const [firstName, setFirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [registering, setRegistering] = useState(false);
  const [errorMsg, setErrorMsg] = useState()

  const onSubmit = (data) => {
    setRegistering(true)
    // props.router.navigate
    dispatch(registerUser(data,null))
  };

  const {registerResponse,error} =useSelector(state => {
    return {
      error: state?.login?.error,
      registerResponse:state?.login?.registerData
    }
})
useEffect(()=>{
  if(registerResponse){
  // toast.success(registerResponse?.message)
  }
  if(registerResponse||error){
    setRegistering(false)
  }
  setErrorMsg(error?.message) 
},[registerResponse,error])


  useEffect(() => {
   if(errors.firstname == undefined){
    setFocus("firstname");}
  }, [setFocus]);

  useEffect(() => {
    // Reset the login error when the component is unmounted or when navigating away
    return () => {
      dispatch(resetRegisterError());
    };
  }, []);
  const handleKeyDown = (e) => {
    const allowedKeys = /^[a-zA-Z ]$/;
    if (!allowedKeys.test(e.key) && e.key !== 'Backspace' && e.key !== 'Delete' && e.key !== 'ArrowLeft' && e.key !== 'ArrowRight' && e.key !== 'Tab') {
      e.preventDefault();
    }
  };

  return (
    <div className="w-full flex items-center justify-end h-full absolute right-[10%]">
      <div className="w-[75%] max-w-sm">
        <div className="p-4">
          <div className="py-3 flex items-center before:flex-[1_1_0%] before:border-t before:border-carrot-orange ltr:before:mr-6 rtl:before:ml-6 after:flex-[1_1_0%] after:border-t after:border-carrot-orange ltr:after:ml-6 rtl:after:mr-6 dark:text-white/70 dark:before:border-white/10 dark:after:border-white/10">
            <div className="w-[5rem] mx-auto">
              <img alt="userlogo" className='z-10' src={user} />
            </div>
          </div>
          <div className="mt-5">
            {registerResponse?.message ?
            <p className='text-[18px] text-center text-greeny-blue'>{registerResponse?.message}</p>
            :
            <form onSubmit={handleSubmit(onSubmit)}>
              {
                errors.firstname && errors.firstname.type=="required" && errors.lastname && errors.lastname.type=="required" && errors.email && errors.email.type=="required" ?
                  <p className="text-yellow text-center text-sm mb-2">Please fill all the fields</p>
                : errors.firstname && errors.firstname.type=="required"  ? 
                  <p className="text-yellow text-center text-sm mb-2">{errors.firstname.message}</p>
                : errors.firstname && errors.firstname.type!=="required" ?
                  <p className="text-error-red text-center text-sm mb-2">{errors.firstname.message}</p>
                :  errors.lastname && errors.lastname.type=="required"  ? 
                  <p className="text-yellow text-center text-sm mb-2">{errors.lastname.message}</p>
                : errors.lastname && errors.lastname.type!=="required" ?
                  <p className="text-error-red text-center text-sm mb-2">{errors.lastname.message}</p>
                : errors.email && errors.email.type=="required" ? 
                  <p className="text-yellow text-center text-sm mb-2">{errors.email.message}</p>
                : errors.email && errors.email.type =='checkEmailAvailability' ?
                <p className="text-greeny-blue text-center text-sm mb-2">{errors.email.message}</p>
                : errors.email && errors.email.type!=="required"?
                  <p className="text-error-red text-center text-sm mb-2">{errors.email.message}</p>
                 :<></>
              }
              {errorMsg &&<p className="text-greeny-blue text-center text-sm mb-2">{errorMsg}</p>}
              
              <div className="relative mb-4">
                <img src={avatar} className="absolute top-1/2 left-3 transform -translate-y-1/2 w-4 brightness-0 invert-[1]" />
                <input type="text" id="firstname" placeholder="First name" name="firstname"
                  {...register("firstname",{
                    onChange: (e) => {
                      setFirstName(e.target.value);
                      setErrorMsg(null);
                  },
                  })}
                  className={`w-full text-white rounded px-0 py-2.5 ${errors.firstname ? errors.firstname.type=="required" ? 'border border-yellow' : 'border border-error-red' :''} focus:outline-none pl-[2.5rem] bg-charcoal-gray placeholder:text-white text-[14px]`}
                  autoComplete="off" 
                  onKeyDown={handleKeyDown}

                  />
                {errors.firstname &&
                  <div className="absolute top-1/2 right-3 transform -translate-y-1/2 w-4" >
                    <Warning circleBg={errors.firstname && errors.firstname.type=="required" ? "yellow" : "#FF6174"} />
                  </div>}
              </div>
              <div className="relative mb-4">
                <img src={avatar} className="absolute top-1/2 left-3 transform -translate-y-1/2 w-4 brightness-0 invert-[1]" />
                <input type="text" id="lastname" placeholder="Last name" name="lastname"
                  {...register("lastname",{
                    onChange: (e) => {
                      setlastName(e.target.value);
                      setErrorMsg(null);
                  },
                  })}
                  className={`w-full text-white rounded px-0 py-2.5 ${errors.lastname ? errors.lastname.type=="required" ? 'border border-yellow' : 'border border-error-red' :''} focus:outline-none pl-[2.5rem] bg-charcoal-gray placeholder:text-white text-[14px]`}
                  autoComplete="off" 
                  onKeyDown={handleKeyDown}

                  />
                {errors.lastname &&
                  <div className="absolute top-1/2 right-3 transform -translate-y-1/2 w-4" >
                    <Warning circleBg={errors.lastname && errors.lastname.type=="required" ? "yellow" : "#FF6174"} />
                  </div>}
              </div>
              
              <div className="relative">
                <img src={email} className="absolute top-1/2 left-3 transform -translate-y-1/2 w-5 brightness-0 invert-[1]" />
                <input type="email" name="email" id="email" placeholder="Email address" autoComplete='off'
                  {...register("email",{
                    onChange: (e) => {
                      setEmailAddress(e.target.value);
                      setErrorMsg(null);
                  },
                  })}
                  className={`w-full text-white rounded px-0 py-2.5 ${errors.email ? errors.email.type=="required" ? 'border border-yellow' : errors.email.type =='checkEmailAvailability'? 'border border-greeny-blue' : 'border border-error-red' :''} ${errorMsg && 'border border-greeny-blue'} focus:outline-none pl-[2.6rem] bg-charcoal-gray placeholder:text-white text-[14px]`}
                />
                {errors?.email  && errors?.email != undefined ?
                    errors?.email?.type =='checkEmailAvailability' ?
                    <img src={tick}className='absolute top-1/2 right-3 transform -translate-y-1/2 w-4'/>
                    :
                  <div className="absolute top-1/2 right-3 transform -translate-y-1/2 w-4" >
                    <Warning circleBg={errors?.email?.type=="required" ? "yellow" : errors?.email?.type!="required" ? "#FF6174" :''} />
                  </div>
                  :<></>
                }
                {errorMsg && <img src={tick}className='absolute top-1/2 right-3 transform -translate-y-1/2 w-4'/>}
              </div>
              <div className="mt-5 flex items-center justify-center">
                <button type="submit" disabled={!firstName || !lastName || !emailAddress} className={`w-full rounded py-1.5 ${!firstName || !lastName || !emailAddress ? 'bg-ash text-[#707070]':'bg-greeny-blue text-white'}  focus:outline-none text-[16px]`}>{registering? 'Registering...':'Register'}</button>
              </div>
              <div className="flex items-center justify-center mt-6">
              <NavLink to={RouteSegments.HOME} className="w-full">
                <button type="button" className="w-full rounded py-1.5 text-white bg-red-orange focus:outline-none text-[16px]">Back to login</button>
              </NavLink>
              </div>
            </form>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default withRouter(Register);

