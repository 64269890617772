import React, { useEffect, useState } from 'react'
import HistroyIcon from '../CommonIcons/HistroyIcon'
import CloseIcon from '../CommonIcons/CloseIcon'
import HistoryDataTable from '../Table/HistoryDataTable'
import DataTableSkeleton from '../Skeleton/DataTableSkeleton'
import { Baseurl } from '../../Utils/Constants'
import { GET_NULL_REPLACEDVALUES } from '../../Helpers/EndPoints'
import axios from 'axios'

const ReplacementHistory = ({ title, onClose, headerBg, borderColor, levelTwoCardId, navigationData, isOpen,workFlowId }) => {


const columns = [
    {
      Header: 'Source',
      accessor: 'source',
    },
    {
      Header: 'Destination',
      accessor: 'destination',
    },
    {
      Header: 'Null replaced value',
      accessor: 'null_replaced_value',
    },
    {
      Header: 'Null replaced key',
      accessor: 'null_replaced_key',
    },
    {
      Header: 'Affected row count',
      accessor: 'affected_row_count',
    },
    {
      Header: 'Created at',
      accessor: 'created_at',
    },    
  ];
  
  const data = [
    {
      source: '',
      destination: '',
      null_replaced_value: '',
      null_replaced_key: '',
      null_replaced_key: '',
      affected_row_count: '',
      created_at: '',
      id: 0
    }
  ]
//   const [workFlowId,setWorkFlowId] = useState()
  const [relplacementValues,setRelplacementValues] =useState()
  const getReplacementValues = () =>{
    axios(`${Baseurl + `/ecl/${workFlowId}/null/replacement/history`}`, {
        method: "GET",
      })
        .then((response) => {
            console.log(response,'response');
          if (response?.data?.status == 200) {
            setRelplacementValues(response?.data?.data)
          }
        })
        .catch((error) => {
          console.log("API not working", error);
        })
  }
  console.log(relplacementValues,'relplacementValues');
  useEffect(()=>{
    if(workFlowId){
    getReplacementValues()
}
  },[workFlowId])
  return (
    <div className={`border-l-2 ${borderColor} h-full`}>
      <div className={`w-full ${headerBg} flex justify-between py-5 pl-[1rem] pr-[2.1rem]`}>
        <div className='flex items-center gap-2 '>
          <HistroyIcon width={'25px'} height={'20px'} bgColor={'#ffffff'} />
          <p className='text-white font-semibold text-[13px]'>{title}</p>
        </div>
        <div className='flex items-center gap-2 cursor-pointer' onClick={() => onClose('replacement')}>
          <p className='text-white font-semibold text-[12px]'>Close</p>
          <CloseIcon width={'15px'} height={'15px'} bgColor={'#ffffff'} />
        </div>
      </div>
      <div className='pt-[3rem] pr-[2rem] pl-[1rem]'>           
        {relplacementValues?.length > 0 ? (
          <HistoryDataTable
            columns={columns}
            data={relplacementValues}
            headerclassName={'py-2 bg-[#5B9BD5] text-white text-[12px] border-none'}    
          />
        ) : relplacementValues !== undefined ? ( // Check if EclHistoryList is defined (not null or undefined)
          <>
            <HistoryDataTable
            columns={columns}
            data={data}
            headerclassName={'py-2 bg-[#5B9BD5] text-white text-[12px] border-none'}            
          />
          </>
        ) : (
          <DataTableSkeleton showHeader={true}  columnWidths={''} rowCount={5} columnCount={7} showPagination={true} />
        )}

      </div>
    </div>
  )
}

export default ReplacementHistory
