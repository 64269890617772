import React, { useEffect,useState } from 'react';
import { Select } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import DownArrow from '../CommonIcons/DownArrow';
// import Button from '../Button';
import Button from '../../Components/CommonComponents/Button';

import HistoryDataTable from '../Table/HistoryDataTable';
import DataTableSkeleton from '../Skeleton/DataTableSkeleton';
import axios from 'axios';
import SelectInput, { TextInput } from '../SelectInput'

import { Baseurl, localStorageKeys, loginLocalStorageKeys } from '../../Utils/Constants';
import { COLUMN_NAME_EXITS_CHECK, CREATE_MERGE_SUMMARY, GET_COLUMN_NAME_LIST, RENAMED_COLUMN_LIST, RENAME_COLUMN_SAVE} from '../../Helpers/EndPoints';
import EditIcon from '../../Images/Group 809.svg'
import { convertDateFormat } from '../../Utils/CommonFunctions';
import CloseIcon from "../../Images/remove.svg"
import { toast } from 'react-toastify';
import DataTable from '../Table/DataTable';
import { useDispatch } from 'react-redux';
import XlsIcon from '../../Images/excel.svg'
import { useLocation } from 'react-router-dom';
import useDataTable from '../../hooks/useDataTable';
import { setCurrentNodeData } from '../../Store/Workflow/WorkflowSlice';
import ProceedButton from '../CommonComponents/ProceedButton';
import downloadingIcon from '../../Images/gif/downloading.gif'


const schema = yup.object().shape({
  category: yup.string().required("Please select category"),
  columnName: yup.string().required("Please select column name"),
});

function RenameColumnName({ 
  workflow_id, 
  ecl_node_id ,
  workflow_node_id,
  calculationDate,
  templateId, 
  templateName,
  client_branch_level_id,
  nodeCategoryId,
  onProceedClick,
                                                     
}
) {
  const [isCategoryOpen, setIsCategoryOpen] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(nodeCategoryId);
  const [disabled, setDisabled] = useState(true);
  const [selectedDataType,setSelectedDataType] = useState('');
  // const [renameColumnList, setRenameColumnList] = useState([]) 
  const [currentIndex, setCurrentIndex] = useState(0);
  const [initialLoading, setInitialLoading] = useState(true);  
  const [columnNameList,setColumnNameList] = useState([])
  const [newColumnName,setNewColumnName] = useState('');
  const [oldColumnName, setOldColumnName] = useState();
  const [renameableColumnList,setRenameableColumnList] = useState([])
  const [showEdit, setShowEdit] =useState(false);
  const [isEdit, setIsEdit] = useState();
  const [showAddRename, setShowAddRename] = useState(false);
  const [undoDatas,setUndoDatas] = useState([]);
  const [fileMerging, setFileMerging] = useState()
  const [renameColumnList, setRenameColumnList] = useState()


  const { dataTableColumns,
    getFilterOptions,
    summaryData,
    tableState,
    refreshDataTable,
    onTableStateChange,
    isDataLoading,
    isExportLoading,
    donwloadXl,
  } = useDataTable({ category_id: selectedCategory, ecl_node_id, workflow_id, from: "renameColumn" })

  const { register, control, reset, formState: { errors } } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });
  const dispatch = useDispatch();
  const location = useLocation();

  const columns = [
    {
      Header: 'Old column',
      accessor: 'old_column',
    },
    {
      Header: 'New column',
      accessor: 'new_column',
    }   
  ];


  console.log({ dataTableColumns });



  const calculationApiCall = async (isFrom,category_id) => {

    if (calculationDate == 'Invalid date') {
    }
    else {
      if(templateId || templateName){     
      const postFormData = new FormData();
      postFormData.append('ecl_workflow_id', workflow_id != null || workflow_id != undefined ? workflow_id : "");
      postFormData.append('user_id', localStorage.getItem(loginLocalStorageKeys.loginId));
      postFormData.append('client_branch_level_id', client_branch_level_id);
      postFormData.append('date', convertDateFormat(calculationDate));
      postFormData.append('template_type', 'New');
      postFormData.append('ecl_workflow_node_id', isEdit ? workflow_node_id :"");
      postFormData.append('node_id', ecl_node_id);  
      postFormData.append('is_proceed', "Completed");    
      if(isFrom == 'postionAnalysis' || isFrom == 'dataCleansing'){
        postFormData.append('category_id', category_id); 
      }
      postFormData.append('template_id', templateId ?? "");
      postFormData.append('template_name', templateId ? "" : templateName)

      axios.post("https://ecl.port0.org/laravelapi/api/ecl/calculation", postFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          // handle the response
          if (response?.data?.status == 200) {
            localStorage.setItem(localStorageKeys.currentWorkFlowId, response?.data?.workflowId)
            setShowEdit(true)
            setShowLoader(false)
            // dispatch(getNodeDetails(response?.data?.workflowId));
            dispatch(setCurrentNodeData({
              workflowNodeId: response?.data?.workflowNodeId,
              nodeCategoryId: category_id
            }))
            // refreshDataTable()           
            //  getTableData();                    
            // setReplaceClicked(false)
            // setNodeSaved(true)
            // setIsFileSentToS3(true)
            // setError(false)                                                        
            if (isFrom != 'another') {
              localStorage.setItem(localStorageKeys.lastNodeStatus, 'completed')
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }}
  }

  const getCategoryList = () => {
    axios(`${Baseurl}/ecl/calculation/${workflow_id}/${ecl_node_id}/nodeWise/categoryList`, {
      method: "GET",
    })
      .then((response) => {
        if (response?.data?.status === 200) {
          setCategoryList(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  };

  const filesMergeApiCall = () => {  
    setFileMerging(true)
    axios(`${Baseurl + CREATE_MERGE_SUMMARY}`, {
      method: "POST",
      data: {
        workflow_id: workflow_id,
        ecl_node_id: ecl_node_id
      },
    })
      .then((response) => {
        if (response?.data?.status == 200) {
          setFileMerging(false)      
          // refreshDataTable()
          setInitialLoading(false)          
          setDisabled(false)
          showLoader(false)
        }else if(response?.data?.status == 500){
          toast.error('Duck DB connection error please refresh the page')
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  };

  useEffect(() => {
    if (nodeCategoryId) {
      getTableData()
    }
  }, [])

  const getTableData = (isFrom) => {
    setShowLoader(true);

    axios(`${Baseurl + RENAMED_COLUMN_LIST}`, {
      method: "POST",
      data: {
        workflow_id: workflow_id,
        ecl_node_id: ecl_node_id,
        category_id: selectedCategory
      },
    })
      .then((response) => {
        if (response?.data?.status === 200) {
          setRenameColumnList(response?.data);
          setShowLoader(false);
          setDisabled(true)
          if(isFrom == 'cateSave'){
            getColumnNameList()
          }
          if(response?.data?.renameDatas?.length ==0 ){
          setShowEdit(false)
          if(isFrom == 'cateSave'){
            setShowAddRename(true)
          }
        }else{
          refreshDataTable()          
          setShowEdit(true)
        }
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
  };

  const getColumnNameList = ()=>{
    axios(`${Baseurl + GET_COLUMN_NAME_LIST}`, {
        method: "POST",
        data: {
          workflow_id: workflow_id,
          ecl_node_id: ecl_node_id,
          category_id: selectedCategory
        },
      })
        .then((response) => {
          if (response?.data?.status === 200) {
            // setShowAddRename(true)
            setColumnNameList(response?.data?.columnNames);
            refreshDataTable()
          }
        })
        .catch((error) => {
          console.log("API not working", error);
        });    
    
  }

  const getSaveRenamedColumns = ()=>{
    setShowLoader(true)
    axios(`${Baseurl+RENAME_COLUMN_SAVE}`, {
        method: "POST", 
        data: {
            ecl_workflow_id: workflow_id,
            ecl_node_id: ecl_node_id,
            ecl_category_id: selectedCategory,
            rename_datas: renameableColumnList,
            rename_undo_datas: undoDatas,
          },      
      })
        .then((response) => {
          if (response?.data?.success == true) {       
            calculationApiCall('dataCleansing',selectedCategory)
            // refreshDataTable()
            setUndoDatas([])
        }else{
          console.log(response?.data?.error,'error');
        }
        })
        .catch((error) => {
          console.log("API not working", error);
        });

  }

  useEffect(() => {    
    getCategoryList();    
    filesMergeApiCall();
  }, []);


  useEffect(()=>{
    if(renameColumnList?.renameDatas?.length > 0){
      setInitialLoading(false);
      setShowLoader(false)
      setShowEdit(renameColumnList?.isEdit)
      setIsEdit(renameColumnList?.isEdit)
    }

  },[renameColumnList])


  const handleClickAdd = () => {
    // Check if newColumnName already exists in the renameableColumnList
    const columnExists = renameableColumnList.some(
        column => column.new_column === newColumnName
    );

    if (columnExists) {
        // Handle the case where the column already exists (e.g., show an error message)
        toast.error("This column name already exists.");
        return;
    }

    // If the column doesn't exist, proceed with the API call
    axios(`${Baseurl + COLUMN_NAME_EXITS_CHECK}`, {
        method: "POST",
        data: {
            workflow_id: workflow_id,
            ecl_node_id: ecl_node_id,
            category_id: selectedCategory,
            column_name: newColumnName
        },
    })
    .then((response) => {
        if (response?.data?.status === 200) {
            setRenameableColumnList([
                ...renameableColumnList,
                { old_column: oldColumnName, new_column: newColumnName }
            ]);
            setOldColumnName(undefined);
            reset({ columnName: undefined });
            setNewColumnName('');
        } else {
            toast.error(response?.data?.message);
        }
    })
    .catch((error) => {
        console.log("API not working", error);
    });
};

  const handleRemoveColumn = (index,column) => {
    setRenameableColumnList(renameableColumnList?.filter((_, i) => i !== index));
    setUndoDatas([...undoDatas, column])
  };

  return (
    <div className='relative '>   
    {
      initialLoading ?
      <div className='mt-2'>
      <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
    </div>
    :  
    (
        <div className={`${showEdit ? 'hidden':'block'} flex space-x-4 mt-4`}>
          <Controller
            control={control}
            name="category"
            render={({ field }) => (
              <Select
                ref={field.ref}
                name={field.name}
                onBlur={field.onBlur}
                {...register("category")}
                onChange={(e) => {
                  field.onChange(e);
                  setSelectedCategory(e);
                }}
                value={nodeCategoryId ? nodeCategoryId : field.value ? field.value : undefined}
                placeholder="Select data category"
                options={categoryList?.map(cList => ({
                  label: cList?.category_name,
                  value: cList?.category_id,
                  disabled: cList?.is_disabled

                }))}
                disabled={disabled || nodeCategoryId}
                onDropdownVisibleChange={(visible) => setIsCategoryOpen(visible)}
                suffixIcon={<DownArrow color="#000000" width={'10px'} height={'10px'} className={isCategoryOpen ? 'transition-transform transform rotate-180' : 'transition-transform transform rotate-[-180]'} />}
                className="custom-select-height border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"
                style={{
                  width: '100%',
                  border: '2px solid #00A7B5',
                  borderRadius: '4px',
                }}
              />
            )}
          />
          <Button
             className={'bg-[#47B4AE] text-white px-[1.5rem] 2xl:px-[1.5rem] py-1.5 rounded-[5px]'}                  
             label={'Save'}   
            onClick={()=>getTableData('cateSave')}
            disabled={disabled || nodeCategoryId || !selectedCategory}
          />
        </div>
    )
      
    }
        
    {renameableColumnList?.length > 0  ? 
    <div className={`2xl:mt-4 mt-3 ${renameColumnList?.renameDatas?.length > 0 && showEdit ? 'hidden':'block'}`}>
      <div className='bg-[#4339A8] grid grid-cols-5 text-white font-semibold text-[13px] py-2 px-4 sticky top-0 z-[1000]'>
        <div className='col-span-2'> Column name to rename</div>
        <div className='col-span-2'> New column name </div>
        <div className='col-span-1'> Action</div>
      </div>
      <div className='max-h-[5rem] xxlg:max-h-[8rem] 2xl:max-h-[15rem]  thin-scrollbar '>
        {renameableColumnList?.map((column,i)=>{
          return(
              <div className='space-y-2 mt-2' key={i}>
                <div className='w-full bg-[#ECECEC] py-2.5 px-4 grid grid-cols-5 rounded'>
                    <div className='col-span-2 text-[#707070] text-[12px] xl:text-[14px]'>
                        {column?.old_column}
                    </div>
                    <div className='col-span-2 text-[#707070] text-[12px] xl:text-[14px]'>
                    {column?.new_column}
                    </div>
                    <div className='col-span-1' onClick={()=>handleRemoveColumn(i,column)}>
                        <button className='text-[#4F49DF] text-[12px] xl:text-[14px] flex items-center gap-2'>
                            <img src={CloseIcon} className='w-3' />
                            Remove
                        </button>
                    </div>
                </div>
              </div>
          )
        })}
      </div>
    </div>

       :<></>

    }   
    {
        !showLoader ? 
       
        
        (renameColumnList?.renameDatas?.length > 0 || summaryData?.columnDatas?.length > 0  ?
            <div className= {`${showEdit ? 'block':'hidden'} mt-5`}>
                <div className='2xl:py-3 py-1.5 bg-[#4339A8] flex justify-between items-center'>
                  <div className='mx-4 flex gap-3 items-center'>
                    <button className='text-[#FFFFFF] bg-[#F7BE7E] py-0.5 px-2 text-[12px] rounded'>Data category</button>
                    <p className='text-[#ffffff] font-semibold text-[12px]'>{renameColumnList?.categoryName}</p>
                    </div>                  
                    <div className={`flex gap-1 items-center mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none':'cursor-pointer'}`} onClick={()=>{getColumnNameList();setShowEdit(false);setRenameableColumnList(renameColumnList?.renameDatas);setShowAddRename(true);}}>
                        <img src={EditIcon}  className='w-4 h-4'/>
                    <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
                    </div>
                </div>            
              <HistoryDataTable
                columns={columns}
                data={renameColumnList?.renameDatas ?? []}
                tableCellClassName={'text-[#000000] 2xl:text-[14px] text-[12px] bg-white px-5 2xl:px-8 py-1.5 xl:py-3 2xl:py-3'}
                headerclassName={'2xl:py-4 py-2 px-5 2xl:px-8 bg-[#A06DFF] text-white text-[12px] xl:text-[13px] border-none max-w-[10rem] min-w-[6rem]'}                
                placeholder={'Select data type'}
                setSelectedDataType={setSelectedDataType}
                name={'dataType'}
                disabled={showEdit}
                setCurrentIndex={setCurrentIndex}
                tableHeight={'table-height max-h-[8.5rem] xxlg:max-h-[17rem]  2xl:max-h-[25rem] 3xl:max-[27rem] 4xl:max-h-[30rem] 5xl:max-[35rem]'}
              />
            </div>
           :<></>
            )
            :
            (<div className='mt-2'>
                    <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
                  </div>)

            
       }
            {showAddRename && columnNameList?.length >0 ?
            <div className={`${(isDataLoading || showLoader)
             || (renameColumnList?.renameDatas?.length > 0 && showEdit) ? 'hidden':'block'}`}>
              <div className='flex space-x-4 mt-6'>
                    <Controller
                        control={control}
                        name={`columnName`}
                        id={`columnName`}
                        render={({ field }) => {
                            return (
                                <SelectInput
                                    ref={field.ref}
                                    name={field.name}
                                    onBlur={field.onBlur}
                                    onChange={(e)=>{
                                        field.onChange(e);
                                        setOldColumnName(e)
                                    }}
                                    value={field.value}
                                    placeholder="Select columnName"
                                    className="custom-select-height border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"
                                    options={columnNameList?.map(cList => ({
                                        label: cList?.column_name,
                                        value: cList?.column_name,
                                        disabled: renameableColumnList?.some(renameColumn => renameColumn?.old_column == cList.column_name)

                                    }))}
                                />
                            );
                        }}
                    />
                    <Controller
                        control={control}
                        name={`newColumnName`}
                        id={`newColumnName`}
                        render={({ field }) => {
                            return (
                                <TextInput placeholder="Enter new column name"
                                className={'place text-[12px] 2xl:text-[13px] h-[1.7rem] xxl:h-[2.2rem]'}
                                value={newColumnName}
                                onChange={(e)=>{setNewColumnName(e.target.value.replace(/[^a-zA-Z0-9\s]/g, ''))}}

                                />
                            );
                        }}
                    />
                    <Button
                        className={'bg-[#47B4AE] text-white px-[1.7rem]  py-1.5 rounded-[5px]'}
                        label={'Add'}
                        disabled={!newColumnName || !oldColumnName}
                        onClick={() => {handleClickAdd()}}
                    />
              </div> 
              {renameableColumnList?.length >0 &&
                <div className='flex items-center justify-center mt-4'>
                    <Button
                    className={'bg-[#47B4AE] text-white px-[1.5rem] 2xl:px-[1.5rem] py-1.5 rounded-[5px]'}                  
                    label={'Save'}   
                    disabled={renameableColumnList?.length <=0 || newColumnName || oldColumnName}
                    onClick={() => {getSaveRenamedColumns()}}
                    />
                </div>
                }
            </div>
                :
                <></>
            }

            {!showLoader ? isDataLoading  
                  ? <div className='mt-2'>
                    <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
                  </div>
                  :
                  (summaryData?.columnDatas?.length > 0) ?
                   <div className='mt-5'> 
            
              <div className='flex justify-end items-center'>
                    {isExportLoading
                    ? <img src={downloadingIcon} className='w-6 mr-2' />
                    : <img src={XlsIcon} className=' mb-2 object-cover mr-2 cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
              </div>
          
              <DataTable 
                columns={dataTableColumns}
                data={summaryData?.columnDatas ?? []}
                onTableStateChange={onTableStateChange}
                totalCount={summaryData?.totalRecords}
                isOptionsDisabled={location?.state?.isfromHistory}
                getFilterOptions={getFilterOptions}
                tableState={{
                  pagination: {
                    pageIndex: tableState.pageIndex,
                    pageSize: tableState.pageSize,
                  },
                  sorting: tableState?.sortBy ? [
                    {
                      id: tableState?.sortBy,
                      desc: tableState?.sortOrder === "desc"
                    }
                  ] : [],
                  columnFilters: tableState.columnFilters
                    ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                    : [],
                  columnPinning: tableState.columnPinning,
                  columnVisibility: tableState?.columnVisibility,
                 }}
                />
                <div className={`float-right 2xl:my-6 my-3 ${showEdit && !location?.state?.isfromHistory ? 'block':'hidden'}`}>
                <ProceedButton
                  onClick={onProceedClick}
                />                                                  
                </div>
                </div>
                :<></>
                :<></>
                                                                        
            }    
    </div>
  );
}

export default RenameColumnName;
