import { configureStore } from '@reduxjs/toolkit';
import login from './Authentication/Reducer';
import Clients from './Clients/Reducer';
import Common from './Common/Reducer';
import ModelBuilder from './Wizard/Reducer'
import CheckWorkFlowReducer from './Wizard/CheckWorkFlow/Reducer'
import rootSaga from "./saga"

import createSagaMiddleware from 'redux-saga'
import Workflow from './Workflow/WorkflowSlice';


const sagaMiddleware = createSagaMiddleware()

const store = configureStore({
  // Automatically calls `combineReducers`
  reducer: {
    login,
    Clients,
    Common,
    ModelBuilder,
    CheckWorkFlowReducer,
    Workflow,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
})

sagaMiddleware.run(rootSaga)

export default store;

// export default function configureStore(initialState) {
//   // Note: passing middleware as the last argument to createStore requires redux@>=3.1.0
//   const sagaMiddleware = createSagaMiddleware()
//   const composedEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//   const store = createStore(
//     rootReducer,
//     initialState,
//     // applyMiddleware(sagaMiddleware)
//     composedEnhancers(applyMiddleware(sagaMiddleware)), // enable redux dev tools
//     // composedEnhancers
//   );
//   sagaMiddleware.run(rootSaga);

//   return store
// }