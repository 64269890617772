import React, { useMemo } from 'react'
import CommonPopup from './CommonPopup'
import SummaryTable from '../Table/SummaryTable'
import { createColumnHelper } from '@tanstack/react-table'
import OverflowTooltip from '../ToolTip/OverFlowToolTip'

const columnHelper = createColumnHelper()
function DataPopup({ onClose, title = "", from, source ,popupClassName=""}) {

    const columns = useMemo(() => {
        return source?.headers?.map(col => columnHelper.accessor(col?.accessorKey, {
            header: col?.header,
            cell: ({ getValue }) => {
                return <OverflowTooltip
                    className="text-[12px] md:text-[10px] xl:text-[12px]"
                    text={getValue()
                    } />
            }
        }))
    }, [source])

    const data = useMemo(() => {
        return source?.datas ?? []
    }, [source])

    return <CommonPopup
        title={title}
        onCancel={onClose}
        content={<div className={`${popupClassName} max-w-[80vw] max-h-[70vh] overflow-x-auto overflow-y-auto thin-scrollbar`}>
            <div className='w-full h-full overflow-x-auto overflow-y-auto thin-scrollbar'>
            <SummaryTable
                columns={columns}
                data={data}
                tdClassName='whitespace-nowrap !max-w-[10rem]'
                thClassName='whitespace-nowrap'
                // className='!w-auto'
            />
            </div>
        </div>}
    />
}

export default DataPopup