import React, {useState, useEffect } from 'react';
//Components
import Button from '../../Components/Button';
import LeftArrow from '../../Components/CommonIcons/LeftArrow';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteSegments from '../../Routes/RouteSegments';

//packages
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import EclHistory from '../../Components/wizardPage/EclHistory';
import ModelBuilder from '../../Components/wizardPage/ModelBuilder';
import { localStorageKeys } from '../../Utils/Constants';
import { DragAndDropComponent } from '../../Components/wizardPage/DragAndDropComponent';
import { useDispatch, useSelector } from 'react-redux';
import { ModelBuilderList } from '../../Store/action';
import { nextStep, setShowMenu } from '../../Store/Workflow/WorkflowSlice';
import BreadCrumbArrow from '../../Images/Group 166.svg'

const WizardLanding = () => {

  const location = useLocation();
  const navigate= useNavigate();
  const [historyPaneOpen, setHistoryPaneOpen] = useState(false);
  const [modelBuilderOpen,setModelBuilderOpen] = useState(false);
  const [activeIndices, setActiveIndices] = useState([0]);
 
  const actionsClick = (id,isFrom) =>{
    if(isFrom=='history'){
      setHistoryPaneOpen(true)
    }else if(isFrom=='modelBuilder'){
      setModelBuilderOpen(true)
    }else if(isFrom=='existNew' || isFrom=='continue'){
      // localStorage.removeItem(localStorageKeys.currentCalculationDate)
      navigate(RouteSegments.NEW_CALCULATION ,{ state: 
        {
            client_country_id:location?.state?.client_country_id,
            clientId:location?.state?.clientId,
            breadCrumbClientName:location?.state?.breadCrumbClientName,
            breadCrumbCountryName:location?.state?.breadCrumbCountryName,
            breadCrumbLevelOne:location?.state?.breadCrumbLevelOne,
            breadCrumbLevelTwo:location?.state?.breadCrumbLevelTwo,
            levelTwoCategory: location?.state?.levelTwoCategory,
            levelTwoCardId:location?.state?.levelTwoCardId,
            data:location?.state?.data,
            workFlowId:id,
            step:4,
            continue:isFrom=='continue' ? true :false,
            isfromHistory: isFrom=='continue' ? false :true,
            newButtonDisable:true
        }}) 
        
    }else{
      // dispatch(setShowMenu(true))
      // dispatch(nextStep({step:1}))
      navigate(RouteSegments.NEW_CALCULATION ,{ state: 
        {
            client_country_id:location?.state?.client_country_id,
            clientId:location?.state?.clientId,
            breadCrumbClientName:location?.state?.breadCrumbClientName,
            breadCrumbCountryName:location?.state?.breadCrumbCountryName,
            breadCrumbLevelOne:location?.state?.breadCrumbLevelOne,
            breadCrumbLevelTwo:location?.state?.breadCrumbLevelTwo,
            levelTwoCategory: location?.state?.levelTwoCategory,
            data:location?.state?.data,
            levelTwoCardId:location?.state?.levelTwoCardId,
            newClicked:true,
            newButtonDisable:true

        }}) 
        } 
    localStorage.removeItem(localStorageKeys.currentCalculationDate)
    localStorage.removeItem(localStorageKeys.currentWorkFlowId)
    localStorage.removeItem(localStorageKeys.currentTemplateId)
    localStorage.removeItem(localStorageKeys.currentTemplateName)

}

const closeDetails =(isFrom) =>{
  setHistoryPaneOpen(false)
  if(isFrom=='history'){
    setHistoryPaneOpen(false)
  }else if(isFrom=='modelBuilder'){
    setModelBuilderOpen(false)
  }
}

const handleItemClick = (index) => {
  setActiveIndices((prevIndices) => {
    if (prevIndices.includes(index)) {
      return prevIndices.filter((i) => i !== index);
    } else {
      return [...prevIndices, index];
    }
  });
};

const dispatch = useDispatch()

useEffect(()=>{
  dispatch(ModelBuilderList())
},[])

const { ModelBuilderData} = useSelector(state => {
  return {
      ModelBuilderData: state?.ModelBuilder?.modelBuilder,        
  }
})
const { WorkflowNodes} = useSelector(state => {
  return {
    
    WorkflowNodes: state?.ModelBuilder?.workflowNodes,    
  }
})

  return (
    <>
    <div className='w-full bg-white'>
    <div className='flex justify-between xl:py-3 py-2 xl:px-[4rem] px-[3rem] sticky bg-white z-10'>
        <div className='flex gap-2 items-center'>
          <div className='flex gap-2 items-center cursor-pointer' onClick={()=>navigate(RouteSegments.LEVEL_TWO ,{ 
                            state:{
                                labelName:location?.state?.breadCrumbLevelOne,
                                breadCrumbClientName:location?.state?.breadCrumbClientName,
                                breadCrumbCountryName:location?.state?.breadCrumbCountryName,
                                client_country_id:location?.state?.client_country_id,
                                data:location?.state?.data,
                                clientId:location?.state?.data?.clientId,
                            }
                         })} >
          <LeftArrow bgColor={'#d0cece'} className={'xl:w-[25px] xl:h-[25px] w-[20px] h-[20px]'}/>
          <p className='text-gray-light text-[13px] 2xl:text-[15px]'>Back</p>
          </div>
          <div className='text-[13px] 2xl:text-[14px] flex gap-1 items-center text-dark-blue font-medium'>

         
            {location?.state?.breadCrumbClientName}
            <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 
             {location?.state?.breadCrumbCountryName}
             <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 
             {location?.state?.breadCrumbLevelOne}
             <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 
             {location?.state?.breadCrumbLevelTwo}
             <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 
             {location?.state?.levelTwoCategory}
             </div>
        </div>
        <div className='flex gap-4 items-center'>
        <Button 
          bgColor='#5B9BD5' 
          size="xs" 
          label={'ECL history'} 
          color={'#FFFFFF'}                     
          paddingY={'xl:py-1.5 py-1 rounded-[5px]'}
          onClick={()=>actionsClick(location?.state?.clientId,'history')} icon={'histroy'} 
        />                        
        <Button 
          bgColor='#47B4AE' 
          size="xs" 
          label={'Model builder'} 
          color={'#FFFFFF'} 
          onClick={()=>actionsClick(location?.state?.clientId,'modelBuilder')} 
          icon={'modelBuilder'} 
          paddingY={'xl:py-1.5 py-1 rounded-[5px]'}
          />                        
        <Button 
          bgColor='#00B050' 
          size="xs" 
          label={'New'} 
          color={'#FFFFFF'} 
          onClick={()=>actionsClick(location?.state?.clientId,'new')} 
          icon={'new'}
          paddingY={'xl:py-1.5 py-1 rounded-[5px]'}

        />                        
        </div>
        <SlidingPane       
          className="custom-slide-pane-overflow"
          overlayClassName="custom-overlay-class z-[101]"
          isOpen={historyPaneOpen}
          width="68%"
          hideHeader
          shouldCloseOnEsc={true}
          onRequestClose={() => {
            // closeDetails('history')
            setHistoryPaneOpen(true)
          }}
        >
          <EclHistory title={'ECL History'} isOpen={historyPaneOpen} onClose={closeDetails} headerBg={'bg-[#5B9BD5]'} borderColor={'border-[#5B9BD5]'} levelTwoCardId={location?.state?.levelTwoCardId} navigationData={actionsClick}/>

        </SlidingPane>      
        <DragAndDropComponent
          showSummaryLoader={false}
          workflowNodeData={WorkflowNodes}
          modelBuilderOpen={modelBuilderOpen}
          closeDetails={closeDetails}
          sections={ModelBuilderData}
          handleItemClick={handleItemClick}
          activeIndices={activeIndices}
          drag={()=>{console.log('drag');}}
          step={0}
          handleCardClick={()=>console.log('card clicked')}
          isFileSetToS3={false}
          checkWorkFlowData={[]}
          clonedImages={[]}
        />

     </div> 
    </div>
    </>
  )
}

export default WizardLanding;
