import React, { useEffect, useMemo, useState } from 'react'
import SummaryTable from '../Table/SummaryTable'
import { createColumnHelper } from '@tanstack/react-table'
import Button from '../Button'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { downloadExport, getCalculationFormData, getMergeSummaryFileCreation, imputationReplaceAverageDetail, imputationProceed, openModalBuilder, selectCurrentNodeData, selectCurrentNodeIds, selectCurrentEclNodeData, fetchColumnListDatatype, imputationReplaceEarliestDetail } from '../../Store/Workflow/WorkflowSlice'
import useDataTable from '../../hooks/useDataTable'
import DataTableSkeleton from '../Skeleton/DataTableSkeleton'

import XlsIcon from '../../Images/excel.svg'
import Eyeicon from '../../Images/eyeicon.svg'
import EditIcon from '../../Images/Group 809.svg'
import { useFormik } from 'formik'
import { REPLACE_EARLIEST_EXPORT } from '../../Helpers/EndPoints'
import { CircularProgress } from '@mui/material'
import DataTable from '../Table/DataTable'
import { exportReplaceEarliest, saveReplaceEarliest } from '../../Helpers/ApiHelpers'
import DataPopup from '../PopUp/DataPopup'
import DropDown from '../DropDown/DropDown'
import ProceedButton from '../CommonComponents/ProceedButton'
import downloadingIcon from '../../Images/gif/downloading.gif'


const ColumnDownload = ({ workflow_id, ecl_node_id, category_id, column_name, parent_node_id,disabled }) => {

    const [isDownloading, setisDownloading] = useState(false)

    const onDownloadClick = async () => {
        setisDownloading(true)
        const request = {
            workflow_id,
            ecl_node_id,
            category_id,
            column_name,
            is_export: true,
            parent_node_id
        }
        await downloadExport(request, REPLACE_EARLIEST_EXPORT)
        setisDownloading(false)
    }
    return isDownloading
        ? <img src={downloadingIcon} className='w-6' />
        : <img alt='' onClick={onDownloadClick} src={XlsIcon} className={`${disabled ? 'pointer-events-none color-change-image':'cursor-pointer'} w-4 h-4 xl:w-5 xl:h-5`} />
}

const ColumnView = ({ workflow_id, ecl_node_id, category_id, column_name, parent_node_id ,disabled}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [showPopup, setShowPopup] = useState(null)
    const currentEclNodeData = useSelector(selectCurrentEclNodeData)

    const onClick = async () => {
        setIsLoading(true)
        const request = {
            workflow_id,
            ecl_node_id,
            category_id,
            column_name,
            is_export: false,
            parent_node_id
        }
        const response = await exportReplaceEarliest(request)
        setIsLoading(false)
        setShowPopup(response?.data)
    }



    return <>
        {showPopup && <DataPopup
            source={showPopup}
            onClose={() => setShowPopup(null)}
            title={`${currentEclNodeData?.name || ""} - ${column_name}`} />}
        {isLoading
            ? < CircularProgress size={18} />
            : <button onClick={onClick} className={`${disabled ? 'text-[#707070]':'text-[#4F49DF]'}text-[12px] xl:text-[14px] flex items-center gap-2`}>
                <img alt='' src={Eyeicon} className={`w-3 ${disabled ? 'pointer-events-none color-change-image':'cursor-pointer'}`} />
                View
            </button>}
    </>
}



const columnHelper = createColumnHelper()

function ReplaceWithEarliest() {


    const location = useLocation()
    const dispatch = useDispatch()
    const nodeWiseCategory = useSelector(state => state?.Workflow?.nodeWiseCategory)
    const currentNodeData = useSelector(selectCurrentNodeData)
    const isDataLoading = useSelector(state => state?.Workflow?.ui?.isDataLoading)
    const isCalculating = useSelector(state => state?.Workflow?.ui?.isCalculating)
    const filesMerging = useSelector(state => state?.Workflow?.ui?.filesMerging)
    const reduxState = useSelector(state => state)
    const { currentEclNodeId, currentWorkflowId, currentWorkflowNodeId } = useSelector(selectCurrentNodeIds)
    const [isEditMode, setIsEditMode] = useState()
    const baseColumnList = useSelector(state => state?.Workflow?.columnList ?? [])
    const [dataSaving, setDataSaving] = useState(false)

    useEffect(() => {
        if (currentNodeData?.replaceEarliestData?.isEdit == false) {
            setIsEditMode(true)
        }
    }, [currentNodeData?.replaceEarliestData?.isEdit])

    const formik = useFormik({
        initialValues: {
            base_column: currentNodeData?.replaceEarliestData?.replaceValueBasedOnAverageDatas?.map(data => data?.base_column) ?? [],
            replace_based_column: currentNodeData?.replaceEarliestData?.replaceValueBasedOnAverageDatas?.map(data => data?.replace_based_column?.toLowerCase()) ?? []
        },
        enableReinitialize: true,
    })
    
    const { dataTableColumns,
        getFilterOptions,
        summaryData,
        tableState,
        refreshDataTable,
        onTableStateChange,
        isDataLoading: isMergeSummaryLoading,
        isExportLoading,
        donwloadXl,
    } = useDataTable({
        category_id: currentNodeData?.nodeCategoryId,
        ecl_node_id: currentEclNodeId,
        workflow_id: currentWorkflowId,
        from: "imputationReplaceBasedOnEarliestDatas",
        parent_node_id: currentNodeData?.parent_node_id
    })


    const columnDropdownValues = useMemo(() => {
        let formattedValues = []

         baseColumnList?.forEach(data => {
            formattedValues.push({
                label: data?.column_name,
                value: data?.column_name,
                data: data,
            })
        })
        return formattedValues

    }, [baseColumnList])

    const onBaseColumnChange = (index, value) => {
        formik.setFieldValue(`base_column.${index}`, value)
    }
    const onReplaceBasedColumnChange = (index, value) => {
        formik.setFieldValue(`replace_based_column.${index}`, value)
    }

    const summaryColumns = useMemo(() => {
        if (isEditMode) {
            return [
                columnHelper.accessor("exception_analysis_method", {
                    header: "Exception Analysis",
                    mergeCells: true,
                    tdClassName: "!py-2.5 !p-2",

                }),
                columnHelper.accessor("column_name", {
                    header: "Destination Column",
                    tdClassName: "!py-2.5 !p-2",

                }),
                columnHelper.display({
                    id: "baseColumn",
                    header: "Base column",
                    tdClassName: "!py-2.5 !p-2",
                    cell: ({ row }) => {
                        const dataType = row?.original?.column_datatype;
                        const currentBaseColumn = formik.values.base_column[row.index];
                        const currentEarliestValue = formik.values.replace_based_column[row.index];
    
                        const baseColumnOptions = columnDropdownValues.map(data => {
                            return {
                                label: data?.label,
                                value: data?.value,
                                disabled: data?.value === row.original.column_name || data?.value === currentEarliestValue,
                            };
                        });
    
                        return (
                            <div className='w-[14rem]'>
                                <DropDown
                                    dropDownOptions={baseColumnOptions}
                                    placeholder={'Select column'}
                                    name={`base_column.${row.index}`}
                                    ordinary={true}
                                    border={'1px solid #E0DCD7'}
                                    disabled={false}
                                    customTags={false}
                                    value={currentBaseColumn || undefined}
                                    onOptionChange={(value) => onBaseColumnChange(row.index, value, dataType)}
                                />
                            </div>
                        );
                    }
                }),
                columnHelper.display({
                    id: "earliest_value",
                    header: "Column based on which earliest value will be identified",
                    tdClassName: "!py-2.5 !p-2",
                    thClassName: "whitespace-nowrap w-[20rem] pr-4",
                    cell: ({ row }) => {
                        const dataType = row?.original?.column_datatype;
                        const currentBaseColumn = formik.values.base_column[row.index];
                        const currentEarliestValue = formik.values.replace_based_column[row.index];
    
                        const earliestValueOptions = columnDropdownValues.map(data => {
                            return {
                                label: data?.label,
                                value: data?.value,
                                disabled: data?.value === row.original.column_name || data?.value === currentBaseColumn,
                            };
                        });
    
                        return (
                            <div className='min-w-[14rem]'>
                                <DropDown
                                    dropDownOptions={earliestValueOptions}
                                    placeholder={'Select column for identifying earliest value'}
                                    name={`replace_based_column.${row.index}`}
                                    ordinary={true}
                                    border={'1px solid #E0DCD7'}
                                    disabled={false}
                                    customTags={false}
                                    value={currentEarliestValue || undefined}
                                    onOptionChange={(value) => onReplaceBasedColumnChange(row.index, value, dataType)}
                                />
                            </div>
                        );
                    }
                }),
                columnHelper.accessor("empty", {
                    header: "",
                    thClassName: "w-[5rem]",

                }),
            ]
        } else {
            return [
                columnHelper.accessor("exception_analysis_method", {
                    header: "Exception Analysis",
                    mergeCells: true,
                    thClassName: "whitespace-nowrap w-[10rem]",
                }),
                columnHelper.accessor("column_name", {
                    header: "Destination Column",
                    thClassName: "whitespace-nowrap w-[10rem]",
                }),
                columnHelper.accessor("base_label", {
                    header: "Base column",
                    thClassName: "whitespace-nowrap w-[10rem]",
                }),
                columnHelper.accessor("replace_based_column_label", {
                    header: "Column for identifying earliest value",
                    thClassName: "whitespace-nowrap w-[16rem]", 
                    cell: ({ row }) => (<span>{row.original?.replace_based_column_label != "" ? row.original?.replace_based_column_label : '-'}</span>)
                   
                }),
                columnHelper.accessor("no_of_modified_values", {
                    header: "No. of modified values",
                    thClassName: "whitespace-nowrap w-[10rem]",

                }),
                columnHelper.accessor("percentage_of_modified_values", {
                    header: "Percentage of modified values",
                    thClassName: "w-[200px]",
                    cell: ({ row }) => (<span>{row.original?.percentage_of_modified_values ? row.original?.percentage_of_modified_values + " %" : '-'}</span>)
                }),


                columnHelper.display({
                    id: "Actions",
                    header: "Actions",
                    thClassName: "w-[120px]",
                    cell: ({ row }) => {
                        return <div className='space-x-4 flex items-center justify-between'>
                            <ColumnView
                                category_id={currentNodeData?.nodeCategoryId}
                                column_name={row.original.column_name}
                                ecl_node_id={currentEclNodeId}
                                workflow_id={currentWorkflowId}
                                disabled={row.original.no_of_modified_values == 0}
                                parent_node_id={currentNodeData?.parent_node_id}
                            />
                            <ColumnDownload
                                category_id={currentNodeData?.nodeCategoryId}
                                column_name={row.original.column_name}
                                ecl_node_id={currentEclNodeId}
                                workflow_id={currentWorkflowId}
                                disabled={row.original.no_of_modified_values == 0}
                                parent_node_id={currentNodeData?.parent_node_id}
                            />
                        </div>
                    }

                }),
            ]
        }

    }, [isEditMode, columnDropdownValues, formik.values.base_column, formik.values.replace_based_column]);

    const summaryTableData = useMemo(() => {
        return currentNodeData?.replaceEarliestData?.replaceValueBasedOnEarliestDatas ?? []
    }, [currentNodeData?.replaceEarliestData?.replaceValueBasedOnEarliestDatas]);


    useEffect(() => {       
        dispatch(getMergeSummaryFileCreation({
            workflow_id: currentWorkflowId,
            ecl_node_id: currentEclNodeId,
        })).then(res => {
            if (res.type == `${getMergeSummaryFileCreation.fulfilled}`) {
                refreshDataTable()
                dispatch(imputationReplaceEarliestDetail({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": currentNodeData?.nodeCategoryId,
                    "parent_node_id": currentNodeData?.parent_node_id,
                }))
                .then(response => {
                    if (response.type == `${imputationReplaceEarliestDetail.fulfilled}`) {
                        dispatch(fetchColumnListDatatype({
                            "workflow_id": currentWorkflowId,
                            "ecl_node_id": currentEclNodeId,
                            "category_id": currentNodeData?.nodeCategoryId,
                            "data_type": ["Integer","Date"],
                    }))
             }
            })
            }
        })        
    }, [])

    const onProceedClick = async () => {
        if (currentNodeData?.node_status == 'Completed') {
            return dispatch(openModalBuilder())
        }

        const requestFormData = getCalculationFormData({
            client_branch_level_id: location?.state?.levelTwoCardId,
            isFrom: "DataImputationReplaceEarliest",
            category_id: currentNodeData?.nodeCategoryId,
            node_id: currentEclNodeId,
            state: reduxState,
            nodeStatus: 'Completed'
        })

        dispatch(imputationProceed({
            requestFormData,

        }))

            .then(res => {
                if (res.type == `${imputationProceed.fulfilled}`) {
                    setIsEditMode(false)
                    refreshDataTable()
                    dispatch(imputationReplaceEarliestDetail({
                        "workflow_id": currentWorkflowId,
                        "ecl_node_id": currentEclNodeId,
                        "category_id": currentNodeData?.nodeCategoryId,
                        "parent_node_id": currentNodeData?.parent_node_id,
                    }))
                }
            })

    }

    const onSave = async () => {
        setDataSaving(true)
        const request = {
            "ecl_workflow_id": currentWorkflowId,
            "ecl_node_id": currentEclNodeId,
            "ecl_category_id": currentNodeData?.nodeCategoryId,
            "parent_node_id": currentNodeData?.parent_node_id,
            "replaceBasedOnEarliestDatas": currentNodeData?.replaceEarliestData?.replaceValueBasedOnEarliestDatas?.map((data, index) => ({
                "column_name": data?.column_name,
                "base_column": formik.values.base_column[index] ?? "",
                "replace_based_column" : formik.values.replace_based_column[index] ?? ""
            }))
        }
        const response = await saveReplaceEarliest(request)
        if (response.data.status == 200) {
            setIsEditMode(false)
            refreshDataTable()
            dispatch(imputationReplaceEarliestDetail({
                "workflow_id": currentWorkflowId,
                "ecl_node_id": currentEclNodeId,
                "category_id": currentNodeData?.nodeCategoryId,
                "parent_node_id": currentNodeData?.parent_node_id,
            }))
            setDataSaving(false)


        }
        console.log(response, 'response data');

    }

    const onEditClick = () => {
        setIsEditMode(prev => !prev)
    }

    const renderTable = () => {
        if (isDataLoading || dataSaving) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        const disableSave = summaryTableData?.length != formik?.values?.base_column.length || summaryTableData?.length == 0
        if (currentNodeData?.nodeCategoryId) {
            return <>
                <div className={`mt-5`}>
                    {isEditMode
                        ? <div className='flex gap-3 items-center py-2'>
                                <button className='text-[#ffffff]  py-0.5 px-2 text-[12px] bg-[#FFC048] rounded-[4px] whitespace-nowrap'>Data category</button>
                                <p className='font-semibold text-[12px]'>{currentNodeData?.replaceEarliestData?.categoryName}</p>
                        </div>
                        : <div className='2xl:py-3 py-1.5 px-2 bg-[#4339A8] grid grid-cols-3 items-center'>
                            <div className=' flex gap-3 items-center col-span-1'>
                                <button className='text-[#ffffff]  py-0.5 px-2 text-[12px] bg-[#FFC048] rounded-[4px] whitespace-nowrap'>Data category</button>
                                <p className='text-[#ffffff] font-semibold text-[12px] whitespace-nowrap'>{currentNodeData?.replaceEarliestData?.categoryName}</p>
                            </div>
                            <h2 className='text-white text-[12px] font-semibold text-center w-full col-span-1'>Calculated output</h2>
                            { currentNodeData?.node_status == 'Completed' ? null
                            : <div className={`col-span-1 flex justify-end gap-1 items-center mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={() => setIsEditMode(true)}>
                                <img alt='' src={EditIcon} className='w-4 h-4' />
                                <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
                            </div>
                           }       
                           
                        </div>}
                </div>
                <div className={`overflow-y-auto overflow-x-auto thin-scrollbar ${isEditMode ? "" : ""}`}>
                    <SummaryTable
                        key={""}
                        columns={summaryColumns}
                        data={summaryTableData}
                        thClassName={` ${isEditMode ? "!bg-[#4339A8]" : ""} `}
                        tdClassName={`${isEditMode ? "p-4" : ""}`}
                    />
                </div>
                {isEditMode
                    ? <div className='flex justify-center my-4'>
                        <Button
                            bgColor='#42B2AC'
                            borderType=""
                            disabled={disableSave}
                            label={'Save'}
                            color={'#ffffff'}
                            onClick={onSave}
                            size={'md'} />
                    </div>
                    : ""}
            </>
        }

    }


    const renderResultTable = () => {

        if (!currentNodeData?.nodeCategoryId) return

        if (isDataLoading || isMergeSummaryLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        const mergeSummaryTableData = summaryData

        console.log({ summaryData });

        return <div className='mt-5'>

            <div className='flex justify-end items-center'>
                {isExportLoading
                    ? <img src={downloadingIcon} className='w-6 mr-2' />
                    : <img alt='' src={XlsIcon} className=' mb-2 object-cover mr-2 cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
            </div>

            <DataTable
                columns={dataTableColumns}
                data={mergeSummaryTableData?.columnDatas ?? []}
                onTableStateChange={onTableStateChange}
                totalCount={mergeSummaryTableData?.totalRecords}
                isOptionsDisabled={location?.state?.isfromHistory}
                getFilterOptions={getFilterOptions} 
                thClassName={"2xl:max-w-[14rem] max-w-[13rem] min-w-fit"}
                td={"2xl:max-w-[11rem] max-w-[10rem] min-w-fit"}
                tableState={{
                    pagination: {
                        pageIndex: tableState.pageIndex,
                        pageSize: tableState.pageSize,
                    },
                    sorting: tableState?.sortBy ? [
                        {
                            id: tableState?.sortBy,
                            desc: tableState?.sortOrder === "desc"
                        }
                    ] : [],
                    columnFilters: tableState.columnFilters
                        ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                        : [],
                    columnPinning: tableState.columnPinning,
                    columnVisibility: tableState?.columnVisibility,
                }}
            />
            {currentNodeData?.replaceEarliestData?.isEdit == false ? null :
                <div className={`float-right 2xl:my-6 my-3 ${location?.state?.isfromHistory || currentNodeData?.node_status == 'Completed' ? 'hidden' : 'block'}`}>
                    <ProceedButton
                        onClick={onProceedClick}
                    />
                   
                </div>
            }

        </div>
    }

    return (
        filesMerging || isCalculating
            ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            : <div className='my-2'>
                {renderTable()}
                {renderResultTable()}
            </div>
    )
}

export default ReplaceWithEarliest