import React from 'react'

const NewPlusIcon = ({width,height,bgColor,className}) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={className} width={width} height={height} viewBox="0 0 20 20">
  <g id="Group_190" data-name="Group 190" transform="translate(1081.947 -585.821)">
    <path id="Path_162" data-name="Path 162" d="M-1071.95,585.821a10,10,0,0,1,10,10.028,10,10,0,0,1-10.018,9.972,10,10,0,0,1-9.982-10.049A10.006,10.006,0,0,1-1071.95,585.821Zm1.337,11.334h.25c1.007,0,2.014,0,3.021,0a1.641,1.641,0,0,0,.47-.067,1.33,1.33,0,0,0,.918-1.436,1.334,1.334,0,0,0-1.317-1.16c-1.035-.005-2.069,0-3.1,0h-.237v-.25c0-1.007,0-2.014,0-3.021a1.645,1.645,0,0,0-.068-.47,1.327,1.327,0,0,0-1.436-.918,1.336,1.336,0,0,0-1.16,1.317c-.005,1.035,0,2.069,0,3.1v.238h-.249c-1.007,0-2.014,0-3.021,0a1.641,1.641,0,0,0-.47.067,1.329,1.329,0,0,0-.918,1.436,1.336,1.336,0,0,0,1.317,1.161c1.035.005,2.069,0,3.1,0h.238v.249c0,1.035-.006,2.069,0,3.1a1.324,1.324,0,0,0,1.605,1.28,1.317,1.317,0,0,0,1.06-1.312C-1070.61,599.379-1070.613,598.283-1070.613,597.155Z" 
    fill={bgColor}/>
  </g>
</svg>

  )
}

export default NewPlusIcon
