import React, { useEffect, useMemo, useState } from 'react'
import { createColumnHelper } from '@tanstack/react-table'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
//imgaes
import XlsIcon from '../../Images/excel.svg'
import EditIcon from '../../Images/Group 809.svg'
import Eyeicon from '../../Images/eyeicon.svg'
import CalenderIcon from '../CommonIcons/CalenderIcon'
import { CircularProgress } from '@mui/material'
import downloadingIcon from '../../Images/gif/downloading.gif'


// components 
import Button from '../Button'
import DataTable from '../Table/DataTable'
import DataPopup from '../PopUp/DataPopup'
import ProceedButton from '../CommonComponents/ProceedButton'
import DropDown from '../DropDown/DropDown'
import SummaryTable from '../Table/SummaryTable'
import useDataTable from '../../hooks/useDataTable'
import DataTableSkeleton from '../Skeleton/DataTableSkeleton'


// packages
import { useFormik } from 'formik'
import dayjs from "dayjs"
import { DatePicker } from 'antd'
import moment from 'moment';
//redux

import { IMPUTATION_REPLACE_MAPPING_EXPORT } from '../../Helpers/EndPoints'
import { downloadExport, getCalculationFormData, getMergeSummaryFileCreation, imputationProceed, selectCurrentNodeData, selectCurrentNodeIds, openModalBuilder, imputationReplaceMappingDetail} from '../../Store/Workflow/WorkflowSlice'
import {exportImputationReplaceMapping, getSplitDataColumnDropdown,getUniqueValuesInColumn,saveImputationReplaceMapping} from '../../Helpers/ApiHelpers'



const ColumnDownload = ({ workflow_id, ecl_node_id, category_id, column_name, parent_node_id,base_column_name,base_column_unique_value,disabled }) => {

    const [isDownloading, setisDownloading] = useState(false)

    const onDownloadClick = async () => {
        setisDownloading(true)
        const request ={
            workflow_id,
            ecl_node_id,
            category_id,
            column_name,
            is_export: true,
            parent_node_id,
            base_column_name,
            base_column_unique_value
        }
        await downloadExport(request, IMPUTATION_REPLACE_MAPPING_EXPORT)
        setisDownloading(false)
    }
    return isDownloading
        ? <img src={downloadingIcon} className='w-6' />
        : <img alt='' onClick={onDownloadClick} src={XlsIcon} className={`${disabled ? 'pointer-events-none color-change-image':'cursor-pointer '} w-4 h-4 xl:w-5 xl:h-5`} />
}

const ColumnView = ({ workflow_id, ecl_node_id, category_id, column_name, parent_node_id ,base_column_name,base_column_unique_value,disabled}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [showPopup, setShowPopup] = useState(null)

    const onClick = async () => {
        setIsLoading(true)
        const request = {
            workflow_id,
            ecl_node_id,
            category_id,
            column_name,
            base_column_unique_value,
            base_column_name,
            is_export: false,
            parent_node_id
        }
        const response = await exportImputationReplaceMapping(request)
        setIsLoading(false)

        if (response?.status == 200) {

        }
        setShowPopup(response?.data)
    }


    return <>
        {showPopup && <DataPopup
            source={showPopup}
            onClose={() => setShowPopup(null)}
            popupClassName={'min-w-[80vw]'}
            title={`Replace with most frequently occurring value based on another column - ${column_name}`} />}
        {isLoading
            ? < CircularProgress size={18} />
            : <button onClick={onClick} disabled={disabled} className={`${disabled ? 'text-[#707070]':'text-[#4F49DF]'} text-[12px] xl:text-[14px] flex items-center gap-2`}>
                <img alt='' src={Eyeicon} className={`w-3 ${disabled ? 'pointer-events-none color-change-image':'cursor-pointer'}`} />
                View
            </button>}
    </>
}

const columnHelper = createColumnHelper()

function MappingBasedOnDiffColumn() {


    const location = useLocation()
    const dispatch = useDispatch()
    const currentNodeData = useSelector(selectCurrentNodeData)
    const isDataLoading = useSelector(state => state?.Workflow?.ui?.isDataLoading)
    const isCalculating = useSelector(state => state?.Workflow?.ui?.isCalculating)
    const filesMerging = useSelector(state => state?.Workflow?.ui?.filesMerging)
    const reduxState = useSelector(state => state)
    const { currentEclNodeId, currentWorkflowId } = useSelector(selectCurrentNodeIds)
    const [isEditMode, setIsEditMode] = useState();
    const [columnsList,setColumnsList] = useState([])
    const [summaryTableData, setSummaryTableData]= useState([])

    useEffect(() => {
        if (currentNodeData?.replaceMappingData?.isEdit == false) {
            setIsEditMode(true)
        }
    }, [currentNodeData?.replaceMappingData?.isEdit])


    const formik = useFormik({
        initialValues: {
            baseColumn: currentNodeData?.replaceMappingData?.mappedValuesDatas?.map(data => data?.base_column_value),
            replacement_value : currentNodeData?.replaceMappingData?.mappedValuesDatas?.map(data => data?.replacement_value)
        },
        enableReinitialize: true,
    })

    const { dataTableColumns,
        getFilterOptions,
        summaryData,
        tableState,
        refreshDataTable,
        onTableStateChange,
        isDataLoading: isMergeSummaryLoading,
        isExportLoading,
        donwloadXl,
    } = useDataTable({ category_id: currentNodeData?.nodeCategoryId, ecl_node_id: currentEclNodeId, parent_node_id:currentNodeData?.parent_node_id, workflow_id: currentWorkflowId, from: "diMappedValuesDifferentColumnDatas" })

    const onBaseColumnChange = async (index, value, destinationColumn) => {
        const selectedColumn = columnsList.find(col => col.column_name === value);
    
        const request = {
            "workflow_id": currentWorkflowId,
            "ecl_node_id": currentEclNodeId,
            "category_id": currentNodeData.nodeCategoryId,
            "column_name": selectedColumn.column_name
        };
    
        try {
            const uniqueValuesRes = await getUniqueValuesInColumn(request);
            if (uniqueValuesRes.data.status === 200) {
                const uniqueValues = uniqueValuesRes.data.datas;

                setSummaryTableData(prevData => {
                    // Filter out previous entries for the destination column
                    const filteredData = prevData.filter(row => row.column_name !== destinationColumn);
    
                    // Create new rows with unique values
                    const newRows = uniqueValues.map(uniqueValue => ({
                        ...prevData[index],
                        base_column_unique_value: uniqueValue,
                        base_column_name: selectedColumn.column_name,
                        base_column_label: selectedColumn.alias_name,
                    }));
                                 
                    // Find the index where the existing rows for the column were located
                    const existingIndex = prevData.findIndex(row => row.column_name === destinationColumn);
    
                    // Insert new rows at the appropriate position
                    if (existingIndex !== -1) {
                        // Insert new rows at the position where the existing rows were located
                        return [
                            ...filteredData.slice(0, existingIndex),
                            ...newRows,
                            ...filteredData.slice(existingIndex),
                        ];
                    } else {
                        // Insert new rows at the current index position
                        return [
                            ...filteredData.slice(0, index),
                            ...newRows,
                            ...filteredData.slice(index),
                        ];
                    }
                });
            }
        } catch (error) {
            console.error('Error fetching unique values:', error);
        }
    };

    useEffect(()=>{
        if(summaryTableData?.length > 0){
            summaryTableData.map((val,i)=>{
                formik.setFieldValue(`baseColumn.${i}`, val.base_column_name)

            })
        }

    },[summaryTableData])

    
    const onChangeReplacementValue = (index, value) => {
        formik.setFieldValue(`replacement_value.${index}`, value)

        setSummaryTableData(prevData => {
            const updatedData = [...prevData];
            updatedData[index] = {
                ...updatedData[index],
                replacement_value: value
            };
            return updatedData;
        });
    };
    
    const getValueByIndex = index => formik.values.base_column_name?.at(index)
    const disabledDate = current => {
        return current && current > moment().endOf('day');
      };
   
    const summaryColumns = useMemo(() => {
        if (isEditMode) {
            return [
                columnHelper.accessor("exception_analysis_method", {
                    header: "Exception Analysis",
                    mergeCells: true,
                    thClassName: "w-[14rem]",
                }),
                columnHelper.accessor("column_name", {
                    header: "Destination Column",
                    tdClassName: "!py-2.5 !px-2",
                    thClassName: "w-[10rem]",
                    mergeCells: true,

                }),
                columnHelper.display({
                    id: "baseColumn",
                    header: "Base column",
                    tdClassName: "!py-2.5 !px-2",
                    thClassName: "w-[12.5rem]",
                    mergeCells:true,
                    anotherColumn: "column_name",
                    
                    cell: ({ row }) => {
                        const filteredData = columnsList?.filter(val => 
                            val?.data_type_label?.toLowerCase() === (row.original.assigned_data_type ? row.original.assigned_data_type?.toLowerCase() : row.original.column_datatype?.toLowerCase()) &&
                            val?.column_name !== row.original.column_name
                        );
    
                        const dropDownOptions = filteredData?.map(option => ({
                            label: option.alias_name,
                            value: option.column_name,
                        })) || [];

                        return <DropDown
                        dropDownOptions={dropDownOptions}
                        placeholder={'Select Base Column'}
                        name={'baseColumn'}
                        border={'1px solid #E0DCD7'}
                        ordinary={true}
                        disabled={false}
                        customTags={false}
                        value={row.original.base_column_name ?? undefined}
                        onOptionChange={(value) => onBaseColumnChange(row.index, value,row.original.column_name)}
                    />
                    }

                }), 
                columnHelper.accessor("base_column_unique_value", {
                    header: "Unique value",
                    tdClassName: "!py-2.5 !px-2 pl-6",
                    thClassName: "w-[10rem] pl-6",
                }),                                 
                columnHelper.display({
                    id: "replacement_value",
                    header: "Replacement value",
                    tdClassName: "!py-2.5 !px-2",
                    thClassName: "w-[12rem]",                    
                    cell: ({ row }) => {
                        
                    if(row.original.base_column_unique_value){                        
                        const value = getValueByIndex(row.index) || undefined
                        const dataType = row.original?.assigned_data_type
                        if (dataType == "TEXT") {
                            return <input
                                className={`place text-[12px] 2xl:text-[13px] !border-[1px] border-[#E0DCD7] w-full rounded h-[2rem] 2xl:h-[2.2rem]  focus:outline-none pl-4 placeholder:text-[#BEBEBE] `}
                                placeholder="Enter replacement value"
                                onChange={(e) => onChangeReplacementValue(row.index, e.target.value, dataType)}
                                defaultValue={row.original?.replacement_value}
                                value={value}
                            />
                        } else if (dataType == "BOOLEAN") {
                            return <DropDown
                                dropDownOptions={row.original?.boolean_values?.map(val => ({ value: `${val}`, label: `${val}` }))}
                                placeholder={'Select replacement value'}
                                name={'replacementValue'}
                                border={'1px solid #E0DCD7'}
                                ordinary={true}
                                disabled={false}
                                customTags={false}
                                value={value || undefined}
                                onOptionChange={(value) => onChangeReplacementValue(row.index, value, dataType)}
                            />
                        } else if (dataType == "DATE") {
                            return <DatePicker
                                value={value ? dayjs(value, 'DD-MMM-YYYY') : undefined}
                                onBlur={formik.handleBlur}
                                showSearch={false}
                                allowClear={false}
                                size="small"
                                className="custom-select-height text-[11px] pl-[0.5rem] custom-disabled-datepicker placeholder:text-[#242B3B]"
                                style={{
                                    width: "100%",
                                    border: " 1px solid #E0DCD7",
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    backgroundColor: '#ffffff',
                                }}
                                placeholder="Select replacement date"
                                showToday={false}
                                disabledDate={disabledDate}

                                onChange={(date) => {
                                    const val = dayjs(date).format('YYYY-MM-DD')
                                    onChangeReplacementValue(row.index, val, dataType)
                                }
                                }
                                format={"YYYY-MM-DD"}
                                suffixIcon={<CalenderIcon color="#707070" className={"xl:w-[15px] xl:h-[15px] w-[12px] h-[12px]"} />}
                            />
                        } else {
                            return <input
                                className={`place text-[12px] 2xl:text-[13px] !border-[1px] border-[#E0DCD7] w-full rounded  h-[2rem] 2xl:h-[2.2rem]  focus:outline-none pl-4 placeholder:text-[#BEBEBE] `}
                                placeholder="Enter replacement value"
                                onChange={(e) => onChangeReplacementValue(row.index, e.target.value, dataType)}
                                defaultValue={row.original?.replacement_value}
                                value={value}
                            />
                        }
                    }
                }

                }),
                            
            ]
        } else {
            return [
                columnHelper.accessor("exception_analysis_method", {
                    header: "Exception Analysis",
                    thClassName:"whitespace-nowrap w-[12rem]",
                    mergeCells: true,
                }),
                columnHelper.accessor("column_label", {
                    header: "Destination Column",
                    thClassName:"whitespace-nowrap w-[10rem]",
                    mergeCells: true,
                    anotherColumn:"exception_analysis_method",

                }),
                columnHelper.accessor("base_column_label", {
                    header: "Base column",
                    thClassName:"whitespace-nowrap w-[10rem]",
                    mergeCells: true,
                    anotherColumn:"exception_analysis_method",

                }),   
                columnHelper.accessor("base_column_unique_value", {
                    header: "Unique value",
                    thClassName:"whitespace-nowrap w-[10rem]",
                }),                                 
                columnHelper.accessor("no_of_modified_values", {
                    header: "No. of modified values",
                    thClassName:"whitespace-nowrap w-[10rem]"
                }),
                columnHelper.accessor("replacement_value", {
                    header: "Replacement value",
                    thClassName:"whitespace-nowrap w-[10rem]"
                }),
                columnHelper.accessor("percentage_of_modified_values", {
                    header: "%of modified values",
                    thClassName: "w-[150px]",
                    cell: ({ row }) => (<span>{row.original?.percentage_of_modified_values ? row.original?.percentage_of_modified_values + " %"  : '-'}</span>)
                }),


                columnHelper.display({
                    id: "Actions",
                    header: "Actions",
                    thClassName: "w-[120px]",
                    cell: ({ row }) => {
                        return <div className='space-x-4 flex items-center justify-between'>
                            <ColumnView
                                category_id={currentNodeData?.nodeCategoryId}
                                column_name={row.original.column_name}
                                ecl_node_id={currentEclNodeId}
                                workflow_id={currentWorkflowId}
                                disabled={row.original.no_of_modified_values == 0}
                                parent_node_id={currentNodeData?.parent_node_id}
                                base_column_name={row.original.base_column_name}
                                base_column_unique_value={row.original.base_column_unique_value}
                            />
                            <ColumnDownload
                                category_id={currentNodeData?.nodeCategoryId}
                                column_name={row.original.column_name}
                                ecl_node_id={currentEclNodeId}
                                workflow_id={currentWorkflowId}
                                disabled={row.original.no_of_modified_values == 0}
                                parent_node_id={currentNodeData?.parent_node_id}
                                base_column_name={row.original.base_column_name}
                                base_column_unique_value={row.original.base_column_unique_value}
                            />
                        </div>
                    }

                }),
            ]
        }

    }, [isEditMode,columnsList])


    useEffect(() => {
         if(currentNodeData?.replaceMappingData?.mappedValuesDatas) {
            setSummaryTableData(currentNodeData?.replaceMappingData?.mappedValuesDatas)
         }
    }, [currentNodeData?.replaceMappingData?.mappedValuesDatas]);

    useEffect(() => {
        dispatch(getMergeSummaryFileCreation({
            workflow_id: currentWorkflowId,
            ecl_node_id: currentEclNodeId,
        })).then(res => {
            if (res.type == `${getMergeSummaryFileCreation.fulfilled}`) {
                refreshDataTable()
                dispatch(imputationReplaceMappingDetail({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": currentNodeData?.nodeCategoryId,
                    "parent_node_id": currentNodeData?.parent_node_id,
                }))
            }
        })
    }, [])

    useEffect(() => {
        if (currentNodeData?.nodeCategoryId && currentNodeData?.replaceMappingData?.isEdit == false) {
            const getColumnDropdown = async() => {
                const response  = await getSplitDataColumnDropdown({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": currentNodeData?.nodeCategoryId,
                    "data_type": ["Boolean","Integer","Date","Text"],
                })
                if(response?.data?.status == 200){
                    setColumnsList(response?.data?.columns)
                }
            }   
            getColumnDropdown()       
        }        
        
    }, [currentNodeData?.nodeCategoryId, currentNodeData?.replaceMappingData?.isEdit])

    const onProceedClick = async () => {
        if (currentNodeData?.node_status == 'Completed') {
            return dispatch(openModalBuilder())
        }

        const requestFormData = getCalculationFormData({
            client_branch_level_id: location?.state?.levelTwoCardId,
            isFrom: "mappedValueDatas",
            category_id: currentNodeData?.nodeCategoryId,
            node_id: currentEclNodeId,
            state: reduxState,
            nodeStatus:'Completed'
        })

        dispatch(imputationProceed({
            requestFormData,            
        })).then(res => {
            if (res.type == `${imputationProceed.fulfilled}`) {
                setIsEditMode(false)
                refreshDataTable()
                dispatch(imputationReplaceMappingDetail({
                    "workflow_id": currentWorkflowId,
                    "ecl_node_id": currentEclNodeId,
                    "category_id": currentNodeData?.nodeCategoryId,
                    "parent_node_id": currentNodeData?.parent_node_id,
                }))
            }
        })

    }

    const onSave = async ()=>{
        const request = {
            "ecl_workflow_id": currentWorkflowId,
            "ecl_node_id": currentEclNodeId,
            "ecl_category_id": currentNodeData?.nodeCategoryId,
            "parent_node_id": currentNodeData?.parent_node_id,
            "mappedValueDatas": summaryTableData
        }
        
             const response = await saveImputationReplaceMapping(request)
             if(response.data.status ==200){
                setIsEditMode(false)
                        refreshDataTable()
                        dispatch(imputationReplaceMappingDetail({
                            "workflow_id": currentWorkflowId,
                            "ecl_node_id": currentEclNodeId,
                            "category_id": currentNodeData?.nodeCategoryId,
                            "parent_node_id": currentNodeData?.parent_node_id,
                        }))
             }
    }

    const onEditClick = () => {
        setIsEditMode(prev => !prev)
    }


    const renderTable = () => {
        if (isDataLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        const disableSave = summaryTableData?.some(data => data?.replacement_value == '' || data?.replacement_value == null) || summaryTableData?.length == 0
        // if (currentNodeData?.nodeCategoryId) {
            return <>
                <div className={`mt-5`}>
                    {isEditMode
                        ? <div className='flex gap-3 items-center py-2'>
                            <button className='text-white bg-[#F7BE7E] text-[12px] py-1 px-3 rounded'>Data category</button>
                            <p className='font-semibold text-[12px]'>{currentNodeData?.replaceMappingData?.categoryName}</p>
                        </div>
                        : <div className='2xl:py-3 py-1.5 px-2 bg-[#4339A8] grid grid-cols-3 items-center'>
                            <div className='col-span-1 flex gap-3 items-center'>
                                <button className='text-white bg-[#F7BE7E] py-0.5 px-2 text-[12px] rounded whitespace-nowrap'>Data category</button>
                                <p className='text-[#ffffff] font-semibold text-[12px]'>{currentNodeData?.replaceMappingData?.categoryName}</p>
                            </div>
                            <h2 className='col-span-1 text-white text-[12px] font-semibold text-center w-full'>Calculated output</h2>   
                            {currentNodeData?.node_status == 'Completed'
                                ? null
                                : <div className={`col-span-1 flex justify-end gap-1 items-center mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={onEditClick}>
                                    <img alt='' src={EditIcon} className='w-4 h-4' />
                                    <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
                                </div>}
                        </div>}
                </div>
                <div className={`overflow-y-auto thin-scrollbar ${isEditMode ? "" : ""}`}>
                    <SummaryTable
                        key={""}
                        columns={summaryColumns}
                        data={summaryTableData}
                        thClassName={` ${isEditMode ? "!bg-[#4339A8]" : ""} `}
                        tdClassName={`${isEditMode ? "p-4" : ""}`}
                    />
                </div>
                {isEditMode ?
                     <div className='flex justify-center my-4'>
                        <Button
                            bgColor='#42B2AC'
                            borderType=""
                            disabled={disableSave}
                            label={'Save'}
                            color={'#ffffff'}
                            onClick={onSave}
                            size={'md'} />
                    </div>
                    :""
                    }
            </>
        // }

    }

    const renderResultTable = () => {

        if (!currentNodeData?.nodeCategoryId) return

        if (isDataLoading || isMergeSummaryLoading) {
            return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }

        const mergeSummaryTableData = summaryData

        console.log({ summaryData });

        return <div className='mt-5'>

            <div className='flex justify-end items-center'>
                {isExportLoading
                    ? <img src={downloadingIcon} className='w-6 mr-2' />
                    : <img alt='' src={XlsIcon} className=' mb-2 object-cover mr-2 cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
            </div>

            <DataTable
                columns={dataTableColumns}
                data={mergeSummaryTableData?.columnDatas ?? []}
                onTableStateChange={onTableStateChange}
                totalCount={mergeSummaryTableData?.totalRecords}
                isOptionsDisabled={location?.state?.isfromHistory}
                getFilterOptions={getFilterOptions}
                tableState={{
                    pagination: {
                        pageIndex: tableState.pageIndex,
                        pageSize: tableState.pageSize,
                    },
                    sorting: tableState?.sortBy ? [
                        {
                            id: tableState?.sortBy,
                            desc: tableState?.sortOrder === "desc"
                        }
                    ] : [],
                    columnFilters: tableState.columnFilters
                        ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                        : [],
                    columnPinning: tableState.columnPinning,
                    columnVisibility: tableState?.columnVisibility,
                }}
            />
            <div className={`float-right 2xl:my-6 my-3 ${location?.state?.isfromHistory || currentNodeData?.node_status == 'Completed' || currentNodeData?.mostFrequentlyOccuringData?.isEdit == false ? 'hidden' : 'block'}`}>
                <ProceedButton
                 onClick={onProceedClick}
                />                

            </div>
        </div>
    }

    return (
        filesMerging || isCalculating
            ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            :
             <div className='my-2'>
                {renderTable()}
                {renderResultTable()}
            </div>
    )
}

export default MappingBasedOnDiffColumn