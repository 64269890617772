import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createDuplicateColumnSave, getCalculationFormData, getCreateDuplicateColumnDetail, getNodeWiseCategoryList, selectCurrentNodeData, selectCurrentNodeIds } from '../../Store/Workflow/WorkflowSlice'
import DataTableSkeleton from '../Skeleton/DataTableSkeleton'
import DropDown from '../DropDown/DropDown'
import Button from '../CommonComponents/Button'
import { useFormik } from 'formik'
import { parseCategotyValueLabel } from '../../Helpers/utils'
import SummaryTable from '../Table/SummaryTable'
import { createColumnHelper } from '@tanstack/react-table'

//imgaes 
import CloseIcon from "../../Images/remove.svg"
import XlsIcon from '../../Images/excel.svg'
import EditIcon from '../../Images/Group 809.svg'
import { TextInput } from '../SelectInput'
import { CircularProgress } from '@mui/material'
import downloadingIcon from '../../Images/gif/downloading.gif'
import useDataTable from '../../hooks/useDataTable'
import DataTable from '../Table/DataTable'
import ProceedButton from '../CommonComponents/ProceedButton'
import { columnNameExistCheck, duplicateColumnNamesList, duplicateRemoveCheck } from '../../Helpers/ApiHelpers'
import { toast } from 'react-toastify'



const columnHelper = createColumnHelper()

const CreateDuplicatecolumn = ({onProceedClick}) => {
    const location = useLocation()
    const dispatch = useDispatch()
    const nodeWiseCategory = useSelector(state => state?.Workflow?.nodeWiseCategory)
    const currentNodeData = useSelector(selectCurrentNodeData)
    const isDataLoading = useSelector(state => state?.Workflow?.ui?.isDataLoading)
    const isCalculating = useSelector(state => state?.Workflow?.ui?.isCalculating)
    const filesMerging = useSelector(state => state?.Workflow?.ui?.filesMerging)
    const reduxState = useSelector(state => state)
    const { currentEclNodeId, currentWorkflowId, currentWorkflowNodeId } = useSelector(selectCurrentNodeIds)
    const [isEditMode, setIsEditMode] = useState(currentWorkflowNodeId != null)
    const [columnNameList,setColumnNameList] = useState([])
    const [newColumnName,setNewColumnName] = useState('');
    const [showFields, setShowFields] = useState(false)
    const [selectedColumnData, setselectedColumnData] = useState([])
    const [undoDatas, setUndoDatas] = useState([])
    const [isRemoving, setIsRemoving] = useState(false);
    const [removingColumnIndex, setRemovingColumnIndex] = useState('')
    

    const isProceedLoading = useSelector(state => state?.Workflow?.ui?.isProceedLoading)
    const disableCategory = location?.state?.isfromHistory || isDataLoading || currentNodeData?.nodeCategoryId;
    const formik = useFormik({
        initialValues: {
            category: currentNodeData?.nodeCategoryId,
            columnName: undefined,
            duplicateColumName: undefined,
        },
        enableReinitialize: true,
    })
    

  const { dataTableColumns,
    getFilterOptions,
    summaryData,
    tableState,
    refreshDataTable,
    onTableStateChange,
    isDataLoading: isMergeSummaryLoading,
    isExportLoading,
    donwloadXl,
  } = useDataTable({ category_id: formik.values.category, ecl_node_id: currentEclNodeId, workflow_id: currentWorkflowId, from: "createDuplicateColumnDatas" })

    const onCategoryChange = (value) => {
        formik.setFieldValue("category", value)
    }

    const getColumnName = async() =>{
      const request = {
        "workflow_id":currentWorkflowId,
        "ecl_node_id":currentEclNodeId,
        "category_id":formik.values.category
     }
      const columnListResponse = await duplicateColumnNamesList(request) 
      if(columnListResponse?.data?.status ==200){
          setColumnNameList(columnListResponse?.data?.columnNames)
      }    
    }

    const onCategorySave = async(isFrom) => { 

      const categorySaveReq = {
            "workflow_id":currentWorkflowId,
            "ecl_node_id":currentEclNodeId,
            "category_id":formik.values.category
         }
         
        dispatch(getCreateDuplicateColumnDetail(categorySaveReq))
        .then(async(response) => {
              if (response.type === `${getCreateDuplicateColumnDetail.fulfilled}`) {  
                if(isFrom != 'edit'){ 
                  getColumnName()                   
                }    
                  refreshDataTable();
              }
          })
    }

    useEffect(() => {
        dispatch(getNodeWiseCategoryList({ workflow_id: currentWorkflowId, ecl_node_id: currentEclNodeId, workflow_node_id: currentWorkflowNodeId }))

    }, [])
    useEffect(() => {
        if(currentNodeData?.id){
        onCategorySave()
    }
    }, [currentNodeData?.id])
  
    const onRemoveClick = async (column,index) => {
      console.log(column,'cnjscjs cj');
      setIsRemoving(true);
      setRemovingColumnIndex(index);
  
      const response = await duplicateRemoveCheck({
          "workflow_id": currentWorkflowId,
          "category_id": formik.values.category,
          "column_name": column?.duplicate_column
      });
  
      if (response?.data?.status === 200) {
          setselectedColumnData((prev) => {
              // Check if the duplicate_column exists in the selectedColumnData
              const duplicateExists = prev.some(col => col.column_name === column.duplicate_column);
              
              if (duplicateExists) {
                  toast.error('The action is restricted as the column is used in anaylsis');
                  setIsRemoving(false);
                  return prev; // Return the previous state without changes
              }
              
              // Filter out the column if the duplicate_column does not exist
              return prev.filter((_, i) => i !== index)

          });
          setUndoDatas([...undoDatas, column])
                    
      } else if (response?.data?.status === 205) {
          toast.error(response?.data?.message);
      }
  
      setIsRemoving(false);
      setRemovingColumnIndex(null);
  };
  

    const summaryTableData = useMemo(() => {
        if(currentNodeData?.createDupColumnData){
            setShowFields(currentNodeData?.createDupColumnData?.createDuplicateColumnDatas?.length <=0)
            setselectedColumnData(currentNodeData?.createDupColumnData?.createDuplicateColumnDatas)
            setIsEditMode(currentNodeData?.createDupColumnData?.createDuplicateColumnDatas?.length > 0)
        }
        return currentNodeData?.createDupColumnData?.createDuplicateColumnDatas ?? []
      }, [currentNodeData?.createDupColumnData])

      const summaryColumns = useMemo(() => {
        const columns = [
          columnHelper.accessor("column_label", {
            header: "Column name",
          }),
          columnHelper.accessor("duplicate_label", {
            header: "Duplicate column name",
          })
        ];
      
        if (showFields) {
          columns.push(
            columnHelper.display({
              header: "Action",
              thClassName: "w-[10%]",
              cell: ({ row }) => {
                return row?.original?.isAddedNew === true || showFields ? (
                  isRemoving && removingColumnIndex === row.index ? <CircularProgress size={15} /> 
                  :
                  <div className='col-span-1' onClick={() => onRemoveClick(row.original,row.index)}>
                    <button className='text-[#4F49DF] text-[12px] xl:text-[14px] flex items-center gap-2'>
                      <img alt='remove' src={CloseIcon} className='w-3' />
                      Remove
                    </button>
                  </div>
                ) : null;
              }
            })
          );
        }
      
        return columns;
      }, [isEditMode, showFields, onRemoveClick]);
      

      const onColumNameChange = (data)=>{
        formik.setFieldValue('columnName',data)
      }

      const handleChangeDupColName = (e) =>{
        formik.setFieldValue('duplicateColumName', e.target.value.replace(/[^a-zA-Z0-9 _-]/g, ''));
        setNewColumnName(e.target.value.replace(/[^a-zA-Z0-9 _-]/g, ''));
      }

    const onSave = () => {
        const requestFormData = getCalculationFormData({
            client_branch_level_id: location?.state?.levelTwoCardId,
            isFrom: "createDuplicateColumn",
            category_id: currentNodeData?.nodeCategoryId,
            node_id: currentEclNodeId,
            state: reduxState,
            nodeStatus: "Completed",
        })       
        const request ={
            "ecl_workflow_id":currentWorkflowId,
            "ecl_node_id":currentEclNodeId,
            "ecl_category_id":formik.values.category,
            "duplicate_column_datas": selectedColumnData?.map(item => ({
              column_name: item.column_name,
              duplicate_column: item.duplicate_column
            })),
            "duplicate_column_undo_datas":undoDatas

        }

       dispatch(createDuplicateColumnSave({requestFormData,request}))
      .then(async(response) => {
        if (response.type === `${createDuplicateColumnSave.fulfilled}`) {   
            onCategorySave('edit') 
            getColumnName()    
            setselectedColumnData([])
            setUndoDatas([])

        }
    })

    }
    
    const getColumnDropdownByValue = value => columnNameList?.find(column => column?.column_name == value)

    const renderCreateDupColTable = () => {
   
      const handleClickAdd = async () => {
        // Check if the duplicate column name already exists in the selectedColumnData
        const duplicateExists = selectedColumnData.some(col => col.duplicate_column === formik.values.duplicateColumName);
    
        if (duplicateExists) {
            toast.error('This column name already exists');
            return;
        }
    
        const request = {
            workflow_id: currentWorkflowId,
            ecl_node_id: currentEclNodeId,
            category_id: formik.values.category,
            column_name: formik.values.duplicateColumName
        };
    
        const response = await columnNameExistCheck(request);
    
        if (response?.data?.status === 200) {
            setselectedColumnData(prev => ([
                ...prev,
                {
                    "column_name": getColumnDropdownByValue(formik.values.columnName)?.column_name,
                    "column_label": formik.values.columnName,
                    "duplicate_label": formik.values.duplicateColumName?.trim(),
                    "duplicate_column": formik.values.duplicateColumName?.trim(),
                    isAddedNew: true,
                }
            ]));
            formik.setFieldValue("columnName", undefined);
            formik.setFieldValue("duplicateColumName", undefined);
        } else if (response?.data?.status === 205) {
            toast.error(response?.data?.message);
        }
    };
    
        return <>
        {
            isEditMode ? 
            <>
             <div className={`2xl:py-3 py-1.5 bg-[#4339A8] flex justify-between items-center`}>
                <div className='mx-4 flex gap-3 items-center'>
                    <button className='text-[#FFFFFF] bg-[#F7BE7E] py-0.5 px-2 text-[12px] rounded'>Data category</button>
                    <p className='text-[#ffffff] font-semibold text-[12px]'>{currentNodeData?.createDupColumnData?.categoryName}</p>
                </div>
                <div className={`flex gap-1 items-center mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={() => {setIsEditMode(false);setShowFields(true)}}>
                    <img src={EditIcon} className='w-4 h-4' />
                    <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
                </div>
            </div>
            {summaryTableData?.length <= 0 ? null : 
            <SummaryTable
                columns={summaryColumns}
                data={summaryTableData}
                thClassName='!text-[10px] 2xl:text-[14px] bg-[#4339A8] px-6'
                tdClassName='!text-[10px] 2xl:text-[14px] px-6'
                theadClassName='w-fit !z-0'
                
                className={isEditMode ? "" : "mt-4"}
            />
            }
            </>
            :
            <>
            {selectedColumnData?.length <= 0 ?  null :
            <SummaryTable
                columns={summaryColumns}
                data={selectedColumnData}
                tdClassName='bg-[#ECECEC] text-[#707070] px-4'
                thClassName='!bg-[#4339A8] px-4'
                className='mt-4 border-separate border-spacing-y-2'
                theadClassName='w-fit !z-0'

            />
            }
            <div className={`flex justify-between w-full gap-6 my-6 ${showFields ? 'block':'hidden'}`}>
            <DropDown   
                key={1}       
                dropDownOptions={columnNameList?.length > 0 ? columnNameList?.map(option => ({
                label: `${option?.alias_name}`,
                value: `${option?.column_name}`,
                })) : []}
                placeholder={'Select column name'}
                name={'columnName'}
                value={formik.values?.columnName}
                ordinary={true}
                disabled={location?.state?.isfromHistory}
                customTags={false}
                onOptionChange={onColumNameChange}
            />
             <TextInput
                    placeholder="Enter name of the duplicate column"
                    className={`place text-[12px] 2xl:text-[13px] custom-select-height`} 
                    name="duplicateColumName"
                    onChange={(e)=>{handleChangeDupColName(e)}}
                    value={formik.values.duplicateColumName}
            />
             <Button
                    className={'bg-[#47B4AE] text-white px-[1.7rem]  py-1.5 rounded-[5px]'}
                    label={'Add'}
                    disabled={!formik.values.columnName || !formik.values.duplicateColumName}
                    onClick={() => {handleClickAdd()}}
                />
            </div>

            <div className={`flex justify-center my-4 ${showFields ? 'block':'hidden'}`}>
                <Button
                className={'bg-[#47B4AE] text-white px-[1.5rem] 2xl:px-[1.5rem] py-1.5 rounded-[5px]'}                  
                label={'Save'}
                onClick={onSave}
                disabled={formik.values.columnName || formik.values.duplicateColumName || selectedColumnData?.length == 0}

                />
            </ div>
            </>           

        }           
            
          
                             
        </>

    }

  
    const renderResultTable = () => {

        if (!currentNodeData?.nodeCategoryId) return
    
        if (isDataLoading || isMergeSummaryLoading) {
          return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }
    
        const mergeSummaryTableData = summaryData
            
        return <div className={`mt-5 ${mergeSummaryTableData?.columnDatas?.length > 0 ? 'block':'hidden'}`}>
    
          <div className='flex justify-end items-center'>
            {isExportLoading
              ? <img src={downloadingIcon} className='w-6 mr-2' />

              : <img alt='' src={XlsIcon} className='object-cover mb-2 mr-2 cursor-pointerw-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
          </div>
    
          <DataTable
            columns={dataTableColumns}
            data={mergeSummaryTableData?.columnDatas ?? []}
            onTableStateChange={onTableStateChange}
            totalCount={mergeSummaryTableData?.totalRecords}
            isOptionsDisabled={location?.state?.isfromHistory}
            getFilterOptions={getFilterOptions}
            tableState={{
              pagination: {
                pageIndex: tableState.pageIndex,
                pageSize: tableState.pageSize,
              },
              sorting: tableState?.sortBy ? [
                {
                  id: tableState?.sortBy,
                  desc: tableState?.sortOrder === "desc"
                }
              ] : [],
              columnFilters: tableState.columnFilters
                ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                : [],
              columnPinning: tableState.columnPinning,
              columnVisibility: tableState?.columnVisibility,
            }}
          />
          {currentNodeData?.node_status == 'Completed'           
            ? <div className={`float-right 2xl:my-6 my-3 ${location?.state?.isfromHistory ? 'hidden' : 'block'}`}>
              <ProceedButton
                isLoading={isProceedLoading}
                onClick={onProceedClick}
              />              
            </div>
            :  null
            }
        </div>
      }

    return (
        filesMerging || isCalculating
            ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
            : <div className='my-2'>
                {isEditMode === true ? "" :
                    <div className='flex gap-5'>
                        <DropDown
                            dropDownOptions={parseCategotyValueLabel(nodeWiseCategory)}
                            placeholder={'Select data category'}
                            name={'usedCategory'}
                            ordinary={true}
                            disabled={disableCategory}
                            customTags={false}
                            value={formik.values.category}
                            onOptionChange={onCategoryChange}
                        />
                        <Button
                            className={'bg-[#47B4AE] text-white px-[1.5rem] 2xl:px-[1.5rem] py-1.5 rounded-[5px]'}                  
                            disabled={disableCategory || !formik.values.category}
                            label={'Save'}
                            onClick={onCategorySave}
                        />
                    </div>
                }
                
                {renderCreateDupColTable()}
                {renderResultTable()}
            </div>
    )
}

export default CreateDuplicatecolumn
