//depentencies
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteSegments from '../../Routes/RouteSegments';

//Components
import Button from '../../Components/Button';
import DynamicTable from '../../Components/Table/DynamicTable';
import OverflowTooltip from '../../Components/ToolTip/OverFlowToolTip';
import EclHistory from '../../Components/wizardPage/EclHistory';
import MultiFileUploadFeilds from '../../Components/wizardPage/MultiFileUploadFeilds';
import FileUploadListSkeleton from '../../Components/Skeleton/FileUploadListSkeleton';
import {DragAndDropComponent, DropComponent} from '../../Components/wizardPage/DragAndDropComponent';
import NewCalculationSkeleton from '../../Components/Skeleton/NewCalculationSkeleton';
import SubSheetsListPopup from '../../Components/PopUp/SubSheetsListPopUp'
import DataTableSkeleton from '../../Components/Skeleton/DataTableSkeleton';
import HamburgerMenu from '../../Components/CommonIcons/HamburgerMenu';
import ActivityHistoryList from '../../Components/wizardPage/ActivityLog';
import DropDown from '../../Components/DropDown/DropDown';
import BreadCrumbArrow from '../../Images/Group 166.svg'

//packages
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import dayjs from 'dayjs';
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import axios from 'axios';
import { toast } from 'react-toastify';
import { DatePicker, Select, Tree } from "antd";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from 'moment';

//images
import LeftArrow from '../../Components/CommonIcons/LeftArrow';
import CalenderIcon from '../../Components/CommonIcons/CalenderIcon';
import DownArrow from '../../Components/CommonIcons/DownArrow';
import AccorDinOpend from '../../Images/accordinOpend.svg'
import AccorDinClosed from '../../Images/accordinClosed.svg'
import removeFile from "../../Images/removeFIle.svg"
import deleteIcon from "../../Images/Group 442.svg"
import XlsActiveIcon from "../../Images/xls-green.svg"
import * as icons from '../../Components/CommonIcons/ModelBuilderIconsSrc'; 


//redux components
import { ModelBuilderList, NodeDetailsDataClear, fileDetailsDataClear, fileUploadSummaryClear, getCategoryList, getDeleteNode, getFileDetails, getFileUploadSummary, getNodeDetails, getSingleFilePerticularList, mergeSummaryDataClear, perticularNodeDataClear, tableUpdateionDataClear } from '../../Store/Wizard/Action';
import { useDispatch, useSelector } from 'react-redux';

//common
import { convertDateFormat, getCurrentDateTime, getFileSize } from '../../Utils/CommonFunctions';
import { Baseurl, localStorageKeys, loginLocalStorageKeys, positionAnalysisTableHeader, deletePopupTableHeader, Constants, modelBuilderSections } from '../../Utils/Constants';
//end points
import { ASSIGNED_DATA_TYPE_LIST, CREATE_MERGE_SUMMARY, GET_MERGE_SUMMARY_RESULT, REMOVED_COLUMN_NAMES_LIST, REMOVE_DUPLICATE_ROW, RENAMED_COLUMN_LIST, TEMPLATE_NAME_CHECK } from '../../Helpers/EndPoints';

import CommonPopupLayout from '../../Components/PopUp/CommonPopupLayout'
import DeletePopUpContent from '../../Components/wizardPage/DeletePopUpContent';
import { getPositionAnalysisCategory } from '../../Helpers/ApiHelpers';
import AssignDataTypeFormat from '../../Components/DataCleansing/AssignDataTypeFormat';
import PositionAnalysisTable from '../../Components/PositionAnalysis/PositionAnalysisTable';
import PositionAnalysis from '../../Components/PositionAnalysis/PositionAnalysis';
import RenameColumnName from '../../Components/DataCleansing/RenameColumn';
import RemoveColumnName from '../../Components/DataCleansing/RemoveColumn';
import RemoveDuplicateRow from '../../Components/DataCleansing/RemoveDuplicateRow';
// import MismatchedDataType from '../../Components/DataCleansing/MismatchedDataType';

const schema = yup.object().shape({

  calculationDate: yup.string().required("Please select date"),
});

let workflow_node_id;
let ecl_workflow_id;
let ecl_node_id;
let nodeCategoryId=undefined;
let categoryChanged;
let tempWorkFlowId;

const NewCalculation = ({dynamicHeight}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { register, control, reset, formState: { errors }, } = useForm({ mode: "onChange", resolver: yupResolver(schema), });
  const { Option } = Select;

  //localstorage get values
  const currentCalculationDate = localStorage.getItem(localStorageKeys?.currentCalculationDate)
  const url = localStorage.getItem(localStorageKeys.uploadedFiles3Url)
  const modelBuilderList = localStorage.getItem(localStorageKeys.modelBuilderList)
  const lastNodeStatus = localStorage.getItem(localStorageKeys.lastNodeStatus)
  const userId = localStorage.getItem(loginLocalStorageKeys.loginId)
  const template_name = localStorage.getItem(localStorageKeys.currentTemplateName);
  const template_id = localStorage.getItem(localStorageKeys.currentTemplateId);
  //ref
  const saveAndAnotherRef = useRef(false);
  const isPosSaveClick = useRef(false)

  //states 
  const [activeIndices, setActiveIndices] = useState([0]);
  const [sections, setSections] = useState();
  const [historyPaneOpen, setHistoryPaneOpen] = useState(false);
  const [activityLogOpen, setActivityLogOpen] = useState(false);
  const [modelBuilderOpen, setModelBuilderOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [calAprochDropDown, setCalAprochDropDown] = useState();
  const [calculationApproach, setCalculationApproach] = useState([]);
  const [category, setCategory] = useState();
  const [categoryList, setCategoryList] = useState([])
  const [calculationDate, setCalculationDate] = useState()
  const [selectedTile, setSelectedTile] = useState(null)
  const [isUpdate, setIsUpdate] = useState(false)
  const [isDropped, setDropped] = useState(false)
  const [isFileSetToS3, setIsFileSentToS3] = useState(true)
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isTemplate, setIsTemplate] = useState()
  const [isNew, setIsNew] = useState(true)
  const [currentAccordinOpen, setCurrentAccordinOpen] = useState(null)
  const [workflowNodeData, setWorkFlowNodeData] = useState([])
  const [apiCalled, setApiCalled] = useState(false);
  const [clonedImages, setClonedImages] = useState([]);
  const [selectedTileId, setSelectedTileId] = useState();
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [calculationDateChanged, setCalculationDateChanged] = useState(false);
  const [deleting, setDeleteing] = useState(false);
  const [uploadAnother, setUploadAnother] = useState(false);
  const [saveAndProceed, setSaveAndProceed] = useState(true)
  const [fileUploadSummary, setFileUploadSummary] = useState([])
  const [selectedNodeSummary, setSelectedNodeSummary] = useState([])
  const [checkWorkFlowData, setCheckWorkFlowData] = useState([])
  const [selectedWorkFlowNode, setSelectedWorkFlowNode] = useState();
  const [showSummaryLoader, setShowSummaryLoader] = useState(false);
  const [nearestNodeClicked, setNearestNodeClicked] = useState(false);
  const [replaceClicked, setReplaceClicked] = useState(false);
  const [initialRedering, setInitialRendering] = useState(true);
  const [draggedTileId, setDraggedTileId] = useState(null);
  const [clickedNode, setClickedNode] = useState({});
  const [mergeSummaryData, setMergeSummaryData] = useState([]);
  const [mergeSummaryTableData, setMergeSummaryTableData] = useState([]);
  const [mergeSummaryTableRequest, setMergeSummaryTableRequest] = useState(null)
  const [mergeSummaryLoader, setMergeSummaryLoader] = useState(false)
  const [positionAnalysisLoader, setPositionAnalysisLoader] = useState(false)
  const [reArangePipeLine, setReArangePipeLine] = useState(true)
  const [showMenu, setShowMenu] = useState(true)
  const [afterSave, setAfterSave] = useState(false);
  const [activiyLogs, setActiviyLogs] = useState(null)  
  const [rendered, setRendered] = useState(false)
  const [currentPage, setCurrentPage] = useState(1);
  const [showSubSheetPopup, setShowSubSheetPopup] = useState(false)
  const [fileDetails, setFileDetails] = useState([]);
  const [enableModelBuilder, setEnableModelBuilder] = useState(false)
  const [deletePopup, setDeletePopup] = useState(false);
  const [categoryId, setCategoryId] = useState(null);
  const [postionAnalysisCategory, setPostionAnalysisCategory] = useState([])
  const [templateName, setTemplateName] = useState('')
  const [templateId, setTemplateId] = useState(null);
  const [enablePositionAnalysisSave,setEnablePositionAnalysisSave] = useState(false);
  const [CalculationMultipleReq,setCalculationMultipleReq] = useState({});
  const [isdeletableFileDetails,setIsdeletableFileDetails] = useState([]);
  const [assignDataTypeFormatData, setAssignDataTypeFormatData] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [renameColumnList, setRenameColumnList] = useState([]);
  const [removeColumnList, setRemoveColumnList] = useState([]);
  const [removeDuplicateRowsList, setRemoveDuplicateRowList] = useState([]);
  const [nodeSaved, setNodeSaved] = useState(false)
  //before page renders
  useEffect(() => {
    if (initialRedering) {
      dispatch(getCategoryList(location?.state?.clientId, location.state?.levelTwoCardId))
      dispatch(ModelBuilderList())
    }
    localStorage.removeItem(localStorageKeys.lastNodeStatus)
  }, [])

  useEffect(() => {
    if (location?.state?.continue) {
      setIsTemplate('New')
    }
    if ((location?.state?.continue || location?.state?.isfromHistory) && initialRedering) {
      setNearestNodeClicked(true)
      setInitialRendering(false)
    }
    if (location?.state?.continue || location?.state?.isfromHistory) {
      if (WorkflowNodes?.length > 0) {
        setIsLoading(false)
      }
      else {
        if (currentCalculationDate) {
          setIsLoading(false)
        }
      }
    }
    else {
      if (currentCalculationDate) {
        setIsLoading(false)
      }
    }
  }, [location?.state])

  //redux values get

  const { CategoryListData, WorkflowNodes, perticularWorkFlowNode, FileSummary, MergeSummaryData, filesSummaryMessage } = useSelector(state => {
    return {
      FileSummary: state?.ModelBuilder?.filesSummaryData,
      CategoryListData: state?.ModelBuilder?.categoryListData,
      WorkflowNodes: state?.ModelBuilder?.workflowNodes,
      perticularWorkFlowNode: state?.ModelBuilder?.perticularNodeData,
      MergeSummaryData: state?.ModelBuilder?.mergeSummaryData,
      filesSummaryMessage: state?.ModelBuilder?.filesSummaryMessage
    }
  })
  const { WorkFlowDataCheck } = useSelector(state => {
    return {
      WorkFlowDataCheck: state?.CheckWorkFlowReducer?.reviewStatusData,
    }
  })

  useEffect(() => {
    try {
      if (CategoryListData) {
        setCategoryList(CategoryListData)
      }
      if (modelBuilderList) {
        setSections(modelBuilderList)
      }
    } catch (error) {
      console.error("Error setting state:", error);
    }
  }, [modelBuilderList, CategoryListData]);

  useEffect(() => {
    try {
      if (WorkflowNodes) {
        setWorkFlowNodeData(WorkflowNodes);
      }
      if (FileSummary) {
        setFileUploadSummary(FileSummary)
      }
      if (perticularWorkFlowNode) {
        setSelectedNodeSummary(perticularWorkFlowNode)
      }
      if (WorkFlowDataCheck) {
        setCheckWorkFlowData(WorkFlowDataCheck)
      }
      if (MergeSummaryData) {
        setMergeSummaryData(MergeSummaryData)
      }
      if (filesSummaryMessage) {
        toast.error(filesSummaryMessage?.message)
      }
    } catch (error) {
      console.error("Error setting state:", error);
    }
  }, [FileSummary, perticularWorkFlowNode, WorkFlowDataCheck, WorkflowNodes, MergeSummaryData, filesSummaryMessage]);

  const getMergedFilesAccordin = (nodeId, isFrom) => {

    axios(`${Baseurl + CREATE_MERGE_SUMMARY}`, {
      method: "POST",
      data: {
        "workflow_id": ecl_workflow_id,
        "ecl_node_id": nodeId ?? draggedTileId
      },
    })
      .then((response) => {
        if (response?.data?.status == 200) {
          if (isFrom == 'postionAnalysis') {
            setEnablePositionAnalysisSave(true)
            console.log('files merged successfully');
            // setMergeSummaryTableData(response?.data?.mergedResults)

          } else if(isFrom == 'viewPosition'){
            if(nodeCategoryId != null){
            getMergedFilesTable(nodeCategoryId,1,nodeId,isFrom)
          }
          }
          else {
            setMergeSummaryData(response?.data)
          }
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      })
  }

  const getMergedFilesTable = (id, page, node_id,isFrom) => {
    setMergeSummaryLoader(true)
    const request = {
      "workflow_id": ecl_workflow_id,
      "ecl_node_id": node_id ?? draggedTileId,
      "category_id": id,
      "page": page ?? currentPage,
      "record_per_page": 10,
      "sort_by": "name",
      "sort_order": "asc",
      "is_export":false
    }
    axios(`${Baseurl + GET_MERGE_SUMMARY_RESULT}`, {
      method: "POST",
      data: request     
      
    })
      .then((response) => {
        if (response?.data?.status == 200) {
          setMergeSummaryTableData(response?.data?.mergedResults)
          setMergeSummaryTableRequest(request)
          setMergeSummaryLoader(false);
          setPositionAnalysisLoader(false)
          if(selectedTileId ==4){
           setIsNew(true)
          }
          if(isFrom != 'viewPosition'){
            setIsFileSentToS3(true)
            setUploadAnother(true)
            setReArangePipeLine(false)
            setError(false)
            calculationApiCall(null,null,isFrom,false,id)
          }
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      })
  }

  useEffect(() => {

    if (location?.state?.workFlowId && currentCalculationDate && currentCalculationDate != 'Invalid date') {
      setCalculationDate(currentCalculationDate)
    } else if (currentCalculationDate && currentCalculationDate != 'Invalid date') {
      setCalculationDate(currentCalculationDate)
    }

    if (template_name) {
      setTemplateName(template_name);
    } if (template_id) {
      setTemplateId(template_id)
    }

  }, [currentCalculationDate, template_id])

  //functions

  const actionsClick = (id, isFrom) => {
    if (isFrom == 'history') {
      setHistoryPaneOpen(true)
    } else if (isFrom == 'modelBuilder') {
      if (((isFileSetToS3 && uploadAnother) || clonedImages.length == 0) && !replaceClicked) {
        // if(nodeSaved || clonedImages?.length ==0){
        setModelBuilderOpen(true)
      }
      else {
        setError(true);
      }
    } else {
      localStorage.removeItem(localStorageKeys.currentCalculationDate)
    }

  }

  const closeDetails = (isFrom) => {
    setHistoryPaneOpen(false)
    if (isFrom == 'history') {
      setHistoryPaneOpen(false)
    } else if (isFrom == 'modelBuilder') {
      setModelBuilderOpen(false)
      setActiveIndices([0])
    } else if (isFrom == 'activity') {
      setActivityLogOpen(false)
    }

  }

  const goToPrevPage = () => {
    localStorage.removeItem(localStorageKeys.oldCategory);
    localStorage.removeItem(localStorageKeys.currentCalculationDate);
    localStorage.removeItem(localStorageKeys.currentWorkFlowId);
    localStorage.removeItem(localStorageKeys.currentTemplateId);
    localStorage.removeItem(localStorageKeys.currentTemplateName);
    dispatch(perticularNodeDataClear())
    dispatch(NodeDetailsDataClear())
    dispatch(mergeSummaryDataClear())
    dispatch(tableUpdateionDataClear())
    ecl_workflow_id = undefined;
    workflow_node_id = undefined;
    tempWorkFlowId = undefined;
    setReplaceClicked(false)
    navigate(RouteSegments.LEVEL_TWO, {
      state: {
        client_country_id: location?.state?.client_country_id,
        clientId: location?.state?.clientId,
        breadCrumbClientName: location?.state?.breadCrumbClientName,
        breadCrumbCountryName: location?.state?.breadCrumbCountryName,
        breadCrumbLevelOne: location?.state?.breadCrumbLevelOne,
        breadCrumbLevelTwo: location?.state?.breadCrumbLevelTwo,
        levelTwoCategory: location?.state?.levelTwoCategory,
        data: location?.state?.data,
        levelTwoCardId: location?.state?.levelTwoCardId,
        labelName: location?.state?.breadCrumbLevelOne,
      }
    })
  }

  const disabledDate = current => {
    return current && current > moment().endOf('day');
  };

  const isSettingCalculationDate = (val) => {

    // Check if the input value is a valid date in the format 'DD/M/YYYY'
    const isValidDate = moment(val, 'DD/M/YYYY', true).isValid() || moment(val).isValid();

    if (isValidDate) {
      const formattedDate = moment(val, 'DD/M/YYYY').format('DD/MM/YY');
      setCalculationDate(formattedDate);
    } else {
      // Handle the case where the input value is not a valid date
      setCalculationDate(val);
      console.error('Invalid date format:', val);
      // Optionally, you can set a default date or handle the error in another way.
      // setCalculationDate(/* default date or null */);
    }
  };

  const handleItemClick = (index) => {
    setActiveIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [...prevIndices, index];
      }
    });
  };

  const handleCardClick = async (ev, id, index) => {
    setClickedNode({ event: ev, id: null, by: 'click' })
    setCurrentAccordinOpen(null)
    setRendered(false);
    setApiCalled(false);
    nodeCategoryId=undefined
    setNodeSaved(false)
    setAssignDataTypeFormatData({})
    setRenameColumnList({});
    setRemoveColumnList({});
    setRemoveDuplicateRowList({});
    setMergeSummaryTableData([])

    if (index == 2 && id > 4) {      
      setDraggedTileId(id)      
    }
    if (index == 1 && id == 4) {
      onReviewClick(4, 'postionAnalysis');
    }
    if (index != 3) {
      if (index == 2 && clonedImages.length != 0) {
        setClickedNode({ event: ev, id: id, by: 'click' })
      } else if (index != 2) {
        setClickedNode({ event: ev, id: id, by: 'click' })
      }
    }
    if (isFileSetToS3) {
      setDraggedTileId(id);
      workflow_node_id = null;
    }
  }

  const drag = async (ev, id, index) => {
    setCurrentAccordinOpen(null)
    nodeCategoryId=undefined
    setApiCalled(false)
    setNodeSaved(false)
    setAssignDataTypeFormatData({})
    setRenameColumnList({});
    setRemoveColumnList({});
    setRemoveDuplicateRowList({});

    if (index == 2 && id > 4) {      
      setDraggedTileId(id)
      setClickedNode({ event: ev, id: null, by: 'drag' })
      setEnablePositionAnalysisSave(true)
    }
    if (index == 1 && id == 4) {
      onReviewClick(id, 'postionAnalysis')
    }
    ev.dataTransfer.setData("Text", ev.target.id);
    if (isFileSetToS3) {
      setDraggedTileId(id)
      workflow_node_id = null
    }
  };

  const onDraggedTileClick = (data, dynamicId, node_id,usedCategoryId) => {
    dispatch(perticularNodeDataClear())
    dispatch(fileUploadSummaryClear())
    setSelectedNodeSummary([])
    setFileUploadSummary([])
    setAssignDataTypeFormatData({})
    setRenameColumnList({});
    setRemoveColumnList({});
    setRemoveDuplicateRowList({});
    setMergeSummaryTableData([])

    if (node_id != null) {
      setShowSummaryLoader(true)
    }
    if(usedCategoryId != null){
      nodeCategoryId = usedCategoryId
    }
    setMergeSummaryTableData([])
    setMergeSummaryTableRequest(null)
    setCurrentAccordinOpen(null)
    setSelectedTileId(data);
    workflow_node_id = node_id
    setIsNew(false);
    // setApiCalled(true)
    setUploadAnother(true)
    setSaveAndProceed(false)
    setSelectedWorkFlowNode(node_id)
    if (node_id && node_id != null) {
      if(data ==4){
        getMergedFilesAccordin(data,'viewPosition')
      }else if(data==5){
        getAssignDataFormatTableData(data,usedCategoryId)
      }else if(data==6){
        getTableData('rename',6,usedCategoryId)
      }else if(data==7){
        getTableData('remove',7,usedCategoryId)
      }else if(data==9){
        getTableData('duplicateRowRemove',9,usedCategoryId)
      }
      else{
        const request = {
          workflow_node_id: node_id,
          page: 1,
          record_per_page: 10,
          sort_by: "",
          sort_order: "",
          file_path: "",
       
        }
        dispatch(getSingleFilePerticularList(request))
      }
      if (node_id == tempWorkFlowId) {
        dispatch(perticularNodeDataClear())
        setNearestNodeClicked(false)
        setShowSummaryLoader(false)
        setIsFileSentToS3(false)
        setIsNew(true)
        setDropped(true)
        localStorage.removeItem(localStorageKeys.lastNodeStatus)
        // setCategory(null)
      }
      else {
        setNearestNodeClicked(true)
      }
    }
    else {
      setIsNew(true)
      setNearestNodeClicked(false)
      setDropped(true)
      // localStorage.removeItem(localStorageKeys.lastNodeStatus)
    }
  }

  const onReplaceClick = () => {
    dispatch(perticularNodeDataClear())
    dispatch(fileUploadSummaryClear())
    setSelectedTile(true);
    setReplaceClicked(true)
    tempWorkFlowId = workflow_node_id
    setIsUpdate(true);
    setIsNew(true)
    setApiCalled(true)
    setIsTemplate('New')
    setNearestNodeClicked(false)
    setDropped(true)
    localStorage.setItem(localStorageKeys.lastNodeStatus, 'pending')    
  }

  const onReviewClick = (id, isFrom) => {
    getMergedFilesAccordin(id, isFrom)
  }

  const apiCallForListingNode = async (id, isFrom, isDelete) => {
    try {
      saveAndAnotherRef.current = isFrom === "another"
      setAfterSave(true)
      const result = await dispatch(getNodeDetails(id));
      if (isFrom === "another") {
        // setWorkFlowId(id)
      }
      if (result && result.type === "NODE_DETAILS") {
        setIsNew(false)
        setApiCalled(true);
        // setCategory(null);
        setSelectedTile(false)
        setIsUpdate(false)
        if (isFrom == 'another') {
          setIsNew(true)
          setSelectedTile(true)
          setModelBuilderOpen(false)
          setShowSummaryLoader(false)
        } else {
          if (!isDelete) {
            setEnableModelBuilder(true)
          }
        }
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      setProgress(0);
      // Handle other error-related tasks if needed
    }
  };

  const calculationApiCall = (url, data, isFrom, isDelete,category_id) => {

    if (calculationDate == 'Invalid date') {
    }
    else {
      if(templateId || templateName){
      const nodeDetailsString = localStorage.getItem(localStorageKeys.nodeDetails);
      const nodeDetails = JSON.parse(nodeDetailsString);
      const file_details = JSON.parse(localStorage.getItem(localStorageKeys.file_details))

      const postFormData = new FormData();
      postFormData.append('ecl_workflow_id', ecl_workflow_id != null || ecl_workflow_id != undefined ? ecl_workflow_id : "");
      postFormData.append('user_id', localStorage.getItem(loginLocalStorageKeys.loginId));
      postFormData.append('client_branch_level_id', location?.state?.levelTwoCardId);
      postFormData.append('date', convertDateFormat(calculationDate));
      postFormData.append('template_type', isTemplate);
      postFormData.append('ecl_workflow_node_id', replaceClicked ? workflow_node_id ?? "" : "");
      postFormData.append('node_id', selectedTileId);
      if(isFrom != 'postionAnalysis'){
      postFormData.append('node_value', JSON.stringify(nodeDetails));
      }
      if(isFrom == 'postionAnalysis' || isFrom == 'dataCleansing'){
        postFormData.append('category_id', category_id); 
      }
      postFormData.append('template_id', templateId ?? "");
      postFormData.append('template_name', templateId ? "" : templateName)

      if (file_details && (isFrom != 'postionAnalysis' && isFrom != 'dataCleansing')) {
        postFormData.append('file_details', JSON.stringify(file_details));
      }
      axios.post("https://ecl.port0.org/laravelapi/api/ecl/calculation", postFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          // handle the response
          if (response?.data?.status == 200) {
            localStorage.setItem(localStorageKeys.currentWorkFlowId, response?.data?.workflowId)
            isPosSaveClick.current = isFrom === "postionAnalysis"
            apiCallForListingNode(response?.data?.workflowId, isFrom, isDelete) 
            setCurrentAccordinOpen(null)           
            setReplaceClicked(false)
            if(isFrom == 'another'){
              setNodeSaved(false)
            }else{
              setNodeSaved(true)
            }
            if (isFrom != 'another') {
              localStorage.setItem(localStorageKeys.lastNodeStatus, 'completed')
            }
          }
        })
        .catch((error) => {
          setProgress(0);
          console.log(error);
        });
    }}
  }

  const NextStepUploadAnother = async (action, selectedCategory) => {
    setReArangePipeLine(false)
    const parentElement = document.getElementById('parent'); // Replace 'parent' with your actual parent element ID

    const nextStepDynamicId = `clonedImage_${new Date().getTime()}`;
    var nextStepNodeCopy = document.createElement('img');
    nextStepNodeCopy.src = icons[`Id${selectedTileId}`]
    nextStepNodeCopy.draggable = false;
    nextStepNodeCopy.id = nextStepDynamicId;
    nextStepNodeCopy.className = `self-center cursor-pointer relative color-change-image ${selectedTileId == 2 ? 'max-w-[1.7rem] max-h-[1.8rem]' : 'max-h-[2rem] max-w-[2rem]'}`;
    nextStepNodeCopy.onclick = () => location?.state?.isfromHistory ? '/#' : onDraggedTileClick(selectedTileId, nextStepDynamicId, null,null);
    nextStepNodeCopy.title = 'null'


    var nextStepCloseIcon = document.createElement('img');
    nextStepCloseIcon.src = removeFile;
    nextStepCloseIcon.id = nextStepNodeCopy.id + 'close'
    nextStepCloseIcon.className = "close-icon absolute top-[-1px] cursor-pointer right-[-1px] h-3 w-3";
    nextStepCloseIcon.onclick = () => handleCloseIconClick(nextStepDynamicId, selectedTileId, ecl_workflow_id, null);

    var nextStepHrElement = document.createElement('hr');
    nextStepHrElement.className = 'self-center w-[2.5rem] max-w-[4.5rem] h-[3px] bg-[#B8B5B5] text-[#B8B5B5]';
    nextStepHrElement.id = nextStepNodeCopy.id + 'hr';

    var nextStepImageContainer = document.createElement('div');
    nextStepImageContainer.className = 'image-container relative self-center';
    nextStepImageContainer.appendChild(nextStepNodeCopy);
    nextStepImageContainer.appendChild(nextStepCloseIcon);

    // Append the new elements
    parentElement.appendChild(nextStepImageContainer);
    parentElement.appendChild(nextStepHrElement);

    // Remove the color-change-image class and close icon from the last element in clonedImages array
    const lastClonedImage = clonedImages[clonedImages.length - 1];
    if (lastClonedImage) {
      const lastImageElement = document.getElementById(lastClonedImage.clonedImageId);
      if (lastImageElement) {
        lastImageElement.classList.remove('color-change-image');

        // Remove the close icon
        const lastCloseIcon = lastImageElement.querySelector('.close-icon');
        if (lastCloseIcon) {
          lastCloseIcon.remove();
        }
      }
    }

    var addImage = document.getElementById('addIcon');
    addImage.className = location?.state?.isfromHistory ? 'pointer-events-none w-[3.2rem]' : 'cursor-pointer w-[3.2rem]';
    parentElement.appendChild(addImage); // Place 'addIcon' at the end

    saveAndAnotherRef.current = false
  };

  const apiCallDeleteNode = async (request, workFlowId) => {
    try {
      const result = await dispatch(getDeleteNode(request));
      if (result) {
        setTimeout(() => {
          dispatch(getNodeDetails(workFlowId))
        }, 1800);
        setDeleteing(false)
      }
    } catch (error) {
      setDeleteing(true)
      console.error('Error uploading file:', error);
    }
  };

  const handleCloseIconClick = (id, selectedId, workFlowId, workFlowNodeId) => {
    setDeleteing(true)
    setNearestNodeClicked(true)
    setIsFileSentToS3(true)
    dispatch(NodeDetailsDataClear())
    dispatch(mergeSummaryDataClear())
    setMergeSummaryLoader(false)
    setCurrentAccordinOpen(null)
    setClickedNode({ event: null, id: null, by: 'delete' })

    if (selectedId == 3) {
      setCategoryId(null)
      setMergeSummaryTableData([])
    }
    const request = {
      "ecl_workflow_node_id": workFlowNodeId,
      "workflow_id": workFlowId,
      "ecl_node_id": selectedId
    }

    if ((workFlowNodeId != null) && (workFlowId) && (selectedId)) {
      apiCallDeleteNode(request, workFlowId)
      setError(false)
    } else {

      if (workFlowId == null) {
        setIsNew(false)
        setIsUpdate(false)
        setUploadAnother(true)
        setDeleteing(false)
        setShowSummaryLoader(false)
        setError(false)
      }
      if (workFlowId != null) {
        dispatch(getNodeDetails(workFlowId))
        localStorage.removeItem(localStorageKeys.lastNodeStatus)
        setIsNew(false)
        setIsUpdate(false)
        setUploadAnother(true)
        setDeleteing(false)
        setShowSummaryLoader(false)
      }
      setError(false)
    }

    setClonedImages((prevClonedImages) => {
      // Find the index of the removed image
      const removedIndex = prevClonedImages.findIndex((image) => image.clonedImageId == id);

      // Remove the image
      const updatedClonedImages = [...prevClonedImages.slice(0, removedIndex), ...prevClonedImages.slice(removedIndex + 1)];

      // Set selectedTileId to the selectedFieldId of the last element (if available)
      const lastElement = updatedClonedImages.length > 0 ? updatedClonedImages[updatedClonedImages.length - 1].selectedFieldId : null;
      // setSelectedTileId(lastElement);

      return updatedClonedImages;
    });

    const imageContainer = document.querySelector(`#${id}`);
    if (imageContainer) {
      const hrElement = document.getElementById(`${id}hr`);
      if (hrElement) {
        hrElement.remove();
      }
      imageContainer.remove();
    }

  };

  const saveAndUploadAnother = (from, data) => {
    if (from == 'proceed') {
      setUploadAnother(true)
      setError(false)
      setSaveAndProceed(false)
      setShowSummaryLoader(true)
      calculationApiCall(url, data)
      if (!replaceClicked) {
        tempWorkFlowId = undefined
      }
      localStorage.setItem(localStorageKeys.enableDataQuality, true)
    }
    else {
      setUploadAnother(true)
      setApiCalled(true)
      setIsFileSentToS3(false)
      setError(false)
      setSaveAndProceed(false)
      setShowSummaryLoader(true)
      calculationApiCall(url, data, 'another')
      if (!replaceClicked) {
        tempWorkFlowId = undefined
      }
      localStorage.setItem(localStorageKeys.enableDataQuality, true)
    }
  }

  const OnClicksaveAndProceed = async (action, selectedCategory) => {
    setSaveAndProceed(false)
    setUploadAnother(true)
    setError(false)

    const lastImageId = clonedImages[clonedImages.length - 1]?.clonedImageId;

    const lastImage = document.getElementById(lastImageId);

    if (lastImage) {
      lastImage.classList.remove('color-change-image'); // Remove the existing class
    }
    saveAndUploadAnother('proceed')
  };

  const toggleAccordion = (index) => {
    if (index == currentAccordinOpen) {
      setCurrentAccordinOpen(null)
     
    } else {
      setCurrentAccordinOpen(index)      
    }
  };

  const getActivityLog = async () => {
    try {
      const response = await axios.get(`${Baseurl}/ecl/calculation/activityLog/${ecl_workflow_id}`);
      // const response = await axios.get(`${Baseurl}/ecl/calculation/dataQualityActivity/${ecl_workflow_id}`);
      if (response?.data?.status === 200) {
        setActiviyLogs(response?.data?.historyDatas);
      }
    } catch (error) {
      console.log("API not working", error);
    } finally {
      console.log("API not working", 'finally');
    }
  };

  const getSummaryApiCall = async (data, urls) => {
    try {
      const categoriesWithFilePath = data?.map((field, i) => ({
        id: field?.id,
        category_name: field?.category,
        file_path: urls[i],
        uploaded_on: getCurrentDateTime(),
        file_size: field?.size,
        fileName: field?.fileName,
        sheetName: field?.sheetName
      }));
      // const categoriesWithFilePath = JSON.parse(localStorage.getItem(localStorageKeys.file_details));

      const oldCategory = localStorage.getItem(localStorageKeys.oldCategory)
      const request = {
        "user_id": localStorage.getItem(loginLocalStorageKeys.loginId),
        "ecl_workflow_node_id": isUpdate ? workflow_node_id : "",
        "ecl_workflow_id": ecl_workflow_id != null || ecl_workflow_id != undefined ? ecl_workflow_id : "",
        "ecl_node_id": ecl_node_id ?? draggedTileId,
        "category_datas": categoriesWithFilePath,
        "old_category_datas": isUpdate && workflow_node_id ? JSON.parse(oldCategory) ?? [] : [],
        "client_id": location?.state?.clientId
      }
      //  dispatch(NodeDetailsDataClear())
      dispatch(perticularNodeDataClear())
      dispatch(fileUploadSummaryClear())

      const response = await dispatch(getFileUploadSummary(request))
      if (response) {
        localStorage.setItem(localStorageKeys.nodeDetails, JSON.stringify(categoriesWithFilePath));
        localStorage.setItem(localStorageKeys.oldCategory, JSON.stringify(categoriesWithFilePath));
        setUploadAnother(false)
        setNearestNodeClicked(true)
        setSaveAndProceed(true)
        setIsNew(false)
        setApiCalled(true);
        // setCategory(null);
        setSelectedTile(false)
        setError(false)
        setDropped(false)
      }
    }
    catch (error) {
      // Handle any unexpected errors during the API call
      console.error('Error during API call:', error);
    }
  }
  //useEffects

  useEffect(() => {
    if (location?.state?.continue || location?.state?.isfromHistory) {
      setUploadAnother(true)
      setIsNew(false)
      setSaveAndProceed(false)
      setTimeout(() => {
        setIsLoading(false)
      }, 2000);
    }
    if (location?.state?.newClicked) {
      setIsLoading(false)
    }
  }, [location?.state])

  useEffect(() => {
    if (!saveAndProceed) {
      localStorage.removeItem(localStorageKeys.oldCategory);
      setIsUpdate(false)
    } else if (uploadAnother) {
      localStorage.removeItem(localStorageKeys.oldCategory);
      setIsUpdate(false)
    }
  }, [saveAndProceed, uploadAnother])

  useEffect(() => {
    if (selectedNodeSummary?.length > 0) {
      setIsLoading(false)
      setShowSummaryLoader(false)
      setMergeSummaryLoader(false)
      if (enableModelBuilder) {
        setModelBuilderOpen(true)
        setEnableModelBuilder(false)
      }
    }
    if (mergeSummaryData?.mergedResults?.length > 0) {
      setShowSummaryLoader(false)
    }
    if (fileUploadSummary?.length > 0) {
      setShowSummaryLoader(false)
    }
    if(mergeSummaryTableData?.length > 0){
      setShowSummaryLoader(false)
      setEnablePositionAnalysisSave(true)
    }
    if(renameColumnList?.renameDatas?.length > 0 || removeColumnList?.removeDatas?.length > 0 || removeDuplicateRowsList?.removeDuplicateRowDatas?.length > 0){
      setShowSummaryLoader(false)
    }
    
  }, [selectedNodeSummary, mergeSummaryData, fileUploadSummary,mergeSummaryTableData,renameColumnList,removeColumnList,removeDuplicateRowsList])

  const getAssignDataFormatTableData = (id) => {
    // setShowLoader(true);
    if(nodeCategoryId){
    axios(`${Baseurl + ASSIGNED_DATA_TYPE_LIST}`, {
      method: "POST",
      data: {
        workflow_id: ecl_workflow_id,
        ecl_node_id: id ?? 5,
        category_id: nodeCategoryId
      },
    })
      .then((response) => {
        if (response?.data?.status === 200) {
          console.log(response?.data?.message, 'message');
          setAssignDataTypeFormatData(response?.data);
          setShowEdit(true)
          setShowSummaryLoader(false)
          // setShowLoader(false);
          // setDisabled(true)
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
    }else{
      toast.error('node category must')
    } 
  };

  const getTableData = (isFrom,ecl_node_id,usedCategoryId) => {

    if(usedCategoryId){

    axios(`${Baseurl + `${isFrom =='rename' ?  RENAMED_COLUMN_LIST : isFrom=='remove' ? REMOVED_COLUMN_NAMES_LIST: isFrom=='duplicateRowRemove' ? REMOVE_DUPLICATE_ROW :'' }`}`, {
      method: "POST",
      data: {
        workflow_id: ecl_workflow_id,
        ecl_node_id: ecl_node_id,
        category_id: usedCategoryId
      },
    })
      .then((response) => {
        if (response?.data?.status === 200) {
          if(isFrom =='rename'){
          setRenameColumnList(response?.data)
        }else if(isFrom == 'remove'){
          setRemoveColumnList(response?.data)
        }else if(isFrom =='duplicateRowRemove'){
          setRemoveDuplicateRowList(response?.data)
        }
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
    }else{
      toast.error('node category must')
    } 
  };


  useEffect(() => {

    if (workflowNodeData?.length > 0) {
      workflowNodeData?.map((item, index) => {
        const isLastItem = index == workflowNodeData?.length - 1;
        const matchingItem = workflowNodeData.find(item => item.id == tempWorkFlowId);
        const abortController = new AbortController();

        const fetchData = async (id,ecl_node_id,usedCategoryId) => {
          dispatch(perticularNodeDataClear())
          dispatch(fileUploadSummaryClear())
          setSelectedNodeSummary([])
          setFileUploadSummary([])
          try {
            // Make sure to pass the controller's signal to the dispatch function
            if(ecl_node_id == 4 ){
              if (!isPosSaveClick.current) {
                isPosSaveClick.current = false
                getMergedFilesAccordin(ecl_node_id, 'viewPosition')
              }
            }else if(ecl_node_id == 5){
              getAssignDataFormatTableData(ecl_node_id,usedCategoryId)
            }else if(ecl_node_id ==6){
              getTableData('rename',6,usedCategoryId)
            }
            else if(ecl_node_id ==7){
              getTableData('remove',7,usedCategoryId)
            }
            else if(ecl_node_id ==9){
              getTableData('duplicateRowRemove',9,usedCategoryId)
            }
            else if(ecl_node_id==1 || ecl_node_id==2){
              const request = {
                workflow_node_id: id,
                page: 1,
                record_per_page: 10,
                sort_by: "",
                sort_order: "",
                file_path: "",
             
              }
              const data = await dispatch(getSingleFilePerticularList(request), { signal: abortController.signal });                      
            }
            setNodeSaved(true)
          } catch (error) {
            // Check if the error is due to an aborted request
            if (error.name === 'AbortError') {
              console.log('Request aborted');
            } else {
              console.error('Error fetching data:', error);
            }
          }
        };

        if (matchingItem && Object.keys(matchingItem).length > 0) {
          workflow_node_id = matchingItem?.id;
          ecl_workflow_id = matchingItem?.ecl_workflow_id;
          ecl_node_id = matchingItem?.ecl_node_id;
          nodeCategoryId= matchingItem?.nodeCategoryId;
          setCategory(matchingItem?.nodeCategoryId)
          setSelectedTileId(matchingItem?.ecl_node_id);
          setSelectedWorkFlowNode(matchingItem?.ecl_node_id,matchingItem?.nodeCategoryId);
          tempWorkFlowId = undefined;

          if (reArangePipeLine) {
            setShowSummaryLoader(true);
          }
          fetchData(matchingItem?.id,matchingItem?.ecl_node_id)
          setApiCalled(true);
          return () => {
            abortController.abort(); // Abort the ongoing request
          };
        } else {
          if (isLastItem && !matchingItem) {
            workflow_node_id = item?.id;
            ecl_workflow_id = item?.ecl_workflow_id
            ecl_node_id = item?.ecl_node_id
            nodeCategoryId= item?.nodeCategoryId;
            setCategory(item?.nodeCategoryId)
            setSelectedTileId(ecl_node_id)
            setSelectedWorkFlowNode(item?.ecl_node_id)
            if (reArangePipeLine) {
              setShowSummaryLoader(true)
            }
            fetchData(item?.id,item?.ecl_node_id,item?.nodeCategoryId)
            setApiCalled(true)
            return () => {
              abortController.abort(); // Abort the ongoing request
            };
          }
        }
      })
    } else {
      if (clonedImages?.length <= 0 || location?.state?.isfromHistory && workflowNodeData?.length <= 0 || location?.state?.continue && workflowNodeData?.length <= 0) {
        setShowSummaryLoader(false)
      } else {
        if (clonedImages?.length != 0) {
          setShowSummaryLoader(true)
        }
      }
    }
    if (workflowNodeData?.length > 0 && saveAndAnotherRef?.current) {
      setShowSummaryLoader(false)
      NextStepUploadAnother()      
    }
    setRenameColumnList({})

  }, [workflowNodeData])

  useEffect(() => {
    if (fileUploadSummary?.length > 0 && (uploadAnother || !saveAndProceed)) {
      fileUploadSummary?.map((item, index) => {
        const isLastItem = index == fileUploadSummary?.length - 1;
        const matchingItem = workflowNodeData.find(item => item.id === tempWorkFlowId);
        const lastData = item?.node_value_summary?.[item?.node_value_summary.length - 1];
        if (matchingItem && Object.keys(matchingItem).length > 0) {
          workflow_node_id = matchingItem?.workflow_node_id;
          ecl_workflow_id = matchingItem?.ecl_workflow_id;
          ecl_node_id = matchingItem?.ecl_node_id;
          nodeCategoryId = matchingItem?.nodeCategoryId
          setSelectedTileId(matchingItem?.ecl_node_id);
          setSelectedWorkFlowNode(matchingItem?.ecl_node_id);
          tempWorkFlowId = undefined;

          if (reArangePipeLine) {
            setShowSummaryLoader(true);
          }
          const request = {
            workflow_node_id: matchingItem?.workflow_node_id,
            page: 1,
            record_per_page: 10,
            sort_by: "",
            sort_order: "",
            file_path: "",
         
          }
          dispatch(getSingleFilePerticularList(request));
          setApiCalled(true);
        } else if (isLastItem && !matchingItem) {
          workflow_node_id = item?.workflow_node_id;
          ecl_workflow_id = item?.ecl_workflow_id
          ecl_node_id = item?.ecl_node_id
          nodeCategoryId = item?.nodeCategoryId
          setSelectedTileId(ecl_node_id)
          setSelectedWorkFlowNode(item?.ecl_node_id)
          if (reArangePipeLine) {
            setShowSummaryLoader(true)
          }
          const request = {
            workflow_node_id: item?.workflow_node_id,
            page: 1,
            record_per_page: 10,
            sort_by: "",
            sort_order: "",
            file_path: "",
         
          }
          dispatch(getSingleFilePerticularList(request))
          // setApiCalled(true)
        }
      })
    } else {
      if (location?.state?.isfromHistory && workflowNodeData?.length <= 0 || location?.state?.continue && workflowNodeData?.length <= 0 || clonedImages?.length == 0) {
        setShowSummaryLoader(false)
      } else {
        if (clonedImages?.length != 0) {
          setShowSummaryLoader(true)
        }
      }
    }

  }, [])

  useEffect(()=>{
    if(location?.state?.continue || location?.state?.isfromHistory || step==4){
      setShowMenu(false)
    }
    
  },[step])

  useEffect(() => {
    if (location?.state?.workFlowId && location?.state?.step) {
      localStorage.setItem(localStorageKeys.currentWorkFlowId, location?.state?.workFlowId)
    }
  }, [location?.state?.workFlowId])

  useEffect(() => {
    const currentWorkFlowId = localStorage.getItem(localStorageKeys.currentWorkFlowId)
    if (currentWorkFlowId && initialRedering) {
      setStep(4)
      setUploadAnother(true)
      setSaveAndProceed(false)
      setIsNew(false)
      setIsTemplate('New')
      setNearestNodeClicked(true)
      setShowSummaryLoader(true)
      if ((currentWorkFlowId) && !afterSave)
        dispatch(getNodeDetails(currentWorkFlowId))
      setInitialRendering(false)
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      setMergeSummaryTableData([])
      dispatch(perticularNodeDataClear())
      dispatch(fileUploadSummaryClear())
      setSelectedNodeSummary([])
      setFileUploadSummary([])
      setAssignDataTypeFormatData({})
      setRenameColumnList({});
      setRemoveColumnList({});
      setRemoveDuplicateRowList({});

      if (selectedTileId === 4) {
        try {
          const response = await getPositionAnalysisCategory(ecl_workflow_id);
          // Use postionAnalysisCategory here
          if (response.status == 200) {
            const transformedArray = response.data.data?.map((obj) => ({
              label: obj?.category_name,
              value: obj?.category_id,
            }));
            setPostionAnalysisCategory(transformedArray)
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [selectedTileId,draggedTileId]);

  const onClickProceed = () => {
    axios(`${Baseurl + TEMPLATE_NAME_CHECK}`, {
      method: "POST",
      data: {
        "template_name": templateName
      },
    })
      .then((response) => {
        if (response?.data?.status == 200) {
          setStep(4);
          setIsTemplate('New');
          dispatch(getCategoryList(location?.state?.clientId, location.state?.levelTwoCardId));
          setIsNew(true)
          setShowMenu(false)

        } else if (response?.data?.status == 500) {
          toast.error(response?.data?.message)
          setTemplateName('')
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      })
  }

  const onDeleteClick = (data) => {
    dispatch(fileDetailsDataClear())
    setReplaceClicked(true);
    dispatch(getFileDetails(ecl_workflow_id, workflow_node_id, data?.category_id));
    setDeletePopup(true);
    setCategoryId(data?.category_id)
  }

  const calculationApiCallMultiple = (url, data, isFrom, isDelete,category_id) => {    
      const postFormData = CalculationMultipleReq;
      postFormData.append('ecl_workflow_id', ecl_workflow_id != null || ecl_workflow_id != undefined ? ecl_workflow_id : "");
      postFormData.append('user_id', localStorage.getItem(loginLocalStorageKeys.loginId));
      postFormData.append('client_branch_level_id', location?.state?.levelTwoCardId);          
      axios.post("https://ecl.port0.org/laravelapi/api/ecl/calculation/multiple", postFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response) => {
          // handle the response
          if (response?.data?.status == 200) {  
            apiCallForListingNode(response?.data?.workflowId, isFrom, isDelete)            
            setReplaceClicked(false)
            if (isFrom != 'another') {
              localStorage.setItem(localStorageKeys.lastNodeStatus, 'completed')
            }
          }
        })
        .catch((error) => {
          setProgress(0);
          console.log(error);
        });    
  }
  const anyOneChecked = isdeletableFileDetails?.some(row => row?.sub_sheets.some(sheet => sheet?.checked == true));
  

console.log(isFileSetToS3 , uploadAnother, clonedImages, replaceClicked,'saved node')

console.log(assignDataTypeFormatData,'assignDataTypeFormatData111');
console.log(renameColumnList,'renameColumnlist');
console.log(mergeSummaryTableData,'mergeSummaryTableData');
console.log(isNew, isUpdate, clonedImages?.length ,'required datas');

  return (
    <>
      {(deletePopup && categoryId) &&
        <CommonPopupLayout
          show={true}
          title={'Select sheets to remove'}
          buttons={true}
          disabled={!anyOneChecked}
          isFrom="delete"
          onCancel={() => { setReplaceClicked(false); setDeletePopup(false) }}
          onSubmit={() => { setShowSummaryLoader(true); calculationApiCallMultiple(null,null,'delete',true) }}
          content={
            <DeletePopUpContent
              headers={deletePopupTableHeader}
              workflow_id={ecl_workflow_id}
              workflow_node_id={workflow_node_id}
              category_id={categoryId}
              fixedHeader={true}
              setIsdeletableFileDetails={setIsdeletableFileDetails}
              setCalculationMultipleReq={setCalculationMultipleReq}              
              headerclassName={'bg-[#2B144C] text-[13px] text-white'} />
          }
        />}      
      {showSubSheetPopup &&
        <SubSheetsListPopup
          show={true}
          dropDownOptions={categoryList}
          setProgress={setProgress}
          data={fileDetails}
          onSubmit={(data, urls) => getSummaryApiCall(data, urls)}
          onCancel={() => setShowSubSheetPopup(false)}
          setIsFileSentToS3={setIsFileSentToS3}
          setDropPointError={setError}
          setShowSummaryLoader={setShowSummaryLoader}
          setIsUploading={setIsUploading}
        />
      }
      <div className='h-screen fixed w-full'>        
        <DragAndDropComponent
          showSummaryLoader={showSummaryLoader}
          workflowNodeData={workflowNodeData}
          modelBuilderOpen={modelBuilderOpen}
          closeDetails={closeDetails}
          sections={sections}
          handleItemClick={handleItemClick}
          activeIndices={activeIndices}
          drag={drag}
          step={step}
          handleCardClick={handleCardClick}
          isFileSetToS3={isFileSetToS3}
          checkWorkFlowData={checkWorkFlowData}
          clonedImages={clonedImages}
          nodeSaved={nodeSaved}

        />
        <div className='custom-reduce-hight'>
          <div className='w-full bg-white h-full'>
            <div className='flex justify-between xl:py-3 py-1.5 xl:px-[4rem] px-[3rem] sticky xl:top-[4rem] top-[2.5rem] bg-white z-10'>
              <div className='flex gap-2 items-center'>
                <div className='flex gap-2 items-center cursor-pointer' onClick={() => goToPrevPage()} >
                  <LeftArrow bgColor={'#d0cece'} className={'xl:w-[25px] xl:h-[25px] w-[20px] h-[20px]'} />
                  <p className='text-gray-light xl:text-[15px] text-[13px]'>Back</p>
                </div> 
                <div className='text-[13px] 2xl:text-[14px] flex gap-1 items-center text-dark-blue font-medium'>

         
                    {location?.state?.breadCrumbClientName}
                    <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 
                    {location?.state?.breadCrumbCountryName}
                    <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 
                    {location?.state?.breadCrumbLevelOne}
                    <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 
                    {location?.state?.breadCrumbLevelTwo}
                    <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 
                    {location?.state?.levelTwoCategory}
                    </div>

                {/* <p className='text-[12px] xl:text-[14px]'>
                  {location?.state?.breadCrumbClientName + "-" + location?.state?.breadCrumbCountryName + "-" + location?.state?.breadCrumbLevelOne + "-" + location?.state?.breadCrumbLevelTwo + "-" + location?.state?.levelTwoCategory}
                  </p> */}
              </div>
              <div className='flex items-center gap-4'>
                <HamburgerMenu color={'#707070'} className={'xl:w-[35px] xl:h-[35px] w-[30px] h-[30px]'} onClick={() => setShowMenu(!showMenu)} showMenu={showMenu} />
                <Button bgColor='#5B9BD5' size="xs" paddingY={'xl:py-1.5 py-1 rounded-[5px]'} label={'Activity Log'} color={'#FFFFFF'} onClick={() => { getActivityLog(); setActivityLogOpen(true) }} icon={'activeLog'} />
                <Button bgColor='#47B4AE' size="xs" paddingY={'xl:py-1.5 py-1 rounded-[5px]'} label={'Model Builder'} color={'#FFFFFF'} onClick={() => actionsClick(location?.state?.clientId, 'modelBuilder')} icon={'modelBuilder'} disabled={showSummaryLoader} />
                <Button bgColor='#00B050' size="xs" paddingY={'xl:py-1.5 py-1 rounded-[5px]'} label={'New'} color={'#FFFFFF'} onClick={() => actionsClick(location?.state?.clientId, 'new')} icon={'new'} disabled={true} />
              </div>
              {/* history sliding pane */}
              <SlidingPane
                className="custom-slide-pane-overflow"
                overlayClassName="custom-overlay-class z-[101]"
                isOpen={historyPaneOpen}
                width="68%"
                hideHeader
                shouldCloseOnEsc={true}
                onRequestClose={() => {
                  setHistoryPaneOpen(true)
                }}
              >
                <EclHistory title={'ECL History'}
                  onClose={closeDetails}
                  headerBg={'bg-[#5B9BD5]'}
                  borderColor={'border-[#5B9BD5]'}
                  isOpen={historyPaneOpen}
                />

              </SlidingPane>
              {/* activity log sliding pane */}
              <SlidingPane
                className="custom-slide-pane-overflow"
                overlayClassName="custom-overlay-class z-[101]"
                isOpen={activityLogOpen}
                width="35%"
                hideHeader
                shouldCloseOnEsc={true}
                onRequestClose={() => {
                  setActivityLogOpen(true)
                }}
              >
                <ActivityHistoryList isOpen={historyPaneOpen} onClose={closeDetails} activityData={activiyLogs} headerBg={'bg-[#42B2AC]'} borderColor={'border-[#42B2AC]'} title={'Activity logs'} />
              </SlidingPane>
            </div>
            {isLoading ?
              <div className={`w-full 2xl:h-[85%] lxl:h-[85%] lg:h-[87%] xlg:h-[88%] h-[82%] pt-4 pl-[4rem] pr-[4rem]`}>
                <NewCalculationSkeleton />
              </div>
              :
              <div className={`w-full ${selectedTileId ==1 || selectedTileId ==2 ? '2xl:h-[85%] lxl:h-[82%] lg:h-[80%]' : selectedTileId ==5 ? 'h-[98%]': 'h-[92%]'} flex ${showMenu ? 'gap-2' : 'gap-0'} xl:px-[4rem] px-[3rem]`}>
                <div className={`transition-all duration-700 ${showMenu ? 'w-[22%] xlg:p-2 p-1' : 'w-0 mt-2 opacity-0 brightness-0 invert-[1] invisible'} h-full bg-[#F2F2F2] relative`}>
                  <div className='h-[90%] overflow-y-auto thin-scrollbar'>
                    {step >= 1 &&
                      <div className='space-y-2'>
                        <div className='bg-[#4F2D7F] text-white text-[12px] xl:text-[14px] xl:p-2 p-[0.3rem]'>
                          Step 1 – Calculation date
                        </div>
                        <Controller
                          control={control}
                          name="calculationDate"
                          render={({ field }) => {
                            return (
                              <DatePicker
                                key={step}
                                id={"CalculationDate"}
                                value={calculationDateChanged ? field.value : calculationDate ? dayjs(calculationDate, 'DD/MM/YY') : field.value}
                                ref={field.ref}
                                name={field.name}
                                onBlur={field.onBlur}
                                showSearch={false}
                                allowClear={false}
                                placement="bottomLeft"
                                size="small"
                                className="custom-select-height text-[11px] pl-[0.5rem] custom-disabled-datepicker placeholder:text-[#242B3B]"

                                style={{
                                  width: "100%",
                                  border: " 2px solid #00A7B5",
                                  // height: '2.2rem',
                                  borderRadius: '4px',
                                  cursor: location?.state?.isfromHistory || step > 1 ? 'not-allowed' : 'pointer',
                                  backgroundColor: '#ffffff',
                                }}
                                disabled={location?.state?.isfromHistory || step > 1}
                                showToday={false}
                                disabledDate={disabledDate}
                                placeholder="Select date"
                                {...register("calculationDate", { required: "Please select date!" })}
                                onChange={(date) => {
                                  field.onChange(date)
                                  setCalculationDateChanged(true)
                                  const val = (date.$D + '/' + (parseInt(date.$M) + 1) + '/' + date.$y)
                                  isSettingCalculationDate(val)
                                }
                                }
                                format={"DD-MM-YY"}
                                suffixIcon={<CalenderIcon color="#707070" className={"xl:w-[15px] xl:h-[15px] w-[12px] h-[12px]"} />}
                              />
                            );
                          }}
                        />
                        <p className={`text-[#331D53] underline xl:text-[14px] text-[12px] text-center font-semibold ${calculationDate && step == 1 ? 'cursor-pointer' : 'pointer-events-none'}`} onClick={() => setStep(2)}>Save and proceed</p>
                      </div>
                    }
                    {step >= 2 &&
                      <div className='space-y-2 mt-4'>
                        <div className='bg-[#4F2D7F] text-white xl:text-[14px] text-[12px] xl:p-2 p-[0.3rem]'>
                          Step 2 – Calculation approach
                        </div>
                        <Controller
                          control={control}
                          name="calculationApproach"
                          id="calculationApproach"
                          render={({ field }) => {
                            return (
                              <Select
                                showSearch
                                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                ref={field.ref}
                                name={field.name}
                                onBlur={field.onBlur}
                                {...register("calculationApproach")}
                                onChange={(e) => {
                                  field.onChange(e);
                                  setCalculationApproach(e)
                                }}
                                suffixIcon={<DownArrow color="#cad3d0" className={`${calAprochDropDown ? 'transition-transform transform rotate-180' : ''} xl:w-[12px] xl:h-[12px] h-[10px] w-[10px]`} />}
                                placeholder="Select from pipeline"
                                disabled={location?.state?.isfromHistory || step > 2}
                                className={"custom-select-height custom-disabled-select border-[2px] focus:border-[2px] focus:border-greeny-blue focus:outline-none text-[#BEBEBE] placeholder:text-[#BEBEBE] focus-within:border-none"}
                                status={errors?.calculationApproach && errors?.calculationApproach.type == "NameCheck" ? "error" : ""}
                                style={{
                                  width: "100%",
                                  border: " 2px solid #00A7B5",
                                  // height: '2.2rem',
                                  borderRadius: '4px',
                                  backgroundColor: '#ffffff',
                                  cursor: location?.state?.isfromHistory || step > 2 ? 'not-allowed' : 'pointer',
                                }}
                              >
                                {calculationApproach?.map((currency, i) => (
                                  <Option key={currency?.id} value={currency?.id}>
                                    {currency?.currency}
                                  </Option>
                                ))}
                              </Select>
                            );
                          }}
                        />
                        <p className={`text-[#331D53] underline xl:text-[14px] text-[12px] text-center font-semibold ${calculationApproach?.length > 0 && step == 2 ? 'cursor-pointer' : 'pointer-events-none'}`}>Save and proceed</p>
                        <p className='text-[#331D53] text-center text-[12px] xl:text-[14px]'>or</p>
                        <div className='flex items-center justify-center'>
                          <Button bgColor='#00A7B5' borderType="" label={'Set up new'} color={'#ffffff'} onClick={() => setStep(3)} size={'md'} paddingY={'xl:py-1.5 py-1 rounded-[5px]'} disabled={!calculationDate || step != 2} />
                        </div>

                      </div>
                    }
                    {step >= 3 &&
                      <div className='space-y-2 mt-4'>
                        <div className='bg-[#4F2D7F] text-white  text-[12px] xl:text-[14px] xl:p-2 p-[0.3rem]'>
                          Step 3 – Pipeline name
                        </div>
                        <input type="text" placeholder="Enter pipeline name" disabled={step == 4} onChange={(e) => setTemplateName(e.target.value)} value={templateName} className={"w-full border-[2px] focus:border-[2px] rounded custom-select-height xl:text-[14px] text-[12px] pl-3 border-greeny-blue focus:outline-greeny-blue text-[#BEBEBE] placeholder:text-[#BEBEBE]"} />
                        <div className='flex items-center justify-center'>
                          <Button bgColor='#00A7B5' borderType="" label={'Proceed'} color={'#ffffff'} onClick={() => onClickProceed()} size={'md'} paddingY={'xl:py-1.5 py-1 rounded-[5px]'} disabled={!templateName || templateName == null || step == 4} />
                        </div>

                      </div>
                    }
                  </div>
                  <div className='w-[93%] absolute bottom-[5%]'>
                      <div className='flex gap-4 items-center justify-center'>
                        <p className='text-[#331D53] xl:text-[14px] text-[12px] cursor-pointer underline text-center whitespace-nowrap ' onClick={() => goToPrevPage()}>Exit wizard</p>
                      </div>
                  </div>
                </div>
                {/* right side content */}
                <div className={` ${showMenu ? 'w-[78%]' : 'w-full'} h-full relative transition-all duration-700`}>
                  {step == 4 &&
                    <div className='custom-hight-calculation'>
                      <div className='bg-[#4F2D7F] text-white text-[12px] xl:text-[14px] xl:py-2 py-[0.3rem] pr-4 pl-2 xl:mt-2 flex justify-between'>
                        <p>
                          Step 4 – ECL Calculation
                        </p>
                        {clonedImages?.length > 0 &&
                        <p className='text-[#FFC048]'>{modelBuilderSections?.find(data => data?.id == selectedTileId)?.name || ""}</p>
                        }
                        <p className='xl:text-[13px] text-[11px] font-normal'>
                          {calculationDate}&nbsp;/&nbsp;{templateName}
                        </p>
                      </div>

                        <DropComponent
                          ecl_workflow_id={ecl_workflow_id}
                          setModelBuilderOpen={setModelBuilderOpen}
                          workflow_node_id={workflow_node_id}
                          selectedTileId={selectedTileId}
                          setSelectedTile={setSelectedTile}
                          setIsFileSentToS3={setIsFileSentToS3}
                          setIsNew={setIsNew}
                          onDraggedTileClick={onDraggedTileClick}
                          workflowNodeData={workflowNodeData}
                          setSelectedTileId={setSelectedTileId}
                          setClonedImages={setClonedImages}
                          clonedImages={clonedImages}
                          isFileSetToS3={isFileSetToS3}
                          error={error}
                          setError={setError}
                          setDeleteing={setDeleteing}
                          uploadAnother={uploadAnother}
                          saveAndProceed={saveAndProceed}
                          categoryChanged={categoryChanged}
                          isUpdate={isUpdate}
                          setIsUpdate={setIsUpdate}
                          setDropped={setDropped}
                          setNearestNodeClicked={setNearestNodeClicked}
                          setUploadAnother={setUploadAnother}
                          draggedTileId={draggedTileId}
                          setShowSummaryLoader={setShowSummaryLoader}
                          clickedNode={clickedNode}
                          setClickedNode={setClickedNode}
                          setMergeSummaryLoader={setMergeSummaryLoader}
                          replaceClicked={replaceClicked}
                          setCurrentAccordinOpen={setCurrentAccordinOpen}
                          setSaveAndProceed={setSaveAndProceed}
                          rendered={rendered}
                          setRendered={setRendered}
                          setCategoryId={setCategoryId}
                          setMergeSummaryTableData={setMergeSummaryTableData}
                          nodeSaved={nodeSaved}
                          setAssignDataTypeFormatData={setAssignDataTypeFormatData}
                          setRenameColumnList={setRenameColumnList}
                          setRemoveColumnList={setRemoveColumnList}
                          setRemoveDuplicateRowList ={setRemoveDuplicateRowList}
                        />                      

                      {isUploading ?
                        <div className='w-full mt-10'>
                          {/* progress bar*/}
                          <div className='flex justify-center'>
                            <progress value={progress} max='100' style={{ width: '60%' }} />
                          </div>
                          <p className='text-[#000000] text-[12px] text-center mt-3'>{`File upload in progress - ${progress}% completed`}</p>
                        </div>
                        :
                        (isNew || isUpdate && !nearestNodeClicked) || mergeSummaryTableData?.length > 0 ?
                          clonedImages?.length > 0 ?                            
                            selectedTileId == 1 || selectedTileId == 2 && isDropped ?
                              <div className='flex gap-4 w-full p-2 xl:mt-12'>
                                <div className='w-[100%]'>
                                  <MultiFileUploadFeilds
                                    categoryList={categoryList}
                                    setIsUploading={setIsUploading}
                                    setProgress={setProgress}
                                    ecl_workflow_id={ecl_workflow_id}
                                    setDropPointError={setError}
                                    setShowSubSheetPopup={setShowSubSheetPopup}
                                    setFileDetails={setFileDetails}
                                    setIsFileSentToS3={setIsFileSentToS3}
                                    setShowSummaryLoader={setShowSummaryLoader}
                                    onSubmit={(data, urls) => getSummaryApiCall(data, urls)}
                                    isFrom={selectedTileId == 1 ? 'single' : selectedTileId == 2 ? 'multiple' : 'null'}
                                  />
                                </div>
                              </div>
                              : selectedTileId == 4 ?  
                              <>
                              
                                {enablePositionAnalysisSave ?                           
                                <div className='my-2'>                                   
                                  <div className='flex gap-5 pl-3'>
                                    <DropDown
                                      dropDownOptions={postionAnalysisCategory}
                                      placeholder={'Select data category'}
                                      name={'usedCategory'}
                                      ordinary={true}
                                      disabled={location?.state?.isfromHistory|| mergeSummaryTableRequest != null}
                                      customTags={false}
                                      selected={category ? category : undefined}
                                      onOptionChange={(val) => {setCategory(val); nodeCategoryId=val;}}
                                    />
                                    <Button 
                                    bgColor='#42B2AC' 
                                    borderType="" 
                                        disabled={!category || location?.state?.isfromHistory || mergeSummaryTableRequest != null} 
                                    label={'Save'} 
                                    color={'#ffffff'} 
                                    onClick={() => { setMergeSummaryTableData([]); 
                                      setPositionAnalysisLoader(true);
                                      getMergedFilesTable(nodeCategoryId ?? categoryId, 1, draggedTileId,'postionAnalysis')                                    
                                    }} 
                                    size={'md'} />
                                  </div>
                                  <div className='h-screen'>
                                    {
                                      mergeSummaryTableData?.length > 0 ?
                                          <div className='md:h-[50%] lg:h-[52%] xl:h-[50%] lxl:h-[55%] 2xl:h-[60%] pl-3 md:pb-[80px] lg:pb-[50px]  overflow-y-auto thin-scrollbar'>
                                            <div className='p-2 text-[13px] space-y-3 mt-4 rounded overflow-y-auto thin-scrollbar border border-[#D0CECE]'>
                                            <div className="grid grid-cols-3">
                                              <div className='col-span-1'>
                                                <div className='grid grid-cols-5 gap-3'>
                                                  <p className=' col-span-2 whitespace-nowrap'>Data category</p>
                                                  <p className='col-span-3 font-semibold'><OverflowTooltip text={mergeSummaryTableData[0].CategoryName} /></p>
                                                </div>
                                              </div>
                                              <div className='col-span-1'>
                                                <div className='grid grid-cols-5 gap-3'>
                                                  <p className=' col-span-2 whitespace-nowrap'>No.of rows</p>
                                                  <p className='col-span-3 font-semibold'><OverflowTooltip text={mergeSummaryTableData[0].totalRecords} /></p>
                                                </div>
                                              </div>
                                              <div className='col-span-1'>
                                                <div className='grid grid-cols-5 gap-3'>
                                                  <p className=' col-span-2 whitespace-nowrap'>No.of columns</p>
                                                  <p className='col-span-3 font-semibold'><OverflowTooltip text={mergeSummaryTableData[0].totalColumns} /></p>
                                                </div>
                                              </div>

                                            </div>
                                          </div>
                                            <div className='mt-2 w-full'>
                                              <div className='w-full overflow-x-hidden overflow-y-auto thin-scrollbar'>
                                              {mergeSummaryTableData[0]?.columnDatas && 
                                              <PositionAnalysisTable 
                                                initialData={mergeSummaryTableData[0]}
                                                category_id={categoryId ?? nodeCategoryId}
                                                ecl_node_id={draggedTileId ?? selectedWorkFlowNode} 
                                                workflow_id={ecl_workflow_id} 
                                                request={mergeSummaryTableRequest} 
                                                current_headers={mergeSummaryTableData[0]?.headers}
                                                tableHeaders={positionAnalysisTableHeader}
                                                isDownloadxls={true}
                                                totalColumns={mergeSummaryTableData[0]?.totalColumns}
                                                totalRecords={mergeSummaryTableData[0]?.totalRecords}
                                                  summary={mergeSummaryTableData[0]}
                                                isFor={'positionAnalysis'}
                                                isOptionsDisabled={location?.state?.isfromHistory}
                                              />}
                                            </div>
                                          </div>
                                        </div>
                                        : categoryId && mergeSummaryTableData?.length <= 0 && positionAnalysisLoader ?
                                          <div className='mt-2'>
                                            <DataTableSkeleton showHeader={true}  columnWidths={''} rowCount={5} columnCount={7} showPagination={false} showActionableButtons={false} from='mergeSummary' isDownload={false} />
                                          </div>
                                          : <></>

                                    }
                                  </div>
                                </div>
                                // <PositionAnalysis 
                                // selectedTileId= {selectedTileId}
                                // ecl_workflow_id= {ecl_workflow_id} 
                                // mergeSummaryTableData={mergeSummaryTableData}
                                // setMergeSummaryTableData ={setMergeSummaryTableData}
                                // mergeSummaryTableRequest ={mergeSummaryTableRequest}
                                // setPositionAnalysisLoader ={setPositionAnalysisLoader}
                                // getMergedFilesTable ={getMergedFilesTable}
                                // categoryId={categoryId}
                                // setCategoryId ={setCategoryId}
                                // draggedTileId ={draggedTileId}
                                // nodeCategoryId ={nodeCategoryId}
                                // selectedWorkFlowNode={selectedWorkFlowNode}
                                // positionAnalysisLoader={positionAnalysisLoader}
                                // />                              
                              : 
                              <FileUploadListSkeleton />}

                              </>
                              : selectedTileId == 5 ?
                              <AssignDataTypeFormat
                              workflow_id={ecl_workflow_id} 
                              ecl_node_id={selectedTileId}
                              workflow_node_id ={workflow_node_id}
                              calculationDate ={calculationDate}
                              templateId={templateId}
                              templateName ={templateName}
                              client_branch_level_id={location?.state?.levelTwoCardId}
                              setReplaceClicked={setReplaceClicked}
                              assignDataTypeFormatData ={assignDataTypeFormatData}
                              setAssignDataTypeFormatData={setAssignDataTypeFormatData}
                              showEdit={showEdit}
                              setShowEdit={setShowEdit}
                              nodeCategoryId={nodeCategoryId}
                              viewMode ={false}
                              setNodeSaved={setNodeSaved}
                              setError={setError}
                              isFileSetToS3={isFileSetToS3}
                              setIsFileSentToS3={setIsFileSentToS3}
                              onProceedClick ={()=>actionsClick(location?.state?.clientId, 'modelBuilder')}  
                           
                              />
                                :
                                selectedTileId == 6 ?
                                <RenameColumnName 
                                key={draggedTileId}
                                workflow_id={ecl_workflow_id} 
                                ecl_node_id={selectedTileId}
                                workflow_node_id ={workflow_node_id}
                                calculationDate ={calculationDate}
                                templateId={templateId}
                                templateName ={templateName}
                                client_branch_level_id={location?.state?.levelTwoCardId}
                                setReplaceClicked={setReplaceClicked}                                                               
                                nodeCategoryId={nodeCategoryId}
                                viewMode ={false}
                                renameColumnList={renameColumnList}
                                setRenameColumnList={setRenameColumnList}
                                setNodeSaved={setNodeSaved}
                                isFileSetToS3={isFileSetToS3}
                                setIsFileSentToS3={setIsFileSentToS3}
                                onProceedClick ={()=>actionsClick(location?.state?.clientId, 'modelBuilder')}  
                                setError={setError}                                                        
                           
                              />
                              :
                              selectedTileId == 7 ?
                              <RemoveColumnName 
                              workflow_id={ecl_workflow_id} 
                              ecl_node_id={selectedTileId}
                              workflow_node_id ={workflow_node_id}
                              calculationDate ={calculationDate}
                              templateId={templateId}
                              templateName ={templateName}
                              client_branch_level_id={location?.state?.levelTwoCardId}                              
                              nodeCategoryId={nodeCategoryId}
                              viewMode ={false}
                              setReplaceClicked={setReplaceClicked}                                                               
                              removeColumnList={removeColumnList}
                              setRemoveColumnList={setRemoveColumnList}
                              setNodeSaved={setNodeSaved}
                              isFileSetToS3={isFileSetToS3}
                              setError={setError}
                              setIsFileSentToS3={setIsFileSentToS3}
                              onProceedClick ={()=>actionsClick(location?.state?.clientId, 'modelBuilder')}                             
                            />
                            :
                            selectedTileId == 9 ?
                            <RemoveDuplicateRow 
                              workflow_id={ecl_workflow_id} 
                              ecl_node_id={selectedTileId}
                              workflow_node_id ={workflow_node_id}
                              calculationDate ={calculationDate}
                              templateId={templateId}
                              templateName ={templateName}
                              client_branch_level_id={location?.state?.levelTwoCardId}                              
                              nodeCategoryId={nodeCategoryId}
                              viewMode ={false}
                              removeDuplicateRowsList={removeDuplicateRowsList}
                              setRemoveDuplicateRowList={setRemoveDuplicateRowList}
                              setReplaceClicked={setReplaceClicked}   
                              setNodeSaved={setNodeSaved}
                              isFileSetToS3={isFileSetToS3}
                              setIsFileSentToS3={setIsFileSentToS3}
                              onProceedClick ={()=>actionsClick(location?.state?.clientId, 'modelBuilder')}                             
                              setError={setError}                                                        
                                                   
                            />
                            :<></>
                            : <></>
                          : <></>
                      }
                      {/* accordin and table view */}
                      {
                        (deleting && workflowNodeData?.length == 0) || showSummaryLoader || mergeSummaryLoader ?
                          <FileUploadListSkeleton />
                          : (selectedNodeSummary?.length > 0 || fileUploadSummary?.length > 0 || assignDataTypeFormatData?.datatypeDatas?.length > 0 || renameColumnList?.renameDatas?.length > 0 || removeColumnList?.removeDatas?.length > 0 ||removeDuplicateRowsList?.removeDuplicateRowDatas?.length > 0 ) && clonedImages?.length > 0 ?
                            <div className={`${location?.state?.isfromHistory ? 'custom-hight-calculationView':'custom-hight-calculation'} overflow-y-auto overflow-x-hidden thin-scrollbar`}>
                              <div className={`${
                                ((selectedTileId && clonedImages?.length > 0 && !isNew) || (calculationDate && !isUpdate && !selectedTile) || (location?.state?.isfromHistory || location?.state?.continue)) && (!isNew && nearestNodeClicked) ? 'block' : 'hidden'} space-y-2`}>
                                {/* accordin button  */}
                                {apiCalled || (location?.state?.isfromHistory || location?.state?.continue) ? (                                  
                                  selectedNodeSummary?.length > 0 && selectedWorkFlowNode
                                    ?
                                    (
                                      // from particular select
                                      selectedNodeSummary?.map((item, index) => {
                                        const isLastItem = index == selectedNodeSummary?.length - 1;

                                        if (isLastItem) {
                                          workflow_node_id = item?.id;
                                          ecl_workflow_id = item?.ecl_workflow_id;
                                          ecl_node_id = item?.ecl_node_id;
                                          nodeCategoryId = item?.nodeCategoryId
                                        }
                                        return (
                                          <React.Fragment key={index}>
                                            <div className={`border-2 border-greeny-blue 2xl:py-2`}>
                                              {isLastItem && item?.node_value_summary?.length > 0 ? (
                                                item?.node_value_summary?.map((data, i) => {
                                                  return (
                                                    <div key={i} className={`${(item?.node_value_summary.length - 1 == i || currentAccordinOpen == null) ? '' : 'border-b border-[#B8B5B5]'} pr-2`}>                                                     
                                                      <div className='justify-between flex p-2'>
                                                            <div className="flex gap-6 items-center">
                                                              <p className='text-[#A8A8A8] text-[13px] pl-2 font-semibold'>Data category</p>
                                                              <p className='text-[#4339A8] text-[13px] font-semibold'>{data?.category}</p>
                                                            </div>
                                                              {!location?.state?.isfromHistory && (
                                                                <img src={deleteIcon} className='w-5 h-5 cursor-pointer m-2 float-right' onClick={() => onDeleteClick(data)} />
                                                              )}
                                                            </div>
                                    
                                                      {data?.newSummaryGroup?.map((files, k) => {
                                                        return (
                                                          <>
                                                            <div className='justify-between flex p-2'>
                                                              <div className="flex gap-2 items-center">
                                                                <img src={XlsActiveIcon} className='w-4' />
                                                                <p className='text-[#000000] text-[13px] font-bold'>{files?.main_file_name}</p>
                                                              </div>                                                             
                                                            </div>
                                                            {files?.new_summary?.map((groupData, j) => {
                                                              return (
                                                                <React.Fragment key={j}>
                                                                  <div id={`index-${j}`} className='flex items-center w-full gap-3 pl-2 cursor-pointer mt-2 2xl:mt-3' onClick={() => { toggleAccordion(groupData?.uniqueId); }}>
                                                                    <img src={groupData?.uniqueId == currentAccordinOpen ? AccorDinOpend : AccorDinClosed} className='w-5 cursor-pointer' />
                                                                    <div className={`border-2 border-greeny-blue text-[12px] xl:p-3 p-2 space-y-2 w-full mb-4`}>
                                                                      <div className={`grid grid-cols-3 gap-2 `} id={groupData?.workflow_node_id} title={groupData?.workflow_node_id}>
                                                                        <div className='col-span-1'>
                                                                          <div className='grid grid-cols-3'>
                                                                            <p className='col-span-1 whitespace-nowrap'>Sheet name</p>
                                                                            <p className='col-span-2 font-semibold'><OverflowTooltip text={groupData?.fileName} /></p>
                                                                          </div>
                                                                        </div>
                                                                        <div className='col-span-1'>
                                                                          <div className='grid grid-cols-3'>
                                                                            <p className='col-span-1 whitespace-nowrap'>Uploaded on</p>
                                                                            <p className='col-span-2 font-semibold'><OverflowTooltip text={moment.utc(groupData?.uploadedOn).local().format('DD-MM-YYYY [at] h:mmA')} /></p>
                                                                          </div>
                                                                        </div>
                                                                        <div className='col-span-1'>
                                                                          <div className='grid grid-cols-3'>
                                                                            <p className='col-span-1 whitespace-nowrap'>File size</p>
                                                                            <p className='col-span-2 font-semibold'><OverflowTooltip text={getFileSize(groupData?.file_size)} /></p>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                      {groupData?.uniqueId == currentAccordinOpen ? (
                                                                        <div className='grid grid-cols-3 gap-2'>
                                                                          <div className='col-span-1'>
                                                                            <div className='grid grid-cols-3'>
                                                                              <p className='col-span-1 whitespace-nowrap'>Total records</p>
                                                                              <p className='col-span-2 font-semibold'>{groupData?.totalRecords + " rows"}</p>
                                                                            </div>
                                                                          </div>
                                                                          <div className='col-span-1'>
                                                                            <div className='grid grid-cols-3'>
                                                                              <p className='col-span-1 whitespace-nowrap'>Total columns</p>
                                                                              <p className='col-span-2 font-semibold'>{groupData?.totalColumns}</p>
                                                                            </div>
                                                                          </div>
                                                                          <div className='col-span-1'>
                                                                            <div className='grid grid-cols-3'>
                                                                              <p className='col-span-1'>Uploaded by</p>
                                                                              <p className='col-span-2 font-semibold'><OverflowTooltip text={groupData?.uploadedBy} /></p>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      ) : null}
                                                                    </div>
                                                                  </div>
                                                                  {groupData?.uniqueId == currentAccordinOpen ? (
                                                                    <div className='ml-[2.5rem]'>
                                                                      <div className='mt-4 w-full'>
                                                                        {/* <DynamicTable key={item?.id} nodeValueSummary={files?.new_summary} index={j ?? 0} fixedHeader={false} /> */}
                                                                        <PositionAnalysisTable 
                                                                          initialData={files?.new_summary[j]?.summary?.data} 
                                                                          category_id={data?.category_id}
                                                                          ecl_node_id={draggedTileId ?? selectedTileId} 
                                                                          workflow_id={ecl_workflow_id} 
                                                                          workFlow_node_id={files?.new_summary[j].workflow_node_id}
                                                                          request={mergeSummaryTableRequest} 
                                                                          tableHeaders={files?.new_summary[j]?.summary?.headers?.map((header, ind) => ({
                                                                            header: header,
                                                                            accessor: header,
                                                                            meta: {
                                                                              filterType: files?.new_summary[j]?.summary?.dataTypes[ind] === "VARCHAR" || files?.new_summary[j]?.summary?.dataTypes[ind] === "DATE"
                                                                                ? "select"
                                                                                : "range"
                                                                            }
                                                                         }))}
                                                                         summary={files?.new_summary[j]?.summary}
                                                                          current_headers={files?.new_summary[j]?.summary?.headers}
                                                                          isDownloadxls={false}
                                                                          totalColumns={files?.new_summary[j]?.totalColumns}
                                                                          totalRecords={files?.new_summary[j]?.totalRecords}
                                                                          filePath={files?.new_summary[j]?.file_path}
                                                                          file_name={groupData?.fileName}
                                                                          isOptionsDisabled={location?.state?.isfromHistory}

                                                                          />
                                                                      </div>
                                                                    </div>
                                                                  ) : null}
                                                                </React.Fragment>
                                                              )
                                                            })}
                                                          </>
                                                        )
                                                      })}
                                                    </div>
                                                  )
                                                })
                                              ) : null}
                                            </div>
                                          </React.Fragment>
                                        )
                                      })
                                    )                                    
                                    :
                                    fileUploadSummary?.length > 0 ?
                                      (
                                        //for summary of data before insert in db
                                        fileUploadSummary.map((item, index) => {
                                          
                                          return (
                                            <div className='border-2 border-greeny-blue pb-2 pr-2'>
                                              <React.Fragment key={index}>
                                                {item?.node_value_summary?.length > 0 ?
                                                  item?.node_value_summary?.map((files, j) => {
                                                    if (files?.workflow_node_id === tempWorkFlowId) {
                                                      workflow_node_id = files?.workflow_node_id;
                                                      ecl_workflow_id = item?.ecl_workflow_id
                                                      ecl_node_id = item?.ecl_node_id;
                                                    }
                                                    return(
                                                      <>
                                                    <div className='justify-between flex p-2'>
                                                      <div className="flex gap-2 items-center">
                                                        <img src={XlsActiveIcon} className='w-4' />
                                                        <p className='text-[#000000] text-[13px] font-bold'>{files?.main_file_name}</p>
                                                      </div>                                                    
                                                  </div>
                                                  {files?.new_summary?.map((data,i)=>{                                                 
                                                    return (
                                                      data?.workflow_node_id == tempWorkFlowId &&
                                                      <React.Fragment key={i}>
                                                        <div id={data?.workflow_node_id} className='flex items-center w-full gap-3 pl-2 cursor-pointer mt-2 2xl:mt-3' onClick={() => { toggleAccordion(data?.uniqueId); }}>
                                                          <img src={i == currentAccordinOpen ? AccorDinOpend : AccorDinClosed} className='w-5 cursor-pointer' />
                                                          <div className={`border-2 border-greeny-blue text-[12px] xl:p-3 p-2 space-y-2 w-full`}>
                                                            <div className='grid grid-cols-7 gap-2' title={data?.workflow_node_id} id={data?.workflow_node_id}>
                                                              <div className='col-span-2'>
                                                                <div className='grid grid-cols-3'>
                                                                  <p className='col-span-1 whitespace-nowrap'>File name</p>
                                                                  <p className='col-span-2 font-semibold'><OverflowTooltip text={data?.sheetName} /></p>
                                                                </div>
                                                              </div>
                                                              <div className='col-span-2'>
                                                                <div className='grid grid-cols-3'>
                                                                  <p className='col-span-1 whitespace-nowrap'>Category</p>
                                                                  <p className='col-span-2 font-semibold'><OverflowTooltip text={data?.CategoryName} /></p>
                                                                </div>
                                                              </div>
                                                              <div className='col-span-2'>
                                                                <div className='grid grid-cols-3'>
                                                                  <p className='col-span-1 whitespace-nowrap '>Uploaded on</p>
                                                                  <p className='col-span-2 font-semibold '><OverflowTooltip text={moment.utc(data?.uploadedOn).local().format('DD-MM-YYYY [at] h:mmA')} /></p>
                                                                </div>
                                                              </div>
                                                              <div className='col-span-1'>
                                                                <div className='grid grid-cols-2'>
                                                                  <p className='col-span-1 whitespace-nowrap'>File size</p>
                                                                  <p className='col-span-1 font-semibold'><OverflowTooltip text={getFileSize(data?.file_size)} /></p>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            {data?.uniqueId == currentAccordinOpen ? (
                                                              <div className='grid grid-cols-7 gap-2'>
                                                                <div className='col-span-2'>
                                                                  <div className='grid grid-cols-3'>
                                                                    <p className='col-span-1 whitespace-nowrap'>Total records</p>
                                                                    <p className='col-span-2 font-semibold'>{data?.totalRecords + " rows"}</p>
                                                                  </div>
                                                                </div>
                                                                <div className='col-span-2'>
                                                                  <div className='grid grid-cols-3'>
                                                                    <p className='col-span-1 whitespace-nowrap'>Total columns</p>
                                                                    <p className='col-span-2 font-semibold'>{data?.totalColumns}</p>
                                                                  </div>
                                                                </div>
                                                                <div className='col-span-2'>
                                                                  <div className='grid grid-cols-3'>
                                                                    <p className='col-span-1 whitespace-nowrap'>Uploaded by</p>
                                                                    <p className='col-span-2 font-semibold'><OverflowTooltip text={data?.uploadedBy} /></p>
                                                                  </div>
                                                                </div>
                                                              </div>

                                                            ) : null}
                                                          </div>
                                                        </div>
                                                        {data?.uniqueId == currentAccordinOpen ?
                                                          <div className='ml-[2.5rem]'>
                                                            <div className='mt-4 w-full overflow-x-auto thin-scrollbar'>
                                                              <DynamicTable key={item?.id} nodeValueSummary={files?.new_summary} index={i ?? 0} fixedHeader={false} />
                                                            </div>
                                                          </div>
                                                          :<></>
                                                        }
                                                      </React.Fragment>
                                                    )
                                                  })}
                                                    </>)
                                                 
                                                  })

                                                  : null
                                                }
                                              </React.Fragment>
                                            </div>
                                          )

                                        })
                                      )
                                      :
                                      assignDataTypeFormatData?.datatypeDatas?.length > 0 ? 
                                        <AssignDataTypeFormat
                                          workflow_id={ecl_workflow_id} 
                                          ecl_node_id={selectedTileId}
                                          workflow_node_id ={workflow_node_id}
                                          calculationDate ={calculationDate}
                                          templateId={templateId}
                                          templateName ={templateName}
                                          client_branch_level_id={location?.state?.levelTwoCardId}
                                          setReplaceClicked={setReplaceClicked}
                                          assignDataTypeFormatData ={assignDataTypeFormatData}
                                          setAssignDataTypeFormatData={setAssignDataTypeFormatData}
                                          showEdit={showEdit}
                                          setShowEdit={setShowEdit}
                                          nodeCategoryId={nodeCategoryId}
                                          viewMode ={true}
                                          setNodeSaved={setNodeSaved}
                                          onProceedClick ={()=>actionsClick(location?.state?.clientId, 'modelBuilder')}                             
                                          isFileSetToS3={isFileSetToS3}
                                          setIsFileSentToS3={setIsFileSentToS3}
                                          setError={setError}                                                        

                                          />
                                          :
                                          renameColumnList?.renameDatas?.length > 0 ?
                                          <RenameColumnName 
                                            workflow_id={ecl_workflow_id} 
                                            ecl_node_id={selectedTileId}
                                            workflow_node_id ={workflow_node_id}
                                            calculationDate ={calculationDate}
                                            templateId={templateId}
                                            templateName ={templateName}
                                            client_branch_level_id={location?.state?.levelTwoCardId}
                                            setReplaceClicked={setReplaceClicked}                                
                                            nodeCategoryId={nodeCategoryId}
                                            viewMode ={true}
                                            renameColumnList={renameColumnList}
                                            setRenameColumnList={setRenameColumnList}
                                            onProceedClick ={()=>actionsClick(location?.state?.clientId, 'modelBuilder')}                             
                                            setNodeSaved={setNodeSaved}
                                            isFileSetToS3={isFileSetToS3}
                                            setIsFileSentToS3={setIsFileSentToS3}
                                            setError={setError}                                                        
                                          
                                            />
                                            :
                                            removeColumnList?.removeDatas?.length > 0 ?
                                            <RemoveColumnName 
                                            workflow_id={ecl_workflow_id} 
                                            ecl_node_id={selectedTileId}
                                            workflow_node_id ={workflow_node_id}
                                            calculationDate ={calculationDate}
                                            templateId={templateId}
                                            templateName ={templateName}
                                            client_branch_level_id={location?.state?.levelTwoCardId}                              
                                            nodeCategoryId={nodeCategoryId}
                                            viewMode ={true}
                                            removeColumnList={removeColumnList}
                                            setRemoveColumnList={setRemoveColumnList}
                                            onProceedClick ={()=>actionsClick(location?.state?.clientId, 'modelBuilder')}                             
                                            setReplaceClicked={setReplaceClicked}     
                                            setNodeSaved={setNodeSaved}
                                            isFileSetToS3={isFileSetToS3}
                                            setIsFileSentToS3={setIsFileSentToS3}  
                                            setError={setError}                                                        

                                            />
                                            : 
                                            removeDuplicateRowsList?.removeDuplicateRowDatas?.length > 0 ?
                                            <RemoveDuplicateRow 
                                              workflow_id={ecl_workflow_id} 
                                              ecl_node_id={selectedTileId}
                                              workflow_node_id ={workflow_node_id}
                                              calculationDate ={calculationDate}
                                              templateId={templateId}
                                              templateName ={templateName}
                                              client_branch_level_id={location?.state?.levelTwoCardId}                              
                                              nodeCategoryId={nodeCategoryId}
                                              viewMode ={true}
                                              removeDuplicateRowsList={removeDuplicateRowsList}
                                              setRemoveDuplicateRowList={setRemoveDuplicateRowList}
                                              setReplaceClicked={setReplaceClicked}    
                                              setNodeSaved={setNodeSaved}
                                              onProceedClick ={()=>actionsClick(location?.state?.clientId, 'modelBuilder')}                             
                                              isFileSetToS3={isFileSetToS3}
                                              setIsFileSentToS3={setIsFileSentToS3}
                                              setError={setError}                                                        
                                              
                                            />

                                            
                                      : selectedNodeSummary !== undefined ? <></>
                                        : fileUploadSummary !== undefined ? <></>
                                          : <FileUploadListSkeleton />
                                )                                 
                                  :
                                 <></>
                                }                                
                              </div>
                            </div>
                            : selectedNodeSummary !== undefined ? <></>
                              : fileUploadSummary !== undefined ? <></>
                                : <FileUploadListSkeleton />
                      }
                    </div>
                  }  
                  {apiCalled && step == 4 && selectedTileId < 3  && !location?.state.isfromHistory && !deleting && !showSummaryLoader && !mergeSummaryLoader ?
                     selectedNodeSummary?.length > 0 || fileUploadSummary?.length > 0 ?
                      <div className='absolute right-0 3xl:bottom-[-1.2rem]  xl:bottom-[-0.6rem] xlg:bottom-[-1.5rem] lg:bottom-[-1.3rem] bottom-0 mini-xl-screen'>
                        <div className='flex items-center gap-3'>                        
                          {/* <Button bgColor='#AFABAB' borderType="" label={'Cancel'} color={'#ffffff'} onClick={() => { onCancelClick() }} size={'md'} disabled={workflowNodeData?.length < 0 || location?.state?.isfromHistory} /> */}
                          <Button bgColor='#AFABAB' borderType="" label={'Cancel'} color={'#ffffff'} onClick={() => { onReplaceClick() }} size={'md'} disabled={workflowNodeData?.length < 0 || location?.state?.isfromHistory || (lastNodeStatus == 'pending' && (tempWorkFlowId != workflow_node_id))} />
                          {/* <Button bgColor='#2F5597' borderType="" label={'Replace file'} color={'#ffffff'} onClick={() => { onReplaceClick() }} size={'md'} disabled={workflowNodeData?.length < 0 || location?.state?.isfromHistory || (lastNodeStatus == 'pending' && (tempWorkFlowId != workflow_node_id))} /> */}
                          <Button bgColor='#70AD47' borderType="" label={'Save & upload another'} color={'#ffffff'} onClick={() => { saveAndUploadAnother('another', categoryList?.filter(data => data.id == category)) }} size={'md'} disabled={workflowNodeData?.length < 0 || location?.state?.isfromHistory || uploadAnother} />
                          <Button bgColor='#70AD47' borderType="" label={'Save & proceed'} color={'#ffffff'} onClick={() => { OnClicksaveAndProceed(); }} size={'md'} disabled={workflowNodeData?.length < 0 || location?.state?.isfromHistory || !saveAndProceed} />
                        </div>
                      </div>
                      : <></>
                    : <></>
                  }
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default NewCalculation;
