import React, { useEffect, useState } from 'react';
//Components
import Button from '../../Components/Button';
import SortDropDown from '../../Components/ClientPage/SortDropDown';
import LeftArrow from '../../Components/CommonIcons/LeftArrow';
import NoDataFound from '../../Components/ClientPage/NoDataFound';
import { useLocation, useNavigate } from 'react-router-dom';
import RouteSegments from '../../Routes/RouteSegments';
import { useDispatch, useSelector } from 'react-redux';
import { clientsCountryLevelOneList, getClearLevelOneData, getClearLevelTwoData, levelNameUpdate } from '../../Store/action';
import CardSkeleton from '../../Components/Skeleton/CardSkeleton';
import InfiniteScroll from 'react-infinite-scroll-component';
import PageCard from '../../Components/ClientPage/Pagecard'

import loadMore from '../../Images/loadmore.webp'
import { Constants } from '../../Utils/Constants';
import SearchInput from '../../Components/ClientPage/SearchInput';

//images
import editIcon from '../../Images/edit.svg'
import OverflowTooltip from '../../Components/ToolTip/OverFlowToolTip';
import BreadCrumbArrow from '../../Images/Group 166.svg'

let vari = [];

const LevelOne = ({dynamicHeight}) => {

  const location = useLocation();
  const navigate= useNavigate()
  const [sortBy,setSortBy] = useState('created_at:desc');
  const [currentListCount,setCurrentListCount] = useState(6)
  const [countrySort,setCountrySort] = useState();
  const [searchTerm, setSearchTerm] = useState('');
  const [selected,setSelected] = useState('Recently added');
  const [isNext, setIsNext] = useState(false) ;
  const [showLoader, setShowLoader] = useState(false);
  const [levelOneList, setLevelOneList] = useState([]);
  const [levelOneLabel,setLevelOneLable] = useState('Level 1');
  const [updatedLabel,setUpdatedLabel] = useState('Level 1');
  const [levelEditShow,setShowLevelEdit] = useState(false);
  const [dataLoading, setDataLoading] = useState(false)
  const [isVerticalScrollbarVisible, setIsVerticalScrollbarVisible] = useState(false);

  const newCalculation =(clientName)=>{
    navigate(RouteSegments.MANAGELEVEL,{ state: {
      breadCrumbClientName:location?.state?.breadCrumbClientName,
      breadCrumbCountryName:location?.state?.breadCrumbCountryName,
      clientId:location?.state?.clientId,
      client_country_id:ClientCountryId,
      parent_level_id:'',
      level:1,
      level_name:levelOneLabel,      
    } })
  }
  const dispatch = useDispatch();
  const levelOneListCallBack = ()=>{
    dispatch(clientsCountryLevelOneList(sortBy,Constants.listSize,searchTerm,location?.state?.client_country_id,countrySort)) //sort,record,key,id
  }

  const { ClientLevelOneList, isLoading,hasMore,ClientLevelName,UpdatedLevelName,ClientCountryId,ClientLevelOneData} = useSelector(state => {
    return {
        ClientLevelOneList: state?.Clients?.clientLevelOneLists,
        isLoading: state?.Clients?.isLoading,
        hasMore:state?.Clients?.hasMore,
        ClientLevelName:state?.Clients?.clientLevelName,
        UpdatedLevelName:state?.Clients?.updatedLevelName,
        ClientCountryId:state?.Clients?.clientCountryId,
        ClientLevelOneData:state?.Clients?.clientLevelOneData

    }
})

// console.log(ClientLevelOneData,'ClientLevelOneData');

const onNext = () => {        
  // Increase currentListCount by 6
  setIsNext(true)
  let newCount = currentListCount + 6;
  setCurrentListCount(newCount);

  // Dispatch action with the updated count
  dispatch(clientsCountryLevelOneList(sortBy,newCount,searchTerm,location?.state?.client_country_id));        
};


useEffect(()=>{        
  if(searchTerm?.length > 0){
      if(searchTerm?.length ==1){
          setCurrentListCount(Constants.listSize) 
          dispatch(clientsCountryLevelOneList(sortBy,Constants.listSize,searchTerm,location?.state?.client_country_id,countrySort));           
      }
      else{
        dispatch(clientsCountryLevelOneList(sortBy,currentListCount,searchTerm,location?.state?.client_country_id,countrySort));
      }
  } 
  else if(searchTerm?.length ==0){
      if(countrySort){
          dispatch(clientsCountryLevelOneList(null,currentListCount,searchTerm,location?.state?.client_country_id,'desc'))  
      }
      else{
        setDataLoading(true)
        dispatch(clientsCountryLevelOneList(sortBy,Constants.listSize,searchTerm,location?.state?.client_country_id,null));  
        setDataLoading(false)         
      }
  }
  },[searchTerm])

useEffect(()=>{    
  if(isNext){
      ClientLevelOneList?.map((client,i)=>{
          vari.push(client)
          setLevelOneList(vari)
      })
      setIsNext(false)
  }         
  else{
      vari = []
      ClientLevelOneList?.map((client,i)=>{
          vari.push(client)
          setLevelOneList(vari)
      })
  }               
  
},[ClientLevelOneList]) 


const updateLevelName =(e)=>{
  e.preventDefault(); // Prevent the default form submission behavior
    const countryId = location?.state?.client_country_id;
    const level = 1;
    const parrent =null;
    const name=levelOneLabel;
    dispatch(levelNameUpdate(countryId,level,parrent,name))
    setShowLevelEdit(false)

}

useEffect(()=>{
    if(UpdatedLevelName){
        setLevelOneLable(UpdatedLevelName)
        setShowLevelEdit(false)
    }
    
},[UpdatedLevelName])

useEffect(() => {
  const handleResize = () => {
    setIsVerticalScrollbarVisible(document.documentElement.scrollHeight > window.innerHeight);
  };

  window.addEventListener('resize', handleResize);
  handleResize(); // Initial check

  return () => {
    window.removeEventListener('resize', handleResize);
  };
}, []);

  return (
    <>
    <div className='w-full bg-white'>
    <div className='flex justify-between xl:py-3 py-2 xl:px-[4rem] px-[3rem] sticky bg-white z-10'>
        <div className='flex gap-2 items-center'>
          <div className='flex gap-2 items-center cursor-pointer' onClick={()=>navigate(RouteSegments.CLIENT_COUNTRIES,{ state: {breadCrumbClientName:location?.state?.breadCrumbClientName,clientId:location?.state?.clientId,} })} >
          <LeftArrow bgColor={'#d0cece'} className={'xl:w-[25px] xl:h-[25px] w-[20px] h-[20px]'}/>
          <p className='text-gray-light text-[13px] xl:text-[15px]'>Back</p>
          </div>
          <div className='flex gap-1 items-center text-dark-blue font-medium'>
          <p className='text-[12px] xl:text-[14px]'>{location?.state?.breadCrumbClientName} </p>
          <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' /> 
          <p className='text-[12px] xl:text-[14px]'>{location?.state?.breadCrumbCountryName}</p>  
          <img src={BreadCrumbArrow} className='w-3 h-3 rotate-180' />           
          {levelEditShow ?  
        <form onSubmit={(e) => { e.preventDefault(); updateLevelName(e); }}>  
          <div className='flex items-center gap-2'>
              <SearchInput searchTerm={levelOneLabel} setSearchTerm={setLevelOneLable} size={'sm'} icon={false} name="levelOneNameUpdate"/>
              <Button bgColor="#70AD47"
                      borderType="normal"
                      label={"Save"}
                      color={"#ffffff"}
                      disabled={!levelOneLabel}
                      size={'sm'} 
                      type={'submit'}
              />  
          </div>
        </form>
        :
        <div className='flex items-center gap-1'>
            <p className='max-w-[10rem]'><OverflowTooltip text={levelOneLabel} /></p>
            <img src={editIcon} alt='edit' className='w-3 cursor-pointer' onClick={()=>setShowLevelEdit(true)}/>
        </div>       
         }  
          </div>
        </div>
          <div className='flex gap-4 items-center'>
              <Button bgColor='#DCFFFD' borderType="dashed" label={"Manage"+" "+ (levelEditShow ? ClientLevelOneData?.clientLevelName : levelOneLabel)} color={'#42B2AC'} onClick={()=>newCalculation(location?.state?.client_country_id)} icon={'plus'} />
              <div className='flex space-x-4 '>
                <SearchInput searchTerm={searchTerm} setSearchTerm={setSearchTerm} icon={true} placeholder={'Search'+' '+(levelEditShow ? ClientLevelOneData?.clientLevelName : levelOneLabel)} isFrom="levelOne"/>
                <SortDropDown setCurrentListCount={setCurrentListCount} clientId={location?.state?.client_country_id} setSortBy={setSortBy} record={currentListCount} searchTerm={searchTerm} setSearchTerm={setSearchTerm} setCountrySort={setCountrySort} countrySort={countrySort} selected={selected} setSelected={setSelected} page={"dashboard"} isFrom="levelOne"/>
              </div>              
          </div>
     </div>
     <>
        {isLoading || dataLoading ?
            <div className='w-full'>
                <CardSkeleton cards={3} menu={"clientList"} />
                <CardSkeleton cards={3} menu={"clientList"} marigin={'2rem'} />
            </div>
            :
            !isLoading && levelOneList?.length > 0 
            ?
            ClientLevelOneList?.length <=0 && searchTerm?.length > 0 
            ? <NoDataFound redirection={false}/> 
            :
            <div className='overflow-y-auto thin-scrollbar' style={{ height: dynamicHeight+"px" }}>
            <div id="scroll-node">
            <InfiniteScroll
                dataLength={levelOneList.length + Constants.listSize}    
                onScroll={()=>setShowLoader(true)}                  
                loader={(hasMore && showLoader )&& <div className='w-full flex items-center justify-center'>
                    <img src={loadMore} alt="load more..." className='w-[20%] h-[5%]' />
                </div>}                
            >
                {levelOneList?.length > 0 &&
                  <div className={`grid grid-cols-3 gap-5 mb-[3.5rem] mt-2 ${isVerticalScrollbarVisible ? 'xl:ml-[4rem] xl:mr-[3.7rem] ml-[3rem] mr-[2.7rem]' :'xl:ml-[4rem] xl:mr-[4rem] ml-[3rem] mr-[3rem]'}`}>
                  {
                        levelOneList?.map(client => (
                        <PageCard 
                        key={client.id} 
                        data={client} 
                        title={client?.category_name} 
                        isPrimary={client?.is_primary} 
                        label={client?.level_two_name} 
                        count={client?.level_two_count} 
                        level={1}
                        client_branch_level_id={client.id}
                        ClientCountryId={ClientCountryId}
                        levelOneListCallBack={levelOneListCallBack}
                      //   location={{
                      //     labelName:levelOneLabel,
                      //     data:client,
                      //     breadCrumbClientName:location?.state?.breadCrumbClientName,
                      //     breadCrumbCountryName:location?.state?.breadCrumbCountryName,
                      //     client_country_id:client?.client_country_id
                      // }}
                      onClick={(name,id)=>{dispatch(getClearLevelOneData());dispatch(getClearLevelTwoData());navigate(RouteSegments.LEVEL_TWO ,{ 
                            state:{
                                labelName:levelOneLabel,
                                data:client,
                                breadCrumbClientName:location?.state?.breadCrumbClientName,
                                breadCrumbCountryName:location?.state?.breadCrumbCountryName,
                                client_country_id:client?.client_country_id
                            }
                         })}}
                        />))
                    }
                </div>
                } 
            </InfiniteScroll>
            {hasMore &&
            <div className='w-full flex items-center justify-center mb-16'>
                <button className='bg-greeny-blue text-white px-4 py-2 rounded text-[14px]' onClick={onNext}>
                {isLoading ? 'Loading...':'Load More'} 
            </button>
        </div> }

            </div>
            </div>
            :
            <div className='w-full'>
                <CardSkeleton cards={3} menu={"clientList"} />
                <CardSkeleton cards={3} menu={"clientList"} marigin={'2rem'} />
            </div>
        }
    </>   
     </div>
    </>
  )
}

export default LevelOne
