import React, { useEffect, useRef, useState } from "react";
import Button from "../Button";
import DropDown from "../DropDown/DropDown";
import { getFileSize } from "../../Utils/CommonFunctions";
import AWS from 'aws-sdk';
//images
import closeIcon from '../../Images/popup-close.svg';
import XlsActiveIcon from '../../Images/xls-green.svg';
import XlsInActiveIcon from '../../Images/xls-gray.svg';
import CategoryIcon from '../../Images/category.svg';
import PlusIcon from '../../Images/plusIcon.svg';
import MinusIcon from '../../Images/minusIcon.svg';
import XlsWhite from '../../Images/xls-white.svg';
import CancelIcon from '../../Images/cancel.svg'
import { localStorageKeys } from "../../Utils/Constants";
import { useDispatch } from "react-redux";
import { setFileUploading } from "../../Store/Workflow/WorkflowSlice";

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

AWS.config.update({
    accessKeyId: ACCESS_KEY,
    secretAccessKey: SECRET_ACCESS_KEY,
});

const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
});

export const FileCheckbox = ({
    file,
    filename,
    fileSize,
    initialCategory,
    initialSelected,
    onCategoryChange,
    dropDownOptions,
    onCheckboxChange,
    index,
    name,
    disabledDropdown
}) => {
    const [selected, setSelected] = useState(initialSelected);
    const [category, setCategory] = useState(initialCategory);
    const [isRerender, setIsRerender] = useState(false);

    const handleCheckboxChange = () => {
        onCheckboxChange(filename, !selected, fileSize, file);
        setSelected(!selected);
        setCategory(null);
        setIsRerender(true)
    };

    const handleCategoryChange = (val, label) => {
        setCategory(val);
        onCategoryChange(filename, val, fileSize, label, file);
    };

    const transformedArray = dropDownOptions?.map((obj) => ({
        label: obj?.category_name,
        value: obj?.id, // Assuming you want the value to be a string
    }));
    const categoryRef = useRef()
    return (
        <div className="grid grid-cols-3 gap-4 items-center px-[2rem]" key={index}>
            <div className="col-span-2 items-center flex gap-2">
                <input
                    type="checkbox"
                    className="h-4 w-4"
                    checked={selected}
                    onChange={handleCheckboxChange}
                />
                <span className="text-[13px]">{filename}</span>
                <span className="text-[13px]">&#40;{getFileSize(fileSize)}&#41;</span>
            </div>
            <div className="col-span-1">
                <div className="w-[15rem]">
                    <DropDown
                        ref={categoryRef}
                        name={name}
                        reRender={isRerender}
                        dropDownOptions={transformedArray}
                        placeholder={"Select data category"}
                        disabled={disabledDropdown}
                        ordinary={true}
                        selected={category ?? undefined}
                        onOptionChange={(val, dataType, label) =>
                            handleCategoryChange(val, label)
                        }
                    />
                </div>
            </div>
        </div>
    );
};

function SubSheetsListPopUp({
    show,
    onSubmit,
    onCancel,
    data
}) {
    const [showApplyAllMessage, setShowApplyAllMessage] = useState(false);
    const [activeIndex, setActiveIndex] = useState(null)
    const [showWarning, setShowWarning] = useState(false);
    const [categoryGroupeddata, setCategoryGroupedData] = useState([])
    const dispatch = useDispatch()

    const handleSubmit = async () => {
        dispatch(setFileUploading({ status: true, value: 0 }));
        onCancel();
        try {
            const totalCategories = categoryGroupeddata.length;
            let totalProgress = 0;
    
            const convertedFilePromises = categoryGroupeddata.map(async (category) => {
                const subsheetGroupKeys = Object.keys(category.subsheetCountGroup);
                const subsheetPromises = subsheetGroupKeys.map(async (key) => {
                    const subsheetGroup = category.subsheetCountGroup[key];
                    const filePromises = subsheetGroup.map(async (file) => {
                        const subsheetUploadPromises = file.subsheets.map(async (subsheet) => {
                            if (subsheet.selected) {
                                const fileNameWithoutExtension = file.fileName.split('.')[0];
                                const params = {
                                    ACL: 'public-read',
                                    Body: subsheet.file,
                                    Bucket: S3_BUCKET,
                                    Key: `${fileNameWithoutExtension}_${subsheet.fileName}`,
                                };
    
                                dispatch(setFileUploading({ status: true, value: 10 }));
    
                                const s3UploadResponse = await myBucket.upload(params).on('httpUploadProgress', (progressEvent) => {
                                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                    totalProgress += percentCompleted / totalCategories;
                                    const clampedProgress = Math.min(Math.round(totalProgress), 100);
                                    dispatch(setFileUploading({ status: true, value: clampedProgress }));
                                }).promise();
    
                                return {
                                    url: `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${fileNameWithoutExtension}_${subsheet.fileName}`,
                                    size: subsheet.size,
                                    fileName: subsheet.fileName,
                                };
                            }
                            return null; // Ensure we return null if not selected
                        });
    
                        const resolvedSubsheetUploads = await Promise.all(subsheetUploadPromises);
                        return resolvedSubsheetUploads.filter(Boolean); // Filter out null values
                    });
    
                    const resolvedFilePromises = await Promise.all(filePromises);
                    return resolvedFilePromises.flat(); // Flatten one level
                });
    
                const resolvedSubsheetPromises = await Promise.all(subsheetPromises);
                return resolvedSubsheetPromises.flat(); // Flatten one level
            });
    
            const allSubsheetUrls = await Promise.all(convertedFilePromises);
    
            // Flatten the array of arrays into a single array of objects
            const urlsArray = allSubsheetUrls.flat().filter(Boolean);
            const urls = urlsArray.map(item => item.url);
    
            console.log(urls, urlsArray, allSubsheetUrls, 'allSubsheetUrls');
    
            if (urls.length) {
                const filteredData = categoryGroupeddata.map((category) => ({
                    category: category.category,
                    subsheetCountGroup: Object.keys(category.subsheetCountGroup).reduce((acc, key) => {
                        const filteredSubsheetGroup = category.subsheetCountGroup[key].map((file) => ({
                            fileName: file.fileName,
                            subsheets: file.subsheets.filter((subsheet) => subsheet.selected === true)
                        })).filter(file => file.subsheets.length > 0);
                        if (filteredSubsheetGroup.length > 0) {
                            acc[key] = filteredSubsheetGroup;
                        }
                        return acc;
                    }, {})
                })).filter(category => Object.keys(category.subsheetCountGroup).length > 0);
    
                const newDataFormat = filteredData.flatMap((category) =>
                    Object.values(category.subsheetCountGroup).flatMap((files) =>
                        files.flatMap(({ fileName, subsheets }) =>
                            subsheets.map(({ category, id, size, fileName, mainFileName }) => ({
                                id,
                                category,
                                size,
                                sheetName: fileName,
                                fileName: mainFileName
                            }))
                        )
                    )
                );
    
                onSubmit(newDataFormat, urls);
            }
        } catch (error) {
            console.error('Error uploading files:', error);
            dispatch(setFileUploading({ status: false, value: 0 }));
        } finally {
            dispatch(setFileUploading({ status: false, value: 0 }));
        }
    };
            
    useEffect(() => {
        const groupedByCategory = data.reduce((acc, obj) => {
            const category = obj.category;
            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push({
                fileName: obj.fileName,
                subsheets: obj.subsheets,
                category: obj.category,
                subsheetCount: obj.subsheets?.length // Add subsheetCount property
            });
            return acc;
        }, {});

        const finalArray = Object.keys(groupedByCategory).map(category => {
            // Sort files by the count of subsheets in ascending order
            const sortedFiles = groupedByCategory[category].sort((a, b) => a.subsheets.length - b.subsheets.length);

            // Group files by subsheetCount
            const subsheetCountGroup = sortedFiles.reduce((acc, file) => {
                const subsheetCount = file.subsheetCount;
                if (!acc[subsheetCount]) {
                    acc[subsheetCount] = [];
                }
                acc[subsheetCount].push(file);
                return acc;
            }, {});

            return {
                category: category,
                files: sortedFiles,
                subsheetCountGroup: subsheetCountGroup // Add subsheetCountGroup property
            };
        });

        setCategoryGroupedData(finalArray)
        console.log(finalArray, 'finalArray');
        console.log(data,'hbcshc dhjc d');
    }, [data]);

    const handleCheckboxChange = (index, groupKey, fileIndex) => {
        const updatedArray = categoryGroupeddata.map((categoryItem, categoryIndex) => {
            if (categoryIndex === index) {
                return {
                    ...categoryItem,
                    subsheetCountGroup: Object.keys(categoryItem.subsheetCountGroup).reduce((acc, key) => {
                        acc[key] = categoryItem.subsheetCountGroup[key].map((fileItem, fIndex) => {
                            if (groupKey === key && fIndex === fileIndex) {
                                return {
                                    ...fileItem,
                                    subsheets: fileItem.subsheets.map(subsheet => ({
                                        ...subsheet,
                                        selected: !fileItem.subsheets.every(s => s.selected)
                                    }))
                                };
                            }
                            return fileItem;
                        });
                        return acc;
                    }, {})
                };
            }
            return categoryItem;
        });

        setCategoryGroupedData(updatedArray);
    };

    const handleSelectSheets = (categoryIndex, groupKey, fileIndex, subsheetIndex) => {
        const updatedArray = categoryGroupeddata.map((categoryItem, cIndex) => {
            if (cIndex === categoryIndex) {
                return {
                    ...categoryItem,
                    subsheetCountGroup: Object.keys(categoryItem.subsheetCountGroup).reduce((acc, key) => {
                        acc[key] = categoryItem.subsheetCountGroup[key].map((fileItem, fIndex) => {
                            if (groupKey === key && fIndex === fileIndex) {
                                return {
                                    ...fileItem,
                                    subsheets: fileItem.subsheets.map((subsheet, sIndex) => {
                                        if (sIndex === subsheetIndex) {
                                            return {
                                                ...subsheet,
                                                selected: !subsheet.selected
                                            };
                                        }
                                        return subsheet;
                                    })
                                };
                            }
                            return fileItem;
                        });
                        return acc;
                    }, {})
                };
            }
            return categoryItem;
        });

        setCategoryGroupedData(updatedArray);
    };

    const handleApplyAll = (categoryIndex, groupKey) => {
        const selectedIndices = [];
    
        // Find the indices of selected subsheets in the first file with selected subsheets
        categoryGroupeddata[categoryIndex]?.subsheetCountGroup[groupKey]?.forEach(file => {
          file?.subsheets?.forEach((subsheet, index) => {
            if (subsheet.selected) {
              selectedIndices.push(index);
            }
          });
        });
    
        if (selectedIndices.length === 0) return; // No subsheets selected, nothing to apply
    
        // Apply the selection to all files within the group
        const updatedCategoryGroupedData = categoryGroupeddata.map((categoryItem, idx) => {
          if (idx === categoryIndex) {
            const updatedSubsheetCountGroup = Object.keys(categoryItem.subsheetCountGroup).reduce((acc, key) => {
              if (key === groupKey) {
                acc[key] = categoryItem.subsheetCountGroup[key].map(file => {
                  const updatedSubsheets = file.subsheets.map((subsheet, index) => ({
                    ...subsheet,
                    selected: selectedIndices.includes(index)
                  }));
                  return { ...file, subsheets: updatedSubsheets };
                });
              } else {
                acc[key] = categoryItem.subsheetCountGroup[key];
              }
              return acc;
            }, {});
            return { ...categoryItem, subsheetCountGroup: updatedSubsheetCountGroup };
          }
          return categoryItem;
        });
    
        // Update the state with the new data
        setCategoryGroupedData(updatedCategoryGroupedData);
      };

      const isAnySubsheetSelected = (categoryIndex, groupKey) => {
        return categoryGroupeddata[categoryIndex]?.subsheetCountGroup[groupKey]?.some(file =>
          file?.subsheets?.some(subsheet => subsheet.selected)
        );
      };

      const anySubsheetSelected = () => {
        return categoryGroupeddata?.some((category) =>
            Object.keys(category?.subsheetCountGroup)?.some((groupKey) =>
                category?.subsheetCountGroup[groupKey]?.some((file) =>
                    file?.subsheets?.some((subsheet) => subsheet.selected)
                )
            )
        );
    };

    useEffect(() => {
        if (showApplyAllMessage) {
            setTimeout(() => {
                setShowApplyAllMessage(!showApplyAllMessage)
            }, 3000);
        }

    }, [showApplyAllMessage])

    const onCancelClick = () => {
        if (anySubsheetSelected()) {
            setShowWarning(true)
        } else {
            onCancel()
        }
    }



    return !show ? (
        <></>
    ) : (
        <div
        className="relative z-[101]"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
    >
        <div className="h-screen w-screen fixed inset-0 bg-black bg-gray-500 bg-opacity-75 transition-opacity"></div>
        <div className="fixed inset-0 z-[101]">
            <div className="flex min-h-full justify-center items-center">
                <div className="relative w-[90%] max-h-screen mt-10 mb-10">
                    <img src={closeIcon} className="absolute right-[-0.7rem] top-[-0.8rem] w-[1.5rem] cursor-pointer" onClick={onCancel} />
                    <div className="bg-white rounded-lg max-h-full overflow-y-auto pb-4">
                        {/* header */}
                        <div className="bg-[#4F2D7F] rounded-t-lg">
                            <p className="2xl:text-[16px] text-[14px] text-white text-center 2xl:leading-[35px] leading-[30px]">
                                Select sheet(s) to upload
                            </p>
                        </div>
                        {/* content */}
                        <div className="px-[2rem] pt-[1.5rem] 3xl:max-h-[95vh] 2xl:max-h-[85vh] max-h-[70vh] overflow-y-auto thin-scrollbar">
                                {categoryGroupeddata?.map((categoryItem, categoryIndex) => (
                                    <div className="mb-3" key={categoryIndex}>
                                        <div className="bg-[#E0DCD7] p-2.5 cursor-pointer rounded-md space-y-1">
                                            <div className="flex gap-3 items-center">
                                                <img src={CategoryIcon} className="2xl:w-4.5 w-4" />
                                                <p className="text-[#4F49DF] 2xl:text-[14px] text-[12px] font-semibold">{categoryItem?.category}</p>                                                
                                            </div>
                                            {Object.keys(categoryItem?.subsheetCountGroup)?.map((groupKey, groupIndex) => (
                                                <div key={groupIndex} className="bg-[#F8F6F5] p-2 rounded-[3px]">
                                                    {/* <div className="flex justify-end items-center mb-2">
                                                    {categoryGroupeddata[categoryIndex]?.subsheetCountGroup[groupKey]?.length > 1 &&
                                                    <button
                                                        className={`text-white 2xl:text-[12px] text-[11px] px-2 py-1 font-medium rounded-md focus:outline-none ${!isAnySubsheetSelected(categoryIndex, groupKey) ? 'bg-[#D0CECE]' : 'bg-[#887DFC] hover:bg-[#2F5597]'}`}
                                                        type="button"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleApplyAll(categoryIndex, groupKey);                                                        }}
                                                            disabled={!isAnySubsheetSelected(categoryIndex, groupKey)}                                                    >
                                                        Apply to multiple sheets
                                                    </button>
                                                }
                                                    </div> */}
                                                    {categoryItem?.subsheetCountGroup[groupKey]?.map((file, fileIndex) => (
                                                        <div key={fileIndex}>
                                                            <div className="flex gap-4 items-center">
                                                                <div className="flex gap-3 items-center">
                                                                    <img src={XlsActiveIcon} className="2xl:w-5 w-4" />
                                                                    <p className="text-[#000000] font-semibold 2xl:text-[13px] text-[12px]">{file?.fileName}</p>
                                                                </div>
                                                                {file?.subsheets?.length > 1 && (
                                                                    <>
                                                                        <div className="flex gap-2 items-center">
                                                                            <input
                                                                                type="checkbox"
                                                                                id={`${categoryIndex}-${groupKey}-${fileIndex}`}
                                                                                className="2xl:h-4 2x;:w-4 w-3.5 h-3.5 cursor-pointer"
                                                                                checked={categoryGroupeddata[categoryIndex]?.subsheetCountGroup[groupKey][fileIndex]?.subsheets.every((sheet) => sheet?.selected)}
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    handleCheckboxChange(categoryIndex, groupKey, fileIndex);
                                                                                }}
                                                                            />
                                                                            <span
                                                                                htmlFor={`${categoryIndex}-${groupKey}-${fileIndex}`}
                                                                                className="2xl:text-[12px] text-[11px] text-[#4F49DF] cursor-pointer"
                                                                                onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    handleCheckboxChange(categoryIndex, groupKey, fileIndex);
                                                                                }}
                                                                            >
                                                                                Select all Sheets
                                                                            </span>
                                                                        </div>
                                                                        {/* {(showApplyAllMessage && categoryIndex === activeIndex) &&
                                                                            <div className="flex gap-2">
                                                                                <p className="text-[13px] font-semibold text-[#5B0000]">
                                                                                    Affects only the documents which have same sheet count
                                                                                </p>
                                                                                <img src={CancelIcon} className="w-4" onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    setShowApplyAllMessage(false);
                                                                                }} />
                                                                            </div>
                                                                        } */}
                                                                    </>
                                                                )}
                                                            </div>
                                                            <div className="">
                                                                <div className="flex gap-3 flex-wrap px-[1.8rem] 2xl:px-[2rem] py-2">
                                                                    {file?.subsheets?.map((data, subSheetIndex) => (
                                                                        <div className={`flex gap-2 cursor-pointer px-2 2xl:py-1.5 py-1 rounded 2xl:text-[13px] text-[12px] items-center ${data?.selected ? 'bg-[#2F5597] text-white' : 'bg-[#D6D6D6] text-[#707070]'
                                                                            }`}
                                                                            key={subSheetIndex}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                handleSelectSheets(categoryIndex, groupKey, fileIndex, subSheetIndex);
                                                                            }}
                                                                        >
                                                                            <img src={data?.selected ? XlsWhite : XlsInActiveIcon} className="w-3 2xl:w-4" />
                                                                            <p>{data?.fileName.replace('.csv', '')}</p>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {showWarning &&
                            <div className="w-full flex flex-col items-center justify-center">
                                <div className="w-[45%] border border-[#AFABAB]">
                                <div className="rounded-[2px] p-1.5 w-fit mx-auto px-[2.5rem] ">
                                    <p className="text-[#B70000] font-medium text-[13px] w-fit text-center">Are you sure you want to cancel the file upload?</p>
                                </div>

                                <div className="flex items-center gap-3 justify-center my-4 ">
                                <Button
                                    bgColor="#ffffff"
                                    borderType="normal"
                                    label={'No'}
                                    color={"#202135"}
                                    type={"button"}
                                    size={"lg"}
                                    borderColor={'#2F5597'}
                                    onClick={() => setShowWarning(false)}
                                />
                                <Button
                                    bgColor="#00A7B5"
                                    borderType="normal"
                                    label={"yes"}
                                    color={"#ffffff"}
                                    type={"button"}
                                    size={"lg"}
                                    disabled={false}
                                    onClick={onCancel}
                                />
                                </div>
                                </div>
                            </div>
                            }

                            {/* footer */}
                            {!showWarning &&
                                // <div className="flex items-center gap-3 justify-center my-4 ">
                                //     <Button
                                //         bgColor="#ffffff"
                                //         borderType="normal"
                                //         label={'No'}
                                //         color={"#202135"}
                                //         type={"button"}
                                //         size={"lg"}
                                //         borderColor={'#2F5597'}
                                //         onClick={() => setShowWarning(false)}
                                //     />
                                //     <Button
                                //         bgColor="#00A7B5"
                                //         borderType="normal"
                                //         label={"yes"}
                                //         color={"#ffffff"}
                                //         type={"button"}
                                //         size={"lg"}
                                //         disabled={false}
                                //         onClick={onCancel}
                                //     />
                                // </div>
                                // :
                                <div className="flex items-center gap-3 justify-center my-4 ">
                                    <Button
                                        bgColor="#ffffff"
                                        borderType="normal"
                                        label={"Cancel"}
                                        color={"#202135"}
                                        type={"button"}
                                        size={"lg"}
                                        borderColor={'#2F5597'}
                                        onClick={onCancelClick}
                                    />
                                    <Button
                                        bgColor="#00A7B5"
                                        borderType="normal"
                                        label={"Confirm"}
                                        color={"#ffffff"}
                                        type={"button"}
                                        size={"lg"}
                                        disabled={!anySubsheetSelected()}
                                        onClick={handleSubmit}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SubSheetsListPopUp;
