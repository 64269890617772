import React from 'react';
import Skeleton from 'react-loading-skeleton';
import FileUploadListSkeleton from './FileUploadListSkeleton';

export const PipelineSkelton =()=>{
  return(
    <div className='px-[0.5rem] py-[0.6rem] flex items-center'>
    <Skeleton height={37} width={30} />
    <Skeleton height={7} width={45} />
    <Skeleton height={37} width={30} />
    <Skeleton height={7} width={45} />
    <Skeleton height={37} width={30} />
    <Skeleton height={7} width={45} />
    <Skeleton height={'3.5rem'} width={'3.5rem'} circle/>
    </div>
  )
}

const NewCalculationSkeleton = () => {
  return (
    <div className="flex h-full">
      {/* Left Panel */}
      <div className="w-1/4 bg-[#F2F2F2] bg-opacity-30 shaddow-lg h-full px-2">
        {/* First Row */}
        <div className="flex flex-col gap-1 justify-center">
          <Skeleton height={30} />
          <Skeleton height={45} />
          <div className='self-center'>
          <Skeleton height={20} width={100}/>
          </div>
        </div>

        {/* Second Row */}
        <div className="flex flex-col gap-3 mt-3">
        <Skeleton height={30} />
          <Skeleton height={45} />
            <div className='self-center'>
                <Skeleton height={20} width={100}/>
            </div>
            <div className='self-center '>
                <Skeleton height={15} width={30}/>
            </div>
            <div className='self-center '>
                <Skeleton height={30} width={80}/>
            </div>
            <div className='self-center lg:mt-[0.5rem] xl:mt-[3rem] 2xl:mt-[10rem]'>
                <Skeleton height={20} width={80}/>
            </div>
        </div>
      </div>

      {/* Gap between Left and Right Panels */}
      <div className="w-3"></div>

      {/* Right Panel */}
      <div className="w-3/4 bg-[#F2F2F2] bg-opacity-30 shaddow-lg h-full ">
        <Skeleton height={30} />
        <div className='px-[1rem] py-[0.6rem]  2xl:p-[1.5rem] flex items-center'>
        <Skeleton height={37} width={30} />
        <Skeleton height={7} width={45} />
        <Skeleton height={37} width={30} />
        <Skeleton height={7} width={45} />
        <Skeleton height={37} width={30} />
        <Skeleton height={7} width={45} />
        <Skeleton height={'3.5rem'} width={'3.5rem'} circle/>
        </div>
        <div className='px-[1rem]'>
          <FileUploadListSkeleton initial={true}/>
        </div>       
      </div>
    </div>
  );
};

export default NewCalculationSkeleton;
