import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States

import {checkWorkflowNodeApi} from "../../../Helpers/ApiHelpers";
import { checkWorkFlowApiError, checkWorkFlowSuccess} from "./Action";
import {CHECK_WORKFLOW, CHECK_WORKFLOW_DATA_CLEAR } from "./ActionType";
import { localStorageKeys } from "../../../Utils/Constants";

function* checkWorkflowNode(data) {
  try {
    const response = yield call(checkWorkflowNodeApi, data?.payload)
    if (response.status === 200) {
      if (response.data.status === 200) {
        localStorage.setItem(localStorageKeys.sameCategoryExist,response?.data?.sameCategoryExist)
        yield put(checkWorkFlowSuccess(response?.data))
      }
    } else {
      yield put(checkWorkFlowApiError(response));
    }
  } catch (error) {
    console.log('API ERROR:', error);
    yield put(checkWorkFlowApiError(error));
  }
}

function* checkWorkFlowDataClear(data) {
  try {    
    yield put(checkWorkFlowSuccess([]));
  } catch (error) {
    // Handle any potential errors
    console.error('Error clearing API response:', error);
  }
}

function* CheckWorkFlow() { 
  yield takeEvery(CHECK_WORKFLOW, checkWorkflowNode);  
  yield takeEvery(CHECK_WORKFLOW_DATA_CLEAR, checkWorkFlowDataClear);

}

export default CheckWorkFlow;
