import React from 'react'

const CategoryIcon = ({bgColor,width,height,className}) => {
    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} className={className} viewBox="0 0 20 20">
                <g id="Group_219" data-name="Group 219" transform="translate(485.338 -214.688)">
                    <path id="Path_177" data-name="Path 177" d="M-480.763,223.848c-.651,0-1.3,0-1.953,0a2.507,2.507,0,0,1-2.613-2.6q0-1.979,0-3.959a2.5,2.5,0,0,1,2.613-2.6q1.966,0,3.933,0a2.5,2.5,0,0,1,2.613,2.6q0,1.979,0,3.959a2.505,2.505,0,0,1-2.613,2.6C-479.444,223.85-480.1,223.848-480.763,223.848Zm0-7.5c-.677,0-1.353,0-2.03,0a.827.827,0,0,0-.882.876q0,2.043,0,4.086a.831.831,0,0,0,.876.883q2.043,0,4.086,0a.827.827,0,0,0,.883-.876q0-2.043,0-4.086a.83.83,0,0,0-.877-.883C-479.39,216.343-480.075,216.345-480.761,216.345Z" transform="translate(-0.006)" fill={bgColor} />
                    <path id="Path_178" data-name="Path 178" d="M-476.177,427.276c0,.66,0,1.32,0,1.979a2.5,2.5,0,0,1-2.6,2.588q-1.979,0-3.959,0a2.506,2.506,0,0,1-2.6-2.587q0-1.992,0-3.985a2.505,2.505,0,0,1,2.6-2.587q1.979,0,3.959,0a2.506,2.506,0,0,1,2.6,2.612C-476.176,425.956-476.177,426.616-476.177,427.276Zm-1.656.007c0-.677,0-1.353,0-2.03a.836.836,0,0,0-.9-.912q-2.03,0-4.06,0a.826.826,0,0,0-.886.872q0,2.043,0,4.086a.832.832,0,0,0,.9.887q2.017,0,4.034,0a.838.838,0,0,0,.913-.926Q-477.832,428.272-477.833,427.283Z" transform="translate(0 -197.157)" fill={bgColor} />
                    <path id="Path_179" data-name="Path 179" d="M-268.164,427.284c0,.66,0,1.32,0,1.979a2.505,2.505,0,0,1-2.6,2.589q-1.979,0-3.959,0a2.506,2.506,0,0,1-2.6-2.611q0-1.966,0-3.933a2.507,2.507,0,0,1,2.624-2.615q1.953,0,3.907,0a2.508,2.508,0,0,1,2.628,2.637Q-268.163,426.308-268.164,427.284Zm-1.656-.015c0-.677,0-1.353,0-2.03a.83.83,0,0,0-.871-.888q-2.056,0-4.112,0a.824.824,0,0,0-.862.87q0,2.056,0,4.112a.826.826,0,0,0,.87.863q2.043,0,4.086,0a.833.833,0,0,0,.889-.9C-269.819,428.622-269.82,427.946-269.82,427.269Z" transform="translate(-197.175 -197.167)" fill={bgColor} />
                    <path id="Path_180" data-name="Path 180" d="M-277.3,219.278a4.6,4.6,0,0,1,4.58-4.565,4.593,4.593,0,0,1,4.579,4.593,4.6,4.6,0,0,1-4.608,4.566A4.594,4.594,0,0,1-277.3,219.278Zm4.589-2.908a2.918,2.918,0,0,0-2.933,2.894,2.917,2.917,0,0,0,2.9,2.953,2.918,2.918,0,0,0,2.946-2.907A2.917,2.917,0,0,0-272.712,216.369Z" transform="translate(-197.197 -0.024)" fill={bgColor} />
                </g>
            </svg>

        </div>
    )
}

export default CategoryIcon
