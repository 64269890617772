import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { binningBuckettingNodeSave, exceptionAnalysisProceed, getBinningColumnList, getCalculationFormData, getDataImputationList, getNodeWiseCategoryList, openModalBuilder, saveBinningBucket, saveBinningCategory, selectCurrentNodeData, selectCurrentNodeIds } from '../../Store/Workflow/WorkflowSlice';
import DropDown from '../DropDown/DropDown';
import { useLocation } from 'react-router-dom';
import Button from '../Button';
import SelectInput, { TextInput } from '../SelectInput';
import * as Yup from 'yup';
import { createColumnHelper } from '@tanstack/react-table';
import SummaryTable from '../Table/SummaryTable';
import XlsIcon from '../../Images/excel.svg';
import ReadOnlyValueDisplay from '../CommonComponents/ReadOnlyValueDisplay';

import { parseCategotyValueLabel } from '../../Helpers/utils';
import useDataTable from '../../hooks/useDataTable';
import DataTableSkeleton from '../Skeleton/DataTableSkeleton';
import { CircularProgress } from '@mui/material';
import DataTable from '../Table/DataTable';
import { binningOnchangeData, bucketDetail } from '../../Helpers/ApiHelpers';
import EditableTable from '../Table/EditableTable';
import { toast } from 'react-toastify';
import ProceedButton from '../CommonComponents/ProceedButton';
import downloadingIcon from '../../Images/gif/downloading.gif'


const columnHelper = createColumnHelper();

function Binningandbucketing() {
    const dispatch = useDispatch();
    const currentBucketData = useSelector(selectCurrentNodeData);
    const isCalculatingData = useSelector(state => state?.Workflow?.ui?.isCalculating);
    const dataState = useSelector(state => state);
    const bucketCategory = useSelector(state => state?.Workflow?.ui?.nodeWiseCategory);
    const location = useLocation();

    const isCalculating = useSelector(state => state?.Workflow?.ui?.isCalculating)
    const filesMerging = useSelector(state => state?.Workflow?.ui?.filesMerging)
    const { currentEclNodeId, currentWorkflowId, currentWorkflowNodeId } = useSelector(selectCurrentNodeIds)
    const nodeWiseCategory = useSelector(state => state?.Workflow?.nodeWiseCategory)
    const currentNodeData = useSelector(selectCurrentNodeData)
    const isDataLoading = useSelector(state => state?.Workflow?.ui?.isDataLoading)
    const isProceedLoading = useSelector(state => state?.Workflow?.ui?.isProceedLoading)
    const reduxState = useSelector(state => state)
    const categoryTableData = useSelector(state => state?.Workflow?.categoryTableData)

    const [bucketValue, setBucketValue] = useState();
    const [bucketNumber, setBucketNumber] = useState();
    const [isAddEnabled, setIsAddEnabled] = useState(false);
    const [showBucketPointTable, setShowBucketPointTable] = useState(false);
    const [showBucketTable, setShowBucketTable] = useState(false);
    const [isSaveButtonClicked, setIsSaveButtonClicked] = useState(false)
    const [bucketTableValue, setBucketTableValue] = useState()
    const [bucketValueEdit, setBucketValueEdit] = useState();
    const [columnName, setColumnName] = useState(undefined);
    const [selectedColumnData, setselectedColumnData] = useState([])
    const [isShowsummaryTable, setIsShowsummaryTable] = useState(false)
    const [selectedColumnDataPointTable, setSelectedColumnDataPointTable] = useState([])
    const [isDisabled, setIsDisabled] = useState(false)


    // const tableData = useMemo(() => 
    //   bucketValue?.dtBinningRangeDatas?.buckets, [bucketValue?.dtBinningRangeDatas?.buckets]);

    const BinningData = useMemo(() => {
      return  bucketValue?.dtBinningRangeDatas?.buckets
  }, [ bucketValue?.dtBinningRangeDatas?.buckets]);

    const [binningTableData, setBinningtableData] = useState(bucketValue?.dtBinningRangeDatas?.buckets);

    useEffect(() => {
      setBinningtableData(BinningData);
  }, [BinningData]);

    const formik = useFormik({
        initialValues: {
            category: currentBucketData?.nodeCategoryId,
            dataBucketColumnList: currentBucketData?.dtBinningRangeDatas?.dtBinningRangeDatas?.map(data => ({ type: data?.bucket_column_name })),
            columnName: undefined,
        },
        validationSchema: Yup.object({
            category: Yup.string().required('Required'),
        }),
        enableReinitialize: true,
    });

    console.log(currentBucketData,"currentBucketData");
    const { dataTableColumns,
        getFilterOptions,
        summaryData,
        tableState,
        refreshDataTable,
        onTableStateChange,
        isDataLoading: isMergeSummaryLoading,
        isExportLoading,
        donwloadXl,
      } = useDataTable({ category_id: formik.values.category, ecl_node_id: currentEclNodeId, workflow_id: currentWorkflowId, from: "binningBucketingEqualRange" })
    

    const onCategoryChange = (value) => {
        formik.setFieldValue("category", value);
    };

    const onCategorySave = (action) => {
        dispatch(saveBinningCategory({
          "workflow_id": currentWorkflowId,
          "ecl_node_id": currentEclNodeId,
          "category_id": formik.values.category
        }
        ))
        if(action == 'add'){
          dispatch(getBinningColumnList({
            "workflow_id": currentWorkflowId,
            "ecl_node_id": currentEclNodeId,
            "category_id": formik.values.category
          }
          ))
        }

        refreshDataTable()
      }

      

    const onAdd = () => {
        setShowBucketPointTable(true)
        setShowBucketTable(true)
        setIsAddEnabled(false);
        setIsShowsummaryTable(true)
        setselectedColumnData(prev => ([
          ...prev,
          {
            "column_name": columnName,
            "no_of_buckets": bucketNumber,
            "bucket_data":  binningTableData,
          }
      ]))

      setSelectedColumnDataPointTable(prev => ([
          ...prev,
          ...binningTableData?.map((data,i)=>(
          {
            "column_name": columnName,
            "no_of_buckets": bucketNumber,
            "user_bucket_name": data?.user_bucket_name,
            "bucket_column_name":columnName+"_bucketed_range",
            "start_point":data?.start_point,
            "end_point":data?.end_point
          }
        ))
      ]))
        
        setIsSaveButtonClicked(false)
        setIsShowsummaryTable(false)
        // validateFields(bucketValueEdit?.dtBinningRangeDatas?.buckets)

    };

    console.log(selectedColumnData,"selectedColumnData");
    console.log(selectedColumnDataPointTable,"selectedColumnDataPointTable");

    const onRemoveClick = (column) => {
      setselectedColumnData(prev => prev?.filter(col => col.column_name != column?.column_name))
  }

  useEffect(() => {
    if (currentNodeData?.id && currentNodeData?.nodeCategoryId) {
        onCategorySave('edit')                              
    }
  }, [currentNodeData?.nodeCategoryId,currentNodeData?.id])

    const onApply = async(action) => {
      
        const details = {
            "ecl_workflow_id":currentWorkflowId,
            "ecl_node_id":currentEclNodeId,
            "ecl_category_id":formik?.values?.category,
            "dt_binning_range_undo_datas": [],
            "dt_binning_range_datas": selectedColumnData?.map(col => ({
                    "column_name": col.column_name,
                    "no_of_buckets": col.no_of_buckets,
                    "bucket_data": col?.bucket_data
                })),
        }
    
    
        try {
        const response = await bucketDetail(details);
        console.log(response,"responseee");
        if (response?.data?.status === 200) {
            setBucketTableValue(response?.data);
            // dispatch(saveBinningCategory({
            //     "workflow_id": currentWorkflowId,
            //     "ecl_node_id": currentEclNodeId,
            //     "category_id": formik.values.category
            //   }
            //   ))
              let request = {
                "workflow_id": currentWorkflowId,
                "ecl_node_id": currentEclNodeId,
                "category_id": formik.values.category
              }
              const requestFormData = getCalculationFormData({
                client_branch_level_id: location?.state?.levelTwoCardId,
                isFrom: "binningBucketinfEqualRanges",
                category_id: formik?.values?.category,
                node_id: currentEclNodeId,
                state: reduxState,
                nodeStatus:'Completed'
              })
              dispatch(binningBuckettingNodeSave({requestFormData}))

            //   dispatch(saveBinningCategory(request)) .then(res => {
            //     if (res.type == `${saveBinningCategory.fulfilled}`) {
            //         refreshDataTable()               
            //     }            
            // })
            // onCategorySave()
            setShowBucketTable(true)


            if(action == "save"){
              setIsSaveButtonClicked(true)
            }
        }
        } catch (error) {
        console.error("Error fetching outlier methods", error);
        }
    };

    const bucketDataColumns = useMemo(() => {
        return [
            columnHelper.accessor("column_name", {
                header: "Column name",
                cell: info => info.getValue(),
                mergeCells: true,
                tdClassName: "align-top",
              }),
            columnHelper.accessor("no_of_buckets", {
                header: "No. of buckets",
                cell: info => info.getValue(),
                mergeCells: true,
                anotherColumn: "column_name",
                tdClassName: "align-top"
            }),
            columnHelper.accessor("user_bucket_name", {
                header: "Name of the bucket",
                cell: info => info.getValue(),
                rowSpan: 2
            }),
            columnHelper.accessor("start_point", {
                header: "Start Point",
                cell: info => info.getValue(),

            }),
            columnHelper.accessor("end_point", {
                header: "End Point",
                cell: info => info.getValue()
            })
        ]
    }, []);

    const [errors, setErrors] = useState({});
    const [hasError, setHasError] = useState(false);
    const [isValid,setIsValid] = useState(true)



    const validateFields = (buckets) => {
      const newErrors = {};
      let foundError = false;

      buckets?.forEach((bucket, index) => {
        const error = {
          start_point: bucket.start_point === null || bucket.start_point === '' || isNaN(bucket.end_point) && (bucket.start_point<=buckets[0].start_point && bucket.start_point>=buckets[0].end_point),
          end_point: bucket.end_point>buckets[0].start_point || bucket.start_point<buckets[buckets?.length-1].end_point || bucket.end_point === null || bucket.end_point === '' || isNaN(bucket.end_point) ,
          user_bucket_name: bucket.user_bucket_name === null || bucket.user_bucket_name === '',
        };

        if (error.start_point || error.end_point || error.user_bucket_name) {
          foundError = true;
        }

        newErrors[index] = error;
      });

      setErrors(newErrors);
      setHasError(foundError);
    };

    const onStartValueChange = (index,value, columnName) => {
        console.log(bucketValue?.dtBinningRangeDatas?.buckets,"index,value");
        console.log(index,value,"index,value");
        setBucketValueEdit((old) => {
          return {
            ...old,
            dtBinningRangeDatas: {
              ...old?.dtBinningRangeDatas,
              buckets: old?.dtBinningRangeDatas?.buckets?.map((row, index1) => {
                if (index === index1) {
                  return {
                    ...row,
                    [columnName]: columnName == "user_bucket_name"?value:parseInt(value),
                  };
                }
                // if (columnName !== "user_bucket_name" && index + 1 === index1) {
                //   return {
                //     ...row,
                //     start_point: parseInt(value),
                //   };
                // }
                console.log(row,"rowrowrow");
                return row;
              }),
            },
          };
        });
    }


    const bucketData = useMemo(() => categoryTableData?.isEdit? categoryTableData?.dtBinningRangeDatas: !isSaveButtonClicked?selectedColumnDataPointTable:categoryTableData?.dtBinningRangeDatas, [isSaveButtonClicked,selectedColumnDataPointTable,categoryTableData]);


    const DataColumns = [
      { accessor: 'start_point', header: 'Start value', editable: true},
      { accessor: 'end_point', header: 'End value', editable: true },
      { accessor: 'system_bucket_name', header: 'Name of the bucket (System generated)', editable: false},
      { accessor: 'user_bucket_name', header: 'Name of the bucket (User defined)', editable: true },
    ];


    useEffect(() => {
      if(!categoryTableData?.isEdit){
        dispatch(getNodeWiseCategoryList({
          workflow_id: currentWorkflowId,
          ecl_node_id: currentEclNodeId,
        }))
      }

      }, [currentEclNodeId, currentWorkflowId, dispatch])
    
    
      useEffect(() => {
        if (currentNodeData?.mismatchTableData?.mismatchedDatas || currentNodeData?.id) {
          refreshDataTable()
        }
        if (currentNodeData?.id) {
          dispatch(saveBinningCategory({
            "workflow_id": currentWorkflowId,
            "ecl_node_id": currentEclNodeId,
            "category_id": formik.values.category
          }
          ))
        }
      }, [])


    const disableCategory = location?.state?.isfromHistory || isDataLoading || currentNodeData?.nodeCategoryId


  const binningColumnList = useSelector(state => {
    let result = "";
      return state?.Workflow?.binningColumnList?
      state?.Workflow?.binningColumnList?.map(data => {
        selectedColumnData?.some((record) => (
          result = record?.column_name === data?.column_label
        ));
        return (
        {
        label: data?.column_name,
        value: data?.column_label,
        data,
        disabled: result
      }
    )
    }):""
    })
  


    const handleKeyPress = (e) => {
      const { key } = e;
      
      // Allow only numbers (1-9)
      if (!/^[0-9]$/.test(key)) {
          e.preventDefault();
      }
      };
    

    const handlePaste = (e) => {
      const pasteData = e.clipboardData.getData('text');
      const value = e.target.value + pasteData;
      if (!/^[0-9]$/.test(value)) {
        e.preventDefault();
      }
    };


const handleInputChange = (e) => {
  setIsAddEnabled(true)
  const { name, value } = e.target;        
  setBucketNumber(e.target.value);

};

      const numberofBucketsOnblur = async (e)=>{
        console.log(bucketNumber,columnName,"columnName");
        if(bucketNumber != null && bucketNumber > 0  && columnName){
          setIsDisabled(true)

        // const inputValue = e.target.value;
        // Allow only numbers greater than 0
              const details = {
                  "workflow_id":currentWorkflowId,
                  "ecl_node_id":currentEclNodeId,
                  "category_id":formik?.values?.category,
                  "column_name": columnName,
                  "no_of_buckets": bucketNumber
              }
          
          
              const response = await binningOnchangeData(details);
              console.log(response,"responseee");
              if (response?.data?.status === 200) {
                setIsDisabled(false)
                setIsAddEnabled(true)
                  setBucketValue(response?.data);
                  setBucketValueEdit(response?.data)
                  setIsShowsummaryTable(true)
              }
              
            }
            else{
              toast.error('The number of buckets can only be positive numerical values other than 0')
          }
    }

      const onColumNameChange = async (data) => {
        formik.setFieldValue("columnName", data);
        setColumnName(data);
        if(bucketNumber){
            setBucketNumber(0)
        }
        // setBucketValue(null);
      };

      const onNumberOfBucketChange = async (event) => {
        const inputValue = event.target.value;
        // Allow only numbers greater than 0
        if (/^\d*\.?\d+$/.test(inputValue) && parseFloat(inputValue) > 0) {
          setBucketNumber(event.target.value);
          console.log(event.target.value,"dataasaaaa");
          if(event.target.value){
              const details = {
                  "workflow_id":currentWorkflowId,
                  "ecl_node_id":currentEclNodeId,
                  "category_id":formik?.values?.category,
                  "column_name": columnName,
                  "no_of_buckets": event.target.value
              }
          
          
              try {
              const response = await binningOnchangeData(details);
              console.log(response,"responseee");
              if (response?.data?.status === 200) {
                setIsAddEnabled(true)
                  setBucketValue(response?.data);
                  setBucketValueEdit(response?.data)
                  setIsShowsummaryTable(true)
              }
            } catch (error) {
            console.error("Error fetching outlier methods", error);
            }
        }

        
        }
        // Clear the value if it doesn't match the criteria
        else if (inputValue == "") {
          setBucketNumber("");

        }
      };

    

    // bucket data form
    const bucketDataForm = () => {

        if (!currentNodeData?.nodeCategoryId) return
    
        if (isDataLoading || isMergeSummaryLoading) {
          return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }
    
        const mergeSummaryTableData = summaryData
    
        console.log({ summaryData });
    
        return <div className='mt-5'>
    
    <div className='flex gap-5 items-end mt-[1.5rem]'>
              <div className={`${isAddEnabled?'w-full':'w-[18%]'}`}>
                <DropDown
                    dropDownOptions={binningColumnList}
                    placeholder={'Select Column Name'}
                    name={'usedCategory'}
                    ordinary={true}
                    disabled={false}
                    customTags={false}
                    // value={value || undefined}
                    value={columnName && isAddEnabled ? columnName : undefined}
                    onOptionChange={onColumNameChange}
                    noDataFountMessage={'File must have integer column'}
                    selectedColumnName={categoryTableData?.dtBinningRangeDatas}
                    />
                    </div>
                <div className={`${isAddEnabled?'w-full':'w-[18%]'}`}>
                    <h2 className='font-semibold text-[12px] 2xl:text-[13px] my-1 '>Number of buckets</h2>

                    <TextInput
                        placeholder="Enter number of buckets"
                        onKeyPress={handleKeyPress}
                        onChange={handleInputChange}
                        onPaste={handlePaste}
                        value={isAddEnabled?bucketNumber:""}
                        onBlur={numberofBucketsOnblur}
                        // onChange={(event)=>onNumberOfBucketChange(event)}
                        className={`place text-[12px] 2xl:text-[13px] custom-select-height ${isDisabled ? 'bg-[#E0DCD7]':'bg-white'}`} 
                        />
                </div>

                {/* show when number of bucket onchange */}
                {isAddEnabled?
                    <>
                        <div className='w-full'>
                            <h2 className='font-semibold text-[12px] 2xl:text-[13px] my-1 '>Minimum value</h2>
                            <ReadOnlyValueDisplay>{bucketValue?.dtBinningRangeDatas?.min_value}</ReadOnlyValueDisplay>
                        </div>
                        <div className='w-full'>
                            <h2 className='font-semibold text-[12px] 2xl:text-[13px] my-1 '>Maximum value</h2>
                            <ReadOnlyValueDisplay>{bucketValue?.dtBinningRangeDatas?.max_value}</ReadOnlyValueDisplay>
                        </div>
                        <div className='w-full'>
                            <h2 className='font-semibold text-[12px] 2xl:text-[13px] my-1 '>Range</h2>
                            <ReadOnlyValueDisplay>{bucketValue?.dtBinningRangeDatas?.range}</ReadOnlyValueDisplay>
                        </div>
                        
                    </>
                :""}

            </div>
        </div>
      }

    // bucket summary table

    const bucketSummaryTable = () => {

        if (!isShowsummaryTable) return  
    
        return <div className='mt-5'>
    
    <div className=''>
              <EditableTable 
              columns={DataColumns} 
              tableData={binningTableData ?? []} 
              setTableData={setBinningtableData}
              setIsValid={setIsValid}
              thClassName={'bg-[#A06DFF] text-white text-[12px] 2xl:text-[14px] font-semibold p-2 whitespace-nowrap w-[15rem] px-4'}
              tdClassName={'p-2 text-[12px] 2xl:text-[14px] text-[#A8A8A8] px-4'}
              trClassName={'border-b border-[#E0DCD7]'}
              inputClassName={'w-[80%]'}                 
          />
    {/* <SummaryTable
                columns={DataColumns}
                data={tableData}
                className='mt-8'
            /> */}

            <div className="mt-4 text-center justify-center">
            <center><Button
                            bgColor='#42B2AC'
                            borderType=""
                            disabled={!formik.values?.category || location?.state?.isfromHistory || !isValid || binningTableData?.some(data => data?.end_point == "" || data?.user_bucket_name=='') }
                            label={'Add'}
                            color={'#ffffff'}
                            onClick={onAdd}
                            size={'md'} />
                            </center>
                
            </div>

            </div>
        </div>
      }


      const bucketSummaryDataTable = () =>{
        if (!showBucketTable &&  !categoryTableData?.isEdit) return  
    
        return  <div className='mt-5'>
          {categoryTableData?.isEdit?
          <>
          
          <div className=' bg-[#2B144C] px-2 flex justify-between items-center'>
          <div className='flex gap-3 items-center py-2'>
            <h3 className='text-[#FFFFFF] text-[12px] whitespace-nowrap bg-[#F7BE7E] px-2 py-1 rounded-[4px] '>Data category</h3>
            <p className='font-semibold text-[#FFFFFF] text-[10px] whitespace-nowrap'>Transaction document</p>
          </div>
            <h2 className='text-white text-[12px] font-semibold text-center w-full'>Calculated output</h2>
            {/* <div className={`float-right flex gap-1 items-center mx-4 ${location?.state?.isfromHistory ? 'pointer-events-none' : 'cursor-pointer'}`} onClick={onEditClick}>
              <img alt='' src={EditIcon} className='w-4 h-4' />
              <span className='text-white text-[13px] xl:text-[14px]'> Edit</span>
            </div> */}
          </div>
        </>
        :""}
                <SummaryTable
                    columns={bucketDataColumns}
                    data={bucketData}

                    tdClassName='border-b border-[#E0DCD7] text-[#707070]'
                    thClassName='!bg-[#A06DFF]'
                    className='-mt-3 border-separate border-spacing-y-2'
                />
                { categoryTableData?.isEdit?"":
                            <div className="mt-4 flex justify-center">
                <Button
                    bgColor='#42B2AC'
                    borderType=""
                    label={'Save'}
                    color={'#ffffff'}
                    onClick={()=>onApply('save')}
                    size={'md'} />
                    </div>
      }
        </div>
      }

   

    // result table proceed button
    const onProceedClick = () => {
       
        dispatch(openModalBuilder())

      }


    // result table
    const renderResultTable = () => {

        if (!currentNodeData?.nodeCategoryId) return
    
        if (isDataLoading || isMergeSummaryLoading) {
          return <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
        }
    
        const mergeSummaryTableData = summaryData
    
        console.log({ summaryData });
    
        return <div className='mt-5'>
    
          <div className='flex justify-end items-center'>
            {isExportLoading
              ? <img src={downloadingIcon} className='w-6 mr-2' />
              : <img alt='' src={XlsIcon} className=' mb-2 object-cover mr-2 cursor-pointer w-5 h-5 xl:w-6 xl:h-6' onClick={donwloadXl} />}
          </div>
    
          <DataTable
            columns={dataTableColumns}
            data={mergeSummaryTableData?.columnDatas ?? []}
            onTableStateChange={onTableStateChange}
            totalCount={mergeSummaryTableData?.totalRecords}
            isOptionsDisabled={location?.state?.isfromHistory}
            getFilterOptions={getFilterOptions}
            tableState={{
              pagination: {
                pageIndex: tableState.pageIndex,
                pageSize: tableState.pageSize,
              },
              sorting: tableState?.sortBy ? [
                {
                  id: tableState?.sortBy,
                  desc: tableState?.sortOrder === "desc"
                }
              ] : [],
              columnFilters: tableState.columnFilters
                ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
                : [],
              columnPinning: tableState.columnPinning,
              columnVisibility: tableState?.columnVisibility,
            }}
          />
          {currentNodeData?.node_status == "Completed"
            ? <div className={`float-right 2xl:my-6 my-3 ${categoryTableData?.isEdit &&!location?.state?.isfromHistory ? 'block' : 'hidden'}`}>
              <ProceedButton
                isLoading={isProceedLoading}
                onClick={onProceedClick}
              />              
            </div>:""}
        </div>
      }

      console.log(categoryTableData,bucketData,"categoryTableData");


    return (
        filesMerging || isCalculating
      ? <DataTableSkeleton showHeader={true} columnWidths={''} rowCount={5} columnCount={7} showPagination={true} showActionableButtons={false} from='mergeSummary' isDownload={false} />
      : 
      <div className='my-2'>
        {!isSaveButtonClicked && !categoryTableData?.isEdit?
        <>
        {currentWorkflowNodeId
          ? null
          : <div className='flex gap-5'>
            <DropDown
              dropDownOptions={parseCategotyValueLabel(nodeWiseCategory)}
              placeholder={'Select data category'}
              name={'usedCategory'}
              ordinary={true}
              disabled={disableCategory}
              customTags={false}
              value={formik.values.category}
              onOptionChange={onCategoryChange}
            />
            
            <Button
              bgColor='#42B2AC'
              borderType=""
              disabled={disableCategory || !formik.values.category}
              label={'Save'}
              color={'#ffffff'}
              onClick={()=>onCategorySave('add')}
              size={'md'} />
          </div>
        }
        {bucketDataForm()}
        {bucketSummaryTable()}
        </>
        :""
        }
        {bucketSummaryDataTable()}
        {/* {renderMistmatchTable()} */}
        {renderResultTable()}
      </div>
    );
}

export default Binningandbucketing;
