import { CATEGORY_NAME_UPDATE_SUCCESS, CLIENTS_LIST_FETCH_SUCCEEDED,
        CLIENTS_LIST_SORTING,
        CLIENTS_LIST_SORTING_SUCCESS ,
        CLIENT_COUNTRY_LEVEL_ONE_LIST,
        CLIENT_COUNTRY_LEVEL_ONE_LIST_FETCH_SUCCEEDED,
        CLIENT_COUNTRY_LEVEL_TWO_LIST_FETCH_SUCCEEDED,
        CLIENT_COUNTRY_LIST_FETCH_SUCCEEDED,
        CLIENT_CREATE_SUCCESS,  
        LEVEL_NAME_UPDATE_SUCCESS,
        MANAGE_COUNTRY_SUCCESS,
        MANAGE_LEVEL_SUCCESS
} from './ActionType'


const initalState = {
    page: 0,
    isLoading: true,
    isLoadingMore: false,
    totalSize: 0,
    canFetchMore: true,
    requestBody: {},
    isError: false,
    clients: [],
    errorMessage: "",
    clientsResponse: null,
    filterSortBy: "sortBy",
    dataSortBy: "sortBy",
}

const Clients = (state = initalState, action) => {  
    switch (action.type) {
        case CLIENTS_LIST_FETCH_SUCCEEDED:
            state = {
            ...state,
            isLoading: false,
            hasMore:action.payload.hasMore,
            clientsList:action.payload.clientList
            };
            break;  
            
        case CLIENT_CREATE_SUCCESS: 
            state = {
            ...state,
            CreatedClients:action.payload
            };
            break;    
        case CLIENTS_LIST_SORTING:
            state = {
            ...state,
            clients:action.payload,
            loading: true,
            };
        break;
        case CLIENTS_LIST_SORTING_SUCCESS:
            state = {
            ...state,
            sortedClientList:action.payload,
            loading: true,
            };
        break;  
        case CLIENT_COUNTRY_LIST_FETCH_SUCCEEDED:
            state = {
            ...state,
            isLoading: false,
            hasMore:action.payload.hasMore,
            clientsCountryList:action.payload.clientList
            };
            break;  
        case CLIENT_COUNTRY_LEVEL_ONE_LIST_FETCH_SUCCEEDED:
            state = {
            ...state,
            isLoading: false,
            hasMore:action.payload.hasMore,
            clientLevelOneData:action.payload,
            updatedLevelName:action.payload.clientLevelName,
            clientLevelOneLists:action.payload.clientLevelOneLists,
            clientCountryId:action.payload.clientCountryId
            };
            break;  
        case CLIENT_COUNTRY_LEVEL_TWO_LIST_FETCH_SUCCEEDED:
            state = {
            ...state,
            isLoading: false,
            hasMore:action.payload.hasMore,
            clientLevelTwoData:action.payload,
            updatedLevelName:action.payload.clientLevelName,
            clientLevelTwoLists:action.payload.clientLevelTwoLists,
            clientCountryId:action.payload.clientCountryId
            };
            break;  
        case LEVEL_NAME_UPDATE_SUCCESS: 
            state = {
            ...state,
            clientLevelName:action?.payload?.clientLevelName,
            loading: true,
            };
        case CATEGORY_NAME_UPDATE_SUCCESS: 
            state = {
            ...state,
            updatedCategoryName:action?.payload?.clientCategoryName,
            loading: true,
            };
        case MANAGE_COUNTRY_SUCCESS: 
            state = {
            ...state,
            manageCountryDetails:action?.payload?.clientDetails,
            loading: true,
            };
        case MANAGE_LEVEL_SUCCESS: 
        // console.log(action?.payload?.categoryList,'action?.payload?.categoryList');
            state = {
            ...state,
            manageLevelDetails:action?.payload?.categoryList,
            loading: true,
            };
        default:
            state = { ...state };
            break;
        }
    return state;
};

export default Clients;