import React, { Children, useEffect, useRef, useState } from "react";
import Button from "../Button";
//images
import closeIcon from "../../Images/popup-close.svg";
import AlertIcon from "../../Images/alert.svg";
import { localStorageKeys } from "../../Utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { deleteCalculationCheck } from "../../Store/Workflow/WorkflowSlice";
import { deleteCalculationFileCheck } from "../../Helpers/ApiHelpers";

function CommonPopupLayout({
  show,
  onSubmit,
  onCancel,
  content,
  disabled,
  title,
  buttons,
  minWidth,
  sumbitButtonName,
  cancelButtonName,
  isFrom,
  handleDisable,
}) {
  const handleSubmit = () => {
    localStorage.setItem(
      localStorageKeys.nodeDetails,
      localStorage.getItem(localStorageKeys.selected_file_details)
    );
    // setTimeout(() => {
    onSubmit();
    // }, 1000);
    onCancel();
  };
  const [confirmTodelete, setConfirmTodelete] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const dispath = useDispatch();

  const { selectedDeleteFiles, deleteFileStatus } = useSelector((state) => {
    return {
      selectedDeleteFiles: state?.Workflow?.selectedDeleteFiles,
      deleteFileStatus: state?.Workflow?.ui?.deleteFileStatus,
    };
  });

  console.log(selectedDeleteFiles, "selectedDeleteFiles");

  const getDeleteFileStatus = async () => {
    const { workflow_id, category_id } = selectedDeleteFiles[0];

    // Initialize the response object with extracted workflow_id and category_id
    const requestData = {
      workflow_id,
      category_id,
      file_paths: [],
    };

    // Iterate over the data array
    selectedDeleteFiles?.forEach((item) => {
      // Filter the sub_sheets where checked is true
      const filteredSheets = item.sub_sheets.filter((sheet) => sheet.checked);

      // Map the filtered sheets to the required structure and push to file_paths
      filteredSheets.forEach((sheet) => {
        requestData?.file_paths.push({
          sheet_name: sheet.sheet_name,
          url: sheet.sheet_url,
        });
      });
    });

    const response = await deleteCalculationFileCheck(requestData);
    if (response?.data?.status == 200) {
      setWarningMessage("Are you sure you want to delete the selected sheets?");
      setConfirmTodelete(true);
      handleDisable(true);
    } else if (response?.data?.status === 205) {
      setWarningMessage(response?.data?.message);
      setConfirmTodelete(true);
      handleDisable(true);
    } else {
      setWarningMessage(response?.data?.message);
    }

    //   await dispath(deleteCalculationCheck(requestData))

    //     .then((response) => {
    //     if (response.type === `${deleteCalculationCheck.fulfilled}`) {
    //         setConfirmTodelete(true)
    //     }else if(response.type === `${deleteCalculationCheck.rejected}`){
    //         setWarningMessage(deleteFileStatus?.message)
    //         setConfirmTodelete(true)
    //     }
    // })
    // .catch((error) => {
    //     console.log("API not working", error);
    // });
  };

  //   useEffect(() => {
  //     if (deleteFileStatus?.message) {
  //         setWarningMessage(deleteFileStatus?.message);
  //     }
  // }, [deleteFileStatus?.message]);

  return !show ? (
    <></>
  ) : (
    <div
      className="relative z-[101]"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="h-screen w-screen fixed inset-0 bg-black bg-gray-500 bg-opacity-75 transition-opacity"></div>
      <div className="fixed inset-0 z-[101]">
        <div className="flex min-h-full justify-center items-center">
          <div
            className={`relative min-h-[15rem] ${
              minWidth ? minWidth : "min-w-[60%]"
            }`}
          >
            <img
              src={closeIcon}
              className="absolute right-[-0.8rem] top-[-1rem] xl:w-[1.7rem] w-[1.5rem] cursor-pointer"
              onClick={onCancel}
            />
            <div
              className={`bg-white rounded-lg min-h-[10rem] max-h-[32rem] ${
                minWidth ? minWidth : "min-w-[60%]"
              } space-y-5 pb-3`}
            >
              {/* header */}
              <div className="bg-[#4F2D7F] rounded-t-lg">
                <p className="text-[14px] text-white text-center leading-[30px]">
                  {title}
                </p>
              </div>
              {/* content */}
              <div className="max-h-[18rem] overflow-y-auto thin-scrollbar">
                <div className="space-y-2 h-full">{content}</div>
              </div>
              {/* footer */}
              {buttons && (
                <div className="space-y-2">
                  {confirmTodelete && (
                    <div className="flex flex-col w-full justify-center items-center">
                      <div className="space-y-2 w-[50%] border border-[#AFABAB] p-2">
                        <div className=" rounded-[2px] p-1.5 w-fit mx-auto px-[2.5rem] ">
                          {/* <div className="flex gap-1">
                                        <img src={AlertIcon} className="w-4" /> 
                                        <p className="text-[#B70000] 2xl:text-[12px] text-[11px]">Warning</p>
                                    </div> */}
                          <p className="text-[#B70000] font-medium 2xl:text-[13px] text-[12px] w-fit text-center">
                            {warningMessage}
                          </p>
                        </div>

                        <div className="flex items-center gap-3 justify-center">
                    <Button
                      bgColor="#ffffff"
                      borderType="normal"
                      label={
                        cancelButtonName
                          ? cancelButtonName
                          : confirmTodelete
                          ? "No"
                          : "Cancel"
                      }
                      color={"#000000"}
                      type={"button"}
                      size={"lg"}
                      borderColor={"#2F5597"}
                      onClick={onCancel}
                      paddingY={"2xl:py-2 py-[0.3rem]"}
                      borerRadius={"rounded-[5px]"}
                    />
                    {confirmTodelete && (
                      <Button
                        bgColor="#42B2AC"
                        borderType="normal"
                        label={"Yes"}
                        color={"#ffffff"}
                        type={"button"}
                        size={"lg"}
                        paddingY={"2xl:py-[0.6rem] py-[0.4rem]"}
                        borerRadius={"rounded-[5px]"}
                        onClick={() => handleSubmit()}
                      />
                    )}
                    {!confirmTodelete && (
                      <Button
                        bgColor="#42B2AC"
                        borderType="normal"
                        label={`${sumbitButtonName ?? "Confirm and delete"}`}
                        color={"#ffffff"}
                        type={"button"}
                        weight={"bold"}
                        size={"lg"}
                        paddingY={"2xl:py-[0.6rem] py-[0.4rem]"}
                        borerRadius={"rounded-[5px]"}
                        disabled={disabled}
                        onClick={() =>
                          isFrom == "delete"
                            ? getDeleteFileStatus()
                            : handleSubmit()
                        }
                      />
                    )}
                  </div>
                      </div>
                    </div>

                  )}
                  {confirmTodelete?"":
                  <div className="flex items-center gap-3 justify-center">
                    <Button
                      bgColor="#ffffff"
                      borderType="normal"
                      label={
                        cancelButtonName
                          ? cancelButtonName
                          : confirmTodelete
                          ? "No"
                          : "Cancel"
                      }
                      color={"#000000"}
                      type={"button"}
                      size={"lg"}
                      borderColor={"#2F5597"}
                      onClick={onCancel}
                      paddingY={"2xl:py-2 py-[0.3rem]"}
                      borerRadius={"rounded-[5px]"}
                    />
                    {confirmTodelete && (
                      <Button
                        bgColor="#42B2AC"
                        borderType="normal"
                        label={"Yes"}
                        color={"#ffffff"}
                        type={"button"}
                        size={"lg"}
                        paddingY={"2xl:py-[0.6rem] py-[0.4rem]"}
                        borerRadius={"rounded-[5px]"}
                        onClick={() => handleSubmit()}
                      />
                    )}
                    {!confirmTodelete && (
                      <Button
                        bgColor="#42B2AC"
                        borderType="normal"
                        label={`${sumbitButtonName ?? "Confirm and delete"}`}
                        color={"#ffffff"}
                        type={"button"}
                        weight={"bold"}
                        size={"lg"}
                        paddingY={"2xl:py-[0.6rem] py-[0.4rem]"}
                        borerRadius={"rounded-[5px]"}
                        disabled={disabled}
                        onClick={() =>
                          isFrom == "delete"
                            ? getDeleteFileStatus()
                            : handleSubmit()
                        }
                      />
                    )}
                  </div>
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CommonPopupLayout;
