import React, { useEffect, useMemo, useState } from 'react'
import { CALCULATION_UNIQUE_VALUES, GET_DATA_QUALITY_TYPE, GET_DATA_QUALITY_TYPE_PERCENTAIL, GET_MERGE_SUMMARY_RESULT, POSITION_ANALYSIS_UNIQUE_VALUES } from '../../Helpers/EndPoints'
import { Baseurl, NumericValues, positionAnalysisTableHeader } from '../../Utils/Constants'
import axios from 'axios'
import { createColumnHelper } from '@tanstack/react-table'
import DataTableSkeleton from '../Skeleton/DataTableSkeleton'
import BarchartIcon from "../../Images/graphView.svg";
import PiechartIcon from "../../Images/pictorialView.svg";
import XlsIcon from '../../Images/excel.svg'
import { getSingleFilePerticularList } from '../../Store/action'
import { useDispatch, useSelector } from 'react-redux'
import { getPerticularNodeDetails } from '../../Helpers/ApiHelpers'
import Chart from '../../Components/wizardPage/Chart'
import ReactWordcloud from 'react-wordcloud'
import { getWordCloudOptions } from '../../Utils/CommonFunctions'
import { BarChartSkeleton } from '../Skeleton/PieChartSkeleton'
import DateChart from "../../Components/wizardPage/DateChart"
import CommonPopup from '../PopUp/CommonPopup'
import RangeChart from '../wizardPage/RangeChart'
import DataTable from '../Table/DataTable'
import { updateCurrentNodeData } from '../../Store/Workflow/WorkflowSlice';
import exportingIcon from '../../Images/gif/downloading.gif'


export function PositionAnalysisTableChart({ type, row, workflow_id, category_id, categoryName, onClose }) {

  const dataTypeRequest = NumericValues.includes(row?.data_type) ? "BIGINT" : row?.data_type

  const [showBy, setShowBy] = useState(dataTypeRequest === "BIGINT" ? "withNotNull" : "byMonth")
  const [graphData, setGraphData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)




  useEffect(() => {
    setIsLoading(true)
    const request = {
      "ecl_workflow_id": workflow_id,
      "category_id": category_id,
      "column_name": row?.column_name,
      "column_data_type": dataTypeRequest,
      "is_null": showBy === "withNull",
      "column_type": showBy === "byDay" ? 'by_day' : 'by_month'
    }

    if ((row?.data_type != 'VARCHAR' && type === "bar") || (row?.data_type == 'VARCHAR' && type === "bar")) {
      axios(`${Baseurl + GET_DATA_QUALITY_TYPE_PERCENTAIL}`, {
        method: "POST",
        data: request,
      })
        .then((response) => {
          if (response?.data?.status == 200) {
            setGraphData(response?.data)
            setIsLoading(false)
          }
        })
        .catch((error) => {
          console.log("API not working", error);
        })
    } else {
      axios(`${Baseurl + GET_DATA_QUALITY_TYPE}`, {
        method: "POST",
        data: request,
      })
        .then((response) => {
          if (response?.data?.status == 200) {
            setIsLoading(false)
            setGraphData(response?.data)
          }
        })
        .catch((error) => {
          console.log("API not working", error);
        })
    }




  }, [category_id, dataTypeRequest, row?.column_name, row?.data_type, showBy, workflow_id, type])

  const renderChart = () => {
    if (isLoading) {
      return <BarChartSkeleton />
    }

    if (type === "bar") {
      if (dataTypeRequest == 'BIGINT') {
        return <Chart data={graphData?.datas} chartType={'bar'} />
      }

      if (dataTypeRequest == 'DATE') {
        return <DateChart data={graphData?.datas} isDayBased={showBy === "byDay"} />
      }

      return <Chart data={graphData?.datas} chartType={'pie'} />
    } else {
      if (dataTypeRequest == 'BIGINT' || dataTypeRequest == 'DATE') {
        if (dataTypeRequest == 'DATE') return <div className='flex items-center justify-center w-full'>
          <p>Coming soon...</p>
        </div>
        const rangeData = {
          min: graphData?.datas[0]?.value,
          mean: graphData?.datas[2]?.value,
          mode: graphData?.datas[3]?.value,
          max: graphData?.datas[1]?.value,
          from: showBy === "byDay" ? "date" : "month",
        }
        return <RangeChart data={rangeData} />
      }
      return <div className='align-center'>
        <ReactWordcloud words={graphData?.datas} options={getWordCloudOptions(graphData?.datas?.length)} />
        </div>
    }

  }
  console.log({ graphData });

  const handleViewByChange = (e) => {
    setShowBy(e.target.value)
  }

  const renderViewBy = () => {
    if (dataTypeRequest == 'BIGINT') {
      return <div className='flex ml-6 space-x-4'>
        <div className='flex space-x-2 text-[13px]'>
          <input className='cursor-pointer' onChange={handleViewByChange} checked={showBy === "withNull"} id='withNull' value="withNull" type='radio' />
          <label className='cursor-pointer' htmlFor='withNull'>With null</label>
        </div>

        <div className='flex space-x-2 text-[13px]'>
          <input className='cursor-pointer' onChange={handleViewByChange} checked={showBy === "withNotNull"} id='withNotNull' value="withNotNull" type='radio' />
          <label className='cursor-pointer' htmlFor='withNotNull'>With not null</label>
        </div>
      </div>
    } else if (dataTypeRequest === "DATE") {
      if (type != "bar") return null
      return <div className='flex space-x-4 ml-6 text-[13px]'>
        <div className='flex space-x-2'>
          <input className='cursor-pointer' onChange={handleViewByChange} checked={showBy === "byDay"} id='byDay' value="byDay" type='radio' />
          <label className='cursor-pointer' htmlFor='byDay'>Date wise</label>
        </div>

        <div className='flex space-x-2 text-[13px]'>
          <input className='cursor-pointer' onChange={handleViewByChange} checked={showBy === "byMonth"} id='byMonth' value="byMonth" type='radio' />
          <label className='cursor-pointer' htmlFor='byMonth'>Month wise</label>
        </div>
      </div>
    } else {
      return <></>
    }
  }


  const height = type != "bar" && (dataTypeRequest == 'BIGINT' || dataTypeRequest == 'DATE') ? "min-h-[15rem]" : "min-h-[50vh]"

  const getWidth = () => {
    if (dataTypeRequest === "DATE") return "min-w-[50vw]"

    return "min-w-[55vw]"
  }

  return (
    <CommonPopup
      title={`${row?.column_name} - ${categoryName} ${type === "bar" ? (dataTypeRequest == 'BIGINT' ? "bar graph" :"pie chart") : "Pictorial view"}`}
      onCancel={onClose}
      content={<div className={`flex flex-col ${height} ${getWidth()} p-4`}>
        {renderViewBy()}
        {renderChart()}
      </div>}
    />
  )
}



function PositionAnalysisTable({ workflow_id, ecl_node_id, workFlow_node_id, category_id, initialData, request, current_headers, isDownloadxls, tableHeaders, totalColumns, totalRecords, isFor, file_name, summary ,isOptionsDisabled, filePath}) {
  const dispatch = useDispatch()
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [summaryData, setSummaryData] = useState(initialData ?? null)
  const [isExport, setIsExport] = useState(false);
  const [prevRequest, setPrevRequest] = useState(request)
  const [currentHeaders,setCurrentHeaders] = useState([])
  const [tableState, setTableState] = useState({
    pageIndex: 0,
    pageSize: 10,
    sortBy: "",
    sortOrder: "",
    columnPinning: {
      left: summary?.pinLeftColumns ?? [],
      right: summary?.pinRightColumns ?? []
    }
  })
  const [grapViewData, setGrapViewData] = useState(null)

  const S3_BUCKET_URL = process.env.REACT_APP_S3_URL;

  const getHeaders = () => {
    let headers = [...tableState.columnPinning.left].map(header => positionAnalysisTableHeader.find(posHead => posHead.accessor == header)?.header);

    tableHeaders.forEach(element => {
      if (!headers.includes(element.header) && !tableState.columnPinning.right?.includes(element.accessor)) {
        headers.push(element.header)
      }
    });
    tableState.columnPinning.right.forEach(element => {
      const el = positionAnalysisTableHeader.find(posHead => posHead.accessor == element)?.header
      if (!headers.includes(el)) {
        headers.push(el)
      }
    });

    return headers.filter(head => head != "Action")
  }


  const apicallSummary = async ({ page, sort_by, sort_order, isFrom, filter_by_values, filter_by_conditions }) => {
    setIsDataLoading(isFrom != 'export');

    const appliedFilterByvalues = (filter_by_values ? filter_by_values : tableState?.columnFilters ?? [])?.filter(fil => !fil.operation)
    const appliedFilterByCondition = (filter_by_conditions ? filter_by_conditions : tableState?.columnFilters ?? [])?.filter(fil => fil.operation)
    if(isFor == 'positionAnalysis'){


    const request = {
      workflow_id,
      ecl_node_id,
      category_id,
      page,
      record_per_page: 10,
      sort_by,
      sort_order,
      is_export: isExport,
      filter_by_values: appliedFilterByvalues ?? [],
      filter_by_conditions: appliedFilterByCondition ?? [],

    };



    const prevReq = { workflow_id,
      ecl_node_id,
      category_id,
      page,
      record_per_page: 10,
      sort_by,
      sort_order,
      is_export: isExport,
      filter_by_values: appliedFilterByvalues ?? [],
      filter_by_conditions: appliedFilterByCondition ?? [],
      is_export: true,
      current_headers: getHeaders(),

     };
    setPrevRequest(request);
    axios(`${Baseurl + GET_MERGE_SUMMARY_RESULT}`, {
      method: "POST",
      data: isFrom === 'export' ? prevReq : request,
      responseType: isFrom === 'export' ? 'blob' : 'json'
    })
      .then((response) => {
        setIsDataLoading(false);
        console.log({ response, dd: isFrom === 'export' && response?.data });
        if (isFrom === 'export' && response?.data) {
          const blob = new Blob([response.data], { type: 'text/xls' });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          // link.setAttribute("download", `file.csv`);
          link.setAttribute("download", response.headers['content-disposition'].split('filename=')[1]); // Extract filename from response headers
          document.body.appendChild(link);
          link.click();
          setIsExport(false);
        } else if (response?.data?.status == 200) {
          setSummaryData(response.data?.mergedResults[0]);
          setIsExport(false);
          console.log("getMergedFilesTable", response.data);
        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });
    }else{
      const apiRequest = {
        workflow_node_id: parseInt(workFlow_node_id),
        page,
        record_per_page: 10,
        sort_by,
        sort_order,
        file_path: filePath,
        filter_by_values: appliedFilterByvalues ?? [],
        filter_by_conditions: appliedFilterByCondition ?? [],

     }
    dispatch(updateCurrentNodeData({request:apiRequest}))
     
    }
    };    

  const pinApiCall = ({ pin }) => {
    if (isFor == 'positionAnalysis') {
      const request = {
        workflow_id,
        ecl_node_id,
        category_id,
        page: 1,
        record_per_page: 1,
        is_export: false,
        pin,
      };
      axios(`${Baseurl + GET_MERGE_SUMMARY_RESULT}`, {
        method: "POST",
        data: request,
      })
    } else {
      const apiRequest = {
        workflow_node_id: parseInt(workFlow_node_id),
        page: 1,
        record_per_page: 1,
        file_path: "",
        sort_by: "",
        sort_order: "",
        pin,
      }
      getPerticularNodeDetails(apiRequest)
    }
  }

  console.log({ summaryData });
  const onColumnActionClick = (type, row) => {
    console.log({ type, rowData: row });
    setGrapViewData({ type, row: row.original })
  }

  const columns = useMemo(() => {
    const columnHelper = createColumnHelper();

    return tableHeaders.map(headerItem => {
        const { header, accessor } = headerItem;
        if (accessor === 'action') {
            return columnHelper.display({
                id: "action",
                header: () => header,
              size: 100,
              maxSize: 120,
              minSize: 80,
                cell: ({ row }) => (
                  <div className='flex items-center space-x-6 md:space-x-3 xl:space-x-6'>
                    <img onClick={() => onColumnActionClick("bar", row)} alt='' className='w-5 md:w-3.5 xl:w-5 2xl:w-6 cursor-pointer' src={BarchartIcon} />
                    <img onClick={() => onColumnActionClick("pie", row)} alt='' className='w-5 md:w-3.5 xl:w-5 2xl:w-6 cursor-pointer' src={PiechartIcon} />
                    </div>
                )
            });
        } else {
          return columnHelper.accessor(row => row[`${accessor}`], {
            id: accessor,
                header: () => header,
              size: 100,
              maxSize: 120,
              minSize: 80,
              meta: headerItem.meta,
              cell: ({ getValue }) => (getValue() || getValue() == 0) ? getValue() === true ? 'True' : getValue() === false ? 'False' : getValue() : <span></span>
            });
        }
    });
}, [tableHeaders]);




  const updateFilterState = (columnId, values, isReset) => {
    let currentFilter = tableState?.columnFilters ? [...tableState?.columnFilters] : []

    if (isReset === true) {
      currentFilter = currentFilter?.filter(fil => fil.key != columnId) ?? []
    } else {
      if (currentFilter.length == 0) {
        currentFilter = [{
          "key": columnId,
          "values": values,
          "select_all": false
        }]
      } else {
        const index = currentFilter.findIndex(ob => ob.key == columnId)
        if (index != -1) {
          currentFilter[index].values = values
        } else {
          currentFilter.push({
            "key": columnId,
            "values": values,
            "select_all": false
          })
        }
      }

    }

    setTableState(prev => ({ ...prev, pageIndex: 0, columnFilters: currentFilter }))
    apicallSummary({ page: 1, sort_by: tableState.sortBy, sort_order: tableState.sortOrder, filter_by_values: currentFilter })
    return currentFilter
  }

  const updateRangeFilterState = (columnId, values, isReset) => {
    let currentFilter = tableState?.columnFilters ? [...tableState?.columnFilters] : []

    if (isReset === true) {
      currentFilter = currentFilter?.filter(fil => fil.key != columnId) ?? []
    } else {
      const filterData = {
        "key": columnId,
        "operation": values.operation,
        "filterData": values,
        "values": values.operation === "between"
          ? {
            "min": values.min,
            "max": values.max
          }
          : values.min
      }
      if (currentFilter.length == 0) {
        currentFilter = [filterData]
      } else {
        const index = currentFilter.findIndex(ob => ob.key == columnId)
        if (index != -1) {
          currentFilter[index] = filterData
        } else {
          currentFilter.push(filterData)
        }
      }

    }
    setTableState(prev => ({ ...prev, pageIndex: 0, columnFilters: currentFilter }))
    apicallSummary({ page: 1, sort_by: tableState.sortBy, sort_order: tableState.sortOrder, filter_by_conditions: currentFilter })
    return currentFilter
  }


  const updateColumnPinning = (action, column) => {

    let columnPinning = tableState?.columnPinning ?? {
      right: [],
      left: []
    }

    if (action === "pinLeft") {
      columnPinning = {
        left: [...columnPinning?.left, column],
        right: columnPinning.right.filter(col => col != column)
      }
    } else if (action === "pinRight") {
      columnPinning = {
        right: [...columnPinning.right, column],
        left: columnPinning.left.filter(col => col != column)
      }
    } else {
      columnPinning = {
        right: columnPinning.right.filter(col => col != column),
        left: columnPinning.left.filter(col => col != column)
      }
    }

    setTableState(prev => ({ ...prev, columnPinning }))
    pinApiCall({ pin: { operation: action === "pinRight" ? "pinright" : action === "pinLeft" ? "pinleft" : "unpin", "key": column } })
  }


  const onTableStateChange = (action, column, data) => {
    if (action === "pagination") {
      setTableState(prev => ({ ...prev, pageIndex: column - 1 }))
      apicallSummary({ page: column, sort_by: tableState.sortBy, sort_order: tableState.sortOrder })
    } else if (action === "sorting") {

      if (column.sortOrder === false) {
        setTableState(prev => ({ ...prev, sortBy: "", sortOrder: "" }))
        apicallSummary({ page: tableState.pageIndex, sort_by: "", sort_order: "" })
      } else {
        setTableState(prev => ({ ...prev, pageIndex: 0, sortBy: column.sortBy, sortOrder: column.sortOrder }))
        apicallSummary({ page: 1, sort_by: column.sortBy, sort_order: column.sortOrder })
      }

    } else if (action === "filter") {
      const columnMeta = column?.columnDef?.meta;
      if (columnMeta?.filterType === "range") {
        updateRangeFilterState(column.id, data)
      } else {
        updateFilterState(column.id, data)
      }
    } else if (action === "filterReset") {
      const columnMeta = column?.columnDef?.meta;
      if (columnMeta?.filterType === "range") {
        updateRangeFilterState(column.id, null, true)
      } else {
        updateFilterState(column.id, null, true)
      }
    } else if (action === "pinRight" || action === "pinLeft" || action === "pinClear") {
      updateColumnPinning(action, column.id)
    }

    console.log({ action, column, data });
  }


  const data = useMemo(() => isFor == 'positionAnalysis' ? summaryData?.columnDatas : initialData, [initialData, isFor, summaryData?.columnDatas])

  const getFilterOptions = async (columnId) => {

    if (isFor == 'positionAnalysis') {
      const response = await axios.post(`${Baseurl}${POSITION_ANALYSIS_UNIQUE_VALUES}`, {
        workflow_id,
        ecl_node_id,
        category_id,
        "column_name": columnId
      })
      if (response.status == 200) {
        return response.data.data ? response.data.data?.filter(dat => dat != null) : []
      } else {
        return []
      }
    } else {
      const response = await axios.post(`${Baseurl}${CALCULATION_UNIQUE_VALUES}`, {
        "workflow_id": workflow_id,
        "file_path": filePath,
        "category_id": category_id,
        "column_name": columnId
      })
      if (response.status == 200) {
        // return response.data.data ? response.data.data?.filter(dat => dat != null) : []
        return response.data.data ? response.data.data?.filter(dat => dat != null) : []
      } else {
        return []
      }
    }

  }

useEffect(()=>{
  setCurrentHeaders(current_headers)

},[current_headers])

const {perticularWorkFlowNode} = useSelector(state => {
  return {   
    perticularWorkFlowNode: state?.ModelBuilder?.perticularNodeData,   
  }
})

useEffect(()=>{
  if(perticularWorkFlowNode){
    setIsDataLoading(false);
  }

},[perticularWorkFlowNode])

  return (
    isDataLoading
      ? <div className='mt-2'>
        <DataTableSkeleton showHeader={true}  columnWidths={''} rowCount={5} columnCount={7} showPagination={false} showActionableButtons={false} from='mergeSummary' isDownload={false} />
      </div>
      : 
      <>
        {grapViewData &&
          <PositionAnalysisTableChart
            categoryName={initialData?.CategoryName}
            category_id={category_id}
            type={grapViewData?.type}
            row={grapViewData.row}
            workflow_id={workflow_id}
            onClose={() => setGrapViewData(null)}
          />}
      {isDownloadxls &&
          <div className='flex items-center justify-end'>
            <img src={isExport ? exportingIcon: XlsIcon} className={`mr-2 mb-2 cursor-pointer object-cover ${isExport ?'w-6' : 'w-5 h-5 xl:w-6 xl:h-6'}`} onClick={() => { setIsExport(true); apicallSummary({ prevRequest, isFrom: 'export' }) }} />
          </div>
      }
      <DataTable
        onTableStateChange={onTableStateChange}
        totalCount={isFor == 'positionAnalysis' ? totalColumns : totalRecords}
        columns={columns}
        thClassName={isFor == 'positionAnalysis' ? ' 2xl:w-[14rem] w-[13rem]' : 'w-fit'}
        data={data ?? []}
        isOptionsDisabled={isOptionsDisabled}
        getFilterOptions={getFilterOptions}
        isFrom = {isFor}
        tableState={{
          pagination: {
            pageIndex: tableState.pageIndex,
            pageSize: tableState.pageSize,
          },
          sorting: tableState?.sortBy ? [
            {
              id: tableState?.sortBy,
              desc: tableState?.sortOrder === "desc"
            }
          ] : [],
          columnFilters: tableState.columnFilters
            ? tableState.columnFilters?.map(fil => ({ id: fil.key, value: fil.values, filterData: fil?.filterData }))
            : [],
          columnPinning: tableState.columnPinning
        }} />
      </>
  )
}

export default PositionAnalysisTable